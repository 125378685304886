// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-scrollable {
    position: relative;
  }
  
  .dropdown-scrollable .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    z-index: 1000;
  }
  
  .dropdown-scrollable .form-check {
    padding-left: 27px;
  }

  .dropdown-scrollable .form-check-input {
    margin-right: 8px; 
  }`, "",{"version":3,"sources":["webpack://./src/pages/performer/CreatePerformer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".dropdown-scrollable {\n    position: relative;\n  }\n  \n  .dropdown-scrollable .dropdown-content {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    max-height: 200px;\n    overflow-y: auto;\n    background: white;\n    border: 1px solid #ccc;\n    z-index: 1000;\n  }\n  \n  .dropdown-scrollable .form-check {\n    padding-left: 27px;\n  }\n\n  .dropdown-scrollable .form-check-input {\n    margin-right: 8px; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
