import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import dummyProfilePicture from "../../assect/images/client/02.jpg";
import Navbar from "../../components/navbarSelf";
import "./venue.css";
import { FaComments } from "react-icons/fa";
import { RiMessage3Fill } from "react-icons/ri";

const VenueOppurtunityApplicants = () => {
  return (
    <>
      <style>
        {`
      body {
        background-color: #102733;
      }
    `}
      </style>
      {/* <div className="background-image"> */}
      <Navbar
        navClass="defaultscroll sticky"
        menuClass="navigation-menu nav-left"
      />
      <br></br>
      <Card
        style={{
          borderRadius: "15px",
          backgroundColor: "#e2e2e2",
          display: "flex",
          justifyContent: "center",
          marginLeft: "13%",
          marginRight: "13%",
          marginTop: "5%",
          marginBottom: "5%",
          paddingLeft: "6%",
          paddingRight: "6%",
          paddingTop: "3%",
          paddingBottom: "5%",
        }}
      >
        <Row className="justify-content-center">
          <h4 className="heading fw-bold text-black title-dark text-center">
            Dickson Tap - House 21/05/2024 @ 7pm
          </h4>
        </Row>
        <br></br>
        <Row className="justify-content-center">
          <Col xs={12} md={3}>
            <h6 className="text-center">Live Chats</h6>
            <div className="thin-card-container">
              <div className="thin-card-left d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <RiMessage3Fill size={40} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="thin-card-container">
              <div className="thin-card-left d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <RiMessage3Fill size={40} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="thin-card-container">
              <div className="thin-card-left d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <RiMessage3Fill size={40} style={{ cursor: "pointer" }} />
              </div>
            </div>
          </Col>
          <Col
            xs={0}
            md={2}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="vl"></div>
          </Col>
          <Col xs={12} md={3}>
            <h6 className="text-center">Live Chats</h6>
            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>

            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>

            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>

            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>

            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>

            <div className="thin-card-container">
              <div className="thin-card-right d-flex align-items-center justify-content-between">
                <img
                  src={dummyProfilePicture}
                  alt="Profile"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <span className="mx-2">John Doe</span>
                <button
                  className="btn btn-sm"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Start Chat
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      {/* </div> */}
    </>
  );
};

export default VenueOppurtunityApplicants;
