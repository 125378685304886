import React from "react";
import { Link } from "react-router-dom";
import NavigationBar from "../../components/navbar/loggedUserNavbarAccount";
import Spinner from "react-bootstrap/Spinner";

export default function RestrictedAccessPage() {
  return (
    <>
      <NavigationBar />
      <section className="landing-profiles-container">
        <div className="title-heading text-center mb-20">
          <h4
            className="heading fw-bold title-dark mb-3"
            style={{
              marginTop: "6%",
              marginBottom: "-6%",
            }}
          >
            Access Restricted
          </h4>
          <p>
            The page is restricted since your profile is in an unpublished
            state.
          </p>
        </div>

        <section className="section" style={{ marginTop: "-5%" }}>
          <div className="row justify-content-center px-5 py-2 mt-10">
            <div className="col-md-6 text-center">
              <p className="mb-4">
                To access this page, please publish your profile or contact
                support for assistance.
              </p>
              {/* <Link to="/" className="btn btn-primary">
                Go Back to Home
              </Link> */}
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
