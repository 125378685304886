import React, { useState, useEffect } from "react";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { BsTrash } from "react-icons/bs";
import "./venue.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyCalendar from "../../pages/calendar/calendarSelfView";
import ViewVenueClassifieds from "../classifieds/viewVenueClassifieds";
import ViewVenueClassifiedsbyOthers from "../classifieds/viewVenueClassifiedsbyOthers";
import ViewOpportunities from "../../pages/opportunities/viewOpportunities";
import ViewOpportunitiesbyOthers from "../../pages/opportunities/viewOpportunitiesbyOthers";
import VenueCalendarSelfView from "../../pages/calendar/venueCalendarSelfView";
import ViewCalloutsbyOthers from "../callouts/viewCalloutsbyOthers";
import ViewCalloutsbySelf from "../callouts/viewCalloutsbySelf";
// import MyCalendar from "../performer/calendar";
import VerifyEmailModal from "../auth/emailVerification";
import VerifyPhoneNumberModal from "../auth/phoneVerification";
import {
  Spinner,
  Modal,
  Button,
  Nav,
  Tab,
  Row,
  Col,
  Card,
} from "react-bootstrap";
// import bg3 from "../../assect/images/bgnew2.jpg";
import Navbar from "../../components/navbarSelf";
import {
  FaFacebook,
  FaKey,
  FaInstagram,
  FaLinkedin,
  FaPlus,
  FaSpotify,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import {
  FaPhone,
  FaEnvelope,
  FaEdit,
  FaTrash,
  FaEllipsisV,
  FaUpload,
  FaCog,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import youtubeThumbnail from "youtube-thumbnail";
import Masonry from "react-masonry-css";
import styled from "styled-components";
// import defaultProfilePicture from "../../assect/images/venue/02.jpg";
// import jezzypic from "../../assect/images/client/jezzypic.jpg";
// import kayapic from "../../assect/images/client/kayapic.jpg";
import { FaUserGroup } from "react-icons/fa6";
import { button } from "@material-tailwind/react";
// import Tab from "@mui/material";

// Function to get YouTube video ID from URL
export function getVideoId(url) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;
  while ((match = regex.exec(url))) {
    if (match[1] === "v") {
      return match[2];
    }
  }
  return null;
}

export function getVideoThumbnailUrl(videoId) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}
const allowedPerformerSections = [
  "complete",
  "info",
  "contact",
  "social_media",
  "image_gallery",
  "video_gallery",
  "experience",
  "performance_history",
  "equipment_requirements",
];

const HoverImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s; /* Add a smooth transition */

  &:hover {
    transform: scale(1.05); /* Scale up when hovering */
  }
`;

function ViewVenueProfile() {
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(false);
  const [performerData, setPerformerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [galleryPictures, setGalleryPictures] = useState([]);
  const [deletingImageId, setDeletingImageId] = useState(null);
  const [videoGalleryThumbnails, setVideoGalleryThumbnails] = useState([]);
  const { performerId } = useParams();
  const [showImageOptionsModal, setShowImageOptionsModal] = useState(false);
  const [showImageConfirmationModal, setShowImageConfirmationModal] =
    useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showImageGalleryOptionsModal, setShowImageGalleryOptionsModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGalleryOption, setSelectedGalleryOption] = useState(null);
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const [zoomedImageUrl, setZoomedImageUrl] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [hoveredFollow, setHoveredFollow] = useState(false);
  const [clickedFollow, setClickedFollow] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState(false);
  const [clickedMessage, setClickedMessage] = useState(false);
  const [hoveredBook, setHoveredBook] = useState(false);
  const [clickedBook, setClickedBook] = useState(false);
  const [venueData, setVenueData] = useState({});
  const { profileId } = useParams();
  const selectedPerformerId = profileId || Cookies.get("profileId");
  const loggedInUserId = Cookies.get("userId");

  const [opportunities, setOpportunities] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOpportunityId, setDeleteOpportunityId] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [authorInfo, setAuthorInfo] = useState({});
  const [selectedCalloutId, setSelectedCalloutId] = useState(null);
  const [callouts, setCallouts] = useState([]);
  const [classifieds, setClassifieds] = useState([]);
  const loggedInProfileId = Cookies.get("profileId");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [showModal2, setShowModal2] = useState(false);
  const handleShowModal2 = () => setShowModal2(true);

  useEffect(() => {
    const fetchClassifieds = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `/v1/classified/published/by/profile-owner/${loggedInProfileId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          // Filter classifieds based on author_profile_id
          const filteredClassifieds = data.classifieds_published.filter(
            (classified) => classified.author_profile_id === loggedInProfileId
          );
          setClassifieds(filteredClassifieds);
        } else {
          console.error(`Failed to fetch classifieds: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching classifieds:", error);
      }
      setLoading(false);
    };

    fetchClassifieds();
  }, [loggedInUserId]);

  const handleDeleteClickClassified = (id) => {
    console.log(`Delete classified with ID: ${id}`);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmClassified = () => {
    setShowDeleteModal(false);
  };

  // const renderClassifieds = () => {
  //   if (classifieds.length === 0) {
  //     return (
  //       <div className="text-center mt-3">
  //         <p>No classifieds found.</p>
  //       </div>
  //     );
  //   }

  //   return classifieds.map((classified) => (
  //     <Card
  //       key={classified._id}
  //       className="mb-3"
  //       style={{
  //         backgroundColor: "#e8e8e8",
  //         borderRadius: "15px",
  //       }}
  //     >
  //       <Card.Body>
  //         <h4>{classified.title}</h4>
  //         <p>{classified.short_description}</p>
  //         <p>
  //           <strong>Location:</strong> {classified.location.suburb}
  //         </p>
  //         <p>
  //           <strong>Date:</strong>{" "}
  //           {classified.from_date
  //             ? new Date(
  //                 classified.from_date.year,
  //                 classified.from_date.month - 1,
  //                 classified.from_date.day
  //               )
  //                 .toISOString()
  //                 .split("T")[0]
  //             : ""}
  //         </p>
  //         <p>
  //           <strong>Time:</strong>{" "}
  //           {classified.from_time
  //             ? `${classified.from_time.hour}:${classified.from_time.minute} ${classified.from_time.am_pm}`
  //             : ""}
  //         </p>
  //       </Card.Body>
  //     </Card>
  //   ));
  // };

  useEffect(() => {
    fetchCallouts();
  }, []);

  const fetchCallouts = async () => {
    setLoading(true);
    const profileId = Cookies.get("profileId");
    try {
      const response = await axiosInstance.get(
        `/v1/call_out/published/by/profile-owner/${selectedPerformerId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setCallouts(response.data.call_outs_published);
    } catch (error) {
      console.error("Error fetching callouts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClickCallout = (calloutId) => {
    setSelectedCalloutId(calloutId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmCallout = async () => {
    setShowDeleteModal(false);
    setLoading(true);

    try {
      const response = await axiosInstance.delete(
        `/v1/call_out/delete-call-out/${selectedCalloutId}`
      );

      if (response.status === 200) {
        setCallouts((prevCallouts) =>
          prevCallouts.filter((callout) => callout._id !== selectedCalloutId)
        );
      } else {
        console.error("Error deleting callout:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting callout:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/review/received/by/profile/${selectedPerformerId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setReviews(response.data.reviews_received);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const fetchAuthorInfo = async (authorProfileId) => {
    try {
      const profileType = authorProfileId.startsWith("PROFILE-PERFORMER")
        ? "performer"
        : "venue";
      const response = await axiosInstance.get(
        `/v1/${profileType}/view-profile/${authorProfileId}/section/complete`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching author info:", error);
      return null;
    }
  };

  useEffect(() => {
    reviews.forEach((review) => {
      fetchAuthorInfo(review.author_profile_id).then((authorInfo) => {
        setAuthorInfo((prevAuthorInfo) => ({
          ...prevAuthorInfo,
          [review.author_profile_id]: authorInfo,
        }));
      });
    });
  }, [reviews]);

  const handleDeleteClick = (id) => {
    setDeleteOpportunityId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/opportunity/delete-opportunity/${deleteOpportunityId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("Opportunity deleted:", response.data);

      fetchOpportunities();
    } catch (error) {
      console.error("Error deleting opportunity:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const fetchOpportunities = async () => {
    setLoading(true);
    const profileId = Cookies.get("profileId");
    try {
      const response = await axiosInstance.get(
        `/v1/opportunity/published/by/profile-owner/${selectedPerformerId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setOpportunities(response.data.opportunities_published);
    } catch (error) {
      console.error("Error fetching opportunities:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleViewMore = () => {
  //   setShowAll(true);
  //   fetchOpportunities();
  // };

  const handleEllipsisClick = (imageId) => {
    setSelectedImageId(imageId);
    setShowImageOptionsModal(true);
  };

  const handleDeleteOption = () => {
    setShowImageOptionsModal(false);
    setShowImageConfirmationModal(true);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "deletePicture") {
      setShowConfirmationModal(true);
      setShowOptionsModal(false);
    } else {
      setShowOptionsModal(false);
      if (option === "changePassword") {
        navigate("/auth-change-password");
      }
    }
  };

  const handleCloseModal = () => {
    setShowOptionsModal(false);
    setSelectedOption(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileIdFromCookies = Cookies.get("profileId");

      // if (!profileId) {
      //   setError("venue ID not found in URL parameters.");
      //   setLoading(false);
      //   return;
      // }

      // Cookies.set("profileId", profileId);

      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${selectedPerformerId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const venueInfo = response.data.venue_info;
          const data = response.data;
          setVenueData(data);
          setError(null);
          const profilePictureUrlFromResponse =
            response.data.venue_info?.profile_picture_url;
          if (profilePictureUrlFromResponse) {
            setProfilePictureUrl(profilePictureUrlFromResponse);
            localStorage.setItem(
              "profilePictureUrl",
              profilePictureUrlFromResponse
            );
          } else {
            // setProfilePictureUrl(defaultProfilePicture);
          }

          if (venueInfo.status === "FRESH" && profilePictureUrlFromResponse) {
            setIsPublishButtonEnabled(true);
          }

          const profileOwnerId = venueInfo.user_id;

          const loggedInUserId = Cookies.get("userId");

          if (profileOwnerId === loggedInUserId) {
            Cookies.set("profileOwnerId", selectedPerformerId);
          }
        } else {
          setError(response.data.error || "Error fetching venue profile");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPerformerId]);

  const handlePublishProfile = async () => {
    try {
      const response = await axiosInstance.post(
        `/v1/venue/publish-profile`,
        {
          venue_id: selectedPerformerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Profile published successfully!");
      } else {
        alert(`API Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      console.error("Error publishing profile:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchGalleryPictures = async () => {
      setGalleryLoading(true);

      const profileIdFromCookies = Cookies.get("profileId");
      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${selectedPerformerId}/section/image_gallery`,
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        console.log("Gallery pictures response:", response.data);

        if (response.status === 200 && response.data.venue_image_gallery) {
          setGalleryPictures(response.data.venue_image_gallery.links);
        } else {
          console.error(
            "Error fetching gallery pictures: Invalid response format",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching gallery pictures:", error);
      } finally {
        setGalleryLoading(false);
      }
    };

    fetchGalleryPictures();
  }, [selectedPerformerId]);

  const handleUploadProfilePicture = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("venue_id", selectedPerformerId);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/upload-picture/profile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const imageUrl = response.data.image_url;
        setProfilePictureUrl(imageUrl);
      } else {
        console.error("Error uploading profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      const response = await axiosInstance.post(
        "/v1/venue/delete-picture/profile",
        { venue_id: selectedPerformerId },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // setProfilePictureUrl(defaultProfilePicture);
      } else {
        console.error("Error deleting profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting profile picture:", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const openFileInput = () => {
    const fileInput = document.getElementById("galleryPictures");
    fileInput.click();
  };

  const handleUploadGalleryPictures = async (event) => {
    setGalleryLoading(true);
    const files = event.target.files;
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("venue_id", selectedPerformerId);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/upload-picture/gallery",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const uploadedImages = response.data.upload_results.filter(
          (result) => result.status === "SUCCESS"
        );
        setGalleryPictures([...galleryPictures, ...uploadedImages]);
      } else {
        console.error("Error uploading gallery pictures:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading gallery pictures:", error);
    } finally {
      setGalleryLoading(false);
    }
  };

  const handleDeletePicture = async (imageId) => {
    try {
      setGalleryLoading(true);
      const response = await axiosInstance.post(
        "/v1/venue/delete-picture/gallery",
        {
          venue_id: selectedPerformerId,
          image_ids: [imageId],
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setGalleryPictures(
          galleryPictures.filter((picture) => picture.image_id !== imageId)
        );
      } else {
        console.error("Error deleting picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting picture:", error);
    } finally {
      setGalleryLoading(false);
      setShowImageConfirmationModal(false);
      setSelectedImageId(null);
      // setDeletingImageId(null);
    }
  };

  const showPreviousArrow = currentImageIndex !== null && currentImageIndex > 0;
  const showNextArrow =
    currentImageIndex !== null &&
    currentImageIndex < galleryPictures.length - 1;

  const handlePreviousClick = () => {
    if (showPreviousArrow) {
      setCurrentImageIndex(currentImageIndex - 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex - 1].image_url);
    }
  };

  const handleNextClick = () => {
    if (showNextArrow) {
      setCurrentImageIndex(currentImageIndex + 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex + 1].image_url);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const navigateToUpdateInfo = () => {
    navigate("/update-venue-info");
  };

  const addopportunity = () => {
    navigate("/add-new-oppurtunity");
  };

  const addcallout = () => {
    navigate("/add-new-callout");
  };

  // useEffect(() => {
  //   const processVideoLinks = async () => {
  //     if (!performerData?.performer_video_gallery?.links) return;

  //     const videoLinks = performerData.performer_video_gallery.links.split(",");

  //     const videoElements = videoLinks.map((videoLink) => {
  //       // Extract video ID from the link (assuming specific format)
  //       const videoId = extractVideoIdFromLink(videoLink);

  //       // Assuming YouTube videos, construct the thumbnail URL
  //       const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  //       // Create the iframe element with the video URL
  //       const iframe = document.createElement("iframe");
  //       iframe.src = videoLink;
  //       iframe.width = "200";
  //       iframe.height = "150";
  //       iframe.frameBorder = "0";
  //       iframe.allowFullScreen = true;

  //       // Create a container element to hold the thumbnail and iframe
  //       const container = document.createElement("div");
  //       container.style.display = "inline-block";
  //       container.style.marginRight = "10px";

  //       // Create an image element to display the thumbnail
  //       const thumbnailImage = document.createElement("img");
  //       thumbnailImage.src = thumbnailUrl;
  //       thumbnailImage.alt = "Video Thumbnail";
  //       thumbnailImage.style.width = "100%";
  //       thumbnailImage.style.cursor = "pointer";

  //       thumbnailImage.addEventListener("click", () => {
  //         iframe.style.display = "block";
  //       });

  //       container.appendChild(thumbnailImage);
  //       container.appendChild(iframe);
  //       iframe.style.display = "none"; // Hide iframe initially

  //       return container;
  //     });
  //   };

  //   processVideoLinks();
  // }, [performerData]);

  const extractVideoIdFromLink = (link) => {
    const youtubeMatch = link.match(
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    if (youtubeMatch) {
      return youtubeMatch[1];
    }
    console.warn("Unsupported video link format:", link);
    return "";
  };

  const handleUploadProfilePictureClick = () => {
    const fileInput = document.getElementById("profilePicture");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleViewMore = () => {
    setViewableCount(opportunities.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  const handleViewMorecallout = () => {
    setViewableCount(callouts.length);
  };

  const handleViewLesscallout = () => {
    setViewableCount(2);
  };

  const navigatetoallprofiles = () => {
    navigate("/all-profiles");
  };

  const handleOpportunityClick = (opportunityId) => {
    Cookies.set("opportunityId", opportunityId);
    console.log("Clicked opportunityId:", opportunityId);
    navigate(`/venue/opportunity/author/${opportunityId}`);
  };

  const handleMessageClick = () => {
    const profileOwnerId = Cookies.get("profileOwnerId");
    navigate(`/messaging/${profileOwnerId}`);
  };
  // const navigatetomessaging = () => {
  //   navigate("/messaging", {
  //     state: {
  //       profileId: Cookies.get("profileOwnerId"),
  //       toProfileId: Cookies.get("profileId"),
  //     },
  //   });
  // };

  const profileOwnerId = Cookies.get("profileOwnerId");

  const navigatetomessaging = () => {
    const profile_id = profileOwnerId;
    const toProfileId = Cookies.get("profileId");

    axiosInstance
      .post(
        `/v1/messaging/create_conversation/${profile_id}/${toProfileId}`,
        { title: "" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const conversation_id = response.data.conversation_id;
        navigate(`/messaging/${conversation_id}`);
      })
      .catch((error) => {
        console.error("Error creating conversation:", error);
      });
  };

  const [isFollowed, setIsFollowed] = useState(false);

  const handleFollowClick = () => {
    setIsFollowed(!isFollowed);
  };

  return (
    <>
      <style>
        {`
      body {
        background-color: #102733;
      }
    `}
      </style>
      <Navbar
        navClass="defaultscroll sticky"
        menuClass="navigation-menu nav-left"
      />
      <div
        style={{
          position: "absolute",
          right: "15%",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "row",
          gap: "10%",
          zIndex: "1",
        }}
      >
        <Button
          style={{
            borderRadius: "12px",
            fontSize: "15px",
            backgroundColor: clickedFollow ? "#418abb" : "#88a1bb",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          variant={hoveredFollow || isFollowed ? "secondary" : "primary"}
          onMouseEnter={() => setHoveredFollow(true)}
          onMouseLeave={() => setHoveredFollow(false)}
          onMouseDown={() => setHoveredFollow(true)}
          onMouseUp={handleFollowClick}
        >
          {isFollowed ? "Unfollow" : "Follow"}
        </Button>
        <Button
          style={{
            borderRadius: "12px",
            fontSize: "15px",
            backgroundColor: clickedMessage ? "#418abb" : "#88a1bb",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          variant={hoveredMessage || clickedMessage ? "secondary" : "primary"}
          onMouseEnter={() => setHoveredMessage(true)}
          onMouseLeave={() => setHoveredMessage(false)}
          onMouseDown={() => setClickedMessage(true)}
          onMouseUp={() => setClickedMessage(false)}
          onClick={navigatetomessaging}
        >
          Message
        </Button>
        <Button
          style={{
            borderRadius: "12px",
            fontSize: "15px",
            backgroundColor: clickedBook ? "#418abb" : "#88a1bb",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          variant={hoveredBook || clickedBook ? "secondary" : "primary"}
          onMouseEnter={() => setHoveredBook(true)}
          onMouseLeave={() => setHoveredBook(false)}
          onMouseDown={() => setClickedBook(true)}
          onMouseUp={() => setClickedBook(false)}
        >
          Book
        </Button>
        <Button
          style={{
            borderRadius: "100px",
            backgroundColor: "#16293a",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          onClick={navigatetoallprofiles}
        >
          <FaUserGroup />
        </Button>
      </div>
      <Card
        style={{
          position: "relative",
          backgroundColor: "#88a1bb",
          padding: "3%",
          borderRadius: "15px",
          overflow: "hidden",
          marginRight: "4%",
          marginLeft: "4%",
          marginTop: "5%",
          marginBottom: "1%",
        }}
      >
        <Row className="justify-content-between">
          <Col
            xs={12}
            md={{ span: 3, order: 1, offset: 0 }}
            // className="custom-left-position"
            className="custom-left-position text-center text-md-left"
          >
            {/* Profile picture section */}
            <div
              onClick={
                venueData.venue_info &&
                venueData.venue_info.user_id === loggedInUserId
                  ? () => setShowOptionsModal(true)
                  : null
              }
              // onClick={() => setShowOptionsModal(true)}
              style={{
                padding: "10px",
                borderRadius: "50%",
                border: "5px solid white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "inline-block",
                cursor: "pointer", // Add cursor pointer
              }}
            >
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : profilePictureUrl ? (
                <img
                  src={profilePictureUrl}
                  alt="Profile Picture"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  // src={defaultProfilePicture}
                  alt="default profile Picture"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
            {/* <div
              style={{
                padding: "10px",
                borderRadius: "50%",
                border: "5px solid white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "inline-block",
              }}
            >
              <img
                src={defaultProfilePicture}
                alt="default profile Picture"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </div> */}

            <br></br>

            <h1 className="my-custom-title" style={{ textAlign: "center" }}>
              {venueData.venue_info?.venue_name}{" "}
              <a href={"/update-venue-name"}>
                {venueData.venue_info &&
                  venueData.venue_info.user_id === loggedInUserId && (
                    <FaEdit
                      size={20}
                      style={{ marginRight: "10px", color: "#418abb" }}
                    />
                  )}
              </a>
            </h1>

            {/* <Button
              variant="outline-primary"
              onClick={navigateToUpdateInfo}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "12px",
                fontSize: "15px",
                border: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "white",
                backgroundColor: "#418abb",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#ff66c4";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#418abb";
              }}
            >
              <FaKey style={{ marginRight: "5px" }} />
              update profile info
            </Button> */}

            {/* Modal for options */}
            <Modal
              size="sm"
              centered
              show={showOptionsModal}
              onHide={handleCloseModal}
            >
              <Modal.Body className="text-center">
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>
                    <p
                      onClick={() => handleUploadProfilePictureClick()}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Upload Profile Picture
                    </p>
                    <input
                      type="file"
                      id="profilePicture"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleUploadProfilePicture}
                    />
                    <p
                      onClick={() => handleOptionClick("deletePicture")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Delete Profile Picture
                    </p>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <Button variant="primary" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Confirmation modal for deleting profile picture */}
            <Modal
              show={showConfirmationModal}
              onHide={() => setShowConfirmationModal(false)}
              size="sm"
              centered
            >
              <Modal.Header className="text-center" closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                Are you sure you want to delete your profile picture?
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  onClick={() => setShowConfirmationModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteProfilePicture}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col
            xs={12}
            md={{ span: 4, order: 2, offset: 1 }}
            style={{ textAlign: "left" }}
          >
            <div style={{ marginTop: "7%" }}>
              {/* Details Section */}
              <br></br>

              {venueData.venue_info &&
                venueData.venue_info.user_id === loggedInUserId && (
                  <Button
                    style={{
                      borderRadius: "12px",
                      fontSize: "15px",
                      backgroundColor: isPublishButtonEnabled
                        ? "#418abb"
                        : "#88a1bb",
                      border: "none",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      color: "white",
                      cursor: isPublishButtonEnabled
                        ? "pointer"
                        : "not-allowed",
                    }}
                    disabled={!isPublishButtonEnabled}
                    onClick={handlePublishProfile}
                  >
                    Publish
                  </Button>
                )}
              <br></br>
              <br></br>

              <p>
                {/* Mobile Number */}
                {venueData && venueData.venue_contact ? (
                  <>
                    <FaPhone size={20} style={{ marginRight: "10px" }} />
                    {venueData.venue_contact.display_phone.phone_number}
                    <button
                      style={{
                        backgroundColor: "#418abb",
                        cursor: "pointer",
                      }}
                      onClick={handleShowModal2}
                      className="publish-btn"
                    >
                      Verify contact number
                    </button>
                  </>
                ) : null}{" "}
                <VerifyPhoneNumberModal
                  show={showModal2}
                  handleClose={handleCloseModal}
                />
                <br />
                <br></br>
                {venueData && venueData.venue_contact ? (
                  <>
                    <FaEnvelope size={20} style={{ marginRight: "10px" }} />
                    {venueData.venue_contact.display_email.email}
                    <span style={{ marginRight: "10px" }}></span>
                    <button
                      style={{
                        backgroundColor: "#418abb",
                        cursor: "pointer",
                      }}
                      onClick={handleShowModal}
                      className="publish-btn"
                    >
                      Verify Email
                    </button>
                  </>
                ) : null}
                <VerifyEmailModal
                  show={showModal}
                  handleClose={handleCloseModal}
                />
                <a href={"/update-venue-contact"}>
                  {venueData.venue_info &&
                    venueData.venue_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "black" }}
                      />
                    )}
                </a>
              </p>
              <p>
                {/* <FaFacebook size={28} style={{ marginRight: "10px" }} /> */}
                {venueData.venue_social_media?.FACEBOOK && (
                  <a href={venueData.venue_social_media.FACEBOOK}>
                    <FaFacebook size={28} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {/* {performerData.performer_social_media?.FACEBOOK && (
                      <a href={performerData.performer_social_media.FACEBOOK}>
                        <FaInstagram
                          size={28}
                          style={{ marginRight: "10px", color: "#e44b61" }}
                        />
                      </a>
                    )}
                    {performerData.performer_social_media?.FACEBOOK && (
                      <a href={performerData.performer_social_media.FACEBOOK}>
                        <FaLinkedin size={28} style={{ marginRight: "10px" }} />
                      </a>
                    )} */}
                {venueData.venue_social_media?.INSTAGRAM && (
                  <a href={venueData.venue_social_media.INSTAGRAM}>
                    <FaInstagram
                      size={20}
                      style={{ marginRight: "10px", color: "#e44b61" }}
                    />
                  </a>
                )}
                {venueData.venue_social_media?.LINKEDIN && (
                  <a href={venueData.venue_social_media.LINKEDIN}>
                    <FaLinkedin size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {venueData.venue_social_media?.SPOTIFY && (
                  <a href={venueData.venue_social_media.SPOTIFY}>
                    <FaSpotify size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {venueData.venue_social_media?.TIKTOK && (
                  <a href={venueData.venue_social_media.TIKTOK}>
                    <FaTiktok size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {venueData.venue_social_media?.YOUTUBE && (
                  <a href={venueData.venue_social_media.YOUTUBE}>
                    <FaYoutube size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                <span style={{ marginRight: "10px" }}></span>
                <a href={"/update-venue-socialmedia"}>
                  {venueData.venue_info &&
                    venueData.venue_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "black" }}
                      />
                    )}
                </a>
              </p>
              {/* <Button
                variant="outline-primary"
                // onClick={navigateToChangePassword}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  fontSize: "15px",
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "white",
                  backgroundColor: "#418abb",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#ff66c4";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#418abb";
                }}
              >
                <FaKey style={{ marginRight: "5px" }} />
                Change Password
              </Button> */}
            </div>
          </Col>
          <Col
            xs={12}
            md={{ span: 4, order: 3, offset: 0 }}
            className="text-center text-md-right"
          >
            {/* Calendar Section */}
            <div
              style={{ textAlign: "left", marginTop: "3%", marginRight: "10%" }}
            >
              <div>
                <h4 style={{ color: "black" }}>Public Info</h4>

                <p>
                  venue type:{" "}
                  {venueData.venue_info?.venue_types
                    ? venueData.venue_info?.venue_types.join(", ")
                    : "Not specified"}
                  <span style={{ marginRight: "10px" }}></span>
                  <a href={"/update-venue-type"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>

                {/* <Card
                  className="shadow-lg"
                  style={{ borderRadius: "15px", marginBottom: "-2%" }}
                > */}

                <p>
                  Genres:{" "}
                  {venueData.venue_info?.venue_genres
                    ? venueData.venue_info?.venue_genres.join(", ")
                    : "Not specified"}
                  <span style={{ marginRight: "10px" }}></span>
                  <a href={"/update-venue-genre"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>

                {/* <Card
                  className="shadow-lg"
                  style={{ borderRadius: "15px", marginBottom: "-2%" }}
                > */}

                <p>
                  Price point range: AUD{" "}
                  {venueData.venue_info?.price_point_value}
                  <a href={"/update-venue-abn"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>
                <p>
                  Business Name: {venueData.venue_info?.business_name}
                  <a href={"/update-venue-business"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>
                <p>
                  abn Number: {venueData.venue_info?.abn_number}
                  <a href={"/update-venue-abn"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>

                <p>
                  location: {venueData.venue_contact?.location.address}
                  <a href={"/update-venue-location"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FaEdit
                          size={20}
                          style={{ marginRight: "10px", color: "#418abb" }}
                        />
                      )}
                  </a>
                </p>
              </div>
              {/* <MyCalendar /> */}
              {/* <Calendar
                localizer={localizer}
                events={[]}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: "260px",
                  width: "100%",
                  border: "1px solid #000",
                  borderRadius: "10px",
                  backgroundColor: "#000",
                  color: "#fff",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  fontSize: "0.9vw",
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: "#418abb",
                    color: "white",
                    borderRadius: "0px",
                    border: "none",
                  };

                  return {
                    style: newStyle,
                  };
                }}
                dayPropGetter={() => ({
                  style: {
                    backgroundColor: "transparent",
                  },
                })}
              /> */}
            </div>
          </Col>
        </Row>
      </Card>

      {/* <Row>
        <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <Card className="shadow-lg" style={{ borderRadius: "25px" }}>
            <Card.Body>
              <div>
                <h5>Bio</h5>
                <p>{performerData.performer_experience?.brief_bio} </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row> */}

      <div style={{ paddingLeft: "3.6%", paddingRight: "3.6%" }}>
        <div className="container-fluid">
          <Row>
            <Col xs={12} md={5} className="order-md-1">
              {/* <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", backgroundColor: "#e2e2e2" }}
              >
                <div style={{ padding: "5%" }}>
                  <div
                    className="text-center"
                    
                  >
                    <h4 style={{ textAlign: "left", color: "black" }}>
                      Public Info
                    </h4>

                   
                    <p>Bar, Beer, Garden, Chill</p>

                    
                    <p>Blues, Chill, Classic</p>


                    <p>Price point range: </p>
                  </div>
                </div>
              </Card> */}
              <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", marginTop: "1%" }}
              >
                <div style={{ padding: "5%" }}>
                  <h4 style={{ textAlign: "left", color: "black" }}>Reviews</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="mb-3"
                        style={{
                          backgroundColor: "#e8e8e8",
                          borderRadius: "15px",
                        }}
                      >
                        <Card.Body className="d-flex align-items-center">
                          <img
                            // src={jezzypic}
                            alt="Reviewer"
                            className="rounded-circle mr-3"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "2%",
                            }}
                          />
                          <div>
                            <h6>Jezzy Nicky</h6>
                            <div className="d-flex align-items-center">
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9734;</span>
                            </div>
                            <p>
                              "The ambiance was electric, and the audience was
                              so engaged. The sound system was top-notch,
                              allowing us to deliver our music with clarity and
                              impact. Definitely one of the best venues we've
                              played at!"
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="mb-3"
                        style={{
                          backgroundColor: "#e8e8e8",
                          borderRadius: "15px",
                        }}
                      >
                        <Card.Body className="d-flex align-items-center">
                          <img
                            // src={kayapic}
                            alt="Reviewer"
                            className="rounded-circle mr-3"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "2%",
                            }}
                          />
                          <div>
                            <h6>Chrissy Costanza</h6>
                            <div className="d-flex align-items-center">
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9734;</span>
                              <span className="text-warning">&#9734;</span>
                            </div>
                            <p>
                              "The acoustics were superb, and the stage setup
                              was perfect for our band. The staff was friendly
                              and accommodating, making sure everything ran
                              smoothly. Can't wait to come back!"
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </Card>
              {/* <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", marginTop: "3%" }}
              >
                <div style={{ padding: "5%" }}>
                  <h4 style={{ textAlign: "left", color: "black" }}>Reviews</h4>
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="row">
                      {reviews.map((review, index) => (
                        <div key={index} className="col-md-12 mb-3">
                          <Card
                            style={{
                              backgroundColor: "#e8e8e8",
                              borderRadius: "15px",
                            }}
                          >
                            <Card.Body className="d-flex align-items-center">
                              <div className="col-md-2">
                                <img
                                  src={
                                    review.author_profile_id.startsWith(
                                      "PROFILE-PERFORMER"
                                    )
                                      ? authorInfo[review.author_profile_id]
                                          ?.performer_info.profile_picture_url
                                      : authorInfo[review.author_profile_id]
                                          ?.venue_info.profile_picture_url ||
                                        "https://via.placeholder.com/150"
                                  }
                                  alt="Author"
                                  className="rounded-circle mr-3"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginTop: "-5%",
                                  }}
                                />
                              </div>
                              <div className="col-md-10">
                                <h6>
                                  {review.author_profile_id.startsWith(
                                    "PROFILE-PERFORMER"
                                  )
                                    ? `${
                                        authorInfo[review.author_profile_id]
                                          ?.performer_info.first_name
                                      } ${
                                        authorInfo[review.author_profile_id]
                                          ?.performer_info.last_name
                                      }`
                                    : authorInfo[review.author_profile_id]
                                        ?.venue_info.venue_name ||
                                      "Anonymous Reviewer"}
                                </h6>
                                <div className="d-flex align-items-center">
                                  {Array.from({ length: review.stars }).map(
                                    (_, index) => (
                                      <span
                                        key={index}
                                        className="text-warning"
                                      >
                                        &#9733;
                                      </span>
                                    )
                                  )}
                                </div>
                                <p>{review.review}</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Card> */}
            </Col>

            <Col xs={12} md={7} className="order-md-2">
              <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", marginTop: "0.5%" }}
              >
                <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                  <Tab.Container defaultActiveKey="calendar">
                    <Row className="justify-content-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="posts"
                            style={{ color: "#418abb" }}
                          >
                            Posts
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="calendar"
                            style={{ color: "#418abb" }}
                          >
                            Calendar
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            eventKey="experience"
                            style={{ color: "#418abb" }}
                          >
                            Experience
                          </Nav.Link>
                        </Nav.Item> */}

                        <Nav.Item>
                          <Nav.Link
                            eventKey="media"
                            style={{ color: "#418abb" }}
                          >
                            Media
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Row>
                    <Row className="justify-content-center">
                      <Tab.Content>
                        <Tab.Pane eventKey="posts">
                          <br></br>
                          <div className="container">
                            <div className="row">
                              {/* <div className="col">
                                  <div className="d-flex align-items-left">
                                    
                                    <strong>
                                      <h4>Opportunities</h4>
                                    </strong>
                                  </div>
                                  <br></br>
                                  <center>
                                    {venueData.venue_info &&
                                      venueData.venue_info.user_id ===
                                        loggedInUserId && (
                                        <Button
                                          variant="outline-primary"
                                          onClick={addopportunity}
                                          style={{
                                            display: "flex",
                                            alignSelf: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "12px",
                                            fontSize: "15px",
                                            border: "none",
                                            boxShadow:
                                              "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            color: "white",
                                            backgroundColor: "#418abb",
                                            transition: "background-color 0.3s",
                                          }}
                                          onMouseEnter={(e) => {
                                            e.target.style.backgroundColor =
                                              "#ff66c4";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.target.style.backgroundColor =
                                              "#418abb";
                                          }}
                                        >
                                          <FaUpload
                                            style={{ marginRight: "5px" }}
                                          />
                                          Add new Opportunity
                                        </Button>
                                      )}
                                  </center>
                                  <br></br>
                                  {loading ? (
                                    <div className="text-center mt-5">
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <span className="ms-2">
                                        Loading Opportunities...
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="mb-3">
                                        {opportunities
                                          .slice(
                                            0,
                                            showAll
                                              ? opportunities.length
                                              : viewableCount
                                          )
                                          .map((opportunity) => (
                                            <Card
                                              key={opportunity._id}
                                              className="mb-3"
                                              onClick={() =>
                                                handleOpportunityClick(
                                                  opportunity._id
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#e8e8e8",
                                                borderRadius: "15px",
                                              }}
                                            >
                                              <Card.Body>
                                                <h4
                                                  style={{ fontWeight: "bold" }}
                                                  className="card-text"
                                                >
                                                  {opportunity.title}
                                                </h4>
                                                <p className="card-text">
                                                  {
                                                    opportunity.short_description
                                                  }
                                                </p>
                                                <p className="card-text">
                                                  <strong>
                                                    Venue: Serenade Square -
                                                    17/08/2023
                                                  </strong>
                                                </p>
                                                <p className="card-text">
                                                  <strong>
                                                    Start Time: 20:00
                                                  </strong>
                                                </p>
                                                {venueData.venue_info &&
                                                  venueData.venue_info
                                                    .user_id ===
                                                    loggedInUserId && (
                                                    <Button
                                                      style={{
                                                        backgroundColor:
                                                          "#151a33",
                                                      }}
                                                      onClick={() =>
                                                        handleDeleteClick(
                                                          opportunity._id
                                                        )
                                                      }
                                                    >
                                                      <BsTrash />
                                                    </Button>
                                                  )}
                                              </Card.Body>
                                              <Modal
                                                show={showDeleteModal}
                                                onHide={() =>
                                                  setShowDeleteModal(false)
                                                }
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    Confirm Deletion
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  Are you sure you want to
                                                  delete this opportunity?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="primary"
                                                    onClick={() =>
                                                      setShowDeleteModal(false)
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    variant="danger"
                                                    onClick={
                                                      handleDeleteConfirm
                                                    }
                                                  >
                                                    Delete
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </Card>
                                          ))}
                                      </div>

                                      {viewableCount < opportunities.length && ( // Show "View More" button only if not all shown
                                        <center>
                                          <Button
                                            variant="link"
                                            onClick={handleViewMore}
                                            style={{ color: "#418abb" }}
                                          >
                                            View More (
                                            {opportunities.length -
                                              viewableCount}{" "}
                                            more)
                                          </Button>
                                        </center>
                                      )}
                                      {viewableCount === opportunities.length &&
                                        opportunities.length > 2 && ( // Show "View Less" button when all shown and more than 2 exist
                                          <center>
                                            <Button
                                              variant="link"
                                              onClick={handleViewLess}
                                              style={{ color: "#418abb" }}
                                            >
                                              View Less
                                            </Button>
                                          </center>
                                        )}
                                    </>
                                  )}
                                </div> */}
                              <div className="d-flex align-items-left">
                                {venueData.venue_info &&
                                venueData.venue_info.user_id ===
                                  loggedInUserId ? (
                                  <ViewOpportunities />
                                ) : (
                                  <ViewOpportunitiesbyOthers />
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="d-flex align-items-left">
                                {venueData.venue_info &&
                                venueData.venue_info.user_id ===
                                  loggedInUserId ? (
                                  <ViewCalloutsbySelf />
                                ) : (
                                  <ViewCalloutsbyOthers />
                                )}
                              </div>
                            </div>

                            <div className="d-flex align-items-left">
                              {venueData.venue_info &&
                              venueData.venue_info.user_id ===
                                loggedInUserId ? (
                                <ViewVenueClassifieds />
                              ) : (
                                <ViewVenueClassifiedsbyOthers />
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="calendar">
                          <div style={{ padding: "2%", marginTop: "3%" }}>
                            <VenueCalendarSelfView />
                          </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="experience">
                          <br></br>
                          <div
                            style={{ paddingLeft: "10%", paddingBottom: "10%" }}
                          >
                            <h4>
                              <Button
                                variant="outline-secondary"
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                  color: "#418abb",
                                  borderRadius: "25px",
                                }}
                                as={Link}
                                to="/update-performer-experience"
                              >
                                <FaEdit size={20} />
                              </Button>
                              Experience
                            </h4>
                            <p>
                              Education:{" "}
                              {performerData.performer_experience?.education}
                              <br></br>
                              Notable Achievements:{" "}
                              {
                                performerData.performer_experience
                                  ?.notable_achievements
                              }
                              <br></br>
                              Skills:{" "}
                              {performerData.performer_experience?.skills}{" "}
                            </p>

                            <br></br>

                            <div>
                              <h4>
                                <Button
                                  variant="outline-secondary"
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "#418abb",
                                    borderRadius: "25px",
                                  }}
                                  as={Link}
                                  to="/update-performer-history"
                                >
                                  <FaEdit size={20} />
                                </Button>
                                Performance History
                              </h4>
                              <p>
                                collaborations:{" "}
                                {
                                  performerData.performer_performance_history
                                    ?.collaborations
                                }{" "}
                                <br></br>
                                Events and festivals:{" "}
                                {
                                  performerData.performer_performance_history
                                    ?.events_and_festivals
                                }{" "}
                                <br></br>
                                Past venues:{" "}
                                {
                                  performerData.performer_performance_history
                                    ?.past_venues
                                }{" "}
                                <br></br>
                                Recidencies:{" "}
                                {
                                  performerData.performer_performance_history
                                    ?.residencies
                                }
                              </p>
                            </div>
                          </div>
                        </Tab.Pane> */}

                        <Tab.Pane eventKey="media">
                          <br />
                          <Tab.Container defaultActiveKey="video_gallery">
                            <Row className="justify-content-center">
                              <Nav variant="tabs">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="video_gallery"
                                    style={{ color: "#418abb" }}
                                  >
                                    Video Gallery
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="image_gallery"
                                    style={{ color: "#418abb" }}
                                  >
                                    Image Gallery
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Row>
                            <Row className="justify-content-center">
                              <Tab.Content>
                                <Tab.Pane eventKey="video_gallery">
                                  <br />
                                  <div
                                    style={{
                                      paddingLeft: "10%",
                                      paddingBottom: "10%",
                                    }}
                                  >
                                    <h4>
                                      {venueData.venue_info &&
                                        venueData.venue_info.user_id ===
                                          loggedInUserId && (
                                          <Button
                                            variant="outline-secondary"
                                            style={{
                                              backgroundColor: "white",
                                              border: "none",
                                              color: "#418abb",
                                              borderRadius: "25px",
                                            }}
                                            as={Link}
                                            to="/update-venue-videoGallery"
                                          >
                                            <FaEdit size={20} />
                                          </Button>
                                        )}
                                      Video Gallery
                                    </h4>
                                    {venueData?.venue_video_gallery?.links && (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "repeat(auto-fit, minmax(200px, 1fr))",
                                          gap: "10px",
                                        }}
                                      >
                                        {Array.isArray(
                                          venueData.venue_video_gallery.links
                                        )
                                          ? venueData.venue_video_gallery.links.map(
                                              (videoLink, index) => {
                                                const videoId =
                                                  extractVideoIdFromLink(
                                                    videoLink
                                                  );
                                                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                                                return (
                                                  <div key={videoLink}>
                                                    <iframe
                                                      src={videoLink}
                                                      width="100%"
                                                      height="150"
                                                      frameBorder="0"
                                                      allowFullScreen
                                                    ></iframe>
                                                    <br />
                                                  </div>
                                                );
                                              }
                                            )
                                          : venueData.venue_video_gallery.links
                                              .split(",")
                                              .map((videoLink, index) => {
                                                const videoId =
                                                  extractVideoIdFromLink(
                                                    videoLink
                                                  );
                                                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                                                return (
                                                  <div key={videoLink}>
                                                    <iframe
                                                      src={videoLink}
                                                      width="100%"
                                                      height="150"
                                                      frameBorder="0"
                                                      allowFullScreen
                                                    ></iframe>
                                                    <br />
                                                  </div>
                                                );
                                              })}
                                      </div>
                                    )}
                                    {!venueData?.venue_video_gallery?.links && (
                                      <p>No videos found.</p>
                                    )}
                                  </div>
                                  {/* <p>
                                      Links:{" "}
                                      {venueData.venue_video_gallery?.links}
                                    </p> */}
                                </Tab.Pane>

                                <Tab.Pane eventKey="image_gallery">
                                  <br />
                                  <div
                                    style={{
                                      textAlign: "center",
                                      paddingBottom: "10%",
                                    }}
                                  >
                                    <h4>Image Gallery</h4>
                                    <br></br>
                                    <div>
                                      <center>
                                        {venueData.venue_info &&
                                          venueData.venue_info.user_id ===
                                            loggedInUserId && (
                                            <Button
                                              variant="outline-primary"
                                              onClick={openFileInput}
                                              style={{
                                                display: "flex",
                                                alignSelf: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "12px",
                                                fontSize: "15px",
                                                border: "none",
                                                boxShadow:
                                                  "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                color: "white",
                                                backgroundColor: "#418abb",
                                                transition:
                                                  "background-color 0.3s",
                                              }}
                                              onMouseEnter={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#ff66c4";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#418abb";
                                              }}
                                            >
                                              <FaUpload
                                                style={{ marginRight: "5px" }}
                                              />
                                              Upload Gallery Picture
                                            </Button>
                                          )}
                                      </center>
                                      <br></br>
                                      {galleryLoading && (
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}

                                      <input
                                        type="file"
                                        id="galleryPictures"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        multiple
                                        onChange={handleUploadGalleryPictures}
                                      />
                                    </div>
                                    <div>
                                      <br></br>
                                      <br></br>
                                      <div
                                        style={{
                                          paddingLeft: "2%",
                                          paddingRight: "2%",
                                        }}
                                      >
                                        <Masonry
                                          breakpointCols={breakpointColumnsObj}
                                          className="my-masonry-grid"
                                          columnClassName="my-masonry-grid_column"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: "3%",
                                          }}
                                        >
                                          {galleryPictures.map(
                                            (picture, index) => (
                                              <div
                                                key={picture.image_id}
                                                style={{
                                                  position: "relative",
                                                  marginBottom: "20px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <HoverImage
                                                    src={picture.image_url}
                                                    alt={picture.image}
                                                    style={{
                                                      maxWidth: "100%",
                                                      height: "auto",
                                                      display: "block",
                                                      borderRadius: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setCurrentImageIndex(
                                                        index
                                                      );
                                                      setZoomedImageUrl(
                                                        picture.image_url
                                                      );
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      top: "5px",
                                                      right: "5px",
                                                      cursor: "pointer",
                                                      background:
                                                        "rgba(255, 255, 255, 0.5)",
                                                      borderRadius: "20%",
                                                    }}
                                                    onClick={() =>
                                                      handleEllipsisClick(
                                                        picture.image_id
                                                      )
                                                    }
                                                  >
                                                    {venueData.venue_info &&
                                                      venueData.venue_info
                                                        .user_id ===
                                                        loggedInUserId && (
                                                        <FaEllipsisV
                                                          size={20}
                                                        />
                                                      )}
                                                  </div>
                                                </div>
                                                {/* Options Modal */}
                                                <Modal
                                                  show={showImageOptionsModal}
                                                  onHide={() =>
                                                    setShowImageOptionsModal(
                                                      false
                                                    )
                                                  }
                                                  centered
                                                  size="sm"
                                                  backdropStyle={{
                                                    backgroundColor:
                                                      "rgba(0, 0, 0, 0.5)",
                                                  }}
                                                >
                                                  {/* <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      Options
                                                    </Modal.Title>
                                                  </Modal.Header> */}
                                                  <Modal.Body className="text-center">
                                                    <p
                                                      onClick={
                                                        handleDeleteOption
                                                      }
                                                      className="text-dark fw-medium"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      Delete Image
                                                    </p>
                                                    <p>Hide Image</p>
                                                  </Modal.Body>
                                                  <Modal.Footer className="d-flex justify-content-center align-items-center">
                                                    <Button
                                                      className="text-center"
                                                      variant="secondary"
                                                      onClick={() =>
                                                        setShowImageOptionsModal(
                                                          false
                                                        )
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>

                                                <Modal
                                                  show={
                                                    showImageConfirmationModal
                                                  }
                                                  onHide={() =>
                                                    setShowImageConfirmationModal(
                                                      false
                                                    )
                                                  }
                                                  backdropStyle={{
                                                    opacity: 0.05,
                                                  }}
                                                  centered
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      Confirm Deletion
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Are you sure you want to
                                                    delete this image?
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={() =>
                                                        setShowImageConfirmationModal(
                                                          false
                                                        )
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      variant="danger"
                                                      onClick={() =>
                                                        handleDeletePicture(
                                                          selectedImageId
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>

                                                <Modal
                                                  show={
                                                    currentImageIndex === index
                                                  }
                                                  onHide={() =>
                                                    setCurrentImageIndex(null)
                                                  }
                                                  size="xl"
                                                >
                                                  <Modal.Body>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <img
                                                        src={zoomedImageUrl}
                                                        alt="Zoomed"
                                                        style={{
                                                          width: "100%",
                                                          height: "auto",
                                                          borderRadius: "8px",
                                                          objectFit: "contain",
                                                          maxHeight: "80vh",
                                                          maxWidth: "80vw",
                                                        }}
                                                      />
                                                      <div
                                                        className="close-icon"
                                                        onClick={() =>
                                                          setCurrentImageIndex(
                                                            null
                                                          )
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "10px",
                                                          left: "10px",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "24px",
                                                        }}
                                                      >
                                                        &#x2715;{" "}
                                                      </div>
                                                    </div>
                                                    {showPreviousArrow && (
                                                      <div
                                                        className="arrow"
                                                        onClick={
                                                          handlePreviousClick
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "50%",
                                                          left: "10px",
                                                          transform:
                                                            "translateY(-50%)",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "36px",
                                                        }}
                                                      >
                                                        <FaAngleLeft />
                                                      </div>
                                                    )}

                                                    {showNextArrow && (
                                                      <div
                                                        className="arrow"
                                                        onClick={
                                                          handleNextClick
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "50%",
                                                          right: "10px",
                                                          transform:
                                                            "translateY(-50%)",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "36px",
                                                        }}
                                                      >
                                                        <FaAngleRight />
                                                      </div>
                                                    )}
                                                  </Modal.Body>
                                                </Modal>
                                              </div>
                                            )
                                          )}
                                        </Masonry>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Row>
                          </Tab.Container>
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ViewVenueProfile;
