import styled from "styled-components";
// import OpportunityImage from "../assect/images/OpportunityImage2.jpg";
// import payment1 from "../assect/images/payment/visa.png";
// import payment2 from "../assect/images/payment/card.png";
// import payment3 from "../assect/images/payment/discover.png";
// import payment4 from "../assect/images/payment/4.svg";
// import profileImage from "../../assect/images/client/02.jpg";
import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import App from "../messaging/messaging";
import { Modal, Spinner } from "react-bootstrap";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 10px;
  margin-right: 70px;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  margin-left: 70px;
  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const NewCalloutPerformerView = ({ calloutId, profileId, onDeleteSuccess }) => {
  const [callout, setCallout] = useState(null);
  const accessToken = Cookies.get("accessToken");
  // const { calloutId } = useParams();
  const selectedOpportunityId = calloutId; //|| Cookies.get("calloutId");
  const [profileOwnerUserId, setProfileOwnerUserId] = useState(null);
  // const profileOwnerId = Cookies.get("profileOwnerId");
  const profileOwnerId = profileId; //Cookies.get("profileId");
  const profileOwnerId2 = Cookies.get("profileOwnerId");
  const loggedInUserId = Cookies.get("userId");
  const [loading, setLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Fetching callout with ID:", selectedOpportunityId);
    const fetchCallout = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/call_out/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCallout(response.data.call_out);
      } catch (error) {
        console.error("Error fetching callout:", error);
      }
    };

    fetchCallout();
  }, [selectedOpportunityId, accessToken]);

  useEffect(() => {
    const fetchProfileOwnerUserId = async () => {
      try {
        let response;
        if (profileOwnerId.startsWith("PROFILE-PERFORMER")) {
          response = await axiosInstance.get(
            `/v1/performer/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.performer_info.user_id);
          }
        } else if (profileOwnerId.startsWith("PROFILE-VENUE")) {
          response = await axiosInstance.get(
            `/v1/venue/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.venue_info.user_id);
          }
        }
      } catch (error) {
        console.error("Error fetching profile owner user ID:", error);
      }
    };

    fetchProfileOwnerUserId();
  }, [profileOwnerId, accessToken]);

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  const handleApplyButtonClick = () => {
    const toProfileId = callout.author_profile_id;
    console.log("opp auther profile id: " + toProfileId);
    Cookies.set("toProfileId", toProfileId);
    // Cookies.set("calloutId", calloutId);
    navigate("/messaging");
  };

  const [showModal3, setShowModal3] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const handleClose3 = () => setShowModal3(false);
  const handleShow = () => setShowModal3(true);

  useEffect(() => {
    if (conversationId) {
      handleShow();
    }
  }, [conversationId]);

  const handleSendMessage = async (toProfileId) => {
    setLoading(true);
    try {
      console.log(callout.title);
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_call_out/${profileOwnerId2}/${toProfileId}/${selectedOpportunityId}`,
        { title: callout.title },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log(response);

        const { call_out_id } = response.data;
        const conversation_id =
          response.data.exists_conversation_id ||
          response.data.conversation_id ||
          response.data.new_conversation_id;
        console.log("Conversation ID:", conversation_id);
        setConversationId(conversation_id);
        handleShow();
        setLoading(false);

        // const titleResponse = await axiosInstance.get(
        //   `/v1/call_out/view/${call_out_id}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${Cookies.get("accessToken")}`,
        //     },
        //   }
        // );

        // if (titleResponse.status === 200) {
        //   const title = titleResponse.data.call_out.title;
        //   navigate(`/messaging/${title}`);

        //   console.log("Title:", title);
        // } else {
        //   console.error(
        //     "Error fetching callout title:",
        //     titleResponse.statusText
        //   );
        // }
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
      setLoading(false);
    }
  };

  const handleDeleteClick = (calloutId) => {
    setIsLoadingDelete(true);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this callout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeletecallout(calloutId);
      }
    });
    setIsLoadingDelete(false);
  };

  const handleDeletecallout = async (calloutId) => {
    setIsLoadingDelete(true);
    const url = `/v1/call_out/delete-call-out/${calloutId}`;

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire(
          "Deleted!",
          "Your callout has been successfully deleted.",
          "success"
        );
        onDeleteSuccess();

        // if (profileOwnerId2.startsWith("PROFILE-PERFORMER")) {
        //   navigate(`/performer/${profileOwnerId2}`);
        // } else {
        //   navigate(`/venue/${profileOwnerId2}`);
        // }
        // navigate(-1);
      } else {
        Swal.fire(
          "Error!",
          `Error deleting callout: ${response.status}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire(
        "Error!",
        "An error occurred during the deletion of the callout.",
        "error"
      );
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      {callout && (
        <>
          <LeftColumn>
            {/* <CategoryTag>Open</CategoryTag> */}
            <Title>{callout.title}</Title>
            {/* <InfoRow>
          <Info>
            <ProfileImage src={profileImage} alt="Profile" />
          </Info>
          <Info>
            Venue:
            <br />
            <strong> Harmony Hall</strong>
          </Info>
          <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info>
        </InfoRow> */}

            {/* <Tabs>
          <Tab
            active={activeTab === "opportunityInfo"}
            onClick={() => setActiveTab("opportunityInfo")}
          >
            Opportunity Info
          </Tab>
          <Tab
            active={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Reviews
          </Tab>
        </Tabs> */}
            <Content>
              <About>
                <h3>Description</h3>
                <p>{callout.descriptions}</p>
                {/* <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox> */}
              </About>
              {/* {activeTab === "opportunityInfo" && (
            <>
              <About>
                <h2>Description</h2>
                <p>
                  An intimate evening of acoustic performances by
                  singer-songwriters and musicians, held in a cozy coffeehouse
                  or café. The stripped-down arrangements and heartfelt lyrics
                  create a warm and inviting atmosphere, perfect for enjoying a
                  cup of coffee and discovering new talent. An intimate evening
                  of acoustic performances by singer-songwriters and musicians,
                  held in a cozy coffeehouse or café. The stripped-down
                  arrangements and heartfelt lyrics create a warm and inviting
                  atmosphere, perfect for enjoying a cup of coffee and
                  discovering new talent.
                </p>
                <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox>
              </About>
            </>
          )}
          {activeTab === "media" && <div>Reviews content goes here...</div>} */}
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {/* <EnrollButton>I am interested</EnrollButton> */}
              <center>
                {profileOwnerUserId !== loggedInUserId && (
                  <button
                    className="oppbutton2"
                    style={{
                      cursor: loading ? "not-allowed" : "pointer",
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",
                      opacity: loading ? 0.6 : 1,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={() =>
                      handleSendMessage(Cookies.get("toProfileIdFirst"))
                    }
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only"> Loading...</span>
                      </>
                    ) : (
                      "MESSAGE"
                    )}
                  </button>
                )}
                <Modal show={showModal3} onHide={handleClose3} size="xl">
                  <Modal.Header>
                    <Modal.Title>Messaging</Modal.Title>
                    <button
                      style={{
                        position: "absolute",
                        // top: "10px",
                        right: "10px",
                        border: "none",
                        background: "transparent",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      onClick={handleClose3}
                    >
                      &times;
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    {/* {conversationId && <App conversation_id={conversationId} />} */}
                    {conversationId ? (
                      <App conversation_id={conversationId} />
                    ) : (
                      "Loading..."
                    )}
                  </Modal.Body>
                </Modal>
                {profileOwnerUserId === loggedInUserId && (
                  <button
                    className="oppbutton2"
                    style={{
                      cursor: isLoadingDelete ? "not-allowed" : "pointer",
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",                      
                      opacity: isLoadingDelete ? 0.6 : 1,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={() => handleDeleteClick(callout._id)}
                    disabled={isLoadingDelete || isDeleted}
                  >
                    {isLoadingDelete ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "DELETE"
                    )}
                  </button>
                )}
              </center>

              <br></br>
              <Stats>
                <DateContainer>
                  {/* <span>
                    From: {"  "}
                    <DateText>
                      {formatDate(callout.from_date)}
                      {"   "}
                      {formatTime(callout.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(callout.to_date)}
                      {"   "}
                      {formatTime(callout.to_time)}
                    </DateText>
                  </span> */}
                  <span style={{ marginBottom: "18px" }}>
                    Price:{" "}
                    <DateText>
                      AUD {callout.price_point_value_from}-
                      {callout.price_point_value_to}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    Location:{" "}
                    <DateText>
                      {callout.location.suburb}, {callout.location.zipcode}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(callout.from_date)}
                      {"   "}
                      {formatTime(callout.from_time)}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    To: {"  "}
                    <DateText>
                      {formatDate(callout.to_date)}
                      {"   "}
                      {formatTime(callout.to_time)}
                    </DateText>
                  </span>
                  <span>
                    Status: <DateText>{callout.status}</DateText>
                  </span>
                </DateContainer>
              </Stats>

              <TagContainer>
                <h6>Type:</h6>
                <TagBox>{callout.call_out_type}</TagBox>
              </TagContainer>

              <TagContainer>
                <h6>Category:</h6>
                <TagBox>{callout.call_out_category}</TagBox>
              </TagContainer>

              <TagContainer>
                <h6>Sub Category:</h6>
                <TagBox>{callout.call_out_sub_category}</TagBox>
              </TagContainer>
            </SideCard>
          </RightColumn>
        </>
      )}
    </Container>
  );
};

export default NewCalloutPerformerView;
