// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .date-time-form .form-group {
    margin-bottom: 1rem;
  }
  
  .submit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/opportunities/OpportunityDateTime.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".date-time-form {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n  \n  .date-time-form .form-group {\n    margin-bottom: 1rem;\n  }\n  \n  .submit-button-container {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
