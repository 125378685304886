import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import descriptionImage from "../../assect/images/Landing_Image1.jpg";
import "./landing.css";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoaded(true), 1000); // Adjust delay if needed
    return () => clearTimeout(timeout);
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/auth-signup");
  };

  const handleHover = (e) => {
    e.target.classList.toggle("hovered");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    // Handle subscription logic here
    console.log("Subscribed with email: ", email);
  };

  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        position: "relative",
        backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        animation: "background-fadein 2s ease-in-out",
      }}
    >
      <Row
        className="justify-content-end"
        style={{
          padding: "50px 20px",
          backgroundColor: "#102733",
          color: "#fff",
        }}
      >
        <Col
          className="buttonsContainer"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="button"
            onMouseEnter={handleHover}
            onClick={handleLogin}
            onMouseLeave={handleHover}
            style={{
              padding: "8px 25px",
              borderRadius: "20px",
              fontWeight: "bold",
              marginRight: "10px",
              transition: "background-color 0.3s",
            }}
          >
            Log In
          </button>
          <button
            className="button"
            onMouseEnter={handleHover}
            onClick={handleSignup}
            onMouseLeave={handleHover}
            style={{
              padding: "8px 25px",
              borderRadius: "20px",
              fontWeight: "bold",
              marginRight: "10px",
              transition: "background-color 0.3s",
            }}
          >
            Create Account
          </button>
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          marginLeft: "5px",
        }}
      >
        <Col md={12} className="text-center content-wrapper">
          <h1
            className="page-title"
            style={{
              fontSize: "6em",
              color: "white",
              fontWeight: "bold",
              animation: isLoaded ? "zoomIn 1s ease-in-out" : "",
            }}
          >
            WELCOME TO OGOTRIX!!!!
          </h1>
          <p
            className="para-desc text-white title-dark mb-0"
            style={{
              fontSize: "1.5em",
              animation: isLoaded ? "fadeInUp 1s ease-in-out 0.3s" : "",
            }}
          >
            Now available for early adopters!
          </p>
          <br />
          <div className="content-aligned">
            <button
              className="button"
              onMouseEnter={handleHover}
              onClick={handleSignup}
              onMouseLeave={handleHover}
              style={{
                opacity: isLoaded ? 1 : 0,
                animation: isLoaded ? "fadeIn 0.5s ease-in-out" : "",
                padding: "15px 40px",
                fontSize: "22px",
              }}
            >
              Create Account
            </button>
          </div>
          <br />
          <div
            className="subscribe-wrapper"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              style={{
                padding: "10px",
                borderRadius: "20px",
                border: "none",
                width: "300px",
                marginBottom: "10px",
              }}
            />
            <button
              className="button"
              onMouseEnter={handleHover}
              onClick={handleSubscribe}
              onMouseLeave={handleHover}
              style={{
                padding: "10px 20px",
                borderRadius: "20px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Subscribe
            </button>
          </div>
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{
          marginTop: "80vh",
          padding: "20px",
        }}
      >
        <Col md={4}>
          <Card className="pricing-card">
            <Card.Body>
              <Card.Title>Performer Pricing</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                $29.99 / month
              </Card.Subtitle>
              <Card.Text>
                <ul>
                  <li>Feature 1</li>
                  <li>Feature 2</li>
                  <li>Feature 3</li>
                  <li>Feature 4</li>
                </ul>
              </Card.Text>
              <Button variant="primary" onClick={handleSignup}>
                Sign Up
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="pricing-card">
            <Card.Body>
              <Card.Title>Venue Pricing</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                $49.99 / month
              </Card.Subtitle>
              <Card.Text>
                <ul>
                  <li>Feature A</li>
                  <li>Feature B</li>
                  <li>Feature C</li>
                  <li>Feature D</li>
                </ul>
              </Card.Text>
              <Button variant="primary" onClick={handleSignup}>
                Sign Up
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LandingPage;
