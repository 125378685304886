import React, { useState, useEffect, useRef } from "react";
import "./NavigationBar.css";
import gigbridgelogo from "../../assect/images/landing/OgotrixLogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaSearch,
  FaChartBar,
  FaUserCog,
  FaUser,
  FaBell,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import Cookies from "js-cookie";

import { FiLogOut } from "react-icons/fi";
import axiosInstance from "../../pages/auth/axiosInstance";

const NavigationBar = () => {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadNotCount, setUnreadNotCount] = useState(0);
  const [messageThreads, setMessageThreads] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const navigate = useNavigate();
  const notificationModalRef = useRef(null);
  const messageModalRef = useRef(null);
  const profileId = Cookies.get("profileOwnerId");

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  const getActiveClass = (path) => {
    return location.pathname === path ? "active-link" : "";
  };

  return (
    <header id="topnav" className={isSticky ? "nav-sticky" : ""}>
      <section className="navigation">
        <div className="nav-container">
          <div className="brand">
            {/* <a href="#!">Gig Bridge LOGO</a> */}
            <img
              src={gigbridgelogo}
              alt="Gigbridge Logo"
              style={{ height: "60px" }}
            />
          </div>
          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!" onClick={toggleMobileMenu}>
                <span className={isMobile ? "active" : ""}></span>
              </a>
            </div>
            <ul className={`nav-list ${isMobile ? "active" : ""}`}>
            <li>                
                <Link to="/" className={getActiveClass("/")}>
                  Home
                </Link>
              </li>
              <li>                
                <Link to="https://ozstream.au/faqs/" className={getActiveClass("/faqs")}>
                  FAQ
                </Link>
              </li>              
              <li>                
                <Link to="https://ozstream.au/contact-us/" className={getActiveClass("https://ozstream.au/contact-us/")}>
                  Contact Us
                </Link>
              </li>   
              <li>                
                <Link to="/login" className={getActiveClass("/login")}>
                  Sign In
                </Link>
              </li>  
              <li>                
                <Link
                  to="/signup"
                  className={getActiveClass("/signup")}
                >
                  Sign Up
                </Link>
              </li>
              
            </ul>
          </nav>
        </div>
      </section>
    </header>
  );
};

export default NavigationBar;
