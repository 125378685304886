import { useState, useEffect } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosInstance from "../pages/auth/axiosInstance";
import StarRatings from "react-star-ratings";
import { ProgressBar } from "react-bootstrap";
import "./ratings.css";

const Ratings = () => {
  const navigate = useNavigate();
  const [ratings, setRatings] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ratingsData, setRatingsData] = useState(null);
  const profileId = Cookies.get("ProfileId");
  const [authorInfo, setAuthorInfo] = useState({});

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        // Make HTTP GET request to fetch reviews
        const response = await axiosInstance.get(
          `/v1/review/received/by/profile/${profileId}`
        );
        // Update state with received reviews data
        setReviews(response.data.reviews_received);
        setRatings(response.data.reviews_summary);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const fetchAuthorInfo = async (authorProfileId) => {
    try {
      const profileType = authorProfileId.startsWith("PROFILE-PERFORMER")
        ? "performer"
        : "venue";
      const response = await axiosInstance.get(
        `/v1/${profileType}/view-profile/${authorProfileId}/section/complete`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching author info:", error);
      return null;
    }
  };

  useEffect(() => {
    reviews.forEach((review) => {
      fetchAuthorInfo(review.author_profile_id).then((authorInfo) => {
        setAuthorInfo((prevAuthorInfo) => ({
          ...prevAuthorInfo,
          [review.author_profile_id]: authorInfo,
        }));
      });
    });
  }, [reviews]);

  return (
    <div
      className="c"
      style={{
        paddingLeft: "5%",
        paddingRight: "5%",
      }}
    >
      <h4 style={{ textAlign: "left", color: "black" }}>Ratings</h4>
      <div className="c-rating">
        <div className="ratings">
          <StarRatings
            rating={ratings?.starts_average}
            starRatedColor="#FCCC4D"
            numberOfStars={5}
            name="rating"
            starDimension="20px"
            starSpacing="10px"
          />
        </div>
        <div className="c-rating-text">
          {ratings && ratings?.starts_average ? ratings?.starts_average : "0"}{" "}
          out of 5
        </div>
      </div>
      <p className="rating-count">
        {ratings && ratings?.stars_total ? ratings?.stars_total : "0"} customer
        ratings
      </p>
      <div className="rating-percents">
        <div className="rating-percent">
          <span className="rating-no">5 star</span>
          <ProgressBar
            now={
              (Number(ratings?.stars_5) / (Number(ratings?.stars_total) || 1)) *
              100
            }
            className="rating-progress"
          />
          <span className="rating-percent-no">
            {ratings && ratings?.stars_total
              ? Math.round(
                  (Number(ratings?.stars_5) / Number(ratings?.stars_total)) *
                    100
                )
              : "0"}
            %
          </span>
        </div>
        <div className="rating-percent">
          <span className="rating-no">4 star</span>
          <ProgressBar
            now={
              (Number(ratings?.stars_4) / (Number(ratings?.stars_total) || 1)) *
              100
            }
            className="rating-progress"
          />
          <span className="rating-percent-no">
            {ratings && ratings?.stars_total
              ? Math.round(
                  (Number(ratings?.stars_4) / Number(ratings?.stars_total)) *
                    100
                )
              : "0"}
            %
          </span>
        </div>
        <div className="rating-percent">
          <span className="rating-no">3 star</span>
          <ProgressBar
            now={
              (Number(ratings?.stars_3) / (Number(ratings?.stars_total) || 1)) *
              100
            }
            className="rating-progress"
          />
          <span className="rating-percent-no">
            {ratings && ratings?.stars_total
              ? Math.round(
                  (Number(ratings?.stars_3) / Number(ratings?.stars_total)) *
                    100
                )
              : "0"}
            %
          </span>
        </div>
        <div className="rating-percent">
          <span className="rating-no">2 star</span>
          <ProgressBar
            now={
              (Number(ratings?.stars_2) / (Number(ratings?.stars_total) || 1)) *
              100
            }
            className="rating-progress"
          />
          <span className="rating-percent-no">
            {ratings && ratings?.stars_total
              ? Math.round(
                  (Number(ratings?.stars_2) / Number(ratings?.stars_total)) *
                    100
                )
              : "0"}
            %
          </span>
        </div>
        <div className="rating-percent">
          <span className="rating-no">1 star</span>
          <ProgressBar
            now={
              (Number(ratings?.stars_1) / (Number(ratings?.stars_total) || 1)) *
              100
            }
            className="rating-progress"
          />
          <span className="rating-percent-no">
            {ratings && ratings?.stars_total
              ? Math.round(
                  (Number(ratings?.stars_1) / Number(ratings?.stars_total)) *
                    100
                )
              : "0"}
            %
          </span>
        </div>
      </div>
      <p className="c-footer-text">How do we calculate ratings?</p>
    </div>
  );
};

export default Ratings;
