import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Row, Modal } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RiFlagFill } from "react-icons/ri";
import NewCalloutPerformerView from "./NewCalloutPerformerView";
import Swal from "sweetalert2";

function ViewCalloutsbyOthers(props) {
  // const { profileId } = useParams();
  const params = useParams();
  const profileId = props.profileId || params.profileId;
  const profileIdn = profileId; //Cookies.get("profileId");
  const [call_outs, setcall_outs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);
  const { call_outId } = useParams();
  const [call_out, setcall_out] = useState(null);
  const navigate = useNavigate();
  const [flaggedCallouts, setFlaggedCallouts] = useState({});
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagComment, setFlagComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedcalloutId, setSelectedcalloutId] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    // Cookies.set("publicProfileId", profileId);
    const fetchcall_outs = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/call_out/published/by/profile/${profileIdn}`
        );
        if (response.status === 200) {
          const data = response.data;
          setcall_outs(data.call_outs_published);
        } else {
          console.error("Error fetching call_outs:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching call_outs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchcall_outs();
  }, []);

  //   const handlecall_outClick = () => {
  //     if (call_out) {
  //       Cookies.set("call_outId", call_outId); // Save call_outId in cookies
  //       navigate(`/call_out/${call_outId}`); // Redirect to call_out venue view page
  //     }
  //   };

  const handlecall_outClick = (call_outId, authorProfileId) => {
    Cookies.set("toProfileIdFirst", authorProfileId);
    Cookies.set("call_outId", call_outId);
    console.log("Clicked call_outId:", call_outId);
    navigate(`/callout/${call_outId}`);
  };
  //   const handlecall_outClick = (call_outId) => {
  //     Cookies.set("call_outId", call_outId);
  //     console.log("Clicked call_outId:", call_outId);
  //     navigate(`/call_out/${call_outId}`);
  //   };

  const handleViewMore = () => {
    setViewableCount(call_outs.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  //   const handleSendMessage = (authorId) => {
  //     // Store author's ID in cookies
  //     Cookies.set("toProfileId", authorId);
  //     // Navigate to messaging page
  //     navigate("/messaging");
  //   };

  const profileOwnerId = Cookies.get("profileOwnerId");

  const handleFlagClick = async (call_out_id) => {
    try {
      const response = await axiosInstance.post(
        "/v1/call_out/flag-call-out",
        {
          reporting_profile_id: Cookies.get("profileOwnerId"),
          call_out_id: call_out_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setFlaggedCallouts((prev) => ({ ...prev, [call_out_id]: true }));
      } else {
        console.error("Error flagging callout:", response.statusText);
      }
    } catch (error) {
      console.error("Error flagging callout:", error);
      let errorMessage = "There was an errorflagging callout.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const handleSendMessage = async (toProfileId) => {
    try {
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_classified/${profileOwnerId}/${toProfileId}`,
        { title: "clasifid" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        navigate("/messaging");
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected callout id", id);
    const isSecure = window.location.protocol === "https:";
    const cookieOptions = {
      secure: isSecure,
      sameSite: "Lax",
      // httpOnly: true,
    };
    Cookies.set("toProfileIdFirst", authorProfileId, cookieOptions);
    // Cookies.set("toProfileIdFirst", authorProfileId);
    setSelectedcalloutId(id);
    // Cookies.set("calloutId", id);
    // console.log("calloutId cookie value:", Cookies.get("calloutId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/call_out/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const callout = response.data;
      console.log("callout response", response.data);
      // const profileId = Cookies.get("profileOwnerId");
      // console.log("author profile id:", callout.callout.author_profile_id);
      console.log("callout id:", id);

      const profileType = getProfileType(Cookies.get("profileOwnerId"));
      if (profileType === "performer") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      } else if (profileType === "venue") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      }
    } catch (error) {
      console.error("Error fetching callout details:", error);
      let errorMessage = "There was an error fetching CallOut details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedcalloutId(null);
    setShowModal(false);
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>
          <h4>call Outs</h4>
        </strong>
      </center>
      <br />
      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading Call Outs...</span>
        </div>
      ) : (
        <Row>
          {call_outs.length > 0 ? (
            <>
              {call_outs.slice(0, viewableCount).map((call_out) => (
                <div
                  key={call_out._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  onClick={() =>
                    openModal(call_out._id, call_out.author_profile_id)
                  }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{call_out.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{call_out.short_description}</p>
                        </div>
                        <div className="events__details-inline">
                          <strong>Location:</strong> {call_out.location.suburb}
                        </div>
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(call_out.from_date)}
                          {"   "}
                          {formatTime(call_out.from_time)}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(call_out.to_date)}
                          {"   "}
                          {formatTime(call_out.to_time)}
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Date:</strong>
                          {typeof call_out.from_date === "object"
                            ? new Date(
                                call_out.from_date.year,
                                call_out.from_date.month - 1,
                                call_out.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : call_out.from_date}
                        </div>
                        <div className="events__details-inline">
                          <strong>Time:</strong>
                          {typeof call_out.from_time === "object"
                            ? `${call_out.from_time.hour}:${call_out.from_time.minute} ${call_out.from_time.am_pm}`
                            : call_out.from_time}
                        </div> */}
                        <Button
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFlagClick(call_out._id);
                          }}
                          style={{
                            color: flaggedCallouts[call_out._id]
                              ? "red"
                              : "black",
                            zIndex: 1,
                          }}
                        >
                          <RiFlagFill />
                        </Button>
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          // onClick={() => handlecall_outClick(call_out._id)}
                          // onClick={() =>
                          //   handlecall_outClick(
                          //     call_out._id,
                          //     call_out.author_profile_id
                          //   )
                          // }
                          onClick={() =>
                            openModal(call_out._id, call_out.author_profile_id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {viewableCount < call_outs.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({call_outs.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === call_outs.length && call_outs.length > 2 && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewLess}
                    style={{ color: "#418abb" }}
                  >
                    View Less
                  </Button>
                </center>
              )}
            </>
          ) : (
            <p className="text-center">No call outs found.</p>
          )}
        </Row>
        // <div>
        //   {call_outs.length > 0 ? (
        //     <>
        //       {call_outs.slice(0, viewableCount).map((call_out) => (
        //         <Card
        //           key={call_out._id}
        //           className="mb-3"
        //           onClick={() =>
        //             handlecall_outClick(
        //               call_out._id,
        //               call_out.author_profile_id
        //             )
        //           }
        //           style={{
        //             backgroundColor: "#e8e8e8",
        //             borderRadius: "15px",
        //             cursor: "pointer",
        //           }}
        //         >
        //           <Card.Body>
        //             <h4>
        //               <strong style={{ fontWeight: "bold" }}>
        //                 {call_out.title}
        //               </strong>
        //             </h4>
        //             <p>{call_out.short_description}</p>
        //             <p>
        //               <strong>Location:</strong> {call_out.location.suburb}
        //             </p>
        //             <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof call_out.from_date === "object"
        //                 ? new Date(
        //                     call_out.from_date.year,
        //                     call_out.from_date.month - 1,
        //                     call_out.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : call_out.from_date}
        //             </p>
        //             <p>
        //               <strong>Time:</strong>{" "}
        //               {typeof call_out.from_time === "object"
        //                 ? `${call_out.from_time.hour}:${call_out.from_time.minute} ${call_out.from_time.am_pm}`
        //                 : call_out.from_time}
        //             </p>
        //             {/* <Button
        //               variant="link"
        //               onClick={() =>
        //                 handleSendMessage(call_out.author_profile_id)
        //               }
        //             >
        //               <FaEnvelope />
        //             </Button> */}

        //             <Button
        //               variant="link"
        //               onClick={(e) => {
        //                 e.stopPropagation();
        //                 handleFlagClick(call_out._id);
        //               }}
        //               style={{
        //                 color: flaggedCallouts[call_out._id] ? "red" : "black",
        //                 zIndex: 1,
        //               }}
        //             >
        //               <RiFlagFill />
        //             </Button>
        //           </Card.Body>
        //         </Card>
        //       ))}
        //       {viewableCount < call_outs.length && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewMore}
        //             style={{ color: "#418abb" }}
        //           >
        //             View More ({call_outs.length - viewableCount} more)
        //           </Button>
        //         </center>
        //       )}
        //       {viewableCount === call_outs.length && call_outs.length > 2 && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewLess}
        //             style={{ color: "#418abb" }}
        //           >
        //             View Less
        //           </Button>
        //         </center>
        //       )}
        //     </>
        //   ) : (
        //     <p className="text-center">No call_outs found.</p>
        //   )}
        // </div>
      )}
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ alignSelf: "center" }}
        // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewCalloutsbyOthers;
