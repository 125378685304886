import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Collapse,
  Card,
  Badge,
  Spinner,
} from "react-bootstrap";
import {
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaSearch,
  FaStar,
} from "react-icons/fa";
import "./newSearch.css";
import { Button, Nav, Tab, Modal } from "react-bootstrap";
import MapComponent from "../../components/map/calloutMap";
import "../venue/venue.css";
// import dummyProfilePicture from "../../assect/images/venue/02.jpg";
import { RiMessage3Fill } from "react-icons/ri";
import Navbar from "../../components/navbarSelf";
import SidebarWithBurgerMenu from "../../components/searchNavbar";
import ParentComponent from "../../components/parentComponent";
import Slider from "rc-slider";
import "react-range-slider-input/dist/style.css";
import "rc-slider/assets/index.css";
import axiosInstance from "../../pages/auth/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import ViewVenueProfile from "../../pages/venue/viewVenueProfile";
import AutoComplete from "../../components/autoCompleteNew";
import ViewPerformerProfile from "../../pages/performer/viewPerformerProfile";
import NewPerformerProfile from "../OtherPages/newPerformerProfile";
import NewPerformerProfileTest from "../OtherPages/newPerformerProfile";
import { FaFlag, FaBookmark, FaRegBookmark } from "react-icons/fa";
import "../OtherPages/ProfileComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBookmark, faSave } from "@fortawesome/free-regular-svg-icons";
import { FiFlag } from "react-icons/fi";
import NavigationBar from "../../components/navbar/newNavigationBar";
import Swal from "sweetalert2";
import CalloutView from "../callouts/calloutPerformerView";
import NewCalloutPerformerView from "../callouts/NewCalloutPerformerView";
import Footer from "../../components/footer";
const isMobile = window.innerWidth <= 767;

const CustomToggleButton = ({ open, onClick }) => (
  <center>
    <div
      onClick={onClick}
      className={`filter-toggle-button ${open ? "open" : ""}`}
      style={{ marginTop: isMobile ? "20%" : "5%" }}
    >
      <span className="button-text">
        CallOut Search Filters
        {open ? (
          <FaChevronUp className="ml-2" />
        ) : (
          <FaChevronDown className="ml-2" />
        )}
      </span>
    </div>
  </center>
);

const NewCalloutSearch = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const setPlaceholders = () => {
      const fromDateInput = document.getElementById("formFromDate");
      const toDateInput = document.getElementById("formToDate");

      if (fromDateInput) {
        fromDateInput.addEventListener("focus", () => {
          if (fromDateInput.value === "") {
            fromDateInput.type = "date";
          }
        });
        fromDateInput.addEventListener("blur", () => {
          if (fromDateInput.value === "") {
            fromDateInput.type = "text";
            fromDateInput.placeholder = "From Date";
          }
        });
        if (fromDateInput.value === "") {
          fromDateInput.type = "text";
          fromDateInput.placeholder = "From Date";
        }
      }

      if (toDateInput) {
        toDateInput.addEventListener("focus", () => {
          if (toDateInput.value === "") {
            toDateInput.type = "date";
          }
        });
        toDateInput.addEventListener("blur", () => {
          if (toDateInput.value === "") {
            toDateInput.type = "text";
            toDateInput.placeholder = "To Date";
          }
        });
        if (toDateInput.value === "") {
          toDateInput.type = "text";
          toDateInput.placeholder = "To Date";
        }
      }
    };

    setPlaceholders();
  }, []);

  const [formData, setFormData] = useState({
    type: "",
    category: "",
    sub_category: "",
    keywords: [],
    search_by_location: "NO",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    search_radius: 150,
    from_price_point: 0,
    to_price_point: 0,
    from_date: "",
    to_date: "",
    display_order: "MOST_RECENT",
  });

  const [performers, setperformers] = useState([]);
  const [searchRadius, setSearchRadius] = useState([]);

  const [genres, setGenres] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);
  const [saveloading, setSaveloading] = useState(false);
  const [selectedperformerId, setSelectedperformerId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [flaggedPerformers, setFlaggedPerformers] = useState({});
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagComment, setFlagComment] = useState("");
  const [selectedFlaggedPerformer, setSelectedFlaggedPerformer] =
    useState(null);
  const [sectionType, setSectionType] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [callouts, setCallouts] = useState([]);
  const [selectedFlaggedCallout, setSelectedFlaggedCallout] = useState(null);
  const [flaggedCallouts, setFlaggedCallouts] = useState({});

  const [selectedCalloutId, setSelectedCalloutId] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const mapRef = useRef(null);

  const [savedSearches, setSavedSearches] = useState([]);
  const profileId = Cookies.get("profileOwnerId");

  const [saveSearch, setSaveSearch] = useState([]);
  const [activeView, setActiveView] = useState("explore");
  const location = useLocation();
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [centerLocation, setCenterLocation] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  const handleFlagClick = (CalloutId) => {
    setSelectedFlaggedCallout(CalloutId);
    setShowFlagModal(true);
  };

  const types = [
    "Performer to Performer",
    "Performer to Venue",
    "Performer to Performer and Venue",
    "Venue to Performer",
    "Venue to Venue",
    "Venue to Performer and Venue",
  ];

  // Categories List
  const categories = [
    "Auditions",
    "Collaboration",
    "Educational Resources",
    "Industry Insights",
    "Personal Announcement",
    "Services Needed",
    "Services Offered",
    "Technical Support + Advice",
    "Venue Specific",
    "Work Opportunities",
  ];

  // Subcategories for each category
  const subCategories = {
    Auditions: [
      "Auditions",
      "Backup Singers",
      "Band Members",
      "Performance Opportunities",
      "Session Musicians",
      "Stage / Sound Production",
    ],
    Collaboration: [
      "Collaboration",
      "Composing",
      "Content",
      "Creating",
      "Creation",
      "Digital",
      "Education",
      "Forming New Band",
      "Lyrics",
      "Music",
      "Musical Arrangements",
      "Networking",
      "Other Performers",
      "Project",
      "Songwriting",
      "Workshop",
    ],
    "Educational Resources": [
      "Books",
      "Courses",
      "Education",
      "General Information",
      "Instructional Video",
      "In-House",
      "In-Person",
      "Masterclass",
      "Music Books",
      "Music Lessons",
      "Online Course",
      "Resources",
      "Seminar",
      "Sheet Music",
      "Tuition",
      "Webinar",
      "Website",
      "Workshop",
    ],
    "Industry Insights": [
      "Compensation",
      "Competitions",
      "Copyright",
      "Grants",
      "Income and Payments",
      "Industry",
      "Laws",
      "Licensing",
      "Music",
      "Performance",
      "Scholarships",
      "Statistics",
      "Streaming",
      "Trends",
    ],
    "Personal Announcement": [
      "Album Launch",
      "Award Nominations",
      "Awards",
      "Call Outs",
      "Crowdfunding Campaigns",
      "Feeding It Forwards",
      "Kudos",
      "Projects",
      "Significant Performance",
      "Special Mentions",
    ],
    "Services Needed": [
      "Album Artwork Design",
      "Bump In / Bump Out",
      "Education",
      "Engineering",
      "Industry Experience",
      "Marketing",
      "Mastering",
      "Mixing",
      "Music",
      "Production",
      "Recording",
      "Sound",
      "Stage Design",
      "Stage Support",
      "Tuition",
      "Video",
    ],
    "Services Offered": [
      "Album Artwork Design",
      "Bump In / Bump Out",
      "Education",
      "Engineering",
      "Industry Experience",
      "Marketing",
      "Mastering",
      "Mixing",
      "Music",
      "Production",
      "Recording",
      "Sound",
      "Stage Design",
      "Stage Support",
      "Tuition",
      "Video",
    ],
    "Technical Support + Advice": [
      "Equipment",
      "How To",
      "Instrument Care",
      "Instrument Customisation",
      "Instrument Maintenance",
      "Music Technology",
      "Performance Techniques",
      "Repair",
      "Seeking Advice",
      "Software",
      "Sound Setup",
      "Tips and Tricks",
      "Troubleshooting",
      "Tutorials",
    ],
    "Venue Specific": [
      "Employing",
      "Hiring",
      "New Venue Openings",
      "Regular Performers",
      "Resident Musicians",
      "Seeking",
      "Special Event Bookings",
      "Themed Music Night",
    ],
    "Work Opportunities": [
      "Gigs",
      "Joint Performances",
      "Live Performance",
      "Offering",
      "Passing Through",
      "Seeking",
      "Touring",
      "Traveling",
      "Venues",
    ],
  };

  // useEffect(() => {
  //   const fetchTypes = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axiosInstance.get(
  //         "/v1/search/filter/call-out-types"
  //       );
  //       // setTypes(response.data.call_out_types || []);
  //       const fetchedTypes = response.data.call_out_types || [];
  //       setTypes(fetchedTypes);
  //       // if (fetchedTypes.length > 0) {
  //       //   const defaultType = fetchedTypes[0];
  //       //   setSelectedType(defaultType);
  //       //   setFormData((prevData) => ({ ...prevData, type: defaultType }));
  //       // }
  //     } catch (error) {
  //       console.error("Error fetching types:", error);
  //     }
  //     setLoading(false);
  //   };

  //   const fetchCategories = async () => {
  //     try {
  //       const response = await axiosInstance.get(
  //         "/v1/search/filter/call-out-categories-sub-categories"
  //       );
  //       const data = response.data.call_out_categories_sub_categories.map(
  //         (category) => ({
  //           label: category.category,
  //           value: category.category,
  //           subCategories: category.sub_categories,
  //         })
  //       );
  //       setCategories(data);

  //       if (data.length > 0) {
  //         const defaultCategory = data[0];
  //         setSelectedCategory({
  //           label: defaultCategory.label,
  //           value: defaultCategory.value,
  //         });
  //         const subCategories = defaultCategory.subCategories.map((sub) => ({
  //           label: sub,
  //           value: sub,
  //         }));
  //         setSubCategories(subCategories);
  //         if (subCategories.length > 0) {
  //           const defaultSubCategory = subCategories[0];
  //           setSelectedSubCategory(defaultSubCategory);
  //           setFormData((prevData) => ({
  //             ...prevData,
  //             category: defaultCategory.value,
  //             sub_category: defaultSubCategory.value,
  //           }));
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   fetchTypes();
  //   fetchCategories();
  // }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleTypeChange = (selectedOptions) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     call_out_type: selectedOptions.map((option) => option.value).join(", "),
  //   }));
  // };

  const handleTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedType(selectedValue);
    setFormData((prevData) => ({
      ...prevData,
      type: selectedValue,
    }));
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);

    const subCategoriesList = subCategories[selectedOption] || [];
    const subCategoriesOptions = subCategoriesList.map((sub) => ({
      label: sub,
      value: sub,
    }));

    const defaultSubCategory =
      subCategoriesOptions.find((sub) => sub.value === formData.sub_category) ||
      subCategoriesOptions[0];

    setSelectedSubCategory(defaultSubCategory);
    setFormData((prevData) => ({
      ...prevData,
      category: selectedOption,
      sub_category: defaultSubCategory ? defaultSubCategory.value : "",
    }));
  };

  // const handleCategoryChange = (selectedOption) => {
  //   setSelectedCategory(selectedOption);
  //   const category = categories.find(
  //     (cat) => cat.value === selectedOption.value
  //   );
  //   if (category) {
  //     const subCategories = category.subCategories.map((sub) => ({
  //       label: sub,
  //       value: sub,
  //     }));
  //     setSubCategories(subCategories);
  //     if (subCategories.length > 0) {
  //       const defaultSubCategory = subCategories[0];
  //       setSelectedSubCategory(defaultSubCategory);
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         category: selectedOption.value,
  //         sub_category: defaultSubCategory.value,
  //       }));
  //     }
  //   }
  // };

  const handleSubCategoryChange = (selectedOption) => {
    // The selectedOption is just the value (string), update it accordingly
    const subCategoryObj = {
      label: selectedOption,
      value: selectedOption,
    };

    setSelectedSubCategory(subCategoryObj);
    setFormData((prevData) => ({
      ...prevData,
      sub_category: selectedOption,
    }));
  };

  // const handleSubCategoryChange = (selectedOption) => {
  //   setSelectedSubCategory(selectedOption);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     sub_category: selectedOption.value,
  //   }));
  // };

  const handleRadiusChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      search_radius: parseInt(e.target.value),
    }));
  };

  useEffect(() => {
    // Initialize section types array and sort it alphabetically
    const sections = [
      "Title",
      "Venue Name",
      "Short Description",
      "Descriptions",
    ];
    const sortedSections = sections.sort((a, b) => a.localeCompare(b));
    setSectionType(sortedSections);
  }, []);

  const handleFlag = async (
    sectionType,
    flagComment,
    reportinProfileID,
    CalloutID
  ) => {
    console.log("sectionType:", sectionType);
    console.log("flagComment:", flagComment);

    console.log("reportinProfileID:", reportinProfileID);
    console.log("CalloutID:", CalloutID);
    try {
      const response = await axiosInstance.post(
        "/v1/call_out/flag-call-out",
        {
          reporting_profile_id: reportinProfileID,
          call_out_id: CalloutID,
          section: sectionType,
          comment: flagComment,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error searching Callouts:", error);
    }
  };

  const handleFlagSave = () => {
    setLoadingSaveComment(true);
    setFlaggedCallouts((prev) => ({
      ...prev,
      [selectedFlaggedCallout]: { sectionType: selectedSection, flagComment },
    }));
    handleFlag(
      selectedSection,
      flagComment,
      `${Cookies.get("profileOwnerId")}`,
      selectedFlaggedCallout
    );
    setShowFlagModal(false);
    setSelectedSection("");
    setFlagComment("");
    setLoadingSaveComment(false);
  };

  const handleFlagCommentChange = (e) => {
    setFlagComment(e.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  // useEffect(() => {
  //   const fetchSaveSearch = async () => {
  //     try {
  //       const PROFILE_ID = `${Cookies.get("profileOwnerId")}`;
  //       const response = await axiosInstance.get(
  //         `/v1/search/load-search-configuration/${PROFILE_ID}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       // setSaveSearch(response.data.search_configurations);
  //       setSaveSearch(response.data.search_configurations || []);
  //       console.log(response.data.search_configurations);
  //     } catch (error) {
  //       console.error("Error fetching dropdown options:", error);
  //     }
  //   };

  //   fetchSaveSearch();
  // }, []);

  // useEffect(() => {
  //   const fetchDropdownOptions = async () => {
  //     try {
  //       const typesResponse = await axiosInstance.get(
  //         "/v1/search/filter/call-out-types"
  //       );
  //       setTypes(typesResponse.data.callout_types);
  //       setSelectedTypes([
  //         {
  //           label: typesResponse.data.callout_types[0],
  //           value: typesResponse.data.callout_types[0],
  //         },
  //       ]);
  //     } catch (error) {
  //       console.error("Error fetching dropdown options:", error);
  //     }
  //   };

  //   fetchDropdownOptions();
  // }, []);

  // const handleTypeChange = (selectedOptions) => {
  //   // Limit selection to 3 values
  //   if (selectedOptions.length <= 3) {
  //     setSelectedTypes(selectedOptions);
  //   }
  // };

  const isMounted = useRef(false);

  useEffect(() => {
    if (location.state && location.state.searchConfig) {
      const searchConfig = location.state.searchConfig;

      // Set the selected type
      setSelectedType(searchConfig.call_out_type || "");

      // Set the category and subcategory
      const selectedCategory = categories.find(
        (cat) => cat === searchConfig.call_out_category
      );
      setSelectedCategory(selectedCategory || null);

      if (selectedCategory) {
        const subCategoriesList = subCategories[selectedCategory] || [];
        const subCategoriesOptions = subCategoriesList.map((sub) => ({
          label: sub,
          value: sub,
        }));

        const selectedSubCategory = subCategoriesOptions.find(
          (sub) => sub.value === searchConfig.call_out_sub_category
        );
        setSelectedSubCategory(selectedSubCategory || null);
      }

      // Populate the form data with the saved search configuration
      setFormData((prevData) => ({
        ...prevData,
        type: searchConfig.call_out_type || "",
        category: searchConfig.call_out_category || "",
        sub_category: searchConfig.call_out_sub_category || "",
        keywords: searchConfig.keywords || [],
        search_by_location: searchConfig.search_by_location || "NO",
        location: searchConfig.location || {
          suburb: "",
          zipcode: "",
          latitude: "",
          longitude: "",
          location_name: "",
          address: "",
          google_map_pin: "",
        },
        from_price_point: searchConfig.from_price_point || 0,
        to_price_point: searchConfig.to_price_point || 5000,
        from_date: searchConfig.from_date || "",
        to_date: searchConfig.to_date || "",
        display_order: searchConfig.display_order || "",
      }));
      setPricePoint([
        searchConfig.from_price_point || 0,
        searchConfig.to_price_point || 5000,
      ]);
      setOpen(true);
    }
  }, [location.state]);

  // useEffect(() => {
  //   if (location.state && location.state.searchConfig) {
  //     const searchConfig = location.state.searchConfig;
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       type: searchConfig.call_out_type || "",
  //       category: searchConfig.call_out_category || "",
  //       sub_category: searchConfig.call_out_sub_category || "",
  //       keywords: searchConfig.keywords || [],
  //       search_by_location: searchConfig.search_by_location || "NO",
  //       location: searchConfig.location || {
  //         suburb: "",
  //         zipcode: "",
  //         latitude: "",
  //         longitude: "",
  //         location_name: "",
  //         address: "",
  //         google_map_pin: "",
  //       },
  //       from_price_point: searchConfig.from_price_point || 0,
  //       to_price_point: searchConfig.to_price_point || 0,
  //       from_date: searchConfig.from_date || "",
  //       to_date: searchConfig.to_date || "",
  //       display_order: searchConfig.display_order || "",
  //     }));
  //   }
  // }, [location.state]);

  // useEffect(() => {
  //   if (location.state && location.state.searchConfig) {
  //     const searchConfig = location.state.searchConfig;
  //     setFormData({
  //       type: searchConfig.call_out_type,
  //       category: searchConfig.call_out_category,
  //       sub_category: searchConfig.call_out_sub_category,
  //       // keywords: searchConfig.keywords.join(", "),
  //       keywords: searchConfig.keywords.map((kw) => kw.toLowerCase()),
  //       // search_by_location: searchConfig.search_by_location,
  //       // location: searchConfig.location,
  //       search_by_location: searchConfig.search_by_location || "NO",
  //       location: searchConfig.location || {
  //         suburb: "",
  //         zipcode: "",
  //         latitude: "",
  //         longitude: "",
  //         location_name: "",
  //         address: "",
  //         google_map_pin: "",
  //       },
  //       from_price_point: searchConfig.from_price_point,
  //       to_price_point: searchConfig.to_price_point,
  //       from_date: searchConfig.from_date,
  //       to_date: searchConfig.to_date,
  //       display_order: searchConfig.display_order,
  //     });
  //   }
  // }, [location.state]);

  // useEffect(() => {
  //   if (isMounted.current) {
  //     handleSubmit();
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [formData]);

  //   useEffect(() => {
  //     if (
  //       formData.type.length > 0 &&
  //       formData.category.length > 0 &&
  //       formData.sub_category.length > 0
  //     ) {
  //       handleSubmit();
  //     }
  //   }, [formData]);

  const handleKeywordChange = (e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      const keyword = e.target.value.trim().toLowerCase();
      if (!formData.keywords.includes(keyword)) {
        setFormData((prevData) => ({
          ...prevData,
          keywords: [...prevData.keywords, keyword],
        }));
      }
      e.target.value = "";
    }
  };

  const removeKeyword = (keyword) => {
    setFormData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((kw) => kw !== keyword),
    }));
  };

  const handleSubmit = async (e) => {
    // console.log("selected types:", selectedTypes[0].value);
    if (e) e.preventDefault();

    const { latitude, longitude } = formData.location;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);

    if (!isNaN(lat) && !isNaN(lng)) {
      setCenterLocation({ latitude: lat, longitude: lng });
    } else {
      console.error("Invalid location coordinates");
    }

    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    console.log("form date:", formData);

    // const formattedDateFrom = moment(formData.from_date).format("YYYY-MM-DD");
    // const formattedDateTo = moment(formData.to_date).format("YYYY-MM-DD");
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/v1/search/call-out-pagination",
        {
          // type: selectedTypes.map((type) => type.value),
          // call_out_type: formData.type,
          call_out_type: formData.type.trim(),
          call_out_category: formData.category,
          call_out_sub_category: formData.sub_category,
          keywords: formData.keywords,
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]), // Use pricePoint state variable here
          to_price_point: parseInt(pricePoint[1]),
          from_date: formData.from_date,
          to_date: formData.to_date,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      // setCallouts(response.data.search_results);
      setCallouts(response.data.search_results.results || []);
      console.log("callout search results: ", response.data.search_results);
      const baseUrl = axiosInstance.defaults.baseURL;

      console.log("base url: ", baseUrl);
      const nextUrl = response.data.search_results.next
        ? `${baseUrl}/v1/search${response.data.search_results.next}`
        : null;
      const prevUrl = response.data.search_results.previous
        ? `${baseUrl}/v1/search${response.data.search_results.previous}`
        : null;

      setNextUrl(nextUrl);
      setPreviousUrl(prevUrl);
      console.log(
        "response of search results: ",
        `${baseUrl}/v1/search${response.data.search_results.next}`
      );
    } catch (error) {
      console.error("Error searching callouts:", error);
      let errorMessage = "Error searching CallOuts.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
    setLoading(false);
  };

  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);

  const handlePagination = async (url, isNext) => {
    try {
      if (isNext) {
        setLoadingNext(true);
      } else {
        setLoadingPrevious(true);
      }

      const queryString = url.split("?")[1];
      const baseUrl = "/v1/search/call-out-pagination";

      const response = await axiosInstance.post(
        `${baseUrl}?${queryString}`,
        {
          call_out_type: formData.type.trim(),
          call_out_category: formData.category,
          call_out_sub_category: formData.sub_category,
          keywords: formData.keywords,
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]), // Use pricePoint state variable here
          to_price_point: parseInt(pricePoint[1]),
          from_date: formData.from_date,
          to_date: formData.to_date,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setCallouts(response.data.search_results.results);
      setNextUrl(
        response.data.search_results.next
          ? `${axiosInstance.defaults.baseURL}/v1/search${response.data.search_results.next}`
          : null
      );
      setPreviousUrl(
        response.data.search_results.previous
          ? `${axiosInstance.defaults.baseURL}/v1/search${response.data.search_results.previous}`
          : null
      );
    } catch (error) {
      console.error("Error fetching paginated data:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching the paginated data. Please try again.",
        "error"
      );
    } finally {
      if (isNext) {
        setLoadingNext(false);
      } else {
        setLoadingPrevious(false);
      }
    }
  };

  const handleSaveSearch = async (e) => {
    // console.log("selected types:", selectedTypes[0].value);

    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    try {
      setSaveloading(true);
      const response = await axiosInstance.post(
        "/v1/search/save/search-configuration",
        {
          search_type: "CALL_OUT",
          profile_id: `${Cookies.get("profileOwnerId")}`,
          call_out_type: formData.type,
          call_out_category: formData.category,
          call_out_sub_category: formData.sub_category,
          keywords: formData.keywords,
          // keywords: formData.keywords ? [formData.keywords] : [],
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]), // Use pricePoint state variable here
          to_price_point: parseInt(pricePoint[1]),
          from_date: formData.from_date,
          to_date: formData.to_date,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Search Configuration Saved!",
        text: "Your search configuration has been saved successfully.",
      });
    } catch (error) {
      // console.error("Error searching performers:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Failed to Save Search Configuration",
      //   text: "An error occurred while saving your search configuration. Please try again.",
      // });
      let errorMessage = "Failed to Save Search Configuration";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Failed to Save Search Configuration", errorMessage, "error");
    }
    setSaveloading(false);
  };

  // const openModal = (id) => {
  //   console.log("selected callout id", id);
  //   setSelectedCalloutId(id);
  //   Cookies.set("calloutId", id);
  //   console.log("calloutId cookie value:", Cookies.get("calloutId"));
  //   setShowModal(true);
  // };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected callout id", id);
    Cookies.set("toProfileIdFirst", authorProfileId);
    setSelectedCalloutId(id);
    // Cookies.set("calloutId", id);
    // console.log("calloutId cookie value:", Cookies.get("calloutId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/call_out/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const callout = response.data;
      console.log("callout response", response.data);
      // const profileId = Cookies.get("profileOwnerId");
      console.log("author profile id:", callout.call_out.author_profile_id);
      console.log("callout details debugging:", callout.call_out);
      console.log("opp id:", id);

      const profileType = getProfileType(Cookies.get("profileOwnerId"));
      if (profileType === "performer") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      } else if (profileType === "venue") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
    }
  };

  const closeModal = () => {
    setSelectedCalloutId(null);
    setShowModal(false);
  };

  const [pricePoint, setPricePoint] = useState([0, 1500]);

  const handlePricePointChange = (value) => {
    setPricePoint(value);
  };

  // const [calloutLocations, setCalloutLocations] = useState([]);

  //   useEffect(() => {
  //     // Extract location data from callouts
  //     const locations = callouts.map((callout) => ({
  //       ...callout.location,
  //       : callout.,
  //       last_name: callout.last_name,
  //       profile_picture_url: callout.profile_picture_url,
  //       callout_genres: callout.callout_genres,
  //       callout_types: callout.callout_types,
  //       price_point_currency: callout.price_point_currency,
  //       price_point_value: callout.price_point_value,
  //     }));
  //     setcalloutLocations(locations);
  //     console.log("locations data..: ", calloutLocations);
  //   }, [callouts]);

  const [calloutLocations, setcalloutLocations] = useState([]);

  useEffect(() => {
    if (!callouts || callouts.length === 0) {
      console.warn("callouts array is empty or undefined");
      return;
    }

    // Extract location data from callouts and set performerLocations state
    const locations = callouts
      .filter((callout) => callout.location)
      .map((callout) => ({
        ...callout.location,
        title: callout.title,
        last_name: callout.last_name,
        profile_picture_url: callout.profile_picture_url,
        callout_category: callout.call_out_category,
        callout_sub_category: callout.call_out_sub_category,
        callout_type: callout.call_out_type,
        price_point_currency: callout.price_point_currency,
        price_point_value_from: callout.price_point_value_from,
        price_point_value_to: callout.price_point_value_to,
      }));

    setcalloutLocations(locations);
    console.log("Updated calloutLocations: ", locations);
  }, [callouts]);

  const handleLocationClick = (openInfoWindow) => {
    mapRef.current = openInfoWindow;
  };

  const handleSearchResultClick = (location) => {
    if (!location || !location.latitude || !location.longitude) {
      alert("This search result doesn't have a location.");
      return;
    }

    if (mapRef.current) {
      mapRef.current(location);
    }
  };

  // useEffect(() => {
  //   if (!callouts || callouts.length === 0) {
  //     console.warn("callouts array is empty or undefined");
  //     return;
  //   }

  //   // Extract location data from callouts and set calloutLocations state
  //   const locations = callouts.map((callout) => ({
  //     ...callout.location,
  //     title: callout.title,
  //     profile_picture_url: callout.profile_picture_url,
  //     callout_genres: callout.callout_genres,
  //     callout_types: callout.callout_types,
  //     price_point_currency: callout.price_point_currency,
  //     price_point_value: callout.price_point_value,
  //   }));

  //   setCalloutLocations(locations);
  //   console.log("Updated callout Locations: ", locations);
  // }, [callouts]);

  // const handleLocationClick = (openInfoWindow) => {
  //   mapRef.current = openInfoWindow;
  // };

  // const handleSearchResultClick = (location) => {
  //   if (mapRef.current) {
  //     mapRef.current(location);
  //   }
  // };

  const handleSuburbSelect = (suburbData) => {
    setSelectedSuburb(suburbData);
    setFormData((prevData) => ({
      ...prevData,
      search_by_location: suburbData ? "YES" : "NO",
      location: suburbData || {
        suburb: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        location_name: "",
        address: "",
        google_map_pin: "",
      },
    }));
  };

  return (
    <>
      <NavigationBar style={{ marginBottom: "22%" }} />
      <Container fluid className="p-2">
        <CustomToggleButton open={open} onClick={() => setOpen(!open)} />
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card
              className="mb-3"
              style={{
                backgroundColor: "#f2f3f7",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "3%",
                paddingBottom: "3%",
              }}
            >
              <Form onSubmit={handleSubmit}>
                <div className="search-container">
                  <div
                    className="d-flex justify-content-end mb-3"
                    style={{ justifyContent: "center", marginTop: "-5%" }}
                  >
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                        style={{
                          cursor: loading ? "not-allowed" : "pointer", // Disable cursor when loading
                          opacity: loading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Search"
                        )}

                        {/* {loading ? "Loading..." : "Search"} */}
                      </button>
                      <Button
                        className="btn btn-primary"
                        onClick={() => handleSaveSearch()}
                        disabled={saveloading}
                        style={{
                          cursor: saveloading ? "not-allowed" : "pointer", // Disable cursor when loading
                          opacity: saveloading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {saveloading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Save"
                        )}
                        {/* {loading ? "Loading..." : "Save"} */}
                      </Button>
                    </div>
                  </div>
                  <div className="horizontal-ruler"></div>
                </div>
                <Row>
                  <Col md={4}>
                    <Form.Label>CallOut Type</Form.Label>
                    <Form.Group controlId="typeFilter" className="input-group">
                      <Form.Control
                        as="select"
                        name="call_out_type"
                        value={selectedType}
                        // value={formData.call_out_type}
                        onChange={handleTypeChange}
                        required
                      >
                        <option value="" disabled>
                          Select Type
                        </option>
                        {types.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Label>Category</Form.Label>
                    <Form.Group
                      controlId="categoryFilter"
                      className="input-group"
                    >
                      <Form.Control
                        as="select"
                        name="call_out_category"
                        value={selectedCategory || ""}
                        onChange={(e) => handleCategoryChange(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {categories.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Label>Sub-Category</Form.Label>
                    <Form.Group
                      controlId="subCategoryFilter"
                      className="input-group"
                    >
                      <Form.Control
                        as="select"
                        name="call_out_sub_category"
                        placeholder="sub category"
                        value={
                          selectedSubCategory ? selectedSubCategory.value : ""
                        }
                        onChange={(e) =>
                          handleSubCategoryChange(e.target.value)
                        }
                        required
                        disabled={!selectedCategory}
                      >
                        <option value="" disabled>
                          Select Sub-Category
                        </option>
                        {selectedCategory ? (
                          subCategories[selectedCategory].map((sub) => (
                            <option key={sub} value={sub}>
                              {sub}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            No sub-categories available
                          </option>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Label>Available Date Range</Form.Label>
                    <Form.Group
                      controlId="formFromDate"
                      className="input-group"
                    >
                      <Form.Control
                        type="date"
                        name="from_date"
                        value={formData.from_date}
                        onChange={handleFilterChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formToDate" className="input-group">
                      <Form.Control
                        type="date"
                        name="to_date"
                        value={formData.to_date}
                        onChange={handleFilterChange}
                        required
                        min={formData.from_date}
                      />
                    </Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Group
                      controlId="formLocation"
                      className="input-group2"
                    >
                      {/* <AutoComplete onSuburbSelect={handleSuburbSelect} /> */}
                      <AutoComplete
                        onSuburbSelect={handleSuburbSelect}
                        location={formData.location}
                      />
                    </Form.Group>
                    <br></br>

                    <Form.Label>Search Radius </Form.Label>

                    <Form.Group controlId="formSearchRadius">
                      <Form.Control
                        as="select"
                        name="search_radius"
                        // value={formData.search_radius}
                        value={300}
                        onChange={handleRadiusChange}
                        className="input-group"
                        disabled
                      >
                        <option value={150}>150 km</option>
                        <option value={300}>300 km</option>
                        <option value={600}>600 km</option>
                        <option value={1200}>1200 km</option>
                        <option value={2400}>2400 km</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="pricePointFilter">
                      <Form.Label>Price Point Range</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          ${pricePoint[0]}
                        </span>
                        <div style={{ flex: 1, margin: "0 10px" }}>
                          <Slider
                            min={0}
                            max={5000}
                            value={pricePoint}
                            onChange={handlePricePointChange}
                            railStyle={{ background: "#d3d3d3", height: "4px" }}
                            trackStyle={[
                              { background: "#007bff", height: "4px" },
                            ]}
                            handleStyle={[
                              {
                                borderColor: "#007bff",
                                height: "14px",
                                width: "14px",
                                marginLeft: "-7px",
                                marginTop: "-5px",
                                backgroundColor: "#007bff",
                              },
                              {
                                borderColor: "#007bff",
                                height: "14px",
                                width: "14px",
                                marginLeft: "-7px",
                                marginTop: "-5px",
                                backgroundColor: "#007bff",
                              },
                            ]}
                            range
                            required
                          />
                        </div>
                        <span style={{ marginLeft: "10px" }}>
                          ${pricePoint[1]}
                        </span>
                      </div>
                    </Form.Group>
                    <input
                      type="hidden"
                      name="from_price_point"
                      // value={pricePoint[0]}
                      value={formData.from_price_point}
                    />
                    <input
                      type="hidden"
                      name="to_price_point"
                      // value={pricePoint[1]}
                      value={formData.to_price_point}
                    />
                    <br />
                    <Form.Group controlId="formKeywords">
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter keywords and press Enter"
                        name="keywords"
                        // onChange={handleFilterChange}
                        // required
                        onKeyDown={handleKeywordChange}
                      />
                      <div className="mt-2">
                        {formData.keywords.map((keyword, index) => (
                          <Badge
                            key={index}
                            pill
                            variant="primary"
                            className="mr-1"
                            onClick={() => removeKeyword(keyword)}
                            style={{ cursor: "pointer" }}
                          >
                            {keyword} <span>&times;</span>
                          </Badge>
                        ))}
                      </div>
                    </Form.Group>
                    {/* <Form.Group controlId="formKeywords">
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Keywords"
                        name="keywords"
                        onChange={handleFilterChange}
                      />
                    </Form.Group> */}
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Collapse>
        <br></br>
        <br></br>
        <Row
          className="mb-3"
          style={{
            justifyContent: "center",
            alignContent: "center",
            //   alignItems: "center",
          }}
        >
          <Col xs={12} md={5}>
            <div style={{ paddingBottom: "10%" }}>
              <Row className="justify-content-center">
                <br />
                <Row>
                  {Array.isArray(callouts) && callouts.length > 0 ? (
                    callouts.map((call_out) => (
                      <div
                        key={call_out._id}
                        className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                        // onClick={() =>
                        //   handleSearchResultClick(call_out.location)
                        // }
                        onClick={() =>
                          handleSearchResultClick(call_out.location)
                        }
                        // style={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   openModal(call_out._id, call_out.author_profile_id)
                        // }
                      >
                        <div className="events__item hover__active">
                          <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                            {/* <div className="events__image">
                            <img
                              src={call_out.profile_picture_url}
                              alt="call_out"
                              className="events__img"
                            />
                          </div> */}
                            <div className="events__content">
                              <div className="events__meta">
                                <h6>{call_out.title}</h6>
                              </div>

                              <div className="events__details-inline">
                                <strong>Type:</strong>
                                {call_out.call_out_type}
                              </div>
                              <div className="events__details-inline">
                                <strong> Category:</strong>
                                {call_out.call_out_category}
                              </div>
                              <div className="events__details-inline">
                                <strong>Sub category:</strong>
                                {call_out.call_out_sub_category}
                              </div>
                              {/* <div className="events__details-inline">
                                <strong> Available Dates:</strong>
                                {Array.isArray(call_out.available_dates) ? (
                                  call_out.available_dates.map((date) => (
                                    <span key={date}>{date}</span>
                                  ))
                                ) : (
                                  <span>No dates available</span>
                                )}
                              </div> */}
                              <div className="events__details-inline">
                                <strong> Price Point:</strong>
                                AUD {call_out.price_point_value_from}-
                                {call_out.price_point_value_to}
                              </div>

                              <div className="card-icons">
                                <FiFlag
                                  onClick={() => handleFlagClick(call_out._id)}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                />
                                {flaggedCallouts[call_out._id] && (
                                  <FaBookmark style={{ color: "red" }} />
                                )}
                              </div>
                            </div>
                            <div className="events__more">
                              <a
                                key={call_out._id}
                                className="link-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openModal(
                                    call_out._id,
                                    call_out.author_profile_id
                                  );
                                }}
                                style={{ cursor: "pointer", zIndex: 1000 }}
                              >
                                View more
                                <i className="fas fa-arrow-right"></i>
                                <i className="fas fa-arrow-right"></i>
                              </a>
                              {/* <a
                                //   href="#"
                                className="link-btn"
                                key={call_out._id}
                                // onClick={() =>
                                //   openModal(
                                //     call_out._id,
                                //     call_out.author_profile_id
                                //   )
                                // }
                                onClick={() =>
                                  handleSearchResultClick(call_out.location)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                View Location
                                <i className="fas fa-arrow-right"></i>
                                <i className="fas fa-arrow-right"></i>
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No CallOuts found</p>
                  )}
                  {callouts && callouts.length > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn btn-primary me-2"
                        disabled={!previousUrl || loadingPrevious}
                        onClick={() =>
                          previousUrl && handlePagination(previousUrl, false)
                        }
                        style={{
                          opacity: loadingPrevious || !previousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor:
                            loadingPrevious || !previousUrl
                              ? "not-allowed"
                              : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingPrevious ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Previous"
                        )}
                      </button>

                      <button
                        className="btn btn-primary"
                        disabled={!nextUrl || loadingNext}
                        onClick={() =>
                          nextUrl && handlePagination(nextUrl, true)
                        }
                        style={{
                          opacity: loadingNext || !nextUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor:
                            loadingNext || !nextUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingNext ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Next"
                        )}
                      </button>
                    </div>
                  )}
                </Row>
              </Row>

              <Modal
                show={showFlagModal}
                onHide={() => setShowFlagModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Flag CallOut</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="sectionType">
                      <Form.Label>Section</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedSection}
                        onChange={handleSectionChange}
                      >
                        <option value="" disabled>
                          Select a section
                        </option>
                        {sectionType.map((section, index) => (
                          <option
                            key={index}
                            value={section.toUpperCase().replace(" ", "_")}
                          >
                            {section}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="flagComment">
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your comment"
                        value={flagComment}
                        onChange={handleFlagCommentChange}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowFlagModal(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleFlagSave}
                    disabled={loadingSaveComment}
                    style={{
                      opacity: loadingSaveComment ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                      cursor: loadingSaveComment ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                      transition: "opacity 0.3s ease", // Smooth transition for opacity change
                      minWidth: "100px",
                    }}
                  >
                    {loadingSaveComment ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{
                          width: "1.2rem",
                          height: "1.2rem",
                          borderWidth: "0.1rem",
                        }}
                      />
                    ) : (
                      "Save Comment"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showModal}
                onHide={closeModal}
                size="xl"
                // style={{ minWidth: "100% !important" }}
              >
                <Modal.Header>
                  <button
                    style={{
                      position: "absolute",
                      // top: "10px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {/* Render ViewPerformerProfile component if performer ID is selected */}
                  {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                  <div>{modalContent}</div>
                </Modal.Body>
              </Modal>

              {/* <Modal
                show={showModal}
                onHide={closeModal}
                size="xl"
                style={{ minWidth: "110% !important" }}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  {selectedCalloutId && (
                    <NewCalloutPerformerView calloutId={selectedCalloutId} />
                  )}
                </Modal.Body>
              </Modal> */}
            </div>
          </Col>
          {!isMobile && (
            <Col xs={12} md={6}>
              <div
                style={{
                  position: "sticky",
                  top: "10px",
                  height: "650px",
                }}
              >
                <MapComponent
                  locations={calloutLocations}
                  onLocationClick={handleLocationClick}
                  centerLocation={centerLocation}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default NewCalloutSearch;
