// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay-supportT {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.modal-content-supportT {
  background-color: #fff;
  border-radius: 8px;
  width: 90%; /* Increase width */
  max-width: 1000px; /* Set a larger maximum width */
  height: 80%; /* Increase height */
  max-height: 90%; /* Set a maximum height */
  padding: 40px; /* Add more padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  position: relative;
  overflow-y: auto; /* Add scroll if content overflows */
}

.modal-close-supportT {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content-supportT h2 {
  margin-top: 0;
}

.modal-content-supportT p {
  margin-bottom: 0;
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC,EAAE,gCAAgC;EACtE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,yCAAyC;AAC1D;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,UAAU,EAAE,mBAAmB;EAC/B,iBAAiB,EAAE,+BAA+B;EAClD,WAAW,EAAE,oBAAoB;EACjC,eAAe,EAAE,yBAAyB;EAC1C,aAAa,EAAE,qBAAqB;EACpC,wCAAwC,EAAE,qCAAqC;EAC/E,kBAAkB;EAClB,gBAAgB,EAAE,oCAAoC;AACxD;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".modal-overlay-supportT {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensure it's on top of other elements */\n}\n\n.modal-content-supportT {\n  background-color: #fff;\n  border-radius: 8px;\n  width: 90%; /* Increase width */\n  max-width: 1000px; /* Set a larger maximum width */\n  height: 80%; /* Increase height */\n  max-height: 90%; /* Set a maximum height */\n  padding: 40px; /* Add more padding */\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */\n  position: relative;\n  overflow-y: auto; /* Add scroll if content overflows */\n}\n\n.modal-close-supportT {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.modal-content-supportT h2 {\n  margin-top: 0;\n}\n\n.modal-content-supportT p {\n  margin-bottom: 0;\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
