import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { Button, Container, Row, Col, Card, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const profileId = Cookies.get("profileOwnerId");
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    let returnUrl = "";

    if (profileId.startsWith("PROFILE-PERFORMER")) {
      returnUrl = `${window.location.origin}/performer/${profileId}`;
    } else {
      returnUrl = `${window.location.origin}/venue/${profileId}`;
    }

    // const returnUrl = `${window.location.origin}/performer/${profileId}`;
    try {

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
          // return_url: `http://localhost:3000/performer/${profileId}`,
        },
      });

      if (error) {
        setErrorMessage(error.message);
        Swal.fire({
          icon: "error",
          title: "Payment failed. Please try again.",
          text: error.message,
        });
      } else if (setupIntent && setupIntent.status === "succeeded") {
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "Your payment setup is complete.",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      }
    } catch (e) {
      console.error("An unexpected error occurred:", e);
    } finally {
      setLoading(false); // This will be executed no matter what
    }

  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100 justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow p-4">
            <Card.Body>
              <Card.Title className="text-center mb-4">
                Setup Payment Method
              </Card.Title>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <form onSubmit={handleSubmit}>
                <PaymentElement />
                <Button
                  type="submit"
                  disabled={loading || !stripe}
                  className="w-100 mt-3"
                  variant="primary"
                  style={{
                    cursor: loading ? "not-allowed" : "pointer", // Disable cursor when loading
                    opacity: loading ? 0.6 : 1, // Reduce opacity when loadingPrevious is true                    
                    transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    
                  }}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SetupForm;
