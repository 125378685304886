// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardcontainer {
  max-width: 1200px;
}

.event-card {
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.2s;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-card h5 {
  font-size: 1.25rem;
  margin: 0;
}

.event-card p {
  margin: 0;
  font-size: 1rem;
}

.badge {
  font-size: 0.9rem;
  padding: 0.5em 0.75em;
}

.badge-primary {
  background-color: #007bff;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-warning {
  background-color: #ffc107;
}

.badge-success {
  background-color: #28a745;
}

.badge-secondary {
  background-color: #6c757d;
}

.highlighted {
  border: 2px solid #007bff;
  background-color: #e9f7fe;
}

.clickable-title {
  cursor: pointer;
  color: #000000;
  text-decoration: none;
}

.clickable-title:hover {
  color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/performer/PerformerDashboard.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".dashboardcontainer {\n  max-width: 1200px;\n}\n\n.event-card {\n  background-color: #fff;\n  border-radius: 8px;\n  transition: transform 0.2s;\n}\n\n.event-card:hover {\n  transform: translateY(-5px);\n}\n\n.event-card h5 {\n  font-size: 1.25rem;\n  margin: 0;\n}\n\n.event-card p {\n  margin: 0;\n  font-size: 1rem;\n}\n\n.badge {\n  font-size: 0.9rem;\n  padding: 0.5em 0.75em;\n}\n\n.badge-primary {\n  background-color: #007bff;\n}\n\n.badge-danger {\n  background-color: #dc3545;\n}\n\n.badge-warning {\n  background-color: #ffc107;\n}\n\n.badge-success {\n  background-color: #28a745;\n}\n\n.badge-secondary {\n  background-color: #6c757d;\n}\n\n.highlighted {\n  border: 2px solid #007bff;\n  background-color: #e9f7fe;\n}\n\n.clickable-title {\n  cursor: pointer;\n  color: #000000;\n  text-decoration: none;\n}\n\n.clickable-title:hover {\n  color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
