import { useRef, useEffect, useState } from "react";
import "./acstyles.css";
import "../pages/venue/venue.css";
import "../pages/search/newSearch.css";
import "../pages/opportunities/addNewOpportunity.css";
const AutoComplete = ({ onSuburbSelect }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [error, setError] = useState("");
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const apiKey =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    "AIzaSyAYJCAkpVrsFw_6MIGeOMfoWOFQgZLRdxw";
  const options = {
    // componentRestrictions: { country: "ng" },
    componentRestrictions: { country: "au" },
    fields: [
      "address_components",
      "geometry",
      "icon",
      "name",
      "place_id",
      "formatted_address",
    ],
    types: ["geocode"],
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      console.log("Autocomplete response:", place);
      if (!place) return;

      const addressComponents = place.address_components;
      const suburb = addressComponents.find(
        (component) =>
          component.types.includes("sublocality") ||
          component.types.includes("locality")
      );

      const zipcode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.short_name;

      if (!zipcode) {
        setError("Please select a location with a valid zip code.");
        setSelectedSuburb(null);
        return;
      }

      const formattedSuburb = `${suburb.long_name} ${
        zipcode ? `(${zipcode})` : ""
      }`;
      const locationName = place.name;
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const googleMapPin = place.icon;

      const selectedSuburbData = {
        suburb: suburb ? suburb.long_name : "",
        zipcode: zipcode || "",
        latitude: latitude,
        longitude: longitude,
        location_name: locationName,
        address: address,
        google_map_pin: googleMapPin,
      };

      setSelectedSuburb(selectedSuburbData);
      setError("");

      if (onSuburbSelect) {
        onSuburbSelect(selectedSuburbData);
      }

      // fetchSuburbDetails(place.place_id);
      console.log("place id: ", place.place_id);
      console.log("latitude new: ", place.geometry.location.lat());
      console.log("longitude new: ", place.geometry.location.lng());
      console.log("google map pin: ", place.icon);
      console.log("address: ", place.formatted_address);
    });
  }, [onSuburbSelect]);

  //   const handleSuburbSelect = (suburb) => {
  //     setSelectedSuburb(suburb);
  //   };

  return (
    <div>
      <input
        // className="input-group shadow-box"
        className="form-control mb-3 shadow-box input-style"
        style={{
          // border: "1px solid #dcdcdc",
          borderRadius: "5px",
          // width: "244px",
          // width: "100%",
          padding: " 0.75rem",
          marginBottom: "1rem",
          transition: "border-color 0.3s",
          // marginTop: "15%",
          // marginLeft: "-30%",
        }}
        ref={inputRef}
        onChange={(e) => setSelectedSuburb(e.target.value)}
        // required
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};
export default AutoComplete;
