// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthProvider';

// export default function ProtectedRoute({ children }) {
//   const { user } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!user || !user.isAuthenticated) {
//       // Redirect to login if user is not authenticated
//       navigate('/auth-login', { replace: true });
//     }
//   }, [navigate, user]);

//   return children;
// }

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import Cookies from "js-cookie";
import { useAuth } from "./AuthProvider";

export default function ProtectedRoute({ children }) {
  // const auth = useAuth();
  const { user } = useAuth();
  // const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [profileStatus, setProfileStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const profileId = Cookies.get("profileOwnerId");

  useEffect(() => {
    // if (authLoading) {
    //   return;
    // }

    // if (!auth || !auth.user) {
    //   // Handle the case where user is null or auth is not yet initialized
    //   navigate("/login", { replace: true });
    //   return;
    // }

    // if (!auth.user.isAuthenticated) {
    //   navigate("/login", { replace: true });
    //   return;
    // }

    // if (!user || !user.isAuthenticated) {
    //   navigate("/login", { replace: true });
    //   return;
    // }

    const fetchProfileStatus = async () => {
      if (!profileId) return;

      const profileType = profileId.split("-")[1];
      let apiUrl = "";

      if (profileType === "PERFORMER") {
        apiUrl = `/v1/performer/view-profile/${profileId}/section/complete`;
      } else if (profileType === "VENUE") {
        apiUrl = `/v1/venue/view-profile/${profileId}/section/complete`;
      }

      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        let status = "";
        if (profileType === "PERFORMER") {
          status = response.data.performer_info.status;
        } else if (profileType === "VENUE") {
          status = response.data.venue_info.status;
        }

        setProfileStatus(status);

        if (status !== "ACTIVE") {
          navigate("/restricted", { replace: true });
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        navigate("/error", { replace: true });
      } finally {
        setLoading(false);
      }
    };

    fetchProfileStatus();
  }, [navigate, profileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (authLoading || loading) {
  //   return <div>Loading...</div>;
  // }

  return profileStatus === "ACTIVE" ? children : null;
}
