import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import descriptionImage from "../../assect/images/Landing_Image1.jpg";
import "./landing.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import axiosInstance from "../auth/axiosInstance";
import Swal from "sweetalert2";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import ZohoForm from "../../components/ZohoForm";
import { FiHexagon } from "react-icons/fi";

function NewUserLandingPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [bannerData, setBannerData] = useState(null);
  const accessToken = Cookies.get("access");
  const [performerPricing, setPerformerPricing] = useState([]);
  const [venuePricing, setVenuePricing] = useState([]);
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axiosInstance.get("/v1/banner/top", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setBannerData(response.data.data.banner);
        console.log("banner data: ", response.data.data.banner);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);

  // useEffect(() => {
  //   const fetchPerformerPricing = async () => {
  //     try {
  //       const response = await axiosInstance.post(
  //         `/v1/billing/get-subscription-package`,
  //         { name: "Performer Subscription." },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setPerformerPricing(response.data);
  //     } catch (error) {
  //       console.error("Error fetching performer pricing:", error);
  //     }
  //   };

  //   const fetchVenuePricing = async () => {
  //     try {
  //       const response = await axiosInstance.post(
  //         `/v1/billing/get-subscription-package`,
  //         { name: "Venue Subscription." },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setVenuePricing(response.data);
  //     } catch (error) {
  //       console.error("Error fetching venue pricing:", error);
  //     }
  //   };

  //   fetchPerformerPricing();
  //   fetchVenuePricing();
  // }, []);

  useEffect(() => {
    const fetchPerformerPricing = async () => {
      try {
        console.log("Fetching performer pricing...");
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Performer Subscription." },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Performer Pricing API Response:", response.data);
        setPerformerPricing(response.data);
      } catch (error) {
        console.error("Error fetching performer pricing:", error);
      }
    };

    const fetchVenuePricing = async () => {
      try {
        console.log("Fetching venue pricing...");
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Venue Subscription." },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Venue Pricing API Response:", response.data);
        setVenuePricing(response.data);
      } catch (error) {
        console.error("Error fetching venue pricing:", error);
      }
    };

    fetchPerformerPricing();
    fetchVenuePricing();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoaded(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/auth-signup");
  };

  const handleFAQ = () => {
    navigate("/faqs");
  };

  const handleHover = (e) => {
    e.target.classList.toggle("hovered");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    const payload = { email: email };

    try {
      const response = await axios.post("/v1/newsletter/register", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        MySwal.fire({
          title: "Success!",
          text: "Thank you for subscribing!",
          icon: "success",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      } else {
        MySwal.fire({
          title: "Error",
          text: "Failed to subscribe. Please try again.",
          icon: "error",
          confirmButtonText: '<i class="fa fa-times"></i> Retry',
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      }
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "An error occurred. Please try again.",
        icon: "error",
        confirmButtonText: '<i class="fa fa-times"></i> Retry',
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
    }
  };

  return (
    <>
      <Container
        fluid
        style={{
          minHeight: "100vh",
          position: "relative",
          backgroundImage: `url(${descriptionImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          animation: "background-fadein 2s ease-in-out",
        }}
      >
        <Row
          className="justify-content-end"
          style={{
            padding: "50px 20px",
            backgroundColor: "#102733",
            color: "#fff",
          }}
        >
          <Col
            className="buttonsContainer"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="button"
              onMouseEnter={handleHover}
              onClick={handleLogin}
              onMouseLeave={handleHover}
              style={{
                padding: "8px 25px",
                borderRadius: "5px",
                fontWeight: "bold",
                marginRight: "10px",
                transition: "background-color 0.3s",
              }}
            >
              Log In
            </button>
            <button
              className="button"
              onMouseEnter={handleHover}
              onClick={handleSignup}
              onMouseLeave={handleHover}
              style={{
                padding: "8px 25px",
                borderRadius: "5px",
                fontWeight: "bold",
                marginRight: "10px",
                transition: "background-color 0.3s",
              }}
            >
              Create Account
            </button>
          </Col>
        </Row>

        {/* Marketing Banner */}
        {/* {bannerData && (
          <Row
            className="marketing-banner"
            style={{
              backgroundImage: `url(${bannerData.banner_url})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#fff",
              padding: "40px 20px",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
              animation: "banner-fadein 2s ease-in-out",
            }}
          >
            <Col className="text-center"> */}
        {/* <h2>Banner Title</h2>
            <p> Banner Details</p> */}
        {/* <h2>{bannerData.title}</h2>
              <p>{bannerData.description}</p>
            </Col>
          </Row>
        )} */}

        <div>
          {bannerData ? (
            <div>
              <h1>{bannerData.title}</h1>
              <img src={bannerData.banner_url} alt={bannerData.description} />
              <p>{bannerData.description}</p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <Row
          className="justify-content-center align-items-center"
          style={{ position: "relative", marginTop: "50px", width: "100%" }}
        >
          <Col md={6} className="text-center content-wrapper">
            <h1
              className="page-title"
              style={{
                fontSize: "6em",
                color: "white",
                fontWeight: "bold",
                animation: isLoaded ? "zoomIn 1s ease-in-out" : "",
              }}
            >
              WELCOME TO OGOTRIX!!!!
            </h1>
            <p
              className="para-desc text-white title-dark mb-0"
              style={{
                fontSize: "1.5em",
                animation: isLoaded ? "fadeInUp 1s ease-in-out 0.3s" : "",
              }}
            >
              Now available for early adopters!
            </p>
            <br />
            <div className="content-aligned">
              <button
                className="button"
                onMouseEnter={handleHover}
                onClick={handleSignup}
                onMouseLeave={handleHover}
                style={{
                  opacity: isLoaded ? 1 : 0,
                  animation: isLoaded ? "fadeIn 0.5s ease-in-out" : "",
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                }}
              >
                Create Account
              </button>
            </div>
            <br />
            <br></br>

            {/* <ZohoForm /> */}
            {/* <center>
              <p
                className="para-desc text-white title-dark mb-0 text-center"
                style={{
                  fontSize: "1.5em",
                  animation: isLoaded ? "fadeInUp 1s ease-in-out 0.3s" : "",
                }}
              >
                Subscribe to our Newsletter!
              </p>
            </center> */}
            <div
              className="subscribe-wrapper"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  width: "300px",
                }}
              />
              <button
                className="button"
                onMouseEnter={handleHover}
                onClick={handleSubscribe}
                onMouseLeave={handleHover}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Subscribe for Newsletter
              </button>
            </div>
          </Col>

          {/* Start Free Trial Card */}
          <Col md={6}>
            <Card
              className="start-trial-card text-center"
              style={{ padding: "30px" }}
            >
              <Card.Body>
                <Card.Title>Start Your Free Trial</Card.Title>
                <Card.Text>
                  Experience all the features and benefits of Ogotrix for free.
                  No credit card required.
                </Card.Text>
                <button
                  className="button"
                  onClick={handleSignup}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHover}
                  style={{
                    alignSelf: "center",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Start Free Trial
                </button>
              </Card.Body>
            </Card>
            <button
              variant="info"
              onClick={handleFAQ}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              className="button"
              style={{
                alignSelf: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Visit FAQ
            </button>
          </Col>
        </Row>

        {/* Features Section */}
        <Row
          className="justify-content-center features-section"
          style={{ marginTop: "100px", padding: "20px" }}
        >
          {[
            "Performers",
            "Venues",
            "Opportunities",
            "Callouts",
            "Classifieds",
            "Booking Process",
          ].map((feature, index) => (
            <Col md={4} key={index} className="feature-card">
              <Card>
                <Card.Body>
                  <Card.Title>{feature}</Card.Title>
                  <Card.Text>
                    {/* You can add a short description for each feature here */}
                    Description of {feature}.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="justify-content-center">
          <div className="row g-2 justify-content-center align-items-center">
            {Array.isArray(performerPricing) && performerPricing.length > 0 ? (
              performerPricing.map((item, index) => (
                <Col
                  key={index}
                  lg={6}
                  md={6}
                  sm={12}
                  className="mb-4 d-flex justify-content-center"
                >
                  <div
                    className={`${
                      performerPricing.length === 1
                        ? "col-lg-6 col-md-8 col-sm-10"
                        : "col-lg-4 col-md-6"
                    }`}
                  >
                    <div
                      className="card rounded-3"
                      style={{
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <div className="p-4 border-bottom text-center">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                          <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                            <FiHexagon className="hexagon" />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <i className="fs-2 text-primary"></i>
                            </div>
                          </div>
                          <h5 className="text-primary">{item.name}</h5>
                          <div className="d-flex justify-content-center">
                            <span className="fs-5">$</span>
                            <span className="h2 fw-semibold mb-0">
                              {(item.unit_amount / 100).toFixed(2)}
                            </span>
                            <span className="fs-6 align-self-end">/Month</span>
                          </div>
                        </div>
                        <div className="p-4">
                          <h6>Pricing Features:</h6>
                          <ul className="list-unstyled">
                            <li className="text-muted mb-0">
                              <span className="icon fs-5 me-2">
                                <i className="mdi mdi-check-circle align-middle text-success"></i>
                              </span>
                              {item.description || "No description for now"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="text-center">No pricing data available.</div>
            )}

            {Array.isArray(venuePricing) && venuePricing.length > 0 ? (
              venuePricing.map((item, index) => (
                <Col
                  key={index}
                  lg={6}
                  md={6}
                  sm={12}
                  className="mb-4 d-flex justify-content-center"
                >
                  <div
                    className={`${
                      venuePricing.length === 1
                        ? "col-lg-6 col-md-8 col-sm-10"
                        : "col-lg-4 col-md-6"
                    }`}
                  >
                    <div
                      className="card rounded-3"
                      style={{
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <div className="p-4 border-bottom text-center">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                          <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                            <FiHexagon className="hexagon" />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <i className="fs-2 text-primary"></i>
                            </div>
                          </div>
                          <h5 className="text-primary">{item.name}</h5>
                          <div className="d-flex justify-content-center">
                            <span className="fs-5">$</span>
                            <span className="h2 fw-semibold mb-0">
                              {(item.unit_amount / 100).toFixed(2)}
                            </span>
                            <span className="fs-6 align-self-end">/Month</span>
                          </div>
                        </div>
                        <div className="p-4">
                          <h6>Pricing Features:</h6>
                          <ul className="list-unstyled">
                            <li className="text-muted mb-0">
                              <span className="icon fs-5 me-2">
                                <i className="mdi mdi-check-circle align-middle text-success"></i>
                              </span>
                              {item.description || "No description for now"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="text-center">No pricing data available.</div>
            )}
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default NewUserLandingPage;
