import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import logo from "../../assect/images/landing/logo.jpg";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 50px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 60px;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

// const Input = styled.textarea`
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   height: auto; /* Allows the textarea to expand vertically */
//   min-height: 100px; /* Set a minimum height to prevent it from collapsing */
// `;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px; /* Set a minimum height to prevent it from collapsing */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  resize: none; /* Disable manual resizing */

  overflow-y: hidden; /* Hide vertical scrollbar */

  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

function UpdatePerformerEquipReq() {
  const profileId = Cookies.get("profileOwnerId");
  const [briefBio, setBriefBio] = useState([]);
  const [education, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [notableAchievements, setNotableAchievements] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //     async function fetchPerformerBio() {
  //         try {
  //             const response = await axiosInstance.get("/v1/performer/profile", {
  //                 headers: {
  //                     Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //                 },
  //             });

  //             if (response.status === 200) {
  //                 setBriefBio(response.data.);
  //             } else {
  //                 setErrorMessage("Error fetching performer profile data.");
  //             }
  //         } catch (error) {
  //             console.error("Error fetching performer profile:", error);
  //             setErrorMessage("An unexpected error occurred. Please try again.");
  //         }
  //     }

  //     fetchPerformerBio();
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/experience",
        {
          performer_id: profileId,
          brief_bio: briefBio,
          education: education,
          skills: skills,
          notable_achievements: notableAchievements,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage("Performer experience updated successfully.");
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Performer experience updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating performer experience.");
        console.error("Error updating performer experience:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer experience:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleChange = (event, setStateFunction) => {
    const { value, scrollHeight } = event.target;
    setStateFunction(value);
    event.target.style.height = "auto"; // Reset the height
    event.target.style.height = scrollHeight + "px"; // Set the dynamic height
  };

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Experience</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        <InputGroup>
          <Label htmlFor="briefBio">Brief Bio</Label>
          <InputWrapper>
            <Textarea
              id="briefBio"
              className="form-control"
              value={briefBio}
              onChange={(e) => handleChange(e, setBriefBio)}
              placeholder="Update brief bio..."
              required
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            required
                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="education">Education</Label>
          <InputWrapper>
            <Textarea
              id="education"
              className="form-control"
              value={education}
              onChange={(e) => handleChange(e, setEducation)}
              placeholder="Update education..."
              required
            />

            {/* <Input
                            type="text"
                            className="form-control"
                            id="technicalRequirements"

                            value={technicalRequirements}
                            onChange={(e) => setTechnicalRequirements(e.target.value)}

                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="skills">Skills</Label>
          <InputWrapper>
            <Textarea
              id="skills"
              className="form-control"
              value={skills}
              onChange={(e) => handleChange(e, setSkills)}
              placeholder="Update skills..."
              required
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            required
                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="notableAchievements">Notable Achievements</Label>
          <InputWrapper>
            <Textarea
              type="text"
              className="form-control"
              id="notableAchievements"
              value={notableAchievements}
              onChange={(e) => handleChange(e, setNotableAchievements)}
              placeholder="Update achievements..."
              required
            />

            {/* <Input
                            type="text"
                            className="form-control"
                            id="technicalRequirements"

                            value={technicalRequirements}
                            onChange={(e) => setTechnicalRequirements(e.target.value)}

                        /> */}
          </InputWrapper>
        </InputGroup>

        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit">
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerEquipReq;
