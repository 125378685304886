import React from "react";
import { Card, Button, Form, Col, Row } from "react-bootstrap";
import "../../pages/venue/venue.css";

function ScheduleEventScreen() {
  return (
    <Row>
      <Col xs={12} md={5} style={{ marginLeft: "120px", marginTop: "120px" }}>
        <Card
          style={{
            borderRadius: "40px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.4)",
            width: "80%",
            backgroundColor: "#418ABB",
            color: "white",
          }}
        >
          <Card.Body>
            <Card.Title>Monday, Jan 11, 2023</Card.Title>
          </Card.Body>
        </Card>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.4)",
              width: "80%",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontWeight: "normal" }}>
                10.00 AM - 11.00 AM
              </Card.Title>
              <Card.Text>Available</Card.Text>
              <Button
                style={{ position: "absolute", top: "40px", right: "10px" }}
              >
                Detail
              </Button>
            </Card.Body>
          </Card>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.4)",
              width: "80%",
            }}
          >
            <Card.Body>
              <Card.Title>11.30 AM - 12.00 PM</Card.Title>
              <Card.Text>Unavailable</Card.Text>
              <Button
                style={{ position: "absolute", top: "40px", right: "10px" }}
              >
                Detail
              </Button>
            </Card.Body>
          </Card>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.4)",
              width: "80%",
            }}
          >
            <Card.Body>
              <Card.Title>11.00 AM - 13.00 PM</Card.Title>
              <Card.Text>Unavailable</Card.Text>
              <Button
                style={{ position: "absolute", top: "40px", right: "10px" }}
              >
                Detail
              </Button>
            </Card.Body>
          </Card>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.4)",
              width: "80%",
            }}
          >
            <Card.Body>
              <Card.Title>9.00 AM - 10.30 AM</Card.Title>
              <Card.Text>Available</Card.Text>
              <Button
                style={{ position: "absolute", top: "40px", right: "10px" }}
              >
                Detail
              </Button>
            </Card.Body>
          </Card>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.4)",
              width: "80%",
            }}
          >
            <Card.Body>
              <Card.Title>11.30 AM - 12.00 PM</Card.Title>
              <Card.Text>Unavailable</Card.Text>
              <Button
                style={{ position: "absolute", top: "40px", right: "10px" }}
              >
                Detail
              </Button>
            </Card.Body>
          </Card>
          {/* Add more cards as needed */}
        </div>
      </Col>
      <Col xs={12} md={5} style={{ marginRight: "120px", marginTop: "120px" }}>
        <h2>Schedule Event</h2>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>
              <strong>Date</strong>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Date is not editable.filled when user clicks on calendar"
            />
          </Form.Group>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Group controlId="formStartTime" style={{ width: "45%" }}>
              <Form.Label>Start Time</Form.Label>
              <Form.Control as="select">
                <option value="09:00">09:00 AM</option>
                <option value="09:30">09:30 AM</option>
                <option value="10:00">10:00 AM</option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formEndTime" style={{ width: "45%" }}>
              <Form.Label>End Time</Form.Label>
              <Form.Control as="select">
                <option value="10:00">10:00 AM</option>
                <option value="10:30">10:30 AM</option>
                <option value="11:00">11:00 AM</option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>
          </div>
          <br />
          <Form.Group controlId="formTitle">
            <Form.Label>
              <strong>Message Request</strong>
            </Form.Label>
            <Form.Control as="textarea" style={{ height: "400px" }} />
          </Form.Group>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              type="submit"
              style={{ marginRight: "40px" }}
            >
              Send
            </Button>{" "}
            <Button variant="secondary" type="button">
              Discard
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
}

export default ScheduleEventScreen;
