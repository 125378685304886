// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupons-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.coupons-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.coupons-table th,
.coupons-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.coupons-table th {
  background-color: #f2f2f2;
  color: black;
}

.coupons-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.coupons-table tr:hover {
  background-color: #ddd;
}

.coupons-table th {
  padding-top: 12px;
  padding-bottom: 12px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/OtherPages/Coupons.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".coupons-container {\n  font-family: Arial, sans-serif;\n  margin: 20px;\n}\n\n.coupons-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.coupons-table th,\n.coupons-table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\n.coupons-table th {\n  background-color: #f2f2f2;\n  color: black;\n}\n\n.coupons-table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.coupons-table tr:hover {\n  background-color: #ddd;\n}\n\n.coupons-table th {\n  padding-top: 12px;\n  padding-bottom: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
