import React, { useState, useEffect } from "react";
import "./opportunity.css";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
// import dummyProfilePicture from "../assect/images/client/jezzypic.jpg";
// import dummyProfilePicture2 from "../assect/images/client/kayapic.jpg";
// import dummyProfilePicture3 from "../assect/images/client/01.jpg";
import "./venue.css";
import Swal from "sweetalert2";

const OpportunityPerformerView = () => {
  const [opportunity, setOpportunity] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId || Cookies.get("opportunityId");
  const [hasApplied, setHasApplied] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        console.log("opportunity details: ", response.data.opportunity);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    const checkIfApplied = async () => {
      const loggedInProfileId = Cookies.get("profileOwnerId");
      const payload = {
        opportunity_id: selectedOpportunityId,
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/has-applied",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setHasApplied(response.data.has_applied);
        } else {
          console.error(
            "Error checking application status:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchOpportunity();
    checkIfApplied();
  }, [selectedOpportunityId, accessToken]);

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  const handleApplyButtonClick = async () => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const opportunityId = selectedOpportunityId;

    const payload = {
      opportunity_id: opportunityId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post("/v1/booking/apply", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log("Application successful:", response.data);
        navigate(`/performer/opportunity/${opportunityId}`);
      } else {
        console.error("Application error:", response.status, response.data);
      }
    } catch (error) {
      console.error("Request error:", error);
      let errorMessage = "There was an error booking opportunity.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const handleWithdrawButtonClick = async () => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      opportunity_id: selectedOpportunityId,
      logged_in_profile_id: loggedInProfileId,
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to withdraw your application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, withdraw",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        const response = await axiosInstance.post(
          "/v1/booking/withdraw/application/by-performer",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("Withdrawal successful:", response.data);
          setHasApplied(false);
          setWithdrawn(true);
          navigate(`/performer/opportunity/${selectedOpportunityId}`);
        } else {
          console.error("Withdrawal error:", response.status, response.data);
        }
      } catch (error) {
        console.error("Request error:", error);
        let errorMessage = "There was an error withdraw your application.";

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }

        Swal.fire("Error", errorMessage, "error");
      }
    }
  };

  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center min-vh-100 background-overlay"
      style={{
        // backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="container p-4 rounded shadow"> */}

      <div className="oppcontainer">
        <h1 className="applicants text-left" style={{ color: "white" }}>
          {opportunity?.title}
        </h1>
        <div className="oppbox">
          {opportunity && (
            <>
              <div className="oppdescription">
                {opportunity.short_description}
              </div>
              <div className="oppdetails">
                <div className="oppdetailRow">
                  <strong>Venue:</strong> <span>{opportunity.venue}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Status:</strong> <span>{opportunity.status}</span>
                </div>
                {/* <div className="oppdetailRow">
                  <strong>Performer:</strong>{" "}
                  <span>{opportunity.performers.performer_name}</span>
                </div> */}
                <div className="oppdetailRow">
                  <strong>Opportunity Type:</strong>{" "}
                  <span>{opportunity.opportunity_types}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Genre:</strong>{" "}
                  <span>{opportunity.opportunity_genres}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Date:</strong>{" "}
                  <span>
                    {formatDate(opportunity.from_date)} to{" "}
                    {formatDate(opportunity.to_date)}
                  </span>
                </div>
                <div className="oppdetailRow">
                  <strong>Duration:</strong>{" "}
                  <span>
                    {formatTime(opportunity.from_time)} to{" "}
                    {formatTime(opportunity.to_time)}
                  </span>
                </div>
                <hr className="hrStyle" />
                <div>
                  <strong>Description:</strong>{" "}
                  {opportunity.descriptions[0].body}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="buttonsContainer">
          {withdrawn ? (
            <span className="label">Cancelled by you</span>
          ) : hasApplied ? (
            <button
              className="oppbutton"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              style={{ padding: "10px 60px" }}
              onClick={handleWithdrawButtonClick}
            >
              WITHDRAW
            </button>
          ) : (
            <button
              className="oppbutton"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              style={{ padding: "10px 60px" }}
              onClick={handleApplyButtonClick}
            >
              APPLY
            </button>
          )}
          {/* <div>
                  <strong>Opportunity has been deleted </strong>
                </div> */}
        </div>
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default OpportunityPerformerView;
