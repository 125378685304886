import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "./axiosInstance";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import PasswordChecklist from "react-password-checklist";
import Spinner from "react-bootstrap/Spinner";

const ChangePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  new_password: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password doesn't match the criteria"
    ),
  new_password_again: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});

export default function ChangePassword() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      new_password_again: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/v1/auth/change-password", {
          current_password: values.current_password,
          new_password: values.new_password,
          new_password_again: values.new_password_again,
        });

        if (response.status === 200) {
          setSuccess(response.data.data.message);
          navigate("/"); // Navigate to homepage on success
          console.log("Password changed successfully");
        } else {
          setError("Error updating password.");
        }
      } catch (error) {
        console.error("Error updating password:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{
          // backgroundImage: `url(${bg3})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Change your password</h5>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="current_password"
                    name="current_password"
                    placeholder="Current Password"
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="current_password">Current Password</label>
                  {formik.errors.current_password &&
                    formik.touched.current_password && (
                      <div className="text-danger">
                        {formik.errors.current_password}
                      </div>
                    )}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="new_password"
                    name="new_password"
                    placeholder="New Password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="new_password">New Password</label>
                  {formik.errors.new_password &&
                    formik.touched.new_password && (
                      <div className="text-danger">
                        {formik.errors.new_password}
                      </div>
                    )}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="new_password_again"
                    name="new_password_again"
                    placeholder="Confirm New Password"
                    value={formik.values.new_password_again}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="new_password_again">
                    Confirm New Password
                  </label>
                  {formik.errors.new_password_again &&
                    formik.touched.new_password_again && (
                      <div className="text-danger">
                        {formik.errors.new_password_again}
                      </div>
                    )}
                </div>

                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "lowercase",
                  ]}
                  minLength={8}
                  value={formik.values.new_password}
                  valueAgain={formik.values.new_password_again}
                />

                <button
                  className="btn btn-primary w-100"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}
                </button>

                {error && <div className="text-danger mt-2">{error}</div>}
                {success && <div className="text-success mt-2">{success}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
