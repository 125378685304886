// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-table {
    font-size: 14px;
  }
  
  .billing-table th, .billing-table td {
    padding: 5px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }

  .back-arrow {
    font-size: 24px; /* Size of the arrow */
    margin-bottom: 10px; /* Space below the arrow */  
    background-color: #F8F9FA;  
    color: black;
    font-weight: bold; 
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/pricing/BillingAndPayment.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe,EAAE,sBAAsB;IACvC,mBAAmB,EAAE,0BAA0B;IAC/C,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".billing-table {\n    font-size: 14px;\n  }\n  \n  .billing-table th, .billing-table td {\n    padding: 5px;\n  }\n  \n  .table-responsive {\n    overflow-x: auto;\n  }\n\n  .back-arrow {\n    font-size: 24px; /* Size of the arrow */\n    margin-bottom: 10px; /* Space below the arrow */  \n    background-color: #F8F9FA;  \n    color: black;\n    font-weight: bold; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
