import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import profileImage from "../../assect/images/client/02.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { Modal, Spinner } from "react-bootstrap";
import NewPerformerProfileTest from "../OtherPages/newPerformerProfile";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const ApplicantsColumn = styled.div`
  flex: 1;
  padding: 2px;
  margin-right: 10px;
  min-width: 200px;
  max-width: 300px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 1px;
  margin-right: 3%;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 2px;

  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 8px 25px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-family: "Hind", sans-serif;
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const ApplicantItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ApplicantName = styled.div`
  margin-left: 10px;
  font-size: 16px;
  color: #333;
`;

const BookingsColumn = styled.div`
  flex: 1;
  padding: 2px;

  min-width: 400px;
  max-width: 500px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const BookingRow = styled.tr`
  &.frozen {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;

    .oppbtn-danger {
      pointer-events: auto;
      opacity: 1 !important;
      cursor: pointer;
      background-color: #dc3545;
      color: white;

      &:hover {
        background-color: #c82333;
      }
    }
  }
`;

const BookingStatus = styled.span`
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: gray;
`;

const ActionButton = styled.button`
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  transition: background-color 0.3s;

  &.oppbtn-warning {
    background-color: #ffc107;
    color: white;

    &:hover {
      background-color: #e0a800;
    }
  }

  &.oppbtn-success {
    background-color: #28a745;
    color: white;

    &:hover {
      background-color: #218838;
    }
  }

  &.oppbtn-danger {
    background-color: #dc3545;
    color: white;

    &:hover {
      background-color: #c82333;
    }
  }
`;

const NewOpportunityAuthorVenueView = ({ opportunityId, onDeleteSuccess }) => {
  const [activeTab, setActiveTab] = React.useState("opportunityInfo");
  const [opportunity, setOpportunity] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [performerDetails, setPerformerDetails] = useState({});
  const accessToken = Cookies.get("accessToken");
  // const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId; //|| Cookies.get("opportunityId");
  const performerId = Cookies.get("profileOwnerId");
  const profileId = Cookies.get("profileOwnerId");
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [cancelcontractloading, setcancelcontractloading] = useState(false);
  const [rejectloading, setrejectloading] = useState(false);
  const [confirmationloading, setconfirmationloading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [venue, setVenue] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        const fetchedOpportunity = response.data.opportunity;
        fetchVenueDetails(fetchedOpportunity.author_profile_id);
        console.log("opportunity details:", response.data.opportunity);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    const fetchVenueDetails = async (venueId) => {
      try {
        const venueSection = "info";
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${venueId}/section/${venueSection}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("venue info:", response.data.venue_info.profile_name);
          setVenue(response.data.venue_info);
        } else {
          console.error("Error fetching venue details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching venue details:", error);
      }
    };

    fetchOpportunity();
  }, [selectedOpportunityId, accessToken]);

  const getVenueProfileImage = () => {
    return venue?.thumbnail_profile_pic_url || venue?.profile_picture_url;
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const loggedInProfileId = Cookies.get("profileOwnerId");

      const payload = {
        opportunity_id: selectedOpportunityId,
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/view-all-active/by/opportunity",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const fetchedBookings = response.data.bookings.filter(
            (booking) => booking !== null
          );

          // If any booking has status PERFORMER_DOUBLE_CONFIRMED, freeze others
          const performerDoubleConfirmed = fetchedBookings.some(
            (booking) => booking.status === "PERFORMER_DOUBLE_CONFIRMED"
          );

          setBookings(
            fetchedBookings.map((booking) => ({
              ...booking,
              isFrozen:
                performerDoubleConfirmed &&
                booking.status !== "PERFORMER_DOUBLE_CONFIRMED",
            }))
          );

          fetchedBookings.forEach((booking) => {
            fetchPerformerDetails(booking.applicant_profile_id);
          });

          // setBookings(
          //   response.data.bookings.filter((booking) => booking !== null)
          // );
          // console.log("booking response: ", response.data.bookings);
          // response.data.bookings.forEach((booking) => {
          //   fetchPerformerDetails(booking.applicant_profile_id);
          // });
        } else {
          console.error(
            "Error fetching bookings:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchBookings();
  }, [selectedOpportunityId, accessToken]);

  const fetchPerformerDetails = async (performerId) => {
    try {
      const response = await axiosInstance.get(
        `/v1/performer/view-profile/${performerId}/section/complete`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setPerformerDetails((prevDetails) => ({
        ...prevDetails,
        [performerId]: response.data.performer_info,
      }));
    } catch (error) {
      console.error("Error fetching performer details:", error);
    }
  };

  const setLoading = (bookingId, stateKey, value) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [bookingId]: { ...prevLoadingStates[bookingId], [stateKey]: value },
    }));
  };

  const handleSendConfirmation = async (bookingId, performerId) => {
    // setconfirmationloading(true);
    setLoading(bookingId, "confirmation", true);
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      booking_id: bookingId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/booking/confirm/by-venue",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Confirmation Sent!", `Booking ID: ${bookingId}`, "success");

        const confirmedBooking = {
          ...bookings.find((b) => b._id === bookingId),
        };
        confirmedBooking.performer = performerDetails[performerId];
        confirmedBooking.confirmed = true;

        // setBookings((prevBookings) => {
        //   const confirmedBookings = prevBookings.filter((b) => b.confirmed);

        //   const pendingBookings = prevBookings.filter((b) => !b.confirmed);
        //   prevBookings.map((booking) =>
        //     booking._id === bookingId
        //       ? { ...booking, status: "VENUE_CONFIRMED" }
        //       : booking
        //   );
        //   return [
        //     ...confirmedBookings.concat(confirmedBooking),
        //     ...pendingBookings,
        //   ];
        // });

        setBookings((prevBookings) =>
          prevBookings.map((booking) => {
            if (booking._id === bookingId) {
              return { ...booking, status: "VENUE_CONFIRMED", isFrozen: false };
            } else {
              return { ...booking, isFrozen: true };
            }
          })
        );
      } else {
        Swal.fire(
          "Error",
          `Failed to send confirmation: ${response.data.message}`,
          "error"
        );
        // setconfirmationloading(false);
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "Failed to send confirmation", "error");
      // setconfirmationloading(false);
      // setLoading(bookingId, "withdraw", false);
    } finally {
      setLoading(bookingId, "confirmation", false);
    }
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  const handleWithdrawRequest = async (bookingId) => {
    // setLoading(bookingId, "withdraw", true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, withdraw it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(bookingId, "withdraw", true);
        try {
          const response = await axiosInstance.post(
            "/v1/booking/withdraw/confirmation/by-venue",
            {
              booking_id: bookingId,
              logged_in_profile_id: loggedInProfileId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.status === 200) {
            Swal.fire(
              "The request has been withdrawn.",
              `Booking ID: ${bookingId}`,
              "success"
            );
            setBookings((prevBookings) =>
              prevBookings.map((booking) =>
                booking._id === bookingId
                  ? { ...booking, status: "PERFORMER_APPLIED" }
                  : booking
              )
            );
          } else {
            console.error(
              "Error withdrawing booking:",
              response.status,
              response.data
            );
            Swal.fire(
              "Error",
              "An error occurred while withdrawing the booking.",
              "error"
            );
          }
        } catch (error) {
          console.error("Request error:", error);
          Swal.fire("Error", "An error occurred", "error");
        } finally {
          setLoading(bookingId, "withdraw", false);
        }
      } else {
        setLoading(bookingId, "withdraw", false);
      }
    });
  };

  const handleReject = async (bookingId) => {
    // setrejectloading(true);
    // setLoading(bookingId, "reject", true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, reject it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(bookingId, "reject", true);
        try {
          const response = await axiosInstance.post(
            "/v1/booking/reject/by-venue",
            {
              booking_id: bookingId,
              logged_in_profile_id: loggedInProfileId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.status === 200) {
            Swal.fire("Rejected!", "The request has been rejected.", "success");
            setBookings((prevBookings) =>
              prevBookings.filter((booking) => booking._id !== bookingId)
            );
          } else {
            console.error(
              "Error rejecting booking:",
              response.status,
              response.data
            );
            Swal.fire(
              "Error",
              "An error occurred while rejecting the booking.",
              "error"
            );
            // setrejectloading(false);
          }
        } catch (error) {
          console.error("Request error:", error);
          Swal.fire("Error", "An error has occurred.", "error");
          // setrejectloading(false);
        } finally {
          setLoading(bookingId, "reject", false);
        }
      } else {
        setLoading(bookingId, "reject", false);
      }
    });
  };

  const handleAcceptCancellation = async (bookingId) => {
    // setcancelcontractloading(true);
    setLoading(bookingId, "cancel", true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-accept/contract/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Cancellation has been accepted.", "success");

        setBookings((prevBookings) =>
          prevBookings.filter((booking) => booking._id !== bookingId)
        );
      } else {
        console.error(
          "Error accepting cancellation:",
          response.status,
          response.data
        );
        Swal.fire(
          "Error",
          "An error occurred while accept the cancellation.",
          "error"
        );
        // setcancelcontractloading(false);
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
      // setcancelcontractloading(false);
    } finally {
      setLoading(bookingId, "cancel", false);
    }
  };

  const handleDeleteClick = (opportunityId) => {
    setIsLoadingDelete(true);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this opportunity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteOpportunity(opportunityId);
      }
    });

    setIsLoadingDelete(true);
  };

  const handleDeleteOpportunity = async (opportunityId) => {
    setIsLoadingDelete(true);
    try {
      // Fetch opportunity details
      const opportunityResponse = await axiosInstance.get(
        `/v1/opportunity/view/${opportunityId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const opportunity = opportunityResponse.data.opportunity;

      // Fetch bookings for this opportunity
      const bookingResponse = await axiosInstance.post(
        "/v1/booking/view-all-active/by/opportunity",
        { opportunity_id: opportunityId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const bookings = bookingResponse.data.bookings;

      // Check for bookings and status
      if (opportunity.status === "OPEN" && bookings.length > 0) {
        Swal.fire({
          title: "Cannot Delete",
          text: "This opportunity post cannot be deleted while there are active applicants. Please remove all applicants before proceeding.",
          icon: "error",
        });
        return;
      }

      if (opportunity.status === "CONFIRMED") {
        // Convert UTC to user's local time
        const toDateTimeUTC = new Date(opportunity.to_date_time_utc);
        const localToDateTime = new Date(
          toDateTimeUTC.getTime() + new Date().getTimezoneOffset() * 60000
        );

        // Get current date and time
        const currentDateTime = new Date();

        // Calculate the 24-hour limit after the event's end
        const deleteAfterDateTime = new Date(
          localToDateTime.getTime() + 24 * 60 * 60 * 1000
        );

        // Check if it's within 24 hours of the event and there are applicants
        if (currentDateTime < deleteAfterDateTime && bookings.length > 0) {
          Swal.fire({
            title: "Cannot Delete",
            text: "This opportunity has been confirmed with an applicant. To delete this post, please remove the remaining applicants and ensure that 24 hours have passed after the event's completion.",
            icon: "error",
          });
          return;
        }
      }

      // Proceed with deletion if no restrictions
      const deleteResponse = await axiosInstance.get(
        `/v1/opportunity/delete-opportunity/${opportunityId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (deleteResponse.status === 200) {
        Swal.fire(
          "Deleted!",
          "Your opportunity has been successfully deleted.",
          "success"
        );
        onDeleteSuccess();
      } else {
        Swal.fire(
          "Error!",
          `Error deleting opportunity: ${deleteResponse.status}`,
          "error"
        );
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text:
          error.response?.data?.error ||
          "An error occurred while deleting the opportunity.",
        icon: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  // const handleDeleteOpportunity = async (opportunityId) => {
  //   const url = `/v1/opportunity/delete-opportunity/${opportunityId}`;

  //   try {
  //     const response = await axiosInstance.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       Swal.fire("Deleted!", "Your opportunity has been deleted.", "success");
  //       onDeleteSuccess();
  //       // navigate(`/venue/${profileId}`);
  //     } else {
  //       Swal.fire(
  //         "Error!",
  //         `Error deleting opportunity: ${response.status}`,
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     let errorMessage = "There was an error in sign up";

  //     if (error.response && error.response.data && error.response.data.error) {
  //       errorMessage = error.response.data.error;
  //     }
  //     console.log(error.response.data);
  //     console.error("Request error:", error);

  //     Swal.fire({
  //       title: "Error!",
  //       text: errorMessage,
  //       icon: "error",
  //       className: "swal-modal",
  //     });
  //   }
  // };

  const [anyConfirmed, setAnyConfirmed] = useState(false);

  useEffect(() => {
    setAnyConfirmed(
      bookings.some((booking) => booking.status === "VENUE_CONFIRMED")
    );
  }, [bookings]);

  const [selectedPerformerId, setSelectedPerformerId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = (performerId) => {
    if (!performerDetails[performerId]) {
      fetchPerformerDetails(performerId);
    }
    setSelectedPerformerId(performerId);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedPerformerId(null);
    setShowModal(false);
  };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      <BookingsColumn>
        <h2>Applicants</h2>
        <table>
          <tbody>
            {bookings.length === 0 ? (
              <tr>
                <td colSpan="3" className="no-applicants"></td>
              </tr>
            ) : (
              bookings.map((booking) => {
                if (!booking) return null; // Check if booking is null

                const performer =
                  performerDetails[booking.applicant_profile_id];
                const isConfirmed = booking.status === "VENUE_CONFIRMED";
                const isPerformerDeclined =
                  booking.status === "PERFORMER_DECLINED";
                const isVenueWithdrawn = booking.status === "VENUE_WITHDRAWN";
                // const isdoubleConfirmed =
                //   booking.status === "PERFORMER_DOUBLED_CONFIRMED";
                const isdoubleConfirmed =
                  booking.status === "PERFORMER_DOUBLED_CONFIRMED";
                const anyDoubleConfirmed = bookings.some(
                  (b) => b.status === "PERFORMER_DOUBLED_CONFIRMED"
                );

                return (
                  <BookingRow
                    key={booking._id}
                    // className={isConfirmed ? "" : "frozen"}
                    className={
                      (!isConfirmed && anyConfirmed) ||
                      (!isdoubleConfirmed && anyDoubleConfirmed)
                        ? "frozen"
                        : ""
                    }
                  >
                    <td>
                      {performer ? (
                        <div
                          className="d-flex align-items-center"
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            openModal(booking.applicant_profile_id)
                          }
                        >
                          <ProfileImage
                            // src={performer.profile_picture_url}
                            src={
                              performer.thumbnail_profile_pic_url ||
                              performer.profile_picture_url
                            }
                            alt={`${performer.profile_name}`}
                          />
                          <span>{`${performer.profile_name}`}</span>
                        </div>
                      ) : (
                        "Loading..."
                      )}
                    </td>
                    <td colSpan="2">
                      <div
                        className="booking-actions"
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* {isdoubleConfirmed ? (
                          <td colSpan="2">Performer Confirmed</td>
                        ) : isConfirmed ? ( */}
                        {isPerformerDeclined ? (
                          <td colSpan="2">
                            <>
                              {loadingStates[booking._id]?.reject ? (
                                <ActionButton
                                  style={{
                                    cursor: loadingStates
                                      ? "not-allowed"
                                      : "pointer",
                                    opacity: loadingStates ? 0.6 : 1,
                                    transition: "opacity 0.3s ease",
                                    marginRight: "5px",
                                    minWidth: "100px",
                                    opacity: loadingStates ? 0.6 : 1,
                                    transition: "opacity 0.3s ease",
                                  }}
                                  className="oppbtn-danger"
                                  disabled={loadingStates}
                                >
                                  {loadingStates ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    " "
                                  )}
                                </ActionButton>
                              ) : (
                                <>
                                  <ActionButton
                                    className="oppbtn-danger"
                                    onClick={() => handleReject(booking._id)}
                                  >
                                    Remove
                                  </ActionButton>
                                </>
                              )}
                            </>
                          </td>
                        ) : isdoubleConfirmed ? (
                          <td colSpan="2">Performer Confirmed</td>
                        ) : isVenueWithdrawn ? (
                          <td colSpan="2">Venue withdrawn</td>
                        ) : isConfirmed ? (
                          <>
                            {loadingStates[booking._id]?.withdraw ? (
                              <ActionButton
                                style={{
                                  cursor: loadingStates
                                    ? "not-allowed"
                                    : "pointer",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                  marginRight: "5px",
                                  minWidth: "100px",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                }}
                                className="oppbtn-warning"
                                disabled={loadingStates}
                              >
                                {loadingStates ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  " "
                                )}
                              </ActionButton>
                            ) : (
                              <>
                                <ActionButton
                                  style={{
                                    marginRight: "5px",
                                  }}
                                  className="oppbtn-warning"
                                  onClick={() =>
                                    handleWithdrawRequest(booking._id)
                                  }
                                >
                                  Withdraw Request
                                </ActionButton>
                              </>
                            )}
                          </>
                        ) : booking.status ===
                          "PERFORMER_CANCELLED_CONTRACT" ? (
                          <>
                            {loadingStates[booking._id]?.cancel ? (
                              <ActionButton
                                style={{
                                  cursor: loadingStates
                                    ? "not-allowed"
                                    : "pointer",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                  marginRight: "5px",
                                  minWidth: "100px",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                }}
                                className="oppbtn-success"
                                disabled={loadingStates}
                              >
                                {loadingStates ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  " "
                                )}
                              </ActionButton>
                            ) : (
                              <>
                                <ActionButton
                                  style={{ marginRight: "5px" }}
                                  className="oppbtn-success"
                                  onClick={() =>
                                    handleAcceptCancellation(booking._id)
                                  }
                                >
                                  Accept Contract Cancellation
                                </ActionButton>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {loadingStates[booking._id]?.confirmation ? (
                              <ActionButton
                                style={{
                                  cursor: loadingStates
                                    ? "not-allowed"
                                    : "pointer",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                  marginRight: "5px",
                                  minWidth: "100px",
                                  opacity: loadingStates ? 0.6 : 1,
                                  transition: "opacity 0.3s ease",
                                }}
                                className="oppbtn-success"
                                disabled={loadingStates}
                              >
                                {loadingStates ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  " "
                                )}
                              </ActionButton>
                            ) : (
                              <>
                                <ActionButton
                                  style={{ marginRight: "5px" }}
                                  className="oppbtn-success"
                                  onClick={() =>
                                    handleSendConfirmation(booking._id)
                                  }
                                >
                                  Send Confirmation
                                </ActionButton>
                              </>
                            )}
                          </>
                        )}
                        {/* {booking.status !== "PERFORMER_CANCELLED_CONTRACT" && (
                        <div
                          style={{ marginRight: "1%" }}
                          className="button-gap"
                        ></div>
                      )} */}
                        {booking.status !== "PERFORMER_CANCELLED_CONTRACT" &&
                          booking.status !== "PERFORMER_DOUBLED_CONFIRMED" &&
                          booking.status !== "VENUE_WITHDRAWN" &&
                          booking.status !== "PERFORMER_DECLINED" && (
                            <>
                              {loadingStates[booking._id]?.reject ? (
                                <ActionButton
                                  style={{
                                    cursor: loadingStates
                                      ? "not-allowed"
                                      : "pointer",
                                    opacity: loadingStates ? 0.6 : 1,
                                    transition: "opacity 0.3s ease",
                                    marginRight: "5px",
                                    minWidth: "100px",
                                    opacity: loadingStates ? 0.6 : 1,
                                    transition: "opacity 0.3s ease",
                                  }}
                                  className="oppbtn-danger"
                                  disabled={loadingStates}
                                >
                                  {loadingStates ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    " "
                                  )}
                                </ActionButton>
                              ) : (
                                <>
                                  <ActionButton
                                    className="oppbtn-danger"
                                    onClick={() => handleReject(booking._id)}
                                  >
                                    Reject
                                  </ActionButton>
                                </>
                              )}
                            </>
                          )}
                      </div>
                      {/* <BookingStatus className="booking-status">
                        {booking.status.replace(/_/g, " ")}
                      </BookingStatus> */}
                    </td>
                  </BookingRow>
                );
              })
            )}
          </tbody>
        </table>
      </BookingsColumn>
      {opportunity && (
        <>
          <LeftColumn>
            <CategoryTag>{opportunity.status}</CategoryTag>
            <Title>{opportunity?.title}</Title>
            <InfoRow>
              <Info>
                <ProfileImage
                  src={getVenueProfileImage()}
                  alt="Venue Profile"
                />
              </Info>
              <Info>
                Venue:{"  "}
                <strong>{venue ? venue.profile_name : "Loading..."}</strong>
              </Info>
              {/* <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info> */}
            </InfoRow>

            {/* <Tabs>
              <Tab
                active={activeTab === "opportunityInfo"}
                onClick={() => setActiveTab("opportunityInfo")}
              >
                Opportunity Info
              </Tab>
            </Tabs> */}
            <Content>
              <About>
                <h3>Description</h3>
                <p>{opportunity.descriptions}</p>
              </About>
              {/* {activeTab === "opportunityInfo" && (
                <>
                  <About>
                    <h2>Description</h2>
                    <p>{opportunity.descriptions[0].body}</p>
                  </About>
                </>
              )} */}
              <br></br>
              <button
                className="oppbutton2"
                style={{
                  cursor: isLoadingDelete ? "not-allowed" : "pointer",
                  padding: "10px 60px",
                  minWidth: "100px",
                  opacity: isLoadingDelete ? 0.6 : 1,
                  transition: "opacity 0.3s ease",
                }}
                onClick={() => handleDeleteClick(opportunity._id)}
                disabled={isLoadingDelete || isDeleted}
              >
                {isLoadingDelete ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "DELETE"
                )}
              </button>
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {/* <EnrollButton>I am interested</EnrollButton> */}
              {/* <br></br>
              <br></br> */}
              <Stats>
                <DateContainer>
                  <span style={{ marginBottom: "18px" }}>
                    Price:{" "}
                    <DateText>
                      AUD {opportunity.price_point_value_from}-
                      {opportunity.price_point_value_to}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    Location:{" "}
                    <DateText>{opportunity.location.address}</DateText>
                  </span>
                  {/* <span>
                    From: {"  "}
                    <DateText>
                      {formatDate(opportunity.from_date)}
                      {"   "}
                      {formatTime(opportunity.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(opportunity.to_date)}
                      {"   "}
                      {formatTime(opportunity.to_time)}
                    </DateText>
                  </span> */}
                  <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(opportunity.from_date)}
                      {"   "}
                      {formatTime(opportunity.from_time)}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    To: {"  "}
                    <DateText>
                      {formatDate(opportunity.to_date)}
                      {"   "}
                      {formatTime(opportunity.to_time)}
                    </DateText>
                  </span>
                  <span>
                    Status: <DateText>{opportunity.status}</DateText>
                  </span>
                </DateContainer>
              </Stats>

              <TagContainer>
                <h6>Opportunity Types:</h6>
                {opportunity.opportunity_types.map((genre) => (
                  <TagBox key={genre}>{genre}</TagBox>
                ))}
              </TagContainer>

              <TagContainer>
                <h6>Opportunity genres:</h6>
                {opportunity.opportunity_genres.map((type) => (
                  <TagBox key={type}>{type}</TagBox>
                ))}
              </TagContainer>

              {/* <TagContainer>
            <h6>Opportunity Types:</h6>
            <TagBox>Beer Garden</TagBox>
            <TagBox>Community Event</TagBox>
          </TagContainer> */}

              {/* <TagContainer>
            <h6>Genres:</h6>
            <TagBox>Classics</TagBox>
            <TagBox>Alternative</TagBox>
          </TagContainer> */}
            </SideCard>
          </RightColumn>
        </>
      )}
      <Modal show={showModal} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Performer Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPerformerId && (
            <NewPerformerProfileTest profileId={selectedPerformerId} />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

NewOpportunityAuthorVenueView.propTypes = {
  opportunityId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

export default NewOpportunityAuthorVenueView;
