// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }

  .submit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
  
  
  .form-row {
    margin-bottom: 1rem;
  }
  
  .form-row label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-row input,
  .form-row textarea {
    width: calc(100% - 22px);
    border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 0.75rem;
  transition: border-color 0.3s;
  }
  
  .form-row textarea {
    height: 100px;
    resize: vertical;
  }
  
  .form-column {
    display: inline-block;
    width: calc(50% - 1rem);
    margin-right: 1rem;
    vertical-align: top;
  }
  
  .form-column:last-child {
    margin-right: 0;
  }
  
  .form-row button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .form-row button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/directBookings/BookingForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,+BAA+B;IAC/B,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,gBAAgB;EAClB;;;EAGA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;;IAEE,wBAAwB;IACxB,yBAAyB;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;EAC7B;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".booking-form {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 1rem;\n    /* background-color: #f9f9f9; */\n    border-radius: 8px;\n    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */\n  }\n\n  .submit-button-container {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: auto;\n  }\n  \n  \n  .form-row {\n    margin-bottom: 1rem;\n  }\n  \n  .form-row label {\n    display: block;\n    margin-bottom: 0.5rem;\n    font-weight: bold;\n  }\n  \n  .form-row input,\n  .form-row textarea {\n    width: calc(100% - 22px);\n    border: 1px solid #dcdcdc;\n  border-radius: 5px;\n  padding: 0.75rem;\n  transition: border-color 0.3s;\n  }\n  \n  .form-row textarea {\n    height: 100px;\n    resize: vertical;\n  }\n  \n  .form-column {\n    display: inline-block;\n    width: calc(50% - 1rem);\n    margin-right: 1rem;\n    vertical-align: top;\n  }\n  \n  .form-column:last-child {\n    margin-right: 0;\n  }\n  \n  .form-row button {\n    display: inline-block;\n    padding: 0.75rem 1.5rem;\n    border: none;\n    border-radius: 4px;\n    background-color: #007bff;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n  .form-row button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
