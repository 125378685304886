import React, { useState, useEffect } from "react";
import "./LandingProfiles.css";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import defaultProfilePicture from "../../assect/images/client/02.jpg";
import plusIcon from "../../assect/images/client/ua.jpg";
import Swal from "sweetalert2";
import NavigationBar from "../../components/navbar/loggedUserNavbar";
// import loadingSpinner from "../../assect/images/spinner.gif";
import { Spinner } from "react-bootstrap";
import Footer from "../../components/footer";

function LandingProfiles() {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false);
  const [clickedProfileId, setClickedProfileId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);

      try {
        const response = await axiosInstance.get("/v1/profile/by/user-id", {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        if (response.status === 200) {
          const { performer_profiles, venue_profiles } = response.data;
          const performerProfilesWithType = performer_profiles.map(
            (profile) => ({
              ...profile,
              profile_type: "Performer",
            })
          );
          const venueProfilesWithType = venue_profiles.map((profile) => ({
            ...profile,
            profile_type: "Venue",
          }));
          const allProfiles = [...performer_profiles, ...venue_profiles];
          setProfiles(allProfiles);
          localStorage.setItem("profilesCache", JSON.stringify(allProfiles));
          localStorage.setItem("cachedUserId", Cookies.get("userId"));
          console.log("profile info:", allProfiles);

          // If no profiles available, navigate user to profile creation page
          // if (allProfiles.length === 0) {
          //   navigate("/profile-type-select");
          // }
        } else {
          setError("Error fetching profiles");
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    // fetchProfiles();
    const cachedProfiles = localStorage.getItem("profilesCache");
    if (
      cachedProfiles &&
      Cookies.get("userId") === localStorage.getItem("cachedUserId")
    ) {
      setProfiles(JSON.parse(cachedProfiles));
    } else {
      fetchProfiles();
    }
  }, [userId]);

  useEffect(() => {
    const monitorTokens = () => {
      const accessToken = Cookies.get("accessToken");
      const refreshToken = Cookies.get("refreshToken");
      const currentUserId = Cookies.get("userId");
      const cachedUserId = localStorage.getItem("cachedUserId");

      if (!accessToken || !refreshToken || currentUserId !== cachedUserId) {
        localStorage.removeItem("profilesCache");
        localStorage.setItem("cachedUserId", currentUserId || "");
        setUserId(currentUserId);
      }
    };

    monitorTokens();

    const intervalId = setInterval(monitorTokens, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axiosInstance.get("/v1/auth/whoami", {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        if (response.status === 200) {
          const { _id } = response.data.data.user_details;
          const isSecure = window.location.protocol === "https:";
          const cookieOptions = {
            secure: isSecure,
            sameSite: "Lax",
            // httpOnly: true,
          };
          Cookies.set("userId", _id, cookieOptions);
          // Cookies.set("userId", _id, { secure: true });
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    fetchUserId();
  }, []);

  const handleProfileClick = async (profileId) => {
    setClickedProfileId(profileId);
    const profileType = profileId.split("-")[1];
    Cookies.set("profileOwnerId", profileId);

    let apiUrl = "";
    if (profileType === "PERFORMER") {
      apiUrl = `/v1/performer/view-profile/${profileId}/section/complete`;
    } else if (profileType === "VENUE") {
      apiUrl = `/v1/venue/view-profile/${profileId}/section/complete`;
    }

    try {
      const response = await axiosInstance.get(apiUrl, {
        headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
      });

      let profileStatus = "";
      if (profileType === "PERFORMER") {
        profileStatus = response.data.performer_info.status;
      } else if (profileType === "VENUE") {
        profileStatus = response.data.venue_info.status;
      }

      if (profileStatus === "PAYMENT_DETAILS_PENDING") {
        if (profileType === "PERFORMER") {
          // navigate(`/performer/${profileId}`);
          navigate(`/performer-pricing`);
        } else if (profileType === "VENUE") {
          // navigate(`/venue/${profileId}`);
          navigate(`/venue-pricing`);
        }
      } else if (profileStatus === "FRESH") {
        if (profileType === "PERFORMER") {
          navigate(`/performer/${profileId}`);
        } else if (profileType === "VENUE") {
          navigate(`/venue/${profileId}`);
        }
      } else {
        if (profileType === "PERFORMER") {
          navigate(`/performer/${profileId}`);
        } else if (profileType === "VENUE") {
          navigate(`/venue/${profileId}`);
        }
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to retrieve profile details.",
      });
    } finally {
      setClickedProfileId(null);
    }
  };
  // const handleProfileClick = (profileId) => {
  //   const profileType = profileId.split("-")[1];
  //   if (profileType === "PERFORMER") {
  //     navigate(`/performer/${profileId}`);
  //   } else if (profileType === "VENUE") {
  //     navigate(`/venue/${profileId}`);
  //   }
  // };

  const handleCreateProfileClick = () => {
    Swal.fire({
      title: "Select Profile Type",

      text: "What is the profile type you want to select?",
      showCancelButton: true,
      confirmButtonText: "Performer",
      cancelButtonText: "Venue",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("profilesCache");
        navigate("/create-performer-profile");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        localStorage.removeItem("profilesCache");
        navigate("/create-venue-profile");
      }
    });
  };

  const isMobile = window.innerWidth <= 767;

  const getProfileType = (profileId) => {
    return profileId.startsWith("PROFILE-VENUE") ? "Venue" : "Performer";
  };

  return (
    <>
      <NavigationBar />
      <section className="landing-profiles-container">
        {loading ? (
          <div className="loading-spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="title-heading text-center mb-20">
              <h4
                className="heading fw-bold title-dark mb-3"
                style={{
                  marginTop: isMobile ? "36%" : "6%",
                  marginBottom: "-6%",
                }}
              >
                My Profiles
                {/* <TypeAnimation
            sequence={["Profiles", 2000, "Profiles", 2000]}
            wrapper="span"
            speed={20}
            repeat={Infinity}
            className="typewrite ms-2"
            style={{ color: "#102733" }}
            cursor={false}
          />{" "} */}
              </h4>
              <p>Click to Activate a Profile</p>
            </div>

            <section className="section" style={{ marginTop: "-5%" }}>
              <div className="row justify-content-center px-5 py-2 mt-10">
                {profiles.map((profile) => (
                  <div
                    key={profile._id}
                    className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                  >
                    <div
                      className="teacher__item text-center transition-3 mb-30"
                      onClick={() => handleProfileClick(profile._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="teacher__thumb w-img fix lpprofile-image-container">
                        <img
                          src={
                            profile.profile_picture_url || defaultProfilePicture
                          }
                          alt="Profile"
                          className="lpprofile-image"
                          style={{ border: "none", borderRadius: 0 }}
                        />
                      </div>
                      {/* <div className="teacher__thumb w-img fix">
                    
                    <img
                      src={profile.profile_picture_url || defaultProfilePicture}
                      alt="Profile"
                      className="profile-image"
                      style={{ border: "none", borderRadius: 0 }}
                    />
                   
                  </div> */}
                      <div className="teacher__content">
                        <h5 className="teacher__title">
                          {/* <Link to={`/profile/${profile._id}`}> */}
                          {profile.profile_name || profile.profile_name}
                          {/* </Link> */}
                        </h5>
                        <span>{getProfileType(profile._id)}</span>

                        <div className="teacher__social">
                          <ul>
                            {profile?.socialIcon?.map((icon) => (
                              <li key={icon.id}>
                                <Link to={icon.link}>
                                  <i className={icon.icon}></i>
                                </Link>
                              </li>
                            ))}
                            {clickedProfileId === profile._id && (
                              <div className="loading-overlay">
                                <Spinner
                                  animation="border"
                                  style={{
                                    color: "black",
                                  }}
                                  // variant="info"
                                />
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                  <div
                    className="teacher__item text-center transition-3 mb-30"
                    onClick={handleCreateProfileClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="teacher__thumb w-img fix lpprofile-image-container">
                      <img
                        src={plusIcon}
                        alt="Create Profile"
                        className="lpprofile-image"
                        style={{ border: "none", borderRadius: 0 }}
                      />
                    </div>

                    {/* <div className="teacher__thumb w-img fix">
                  <img
                    src={plusIcon}
                    alt="Create Profile"
                    className="profile-image"
                    style={{ border: "none", borderRadius: 0 }}
                  />
                </div> */}
                    <div className="teacher__content">
                      <h5 className="teacher__title">Create new Profile</h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </section>
      <Footer />
    </>
  );
}

export default LandingProfiles;
