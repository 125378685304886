import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbarSelf";
import Footer from "../../components/footer";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon.png";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../auth/axiosInstance";

export default function AccountRejectedPage() {
  const rejectionReason = "Your account did not meet our eligibility criteria.";
  const [accountStatus, setAccountStatus] = useState("REJECTED");
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");
  useEffect(() => {
    const checkAccountStatus = async () => {
      try {
        const response = await axiosInstance.get("/v1/auth/account/status", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const status = response.data.data.account_status;
        setAccountStatus(status);
        if (status === "ACTIVE") {
          navigate("/profiles");
        }
      } catch (error) {
        console.error("Error checking account status", error);
      }
    };

    const interval = setInterval(checkAccountStatus, 5000);

    return () => clearInterval(interval);
  }, [navigate, accessToken]);

  return (
    // <>
    //   <Navbar
    //     navClass="defaultscroll sticky"
    //     logolight={true}
    //     menuClass="navigation-menu nav-left nav-light"
    //   />
    //   <section
    //     className="bg-half-170 d-table w-100"
    //     style={{ backgroundImage: `url(${bg3})` }}
    //   >
    //     <div className="bg-overlay bg-gradient-overlay-2"></div>
    //     <div className="container">
    //       <div className="row mt-5 justify-content-center">
    //         <div className="col-12">
    //           <div className="title-heading text-center">
    //             <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
    //               Account Rejected
    //             </h5>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="position-middle-bottom">
    //         <nav aria-label="breadcrumb" className="d-block">
    //           <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
    //             <li className="breadcrumb-item">
    //               <Link to="/">Home</Link>
    //             </li>
    //             <li className="breadcrumb-item active" aria-current="page">
    //               Account Rejected
    //             </li>
    //           </ul>
    //         </nav>
    //       </div>
    //     </div>
    //   </section>
    //   <div className="position-relative">
    //     <div className="shape overflow-hidden text-white">
    //       <svg
    //         viewBox="0 0 2880 48"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
    //           fill="currentColor"
    //         ></path>
    //       </svg>
    //     </div>
    //   </div>
    //   <section className="section">
    //     <div className="container">
    //       <div className="row justify-content-center">
    //         <div className="col-lg-8">
    //           <div className="card shadow border-0 rounded-3">
    //             <div className="card-body">
    //               <h5 className="card-title mb-4">Account Rejected</h5>
    //               <p className="text-muted">
    //                 We regret to inform you that your account registration has
    //                 been rejected.
    //               </p>
    //               <p className="text-muted">
    //                 <strong>Reason:</strong> {rejectionReason}
    //               </p>
    //               <p className="text-muted">
    //                 If you believe this is an error or have any questions,
    //                 please contact our support team.
    //               </p>
    //               <div className="d-grid gap-2">
    //                 <Link className="btn btn-primary" to="/">
    //                   Go to Homepage
    //                 </Link>
    //                 <Link className="btn btn-outline-primary" to="/contact">
    //                   Contact Support
    //                 </Link>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <Footer />
    // </>

    <PageWrapper>
      <Card>
        <Logo src={logo} alt="Company Logo" />
        <Title>Account Rejected</Title>
        <Message>
          Unfortunately, your account creation request has been declined. If you
          would like more information, please contact our support team for
          assistance.
        </Message>
        <Link
          to="https://ozstream.au/contact-us/"
          className="text-foot"
          style={{
            color: "#8fbae7",
            // fontFamily: "Poppins, sans-serif",
            // fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Contact Support
        </Link>
      </Card>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #edf2f7;
  padding: 20px;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
`;

const Logo = styled.img`
  width: 120px;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #c0392b; /* Strong red for the rejection message */
  margin-bottom: 25px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #596275;
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ContactButton = styled.button`
  background-color: #e74c3c;
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;
