import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ListGroup, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import axiosInstance from "../auth/axiosInstance";
import Navbar from "../../components/navbarSelf";
import Footer from "../../components/footer";
import { FaPlusCircle, FaTimes, FiHexagon } from "../../assect/icons/vander";
import { FaCreditCard } from "react-icons/fa6";
import bg3 from "../../assect/images/bg/03.jpg";
import NavigationBar from "../../components/navbar/loggedUserNavbar";
import Spinner from "react-bootstrap/Spinner";

export default function PerformerPricing() {
  const profileId = Cookies.get("profileOwnerId");
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [priceId, setPriceId] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  // const clickSubscription = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   console.log("Subscribe");
  //   console.log(Cookies.get("accessToken"));
  //   handleOpenModal();
  //   setLoading(false);
  // };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePaymentMethodSelection = (id) => {
    setIsModalOpen(false);
    setSelectedPaymentMethod(id);
    createSubscription(id);
  };

  const clickSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Subscribe");
    console.log(Cookies.get("accessToken"));
    await createSubscription(null);
    setLoading(false);
  };

  const createSubscription = async (paymentMethodId) => {
    console.log(priceId);
    console.log(Cookies.get("accessToken"));
    console.log(Cookies.get("profileOwnerId"));
    try {
      const response = await axiosInstance.post(
        "/v1/billing/create-subscription",
        {
          priceId: priceId,
          paymentId: paymentMethodId,
          profileId: Cookies.get("profileOwnerId"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      const data = response.data;
      console.log(data.clientSecret);
      console.log("subscription create response: ", response);

      if (data.clientSecret) {
        navigate("/pay", { state: { clientSecret: data.clientSecret } });
      } else {
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "The profile is now again active.",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      }
    } catch (error) {
      console.error("Error creating subscription:", error);
      setupPaymentMethod();
      // Swal.fire({
      //   icon: "error",
      //   title: "Payment Failed",
      //   text: "There was an issue processing your payment.",
      //   showCancelButton: true,
      //   confirmButtonText: "Change Payment Method",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     handleOpenModal();
      //   }
      // });
    }
  };

  // const setupPaymentMethod = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/billing/setup-payment-method",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     const data = response.data;
  //     console.log(data.clientSecret);

  //     navigate("/pay", { state: { clientSecret: data.clientSecret } });
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error creating subscription:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Payment Failed",
  //       text: "An issue occurred while processing your payment. Please try again.",
  //       showCancelButton: true,
  //       confirmButtonText: "Change Payment Method",
  //     });
  //     // }).then((result) => {
  //     //   if (result.isConfirmed) {
  //     //     handleOpenModal();
  //     //   }
  //     // });
  //   }
  // };

  const setupPaymentMethod = async () => {
    setIsLoading(true);
    const profileId = Cookies.get("profileOwnerId");

    try {
      const profileResponse = await axiosInstance.get(
        `/v1/performer/view-profile/${profileId}/section/complete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      const subscriptionId =
        profileResponse.data.profile_billing_info.subscription_id;

      const paymentResponse = await axiosInstance.post(
        "/v1/billing/setup-payment-method",
        {
          subscription_id: subscriptionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      const data = paymentResponse.data;
      console.log(data.clientSecret);

      navigate("/pay", { state: { clientSecret: data.clientSecret } });
      setIsLoading(false);
    } catch (error) {
      console.error("Error setting up payment method:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "An issue occurred while processing your payment. Please try again.",
        showCancelButton: true,
        confirmButtonText: "Change Payment Method",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      const fetchPayments = async () => {
        try {
          const response = await axiosInstance.post(
            "/v1/billing/payment-methods",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );
          setPaymentMethods(response.data.payment_methods);
          console.log(response.data.payment_methods);
        } catch (error) {
          console.error("Error fetching payment methods:", error);
        }
      };

      fetchPayments();
    }
  }, [isModalOpen]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Performer Profile" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("pricing data", response.data);
        setPricingData(response.data);
        if (response.data.length > 0) {
          setPriceId(response.data[0].default_price);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <NavigationBar />
      {/* <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      /> */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Pricing Plans
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-sm-0 pt-sm-0">
              {isLoading ? (
                <div className="text-center">Loading...</div>
              ) : error ? (
                <div className="text-center text-danger">
                  Error: {error.message}
                </div>
              ) : (
                <div className="features-absolute">
                  <div className="row g-4 justify-content-center">
                    {Array.isArray(pricingData) && pricingData.length > 0 ? (
                      pricingData.map((item, index) => (
                        <div
                          className={`${
                            pricingData.length === 1
                              ? "col-lg-6 col-md-8 col-sm-10"
                              : "col-lg-6 col-md-8"
                          }`}
                          key={index}
                          style={{ width: "780px" }}
                        >
                          <div
                            className="card rounded-3 "
                            style={{
                              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="p-4 border-bottom text-center">
                              <div className="position-relative features text-center mx-lg-4 px-md-1">
                                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                  <FiHexagon className="hexagon" />
                                  <div className="position-absolute top-50 start-50 translate-middle">
                                    <i className="fs-2 text-primary"></i>
                                  </div>
                                </div>
                                <h5 className="text-primary">{item.name}</h5>
                                <div className="d-flex justify-content-center">
                                  <span className="fs-5">$</span>
                                  <span className="h2 fw-semibold mb-0">
                                    {(item.unit_amount / 100).toFixed(2)}
                                  </span>
                                  <span className="fs-6 align-self-end">
                                    /Month
                                  </span>
                                </div>
                              </div>
                              <div className="p-4">
                                <h6>Features:</h6>
                                <br></br>
                                <ul className="list-unstyled">
                                  {/* <li className="text-muted mb-0">
                                    <span className="icon fs-5 me-2">
                                      <i className="mdi mdi-check-circle align-middle text-success"></i>
                                    </span>
                                    {item.description ||
                                      "No description for now"}
                                  </li> */}
                                  <li className="text-muted mb-0">
                                    <p className="d-flex align-items-start">
                                      <span className="icon fs-5 me-2">
                                        <i className="mdi mdi-check-circle align-middle text-success"></i>
                                      </span>
                                      <span>
                                        <strong>Your Digital Stage:</strong>{" "}
                                        Share your bio, media, and talent to
                                        attract venues.
                                      </span>
                                    </p>
                                  </li>
                                  <li className="text-muted mb-0">
                                    <p className="d-flex align-items-start">
                                      <span className="icon fs-5 me-2">
                                        <i className="mdi mdi-check-circle align-middle text-success"></i>
                                      </span>
                                      <span>
                                        <strong>
                                          No Middlemen, No Hassles:
                                        </strong>{" "}
                                        Communicate with venues without
                                        middlemen. venues.
                                      </span>
                                    </p>
                                  </li>
                                  <li className="text-muted mb-0">
                                    <p className="d-flex align-items-start">
                                      <span className="icon fs-5 me-2">
                                        <i className="mdi mdi-check-circle align-middle text-success"></i>
                                      </span>
                                      <span>
                                        <strong>Explore Opportunities:</strong>{" "}
                                        Apply for gigs that fit your style and
                                        follow venues for updates.
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                                <Button
                                  onClick={clickSubscription}
                                  className="btn btn-primary"
                                  disabled={loading}
                                  style={{
                                    cursor: loading ? "not-allowed" : "pointer", // Disable cursor when loading
                                  }}
                                >
                                  {loading ? (
                                    <Spinner
                                      animation="border"
                                      role="status"
                                      style={{
                                        // borderRadius: "3px",
                                        width: "1.2rem",
                                        height: "1.2rem",
                                        // borderWidth: "0.1rem",
                                      }}
                                    />
                                  ) : (
                                    "Subscribe"
                                  )}
                                </Button>

                                <Modal
                                  show={isModalOpen}
                                  onHide={() => setIsModalOpen(false)}
                                  centered
                                >
                                  <Modal.Header>
                                    <Modal.Title>
                                      Select a Payment Method
                                    </Modal.Title>
                                    <FaTimes
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        color: "red",
                                      }}
                                      onClick={() => setIsModalOpen(false)}
                                    />
                                  </Modal.Header>
                                  <Modal.Body>
                                    <ListGroup>
                                      {paymentMethods.length > 0 ? (
                                        paymentMethods.map((value) => (
                                          <ListGroup.Item
                                            key={value.id}
                                            action
                                            onClick={() =>
                                              handlePaymentMethodSelection(
                                                value.id
                                              )
                                            }
                                            className="d-flex align-items-center"
                                          >
                                            <FaCreditCard className="me-3" />
                                            {value.id}
                                          </ListGroup.Item>
                                        ))
                                      ) : (
                                        <ListGroup.Item>
                                          No existing payment methods.
                                        </ListGroup.Item>
                                      )}
                                      <ListGroup.Item
                                        action
                                        onClick={() =>
                                          handlePaymentMethodSelection(false)
                                        }
                                        className="d-flex align-items-center"
                                      >
                                        <FaPlusCircle className="me-3" />
                                        Attach new method.
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">
                        No pricing data available.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
