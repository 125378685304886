import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter, // X is Twitter in FontAwesome
  faMastodon,
  faMixcloud,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faVimeo,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 30px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 60px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "20px" : "40px")};
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const SubTitle = styled.p`
  margin-bottom: 35px;
  color: #666;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ResendLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const SignInLink = styled.p`
  display: block;
  margin-top: 20px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function UpdatePerformerSocialMedia() {
  const profileId = Cookies.get("profileOwnerId");
  const [socialMediaTypes, setSocialMediaTypes] = useState([]);
  const [socialMediaValues, setSocialMediaValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSocialMediaTypes() {
      try {
        const response = await axiosInstance.get(
          "/v1/profile/social-media-types",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          setSocialMediaTypes(response.data.social_media_types);
          // Initialize social media values state with empty strings for each type
          const initialValues = response.data.social_media_types.reduce(
            (acc, type) => ({ ...acc, [type]: "" }),
            {}
          );
          setSocialMediaValues(initialValues);
        } else {
          console.error("Error fetching social media types:", response.data);
          setErrorMessage(
            "An error occurred while fetching social media types. Please try again."
          );
        }
      } catch (error) {
        console.error("Error fetching social media types:", error);
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }

    fetchSocialMediaTypes();
  }, []);

  const handleChange = (type, value) => {
    setSocialMediaValues((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/social-media",
        {
          performer_id: Cookies.get("profileOwnerId"),
          ...socialMediaValues,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage(
          "Performer social media has been updated successfully."
        );
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Performer social media has been updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileOwnerId");
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage(
          "An error occurred while updating performer social media. Please try again."
        );
        console.error("Error updating performer social media:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer social media:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  const getIcon = (type) => {
    switch (type.toLowerCase()) {
      case "facebook":
        return faFacebook;
      case "instagram":
        return faInstagram;
      case "linkedin":
        return faLinkedin;
      case "x":
        return faTwitter;
      case "mastodon":
        return faMastodon;
      case "mix_cloud":
        return faMixcloud;
      case "sound_cloud":
        return faSoundcloud;
      case "spotify":
        return faSpotify;
      case "tiktok":
        return faTiktok;
      case "vimeo":
        return faVimeo;
      case "youtube":
        return faYoutube;
      default:
        return far; // Default icon
    }
  };
  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Social Media</Title>
      </TitleWrapper>

      {/* <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        {socialMediaTypes.map((type) => (
          <InputGroup key={type}>
            <Label htmlFor={type.toLowerCase()}>{type}</Label>
            <InputWrapper>
              <Icon icon={getIcon(type)} />
              <Input
                type="text"
                className="form-control"
                id={type.toLowerCase()}
                placeholder={type.toLowerCase()}
                value={socialMediaValues[type]}
                onChange={(e) => handleChange(type, e.target.value)}
              />
            </InputWrapper>
          </InputGroup>
        ))}

        <Button type="submit">
          Submit {loading && <Spinner animation="border" role="status" />}{" "}
        </Button>
      </FormWrapper>
    </Container>
  ); */}

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        {socialMediaTypes.map((type) => (
          <InputGroup key={type}>
            <Label htmlFor={type.toLowerCase()}>{type}</Label>
            <InputWrapper>
              <Icon icon={getIcon(type)} />
              <Input
                type="text"
                className="form-control"
                id={type.toLowerCase()}
                placeholder={type.toLowerCase()}
                value={socialMediaValues[type]}
                onChange={(e) => handleChange(type, e.target.value)}
              />
            </InputWrapper>
          </InputGroup>
        ))}
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerSocialMedia;
