import React, { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const baseBackendURL = process.env.REACT_APP_BASE_URL;

const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const accessToken = Cookies.get("accessToken");
  //   const isAuthenticatedFromStorage = localStorage.getItem("isAuthenticated");

  //   if (accessToken) {
  //     setUser({ isAuthenticated: true });
  //   }

  //   setLoading(false);
  // }, []);

  // useEffect(() => {
  //   const accessToken = Cookies.get("accessToken");

  //   // Check if the token exists and the user is authenticated
  //   if (accessToken) {
  //     setUser({ isAuthenticated: true });
  //   } else {
  //     setUser({ isAuthenticated: false });
  //   }

  //   setLoading(false);
  // }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleLogin = async (formData) => {
    // Perform actual login API call
    try {
      const response = await axios.post(
        `${baseBackendURL}/v1/auth/sign-in`,
        // "https://www.ramoi.com/v1/auth/sign-in",
        // "https://api.ogotrix.au/v1/auth/sign-in",
        // "http://127.0.0.1:4000/v1/auth/sign-in",
        formData
      );

      const { access, refresh } = response.data.data.tokens;

      Cookies.set("accessToken", access, { secure: true });
      Cookies.set("refreshToken", refresh, { secure: true });

      setUser({ isAuthenticated: true });
    } catch (error) {
      console.error("Login API Error:", error);
    }
  };

  const handleLogout = () => {
    Cookies.remove("accessToken", { secure: true });
    Cookies.remove("refreshToken", { secure: true });
    localStorage.removeItem("isAuthenticated");
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ loading, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);

//   if (context === undefined) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }

//   return context;
// };
