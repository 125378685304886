import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";

const MapComponent = ({ locations, onLocationClick, centerLocation }) => {
  const mapRef = useRef(null);
  const [profileLocation, setProfileLocation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapInstanceRef = useRef(null);
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [centerMarker, setCenterMarker] = useState(null);

  useEffect(() => {
    async function fetchProfileLocation() {
      const profileId = Cookies.get("profileOwnerId");

      const profileType = profileId.startsWith("PROFILE-PERFORMER")
        ? "performer"
        : "venue";

      try {
        const response = await axiosInstance.get(
          `/v1/${profileType}/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        const locationData =
          response.data.performer_contact?.location ||
          response.data.venue_contact?.location;

        setProfileLocation(locationData);
      } catch (error) {
        console.error("Error fetching profile location:", error);
      }
    }

    fetchProfileLocation();
  }, []);

  // useEffect(() => {
  //   if (!window.google) return;

  //   const google = window.google;
  //   const mapInstance = new google.maps.Map(mapRef.current, {
  //     center: { lat: -25.2744, lng: 133.7751 }, // Center of Australia
  //     zoom: 5,
  //   });

  //   setMap(mapInstance);
  //   mapInstanceRef.current = mapInstance;

  //   const infoWindowInstance = new google.maps.InfoWindow();

  //   // Close InfoWindow when clicking outside of it
  //   google.maps.event.addListener(mapInstance, "click", () => {
  //     if (infoWindowInstance) {
  //       infoWindowInstance.close();
  //     }
  //   });

  //   const newMarkers = locations.map((location) => {
  //     const marker = new google.maps.Marker({
  //       position: { lat: location.latitude, lng: location.longitude },
  //       map: mapInstance,
  //       title: location.location_name,
  //     });

  //     marker.addListener("click", () => {
  //       const content = `
  //         <div style="max-width: 180px;">
  //         <h6><strong>${location.title}</strong> </h6>

  //           <p><strong>Types:</strong> ${location.classified_type}</p>
  //           <p><strong>Category:</strong> ${location.classified_category}</p>
  //           <p><strong>Sub category:</strong> ${location.classified_sub_category}</p>
  //           <p><strong>Price:</strong> AUD ${location.price_point_value_from} to ${location.price_point_value_to}</p>
  //         </div>
  //       `;

  //       infoWindowInstance.setContent(content);
  //       infoWindowInstance.open(mapInstance, marker);
  //     });

  //     return marker;
  //   });

  //   setMarkers(newMarkers);

  //   if (profileLocation) {
  //     const { latitude, longitude, location_name } = profileLocation;

  //     const profileMarker = new google.maps.Marker({
  //       position: { lat: latitude, lng: longitude },
  //       map: mapInstance,
  //       title: location_name,
  //       icon: {
  //         url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  //       },
  //     });

  //     // Center and zoom the map only once after loading the profile location
  //     mapInstance.setCenter({ lat: latitude, lng: longitude });
  //     mapInstance.setZoom(14);
  //   }
  // }, [locations, profileLocation]);

  useEffect(() => {
    function loadScript(url) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) {
      // const url = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAYJCAkpVrsFw_6MIGeOMfoWOFQgZLRdxw&libraries=places&callback=initMap`;
      const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
      window.initMap = initMap;
      loadScript(url);
    } else {
      console.error("Google Maps API key is missing");
    }

    function initMap() {
      if (!mapRef.current) return;

      const google = window.google;
      const mapInstance = new google.maps.Map(mapRef.current, {
        center: { lat: -25.2744, lng: 133.7751 }, // Center of Australia
        zoom: 5,
      });

      setMap(mapInstance);
      mapInstanceRef.current = mapInstance;

      const infoWindowInstance = new google.maps.InfoWindow();

      google.maps.event.addListener(mapInstance, "click", () => {
        if (infoWindowInstance) {
          infoWindowInstance.close();
        }
      });

      const newMarkers = locations.map((location) => {
        const marker = new google.maps.Marker({
          position: { lat: location.latitude, lng: location.longitude },
          map: mapInstance,
          title: location.location_name,
        });

        marker.addListener("click", () => {
          const content = `
            <div style="max-width: 180px;">
            <h6><strong>${location.title}</strong> </h6>
              
              <p><strong>Types:</strong> ${location.classified_type}</p>
              <p><strong>Category:</strong> ${location.classified_category}</p>
              <p><strong>Sub category:</strong> ${location.classified_sub_category}</p>
              <p><strong>Price:</strong> AUD ${location.price_point_value_from} to ${location.price_point_value_to}</p>
            </div>
          `;

          infoWindowInstance.setContent(content);
          infoWindowInstance.open(mapInstance, marker);
        });

        return marker;
      });

      setMarkers(newMarkers);

      if (profileLocation) {
        const { latitude, longitude, location_name } = profileLocation;

        const profileMarker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: mapInstance,
          title: location_name,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          },
        });

        // Center and zoom the map only once after loading the profile location
        mapInstance.setCenter({ lat: latitude, lng: longitude });
        mapInstance.setZoom(14);
      }
    }
  }, [locations, profileLocation]);

  useEffect(() => {
    if (onLocationClick) {
      onLocationClick(handleLocationClick);
    }
  }, [onLocationClick, markers, map]);

  useEffect(() => {
    if (map) {
      if (centerMarker) {
        centerMarker.setMap(null);
      }

      let locationToCenter;
      if (centerLocation) {
        locationToCenter = centerLocation;
      } else if (profileLocation) {
        locationToCenter = profileLocation;
      }

      if (locationToCenter) {
        const { latitude, longitude } = locationToCenter;
        map.setCenter({ lat: latitude, lng: longitude });
        map.setZoom(14);

        const newMarker = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map,
          icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        });

        setCenterMarker(newMarker);
      }
    }
  }, [centerLocation, profileLocation, map]);

  const handleLocationClick = (location) => {
    const marker = markers.find(
      (marker) =>
        marker.getPosition().lat() === location.latitude &&
        marker.getPosition().lng() === location.longitude
    );

    if (marker) {
      if (selectedMarker) {
        selectedMarker.setIcon(null);
      }

      marker.setIcon("http://maps.google.com/mapfiles/ms/icons/green-dot.png");
      setSelectedMarker(marker);
      map.setCenter(marker.getPosition());
    }
  };

  return <div ref={mapRef} style={{ height: "100%", width: "100%" }} />;
};

export default MapComponent;
