import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Row, Modal } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RiFlag2Fill, RiFlagFill } from "react-icons/ri";
import NewClassifiedPerformerView from "./NewClassifiedPerformerView";
import NewClassifiedVenueView from "./NewClassifiedVenueView";
import Swal from "sweetalert2";

function ViewVenueClassifiedsbyOthers(props) {
  // const { profileId } = useParams();
  // const params = useParams();
  // const profileId = props.profileId || params.profileId;
  const params = useParams();
  const profileId = props.profileId || params.profileId;
  const profileIdn = profileId; //Cookies.get("profileId");
  console.log(profileId);
  const [classifieds, setClassifieds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);
  const { classifiedId } = useParams();
  const [classified, setClassified] = useState(null);
  const [flaggedClassifieds, setFlaggedClassifieds] = useState({});
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedclassifiedId, setSelectedclassifiedId] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    // Cookies.set("publicProfileId", profileId);
    const fetchClassifieds = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/classified/published/by/profile/${profileIdn}`
        );
        if (response.status === 200) {
          const data = response.data;
          setClassifieds(data.classifieds_published);
        } else {
          console.error("Error fetching classifieds:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching classifieds:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClassifieds();
  }, []);

  //   const handleClassifiedClick = () => {
  //     if (classified) {
  //       Cookies.set("classifiedId", classifiedId); // Save classifiedId in cookies
  //       navigate(`/classified/${classifiedId}`); // Redirect to classified venue view page
  //     }
  //   };

  const handleClassifiedClick = (classifiedId, authorProfileId) => {
    // Cookies.set("toProfileIdFirst", authorProfileId);
    // Cookies.set("classifiedId", classifiedId);
    console.log("Clicked classifiedId:", classifiedId);
    navigate(`/classified/${classifiedId}`);
  };
  //   const handleClassifiedClick = (classifiedId) => {
  //     Cookies.set("classifiedId", classifiedId);
  //     console.log("Clicked classifiedId:", classifiedId);
  //     navigate(`/classified/${classifiedId}`);
  //   };

  const handleViewMore = () => {
    setViewableCount(classifieds.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  //   const handleSendMessage = (authorId) => {
  //     // Store author's ID in cookies
  //     Cookies.set("toProfileId", authorId);
  //     // Navigate to messaging page
  //     navigate("/messaging");
  //   };

  const profileOwnerId = Cookies.get("profileOwnerId");

  const handleSendMessage = async (toProfileId) => {
    try {
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_classified/${profileOwnerId}/${toProfileId}`,
        { title: "clasifid" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        navigate("/messaging");
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const handleFlagClick = async (classified_id) => {
    try {
      const response = await axiosInstance.post(
        "/v1/classified/flag-classified",
        {
          reporting_profile_id: Cookies.get("profileOwnerId"),
          classified_id: classified_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setFlaggedClassifieds((prev) => ({ ...prev, [classified_id]: true }));
      } else {
        console.error("Error flagging classified:", response.statusText);
      }
    } catch (error) {
      console.error("Error flagging classified:", error);
      let errorMessage = "There was an error flagging classified.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected classified id", id);
    const isSecure = window.location.protocol === "https:";
    const cookieOptions = {
      secure: isSecure,
      sameSite: "Lax",
      // httpOnly: true,
    };
    Cookies.set("toProfileIdFirst", authorProfileId, cookieOptions);
    // Cookies.set("toProfileIdFirst", authorProfileId);
    setSelectedclassifiedId(id);
    // Cookies.set("classifiedId", id);
    // console.log("classifiedId cookie value:", Cookies.get("classifiedId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/classified/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const classified = response.data;
      console.log("classified response", response.data);
      // const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        classified.classified.author_profile_id
      );
      console.log("classified id:", id);

      const profileType = getProfileType(Cookies.get("profileOwnerId"));
      if (profileType === "performer") {
        setModalContent(
          <NewClassifiedPerformerView
            classifiedId={id}
            profileId={authorProfileId}
          />
        );
      } else if (profileType === "venue") {
        setModalContent(
          <NewClassifiedVenueView
            classifiedId={id}
            profileId={authorProfileId}
          />
        );
      }
    } catch (error) {
      console.error("Error fetching classified details:", error);
      let errorMessage = "There was an error fetching classified details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedclassifiedId(null);
    setShowModal(false);
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>
          <h4>Classifieds</h4>
        </strong>
      </center>
      <br />
      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading Classifieds...</span>
        </div>
      ) : (
        <Row>
          {classifieds.length > 0 ? (
            <>
              {classifieds.slice(0, viewableCount).map((classified) => (
                <div
                  key={classified._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  onClick={() =>
                    openModal(classified._id, classified.author_profile_id)
                  }
                  // onClick={() => handleClassifiedClick(classified._id)}
                  // onClick={() =>
                  //   handleClassifiedClick(
                  //     classified._id,
                  //     classified.author_profile_id
                  //   )
                  // }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{classified.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{classified.short_description}</p>
                        </div>
                        <div className="events__details-inline">
                          <strong>Location:</strong>{" "}
                          {classified.location.suburb}
                        </div>
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(classified.from_date)}
                          {/* {"   "}
                          {formatTime(classified.from_time)} */}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(classified.to_date)}
                          {/* {"   "}
                          {formatTime(classified.to_time)} */}
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Date:</strong>
                          {typeof classified.from_date === "object"
                            ? new Date(
                                classified.from_date.year,
                                classified.from_date.month - 1,
                                classified.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : classified.from_date}
                        </div>
                        <div className="events__details-inline">
                          <strong>Time:</strong>
                          {typeof classified.from_time === "object"
                            ? `${classified.from_time.hour}:${classified.from_time.minute} ${classified.from_time.am_pm}`
                            : classified.from_time}
                        </div> */}
                        <Button
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFlagClick(classified._id);
                          }}
                          style={{
                            color: flaggedClassifieds[classified._id]
                              ? "red"
                              : "black",
                            zIndex: 1,
                          }}
                        >
                          <RiFlag2Fill />
                        </Button>
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          onClick={() =>
                            openModal(
                              classified._id,
                              classified.author_profile_id
                            )
                          }
                          // onClick={() => handleClassifiedClick(classified._id)}
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {viewableCount < classifieds.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({classifieds.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === classifieds.length &&
                classifieds.length > 2 && (
                  <center>
                    <Button
                      variant="link"
                      onClick={handleViewLess}
                      style={{ color: "#418abb" }}
                    >
                      View Less
                    </Button>
                  </center>
                )}
            </>
          ) : (
            <p className="text-center">No classifieds found.</p>
          )}
        </Row>
        // <div>
        //   {classifieds.length > 0 ? (
        //     <>
        //       {classifieds.slice(0, viewableCount).map((classified) => (
        //         <Card
        //           key={classified._id}
        //           className="mb-3"
        //           onClick={() =>
        //             handleClassifiedClick(
        //               classified._id,
        //               classified.author_profile_id
        //             )
        //           }
        //           style={{
        //             backgroundColor: "#e8e8e8",
        //             borderRadius: "15px",
        //             cursor: "pointer",
        //           }}
        //         >
        //           <Card.Body>
        //             <h4>
        //               <strong style={{ fontWeight: "bold" }}>
        //                 {classified.title}
        //               </strong>
        //             </h4>
        //             <p>{classified.short_description}</p>
        //             <p>
        //               <strong>Location:</strong> {classified.location.suburb}
        //             </p>
        //             <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof classified.from_date === "object"
        //                 ? new Date(
        //                     classified.from_date.year,
        //                     classified.from_date.month - 1,
        //                     classified.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : classified.from_date}
        //             </p>
        //             <p>
        //               <strong>Time:</strong>{" "}
        //               {typeof classified.from_time === "object"
        //                 ? `${classified.from_time.hour}:${classified.from_time.minute} ${classified.from_time.am_pm}`
        //                 : classified.from_time}
        //             </p>
        //             {/* <Button
        //               variant="link"
        //               onClick={() =>
        //                 handleSendMessage(classified.author_profile_id)
        //               }
        //             >
        //               <FaEnvelope />
        //             </Button> */}

        //             <Button
        //               variant="link"
        //               onClick={(e) => {
        //                 e.stopPropagation();
        //                 handleFlagClick(classified._id);
        //               }}
        //               style={{
        //                 color: flaggedClassifieds[classified._id]
        //                   ? "red"
        //                   : "black",
        //                 zIndex: 1,
        //               }}
        //             >
        //               <RiFlag2Fill />
        //             </Button>
        //           </Card.Body>
        //         </Card>
        //       ))}
        //       {viewableCount < classifieds.length && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewMore}
        //             style={{ color: "#418abb" }}
        //           >
        //             View More ({classifieds.length - viewableCount} more)
        //           </Button>
        //         </center>
        //       )}
        //       {viewableCount === classifieds.length &&
        //         classifieds.length > 2 && (
        //           <center>
        //             <Button
        //               variant="link"
        //               onClick={handleViewLess}
        //               style={{ color: "#418abb" }}
        //             >
        //               View Less
        //             </Button>
        //           </center>
        //         )}
        //     </>
        //   ) : (
        //     <p className="text-center">No classifieds found.</p>
        //   )}
        // </div>
      )}
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ alignSelf: "center" }}
        // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewVenueClassifiedsbyOthers;
