import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";
import axiosInstance from "./axiosInstance";

export default function PhoneEntry() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/phone_number",
        { phone_number: phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("API Response:", response?.data.data);
      // navigate("/verify-phone");
      // navigate("/account-approval-notice");
      navigate("/profiles");
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to save phone number",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        // style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={handleSubmit}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Enter your phone number</h5>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingPhoneNumber"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingPhoneNumber">Phone Number</label>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Save Phone Number{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
