import React from "react";
import { Link } from "react-router-dom";
import "./PaymentPachages.css";
import Navbar from "../../components/navbarSelf";
import Footer from "../../components/footer";
// import bg3 from "../../assect/images/bg/03.jpg";
import { FiArrowRight } from "../../assect/icons/vander";

const packages = [
  {
    id: 1,
    name: "Basic Plan",
    price: "$10/month",
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    id: 2,
    name: "Standard Plan",
    price: "$20/month",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
  },
  {
    id: 3,
    name: "Premium Plan",
    price: "$30/month",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
];

export default function PaymentPackages() {
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        // style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Payment Packages
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment Packages
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="card shadow border-0 rounded-3">
                <div className="card-body">
                  <h5 className="card-title">Choose Your Plan</h5>
                  <p className="text-muted">
                    Select the plan that best fits your needs and start enjoying
                    our services today.
                  </p>
                  <div className="row">
                    {packages.map((pkg) => (
                      <div className="col-md-4 mb-3" key={pkg.id}>
                        <div className="card pricing-box border-0 shadow rounded-3">
                          <div className="card-body py-5 text-center">
                            <h5 className="card-title">{pkg.name}</h5>
                            <h6 className="text-primary">{pkg.price}</h6>
                            <ul className="list-unstyled text-muted mt-3">
                              {pkg.features.map((feature, index) => (
                                <li key={index}>
                                  <FiArrowRight className="fea icon-sm me-2" />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <Link
                              to="/subscribe"
                              className="btn btn-primary mt-4"
                            >
                              Subscribe
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
