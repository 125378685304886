import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import bg3 from "../../assect/images/bg1.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import AutoComplete from "../../components/autoComplete";
import "./CreatePerformer.css";
import "../OtherPages/LandingProfiles.css";
import { MultiSelect } from "react-multi-select-component";

function CreatePerformerProfile() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    performer_types: [],
    performer_genres: [],
    location: {
      suburb: "",
      zipcode: "",
      latitude: 0,
      longitude: 0,
      location_name: "",
      address: "",
      google_map_pin: "",
    },
  });
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [performerTypes, setPerformerTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);
  const [showGenresDropdown, setShowGenresDropdown] = useState(false);
  const typesInputRef = useRef(null);
  const genresInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("Fetching data...");
        const [profileTypesResponse, genreTypesResponse] = await Promise.all([
          axiosInstance.get("/v1/performer/performer-genres", {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }),
          axiosInstance.get("/v1/performer/performer-types", {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }),
        ]);

        console.log("Data received:", profileTypesResponse, genreTypesResponse);

        if (
          profileTypesResponse.status === 200 &&
          genreTypesResponse.status === 200
        ) {
          console.log(
            "Performer types response data:",
            profileTypesResponse.data
          );
          const performerGenres = profileTypesResponse.data.performer_genres;
          console.log("Performer genres:", performerGenres);
          setGenres(performerGenres);

          console.log(
            "Performer types response data:",
            genreTypesResponse.data
          );
          const performerTypes = genreTypesResponse.data.performer_types;
          console.log("Performer types:", performerTypes);
          setPerformerTypes(performerTypes);
        } else {
          setErrorMessage("Error fetching data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      location: { ...prevData.location, [name]: value },
    }));
  };

  const handleGenreChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedGenres.length < 7) {
        setSelectedGenres([...selectedGenres, value]);
      } else {
        setErrorMessage("You can only select a maximum of 7 genres.");
      }
    } else {
      setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
    }
  };

  // const handleTypeChange = (event) => {
  //   const checked = event.target.checked;
  //   const value = event.target.value;

  //   if (checked) {
  //     if (!selectedTypes.includes(value)) {
  //       setSelectedTypes([...selectedTypes, value]);
  //     }
  //   } else {
  //     setSelectedTypes(selectedTypes.filter((type) => type !== value));
  //   }
  // };

  const handleTypeChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedTypes.length < 3) {
        setSelectedTypes([...selectedTypes, value]);
      } else {
        setErrorMessage("You can only select a maximum of 3 performer types.");
      }
    } else {
      setSelectedTypes(selectedTypes.filter((type) => type !== value));
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      type: "PERFORMER",
      first_name: formData.first_name.trim(),
      last_name: formData.last_name.trim(),
      performer_types: selectedTypes,
      performer_genres: selectedGenres,
      location: formData.location,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/performer/create-profile",
        payload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        const profileId = response.data.profile_id;
        Cookies.set("profileOwnerId", profileId);

        const profileResponse = await axiosInstance.get(
          `/v1/performer/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (profileResponse.status === 200) {
          const profileStatus = profileResponse.data.performer_info.status;

          if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-PERFORMER")
          ) {
            // navigate("/performer-pricing");
            navigate(`/performer/${profileId}`);
          } else if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-VENUE")
          ) {
            // navigate("/venue-pricing");
            navigate(`/venue/${profileId}`);
          } else {
            Swal.fire({
              title: "Success!",
              text: "Your profile was created successfully.",
              icon: "success",
              className: "swal-modal",
            }).then(() => {
              navigate(`/performer/${profileId}`);
            });
          }
        } else {
          setErrorMessage("Error fetching profile status.");
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        }
      } else {
        setErrorMessage(response.data.error);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleTypesInputClick = () => {
    setShowTypesDropdown(!showTypesDropdown);
  };

  const handleGenresInputClick = () => {
    setShowGenresDropdown(!showGenresDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      typesInputRef.current &&
      !typesInputRef.current.contains(event.target)
    ) {
      setShowTypesDropdown(false);
    }
    if (
      genresInputRef.current &&
      !genresInputRef.current.contains(event.target)
    ) {
      setShowGenresDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="landing-profiles-container">
      {/* // <section
    //   className="bg-home zoom-image d-flex align-items-center"
    //   style={{ overflow: "scroll", minHeight: "100vh", padding: "0" }}
    // > */}
      {/* <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div> */}
      {/* <div className="bg-overlay bg-gradient-overlay"></div> */}
      <br></br>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white  shadow-md mx-auto w-100"
              style={{ maxWidth: "400px", borderRadius: "1px" }}
            >
              <form onSubmit={handleSubmit}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Create Performer Profile</h5>

                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="floatingInput">First Name</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="lastName">Last Name</label>
                </div>

                <div className="mb-3">
                  <label htmlFor="performer_types" className="form-label">
                    Performer Types
                  </label>
                  <div className="dropdown-scrollable" ref={typesInputRef}>
                    <input
                      type="text"
                      id="performer_types"
                      name="performer_types"
                      placeholder="Select Performer Types"
                      onFocus={handleTypesInputClick}
                      readOnly
                    />
                    {showTypesDropdown && (
                      <div className="dropdown-content" ref={typesInputRef}>
                        {performerTypes.length > 0 ? (
                          performerTypes.map((type) => (
                            <div className="form-check" key={type}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`type-${type}`}
                                name="performer_types"
                                value={type}
                                checked={selectedTypes.includes(type)}
                                onChange={handleTypeChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`type-${type}`}
                              >
                                {type}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading performer types...</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="performer_genres" className="form-label">
                    Genres (max 7)
                  </label>
                  <div className="dropdown-scrollable" ref={genresInputRef}>
                    <input
                      type="text"
                      id="performer_genres"
                      name="performer_genres"
                      placeholder="Select Genres"
                      onFocus={handleGenresInputClick}
                      readOnly
                    />
                    {showGenresDropdown && (
                      <div className="dropdown-content">
                        {genres.length > 0 ? (
                          genres.map((genre) => (
                            <div className="form-check" key={genre}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`genre-${genre}`}
                                name="performer_genres"
                                value={genre}
                                checked={selectedGenres.includes(genre)}
                                onChange={handleGenreChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`genre-${genre}`}
                              >
                                {genre}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading genres...</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {selectedGenres.length > 7 && (
                  <span className="text-danger">
                    You can only select a maximum of 7 genres.
                  </span>
                )}

                <div className="mb-3">
                  <AutoComplete onSuburbSelect={handleSuburbSelect} />
                </div>

                <button
                  style={{ borderRadius: "1px" }}
                  type="submit"
                  className="btn btn-primary w-100 mt-3"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Create Profile"
                  )}
                </button>

                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreatePerformerProfile;
