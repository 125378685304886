import React, { useState, useEffect } from "react";
import axiosInstance from "../auth/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
// import logo from "../../assect/images/landing/logo.jpg";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 100px;
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "20px" : "40px")};
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  margin-bottom: 35px;
  color: #666;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ResendLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const SignInLink = styled.p`
  display: block;
  margin-top: 20px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function UpdatePerformerPhoneVerify() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const profileId = Cookies.get("profileOwnerId");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#f0f2f5";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      performer_id: profileId,
      verification_code: verificationCode,
    };
    try {
      const response = await axiosInstance.post(
        "/v1/performer/verification/performer-phone-number/verify",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("API Response:", response?.data.data);
      localStorage.removeItem("performerData");
      Swal.fire({
        title: "Success!",
        text: "Your phone number has been successfully verified.",
        icon: "success",
        className: "swal-modal",
      }).then(() => {
        navigate(`/performer/${profileId}`);
      });
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Verification code is invalid.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    const payload = {
      performer_id: profileId,
    };
    try {
      const response = await axiosInstance.post(
        "/v1/performer/verification/performer-phone-number/resend",
        payload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("Resend API Response:", response?.data.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The verification code has been resent successfully.",
      });
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to resend verification code",
      });
    }
  };
  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleHome = () => {
    navigate("/login");
  };

  return (
    <Container>
      {/* <NavBar isMobile={isMobile}>
        <Logo src={logo} alt="Logo" />
        <div>
          <ButtonB onClick={handleHome}>Login</ButtonB>
          <ButtonB onClick={handleSignup}>Sign up</ButtonB>
        </div>
      </NavBar> */}

      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Verify Phone Number</Title>
      </TitleWrapper>
      {/* <SubTitle>Please verify your phone number</SubTitle> */}

      <FormWrapper onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="floatingVerificationCode">
            Phone Verification Code
          </Label>
          <InputWrapper>
            <Input
              type="text"
              id="floatingVerificationCode"
              placeholder="XXXXXX"
              value={verificationCode}
              onChange={handleChange}
            />
          </InputWrapper>
        </InputGroup>

        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Verify"
            )}
          </Button>
        </ButtonWrapper>

        <ResendLink onClick={handleResend}>
          Re-send the verification code
        </ResendLink>
      </FormWrapper>
    </Container>
  );
}
