import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 120px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 120px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "20px" : "40px")};
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const SubTitle = styled.p`
  margin-bottom: 35px;
  color: #666;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px; /* Set a minimum height to prevent it from collapsing */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  resize: none; /* Disable manual resizing */

  overflow-y: hidden; /* Hide vertical scrollbar */

  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ResendLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const SignInLink = styled.p`
  display: block;
  margin-top: 20px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function UpdateVenueShortBio() {
  const profileId = Cookies.get("profileOwnerId");
  const [shortBio, setShortBio] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchVenueShortBio() {
      try {
        const response = await axios.get("/v1/venue/profile", {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });

        if (response.status === 200) {
          // Log all cookies to see what details are available
          const allCookies = Cookies.get();
          console.log("All cookies:", allCookies);

          // Assuming you want to set the tagline from the response data
          setShortBio(response.data.data.shortBio.join("\n")); // Adjust according to your response structure
        } else {
          setErrorMessage("Error fetching venue profile data.");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }

    fetchVenueShortBio();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // const shortBioArray = shortBio
    //   .split("\n")
    //   .filter((line) => line.trim() !== "");

    const shortBioArray = shortBio.split("\n");

    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/thumbnail-bio",
        {
          venue_id: profileId,
          thumbnail_bio: shortBioArray,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        setSuccessMessage("Your bio has been updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "Your bio has been updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/venue/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating bio");
        console.error("Error updating bio:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating bio:", error);
      setErrorMessage(
        "An error occurred while updating the bio. Please try again."
      );
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, setStateFunction) => {
    const { value, scrollHeight } = event.target;
    setStateFunction(value);
    event.target.style.height = "auto"; // Reset the height
    event.target.style.height = scrollHeight + "px"; // Set the dynamic height
  };

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/venue/${profileId}`);
    } catch (error) {
      console.error("Error navigating to venue profile:", error);
      setLoading(false);
      setErrorMessage(
        "An error occurred while updating the bio. Please try again."
      );
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating the bio. Please try again.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Bio</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        <InputGroup>
          <Label htmlFor="shortBio">Bio</Label>
          <InputWrapper>
            <Textarea
              id="shortBio"
              className="form-control"
              value={shortBio}
              onChange={(e) => handleChange(e, setShortBio)}
              placeholder="Update bio..."
              required
            />
          </InputWrapper>
        </InputGroup>

        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}
export default UpdateVenueShortBio;
