import React, { useState, useEffect, useRef } from "react";
import "./NavigationBar.css";
import gigbridgelogo from "../../assect/images/landing/OgotrixLogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaSearch,
  FaChartBar,
  FaUserCog,
  FaUser,
  FaBell,
  FaEnvelope,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import Cookies from "js-cookie";

import { FiLogOut } from "react-icons/fi";
import axiosInstance from "../../pages/auth/axiosInstance";
import { Modal } from "react-bootstrap";
import App from "../../pages/messaging/messaging";
import Spinner from "react-bootstrap/Spinner";

const NavigationBar = ({ isPublished }) => {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadNotCount, setUnreadNotCount] = useState(0);
  const [messageThreads, setMessageThreads] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [allnotifications, setAllNotifications] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const navigate = useNavigate();
  const notificationModalRef = useRef(null);
  const messageModalRef = useRef(null);
  const profileId = Cookies.get("profileOwnerId");
  const [showDashboard, setShowDashboard] = useState(false);
  const [showMessageIcon, setShowMessageIcon] = useState(false);
  const [profileStatus, setProfileStatus] = useState("");
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 50);
  //   });

  //   const fetchUnreadCount = async () => {
  //     const profileId = Cookies.get("profileOwnerId");
  //     const accessToken = Cookies.get("accessToken");

  //     try {
  //       const response = await axiosInstance.get(
  //         "/v1/notification/get_count_of_unopened_notification",
  //         {
  //           params: { target_id: profileId },
  //           headers: { Authorization: `Bearer ${accessToken}` },
  //         }
  //       );

  //       console.log("Full API Response:", response);

  //       if (response.status === 200) {
  //         if (response.data) {
  //           console.log("API Response Data:", response.data);

  //           const countMatch = response.data.match(/count\s*:\s*(\d+)/);
  //           if (countMatch && countMatch[1]) {
  //             const count = parseInt(countMatch[1], 10);
  //             setUnreadNotCount(count);
  //             console.log("Unread Notifications Count:", count);
  //           } else {
  //             console.error("Count not found in response data.");
  //             setUnreadNotCount(0);
  //           }
  //         } else {
  //           console.error("Response data is undefined.");
  //         }
  //       } else {
  //         console.error("Unexpected response status:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("Request Error:", error);
  //     }
  //   };

  //   fetchUnreadCount();
  // }, []);

  // const fetchNotifications = async () => {
  //   const profileId = Cookies.get("profileOwnerId");
  //   const accessToken = Cookies.get("accessToken");

  //   try {
  //     const response = await axiosInstance.get(
  //       "/v1/notification/get_last_7_notification",
  //       {
  //         params: { target_id: profileId },
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setNotifications(response.data.message || []);
  //     }
  //   } catch (error) {
  //     console.error("Request Error:", error);
  //   }
  // };

  // const fetchALLNotifications = async (url = '/v1/notification/get_notification') => {
  //   const profileId = Cookies.get("profileOwnerId");
  //   const accessToken = Cookies.get("accessToken");

  //   try {
  //     const response = await axiosInstance.post(
  //       url, // Use the dynamic URL here
  //       null,
  //       {
  //         params: { target_id: profileId },
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log("Response Data AllNotifications:", response.data);
  //       setAllNotifications(response.data.results || []);
  //       setNextUrl(response.data.next || null);  // Update the nextUrl
  //       setPreviousUrl(response.data.previous || null);  // Update the previousUrl
  //     }
  //   } catch (error) {
  //     console.error("Request Error:", error);
  //   }
  // };

  const fetchALLNotifications = async (
    url = "/v1/notification/get_notification"
  ) => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.post(
        url, // Use the dynamic URL here
        null,
        {
          params: { target_id: profileId },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        console.log("Response Data AllNotifications:", response.data);
        setAllNotifications(response.data.results || []);
        setNextUrl(response.data.next || null); // Update the nextUrl
        setPreviousUrl(response.data.previous || null); // Update the previousUrl
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const fetchNotifications = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        "/v1/notification/get_last_7_notification",
        {
          params: { target_id: profileId },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        console.log("Response Data1:", response.data);
        setNotifications(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  // useEffect to handle the initial data fetching
  useEffect(() => {
    const fetchUnreadCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          "/v1/notification/get_count_of_unopened_notification",
          {
            params: { target_id: profileId },
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log("Full API Response:", response);

        if (response.status === 200) {
          if (response.data) {
            console.log("API Response Data:", response.data);

            const countMatch = response.data.match(/count\s*:\s*(\d+)/);
            if (countMatch && countMatch[1]) {
              const count = parseInt(countMatch[1], 10);
              setUnreadNotCount(count);
              console.log("Unread Notifications Count:", count);
            } else {
              console.error("Count not found in response data.");
              setUnreadNotCount(0);
            }
          } else {
            console.error("Response data is undefined.");
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    // Execute all fetch functions when the component mounts
    fetchUnreadCount();
    fetchNotifications();
    fetchALLNotifications();
  }, []);

  // // Handle fetching notifications with pagination
  // const handleNextNotification = () => {
  //   if (nextUrl) {
  //     const nextUrlWithBasePath = nextUrl.startsWith("/v1/notification")
  //       ? nextUrl
  //       : `/v1/notification${nextUrl}`;

  //     fetchALLNotifications(nextUrlWithBasePath);
  //   }
  // };

  // const handlePreviousNotification = () => {
  //   if (previousUrl) {
  //     const previousUrlWithBasePath = previousUrl.startsWith("/v1/notification")
  //       ? previousUrl
  //       : `/v1/notification${previousUrl}`;

  //     fetchALLNotifications(previousUrlWithBasePath);
  //   }
  // };

  const handleNextNotification = (e) => {
    e.preventDefault();
    setLoadingNext(true); // Start loading
    
    if (nextUrl) {
      const nextUrlWithBasePath = nextUrl.startsWith("/v1/notification")
        ? nextUrl
        : `/v1/notification${nextUrl}`;
  
      fetchALLNotifications(nextUrlWithBasePath)
        .then((response) => {
          // Assuming `response` contains next and previous URLs
          setNextUrl(response.nextUrl || null); // Update or nullify nextUrl
          setPreviousUrl(response.previousUrl || null); // Update or nullify previousUrl
          setLoadingNext(false); // Stop loading after successful fetch
        })
        .catch((error) => {
          console.error("Failed to fetch notifications", error);
          setLoadingNext(false); // Stop loading in case of error
        });
    } else {
      setLoadingNext(false); // If no URL, stop loading
    }
  };
  
  const handlePreviousNotification = (e) => {
    e.preventDefault();
    setLoadingPrevious(true); // Start loading
  
    if (previousUrl) {
      const previousUrlWithBasePath = previousUrl.startsWith("/v1/notification")
        ? previousUrl
        : `/v1/notification${previousUrl}`;
  
      fetchALLNotifications(previousUrlWithBasePath)
        .then((response) => {
          // Update the URLs after fetching notifications
          setNextUrl(response.nextUrl || null); // Update or nullify nextUrl
          setPreviousUrl(response.previousUrl || null); // Update or nullify previousUrl
          setLoadingPrevious(false); // Stop loading after successful fetch
        })
        .catch((error) => {
          console.error("Failed to fetch notifications", error);
          setLoadingPrevious(false); // Stop loading in case of error
        });
    } else {
      setLoadingPrevious(false); // If no URL, stop loading
    }
  };

  // const handleNotificationClick = (e) => {
  //   e.preventDefault();
  //   setNotificationModal(!notificationModal);
  //   if (!notificationModal) fetchNotifications();
  // };

  const handleNotificationClick = async (e) => {
    e.preventDefault();
    setNotificationModal(!notificationModal);

    setUnreadNotCount(0);

    if (!notificationModal) {
      await fetchNotifications();
      await markNotificationsAsRead();
    }

    // if (!notificationModal) fetchNotifications();
  };

  const handleClickOutside = (event) => {
    if (
      notificationModalRef.current &&
      !notificationModalRef.current.contains(event.target)
    ) {
      setNotificationModal(false);
    }

    if (
      messageModalRef.current &&
      !messageModalRef.current.contains(event.target)
    ) {
      setModal(false);
    }
  };

  useEffect(() => {
    if (notificationModal || modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationModal, modal]);

  useEffect(() => {
    const fetchUnreadMessagesCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          `/v1/messaging/get_all_unread_messge_count_by_profile/${profileId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          setUnreadCount(response.data.count || 0);
          console.log("unread msg count:", response.data);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    fetchUnreadMessagesCount();
  }, []);

  const fetchLastSevenMessages = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        `/v1/messaging/get_last_7_messge_by_profile/${profileId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        setMessageThreads(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    fetchALLNotifications();
    setShow1(true);
  };

  const getActiveClass = (path) => {
    const searchPaths = [
      "/search/performer",
      "/search/venue",
      "/search/opportunity",
      "/search/call_out",
      "/search/classified",
      "/saved-searches",
    ];
    if (path === "/search") {
      return searchPaths.includes(location.pathname) ? "active-link" : "";
    }
    return location.pathname === path ? "active-link" : "";
  };

  const markNotificationsAsRead = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.post(
        "/v1/notification/update_hsa_opend_all",
        { target_id: profileId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        setUnreadNotCount(0);
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  useEffect(() => {
    const handleProfileClick = async () => {
      if (!profileId) return;

      const profileType = profileId.split("-")[1];
      Cookies.set("profileOwnerId", profileId);

      let apiUrl = "";
      if (profileType === "PERFORMER") {
        apiUrl = `/v1/performer/view-profile/${profileId}/section/complete`;
      } else if (profileType === "VENUE") {
        apiUrl = `/v1/venue/view-profile/${profileId}/section/complete`;
      }

      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        let status = "";
        if (profileType === "PERFORMER") {
          status = response.data.performer_info.status;
        } else if (profileType === "VENUE") {
          status = response.data.venue_info.status;
        }

        setProfileStatus(status);
        if (status === "ACTIVE" || isPublished) {
          setShowDashboard(true);
          setShowMessageIcon(true);
        } else {
          setShowDashboard(false);
          setShowMessageIcon(false);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    handleProfileClick();
  }, [isPublished]); // Depend on isPublished to trigger rerender

  return (
    <header id="topnav" className={isSticky ? "nav-sticky" : ""}>
      <section className="navigation">
        <div className="nav-container">
          <div className="brand">
            {/* <a href="#!">Gig Bridge LOGO</a> */}
            <img
              src={gigbridgelogo}
              alt="Gigbridge Logo"
              style={{ height: "60px" }}
            />
          </div>
          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!" onClick={toggleMobileMenu}>
                <span className={isMobile ? "active" : ""}></span>
              </a>
            </div>
            <ul className={`nav-list ${isMobile ? "active" : ""}`}>
              <li>
                {/* <a href="/account-page">Account</a> */}
                <Link to="/" className={getActiveClass("/")}>
                  Home
                </Link>
              </li>
              {profileId.startsWith("PROFILE-PERFORMER") ? (
                <li>
                  {/* <a href={`/performer/${profileId}`}>My Profile</a> */}
                  <Link
                    to={`/performer/${profileId}`}
                    className={getActiveClass(`/performer/${profileId}`)}
                  >
                    My Profile
                  </Link>
                </li>
              ) : profileId.startsWith("PROFILE-VENUE") ? (
                <li>
                  {/* <a href={`/venue/${profileId}`}>My Profile</a> */}
                  <Link
                    to={`/venue/${profileId}`}
                    className={getActiveClass(`/performer/${profileId}`)}
                  >
                    My Profile
                  </Link>
                </li>
              ) : null}
              {showDashboard && profileId.startsWith("PROFILE-PERFORMER") && (
                <li>
                  <Link
                    to="/performer-dashboard-page"
                    className={getActiveClass("/performer-dashboard-page")}
                  >
                    Dashboard
                  </Link>
                </li>
              )}

              {showDashboard && profileId.startsWith("PROFILE-VENUE") && (
                <li>
                  <Link
                    to="/venue-dashboard-page"
                    className={getActiveClass("/venue-dashboard-page")}
                  >
                    Dashboard
                  </Link>
                </li>
              )}
              <li>
                {/* <a href="/profiles">Profile Hub</a> */}
                <Link to="/profiles" className={getActiveClass("/profiles")}>
                  Profile Hub
                </Link>
              </li>

              <li>
                <a
                  href="#!"
                  onClick={() => handleDropdownClick(0)}
                  className={getActiveClass("/search")}
                >
                  Search
                </a>
                <ul
                  className={`nav-dropdown ${
                    activeDropdown === 0 ? "active" : ""
                  }`}
                >
                  <li>
                    {/* <a href="/search/performer">Performer Search</a> */}
                    <Link
                      to="/search/performer"
                      className={getActiveClass("/search/performer")}
                    >
                      Performer Search
                    </Link>
                  </li>
                  <li>
                    {/* <a href="/search/venue">Venue Search</a> */}
                    <Link
                      to="/search/venue"
                      className={getActiveClass("/search/venue")}
                    >
                      Venue Search
                    </Link>
                  </li>
                  <li>
                    {/* <a href="/search/opportunity">Opportunity Search</a> */}
                    <Link
                      to="/search/opportunity"
                      className={getActiveClass("/search/opportunity")}
                    >
                      Opportunity Search
                    </Link>
                  </li>
                  <li>
                    {/* <a href="/search/call_out">CallOut Search</a> */}
                    <Link
                      to="/search/call_out"
                      className={getActiveClass("/search/call_out")}
                    >
                      CallOut Search
                    </Link>
                  </li>
                  <li>
                    {/* <a href="/search/classified">Classifieds Search</a> */}
                    <Link
                      to="/search/classified"
                      className={getActiveClass("/search/classified")}
                    >
                      Classifieds Search
                    </Link>
                  </li>
                  <li>
                    {/* <a href="/saved-searches">Saved Searches</a> */}
                    <Link
                      to="/saved-searches"
                      className={getActiveClass("/saved-searches")}
                    >
                      Saved Searches
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                {/* <a href="/account-page">Account</a> */}
                <Link
                  to="/account-page"
                  className={getActiveClass("/account-page")}
                >
                  Account
                </Link>
              </li>
              {/* <li>
                <button onClick={handleShow} className="message-button">
                  <FaEnvelope className="message-icon" />
                  {unreadCount > 0 && (
                    <span className="notification-badge">{unreadCount}</span>
                  )}
                </button>
              </li> */}
              {showMessageIcon && (
                <li>
                  <button
                    onClick={handleShow}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setModal(!modal);
                    //   if (!modal) fetchLastSevenMessages(); // Fetch messages on modal open
                    // }}
                    className="message-button"
                  >
                    <FaEnvelope className="message-icon" />
                    {unreadCount > 0 && (
                      <span className="notification-badge">{unreadCount}</span>
                    )}
                  </button>
                </li>
              )}
              {modal && (
                <div
                  className="custom-dropdown show p-3"
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "0",
                    width: "100%",
                    maxWidth: "400px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    zIndex: 1000,
                  }}
                  ref={messageModalRef}
                >
                  {messageThreads.map((thread) => {
                    const isProfileOwnerSender = profileId === thread.to;
                    const otherProfilePic = isProfileOwnerSender
                      ? thread.from_profile_pic_url
                      : thread.to_profile_pic_url;
                    const otherDisplayName = isProfileOwnerSender
                      ? thread.from_display_name
                      : thread.to_display_name;

                    return (
                      <div
                        key={thread.conversation_id}
                        className="message-card"
                        style={{
                          padding: "2px 6px",
                          marginBottom: "5px",
                          borderRadius: "8px",
                          backgroundColor: thread.has_opened_by_to
                            ? "#f8f9fa"
                            : "#e9ecef",
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                          transition: "background-color 0.3s",
                        }}
                      >
                        <Link
                          // to={`/messaging/${thread.conversation_id}`}
                          className="d-flex align-items-center"
                          // onClick={() => setModal(false)}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "default",
                          }}
                        >
                          <img
                            src={otherProfilePic}
                            alt={otherDisplayName}
                            className="rounded-circle me-2"
                            width="40"
                            height="40"
                            style={{ objectFit: "cover" }}
                          />
                          <div className="message-info">
                            <strong
                              style={{
                                color: thread.has_opened_by_to
                                  ? "#6c757d"
                                  : "#000",
                              }}
                            >
                              {otherDisplayName}
                            </strong>
                            <p
                              style={{
                                color: thread.has_opened_by_to
                                  ? "#6c757d"
                                  : "#000",
                                margin: 0,
                              }}
                            >
                              {/* {thread.last_message} */}
                              {thread.last_message.slice(0, 50)}
                              {thread.last_message.length > 50 && "..."}
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                  <Link
                    onClick={handleShow}
                    style={{
                      textAlign: "center",
                      display: "block",
                      color: "#007bff",
                    }}
                  >
                    Click here to open Messenger.
                  </Link>
                </div>
              )}
              <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                  <Modal.Title>Messages</Modal.Title>
                  <button
                    style={{
                      position: "absolute",
                      // top: "10px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <App />
                </Modal.Body>
              </Modal>

              <li>
                <button
                  onClick={handleNotificationClick}
                  className="message-button"
                >
                  <FaBell className="message-icon" />
                  {unreadNotCount > 0 && (
                    <span className="notification-badge">{unreadNotCount}</span>
                  )}
                </button>
              </li>
              {notificationModal && (
                <div
                  className="custom-dropdown show p-3"
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "0",
                    width: "100%",
                    maxWidth: "400px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    zIndex: 1000,
                  }}
                  ref={notificationModalRef}
                >
                  {notifications.map((notification) => (
                    <div
                      key={notification.notification_id}
                      className="message-card"
                      style={{
                        padding: "2px 6px",
                        marginBottom: "5px",
                        borderRadius: "8px",
                        backgroundColor: notification.has_opened_by_to
                          ? "#f8f9fa"
                          : "#e9ecef",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        transition: "background-color 0.3s",
                      }}
                    >
                      <Link
                        // to={`/messaging/${thread.conversation_id}`}
                        className="d-flex align-items-center"
                        // onClick={() => setModal(false)}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "default",
                        }}
                      >
                        <div className="message-info">
                          <strong
                            style={{
                              color: notification.has_opened
                                ? "#6c757d"
                                : "#000",
                            }}
                          >
                            {notification.title}
                          </strong>
                          <p
                            style={{
                              color: notification.has_opened
                                ? "#6c757d"
                                : "#000",
                              margin: 0,
                            }}
                          >
                            {notification.body}
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}
                  <Link
                    onClick={handleShow1}
                    style={{
                      textAlign: "center",
                      display: "block",
                      color: "#007bff",
                    }}
                  >
                    Click to see all notifications
                  </Link>
                </div>
              )}
              <Modal show={show1} onHide={handleClose1} size="xl">
                <Modal.Header>
                  <Modal.Title>Notifications</Modal.Title>
                  <button
                    style={{
                      position: "absolute",
                      // top: "10px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleClose1}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {allnotifications.map((allnotification) => (
                    <div
                      key={allnotification.notification_id}
                      className="message-card"
                      style={{
                        padding: "2px 6px",
                        marginBottom: "5px",
                        borderRadius: "8px",
                        backgroundColor: allnotification.has_opened_by_to
                          ? "#f8f9fa"
                          : "#e9ecef",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        transition: "background-color 0.3s",
                      }}
                    >
                      <Link
                        // to={`/messaging/${thread.conversation_id}`}
                        className="d-flex align-items-center"
                        // onClick={() => setModal(false)}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "default",
                        }}
                      >
                        <div className="message-info">
                          <strong
                            style={{
                              color: allnotification.has_opened
                                ? "#6c757d"
                                : "#000",
                            }}
                          >
                            {allnotification.title}
                          </strong>
                          <p
                            style={{
                              color: allnotification.has_opened
                                ? "#6c757d"
                                : "#000",
                              margin: 0,
                            }}
                          >
                            {allnotification.body}
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}

                  {allnotifications.length > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn btn-primary me-3"
                        disabled={!previousUrl || loadingPrevious}
                        onClick={handlePreviousNotification}
                        style={{
                          opacity: loadingPrevious || !previousUrl? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor: loadingPrevious || !previousUrl? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingPrevious ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Previous"
                        )}
                        
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={loadingNext || !nextUrl}
                        onClick={handleNextNotification}
                        style={{
                          opacity: loadingNext  || !nextUrl? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor: loadingNext || !nextUrl? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingNext ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Next"
                        )}
                        
                      </button>
                      {/* <button
                        className="btn btn-primary me-3"
                        disabled={!previousUrl}
                        onClick={handlePreviousNotification}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={!nextUrl}
                        onClick={handleNextNotification}
                      >
                        Next
                      </button> */}
                    </div>
                  )}
                </Modal.Body>
              </Modal>
              {/* <li>
                <a onClick={handleShow}>
                  Messages{"  "} <FaMessage className="icons" />
                </a>
              </li>
              <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                  <Modal.Title>Messages</Modal.Title>
                  <button
                    style={{
                      position: "absolute",
                      // top: "10px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <App />
                </Modal.Body>
              </Modal> */}
              {/* <li>
                <a href="/messaging" target="_blank">
                  Messages{"  "} <FaMessage className="icons" />
                </a>
              </li> */}
              {/* <li>
                <Link
                  // to="#"
                  // className="btn btn-sm btn-icon btn-pills btn-primary position-relative"
                  className="position-relative"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(!modal);
                    if (!modal) fetchLastSevenMessages();
                  }}
                >
                  Messages {"  "} <FaMessage className="icons" />
                  {unreadCount > 0 && (
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                      {unreadCount}
                    </span>
                  )}
                </Link>

                {modal && (
                  <div
                    className="dropdown-menu show p-3"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "0",
                      width: "300px",
                    }}
                    ref={messageModalRef}
                  >
                    <ul className="list-unstyled">
                      {messageThreads.map((thread) => (
                        <li
                          key={thread.conversation_id}
                          className="mb-2"
                          style={{
                            backgroundColor: thread.has_opened_by_to
                              ? "#f8f9fa"
                              : "#e9ecef",
                          }}
                        >
                          <Link
                            to={`/messaging/${thread.conversation_id}`}
                            className="d-flex align-items-center"
                            onClick={() => setModal(false)}
                          >
                            <img
                              src={thread.from_profile_pic_url}
                              alt={thread.from_display_name}
                              className="rounded-circle me-2"
                              width="40"
                              height="40"
                            />
                            <div>
                              <strong
                                style={{
                                  color: thread.has_opened_by_to
                                    ? "#6c757d"
                                    : "#000",
                                }}
                              >
                                {thread.from_display_name}
                              </strong>
                              <p
                                style={{
                                  color: thread.has_opened_by_to
                                    ? "#6c757d"
                                    : "#000",
                                }}
                                className="mb-0"
                              >
                                {thread.last_message}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <Link to="/messaging" className="btn btn-primary btn-block">
                      Show More
                    </Link>
                  </div>
                )}
              </li> */}
              {/* <li>
                <a href="#!">About Us</a>
              </li> */}
              {/* <li
                // className="list-inline-item ps-1 mb-0"
                style={{ marginTop: "3%" }}
              >
                <Link
                  to="#"
                  className="btn btn-sm btn-icon btn-pills btn-primary position-relative"
                  onClick={handleNotificationClick}
                >
                  <FaBell className="icons" />
                  {unreadNotCount > 0 && (
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                      {unreadNotCount}
                    </span>
                  )}
                </Link>

                {notificationModal && (
                  <div
                    className="dropdown-menu show p-3"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "0",
                      width: "300px",
                    }}
                    ref={notificationModalRef}
                  >
                    <ul className="list-unstyled">
                      {notifications.map((notification) => (
                        <li
                          key={notification.notification_id}
                          className="mb-2"
                          style={{
                            backgroundColor: notification.has_opened
                              ? "#f8f9fa"
                              : "#e9ecef",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <strong
                                style={{
                                  color: notification.has_opened
                                    ? "#6c757d"
                                    : "#000",
                                }}
                              >
                                {notification.title}
                              </strong>
                              <p
                                style={{
                                  color: notification.has_opened
                                    ? "#6c757d"
                                    : "#000",
                                }}
                                className="mb-0"
                              >
                                {notification.body}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/notifications"
                      className="btn btn-primary btn-block"
                    >
                      Show More
                    </Link>
                  </div>
                )}
              </li> */}
            </ul>
          </nav>
        </div>
      </section>
    </header>
  );
};

export default NavigationBar;
