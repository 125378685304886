import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosInstance from "../auth/axiosInstance";
import { Spinner } from "react-bootstrap";

const localizer = momentLocalizer(moment);

const VenueCalendarPublicView = ({ profileId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const profileIdn = profileId;
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const fetchEventsByMonth = async (year, month) => {
    setIsLoading(true);
    try {
      // const profileId = Cookies.get("publicProfileId");
      // const profileId = Cookies.get("profileId");
      const accessToken = Cookies.get("accessToken");

      const userEventsResponse = await axiosInstance.post(
        `/v1/calendar/get-events/by-month/visited`,
        {
          profile_id: profileIdn,
          year: year,
          month: month,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("public view venue events: ", userEventsResponse);
      console.log(profileIdn);
      const userEvents = userEventsResponse.data.calendar_events.map(
        (event) => ({
          id: event._id,
          // title: event.name === "[UNAVAILABLE]" ? "Unavailable" : "Available",
          title:
            event.name === "[UNAVAILABLE]"
              ? "Unavailable"
              : event.name.startsWith("Opportunity")
              ? "Unavailable - Opportunity"
              : "Available",
          start: convertUTCToLocal(new Date(event.start_date)),
          end: convertUTCToLocal(new Date(event.end_date)),
          // start: new Date(event.start_date),
          // end: new Date(event.end_date),
          recurrence: event.recurrence,
          slot_status: event.slot_status,
          slot_reservation_type: event.slot_reservation_type,
        })
      );

      // const allEvents = [...bookingEvents, ...userEvents];
      setEvents(userEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      console.log(profileIdn);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEventsByMonth(currentYear, currentMonth);
  }, [currentYear, currentMonth]);

  return (
    <div>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          eventPropGetter={(event, start, end, isSelected) => {
            let eventStyle = {
              backgroundColor: "",
              color: "",
            };

            if (
              event.slot_status === "UNAVAILABLE" &&
              event.slot_reservation_type === "BOOKING"
            ) {
              eventStyle.backgroundColor = "gray";
            }

            if (
              event.slot_status === "AVAILABLE" &&
              event.slot_reservation_type === "USER"
            ) {
              eventStyle.backgroundColor = "#5BFF33";
            }

            if (
              event.slot_status === "UNAVAILABLE" &&
              event.slot_reservation_type === "BOOKING"
            ) {
              eventStyle.backgroundColor = "#329bad";
            }

            return {
              style: eventStyle,
            };
          }}
        />
      )}
    </div>
  );
};

export default VenueCalendarPublicView;
