import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faPhone,
  faIdCard,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import axios from "axios";
import PasswordChecklist from "react-password-checklist";
import Spinner from "react-bootstrap/Spinner";
import NavigationBar from "../../components/navbar/loggedOutUserNavbar";

const baseBackendURL = process.env.REACT_APP_BASE_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  padding-top: 120px;

  @media (max-width: 768px) {
    padding-top: 100px; // Adjust margin as needed for spacing
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 5px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  color: #666;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 3px;
  color: #666;
`;

// const CheckboxLabel = styled.label`
//   display: flex;
//   align-items: center;
// `;

// const Checkbox = styled.input`
//   margin-right: 10px;
// `;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SignInLink = styled.p`
  margin-top: 15px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignupSchema = (isCreatingVenueProfile) =>
  yup.object().shape({
    email: yup.string().required("Email is required").email(),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Password doesn't match the criteria"
      ),
    password_again: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    phone_number: yup
      .string()
      .required("Phone Number is required")
      .matches(
        /^(?:\+?61|0)4\d{8}$/,
        "Only Australian mobile numbers are allowed."
      ),
    abn_number: isCreatingVenueProfile
      ? yup
          .string()
          .required("ABN Number is required")
          .matches(/^\d{11}$/, "Please enter a valid 11-digit ABN Number")
      : yup.string().notRequired(),
    acn_number: isCreatingVenueProfile
      ? yup
          .string()
          .required("ACN Number is required")
          .matches(/^\d{9}$/, "Please enter a valid 9-digit ACN Number")
      : yup.string().notRequired(),
    business_name: isCreatingVenueProfile
      ? yup.string().required("Business Name is required")
      : yup.string().notRequired(),
    business_location: isCreatingVenueProfile
      ? yup.string().required("Business Location is required")
      : yup.string().notRequired(),

    terms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
    privacy: yup
      .boolean()
      .oneOf([true], "You must accept the privacy policies"),
  });

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [referralProfileId, setReferralProfileId] = useState("");
  const [isCreatingVenueProfile, setIsCreatingVenueProfile] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#f0f2f5";

    // Cleanup function to reset the background color
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []); // <== Added useEffect hook

  const handleLogin = () => {
    navigate("/login");
  };

  const handleHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get("ref");
    if (referralId) {
      setReferralProfileId(referralId);
      formik.setFieldValue("referral_profile_id", referralId);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_again: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      abn_number: "",
      acn_number: "",
      business_name: "",
      business_location: "",
      terms: false,
      privacy: false,
      referral_profile_id: "",
    },
    validationSchema: SignupSchema(isCreatingVenueProfile),
    onSubmit: async (values) => {
      setLoading(true);
      console.log("payload values: ", values);
      try {
        const response = await axios.post(
          `${baseBackendURL}/v1/auth/sign-up`,
          // "https://api.ogotrix.au/v1/auth/sign-up",
          // "http://127.0.0.1:4000/v1/auth/sign-up",
          // "https://www.ramoi.com/v1/auth/sign-up",
          values
        );

        console.log("API Response:", response?.data.data);
        Swal.fire({
          title: "Success!",
          text: "Your account has been successfully created. Please sign in to continue.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate("/login");
        });
      } catch (error) {
        // console.log(error);
        // console.error("API Error:", error.response?.data.data);

        let errorMessage =
          "An error occurred during sign-up. Please try again.";

        if (error.response && error.response.data && error.response.data.data) {
          errorMessage = error.response.data.message;
        }
        console.log(error.response.data);
        console.log(error.response.data.error_description);

        // Swal.fire("Error", errorMessage, "error");

        // Swal.fire({
        //   icon: "error",
        //   title: "Error",
        //   text: errorMessage,
        // });

        // const errorMessage =

        if (errorMessage === "USER_ALREADY_EXISTS") {
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCheckboxChange = (event) => {
    setIsCreatingVenueProfile(event.target.checked);
  };

  return (
    <Container>
      {/* <NavBar isMobile={isMobile}>
        <Logo src={logo} alt="Logo" />
        <div>          
          <ButtonB onClick={handleLogin}>Login</ButtonB>
        </div>
      </NavBar> */}
      <NavigationBar />
      <Title>Create an Account</Title>
      <SubTitle>If you don't have an account you can Register here!</SubTitle>
      {/* <SubTitle>Get access to a sea of talents and opportunities</SubTitle> */}

      <FormWrapper onSubmit={formik.handleSubmit}>
        <InputGroup>
          <Label htmlFor="first_name">First Name</Label>
          <InputWrapper>
            <Icon icon={faUser} />
            <Input
              type="text"
              id="first_name"
              name="first_name"
              className="form-control"
              placeholder="First name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          {formik.errors.first_name && formik.touched.first_name && (
            <div className="text-danger">{formik.errors.first_name}</div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="last_name">Last Name</Label>
          <InputWrapper>
            <Icon icon={faUser} />
            <Input
              type="text"
              id="last_name"
              name="last_name"
              className="form-control"
              placeholder="Last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          {formik.errors.last_name && formik.touched.last_name && (
            <div className="text-danger">{formik.errors.last_name}</div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="phone_number">Phone Number</Label>
          <InputWrapper>
            <Icon icon={faPhone} />
            <Input
              type="text"
              id="phone_number"
              name="phone_number"
              className="form-control"
              placeholder="+61XXXXXXXXX"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          {formik.errors.phone_number && formik.touched.phone_number && (
            <div className="text-danger">{formik.errors.phone_number}</div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="email">Email</Label>
          <InputWrapper>
            <Icon icon={faEnvelope} />
            <Input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          {formik.errors.email && formik.touched.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="password">Password</Label>
          <InputWrapper>
            <Icon icon={faLock} />
            <Input
              type="password"
              id="password"
              name="password"
              className="form-control"
              placeholder="Password"
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                setIsPasswordFocused(true);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                setIsPasswordFocused(false);
              }}
            />
          </InputWrapper>
          {formik.errors.password && formik.touched.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="password_again">Confirm Password</Label>
          <InputWrapper>
            <Icon icon={faLock} />
            <Input
              type="password"
              id="password_again"
              name="password_again"
              className="form-control"
              placeholder="Confirm password"
              value={formik.values.password_again}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          {formik.errors.password_again && formik.touched.password_again && (
            <div className="text-danger">{formik.errors.password_again}</div>
          )}
        </InputGroup>
        {isPasswordFocused && (
          <PasswordChecklist
            style={{ textAlign: "left" }}
            rules={[
              "minLength",
              "specialChar",
              "number",
              "capital",
              "lowercase",
            ]}
            minLength={8}
            value={formik.values.password}
            valueAgain={formik.values.password_again}
          />
        )}

        {/* <CheckboxWrapper>
          <CheckboxLabel>
            <Checkbox type="checkbox" value="" id="keepSignedIn" />
            <span>Are you creating a venue profile?</span>
          </CheckboxLabel>
          
        </CheckboxWrapper> */}

        <CheckboxWrapper>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              id="creating_venue_profile"
              name="creating_venue_profile"
              onChange={handleCheckboxChange}
            />
            Creating Venue Profile
          </CheckboxLabel>
        </CheckboxWrapper>

        {isCreatingVenueProfile && (
          <>
            <InputGroup>
              <Label htmlFor="abn_number">ABN Number</Label>
              <InputWrapper>
                <Icon icon={faIdCard} />
                <Input
                  id="abn_number"
                  name="abn_number"
                  className="form-control"
                  placeholder="ABN number"
                  value={formik.values.abn_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputWrapper>
              {formik.errors.abn_number && formik.touched.abn_number && (
                <div className="text-danger">{formik.errors.abn_number}</div>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="acn_number">ACN Number</Label>
              <InputWrapper>
                <Icon icon={faIdCard} />
                <Input
                  id="acn_number"
                  name="acn_number"
                  className="form-control"
                  placeholder="ACN number"
                  value={formik.values.acn_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputWrapper>
              {formik.errors.acn_number && formik.touched.acn_number && (
                <div className="text-danger">{formik.errors.acn_number}</div>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="business_name">Business Name</Label>
              <InputWrapper>
                <Icon icon={faBriefcase} />
                <Input
                  type="text"
                  id="business_name"
                  name="business_name"
                  className="form-control"
                  placeholder="Business name"
                  value={formik.values.business_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputWrapper>
              {formik.errors.business_name && formik.touched.business_name && (
                <div className="text-danger">{formik.errors.business_name}</div>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="business_location">Business Location</Label>
              <InputWrapper>
                <Icon icon={faBriefcase} />
                <Input
                  type="text"
                  id="business_location"
                  name="business_location"
                  className="form-control"
                  placeholder="Business location"
                  value={formik.values.business_location}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputWrapper>
              {formik.errors.business_location &&
                formik.touched.business_location && (
                  <div className="text-danger">
                    {formik.errors.business_location}
                  </div>
                )}
            </InputGroup>
          </>
        )}

        <InputGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              id="terms"
              name="terms"
              checked={formik.values.terms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            I agree to the
            <a
              style={{ marginLeft: "5px" }}
              href="https://ozstream.au/gigbridge-terms-and-conditions/"
            >
              {" "}
              Terms & Conditions
            </a>
          </CheckboxLabel>
          {formik.errors.terms && formik.touched.terms && (
            <div className="text-danger">{formik.errors.terms}</div>
          )}
        </InputGroup>

        <InputGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              id="privacy"
              name="privacy"
              checked={formik.values.privacy}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            I agree to the
            <a
              style={{ marginLeft: "5px" }}
              href="https://ozstream.au/privacy-collection-notice/"
            >
              {" "}
              Privacy Policies
            </a>
          </CheckboxLabel>
          {formik.errors.privacy && formik.touched.privacy && (
            <div className="text-danger">{formik.errors.privacy}</div>
          )}
        </InputGroup>

        <Button type="submit" disabled={loading}>
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderWidth: "0.1rem",
              }}
            />
          ) : (
            "Sign Up"
          )}
        </Button>
      </FormWrapper>

      <SignInLink>
        Already have an account? <a href="/login">Sign In</a>
      </SignInLink>
    </Container>
  );
}
