import styled from "styled-components";
// import bookingImage from "../assect/images/OpportunityImage2.jpg";
// import payment1 from "../assect/images/payment/visa.png";
// import payment2 from "../assect/images/payment/card.png";
// import payment3 from "../assect/images/payment/discover.png";
// import payment4 from "../assect/images/payment/4.svg";
import profileImage from "../../assect/images/client/02.jpg";
import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import NewVenueProfile from "../OtherPages/newVenueProfile";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 10px;
  margin-right: 70px;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  margin-left: 70px;
  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const DirectBookingPerformerView = ({ bookingId }) => {
  const [booking, setbooking] = useState(null);
  const accessToken = Cookies.get("accessToken");
  // const { bookingId } = useParams();
  const selectedbookingId = bookingId; //|| Cookies.get("bookingId");
  const [hasApplied, setHasApplied] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchbooking = async () => {
      try {
        const response = await axiosInstance.post(
          `/v1/booking-direct/view-all/by/direct-booking-id`,
          {
            direct_booking_id: selectedbookingId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setbooking(response.data.bookings_direct);
        const fetchedOpportunity = response.data.bookings_direct;
        fetchVenueDetails(fetchedOpportunity.venue_profile_id);
        setauthorId(fetchedOpportunity.venue_profile_id);
        console.log("booking id: ", response.data.bookings_direct);
      } catch (error) {
        console.error("Error fetching booking:", error);
      }
    };

    const fetchVenueDetails = async (venueId) => {
      try {
        const venueSection = "info";
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${venueId}/section/${venueSection}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("venue info:", response.data.venue_info.profile_name);
          setVenue(response.data.venue_info);
        } else {
          console.error("Error fetching venue details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching venue details:", error);
      }
    };

    const checkIfApplied = async () => {
      const loggedInProfileId = Cookies.get("profileOwnerId");
      const payload = {
        booking_id: selectedbookingId,
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/has-applied",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setHasApplied(response.data.has_applied);
        } else {
          console.error(
            "Error checking application status:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchbooking();
    checkIfApplied();
  }, [selectedbookingId, accessToken]);

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  const handleApplyButtonClick = async () => {
    if (booking.status !== "OPEN") {
      setTooltipVisible(true);
      return;
    }
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const bookingId = selectedbookingId;

    const payload = {
      booking_id: bookingId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post("/v1/booking/apply", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Application successful!",
        });
        console.log("Application successful:", response.data);
        // navigate(`/performer/booking/${bookingId}`);
      } else {
        console.error("Application error:", response.status, response.data);
      }
    } catch (error) {
      console.error("Request error:", error);
      let errorMessage = "There was an error applying to this.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const handleWithdrawButtonClick = async () => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      booking_id: selectedbookingId,
      logged_in_profile_id: loggedInProfileId,
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to withdraw your application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, withdraw",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        const response = await axiosInstance.post(
          "/v1/booking/withdraw/application/by-performer",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("Withdrawal successful:", response.data);
          setHasApplied(false);
          setWithdrawn(true);
          // navigate(`/performer/booking/${selectedbookingId}`);
        } else {
          console.error("Withdrawal error:", response.status, response.data);
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    }
  };

  const [venue, setVenue] = useState(null);
  const [authorId, setauthorId] = useState(null);

  const getVenueProfileImage = () => {
    return venue?.thumbnail_profile_pic_url || venue?.profile_picture_url;
  };

  const [selectedperformerId, setSelectedperformerId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    console.log("author id", authorId);

    setSelectedperformerId(authorId);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedperformerId(null);
    setShowModal(false);
  };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      {booking && (
        <>
          <LeftColumn>
            <CategoryTag>{booking.status}</CategoryTag>
            <Title>{booking?.title}</Title>
            <InfoRow>
              <Info>
                <ProfileImage
                  src={getVenueProfileImage()}
                  alt="Venue Profile"
                />
              </Info>

              <Info>
                Venue:{"  "}
                <a
                  className="link-btn"
                  onClick={() => openModal()}
                  style={{ cursor: "pointer" }}
                >
                  <strong>{venue ? venue.profile_name : "Loading..."}</strong>
                </a>
              </Info>
              {/* <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info> */}
            </InfoRow>

            {/* <Tabs>
          <Tab
            active={activeTab === "bookingInfo"}
            onClick={() => setActiveTab("bookingInfo")}
          >
            booking Info
          </Tab>
          <Tab
            active={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Reviews
          </Tab>
        </Tabs> */}
            <Content>
              <About>
                <h2>Message</h2>
                {booking.message &&
                  booking.message.map((line, index) => (
                    <p key={index}>{line ? line : <br />}</p>
                  ))}
                {/* <p>{booking.message}</p> */}
                {/* <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox> */}
              </About>
              {/* {activeTab === "bookingInfo" && (
            <>
              
            </>
          )} */}
              {/* {activeTab === "media" && <div>Reviews content goes here...</div>} */}
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {/* <EnrollButton></EnrollButton> */}
              {/* <div className="buttonsContainer"> */}
              {/* <center>
                {withdrawn ? (
                  <span className="label">Cancelled by you</span>
                ) : hasApplied ? (
                  <button
                    className="oppbutton2"
                    style={{
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    onClick={handleWithdrawButtonClick}
                  >
                    WITHDRAW
                  </button>
                ) : (
                  <>
                    <button
                      className="oppbutton2"
                      style={{
                        padding: "15px 50px",
                        width: "100%",
                        fontSize: "16px",
                      }}
                      onClick={handleApplyButtonClick}
                    >
                      APPLY
                    </button>
                    {tooltipVisible && (
                      <div
                        style={{
                          marginTop: "10px",
                          padding: "10px",
                          backgroundColor: "#f8d7da",
                          color: "black",
                          border: "1px solid #f5c6cb",
                          borderRadius: "4px",
                        }}
                      >
                        This booking post is in {booking.status} status, you
                        can't apply to this.
                      </div>
                    )}
                  </>
                )}
              </center> */}

              <br></br>
              <Stats>
                <DateContainer>
                  {/* <span style={{ marginBottom: "18px" }}>
                    Price: <DateText>AUD {booking.price_point_value}</DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    Location: <DateText>{booking.location.address}</DateText>
                  </span> */}
                  <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(booking.from_date)}
                      {"   "}
                      {formatTime(booking.from_time)}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    To: {"  "}
                    <DateText>
                      {formatDate(booking.to_date)}
                      {"   "}
                      {formatTime(booking.to_time)}
                    </DateText>
                  </span>
                  {/* <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(booking.from_date)}
                      {"   "}
                      {formatTime(booking.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(booking.to_date)}
                      {"   "}
                      {formatTime(booking.to_time)}
                    </DateText>
                  </span> */}
                  <span>
                    Status: <DateText>{booking.status}</DateText>
                  </span>
                </DateContainer>
              </Stats>

              {/* <TagContainer>
                <h6>booking Types:</h6>
                {booking.booking_types.map((genre) => (
                  <TagBox key={genre}>{genre}</TagBox>
                ))}
              </TagContainer>

              <TagContainer>
                <h6>booking genres:</h6>
                {booking.booking_genres.map((type) => (
                  <TagBox key={type}>{type}</TagBox>
                ))}
              </TagContainer> */}
            </SideCard>
          </RightColumn>
        </>
      )}
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ minWidth: "110% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "0px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {selectedperformerId && (
            <NewVenueProfile profileId={selectedperformerId} />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default DirectBookingPerformerView;
