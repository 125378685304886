import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import NavigationBar from "../../components/navbar/loggedOutUserNavbar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height:: 100vh;
  padding-top: 150px;    
  padding: 20px;
  margin-top:100px;
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;
`;

const SubTitle = styled.p`
  margin-bottom: 35px;
  color: #666;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6; 
    cursor: not-allowed;
  }
`;

const SignInLink = styled.p`
  display: block;
  margin-top: 20px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function ResetPassword() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const validate = () => {
    const errors = {};
    if (!formData.email) errors.email = "Email is required";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error message for the corresponding field
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    

    const payload = { email: formData.email }; // Set the payload based on user input

    try {
      const response = await axiosInstance.post(
        "/v1/auth/forgot-password",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "A temporary password has been successfully sent to your registered email address!",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate("/temporaryPasswordSent");
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred during the password reset process. Please try again.";

      if (error.response && error.response.data && error.response.data.data) {
        errorMessage = error.response.data.message;
        console.log(error.response.data)
      }

      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#f0f2f5";

    // Cleanup function to reset the background color
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []); // <== Added useEffect hook

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleHome = () => {
    navigate("/login");
  };

  return (
    <Container>
      {/* <NavBar isMobile={isMobile}>
        <Logo src={logo} alt="Logo" />
        <div>
          <ButtonB onClick={handleHome}>Sign in</ButtonB>
          <ButtonB onClick={handleSignup}>Sign up</ButtonB>
        </div>
      </NavBar> */}
      <NavigationBar />

      <Title>Forget Password.</Title>
      <SubTitle>
        Forgot your Password? No Problem. Get your new password to your Email
      </SubTitle>

      <FormWrapper>
        <InputGroup>
          <Label htmlFor="floatingInput">Email</Label>
          <InputWrapper>
            <Icon icon={faEnvelope} />
            <Input
              name="email"
              type="email"
              id="floatingInput"
              placeholder="e-mail address"
              value={formData.email}
              onChange={handleChange}
            />
          </InputWrapper>
        </InputGroup>

        <Button type="submit" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderWidth: "0.1rem",
              }}
            />
          ) : (
            "Reset password"
          )}
        </Button>

        <SignInLink>
          Back to <a href="/login">Sign In</a>
        </SignInLink>
      </FormWrapper>
    </Container>
  );
}
