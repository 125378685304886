import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import defaultProfilePicture from "../../assect/images/client/02.jpg";
import axiosInstance from "../auth/axiosInstance";
import "./FollowedProfiles.css";
import { useNavigate } from "react-router-dom";
import NewPerformerProfileTest from "../OtherPages/newPerformerProfile";
import NewVenueProfileTest from "../OtherPages/newVenueProfile";
import Spinner from "react-bootstrap/Spinner";

const FollowedProfilesPage = () => {
  const [followedProfiles, setFollowedProfiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [loadingfollowed, setLoadingfollowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedperformerId, setSelectedperformerId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const profileId = Cookies.get("profileOwnerId");
  const navigate = useNavigate();
  const profile = profiles.find(profile => profile._id === selectedperformerId);

  useEffect(() => {
    const fetchFollowedProfiles = async () => {
      try {
        const response = await axiosInstance.post(
          "/v1/profile/followed-profiles/by-self",
          {
            profile_id: profileId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const followedProfilesData = response.data.profiles_followed_by_self;
          setFollowedProfiles(followedProfilesData);
        } else {
          console.error("API Error:", response.status, response.data);
        }
      } catch (error) {
        console.error("Request Error:", error);
        setError("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFollowedProfiles();
  }, [profileId]);

  useEffect(() => {
    const fetchProfiles = async () => {
      const profilesData = await Promise.all(
        followedProfiles.map(async (profile) => {
          try {
            let response;
            if (profile.followed_profile_id.startsWith("PROFILE-VENUE")) {
              response = await axiosInstance.get(
                `/v1/venue/view-profile/${profile.followed_profile_id}/section/complete`,
                {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("accessToken")}`,
                  },
                }
              );
              return { ...response.data.venue_info, profile_type: "VENUE" };
            } else if (
              profile.followed_profile_id.startsWith("PROFILE-PERFORMER")
            ) {
              response = await axiosInstance.get(
                `/v1/performer/view-profile/${profile.followed_profile_id}/section/complete`,
                {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("accessToken")}`,
                  },
                }
              );
              console.log("Performer Profile Response:", response.data.performer_info);
              return { ...response.data.performer_info, profile_type: "PERFORMER" };
            }
          } catch (error) {
            console.error("Request Error:", error);
            setError("An unexpected error occurred. Please try again later.");
          }
        })
      );
      console.log("All Profiles Data:", profilesData);
      setProfiles(profilesData);
    };

    if (followedProfiles.length > 0) {
      fetchProfiles();
    }
  }, [followedProfiles]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleBackClick = () => {
    const profileType = profileId.split("-")[1];
    if (profileType === "PERFORMER") {
      navigate(`/performer/${profileId}`);
    } else if (profileType === "VENUE") {
      navigate(`/venue/${profileId}`);
    }
  };

  const openModal = (id) => {
    // Assuming 'id' is the selected profile ID
    setSelectedperformerId(id);
    setShowModal(true);
  };


  const closeModal = () => {
    setSelectedperformerId(null);
    setShowModal(false);
  };


  return (
    <div className="followed-profiles-page-custom">
      {/* <div
        className="followed-back-arrow-custom"
        onClick={handleBackClick}
        style={{ cursor: 'pointer' }}
      >
        ←
      </div> */}

      <button
        className="followed-back-arrow"
        onClick={handleBackClick}
        style={{
          border: "none",
          outline: "none",
          marginLeft: "25%",
          fontSize: '25px', 
          marginBottom: '10px', 
          color: 'black',
          background: 'transparent', 
          cursor: 'pointer',
        }}
      >
        ←
      </button>


      <h2>Followed Profiles</h2>
      <div className="followed-profiles-list-custom">
        {profiles.map((profile) => (
          <div key={profile.id} className="profile-card-custom">
            <img
              src={profile.profile_picture_url || defaultProfilePicture}
              alt={profile.profile_name}
              className="profile-picture-custom"
            />
            <div className="profile-info-custom">
              <a
                //   href="#"
                className="link-btn"
                onClick={() => openModal(profile._id)}
                style={{ cursor: "pointer" }}
              >
                <h4
                >
                  {profile.profile_name}
                </h4>
              </a>

            </div>
            <div className="follow-button-custom">
              <button className="followed-button-custom"
                disabled={loadingfollowed}
                style={{
                  opacity: loadingfollowed ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                  cursor: loadingfollowed ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                  transition: "opacity 0.3s ease", // Smooth transition for opacity change
                  minWidth: "100px",
                }}>
                {loadingfollowed ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{
                      width: "1.2rem",
                      height: "1.2rem",
                      borderWidth: "0.1rem",
                    }}
                  />
                ) : (
                  "Followed"
                )}
              </button>
            </div>
          </div>
        ))}
      </div>


      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ minWidth: "110% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {selectedperformerId && profiles.find(profile => profile._id === selectedperformerId) ? (
            <NewPerformerProfileTest profileId={selectedperformerId} />
          ) : (
            <div>No profile found</div>
          )}
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default FollowedProfilesPage;
