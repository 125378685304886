// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqs-back-arrow-custom {
    font-size: 24px; /* Size of the arrow */
    margin-bottom: 10px; /* Space below the arrow */
    margin-left: 12%; 
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/pages/OtherPages/faqs.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,sBAAsB;IACvC,mBAAmB,EAAE,0BAA0B;IAC/C,gBAAgB;IAChB,YAAY;EACd","sourcesContent":[".faqs-back-arrow-custom {\n    font-size: 24px; /* Size of the arrow */\n    margin-bottom: 10px; /* Space below the arrow */\n    margin-left: 12%; \n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
