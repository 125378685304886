import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg1.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import AutoComplete from "../../components/autoComplete";
import "../performer/CreatePerformer.css";
import "../OtherPages/LandingProfiles.css";

function CreateVenueProfile() {
  const [formData, setFormData] = useState({
    venue_name: "",
    business_name: "",
    abn_number: "",
    venue_genres: [],
    venue_types: [],
    location: {
      suburb: "",
      zipcode: "",
      latitude: 0,
      longitude: 0,
      location_name: "",
      address: "",
      google_map_pin: "",
    },
  });
  const [genres, setGenres] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);
  const [showGenresDropdown, setShowGenresDropdown] = useState(false);
  const typesInputRef = useRef(null);
  const genresInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("Fetching data...");
        const [genresResponse, typesResponse] = await Promise.all([
          axiosInstance.get("/v1/venue/venue-genres", {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }),
          axiosInstance.get("/v1/venue/venue-types", {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }),
        ]);

        console.log("Data received:", typesResponse, genresResponse);

        if (genresResponse.status === 200 && typesResponse.status === 200) {
          console.log("Performer types response data:", typesResponse.data);
          setGenres(genresResponse.data.venue_genres);
          setTypes(typesResponse.data.venue_types);
        } else {
          setErrorMessage("Error fetching data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      location: { ...prevData.location, [name]: value },
    }));
  };

  // const handleGenreChange = (event) => {
  //   const checked = event.target.checked;
  //   const value = event.target.value;

  //   if (checked) {
  //     setSelectedGenres([...selectedGenres, value]);
  //   } else {
  //     setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
  //   }
  // };

  // const handleTypeChange = (event) => {
  //   const checked = event.target.checked;
  //   const value = event.target.value;

  //   if (checked) {
  //     setSelectedTypes([...selectedTypes, value]);
  //   } else {
  //     setSelectedTypes(selectedTypes.filter((type) => type !== value));
  //   }
  // };

  const handleTypeChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedTypes.length < 3) {
        setSelectedTypes([...selectedTypes, value]);
      } else {
        setErrorMessage("You can only select a maximum of 3 performer types.");
      }
    } else {
      setSelectedTypes(selectedTypes.filter((type) => type !== value));
    }
  };

  const handleGenreChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedGenres.length < 7) {
        setSelectedGenres([...selectedGenres, value]);
      } else {
        setErrorMessage("You can only select a maximum of 7 genres.");
      }
    } else {
      setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);

  //   const payload = {
  //     type: "VENUE",
  //     ...formData,
  //     venue_genres: selectedGenres,
  //     venue_types: selectedTypes,
  //   };

  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/venue/create-profile",
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );

  //     setLoading(false);

  //     if (response.status === 200) {
  //       const profileId = response.data.profile_id;
  //       Cookies.set("profileId", profileId, { secure: true });

  //       Swal.fire({
  //         title: "Success!",
  //         text: "Your venue profile was created successfully.",
  //         icon: "success",
  //         className: "swal-modal",
  //       }).then(() => {
  //         navigate(`/venue/${profileId}`);
  //       });
  //     } else {
  //       setErrorMessage(response.data.error);
  //       Swal.fire({
  //         title: "Error!",
  //         text: errorMessage,
  //         icon: "error",
  //         className: "swal-modal",
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setErrorMessage("An unexpected error occurred. Please try again.");
  //     Swal.fire({
  //       title: "Error!",
  //       text: errorMessage,
  //       icon: "error",
  //       className: "swal-modal",
  //     });
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      type: "VENUE",
      ...formData,
      venue_genres: selectedGenres,
      venue_types: selectedTypes,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/venue/create-profile",
        payload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        const profileId = response.data.profile_id;
        Cookies.set("profileId", profileId, { secure: true });

        const profileResponse = await axiosInstance.get(
          `/v1/venue/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (profileResponse.status === 200) {
          const profileStatus = profileResponse.data.venue_info.status;

          if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-PERFORMER")
          ) {
            navigate("/performer-pricing");
          } else if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-VENUE")
          ) {
            navigate("/venue-pricing");
          } else {
            Swal.fire({
              title: "Success!",
              text: "Your profile was created successfully.",
              icon: "success",
              className: "swal-modal",
            }).then(() => {
              navigate(`/venue/${profileId}`);
            });
          }
        } else {
          setErrorMessage("Error fetching profile status.");
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        }
      } else {
        setErrorMessage(response.data.error);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleTypesInputClick = () => {
    setShowTypesDropdown(!showTypesDropdown);
  };

  const handleGenresInputClick = () => {
    setShowGenresDropdown(!showGenresDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      typesInputRef.current &&
      !typesInputRef.current.contains(event.target)
    ) {
      setShowTypesDropdown(false);
    }
    if (
      genresInputRef.current &&
      !genresInputRef.current.contains(event.target)
    ) {
      setShowGenresDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="landing-profiles-container">
      {/* <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={handleSubmit}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Create Venue Profile</h5>

                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="venueName"
                    placeholder="Venue Name"
                    name="venue_name"
                    value={formData.venue_name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="venueName">Venue Name</label>
                </div>

                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="businessName"
                    placeholder="business Name"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="businessName">Business Name</label>
                </div>

                <div className="form-floating mb-2">
                  <input
                    type="number"
                    className="form-control"
                    id="abnNumber"
                    placeholder="abn number"
                    name="abn_number"
                    value={formData.abn_number}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="abnNumber">abn number</label>
                </div>

                <div className="mb-3">
                  <label htmlFor="venue_types" className="form-label">
                    Venue Types
                  </label>
                  <div className="dropdown-scrollable" ref={typesInputRef}>
                    <input
                      type="text"
                      className="form-control"
                      id="venue_types"
                      name="venue_types"
                      onFocus={handleTypesInputClick}
                      placeholder="Select venue Types"
                      readOnly
                    />
                    {showTypesDropdown && (
                      <div className="dropdown-content">
                        {types.length > 0 ? (
                          types.map((type) => (
                            <div className="form-check" key={type}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`type-${type}`}
                                name="venue_types"
                                value={type}
                                checked={selectedTypes.includes(type)}
                                onChange={handleTypeChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`type-${type}`}
                              >
                                {type}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading venue types...</p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* {types && types.length > 0 ? (
                    types.map((type) => (
                      <div key={type} className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`type-${type}`}
                          name="venue_types"
                          value={type}
                          checked={selectedTypes.includes(type)}
                          onChange={handleTypeChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`type-${type}`}
                        >
                          {type}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>Loading venue types...</p>
                  )} */}
                </div>

                <div className="mb-3">
                  <label htmlFor="venue_genres" className="form-label">
                    Genres (max 7)
                  </label>

                  <div className="dropdown-scrollable" ref={genresInputRef}>
                    <input
                      type="text"
                      className="form-control"
                      id="venue_genres"
                      name="venue_genres"
                      onFocus={handleGenresInputClick}
                      placeholder="Select Genres"
                      readOnly
                    />
                    {showGenresDropdown && (
                      <div className="dropdown-content">
                        {genres.length > 0 ? (
                          genres.map((genre) => (
                            <div className="form-check" key={genre}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`genre-${genre}`}
                                name="venue_genres"
                                value={genre}
                                checked={selectedGenres.includes(genre)}
                                onChange={handleGenreChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`genre-${genre}`}
                              >
                                {genre}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading genres...</p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* {genres && genres.length > 0 ? (
                    genres.map((genre) => (
                      <div key={genre} className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`genre-${genre}`}
                          name="venue_genres"
                          value={genre}
                          checked={selectedGenres.includes(genre)}
                          onChange={handleGenreChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`genre-${genre}`}
                        >
                          {genre}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>Loading genres...</p>
                  )} */}
                </div>

                {selectedGenres.length > 7 && (
                  <span className="text-danger">
                    You can only select a maximum of 7 genres.
                  </span>
                )}

                <div className="mb-3">
                  <AutoComplete onSuburbSelect={handleSuburbSelect} />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-3"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Create Profile"
                  )}
                </button>

                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateVenueProfile;
