import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
// import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import AutoComplete from "../../components/autoComplete";
import "../OtherPages/LandingProfiles.css";

function UpdatePerformerContact() {
  const profileId = Cookies.get("profileOwnerId");
  const [contact, setContact] = useState({
    phone_number: "",
    email: "",
    location: {
      suburb: "",
      zipcode: "",
      latitude: 0,
      longitude: 0,
      location_name: "",
      address: "",
      google_map_pin: "",
    },
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchPerformerContact() {
  //     try {
  //       const response = await axiosInstance.get("/v1/performer/profile", {
  //         headers: {
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       });

  //       if (response.status === 200) {
  //         const { phone_number, email, location } = response.data;
  //         setContact({ phone_number, email, location });
  //       } else {
  //         setErrorMessage("Error fetching performer profile data.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching performer profile:", error);
  //       setErrorMessage("An unexpected error occurred. Please try again.");
  //     }
  //   }

  //   fetchPerformerContact();
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setContact((prevData) => ({
      ...prevData,
      location: { ...prevData.location, [name]: value },
    }));
  };

  const handleSuburbSelect = (suburbData) => {
    setContact((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/contact",
        {
          performer_id: Cookies.get("profileOwnerId"),
          phone_number: contact.phone_number,
          email: contact.email,
          location: contact.location,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("contact updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "contact updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating contact.");
        console.error("Error updating contact:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating contact:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={handleSubmit}>
                <Link to="/">
                  {/* <img src={logo} className="mb-4 d-block mx-auto" alt="" /> */}
                </Link>
                <h5 className="mb-3">Update Contact</h5>

                <div className="form-floating mb-2">
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phone_number"
                    value={contact.phone_number}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="phoneNumber">Phone Number</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    value={contact.email}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>

                <div className="mb-3">
                  <AutoComplete onSuburbSelect={handleSuburbSelect} />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Update Contact"
                  )}
                </button>

                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success mt-3" role="alert">
                    {successMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePerformerContact;
