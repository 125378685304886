//current correct user account page

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbarSelf";
import Footer from "../../components/footer";
// import bg3 from "../../assect/images/bg/03.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import VerificationModal from "./VerificationModal";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaEdit, FaKey } from "react-icons/fa";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { FiLogOut } from "react-icons/fi";
import {
  faKey,
  faEnvelope,
  faCheckCircle,
  faCircleXmark,
  faPhone,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import NavigationBar from "../../components/navbar/loggedUserNavbarAccount";
import "../OtherPages/LandingProfiles.css";
import Spinner from "react-bootstrap/Spinner";

export default function UserAccountPage() {
  const [accountDetails, setAccountDetails] = useState(null);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [isEditingfname, setIsEditingfname] = useState(false);
  const [isEditinglname, setIsEditinglname] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [isEditingBusinessName, setIsEditingBusinessName] = useState(false);
  const [isEditingBusinessLocation, setIsEditingBusinessLocation] =
    useState(false);
  const [isEditingAbn, setIsEditingAbn] = useState(false);
  const [isEditingAcn, setIsEditingAcn] = useState(false);
  const [newfname, setNewfname] = useState("");
  const [newlname, setNewlname] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newBusinessName, setNewBusinessName] = useState("");
  const [newBusinessLocation, setNewBusinessLocation] = useState("");
  const [newAbn, setNewAbn] = useState("");
  const [newAcn, setNewAcn] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [verificationType, setVerificationType] = useState("");
  const navigate = useNavigate();
  const profileId = Cookies.get("profileOwnerId");
  const [loading, setLoading] = useState(false);
  const [phoneLoading, setphoneLoading] = useState(false);
  const [fnameLoading, setFnameLoading] = useState(false);
  const [lnameLoading, setLnameLoading] = useState(false);
  const [abnLoading, setAbnLoading] = useState(false);
  const [acnLoading, setAcnLoading] = useState(false);
  const [bnameLoading, setBnameLoading] = useState(false);
  const [blocationLoading, setBlocationLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // // Regex to validate Australian mobile numbers
  // const validatePhoneNumber = (number) => {
  //   const australianMobileRegex = /^04\d{8}$/;
  //   return australianMobileRegex.test(number);
  // };

  //   // Regex to validate Australian mobile numbers
  // const validatePhoneNumber = (number) => {
  //   // Regex to match either:
  //   // 1. Local format: 04 followed by 8 digits (e.g., 0412345678)
  //   // 2. International format: +614 followed by 8 digits (e.g., +61412345678)
  //   const australianMobileRegex = /^(?:\+?61|0)4\d{8}$/;
  //   return australianMobileRegex.test(number);
  // };

  const validatePhoneNumber = (number) => {
    // Normalize the input by removing spaces
    const normalizedNumber = number.replace(/\s+/g, "");

    // Regex to match:
    // 1. Local format: 04 followed by 8 digits (e.g., 0412345678)
    // 2. International format: +614 followed by 8 digits (e.g., +61412345678)
    const australianMobileRegex = /^(?:\+61|0)4\d{8}$/;
    return australianMobileRegex.test(normalizedNumber);
  };

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const response = await axiosInstance.get("/v1/auth/account/details", {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });
        setAccountDetails(response.data.data.account_details);
        console.log("user acc details", response.data.data.account_details);
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleEditEmail = () => {
    setIsEditingEmail(true);
    setNewEmail(accountDetails.email);
  };

  const handleSaveEmail = async () => {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/email",
        {
          email: newEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The email has been updated successfully. Please verify your new email address.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          email: newEmail,
          is_verified_email: false,
        }));
        setIsEditingEmail(false);
        setVerificationType("email");
        setShowModal(true);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the email. Please try again.",
        "error"
      );
      console.error("Error updating email:", error);
    }
  };

  const handleEditPhoneNumber = () => {
    setIsEditingPhoneNumber(true);
    setNewPhoneNumber(accountDetails.phone_number);
  };

  const handleSavePhoneNumber = async (e) => {
    e.preventDefault();
    setphoneLoading(true);

    // if (!validatePhoneNumber(newPhoneNumber)) {
    //   setLoading(false);
    //   return Swal.fire({
    //     title: "Invalid Number",
    //     text: "You can only add Australian mobile numbers.",
    //     icon: "error",
    //     confirmButtonText: "OK"
    //   });
    // }
    // Normalize the phone number by removing spaces
    const normalizedPhoneNumber = newPhoneNumber.replace(/\s+/g, "");

    // Check if the normalized phone number is valid
    if (!validatePhoneNumber(normalizedPhoneNumber)) {
      setphoneLoading(false);
      return Swal.fire({
        title: "Invalid Number",
        text: "Only Australian mobile numbers are allowed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/phone_number",
        {
          phone_number: newPhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "Your phone number has been updated successfully! Please verify your new phone number.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          phone_number: newPhoneNumber,
          is_verified_phone: false,
        }));
        setIsEditingPhoneNumber(false);
        setVerificationType("phone");
        setShowModal(true);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "Failed to update the phone number. Please try again.",
        "error"
      );
      console.error("Error updating phone number:", error);
    } finally {
      setphoneLoading(false);
    }
  };

  const handleEditfname = () => {
    setIsEditingfname(true);
    setNewfname(accountDetails.first_name);
  };

  const handleSaveFirstName = async (e) => {
    e.preventDefault();
    setFnameLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/first-name",
        {
          first_name: newfname,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The first name has been updated successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          first_name: newfname,
        }));
        setIsEditingfname(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the first name. Please try again.",
        "error"
      );
      console.error("Error updating first name:", error);
    } finally {
      setFnameLoading(false);
    }
  };

  const handleEditlname = () => {
    setIsEditinglname(true);
    setNewlname(accountDetails.last_name);
  };

  const handleSaveLastName = async (e) => {
    e.preventDefault();
    setLnameLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/last-name",
        {
          last_name: newlname,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The last name has been updated successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          last_name: newlname,
        }));
        setIsEditinglname(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the last name. Please try again.",
        "error"
      );
      console.error("Error updating last name:", error);
    } finally {
      setLnameLoading(false);
    }
  };

  const handleEditAbn = () => {
    setIsEditingAbn(true);
    setNewAbn(accountDetails.abn_number);
  };

  const validateABN = (abn_number) => {
    // Regex to match either:
    // 1. Local format: 04 followed by 8 digits (e.g., 0412345678)
    // 2. International format: +614 followed by 8 digits (e.g., +61412345678)
    const abnRegex = /^\d{11}$/;
    return abnRegex.test(abn_number);
  };

  const handleSaveAbnNumber = async (e) => {
    e.preventDefault();
    setAbnLoading(true);

    if (!validateABN(newAbn)) {
      setAbnLoading(false);
      return Swal.fire({
        title: "Invalid ABN",
        text: "Please enter a valid 11-digit Australian Business Number (ABN).",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/abn-number",
        {
          abn_number: newAbn,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The Australian Business Number (ABN) has been updated successfully. ",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          abn_number: newAbn,
        }));
        setIsEditingAbn(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the Australian Business Number (ABN). Please try again.",
        "error"
      );
      console.error("Error updating abn number:", error);
    } finally {
      setAbnLoading(false);
    }
  };

  const handleEditAcn = () => {
    setIsEditingAcn(true);
    setNewAcn(accountDetails.acn_number);
  };

  const validateACN = (acn_number) => {
    // Regex to match either:
    // 1. Local format: 04 followed by 8 digits (e.g., 0412345678)
    // 2. International format: +614 followed by 8 digits (e.g., +61412345678)
    const acnRegex = /^\d{9}$/;
    return acnRegex.test(acn_number);
  };

  const handleSaveAcnNumber = async (e) => {
    e.preventDefault();
    setAcnLoading(true);

    if (!validateACN(newAcn)) {
      setAcnLoading(false);
      return Swal.fire({
        title: "Invalid ACN",
        text: "Please enter a valid 9-digit Australian Company Number (ACN).",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/acn-number",
        {
          acn_number: newAcn,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The Australian Company Number (ACN) has been updated successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          acn_number: newAcn,
        }));
        setIsEditingAcn(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the Australian Company Number (ACN). Please try again.",
        "error"
      );
      console.error("Error updating acn number:", error);
    } finally {
      setAcnLoading(false);
    }
  };

  const handleEditBusinessName = () => {
    setIsEditingBusinessName(true);
    setNewBusinessName(accountDetails.business_name);
  };

  const handleSaveBusinessName = async (e) => {
    e.preventDefault();
    setBnameLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/business-name",
        {
          business_name: newBusinessName,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The business name has been updated successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          business_name: newBusinessName,
        }));
        setIsEditingBusinessName(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the business name. Please try again.",
        "error"
      );
      console.error("Error updating business name:", error);
    } finally {
      setBnameLoading(false);
    }
  };

  const handleEditBusinessLocation = () => {
    setIsEditingBusinessLocation(true);
    setNewBusinessLocation(accountDetails.business_location);
  };

  const handleSaveBusinessLocation = async (e) => {
    e.preventDefault();
    setBlocationLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/auth/update/business-location",
        {
          business_location: newBusinessLocation,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Success",
          "The business location has been updated successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          business_location: newBusinessLocation,
        }));
        setIsEditingBusinessLocation(false);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while updating the business location. Please try again.",
        "error"
      );
      console.error("Error updating business location:", error);
    } finally {
      setBlocationLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleVerify = async (code) => {
    try {
      let response;

      if (verificationType === "email") {
        response = await axiosInstance.post(
          "/v1/auth/verify/email",
          {
            verification_code: code,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
      } else if (verificationType === "phone") {
        response = await axiosInstance.post(
          "/v1/auth/verify/phone_number/at/update",
          {
            verification_code: code,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
      }

      if (response && response.status === 200) {
        Swal.fire(
          "Success",
          "Verification has been completed successfully.",
          "success"
        );
        setAccountDetails((prevDetails) => ({
          ...prevDetails,
          is_verified_email:
            verificationType === "email" ? true : prevDetails.is_verified_email,
          is_verified_phone:
            verificationType === "phone" ? true : prevDetails.is_verified_phone,
        }));
      }
    } catch (error) {
      Swal.fire("Error", "Verification has failed. Please try again.", "error");
      console.error("Error verifying:", error);
    }

    setShowModal(false);
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      let response;

      if (verificationType === "email") {
        response = await axiosInstance.get(
          "/v1/auth/resend/verification/email",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
      } else if (verificationType === "phone") {
        response = await axiosInstance.get(
          "/v1/auth/resend/verification/phone_number",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
      }

      if (response && response.status === 200) {
        Swal.fire(
          "Success",
          "The verification code has been resent successfully.",
          "success"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Unable to resend verification code.", "error");
      console.error("Error resending verification code:", error);
    }
  };

  const navigateToChangePassword = () => {
    navigate("/auth-change-password");
  };

  if (!accountDetails) {
    return <div></div>;
  }

  const navigateToSupportTicketPage = () => {
    navigate("/support-ticket-page");
  };

  const navigateToPaymentHistorytPage = () => {
    navigate("/billing-history");
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      // Ensure access token exists
      const accessToken = Cookies.get("accessToken");
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      // Revoke access token (and optionally refresh token)
      const response = await axiosInstance.get("/v1/auth/sign-out", {
        headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
      });

      console.log("API Response:", response?.data.data);

      Cookies.remove("accessToken");
      Cookies.remove("refreshToken"); // If storing refresh token in cookies
      Cookies.remove("performerId");
      Cookies.remove("profileId");
      Cookies.remove("profileOwnerId");
      Cookies.remove("userId");

      navigate("/login");

      // Optionally display a logout success message
      // Swal.fire({
      //   title: 'Logged Out!',
      //   text: 'You have been successfully logged out!',
      //   icon: 'success',
      // });
    } catch (error) {
      console.error("API Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to log out. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const cardStyle = {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: isHovered
      ? "0 8px 20px rgba(0, 0, 0, 0.2)"
      : "0 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    transform: isHovered ? "translateY(-3px)" : "translateY(0)",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const inputStyle = {
    flexGrow: 1,
    marginRight: "0.5rem",
  };

  const iconStyle = {
    color: "#418abb",
    cursor: "pointer",
  };

  const renderTooltip = (props) => <Tooltip {...props}>Click to edit</Tooltip>;

  return (
    <>
      <NavigationBar />
      <section className="landing-profiles-container2">
        {/* <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  User Account
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div
                  className="card shadow border-0 rounded-3"
                  style={cardStyle}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="card-body">
                    <center>
                      <h4 className="card-title mb-4 align-items-center">
                        <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                        User Information
                      </h4>
                    </center>
                    <br></br>
                    <div className="mb-4 text-center">
                      <Button
                        variant="outline-primary"
                        onClick={navigateToChangePassword}
                        className="me-2 mb-2 custom-btn"
                      >
                        <FontAwesomeIcon icon={faKey} className="me-2" />
                        Change Password
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={navigateToSupportTicketPage}
                        className="me-2 mb-2 custom-btn"
                      >
                        <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                        Support Ticket
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="me-2 mb-2 custom-btn"
                        onClick={navigateToPaymentHistorytPage}
                        // type="button"
                      >
                        Payment History
                      </Button>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          First Name:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={
                              isEditingfname
                                ? newfname
                                : accountDetails.first_name
                            }
                            onChange={(e) => setNewfname(e.target.value)}
                            disabled={!isEditingfname}
                            style={inputStyle}
                          />
                          {!isEditingfname ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditfname}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveFirstName}
                              disabled={fnameLoading}
                              style={{
                                cursor: fnameLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: fnameLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {fnameLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}

                          {/* <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </OverlayTrigger> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Last Name:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={
                              isEditinglname
                                ? newlname
                                : accountDetails.last_name
                            }
                            onChange={(e) => setNewlname(e.target.value)}
                            disabled={!isEditinglname}
                            style={inputStyle}
                          />
                          {!isEditinglname ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditlname}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveLastName}
                              disabled={lnameLoading}
                              style={{
                                cursor: lnameLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: lnameLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {lnameLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </OverlayTrigger> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={
                              isEditingEmail ? newEmail : accountDetails.email
                            }
                            onChange={(e) => setNewEmail(e.target.value)}
                            disabled={!isEditingEmail}
                            style={inputStyle}
                          />
                          <span className="input-group-text">
                            <FontAwesomeIcon
                              icon={
                                accountDetails.is_verified_email
                                  ? faCheckCircle
                                  : faCircleXmark
                              }
                              style={{
                                color: accountDetails.is_verified_email
                                  ? "green"
                                  : "red",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                !accountDetails.is_verified_email &&
                                setShowModal(true)
                              }
                            />
                          </span>
                          {/* {!isEditingEmail ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditEmail}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveEmail}
                            >
                              Save
                            </button>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phoneNumber" className="form-label">
                          Phone Number:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            value={
                              isEditingPhoneNumber
                                ? newPhoneNumber
                                : accountDetails.phone_number
                            }
                            onChange={(e) => setNewPhoneNumber(e.target.value)}
                            disabled={!isEditingPhoneNumber}
                            style={inputStyle}
                          />
                          <span className="input-group-text" style={{marginRight: "10px"}}>
                            <FontAwesomeIcon
                              icon={
                                accountDetails.is_verified_phone
                                  ? faCheckCircle
                                  : faCircleXmark
                              }
                              style={{
                                color: accountDetails.is_verified_phone
                                  ? "green"
                                  : "red",
                                cursor: "pointer",                                
                              }}
                              onClick={() =>
                                !accountDetails.is_verified_phone &&
                                setShowModal(true)
                              }
                            />
                          </span>
                          {!isEditingPhoneNumber ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditPhoneNumber}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSavePhoneNumber}
                              disabled={phoneLoading}
                              style={{
                                cursor: phoneLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: phoneLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {phoneLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="business_name" className="form-label">
                          Business Name:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            className="form-control"
                            id="business_name"
                            value={
                              isEditingBusinessName
                                ? newBusinessName
                                : accountDetails.business_name
                            }
                            onChange={(e) => setNewBusinessName(e.target.value)}
                            disabled={!isEditingBusinessName}
                            style={inputStyle}
                          />
                          {!isEditingBusinessName ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditBusinessName}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveBusinessName}
                              disabled={bnameLoading}
                              style={{
                                cursor: bnameLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: bnameLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {bnameLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="business_location"
                          className="form-label"
                        >
                          Business Location:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            className="form-control"
                            id="business_location"
                            value={
                              isEditingBusinessLocation
                                ? newBusinessLocation
                                : accountDetails.business_location
                            }
                            onChange={(e) =>
                              setNewBusinessLocation(e.target.value)
                            }
                            disabled={!isEditingBusinessLocation}
                            style={inputStyle}
                          />
                          {!isEditingBusinessLocation ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditBusinessLocation}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveBusinessLocation}
                              disabled={blocationLoading}
                              style={{
                                cursor: blocationLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: blocationLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {blocationLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="abn_number" className="form-label">
                          ABN Number:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            className="form-control"
                            id="abn_number"
                            value={
                              isEditingAbn ? newAbn : accountDetails.abn_number
                            }
                            onChange={(e) => setNewAbn(e.target.value)}
                            disabled={!isEditingAbn}
                            style={inputStyle}
                          />
                          {!isEditingAbn ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditAbn}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveAbnNumber}
                              disabled={abnLoading}
                              style={{
                                cursor: abnLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: abnLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {abnLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </OverlayTrigger> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="acn_number" className="form-label">
                          ACN Number:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            className="form-control"
                            id="acn_number"
                            value={
                              isEditingAcn ? newAcn : accountDetails.acn_number
                            }
                            onChange={(e) => setNewAcn(e.target.value)}
                            disabled={!isEditingAcn}
                            style={inputStyle}
                          />
                          {!isEditingAcn ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={handleEditAcn}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={handleSaveAcnNumber}
                              disabled={acnLoading}
                              style={{
                                cursor: acnLoading ? "not-allowed" : "pointer", // Disable cursor when loading
                                opacity: acnLoading ? 0.6 : 1, // Reduce opacity when loading
                                transition: "opacity 0.3s ease", // Smooth transition for opacity change
                                minWidth: "100px",
                              }}
                            >
                              {acnLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </OverlayTrigger> */}
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <label htmlFor="status" className="form-label">
                          Status:
                        </label>
                        <div style={inputGroupStyle}>
                          <input
                            type="text"
                            className="form-control"
                            id="status"
                            value={accountDetails.status}
                            disabled
                            style={inputStyle}
                          />
                         
                        </div>
                      </div> */}
                    </div>
                    <center>
                      <Button
                        variant="outline-primary"
                        onClick={handleLogout}
                        className="me-2 mb-2 custom-btn"
                      >
                        <FiLogOut className="icons" />
                        Logout
                      </Button>
                      {/* <Button
                        className="btn btn-primary mt-4"
                        style={{ maxWidth: "20%" }}
                        onClick={handleLogout}
                      >
                        Logout <FiLogOut className="icons" />
                      </Button> */}
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <Footer /> */}
      {showModal && (
        <VerificationModal
          show={showModal}
          onClose={handleCloseModal}
          onVerify={handleVerify}
          onResend={handleResend}
          verificationType="phoneNumber"
        />
      )}
      <Footer />
    </>
  );
}
