import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import "../OtherPages/LandingProfiles.css";

function UpdateVenueAbn() {
  const profileId = Cookies.get("profileId");
  const [abnNumber, setabnNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     async function fetchabnNumber() {
  //       try {
  //         const response = await axiosInstance.get("/v1/venue/profile", {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         });

  //         if (response.status === 200) {
  //           setabnNumber(response.data.venue_name);
  //         } else {
  //           setErrorMessage("Error fetching venue profile data.");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching venue profile:", error);
  //         setErrorMessage("An unexpected error occurred. Please try again.");
  //       }
  //     }

  //     fetchabnNumber();
  //   }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/abn-number",
        {
          venue_id: profileId,
          abn_number: abnNumber,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("abn number updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "abn number updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/venue/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating abn number.");
        console.error("Error updating abn number:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating abn number:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
        <div
          className="bg-overlay image-wrap"
          style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
        ></div>
        <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update abn number</h5>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="abnNumber"
                    placeholder="abn number"
                    name="abnNumber"
                    value={abnNumber}
                    onChange={(e) => setabnNumber(e.target.value)}
                    required
                  />
                  <label htmlFor="abnNumber">abn number</label>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdateVenueAbn;
