import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import AutoComplete from "../../components/autoComplete";
import { MultiSelect } from "react-multi-select-component";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faEnvelope,
    faLock,
    faPhone,
    faMusic,
    faTheaterMasks,
    faGuitar,
    faStar,
    faFilm,
    faMusicNote,
    faMicrophone,
    faHatCowboy,
    faHeadphones,
    faMapMarkerAlt,

} from "@fortawesome/free-solid-svg-icons";


import axios from "axios";
import axiosInstance from "../../pages/auth/axiosInstance";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  padding-top: 100px;

  @media (max-width: 768px) {
    padding-top: 100px; // Adjust margin as needed for spacing
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
        props.isMobile
            ? "20px"
            : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 5px;

  
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;

  /* Media query for mobile view */
  @media (max-width: 768px) {    
    margin-top: 30px;
  }
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  color: #666;

  /* Media query for mobile view */
  @media (max-width: 768px) {    
    text-align: center;
  }
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SignInLink = styled.p`
  margin-top: 15px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function CreatePerformerProfile() {
    const [formData, setFormData] = useState({
      first_name: "",
      last_name: "",
      performer_types: [],
      performer_genres: [],
      location: {
        suburb: "",
        zipcode: "",
        latitude: 0,
        longitude: 0,
        location_name: "",
        address: "",
        google_map_pin: "",
      },
    });
    const [genres, setGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [performerTypes, setPerformerTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTypesDropdown, setShowTypesDropdown] = useState(false);
    const [showGenresDropdown, setShowGenresDropdown] = useState(false);
    const typesInputRef = useRef(null);
    const genresInputRef = useRef(null);
    const navigate = useNavigate();

    


    useEffect(() => {
        async function fetchData() {
          try {
            console.log("Fetching data...");
            const [profileTypesResponse, genreTypesResponse] = await Promise.all([
              axiosInstance.get("/v1/performer/performer-genres", {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }),
              axiosInstance.get("/v1/performer/performer-types", {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }),
            ]);
    
            console.log("Data received:", profileTypesResponse, genreTypesResponse);
    
            if (
              profileTypesResponse.status === 200 &&
              genreTypesResponse.status === 200
            ) {
              console.log(
                "Performer types response data:",
                profileTypesResponse.data
              );
              const performerGenres = profileTypesResponse.data.performer_genres;
              console.log("Performer genres:", performerGenres);
              setGenres(performerGenres);
    
              console.log(
                "Performer types response data:",
                genreTypesResponse.data
              );
              const performerTypes = genreTypesResponse.data.performer_types;
              console.log("Performer types:", performerTypes);
              setPerformerTypes(performerTypes);
            } else {
              setErrorMessage("Error fetching data.");
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        }
    
        fetchData();
      }, []);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          location: { ...prevData.location, [name]: value },
        }));
      };
    
      const handleGenreChange = (event) => {
        const checked = event.target.checked;
        const value = event.target.value;
    
        if (checked) {
          if (selectedGenres.length < 5) {
            setSelectedGenres([...selectedGenres, value]);
          } else {
            setErrorMessage("You can only select a maximum of 5 genres.");
          }
        } else {
          setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
        }
      };
    
      // const handleTypeChange = (event) => {
      //   const checked = event.target.checked;
      //   const value = event.target.value;
    
      //   if (checked) {
      //     if (!selectedTypes.includes(value)) {
      //       setSelectedTypes([...selectedTypes, value]);
      //     }
      //   } else {
      //     setSelectedTypes(selectedTypes.filter((type) => type !== value));
      //   }
      // };
    
      const handleTypeChange = (event) => {
        const checked = event.target.checked;
        const value = event.target.value;
    
        if (checked) {
          if (selectedTypes.length < 3) {
            setSelectedTypes([...selectedTypes, value]);
          } else {
            setErrorMessage("You can only select a maximum of 3 performer types.");
          }
        } else {
          setSelectedTypes(selectedTypes.filter((type) => type !== value));
        }
      };
    
      const handleSuburbSelect = (suburbData) => {
        setFormData((prevData) => ({
          ...prevData,
          location: {
            suburb: suburbData.suburb,
            zipcode: suburbData.zipcode,
            latitude: suburbData.latitude,
            longitude: suburbData.longitude,
            location_name: suburbData.location_name,
            address: suburbData.address,
            google_map_pin: suburbData.google_map_pin,
          },
        }));
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        const payload = {
          type: "PERFORMER",
          first_name: formData.first_name.trim(),
          last_name: formData.last_name.trim(),
          performer_types: selectedTypes,
          performer_genres: selectedGenres,
          location: formData.location,
        };
    
        try {
          const response = await axiosInstance.post(
            "/v1/performer/create-profile",
            payload,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );
    
          setLoading(false);
    
          if (response.status === 200) {
            const profileId = response.data.profile_id;
            Cookies.set("profileId", profileId, { secure: true });
    
            const profileResponse = await axiosInstance.get(
              `/v1/performer/view-profile/${profileId}/section/complete`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }
            );
    
            if (profileResponse.status === 200) {
              const profileStatus = profileResponse.data.performer_info.status;
    
              if (
                profileStatus === "FRESH" &&
                profileId.startsWith("PROFILE-PERFORMER")
              ) {
                navigate("/performer-pricing");
              } else if (
                profileStatus === "FRESH" &&
                profileId.startsWith("PROFILE-VENUE")
              ) {
                navigate("/venue-pricing");
              } else {
                Swal.fire({
                  title: "Success!",
                  text: "Your profile was created successfully.",
                  icon: "success",
                  className: "swal-modal",
                }).then(() => {
                  navigate(`/performer/${profileId}`);
                });
              }
            } else {
              setErrorMessage("Error fetching profile status.");
              Swal.fire({
                title: "Error!",
                text: errorMessage,
                icon: "error",
                className: "swal-modal",
              });
            }
          } else {
            setErrorMessage(response.data.error);
            Swal.fire({
              title: "Error!",
              text: errorMessage,
              icon: "error",
              className: "swal-modal",
            });
          }
        } catch (error) {
          setLoading(false);
          setErrorMessage("An unexpected error occurred. Please try again.");
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        }
      };
    
      const handleTypesInputClick = () => {
        setShowTypesDropdown(!showTypesDropdown);
      };
    
      const handleGenresInputClick = () => {
        setShowGenresDropdown(!showGenresDropdown);
      };
    
      const handleClickOutside = (event) => {
        if (
          typesInputRef.current &&
          !typesInputRef.current.contains(event.target)
        ) {
          setShowTypesDropdown(false);
        }
        if (
          genresInputRef.current &&
          !genresInputRef.current.contains(event.target)
        ) {
          setShowGenresDropdown(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    return (
        <Container>
            <Title>Create Performer Profile</Title>


            <FormWrapper onSubmit={handleSubmit}>
                <InputGroup>
                    <Label htmlFor="floatingInput">First Name</Label>
                    <InputWrapper>
                        <Icon icon={faUser} />
                        <Input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="First Name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                        />

                    </InputWrapper>
                </InputGroup>

                <InputGroup>
                    <Label htmlFor="lastName">Last Name</Label>
                    <InputWrapper>
                        <Icon icon={faUser} />
                        <Input
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                        />
                    </InputWrapper>
                </InputGroup>

                <InputGroup>
                    <Label htmlFor="performer_types">Performer Types</Label>
                    <InputWrapper ref={typesInputRef}>
                        <Icon icon={faHeadphones} />
                        <Input
                            type="text"
                            id="performer_types"
                            name="performer_types"
                            placeholder="Select Performer Types"
                            value={selectedTypes.join(", ")} // Display selected types
                            onFocus={handleTypesInputClick}
                            readOnly
                        />
                    </InputWrapper>
                    {showTypesDropdown && (
                        <div className="dropdown-content" ref={typesInputRef}>
                            {performerTypes.length > 0 ? (
                                performerTypes.map((type) => (
                                    <div className="form-check" key={type}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`type-${type}`}
                                            name="performer_types"
                                            value={type}
                                            checked={selectedTypes.includes(type)}
                                            onChange={handleTypeChange}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`type-${type}`}
                                        >
                                            {type}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <p>Loading performer types...</p>
                            )}
                        </div>
                    )}
                </InputGroup>

                <InputGroup>
                    <Label htmlFor="performer_genres">Genres (max 5)</Label>
                    <InputWrapper>
                        <Icon icon={faGuitar} />
                        <Input
                            type="text"
                            className="form-control"
                            id="performer_genres"
                            name="performer_genres"
                            placeholder="Select Genres"
                            value={selectedGenres.join(", ")} // Display selected genres
                            onFocus={handleGenresInputClick}
                            readOnly
                        />
                    </InputWrapper>
                    {showGenresDropdown && (
                        <div className="dropdown-content" ref={genresInputRef}>
                            {genres.length > 0 ? (
                                genres.map((genre) => (
                                    <div className="form-check" key={genre}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`genre-${genre}`}
                                            name="performer_genres"
                                            value={genre}
                                            checked={selectedGenres.includes(genre)}
                                            onChange={handleGenreChange}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`genre-${genre}`}
                                        >
                                            {genre}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <p>Loading genres...</p>
                            )}
                        </div>
                    )}
                    {selectedGenres.length > 5 && (
                        <span className="text-danger">
                            You can only select a maximum of 5 genres.
                        </span>
                    )}
                </InputGroup>

                <InputGroup>
                    <Label htmlFor="location_suburb">Location</Label>
                    <InputWrapper>
                        <Icon icon={faMapMarkerAlt} />
                        <AutoComplete onSuburbSelect={handleSuburbSelect} />
                    </InputWrapper>                    
                </InputGroup>



                <Button type="submit" disabled={loading}>
                {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Create Profile"
                  )}
                </Button>
                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
            </FormWrapper>


        </Container>
    );
}
export default CreatePerformerProfile;