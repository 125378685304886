// LeftNavbar.js

import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import { Container, Row, Col } from "react-bootstrap";
import "./Navbar.css"; // Add your custom CSS for navbar styling

const LeftNavbar = ({ isOpen, toggleNavbar }) => {
  const location = useLocation();
  const activePage = location.pathname;

  return (
    <Container fluid className={`left-navbar ${isOpen ? "open" : ""}`}>
      <Row>
        <Col xs={12}>
          {/* Navbar options */}
          <br></br>
          <br></br>
          <br></br>
          <ul>
            <li className={activePage === "/performer-search" ? "active" : ""}>
              <Link to="/performer-search">Performer</Link>
            </li>
            <li className={activePage === "/venue-search" ? "active" : ""}>
              <Link to="/venue-search">Venue</Link>
            </li>
            <li
              className={activePage === "/opportunity-search" ? "active" : ""}
            >
              <Link to="/opportunity-search">Opportunity</Link>
            </li>
            <li className={activePage === "/callout-search" ? "active" : ""}>
              <Link to="/callout-search">Call Outs</Link>
            </li>
            <li className={activePage === "/classified-search" ? "active" : ""}>
              <Link to="/classified-search">Classifieds</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default LeftNavbar;
