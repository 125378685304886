import React, { useState, useEffect } from "react";
import './pricing.css';
import { FaCheckCircle } from 'react-icons/fa';
import axios from "axios";
import Cookies from "js-cookie";
import axiosInstance from "../pages/auth/axiosInstance";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";

// Import AOS and its styles
import AOS from "aos";
import "aos/dist/aos.css";

const Pricing = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);
  const accessToken = Cookies.get("accessToken");
  const [performerPricing, setPerformerPricing] = useState([]);
  const [venuePricing, setVenuePricing] = useState([]);
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
  }, []);

  const handleToggle = (planType) => {
    setIsAnnual(planType === 'annual');
  };

  useEffect(() => {
    const fetchPerformerPricing = async () => {
      try {
        console.log("Fetching performer pricing...");
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Performer Profile" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Performer Pricing API Response:", response.data);
        setPerformerPricing(response.data);
      } catch (error) {
        console.error("Error fetching performer pricing:", error);
      }
    };

    const fetchVenuePricing = async () => {
      try {
        console.log("Fetching venue pricing...");
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Venue Profile" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Venue Pricing API Response:", response.data);
        setVenuePricing(response.data);
      } catch (error) {
        console.error("Error fetching venue pricing:", error);
      }
    };

    fetchPerformerPricing();
    fetchVenuePricing();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoaded(true), 1000);
    return () => clearTimeout(timeout);
  }, []);



  const handleHover = (e) => {
    e.target.classList.toggle("hovered");
  };

  const handleLogin = () => {
    navigate("/signup");
  };

  return (
    <div className="pricing-container">
      <h1 data-aos="zoom-in"> Blow your own trumpet! Stand out in the crowd!</h1>
      {/* <p>No contracts. No surprise fees.</p> */}
      {/* <div className="toggle-buttons">
                <div className={`toggle-button ${isAnnual ? 'annual-active' : 'monthly-active'}`}>
                    <span className={`toggle-option ${isAnnual ? 'active' : ''}`} onClick={() => handleToggle('annual')}>
                        Annual
                    </span>
                    <span className={`toggle-option ${!isAnnual ? 'active' : ''}`} onClick={() => handleToggle('monthly')}>
                        Monthly
                    </span>
                </div>
            </div> */}
      <div className="plans">
        {performerPricing.length > 0 ? (
          performerPricing.map((item, index) => {
            const monthlyPrice = (item.unit_amount / 100).toFixed(2);
            // const annualPrice = (item.unit_amount / 100 * 12).toFixed(2);
            return (
              <div isMobile={isMobile} key={index} className="plan" data-aos="zoom-in">
                <h2>{item.name}</h2>
                <div className="price" style={{ marginBottom: "10px" }}>

                  <>
                    <strong style={{ fontSize: '1.5em' }}>${monthlyPrice.split('.')[0]}</strong>
                    <span style={{ fontSize: '1em' }}>.{monthlyPrice.split('.')[1]}/month(incl.GST)</span>
                  </>

                </div>
                <p><strong>Special Offer:</strong> {item.description || "1 month free to get set up!"}<br /><br />
                  {"GigBridge is perfect for both new and established performers seeking fresh opportunities. It helps you expand your reach and build your career."}</p>

                <hr />
                <ul>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>Your Digital Stage:</strong> Share your bio, media, and talent to attract venues.
                    </div>
                  </li>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>No Middlemen, No Hassles:</strong> Communicate with venues without middlemen.
                    </div>
                  </li>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>Explore Opportunities:</strong> Apply for gigs that fit your style and follow venues for updates.
                    </div>
                  </li>
                </ul>

                <br />
                <button onClick={handleLogin}>Get started</button>
              </div>
            );
          })
        ) : (
          <div className="text-center">No pricing data available.</div>
        )}

        {venuePricing.length > 0 ? (
          venuePricing.map((item, index) => {
            const monthlyPrice = (item.unit_amount / 100).toFixed(2);
            // const annualPrice = (item.unit_amount / 100 * 12).toFixed(2);
            return (
              <div isMobile={isMobile} key={index} className="plan" data-aos="zoom-in">
                <h2>{item.name}</h2>
                <div className="price" style={{ marginBottom: "10px" }}>
                  <>
                    <strong style={{ fontSize: '1.5em' }}>${monthlyPrice.split('.')[0]}</strong>
                    <span style={{ fontSize: '1em' }}>.{monthlyPrice.split('.')[1]}/month(incl.GST)</span>
                  </>

                </div>
                <p ><strong>Special Offer:</strong>{item.description || "1 month free to get started!"}<br /><br />
                  {"GigBridge makes it easy for venues to find the right talent, helping you create unforgettable experiences and attract new customers."}</p>

                <hr />
                <ul>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>Your Entertainment Hub:</strong> Post opportunities and have the talent come to you.
                    </div>
                  </li>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>Your Price, Your Way:</strong> Book artists that suit your venue’s atmosphere and budget.
                    </div>
                  </li>
                  <li><FaCheckCircle className="CheckIcon" />
                    <div>
                      <strong>Diverse Talent Pool:</strong> Choose from emerging and experienced performers to create memorable live-music events.
                    </div>
                  </li>
                </ul>

                <br />
                <button onClick={handleLogin}>Get started</button>
              </div>
            );
          })
        ) : (
          <div className="text-center">No pricing data available.</div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
