import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "./axiosInstance";

export default function AuthLogin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "http://gigbridgeapiqa-env.eba-tiav7hzt.eu-north-1.elasticbeanstalk.com/v1/auth/sign-in",
        formData
      );
      console.log("API Response:", response?.data.data);

      const { access, refresh } = response.data.data.tokens; // Extract tokens from response
      handleLoginSuccess(access, refresh); // Pass tokens to handleLoginSuccess function
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid email or password",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSuccess = async (accessToken, refreshToken) => {
    // Securely store tokens in cookies
    Cookies.set("accessToken", accessToken, { secure: true });
    Cookies.set("refreshToken", refreshToken, { secure: true });
    localStorage.setItem("isAuthenticated", true);
    try {
      const response = await axiosInstance.get("/v1/auth/account/status", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const accountStatus = response.data.data.account_status;
      console.log("account status: ", response.data.data.account_status);
      if (accountStatus === "ACTIVE") {
        navigate("/profiles");
      } else if (accountStatus === "FRESH") {
        navigate("/verify-email");
        // } else if (accountStatus === "EMAIL_UNVERIFIED") {
        //   navigate("/verify-email");
      } else if (accountStatus === "PHONE_NUMBER_PENDING") {
        navigate("/phone-entry");
      } else if (accountStatus === "PHONE_NUMBER_UNVERIFIED") {
        navigate("/verify-phone");
      } else if (accountStatus === "APPROVAL_PENDING") {
        navigate("/account-approval-notice");
      } else if (accountStatus === "REJECTED") {
        navigate("/account-rejected-page");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unexpected account status",
        });
      }
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to retrieve account status",
      });
    }

    // Swal.fire({
    //     title: "Success!",
    //     text: "You are logged in!",
    //     icon: "success",
    //   }).then(() => {
    //     navigate("/");

    // });

    // navigate("/");
  };

  return (
    <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        // style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Please sign in</h5>

                <div className="form-floating mb-2">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label text-muted"
                        htmlFor="flexCheckDefault"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <span className="forgot-pass text-muted mb-0">
                    <Link to="/auth-reset-password" className="text-muted">
                      Forgot password ?
                    </Link>
                  </span>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Sign in{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>

                <div className="col-12 text-center mt-3">
                  <span>
                    <span className="text-muted me-2">
                      I'm new here. Take me to register page.
                      {/* Don't have an account ? */}
                    </span>{" "}
                    <Link to="/auth-signup" className="text-dark fw-medium">
                      Sign Up
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
