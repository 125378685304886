import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./supportTicket.css"; // Import the CSS file
import SupportTicketForm from "../components/supportTicketForm";
import axiosInstance from "../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import Modal from "../components/modal";
import Swal from "sweetalert2";
// import NavigationBar from "../components/navbar/newNavigationBarProfiles";
import NavigationBar from "../components/navbar/loggedUserNavbarAccount";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

const SupportTicketPage = () => {
  const [loading, setLoading] = useState(false);
  const [performerData, setPerformerData] = useState({});
  const [venueData, setVenueData] = useState({});
  const [profileType, setProfileType] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [supportTicketForm, setSupportTicketForm] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [blurProfile, setBlurProfile] = useState(false);
  const selectedPerformerId = Cookies.get("profileOwnerId");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const profileId = Cookies.get("profileOwnerId");
  const [isPublished, setIsPublished] = useState(false);
  const navigate = useNavigate();

  const handleAddTickets = () => {
    setSupportTicketForm(true);
    setBlurProfile(true);
  };

  const handleClose = () => {
    setSupportTicketForm(true);
    setBlurProfile(false);
  };

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `v1/support/tickets/by/profile/${selectedPerformerId}`
        );

        setTickets(response.data.support_tickets_written);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [loadingTicket]);

  const deleteTicket = async (ticketId) => {
    setLoadingTicket(true);
    try {
      const response = await axiosInstance.get(
        `/v1/support/delete-ticket/${ticketId}`
      );
      if (response.data) {
        setLoadingTicket(false);
      }
      if (response.status === 200) {
        Swal.fire(
          "Deleted!",
          "The support ticket has been deleted successfully",
          "success"
        );
      } else {
        console.error(
          "Error deleting Support Ticket:",
          response.status,
          response.data
        );
        Swal.fire("Error", "An error occurred while deleting the support ticket. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error deleting ticket:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    }
  };

  const openModal = (ticket) => {
    setSelectedTicket(ticket);
  };

  const closeModal = () => {
    setSelectedTicket(null);
  };

  const handleBackClick = () => {
    navigate(`/account-page`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!profileId) return;

      const profileType = profileId.split("-")[1];
      Cookies.set("profileOwnerId", profileId);

      let apiUrl = "";
      if (profileType === "PERFORMER") {
        apiUrl = `/v1/performer/view-profile/${profileId}/section/complete`;
      } else if (profileType === "VENUE") {
        apiUrl = `/v1/venue/view-profile/${profileId}/section/complete`;
      }

      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        if (profileType === "PERFORMER") {
          setProfileType(profileType);
          setPerformerData(response.data);
        } else if (profileType === "VENUE") {
          setProfileType(profileType);
          setVenueData(response.data);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, [isPublished]); // Depend on isPublished to trigger rerender

  return (
    <>
      <NavigationBar />
      <div className="container_Support container">
        <div className="content_Support">
          <button
            className="back-arrow"
            onClick={handleBackClick}
            style={{
              border: "none",
              outline: "none",
              marginLeft: "-1%",      
              fontSize: '25px',
              marginBottom: '10px',
              color: 'black',
              background: 'transparent',
              cursor: 'pointer',
            }}
          >
            ←
          </button>
          <div className="course-header_Support">
            <h1 className="title_Support ">GigBridge Support Ticket</h1>
          </div>
          <div className="sidebar_Support">
            <button
              className="add-button_Support btn btn-primary"
              onClick={handleAddTickets}
            >
              Add Support Ticket
            </button>
            {supportTicketForm && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: "999",
                }}
                onClick={handleClose}
              >
                <SupportTicketForm
                  onClose={() => setSupportTicketForm(false)}
                />
              </div>
            )}
          </div>
          <Modal
            show={!!selectedTicket}
            onClose={closeModal}
            ticket={selectedTicket}
          />
          <div className="row ticket-container">
            {tickets.map((ticket) => (
              <div
                className="card_Support col-12 mb-3"
                key={ticket._id}
                onClick={() => openModal(ticket)}
              >
                <h2 className="card-title_Support">{ticket.title}</h2>
                <p className="card-message_Support">{ticket.message}</p>
                <div className="delete-button-container">
                  <button
                    className="delete-button_Support btn btn-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTicket(ticket._id);
                    }}
                    disabled={loadingTicket}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SupportTicketPage;
