import React, { useState, useEffect } from "react";
import "./opportunity.css";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
// import dummyProfilePicture from "../assect/images/client/jezzypic.jpg";
// import dummyProfilePicture2 from "../assect/images/client/kayapic.jpg";
// import dummyProfilePicture3 from "../assect/images/client/01.jpg";
import "./venue.css";

const OpportunityVenueView = () => {
  const [opportunity, setOpportunity] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId || Cookies.get("opportunityId");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    fetchOpportunity();
  }, [selectedOpportunityId, accessToken]);

  const handleApplyButtonClick = () => {
    const toProfileId = opportunity.author_profile_id;
    console.log("opp auther profile id: " + toProfileId);
    Cookies.set("toProfileId", toProfileId);
    // Cookies.set("opportunityId", opportunityId);
    navigate("/messaging");
  };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center min-vh-100 background-overlay"
      style={{
        // backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="container p-4 rounded shadow"> */}

      <div className="oppcontainer">
        <h1 className="applicants text-left" style={{ color: "white" }}>
          {opportunity?.title}
        </h1>
        <div className="oppbox">
          {opportunity && (
            <>
              <div className="oppdescription">
                {opportunity.short_description}
              </div>
              <div className="oppdetails">
                <div className="oppdetailRow">
                  <strong>Venue:</strong> <span>{opportunity.venue}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Status:</strong> <span>{opportunity.status}</span>
                </div>
                {/* <div className="oppdetailRow">
                  <strong>Performer:</strong>{" "}
                  <span>{opportunity.performers.performer_name}</span>
                </div> */}
                <div className="oppdetailRow">
                  <strong>Opportunity Type:</strong>{" "}
                  <span>{opportunity.opportunity_types}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Genre:</strong>{" "}
                  <span>{opportunity.opportunity_genres}</span>
                </div>
                <div className="oppdetailRow">
                  <strong>Date:</strong>{" "}
                  <span>
                    {formatDate(opportunity.from_date)} to{" "}
                    {formatDate(opportunity.to_date)}
                  </span>
                </div>
                <div className="oppdetailRow">
                  <strong>Duration:</strong>{" "}
                  <span>
                    {formatTime(opportunity.from_time)} to{" "}
                    {formatTime(opportunity.to_time)}
                  </span>
                </div>
                <hr className="hrStyle" />
                <div>
                  <strong>Description:</strong>{" "}
                  {opportunity.descriptions[0].body}
                </div>
              </div>
            </>
          )}
        </div>
        {/* <div className="buttonsContainer">
          <button
            className="oppbutton"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            style={{ padding: "10px 60px" }}
            // onClick={handleApplyButtonClick}
          >
            POST
          </button>
          <button
            className="oppbutton"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            style={{ padding: "10px 60px" }}
          >
            DELETE
          </button>
        </div> */}
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default OpportunityVenueView;
