import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import logo from "../../assect/images/landing/logo.jpg";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 80px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 60px;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

// const Input = styled.textarea`
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   height: auto; /* Allows the textarea to expand vertically */
//   min-height: 100px; /* Set a minimum height to prevent it from collapsing */
// `;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px; /* Set a minimum height to prevent it from collapsing */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  /* Hide vertical scrollbar */
  overflow-y: hidden;

  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

function UpdatePerformerEquipReq() {
  const [instruments, setInstruments] = useState([]);
  const [technicalRequirements, setTechnicalRequirements] = useState([]);
  const [stageSetup, setStageSetup] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/equipment-requirement",
        {
          performer_id: Cookies.get("profileId"),
          instruments: instruments,
          technical_requirements: technicalRequirements,
          stage_setup: stageSetup,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage(
          "Performer equipment requirements updated successfully."
        );
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Performer equipment requirements updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileId");
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating performer equipment requirements.");
        console.error(
          "Error updating performer equipment requirements:",
          response.data
        );
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer equipment requirements:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleChange = (event, setStateFunction) => {
    const { value, scrollHeight } = event.target;
    setStateFunction(value);
    event.target.style.height = "auto"; // Reset the height
    event.target.style.height = scrollHeight + "px"; // Set the dynamic height
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Equipment Requirements</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        <InputGroup>
          <Label htmlFor="instruments">Instruments</Label>
          <InputWrapper>
            <Textarea
              id="instruments"
              className="form-control"
              value={instruments}
              onChange={(e) => handleChange(e, setInstruments)}
              placeholder="Enter instruments..."
              required
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            required
                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="technicalRequirements">Technical Requirements</Label>
          <InputWrapper>
            <Textarea
              type="text"
              className="form-control"
              id="technicalRequirements"
              value={technicalRequirements}
              onChange={(e) => handleChange(e, setTechnicalRequirements)}
              placeholder="Enter technical requirements..."
              required
            />

            {/* <Input
                            type="text"
                            className="form-control"
                            id="technicalRequirements"

                            value={technicalRequirements}
                            onChange={(e) => setTechnicalRequirements(e.target.value)}

                        /> */}
          </InputWrapper>
        </InputGroup>

        <Button type="submit">
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderWidth: "0.1rem",
              }}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerEquipReq;
