import { useRef, useEffect, useState } from "react";
import "./acstyles.css";
import "../pages/venue/venue.css";
import "../pages/search/newSearch.css";
const AutoComplete = ({ location, onSuburbSelect }) => {
  const [inputValue, setInputValue] = useState("");

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [error, setError] = useState("");
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const options = {
    // componentRestrictions: { country: "ng" },
    componentRestrictions: { country: "au" },
    fields: [
      "address_components",
      "geometry",
      "icon",
      "name",
      "place_id",
      "formatted_address",
    ],
    types: ["geocode"],
  };

  useEffect(() => {
    if (location && location.suburb) {
      setInputValue(location.address);
    }
  }, [location]);

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps API is not loaded properly.");
      return;
    }
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      console.log("Autocomplete response:", place);
      // if (!place) return;
      if (!place || !place.address_components || !place.geometry) {
        setError("Invalid location selected. Please choose a valid place.");
        return;
      }

      const addressComponents = place.address_components;
      const suburb = addressComponents.find(
        (component) =>
          component.types.includes("sublocality") ||
          component.types.includes("locality")
      );

      if (!suburb) {
        setError("No valid suburb found.");
        return;
      }

      const zipcode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.short_name;

      if (!zipcode && !suburb) {
        setError("Please select a valid location with a zip code or a city.");
        setSelectedSuburb(null);
        return;
      }

      // if (!zipcode) {
      //   setError("Please select a location with a valid zip code.");
      //   setSelectedSuburb(null);
      //   return;
      // }

      // const formattedSuburb = `${suburb.long_name} ${
      //   zipcode ? `(${zipcode})` : ""
      // }`;
      const locationName = place.name;
      const address = place.formatted_address;
      // const latitude = place.geometry.location.lat();
      // const longitude = place.geometry.location.lng();
      const latitude =
        place.geometry && place.geometry.location
          ? place.geometry.location.lat()
          : null;
      const longitude =
        place.geometry && place.geometry.location
          ? place.geometry.location.lng()
          : null;
      const googleMapPin = place.icon;

      if (!latitude || !longitude) {
        setError("Invalid location coordinates.");
        return;
      }

      const selectedSuburbData = {
        suburb: suburb ? suburb.long_name : "",
        zipcode: zipcode || "",
        latitude: latitude,
        longitude: longitude,
        location_name: locationName,
        address: address,
        google_map_pin: googleMapPin,
      };

      setSelectedSuburb(selectedSuburbData);
      setError("");
      if (onSuburbSelect) {
        onSuburbSelect(selectedSuburbData);
      }

      // fetchSuburbDetails(place.place_id);
      console.log("place id: ", place.place_id);
      console.log("latitude new: ", place.geometry.location.lat());
      console.log("longitude new: ", place.geometry.location.lng());
      console.log("google map pin: ", place.icon);
      console.log("address: ", place.formatted_address);
    });
  }, [onSuburbSelect]);

  //   const handleSuburbSelect = (suburb) => {
  //     setSelectedSuburb(suburb);
  //   };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      <input
        className="input-group"
        style={{
          border: "none",
          // width: "244px",
          // width: "100%",
          padding: "6px",
          // marginTop: "15%",
          // marginLeft: "-30%",
        }}
        ref={inputRef}
        value={inputValue}
        // onChange={(e) => setSelectedSuburb(e.target.value)}
        // onInputChange={(value) => setInputValue(value)}
        onChange={handleChange}
        isClearable
        required
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};
export default AutoComplete;
