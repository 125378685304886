import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "./venue.css";
import "./opportunity.css";

// import dummyProfilePicture from "../assect/images/client/jezzypic.jpg";
// import dummyProfilePicture2 from "../assect/images/client/kayapic.jpg";
// import dummyProfilePicture3 from "../assect/images/client/01.jpg";

const OpportunityAuthorVenueView = () => {
  const [opportunity, setOpportunity] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [performerDetails, setPerformerDetails] = useState({});
  const accessToken = Cookies.get("accessToken");
  const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId || Cookies.get("opportunityId");
  const performerId = Cookies.get("profileOwnerId");
  const profileId = Cookies.get("profileOwnerId");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        console.log("opportunity details:", response);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    fetchOpportunity();
  }, [selectedOpportunityId, accessToken]);

  useEffect(() => {
    const fetchBookings = async () => {
      const loggedInProfileId = Cookies.get("profileOwnerId");

      const payload = {
        opportunity_id: selectedOpportunityId,
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/view-all-active/by/opportunity",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setBookings(response.data.bookings);
          response.data.bookings.forEach((booking) => {
            fetchPerformerDetails(booking.applicant_profile_id);
          });
        } else {
          console.error(
            "Error fetching bookings:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchBookings();
  }, [selectedOpportunityId, accessToken]);

  const fetchPerformerDetails = async (performerId) => {
    try {
      const response = await axiosInstance.get(
        `/v1/performer/view-profile/${performerId}/section/complete`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setPerformerDetails((prevDetails) => ({
        ...prevDetails,
        [performerId]: response.data.performer_info,
      }));
    } catch (error) {
      console.error("Error fetching performer details:", error);
    }
  };

  const handleSendConfirmation = async (bookingId, performerId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      booking_id: bookingId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/booking/confirm/by-venue",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Confirmation Sent!", `Booking ID: ${bookingId}`, "success");

        const confirmedBooking = {
          ...bookings.find((b) => b._id === bookingId),
        };
        confirmedBooking.performer = performerDetails[performerId];
        confirmedBooking.confirmed = true;

        setBookings((prevBookings) => {
          const confirmedBookings = prevBookings.filter((b) => b.confirmed);
          const pendingBookings = prevBookings.filter((b) => !b.confirmed);
          return [
            ...confirmedBookings.concat(confirmedBooking),
            ...pendingBookings,
          ];
        });
      } else {
        Swal.fire(
          "Error",
          `Failed to send confirmation: ${response.data.message}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "Failed to send confirmation", "error");
    }
  };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  const handleWithdrawRequest = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/withdraw/confirmation/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_APPLIED" }
              : booking
          )
        );
        Swal.fire("Request Withdrawn!", `Booking ID: ${bookingId}`, "success");
      } else {
        console.error(
          "Error withdrawing booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to withdraw booking", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleReject = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/reject/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Request rejected!", "success");
      } else {
        console.error(
          "Error rejecting booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to reject booking", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleAcceptCancellation = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-accept/contract/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Cancellation Accepted!", "success");
      } else {
        console.error(
          "Error accepting cancellation:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to accept cancellation", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleDeleteClick = (opportunityId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this opportunity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteOpportunity(opportunityId);
      }
    });
  };

  const handleDeleteOpportunity = async (opportunityId) => {
    const url = `/v1/opportunity/delete-opportunity/${opportunityId}`;

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire("Deleted!", "Your opportunity has been deleted.", "success");
        navigate(`/venue/${profileId}`);
      } else {
        Swal.fire(
          "Error!",
          `Error deleting opportunity: ${response.status}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the opportunity",
        "error"
      );
    }
  };

  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center min-vh-100 background-overlay"
      style={{
        // backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="row">
        <div
          className="col-md-2"
          style={{ marginTop: "2%", marginRight: "5%" }}
        >
          <h1 className="applicants text-left" style={{ color: "white" }}>
            Applicants
          </h1>
          <div className="table-container">
            <table className="table table-striped table-hover">
              <tbody>
                {bookings.map((booking) => {
                  const performer =
                    performerDetails[booking.applicant_profile_id];
                  const isConfirmed = booking.status === "VENUE_CONFIRMED";
                  const isPerformerDoubleConfirmed =
                    booking.status === "PERFORMER_DOUBLED_CONFIRMED";

                  return (
                    <tr
                      key={booking._id}
                      className={isConfirmed ? "" : "frozen"}
                    >
                      <td>
                        {performer ? (
                          <div className="d-flex align-items-center">
                            <img
                              // src={performer.thumbnail_profile_pic_url}
                              src={
                                performer.thumbnail_profile_pic_url ||
                                performer.profile_picture_url
                              }
                              alt={`${performer.profile_name}`}
                              className="rounded-circle"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                              }}
                            />
                            <span>{`${performer.profile_name}`}</span>
                          </div>
                        ) : (
                          "Loading..."
                        )}
                      </td>
                      {!isPerformerDoubleConfirmed && (
                        <>
                          <td>
                            {isConfirmed ? (
                              <button
                                className="oppbtn oppbtn-warning"
                                onClick={() =>
                                  handleWithdrawRequest(booking._id)
                                }
                              >
                                Withdraw Request
                              </button>
                            ) : booking.status ===
                              "PERFORMER_CANCELLED_CONTRACT" ? (
                              <button
                                className="oppbtn oppbtn-success"
                                onClick={() =>
                                  handleAcceptCancellation(booking._id)
                                }
                              >
                                Accept Contract Cancellation
                              </button>
                            ) : (
                              <button
                                className="oppbtn oppbtn-success"
                                onClick={() =>
                                  handleSendConfirmation(booking._id)
                                }
                              >
                                Send Confirmation
                              </button>
                            )}
                          </td>
                        </>
                      )}
                      <td>
                        <span className="booking-status">{booking.status}</span>
                        {booking.status !== "PERFORMER_CANCELLED_CONTRACT" && (
                          <button
                            className="oppbtn oppbtn-danger"
                            onClick={() => handleReject(booking._id)}
                          >
                            Reject
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-10">
          <div className="oppcontainer">
            <h1 className="applicants text-left" style={{ color: "white" }}>
              {opportunity?.title}
            </h1>
            <div className="oppbox">
              {opportunity && (
                <>
                  <div className="oppdescription">
                    {opportunity.short_description}
                  </div>
                  <div className="oppdetails">
                    <div className="oppdetailRow">
                      <strong>Venue:</strong> <span>{opportunity.venue}</span>
                    </div>
                    <div className="oppdetailRow">
                      <strong>Status:</strong> <span>{opportunity.status}</span>
                    </div>
                    {/* <div className="oppdetailRow">
                      <strong>Performer:</strong>{" "}
                      <span>{opportunity.performers.performer_name}</span>
                    </div> */}
                    <div className="oppdetailRow">
                      <strong>Opportunity Type:</strong>{" "}
                      <span>{opportunity.opportunity_types}</span>
                    </div>
                    <div className="oppdetailRow">
                      <strong>Genre:</strong>{" "}
                      <span>{opportunity.opportunity_genres}</span>
                    </div>
                    <div className="oppdetailRow">
                      <strong>Date:</strong>{" "}
                      <span>
                        {formatDate(opportunity.from_date)} to{" "}
                        {formatDate(opportunity.to_date)}
                      </span>
                    </div>
                    <div className="oppdetailRow">
                      <strong>Duration:</strong>{" "}
                      <span>
                        {formatTime(opportunity.from_time)} to{" "}
                        {formatTime(opportunity.to_time)}
                      </span>
                    </div>
                    <hr className="hrStyle" />
                    <div>
                      <strong>Description:</strong>{" "}
                      {opportunity.descriptions[0].body}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="buttonsContainer">
              {/* <button
                className="oppbutton"
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{ padding: "10px 60px" }}
                // onClick={handleApplyButtonClick}
              >
                POST
              </button> */}
              <button
                className="oppbutton"
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{ padding: "10px 60px" }}
                onClick={() => handleDeleteClick(opportunity._id)}
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default OpportunityAuthorVenueView;
