import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import "./CreatePerformer.css";
import "../OtherPages/LandingProfiles.css";

function UpdatePerformerGenre() {
  const profileId = Cookies.get("profileId");
  const [genres, setGenres] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const genresInputRef = useRef(null);
  const [showGenresDropdown, setShowGenresDropdown] = useState(false);
  const navigate = useNavigate();

  const MAX_GENRES = 5;

  const handleGenreChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      // Add genre if not already selected and within the limit
      if (genres.length < MAX_GENRES) {
        setGenres([...genres, value]);
      } else {
        setErrorMessage("You can only select a maximum of 5 genres.");
      }
    } else {
      setGenres(genres.filter((genre) => genre !== value));
    }
  };

  useEffect(() => {
    async function fetchGenreTypes() {
      try {
        const response = await axiosInstance.get(
          "/v1/performer/performer-genres",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          setAvailableGenres(response.data.performer_genres);
        } else {
          setErrorMessage("Error fetching genre types.");
        }
      } catch (error) {
        console.error("Error fetching genre types:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchGenreTypes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/performer-genres",
        {
          performer_id: profileId,
          performer_genres: genres,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("Genres updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "Genres updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating genres.");
        console.error("Error updating genres:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer genres:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleGenresInputClick = () => {
    setShowGenresDropdown(!showGenresDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      genresInputRef.current &&
      !genresInputRef.current.contains(event.target)
    ) {
      setShowGenresDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update Genres</h5>

                <div className="mb-3">
                  <label htmlFor="genres" className="form-label">
                    Genres
                  </label>
                  <div className="dropdown-scrollable" ref={genresInputRef}>
                    <input
                      type="text"
                      className="form-control"
                      id="genres"
                      name="genres"
                      onFocus={handleGenresInputClick}
                      placeholder="Select Genres"
                      readOnly
                    />
                    {showGenresDropdown && (
                      <div className="dropdown-content">
                        {availableGenres.length > 0 ? (
                          availableGenres.map((genre) => (
                            <div className="form-check" key={genre}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`genre-${genre}`}
                                name="genres"
                                value={genre}
                                checked={genres.includes(genre)}
                                onChange={handleGenreChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`genre-${genre}`}
                              >
                                {genre}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading genres...</p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* <div>
                    {availableGenres.map((genre) => (
                      <div key={genre}>
                        <input
                          type="checkbox"
                          id={`genre-${genre}`}
                          name="genres"
                          value={genre}
                          checked={genres.includes(genre)}
                          onChange={(event) => handleGenreChange(event)}
                        />
                        <label htmlFor={`genre-${genre}`}>{genre}</label>
                        <br />
                      </div>
                    ))}
                  </div> */}
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePerformerGenre;
