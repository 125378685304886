import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosInstance from "../auth/axiosInstance";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import "./Calendar.css";
import NewOpportunityAuthorVenueView from "../newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../newView/NewOpportunityPerformerView";
import NewOpportunityVenueView from "../newView/NewOpportunityVenueView";

import CustomTimePicker from "../../components/CustomTimePicker";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const localizer = momentLocalizer(moment);

const CalendarSelfView = (props) => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [showModal2, setShowModal2] = useState(false);
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availability, setAvailability] = useState({
    status: "available",
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
    isRecurring: false,
    recurringType: "",
    recurringUntilDate: "",
  });
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentDate, setCurrentDate] = useState(new Date());

  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");
  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const fetchAllEvents = async () => {
    setIsLoading(true);
    try {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      // Fetch both bookings and events in parallel
      // const [bookingsResponse, eventsByMonthResponse] = await Promise.all([
      //   axiosInstance.post(
      //     `/v1/booking/view-all/by/performer`,
      //     {
      //       logged_in_profile_id: profileId,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   ),
      //   axiosInstance.post(
      //     `/v1/calendar/get-events/by-month/self`,
      //     {
      //       profile_id: profileId,
      //       year: currentYear,
      //       month: currentMonth,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   ),
      // ]);

      // const bookingsData = bookingsResponse.data.bookings.map((booking) => ({
      //   _id: booking._id,
      //   title: booking.title,
      //   start_date: new Date(
      //     booking.from_date.year,
      //     booking.from_date.month - 1,
      //     booking.from_date.date,
      //     booking.from_time.hour,
      //     booking.from_time.minute
      //   ),
      //   end_date: new Date(
      //     booking.to_date.year,
      //     booking.to_date.month - 1,
      //     booking.to_date.date,
      //     booking.to_time.hour,
      //     booking.to_time.minute
      //   ),
      //   slot_status: "UNAVAILABLE",
      //   slot_reservation_type: "BOOKING",
      //   opportunity_id: booking.opportunity_id,
      // }));

      const eventsByMonthResponse = await axiosInstance.post(
        `/v1/calendar/get-events/by-month/self`,
        {
          profile_id: profileId,
          year: currentYear,
          month: currentMonth,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const userEvents = eventsByMonthResponse.data.calendar_events.map(
        (event) => ({
          id: event._id,
          title:
            event.name === "[UNAVAILABLE]"
              ? "Unavailable"
              : event.name.startsWith("Opportunity") ||
                event.name.startsWith("Direct Booking")
              ? event.name
              : "Available",
          // title: event.name === "[UNAVAILABLE]" ? "Unavailable" : "Available",
          start: convertUTCToLocal(new Date(event.start_date)),
          end: convertUTCToLocal(new Date(event.end_date)),
          // start: new Date(event.start_date),
          // end: new Date(event.end_date),
          recurrence: event.recurrence,
          recurrence_series_id: event.recurrence_series_id,
          slot_status: event.slot_status,
          opportunity_id: event.opportunity_id,
          slot_reservation_type: event.slot_reservation_type,
        })
      );

      // Combine bookings and events and update state
      // setEvents([...bookingsData, ...userEvents]);
      // setEvents([userEvents]);
      setEvents(userEvents);
    } catch (error) {
      console.error("Error fetching events:", error); // Log errors if fetching fails
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data when component mounts or when month/year changes
  useEffect(() => {
    fetchAllEvents();
  }, [currentYear, currentMonth]);

  // const fetchBookings = async () => {
  //   try {
  //     const profileId = Cookies.get("profileOwnerId");
  //     const accessToken = Cookies.get("accessToken");
  //     const response = await axiosInstance.post(
  //       `/v1/booking/view-all/by/performer`,
  //       { logged_in_profile_id: profileId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const bookingsData = response.data.bookings.map((booking) => ({
  //       _id: booking._id,
  //       title: booking.title,
  //       start_date: new Date(
  //         booking.from_date.year,
  //         booking.from_date.month - 1,
  //         booking.from_date.date,
  //         booking.from_time.hour,
  //         booking.from_time.minute
  //       ),
  //       end_date: new Date(
  //         booking.to_date.year,
  //         booking.to_date.month - 1,
  //         booking.to_date.date,
  //         booking.to_time.hour,
  //         booking.to_time.minute
  //       ),
  //       slot_status: "UNAVAILABLE",
  //       slot_reservation_type: "BOOKING",
  //       opportunity_id: booking.opportunity_id,
  //     }));
  //     setBookings(bookingsData);
  //   } catch (error) {
  //     console.error("Error fetching bookings:", error);
  //   }
  // };

  // const fetchEventsByMonth = async (year, month) => {
  //   try {
  //     const profileId = Cookies.get("profileOwnerId");
  //     const accessToken = Cookies.get("accessToken");

  //     const bookingsResponse = await axiosInstance.post(
  //       `/v1/booking/view-all/by/performer`,
  //       { logged_in_profile_id: profileId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const bookingEvents = bookingsResponse.data.bookings.map((booking) => ({
  //       id: booking._id,
  //       title: booking.title,
  //       start: new Date(
  //         booking.from_date.year,
  //         booking.from_date.month - 1,
  //         booking.from_date.date,
  //         booking.from_time.hour,
  //         booking.from_time.minute
  //       ),
  //       end: new Date(
  //         booking.to_date.year,
  //         booking.to_date.month - 1,
  //         booking.to_date.date,
  //         booking.to_time.hour,
  //         booking.to_time.minute
  //       ),
  //       slot_status: "UNAVAILABLE",
  //       slot_reservation_type: "BOOKING",
  //       opportunity_id: booking.opportunity_id,
  //     }));

  //     const userEventsResponse = await axiosInstance.post(
  //       `/v1/calendar/get-events/by-month/self`,
  //       {
  //         profile_id: profileId,
  //         year: year,
  //         month: month,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log("uswreventsresponse: ", userEventsResponse);
  //     const userEvents = userEventsResponse.data.calendar_events.map(
  //       (event) => ({
  //         id: event._id,
  //         title:
  //           event.name === "[UNAVAILABLE]"
  //             ? "Unavailable"
  //             : event.name.startsWith("Opportunity") ||
  //               event.name.startsWith("Direct Booking")
  //             ? event.name
  //             : "Available",
  //         // title: event.name === "[UNAVAILABLE]" ? "Unavailable" : "Available",
  //         start: convertUTCToLocal(new Date(event.start_date)),
  //         end: convertUTCToLocal(new Date(event.end_date)),
  //         // start: new Date(event.start_date),
  //         // end: new Date(event.end_date),
  //         recurrence: event.recurrence,
  //         recurrence_series_id: event.recurrence_series_id,
  //         slot_status: event.slot_status,
  //         opportunity_id: event.opportunity_id,
  //         slot_reservation_type: event.slot_reservation_type,
  //       })
  //     );

  //     const allEvents = [...bookingEvents, ...userEvents];
  //     setEvents(allEvents);
  //   } catch (error) {
  //     console.error("Error fetching events:", error);
  //   }
  // };

  // const fetchEventsByDay = async (year, month, day) => {
  //   try {
  //     const profileId = Cookies.get("profileOwnerId");
  //     const accessToken = Cookies.get("accessToken");

  //     const response = await axiosInstance.post(
  //       `/v1/calendar/get-events/by-day/self`,
  //       {
  //         profile_id: profileId,
  //         year: year,
  //         month: month,
  //         day: day,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const userEvents = response.data.calendar_events.map((event) => ({
  //       id: event._id,
  //       title: event.name === "[UNAVAILABLE]" ? "Unavailable" : "Available",
  //       start: new Date(event.start_date),
  //       end: new Date(event.end_date),
  //       recurrence: event.recurrence,
  //       recurrence_series_id: event.recurrence_series_id,
  //       slot_status: event.slot_status,
  //       slot_reservation_type: event.slot_reservation_type,
  //     }));

  //     setEvents(userEvents);
  //   } catch (error) {
  //     console.error("Error fetching events:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchBookings();
  //   fetchEventsByMonth(currentYear, currentMonth);
  // }, [currentYear, currentMonth]);

  // useEffect(() => {
  //   fetchBookings();
  //   fetchEventsByDay(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth() + 1,
  //     currentDate.getDate()
  //   );
  //   fetchEventsByMonth(currentDate.getFullYear(), currentDate.getMonth() + 1);
  // }, [currentDate]);

  const handleEventClick = async (event) => {
    const profileId = Cookies.get("profileOwnerId");
    console.log("event:", event);
    if (
      profileId.startsWith("PROFILE-PERFORMER") &&
      event.slot_reservation_type === "DIRECT_BOOKING"
    ) {
      console.log("Booking event click:", event);
    } else {
      if (profileId.startsWith("PROFILE-VENUE")) {
        const opportunityId = event.opportunity_id;
        console.log("opportunity details:", event);
        openModal(opportunityId, profileId);
        // navigate(`/performer-dashboard`, { state: { opportunityId } });
      } else {
        const opportunityId = event.opportunity_id;
        openModal(opportunityId, profileId);
      }
    }
  };

  // const handleEventClick = async (event) => {
  //   const profileId = Cookies.get("profileOwnerId");
  //   console.log("event:", event);
  //   if (
  //     profileId.startsWith("PROFILE-PERFORMER") &&
  //     event.slot_reservation_type === "USER" &&
  //     event.recurrence &&
  //     ["MONTHLY", "DAILY", "WEEKLY"].includes(event.recurrence)
  //   ) {
  //     const result = await Swal.fire({
  //       title: "Delete Slot",
  //       text: "Do you want to delete this slot or all slots in the series?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Delete this slot",
  //       cancelButtonText: "Delete all slots in the series",
  //       reverseButtons: true,
  //       focusConfirm: true,
  //       focusCancel: true,
  //       confirmButtonColor: "#335C8E ",
  //       cancelButtonColor: "#337F8E ",
  //       // customClass: {
  //       //   confirmButton: "confirm-button-class",
  //       //   cancelButton: "cancel-button-class",
  //       // },
  //       position: "center",
  //     });

  //     if (result.isConfirmed) {
  //       // User selected "Delete this slot"
  //       Swal.fire({
  //         title: "Are you sure?",
  //         text: "You won't be able to revert this!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#d33",
  //         cancelButtonColor: "#8F948E ",
  //         confirmButtonText: "Yes, delete it!",
  //         position: "center",
  //       }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           Swal.fire({
  //             title: "Deleting...",
  //             text: "Please wait while the slot is being deleted.",
  //             position: "center",
  //             didOpen: () => {
  //               Swal.showLoading();
  //             },
  //           });
  //           try {
  //             const accessToken = Cookies.get("accessToken");

  //             await axiosInstance.post(
  //               `/v1/calendar/delete-event/standalone`,
  //               {
  //                 profile_id: profileId,
  //                 event_id: event.id,
  //               },
  //               {
  //                 headers: {
  //                   Authorization: `Bearer ${accessToken}`,
  //                 },
  //               }
  //             );

  //             fetchEventsByMonth(currentYear, currentMonth);
  //             Swal.fire({
  //               title: "Deleted!",
  //               text: "Your slot has been deleted.",
  //               icon: "success",
  //               confirmButtonText: "ok",
  //               confirmButtonColor: "#33778E ",
  //             });
  //           } catch (error) {
  //             console.error("Error deleting event:", error);
  //             Swal.fire(
  //               "Error!",
  //               "Something went wrong while deleting the slot.",
  //               "error"
  //             );
  //           }
  //         }
  //       });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       // User selected "Delete all slots in the series"
  //       Swal.fire({
  //         title: "Are you sure?",
  //         text: "You won't be able to revert this!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#d33",
  //         cancelButtonColor: "#8F948E ",
  //         confirmButtonText: "Yes, delete it!",
  //         position: "center",
  //       }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           Swal.fire({
  //             title: "Deleting...",
  //             text: "Please wait a while all the slots are being deleted.",
  //             position: "center",
  //             didOpen: () => {
  //               Swal.showLoading();
  //             },
  //           });
  //           try {
  //             const accessToken = Cookies.get("accessToken");

  //             await axiosInstance.post(
  //               `/v1/calendar/delete-event/recurring-series`,
  //               {
  //                 profile_id: profileId,
  //                 recurrence_series_id: event.recurrence_series_id,
  //               },
  //               {
  //                 headers: {
  //                   Authorization: `Bearer ${accessToken}`,
  //                 },
  //               }
  //             );

  //             fetchEventsByMonth(currentYear, currentMonth);
  //             Swal.fire({
  //               title: "Deleted!",
  //               text: "Your slots have  been deleted.",
  //               icon: "success",
  //               confirmButtonText: "ok",
  //               confirmButtonColor: "#33778E ",
  //             });
  //           } catch (error) {
  //             console.error("Error deleting event:", error);
  //             Swal.fire(
  //               "Error!",
  //               "Something went wrong while deleting the slots.",
  //               "error"
  //             );
  //           }
  //         }
  //       });
  //     }
  //   } else if (
  //     profileId.startsWith("PROFILE-PERFORMER") &&
  //     event.slot_reservation_type === "USER" &&
  //     !event.recurrence
  //   ) {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#d33",
  //       cancelButtonColor: "#8F948E ",
  //       confirmButtonText: "Yes, delete it!",
  //       position: "center",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         Swal.fire({
  //           title: "Deleting...",
  //           text: "Please wait a while this slot is being deleted.",
  //           position: "center",
  //           didOpen: () => {
  //             Swal.showLoading();
  //           },
  //         });
  //         try {
  //           const accessToken = Cookies.get("accessToken");
  //           console.log("event id:", event.id);
  //           console.log("profile id:", Cookies.get("profileOwnerId"));
  //           await axiosInstance.post(
  //             `/v1/calendar/delete-event/standalone`,
  //             {
  //               profile_id: Cookies.get("profileOwnerId"),
  //               event_id: event.id,
  //             },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //               },
  //             }
  //           );

  //           fetchEventsByMonth(currentYear, currentMonth);
  //           Swal.fire({
  //             title: "Deleted!",
  //             text: "Your slot has been deleted.",
  //             icon: "success",
  //             confirmButtonText: "ok",
  //             confirmButtonColor: "#33778E ",
  //           });
  //         } catch (error) {
  //           console.error("Error deleting event:", error);
  //           Swal.fire(
  //             "Error!",
  //             "Something went wrong while deleting the slot.",
  //             "error"
  //           );
  //         }
  //       }
  //     });
  //   } else if (
  //     profileId.startsWith("PROFILE-PERFORMER") &&
  //     event.slot_reservation_type === "DIRECT_BOOKING"
  //   ) {
  //     console.log("Booking event click:", event);
  //   } else {
  //     if (profileId.startsWith("PROFILE-VENUE")) {
  //       const opportunityId = event.opportunity_id;
  //       console.log("opportunity details:", event);
  //       openModal(opportunityId, profileId);
  //       // navigate(`/performer-dashboard`, { state: { opportunityId } });
  //     } else {
  //       const opportunityId = event.opportunity_id;
  //       openModal(opportunityId, profileId);
  //     }
  //   }
  // };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    setShowModal2(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(profileId);
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
      let errorMessage = "There was an error fetching opportunity details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleToTimeChange = (date) => {
    // Assuming availability is part of your component's state
    setAvailability((prevState) => ({
      ...prevState,
      toTime: date,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAvailability((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const profileId = Cookies.get("profileOwnerId");

  //   const convertTo12HourFormat = (hour, minute) => {
  //     const isPM = hour >= 12;
  //     const formattedHour = hour % 12 || 12;
  //     const formattedMinute = minute % 30 === 0 ? minute : 0;
  //     const amPm = isPM ? "PM" : "AM";
  //     return { formattedHour, formattedMinute, amPm };
  //   };

  //   const fromDateTime = new Date(`${availability.from}`);
  //   const toDateTime = new Date(`${availability.to}`);

  //   console.log(fromDateTime);

  //   const recurringUntil = new Date(`${availability.recurringUntilDate}`);

  //   const fromTime = convertTo12HourFormat(
  //     fromDateTime.getHours(),
  //     fromDateTime.getMinutes()
  //   );

  //   console.log(fromTime);

  //   const toTime = convertTo12HourFormat(
  //     toDateTime.getHours(),
  //     toDateTime.getMinutes()
  //   );

  //   let newEvent;

  //   if (availability.isRecurring) {
  //     console.log(availability.recurringUntilDate);
  //     newEvent = {
  //       _id: new Date().getTime().toString(),
  //       profile_id: profileId,
  //       slot_status: availability.status.toUpperCase(),
  //       slot_reservation_type: "USER",
  //       name: `[${availability.status.toUpperCase()}]`,
  //       start_date: fromDateTime,
  //       start_year: fromDateTime.getFullYear(),
  //       start_month: fromDateTime.getMonth() + 1,
  //       start_day: fromDateTime.getDate(),
  //       start_hour: fromTime.formattedHour,
  //       start_minute: fromTime.formattedMinute,
  //       start_am_pm: fromTime.amPm,
  //       end_date: toDateTime,
  //       end_year: toDateTime.getFullYear(),
  //       end_month: toDateTime.getMonth() + 1,
  //       end_day: toDateTime.getDate(),
  //       end_hour: toTime.formattedHour,
  //       end_minute: toTime.formattedMinute,
  //       end_am_pm: toTime.amPm,
  //       duration_hours: (toDateTime - fromDateTime) / (1000 * 60 * 60),
  //       occupied_slots: [],
  //       recurrence_series_id: availability.isRecurring
  //         ? new Date().getTime().toString()
  //         : null,
  //       recurrence: availability.isRecurring
  //         ? availability.recurringType
  //         : null,
  //       recurring_year: recurringUntil.getFullYear(),
  //       recurring_month: recurringUntil.getMonth() + 1,
  //       recurring_date: recurringUntil.getDate(),
  //     };
  //   } else {
  //     newEvent = {
  //       _id: new Date().getTime().toString(),
  //       profile_id: profileId,
  //       slot_status: availability.status.toUpperCase(),
  //       slot_reservation_type: "USER",
  //       name: `[${availability.status.toUpperCase()}]`,
  //       start_date: fromDateTime,
  //       start_year: fromDateTime.getFullYear(),
  //       start_month: fromDateTime.getMonth() + 1,
  //       start_day: fromDateTime.getDate(),
  //       start_hour: fromTime.formattedHour,
  //       start_minute: fromTime.formattedMinute,
  //       start_am_pm: fromTime.amPm,
  //       end_date: toDateTime,
  //       end_year: toDateTime.getFullYear(),
  //       end_month: toDateTime.getMonth() + 1,
  //       end_day: toDateTime.getDate(),
  //       end_hour: toTime.formattedHour,
  //       end_minute: toTime.formattedMinute,
  //       end_am_pm: toTime.amPm,
  //       duration_hours: (toDateTime - fromDateTime) / (1000 * 60 * 60),
  //       occupied_slots: [],
  //       recurrence_series_id: availability.isRecurring
  //         ? new Date().getTime().toString()
  //         : null,
  //       recurrence: availability.isRecurring
  //         ? availability.recurringType
  //         : null,
  //     };
  //   }

  //   console.log(newEvent);

  //   try {
  //     const accessToken = Cookies.get("accessToken");
  //     Swal.fire({
  //       title: "Setting Availability...",
  //       text: "Please wait while the slot availability is being set.",
  //       position: "center",
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });

  //     if (availability.isRecurring) {
  //       await callRecurringApi(newEvent);
  //     } else {
  //       const response = await axiosInstance.post(
  //         "/v1/calendar/set-slot-availability/standalone",
  //         {
  //           profile_id: profileId,
  //           status: availability.status.toUpperCase(),
  //           from_date: {
  //             year: newEvent.start_year,
  //             month: newEvent.start_month,
  //             day: newEvent.start_day,
  //           },
  //           to_date: {
  //             year: newEvent.end_year,
  //             month: newEvent.end_month,
  //             day: newEvent.end_day,
  //           },
  //           from_time: {
  //             hour: fromTime.formattedHour,
  //             minute: fromTime.formattedMinute,
  //             am_pm: fromTime.amPm,
  //           },
  //           to_time: {
  //             hour: toTime.formattedHour,
  //             minute: toTime.formattedMinute,
  //             am_pm: toTime.amPm,
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setEvents([...events, newEvent]);
  //         Swal.fire({
  //           title: "Success",
  //           text: "Slot availability has been set.",
  //           icon: "success",
  //           confirmButtonText: "Ok",
  //           confirmButtonColor: "#33778E ",
  //         });
  //         fetchEventsByMonth(currentYear, currentMonth);
  //       } else {
  //         console.error("Error setting availability:", response.statusText);
  //         Swal.fire(
  //           "Error",
  //           "There was an error setting slot availability.",
  //           "error"
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error setting availability:", error);
  //     let errorMessage = "There was an error setting slot availability.";

  //     if (error.response && error.response.data && error.response.data.error) {
  //       errorMessage = error.response.data.error;
  //     }

  //     Swal.fire("Error", errorMessage, "error");
  //   }

  //   handleCloseModal();
  // };

  const handleAvailabilityChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(value);
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNavigate = (date) => {
    setCurrentMonth(date.getMonth() + 1);
    setCurrentYear(date.getFullYear());
  };

  // const callRecurringApi = async (newEvent) => {
  //   const accessToken = Cookies.get("accessToken");

  //   const payload = {
  //     profile_id: newEvent.profile_id,
  //     status: newEvent.slot_status,
  //     recurrence: newEvent.recurrence,
  //     recurring_until_date: {
  //       year: newEvent.recurring_year,
  //       month: newEvent.recurring_month,
  //       day: newEvent.recurring_date,
  //     },
  //     from_date: {
  //       year: newEvent.start_year,
  //       month: newEvent.start_month,
  //       day: newEvent.start_day,
  //     },
  //     to_date: {
  //       year: newEvent.end_year,
  //       month: newEvent.end_month,
  //       day: newEvent.end_day,
  //     },
  //     from_time: {
  //       hour: newEvent.start_hour,
  //       minute: newEvent.start_minute,
  //       am_pm: newEvent.start_am_pm,
  //     },
  //     to_time: {
  //       hour: newEvent.end_hour,
  //       minute: newEvent.end_minute,
  //       am_pm: newEvent.end_am_pm,
  //     },
  //   };

  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/calendar/set-slot-availability/recurring",
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setEvents([...events, newEvent]);
  //       Swal.fire({
  //         title: "Success",
  //         text: "Slot availability has been set.",
  //         icon: "success",
  //         confirmButtonText: "Ok",
  //         confirmButtonColor: "#33778E ",
  //       });
  //       fetchEventsByMonth(currentYear, currentMonth);
  //     } else {
  //       console.error("Error setting availability:", response.statusText);
  //       Swal.fire(
  //         "Error",
  //         "There was an error setting slot availability.",
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error setting recurring availability:", error);
  //     let errorMessage = "There was an error setting slot availability.";

  //     if (error.response && error.response.data && error.response.data.error) {
  //       errorMessage = error.response.data.error;
  //     }

  //     Swal.fire("Error", errorMessage, "error");
  //   }
  // };

  // const allEvents = [...bookings, ...events];

  return (
    <div>
      {/* <div className="d-flex justify-content-between mb-3">
        <Button
          variant="primary"
          style={{ borderRadius: "3px" }}
          onClick={handleShowModal}
        >
          Set Availability
        </Button>
      </div> */}
      {/* <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleEventClick}
        onNavigate={handleNavigate}
      /> */}

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleEventClick}
          onNavigate={handleNavigate}
          onView={() => {}}
          eventPropGetter={(event, start, end, isSelected) => {
            let eventStyle = {
              backgroundColor: "",
              color: "",
            };

            if (
              event.slot_status === "UNAVAILABLE" &&
              event.slot_reservation_type === "USER"
            ) {
              eventStyle.backgroundColor = "gray";
            }

            if (
              event.slot_status === "AVAILABLE" &&
              event.slot_reservation_type === "USER"
            ) {
              eventStyle.backgroundColor = "#5BFF33";
            }

            if (
              event.slot_status === "UNAVAILABLE" &&
              event.slot_reservation_type === "BOOKING"
            ) {
              eventStyle.backgroundColor = "#329bad";
            }

            if (
              event.slot_status === "UNAVAILABLE" &&
              event.slot_reservation_type === "DIRECT_BOOKING"
            ) {
              eventStyle.backgroundColor = "#309A57";
            }

            return {
              style: eventStyle,
            };
          }}
          eventStyleGetter={(event, start, end, isSelected) => {
            return {
              style: {
                fontSize: "13px",
              },
            };
          }}
          components={{
            event: ({ event }) => (
              <div style={{ fontSize: "13px" }}>{event.title}</div>
            ),
          }}
        />
      )}
      {/* <Calendar
        localizer={localizer}
        events={allEvents.map((event) => ({
          id: event._id,
          title: event.title || event.name,
          start: new Date(event.start_date),
          end: new Date(event.end_date),
          slot_status: event.slot_status,
          slot_reservation_type: event.slot_reservation_type,
          opportunity_id: event.opportunity_id,
        }))}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleEventClick}
        onNavigate={handleNavigate}
      /> */}

      <Modal show={showModal2} onHide={handleCloseModal2} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Opportunity Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CalendarSelfView;
