import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import "../performer/CreatePerformer.css";
import "../OtherPages/LandingProfiles.css";

function UpdateVenueType() {
  const profileId = Cookies.get("profileId");
  const [types, setTypes] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);
  const typesInputRef = useRef(null);
  const navigate = useNavigate();

  // const handleTypeChange = (event) => {
  //   const checked = event.target.checked;
  //   const value = event.target.value;

  //   if (checked) {
  //     // Add type if not already selected
  //     if (!types.includes(value)) {
  //       setTypes([...types, value]);
  //     }
  //   } else {
  //     setTypes(types.filter((type) => type !== value));
  //   }
  // };

  const handleTypeChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (types.length < 3) {
        setTypes([...types, value]);
      } else {
        setErrorMessage("You can only select a maximum of 3 performer types.");
      }
    } else {
      setTypes(types.filter((type) => type !== value));
    }
  };

  useEffect(() => {
    async function fetchVenueTypes() {
      try {
        const response = await axiosInstance.get("/v1/venue/venue-types", {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });

        if (response.status === 200) {
          setAvailableTypes(response.data.venue_types);
        } else {
          setErrorMessage("Error fetching venue types.");
        }
      } catch (error) {
        console.error("Error fetching venue types:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchVenueTypes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/venue-types",
        {
          venue_id: profileId,
          venue_types: types,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("venue types updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "venue types updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/venue/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating venue types.");
        console.error("Error updating venue types:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating venue types:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  const handleTypesInputClick = () => {
    setShowTypesDropdown(!showTypesDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      typesInputRef.current &&
      !typesInputRef.current.contains(event.target)
    ) {
      setShowTypesDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update venue Types</h5>

                <div className="mb-3">
                  <div className="dropdown-scrollable" ref={typesInputRef}>
                    <input
                      type="text"
                      className="form-control"
                      id="types"
                      name="types"
                      onFocus={handleTypesInputClick}
                      placeholder="Select Performer Types"
                      readOnly
                    />
                    {showTypesDropdown && (
                      <div className="dropdown-content">
                        {availableTypes.length > 0 ? (
                          availableTypes.map((type) => (
                            <div className="form-check" key={type}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`type-${type}`}
                                name="types"
                                value={type}
                                checked={types.includes(type)}
                                onChange={handleTypeChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`type-${type}`}
                              >
                                {type}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>Loading performer types...</p>
                        )}
                      </div>
                    )}
                  </div>

                  {/* {availableTypes.map((type) => (
                    <div key={type}>
                      <input
                        type="checkbox"
                        id={`type-${type}`}
                        name="types"
                        value={type}
                        checked={types.includes(type)}
                        onChange={(event) => handleTypeChange(event)}
                      />
                      <label htmlFor={`type-${type}`}>{type}</label>
                      <br />
                    </div>
                  ))} */}
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdateVenueType;
