import styled from "styled-components";
// import OpportunityImage from "../assect/images/OpportunityImage2.jpg";
// import payment1 from "../assect/images/payment/visa.png";
// import payment2 from "../assect/images/payment/card.png";
// import payment3 from "../assect/images/payment/discover.png";
// import payment4 from "../assect/images/payment/4.svg";
// import profileImage from "../../assect/images/client/02.jpg";
import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 10px;
  margin-right: 70px;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  margin-left: 70px;
  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const NewOpportunityVenueView = ({ opportunityId, profileId }) => {
  const [activeTab, setActiveTab] = React.useState("courseInfo");
  const [opportunity, setOpportunity] = useState(null);
  const accessToken = Cookies.get("accessToken");
  // const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId; //|| Cookies.get("opportunityId");
  const [venue, setVenue] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        const fetchedOpportunity = response.data.opportunity;
        fetchVenueDetails(fetchedOpportunity.author_profile_id);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    const fetchVenueDetails = async (venueId) => {
      try {
        const venueSection = "info";
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${venueId}/section/${venueSection}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("venue info:", response.data.venue_info.profile_name);
          setVenue(response.data.venue_info);
        } else {
          console.error("Error fetching venue details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching venue details:", error);
      }
    };

    fetchOpportunity();
  }, [selectedOpportunityId, accessToken]);

  const getVenueProfileImage = () => {
    return venue?.thumbnail_profile_pic_url || venue?.profile_picture_url;
  };

  const handleApplyButtonClick = () => {
    const toProfileId = opportunity.author_profile_id;
    console.log("opp auther profile id: " + toProfileId);
    Cookies.set("toProfileId", toProfileId);
    // Cookies.set("opportunityId", opportunityId);
    navigate("/messaging");
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };
  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      {opportunity && (
        <>
          <LeftColumn>
            <CategoryTag>{opportunity.status}</CategoryTag>
            <Title>{opportunity?.title}</Title>
            <InfoRow>
              <Info>
                <ProfileImage
                  src={getVenueProfileImage()}
                  alt="Venue Profile"
                />
              </Info>
              <Info>
                Venue:{"  "}
                <strong>{venue ? venue.profile_name : "Loading..."}</strong>
              </Info>
              {/* <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info> */}
            </InfoRow>

            {/* <Tabs>
          <Tab
            active={activeTab === "opportunityInfo"}
            onClick={() => setActiveTab("opportunityInfo")}
          >
            Opportunity Info
          </Tab>
          <Tab
            active={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Reviews
          </Tab>
        </Tabs> */}
            <Content>
              <About>
                <h3>Description</h3>
                <p>{opportunity.descriptions}</p>
                {/* <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox> */}
              </About>
              {/* {activeTab === "opportunityInfo" && (
            <>
              
            </>
          )} */}
              {/* {activeTab === "media" && <div>Reviews content goes here...</div>} */}
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {/* <EnrollButton>I am interested</EnrollButton> */}
              <br></br>
              <Stats>
                <DateContainer>
                  <span style={{ marginBottom: "18px" }}>
                    Price:{" "}
                    <DateText>
                      AUD {opportunity.price_point_value_from}-
                      {opportunity.price_point_value_to}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    Location:{" "}
                    <DateText>{opportunity.location.address}</DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(opportunity.from_date)}
                      {"   "}
                      {formatTime(opportunity.from_time)}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    To: {"  "}
                    <DateText>
                      {formatDate(opportunity.to_date)}
                      {"   "}
                      {formatTime(opportunity.to_time)}
                    </DateText>
                  </span>
                  {/* <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(opportunity.from_date)}
                      {"   "}
                      {formatTime(opportunity.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(opportunity.to_date)}
                      {"   "}
                      {formatTime(opportunity.to_time)}
                    </DateText>
                  </span> */}
                  <span>
                    Status: <DateText>{opportunity.status}</DateText>
                  </span>
                </DateContainer>
              </Stats>

              <TagContainer>
                <h6>Opportunity Types:</h6>
                {opportunity.opportunity_types.map((genre) => (
                  <TagBox key={genre}>{genre}</TagBox>
                ))}
              </TagContainer>

              <TagContainer>
                <h6>Opportunity genres:</h6>
                {opportunity.opportunity_genres.map((type) => (
                  <TagBox key={type}>{type}</TagBox>
                ))}
              </TagContainer>
            </SideCard>
          </RightColumn>
        </>
      )}
    </Container>
  );
};

export default NewOpportunityVenueView;
