import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGuitar,
  faChevronDown,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 100px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 120px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "20px" : "40px")};
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const SubTitle = styled.p`
  margin-bottom: 35px;
  color: #666;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;

  &.rotated {
    transform: translateY(-50%) rotate(180deg);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ResendLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const SignInLink = styled.p`
  display: block;
  margin-top: 20px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function UpdatePerformerType() {
  const profileId = Cookies.get("profileOwnerId");
  const [types, setTypes] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);
  const typesInputRef = useRef(null);
  const navigate = useNavigate();

  // Hardcoded performer types
  const availableTypes = [
    "New",
    "Emerging",
    "Amateur",
    "Professional",
    "Band",
    "Entertainer",
    "Individual Performer",
    "Duo",
    "Trio",
    "Singer",
    "Vocalist",
  ];

  const handleTypeChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (types.length < 3) {
        setTypes([...types, value]);
      } else {
        setErrorMessage("You can only select a maximum of 3 performer types.");
      }
    } else {
      setTypes(types.filter((type) => type !== value));
    }
  };

  // useEffect(() => {
  //     async function fetchPerformerTypes() {
  //         try {
  //             const response = await axiosInstance.get(
  //                 "/v1/performer/performer-types",
  //                 {
  //                     headers: {
  //                         Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //                     },
  //                 }
  //             );

  //             if (response.status === 200) {
  //                 setAvailableTypes(response.data.performer_types);
  //             } else {
  //                 setErrorMessage("Error fetching performer types.");
  //             }
  //         } catch (error) {
  //             console.error("Error fetching performer types:", error);
  //             setErrorMessage("An unexpected error occurred. Please try again.");
  //         }
  //     }

  //     fetchPerformerTypes();
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/performer-types",
        {
          performer_id: profileId,
          performer_types: types,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage("Performer types have been updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "Performer types have been updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage(
          "An error occurred while updating performer types. Please try again."
        );
        console.error("Error updating performer types:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer types:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTypesInputClick = () => {
    setShowTypesDropdown(!showTypesDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      typesInputRef.current &&
      !typesInputRef.current.contains(event.target)
    ) {
      setShowTypesDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Types</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        <InputGroup>
          <Label htmlFor="types">Types</Label>
          <InputWrapper>
            <Icon icon={faHeadphones} />
            <Input
              type="text"
              className="form-control"
              id="types"
              name="types"
              placeholder="Select up to 3 Types"
              value={types.join(", ")} // Display selected genres
              onFocus={handleTypesInputClick}
              readOnly
            />
            <DropdownIcon
              icon={faChevronDown}
              className={showTypesDropdown ? "rotated" : ""}
              onClick={() => setShowTypesDropdown(!showTypesDropdown)}
            />
          </InputWrapper>
          {showTypesDropdown && (
            <div className="dropdown-content">
              {availableTypes.length > 0 ? (
                availableTypes.map((type) => (
                  <div className="form-check" key={type}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`type-${type}`}
                      name="types"
                      value={type}
                      checked={types.includes(type)}
                      onChange={handleTypeChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`type-${type}`}
                    >
                      {type}
                    </label>
                  </div>
                ))
              ) : (
                <p>Loading types...</p>
              )}
            </div>
          )}
        </InputGroup>
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>

        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
          </div>
        )}
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerType;
