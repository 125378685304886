import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Modal, Badge, Row } from "react-bootstrap";
import { FaTrash, FaUpload } from "react-icons/fa";
import axiosInstance from "../auth/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import NewOpportunityAuthorVenueView from "../newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../newView/NewOpportunityPerformerView";
import NewOpportunityVenueView from "../newView/NewOpportunityVenueView";
import Swal from "sweetalert2";
import OpportunityDateTime from "./OpportunityDateTime";

function ViewOpportunities() {
  const [deleteOpportunityId, setDeleteOpportunityId] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const profileId = Cookies.get("profileOwnerId");
  const [opportunities, setopportunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [error, setError] = useState(null);
  const loggedInUserId = Cookies.get("userId");
  const [venueData, setVenueData] = useState({});
  const { opportunityId } = useParams();
  const [opportunity, setopportunity] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [showOpportunityDatetimeModal, setShowOpportunityDatetimeModal] =
    useState(false);
  const [newOpportunityCreated, setNewOpportunityCreated] = useState(false);

  const handleAddNewOpportunityClose = () => {
    setShowOpportunityDatetimeModal(false);
  };

  const handleAddNewOpportunity = () => {
    setShowOpportunityDatetimeModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileIdFromCookies = Cookies.get("profileId");

      if (!profileId) {
        setError("venue ID not found in URL parameters.");
        setLoading(false);
        return;
      }

      // Cookies.set("profileId", profileId);

      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          setVenueData(data);
          setError(null);
        } else {
          setError(response.data.error || "Error fetching venue profile");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profileId]);

  useEffect(() => {
    const fetchopportunities = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/published/by/profile-owner/${profileId}`,

          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response.status === 200) {
          const data = response.data;
          console.log(
            "opportunity details: ",
            data.opportunities_published[0].location.address
          );
          // setopportunities(
          //   data.opportunities_published.filter(
          //     (opportunity) => opportunity.author_profile_id === profileId
          //   )
          // );

          const filteredOpportunities = data.opportunities_published.filter(
            (opportunity) => opportunity.author_profile_id === profileId
          );

          const sortedOpportunities = filteredOpportunities.sort((a, b) => {
            const dateA = new Date(
              a.from_date.year,
              a.from_date.month - 1,
              a.from_date.day
            );
            const dateB = new Date(
              b.from_date.year,
              b.from_date.month - 1,
              b.from_date.day
            );
            return dateA - dateB;
          });

          setopportunities(sortedOpportunities);
        } else {
          console.error("Error fetching opportunities:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      } finally {
        setLoading(false);
      }
    };

    if (profileId || newOpportunityCreated) {
      fetchopportunities();
    }
  }, [profileId, newOpportunityCreated]);

  const handleopportunityClick = (opportunityId) => {
    Cookies.set("opportunityId", opportunityId);
    console.log("Clicked opportunityId:", opportunityId);
    navigate(`/opportunity/${opportunityId}`);
  };

  // const handleopportunityClick = (opportunityId) => {
  //   if (opportunity) {
  //     Cookies.set("opportunityId", opportunityId);
  //     navigate(`/opportunity/${opportunityId}`);
  //   }
  // };

  // Check if logged-in user matches profile owner

  const handleViewMore = () => {
    setViewableCount(opportunities.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  const navigateopportunity = () => {
    navigate("/add-new-oppurtunity");
  };

  const handleDeleteClick = (id) => {
    setDeleteOpportunityId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/opportunity/delete-opportunity/${deleteTarget}`
      );
      if (response.status === 200) {
        setopportunities((prevopportunities) =>
          prevopportunities.filter(
            (opportunity) => opportunity._id !== deleteTarget
          )
        );
      } else {
        console.error("Error deleting opportunity:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting opportunity:", error);
      let errorMessage = "There was an error deleting opportunity.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleOpportunityClick = (opportunityId) => {
    Cookies.set("opportunityId", opportunityId);
    console.log("Clicked opportunityId:", opportunityId);
    navigate(`/venue/opportunity/author/${opportunityId}`);
  };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    // Cookies.set("opportunityId", id);
    // console.log("opportunityId cookie value:", Cookies.get("opportunityId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
            onDeleteSuccess={() => {
              setShowModal(false);
              window.location.reload();
            }}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(profileId);
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
              onOtherAction={() => {
                setShowModal(false);
              }}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
      let errorMessage = "There was an error fetching opportunity details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedOpportunityId(null);
    setShowModal(false);
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  // const showFirstModal = () => setShowOpportunityDatetimeModal(true);

  const closeFirstModal = () => setShowOpportunityDatetimeModal(false);

  const onNewOpportunityCreated = (newOpportunity) => {
    setopportunities((prevOpportunities) => [
      newOpportunity,
      ...prevOpportunities,
    ]);
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>
          <h4>Opportunities</h4>
        </strong>

        {/* {venueData.venue_info &&
          venueData.venue_info.user_id === loggedInUserId && ( */}
        <Button
          variant="outline-primary"
          onClick={handleAddNewOpportunity}
          style={{
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            fontSize: "15px",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
            backgroundColor: "#418abb",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff66c4")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#418abb")}
        >
          <FaUpload style={{ marginRight: "5px" }} />
          Add New opportunity
        </Button>
      </center>
      <br></br>

      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading opportunities...</span>
        </div>
      ) : (
        <Row>
          {opportunities.length > 0 ? (
            <>
              {opportunities.slice(0, viewableCount).map((opportunity) => (
                <div
                  key={opportunity._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  // onClick={() => handleOpportunityClick(opportunity._id)}
                  onClick={() =>
                    openModal(opportunity._id, opportunity.author_profile_id)
                  }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{opportunity.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{opportunity.short_description}</p>
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Location:</strong>{" "}
                          {opportunity.location.address}
                        </div> */}
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(opportunity.from_date)}
                          {"   "}
                          {formatTime(opportunity.from_time)}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(opportunity.to_date)}
                          {"   "}
                          {formatTime(opportunity.to_time)}
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Date:</strong>
                          
                          {typeof opportunity.from_date === "object" &&
                          opportunity.from_date &&
                          typeof opportunity.from_date.year === "number" &&
                          typeof opportunity.from_date.month === "number" &&
                          typeof opportunity.from_date.day === "number" &&
                          opportunity.from_date.year > 0 &&
                          opportunity.from_date.month >= 1 &&
                          opportunity.from_date.month <= 12 &&
                          opportunity.from_date.day >= 1 &&
                          opportunity.from_date.day <= 31
                            ? new Date(
                                opportunity.from_date.year,
                                opportunity.from_date.month - 1,
                                opportunity.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : typeof opportunity.from_date === "string"
                            ? opportunity.from_date
                            : ""}
                          
                        </div>
                        <div className="events__details-inline">
                          <strong>Time:</strong>
                          
                          {opportunity.from_time &&
                          typeof opportunity.from_time === "object"
                            ? `${opportunity.from_time.hour}:${opportunity.from_time.minute} ${opportunity.from_time.am_pm}`
                            : opportunity.from_time}
                        </div> */}

                        {/* {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <Button
                              style={{
                                backgroundColor: "#151a33",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(opportunity._id);
                              }}
                            >
                              <BsTrash />
                            </Button>
                          )} */}
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          // onClick={() =>
                          //   handleopportunityClick(opportunity._id)
                          // }
                          onClick={() =>
                            openModal(
                              opportunity._id,
                              opportunity.author_profile_id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                  <button
                    style={{
                      position: "absolute",
                      // top: "10px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDeleteModal(false)}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this opportunity?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={handleDeleteConfirm}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
              {viewableCount < opportunities.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({opportunities.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === opportunities.length &&
                opportunities.length > 2 && (
                  <center>
                    <Button
                      variant="link"
                      onClick={handleViewLess}
                      style={{ color: "#418abb" }}
                    >
                      View Less
                    </Button>
                  </center>
                )}
            </>
          ) : (
            <p className="text-center">No opportunities found.</p>
          )}
        </Row>
        // <div>
        //   {opportunities.length > 0 ? (
        //     <>
        //       {opportunities.slice(0, viewableCount).map((opportunity) => (
        //         <Card
        //           key={opportunity._id}
        //           className="mb-3"
        //           onClick={() => handleOpportunityClick(opportunity._id)}
        //           style={{
        //             backgroundColor: "#e8e8e8",
        //             borderRadius: "15px",
        //             cursor: "pointer",
        //           }}
        //         >
        //           <span className="badge bg-danger rounded-pill position-absolute top-0 end-0 m-2">
        //             Urgent
        //           </span>
        //           <Card.Body>
        //             <h4>
        //               <strong style={{ fontWeight: "bold" }}>
        //                 {opportunity.title}
        //               </strong>
        //             </h4>
        //             <p>{opportunity.short_description}</p>
        //             <p>
        //               <strong>Location:</strong> {opportunity.location.suburb}
        //             </p>
        //             {/* <p>
        //               <strong>Date:</strong>
        //               {typeof opportunity.from_date === "object"
        //                 ? new Date(
        //                     opportunity.from_date.year,
        //                     opportunity.from_date.month - 1,
        //                     opportunity.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : opportunity.from_date}
        //             </p> */}
        //             <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof opportunity.from_date === "object" &&
        //               opportunity.from_date &&
        //               typeof opportunity.from_date.year === "number" &&
        //               typeof opportunity.from_date.month === "number" &&
        //               typeof opportunity.from_date.day === "number" &&
        //               opportunity.from_date.year > 0 &&
        //               opportunity.from_date.month >= 1 &&
        //               opportunity.from_date.month <= 12 &&
        //               opportunity.from_date.day >= 1 &&
        //               opportunity.from_date.day <= 31
        //                 ? new Date(
        //                     opportunity.from_date.year,
        //                     opportunity.from_date.month - 1,
        //                     opportunity.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : typeof opportunity.from_date === "string"
        //                 ? opportunity.from_date
        //                 : ""}
        //             </p>

        //             <p>
        //               <strong>Time:</strong>{" "}
        //               {typeof opportunity.from_time === "object"
        //                 ? `${opportunity.from_time.hour}:${opportunity.from_time.minute} ${opportunity.from_time.am_pm}`
        //                 : opportunity.from_time}
        //             </p>
        //             {venueData.venue_info &&
        //               venueData.venue_info.user_id === loggedInUserId && (
        //                 <Button
        //                   style={{
        //                     backgroundColor: "#151a33",
        //                     zIndex: 1,
        //                   }}
        //                   onClick={() => handleDeleteClick(opportunity._id)}
        //                 >
        //                   <BsTrash />
        //                 </Button>
        //               )}
        //           </Card.Body>

        //           <Modal
        //             show={showDeleteModal}
        //             onHide={() => setShowDeleteModal(false)}
        //           >
        //             <Modal.Header closeButton>
        //               <Modal.Title>Confirm Deletion</Modal.Title>
        //             </Modal.Header>
        //             <Modal.Body>
        //               Are you sure you want to delete this opportunity?
        //             </Modal.Body>
        //             <Modal.Footer>
        //               <Button
        //                 variant="primary"
        //                 onClick={() => setShowDeleteModal(false)}
        //               >
        //                 Cancel
        //               </Button>
        //               <Button variant="danger" onClick={handleDeleteConfirm}>
        //                 Delete
        //               </Button>
        //             </Modal.Footer>
        //           </Modal>
        //         </Card>
        //       ))}
        //       {viewableCount < opportunities.length && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewMore}
        //             style={{ color: "#418abb" }}
        //           >
        //             View More ({opportunities.length - viewableCount} more)
        //           </Button>
        //         </center>
        //       )}
        //       {viewableCount === opportunities.length &&
        //         opportunities.length > 2 && (
        //           <center>
        //             <Button
        //               variant="link"
        //               onClick={handleViewLess}
        //               style={{ color: "#418abb" }}
        //             >
        //               View Less
        //             </Button>
        //           </center>
        //         )}
        //     </>
        //   ) : (
        //     <p className="text-center">No opportunities found.</p>
        //   )}
        // </div>
      )}
      {/* Delete confirmation modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirm Deletion</Modal.Title>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setShowDeleteModal(false)}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this opportunity?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ width: "150% !important", alignSelf: "center" }}
        // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showOpportunityDatetimeModal}
        onHide={() => setShowOpportunityDatetimeModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Set Opportunity Date and Time</Modal.Title>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setShowOpportunityDatetimeModal(false)}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <OpportunityDateTime
            setNewOpportunityCreated={setNewOpportunityCreated}
            // showFirstModal={showFirstModal}
            closeFirstModal={closeFirstModal}
            // onClose={handleAddNewOpportunityClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewOpportunities;
