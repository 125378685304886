import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import AutoComplete from "../../components/autoComplete";
import "../OtherPages/LandingProfiles.css";

function UpdatePerformerLocation() {
  const profileId = Cookies.get("profileId");
  const [location, setLocation] = useState({
    suburb: "",
    zipcode: "",
    latitude: 0,
    longitude: 0,
    location_name: "",
    address: "",
    google_map_pin: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     async function fetchPerformerLocation() {
  //       try {
  //         const response = await axiosInstance.get("/v1/performer/profile", {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         });

  //         if (response.status === 200) {
  //           const { location } = response.data;
  //           setLocation(location);
  //         } else {
  //           setErrorMessage("Error fetching performer profile data.");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching performer profile:", error);
  //         setErrorMessage("An unexpected error occurred. Please try again.");
  //       }
  //     }

  //     fetchPerformerLocation();
  //   }, []);

  const handleSuburbSelect = (suburbData) => {
    setLocation({
      suburb: suburbData.suburb,
      zipcode: suburbData.zipcode,
      latitude: suburbData.latitude,
      longitude: suburbData.longitude,
      location_name: suburbData.location_name,
      address: suburbData.address,
      google_map_pin: suburbData.google_map_pin,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/location",
        {
          performer_id: Cookies.get("profileId"),
          location: location,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("Performer location updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "Performer location updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating performer location.");
        console.error("Error updating performer location:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer location:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={handleSubmit}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update Performer Location</h5>

                <div className="mb-3">
                  <AutoComplete onSuburbSelect={handleSuburbSelect} />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Update Location"
                  )}
                </button>

                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success mt-3" role="alert">
                    {successMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePerformerLocation;
