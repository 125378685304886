import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner, Row, Col, Card } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import CustomTimePicker from "../../components/CustomTimePicker";
// import AddNewOpportunity from "./addNewOppurtunity";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import PropTypes from "prop-types";
import "../opportunities/OpportunityDateTime.css";

import moment from "moment";
import DirectBookingPerformerDateTime from "./directBookingPerformerDateTime";

const DirectBookingVenueDateTime = ({ profileId, closeFirstModal }) => {
  const [timeError, setTimeError] = useState("");
  const [fromDisplayTime, setFromDisplayTime] = useState(null);
  const [toDisplayTime, setToDisplayTime] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const [fromDisplayTime2, setFromDisplayTime2] = useState(null);
  const [toDisplayTime2, setToDisplayTime2] = useState(null);
  const [fromTime2, setFromTime2] = useState(null);
  const [toTime2, setToTime2] = useState(null);

  const [showDirectBookingModalPerformer, setShowDirectBookingModalPerformer] =
    useState(false);

  const handledirectbookingClose = () => {
    setShowDirectBookingModalPerformer(false);
  };

  const handledirectbooking = () => {
    setShowDirectBookingModalPerformer(true);
  };

  const closeSecondModal = () => setShowDirectBookingModalPerformer(false);

  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");

  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };

  // const validToDate = function (current) {
  //   return (
  //     current.isAfter(moment(fromDate).subtract(1, "day")) &&
  //     !current.isAfter(lastday)
  //   );
  // };

  const validToDate = function (current) {
    if (!fromDate) return false;

    const fromDateMoment = moment(fromDate);
    const nextDay = moment(fromDate).add(1, "day");

    return (
      current.isSame(fromDateMoment, "day") || current.isSame(nextDay, "day")
    );
  };

  const convertToMoment = (timeObj, dateObj) => {
    const { hour, minute, am_pm } = timeObj;

    // Convert hour based on AM/PM
    let hour24 = hour;
    if (am_pm === "PM" && hour !== 12) {
      hour24 += 12;
    } else if (am_pm === "AM" && hour === 12) {
      hour24 = 0;
    }

    // Create a moment object with the current date and specified time
    const now = moment();
    const dateTime = moment({
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      date: dateObj.getDate(),
      hour: hour24,
      minute: minute,
      second: 0,
      millisecond: 0,
    });

    return dateTime;
  };

  // const closeModal = () => {
  //   setShowNextModal(false);
  //
  // };

  const closeModal = () => {
    setShowNextModal(false);
    if (typeof onClose === "function") {
      // Call the onClose function if it is defined and a function
    } else {
      console.warn("onClose is not a function");
    }
  };

  const handleFetchEvents = async (date) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/calendar/get-events/by-day/self",
        {
          profile_id: Cookies.get("profileOwnerId"),
          year: new Date(date).getFullYear(),
          month: new Date(date).getMonth() + 1,
          day: new Date(date).getDate(),
        }
      );
      console.log();
      setEvents(response.data.calendar_events || []);
      setShowEventsModal(true);
    } catch (error) {
      console.error("Error fetching events:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch events",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFromTime = (e) => {
    const { name, value } = e.target;
    // setFromTime(value);
    setFromTime(moment(value));
  };

  // const handleChangeToTime = (e) => {
  //   const { name, value } = e.target;
  //   // setToTime(value);
  //   setToTime(moment(value));
  // };

  const handleChangeToTime = (e) => {
    const { name, value } = e.target;
    setToTime(value);
    console.log("To time: ", value);

    // New validation
    const fromDateTime = moment(fromDate).set({
      hour: moment(fromTime).hour(),
      minute: moment(fromTime).minute(),
    });
    const toDateTime = moment(toDate).set({
      hour: moment(value).hour(),
      minute: moment(value).minute(),
    });

    const maxDuration = 10 * 60 * 60 * 1000; // 10 hours in milliseconds

    // // Convert fromDate and toDate to Date objects with time included
    // const fromDateTime = moment(`${fromDate}T${fromTime}`);
    // const toDateTime = moment(`${toDate}T${value}`);

    // console.log("From DateTime:", fromDateTime.format());
    // console.log("To DateTime:", toDateTime.format());

    // // Calculate the difference in hours
    // const hourDifference = toDateTime.diff(fromDateTime, 'hour', true); // true gives floating point number

    // console.log("Hour Difference:", hourDifference);

    if (
      moment(fromDate).isSame(toDate, "day") &&
      moment(value).isBefore(moment(fromTime))
    ) {
      setTimeError(
        "The 'To Time' cannot be before the 'From Time' on the same day."
      );
    } else if (toDateTime.diff(fromDateTime) > maxDuration) {
      setTimeError(
        "The 'To Date/Time' must be within 10 hours of the 'From Date/Time'."
      );
    } else {
      setTimeError("");
    }
  };

  // const handleChangeToTime = (e) => {
  //   const { name, value } = e.target;
  //   setToTime(value);

  //   if (
  //     moment(fromDate).isSame(toDate, "day") &&
  //     moment(value).isBefore(moment(fromTime))
  //   ) {
  //     setTimeError(
  //       "The 'To Time' cannot be before the 'From Time' on the same day."
  //     );
  //   } else {
  //     setTimeError("");
  //   }
  // };

  //   const handleNext = () => {
  //     setDateTime({ fromDate, toDate, fromTime, toTime });
  //     onNext();
  //   };

  const convertTo12HourFormat = (hr, min) => {
    const isPM = hr >= 12;
    const hour = hr % 12 || 12;
    const minute = min % 30 === 0 ? min : 0;
    const am_pm = isPM ? "PM" : "AM";
    return { hour, minute, am_pm };
  };

  const handleFromDateChange = (e) => {
    const date = e.target.value;
    // const fromDateTime = new Date(`${date}`);
    // const fromTime = convertTo12HourFormat(
    //   fromDateTime.getHours(),
    //   fromDateTime.getMinutes()
    // );
    setFromDate(new Date(date));
    handleFetchEvents(date);
  };

  const handleToDateChange = (e) => {
    const date = e.target.value;
    // const toDateTime = new Date(`${date}`);
    setToDate(new Date(date));
    handleFetchEvents(date);
  };

  // const handleFromTimeChange = (e) => {
  //   const date = e.target.value;
  //   console.log(date.hour());
  //   const fromTime = convertTo12HourFormat(date.hour(), date.minute());
  //   setFromTime(fromTime);
  // };

  // const handleToTimeChange = (e) => {
  //   const date = e.target.value;
  //   // const fromDateTime = new Date(`${date}`);
  //   const fromTime = convertTo12HourFormat(date.getHours(), date.getMinutes());
  //   setToTime(fromTime);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fromDate || !fromTime || !toDate || !toTime) {
      alert("Please fill in all fields before proceeding.");
      return;
    }

    setLoading(true);

    // let frTime;
    // let tTime;

    // const convertToMoment = (timeObj, dateObj) => {
    //   const { hour, minute, am_pm } = timeObj;

    //   // Convert hour based on AM/PM
    //   let hour24 = hour;
    //   if (am_pm === "PM" && hour !== 12) {
    //     hour24 += 12;
    //   } else if (am_pm === "AM" && hour === 12) {
    //     hour24 = 0;
    //   }

    //   // Create a moment object with the current date and specified time
    //   const now = moment();
    //   const dateTime = moment({
    //     year: dateObj.getFullYear(),
    //     month: dateObj.getMonth() + 1,
    //     date: dateObj.getDate(),
    //     hour: hour24,
    //     minute: minute,
    //     second: 0,
    //     millisecond: 0,
    //   });

    //   return dateTime;
    // };

    // if (moment.isMoment(fromTime) || moment.isMoment(toTime)) {
    //   frTime = convertTo12HourFormat(fromTime.hour(), fromTime.minute());
    //   tTime = convertTo12HourFormat(toTime.hour(), toTime.minute());
    // } else {
    //   const momentFromTime = convertToMoment(fromTime, fromDate);
    //   const momentToTime = convertToMoment(toTime, toDate);
    //   frTime = convertTo12HourFormat(
    //     momentFromTime.hour(),
    //     momentFromTime.minute()
    //   );
    //   tTime = convertTo12HourFormat(momentToTime.hour(), momentToTime.minute());
    // }
    // setFromTime(frTime);
    // setToTime(tTime);

    // const payload = {
    //   profile_type: "VENUE",
    //   profile_id: Cookies.get("profileOwnerId"),
    //   from_date: {
    //     year: new Date(fromDate).getFullYear(),
    //     month: new Date(fromDate).getMonth() + 1,
    //     day: new Date(fromDate).getDate(),
    //   },
    //   to_date: {
    //     year: new Date(toDate).getFullYear(),
    //     month: new Date(toDate).getMonth() + 1,
    //     day: new Date(toDate).getDate(),
    //   },
    //   from_time: {
    //     hour: frTime.hour,
    //     minute: frTime.minute,
    //     am_pm: frTime.am_pm,
    //   },
    //   to_time: {
    //     hour: tTime.hour,
    //     minute: tTime.minute,
    //     am_pm: tTime.am_pm,
    //   },
    // };

    // console.log(payload);

    let frTime;
    let tTime;

    if (moment.isMoment(fromTime)) {
      frTime = fromTime;
    } else {
      frTime = convertToMoment(fromTime, fromDate);
    }

    if (moment.isMoment(toTime)) {
      tTime = toTime;
    } else {
      tTime = convertToMoment(toTime, toDate);
    }

    const fromTime12 = convertTo12HourFormat(frTime.hour(), frTime.minute());
    const toTime12 = convertTo12HourFormat(tTime.hour(), tTime.minute());

    const padZero = (num) => num.toString().padStart(2, "0");

    const fromTimeString = `${fromTime12.hour} : ${padZero(
      fromTime12.minute
    )} ${fromTime12.am_pm}`;
    const toTimeString = `${toTime12.hour} : ${padZero(toTime12.minute)} ${
      toTime12.am_pm
    }`;

    setFromDisplayTime2(fromTimeString);
    setToDisplayTime2(toTimeString);
    setFromTime2(fromTime12);
    setToTime2(toTime12);

    // setFromTime(fromTime12);
    // setToTime(toTime12);

    const frisPM = frTime.hour() >= 12;
    const fram_pm = frisPM ? "PM" : "AM";

    const ftDisplay = `${frTime.hour()} : ${
      frTime.minute() == 0 ? "00" : frTime.minute()
    } : ${fram_pm}`;
    setFromDisplayTime(ftDisplay);

    const tisPM = frTime.hour() >= 12;
    const tam_pm = tisPM ? "PM" : "AM";

    const ttDisplay = `${frTime.hour()} : ${
      frTime.minute() == 0 ? "00" : frTime.minute()
    } : ${tam_pm}`;
    setToDisplayTime(ttDisplay);

    const payload = {
      profile_type: "VENUE",
      profile_id: Cookies.get("profileOwnerId"),
      from_date: {
        year: new Date(fromDate).getFullYear(),
        month: new Date(fromDate).getMonth() + 1,
        day: new Date(fromDate).getDate(),
      },
      to_date: {
        year: new Date(toDate).getFullYear(),
        month: new Date(toDate).getMonth() + 1,
        day: new Date(toDate).getDate(),
      },
      from_time: {
        hour: fromTime12.hour,
        minute: fromTime12.minute,
        am_pm: fromTime12.am_pm,
      },
      to_time: {
        hour: toTime12.hour,
        minute: toTime12.minute,
        am_pm: toTime12.am_pm,
      },
    };

    console.log(payload);

    try {
      const response = await axiosInstance.post(
        "/v1/calendar/check-conflict",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      // if (response.data.conflict) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Conflict",
      //     text: "There is a conflict with the selected dates and times.",
      //   });
      // } else {
      //   setShowDirectBookingModalPerformer(true);

      console.log(response.data);
      if (response.data.slot_availability === false) {
        Swal.fire({
          icon: "error",
          title: "Conflict",
          text:
            // response.data.collision_message ||
            "Your entered time slot is already taken by another event.",
        });
      } else {
        setShowDirectBookingModalPerformer(true);

        // closeFirstModal();
        //
        // setDateTime({ fromDate, fromTime, toDate, toTime });
        //
        // setDateTime({ fromDate, fromTime, toDate, toTime });
        // onSuccess();
      }
    } catch (error) {
      console.error("Error checking conflicts:", error);
      let errorMessage = "Failed to check for conflicts.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  // const onSuccess = () => {
  //   setShowNextModal(true);
  //
  // };

  // const handleSubmit = async () => {
  //   if (!fromDate || !fromTime || !toDate || !toTime) {
  //     alert("Please fill in all fields before proceeding.");
  //     return;
  //   }

  //   setDateTime({ fromDate, fromTime, toDate, toTime });
  // };

  // const handleNext = () => {
  //   if (!fromDate || !fromTime || !toDate || !toTime) {
  //     alert("Please fill in all fields before proceeding.");
  //     return;
  //   }

  //   setDateTime({ fromDate, fromTime, toDate, toTime });
  //   onNext();
  // };

  // const onSuccess = () => {
  //   // Handle successful submission, such as saving the date and time
  //   // Then open the next modal
  //   setShowNextModal(true);
  //    // Close the current modal
  // };

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const formatDateTime = (date) => {
    const localDate = convertUTCToLocal(new Date(date));
    // return localDate.toLocaleString("en-GB");
    const formattedDate = localDate.toLocaleDateString("en-GB"); // dd/mm/yyyy
    const formattedTime = localDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <Card
        style={{
          borderRadius: "5px",
          backgroundColor: "#f0f2f5",
          display: "flex",
          justifyContent: "center",
          // marginLeft: "5%",
          // marginRight: "5%",
          // marginTop: "3%",
          paddingLeft: "2%",
          paddingRight: "2%",
          paddingTop: "2%",
          paddingBottom: "1%",
          // backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Row>
          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <Datetime
                  inputProps={{ readOnly: true, placeholder: "Select Date" }}
                  className="form-control shadow-box input-style2"
                  // dateFormat={true}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(date) =>
                    handleFromDateChange({
                      target: { value: date, name: "fromDate" },
                    })
                  }
                  value={fromDate}
                  isValidDate={valid}
                />
              </Form.Group>
              <Form.Group controlId="fromTime">
                <Form.Label>From Time</Form.Label>
                <Datetime
                  inputProps={{ readOnly: true, placeholder: "Select Time" }}
                  className="form-control shadow-box input-style2"
                  dateFormat={false}
                  onChange={(date) =>
                    handleChangeFromTime({
                      target: { value: date, name: "fromTime" },
                    })
                  }
                  value={fromTime}
                  // value={fromDisplayTime}
                  timeConstraints={{
                    minutes: {
                      step: 30,
                    },
                  }}
                  isValidDate={valid}
                />
              </Form.Group>

              <br></br>
              <br></br>
              <Form.Group controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <Datetime
                  inputProps={{ readOnly: true, placeholder: "Select Date" }}
                  className="form-control shadow-box input-style2"
                  // dateFormat={true}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(date) =>
                    handleToDateChange({
                      target: { value: date, name: "toDate" },
                    })
                  }
                  value={toDate}
                  isValidDate={validToDate}
                  // isValidDate={valid}
                />
              </Form.Group>
              <Form.Group controlId="toTime">
                <Form.Label>To Time</Form.Label>
                <Datetime
                  inputProps={{ readOnly: true, placeholder: "Select Time" }}
                  className="form-control shadow-box input-style2"
                  dateFormat={false}
                  onChange={(date) =>
                    handleChangeToTime({
                      target: { value: date, name: "toTime" },
                    })
                  }
                  value={toTime}
                  // value={toDisplayTime}
                  timeConstraints={{
                    minutes: {
                      step: 30,
                    },
                  }}
                  isValidDate={valid}
                />
                {timeError && (
                  <div className="text-danger mt-2">{timeError}</div>
                )}
              </Form.Group>
              <div className="submit-button-container">
                <Button
                  style={{
                    borderRadius: "5px",
                    padding: "0.75rem 1.5rem",
                  }}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
            <br></br>
          </Col>
          <Col md={6}>
            <h3>Events</h3>
            {events.length > 0 ? (
              <ul>
                {events.map((event) => (
                  <>
                    <li
                      key={event._id}
                      style={{
                        color:
                          event.name === "[AVAILABLE]" ? "green" : "inherit",
                      }}
                    >
                      {event.name} - {formatDateTime(event.start_date)} to{" "}
                      {formatDateTime(event.end_date)}
                    </li>
                    <br></br>
                  </>
                  // <li key={event._id}>
                  //   {event.name} on {event.end_date}
                  // </li>
                ))}
              </ul>
            ) : (
              <p>No events found for the selected date.</p>
            )}
          </Col>
          {/* <div className="submit-button-container"> */}

          {/* </div> */}
        </Row>

        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
        {/* <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Next"}
        </Button> */}
      </Card>

      {/* <Modal show={showEventsModal} onHide={() => setShowEventsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {events && events.length ? ( // Check if events is defined and has length
            <ul>
              {events.map((event) => (
                <li key={event._id}>
                  {event.name} on {event.end_date}
                </li>
              ))}
            </ul>
          ) : (
            <p>No events found for the selected date.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        size="lg"
        show={showDirectBookingModalPerformer}
        onHide={() => setShowDirectBookingModalPerformer(false)}
      >
        <Modal.Header>
          <Modal.Title>
            Set Date and Time - check performer's availability
          </Modal.Title>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setShowDirectBookingModalPerformer(false)}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <DirectBookingPerformerDateTime
            profileId={profileId}
            // initialData={{ fromDate, fromTime, toDate, toTime }}
            initialData={{
              fromDate,
              fromTime2,
              toTime2,
              fromDisplayTime2,
              toDate,
              toDisplayTime2,
            }}
            closeFirstModal={closeFirstModal}
            closeSecondModal={closeSecondModal}
            // onClose={handleAddNewOpportunityClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

DirectBookingVenueDateTime.propTypes = {
  onClose: PropTypes.func, // Validate that onClose should be a function
};

export default DirectBookingVenueDateTime;
