import React, { useState, useEffect } from "react";
import { Card, Button, Badge } from "react-bootstrap";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import "./PerformerDashboard.css";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

const getStatusBadgeVariant = (status) => {
  switch (status) {
    case "PERFORMER_APPLIED":
      return "badge-primary";
    case "PERFORMER_DECLINED":
      return "badge-danger";
    case "VENUE_REJECTED":
      return "badge-danger";
    case "CONFIRMATION_REQUESTED":
      return "badge-warning";
    case "VENUE_WITHDRAWN":
      return "badge-warning";
    case "PERFORMER_DOUBLED_CONFIRMED":
      return "badge-success";
    default:
      return "badge-secondary";
  }
};

const PerformerDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const loggedInProfileId = Cookies.get("profileOwnerId");
  const location = useLocation();
  const { opportunityId } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      const payload = {
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/view-all/by/performer",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const sortedBookings = sortBookingsByDate(response.data.bookings);
          setBookings(sortedBookings);
        } else {
          console.error(
            "Error fetching bookings:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchBookings();
  }, [accessToken, loggedInProfileId]);

  const sortBookingsByDate = (bookings) => {
    return bookings.sort((booking1, booking2) => {
      const date1 = new Date(booking1.from_date_time_utc);
      const date2 = new Date(booking2.from_date_time_utc);
      return date2.getTime() - date1.getTime();
    });
  };

  const handleDoubleConfirm = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/double-confirm/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Double confirmed!", "success");
      } else {
        console.error(
          "Error confirming request:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to confirm request", "error");
      }
    } catch (error) {
      console.error("confirm Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleReject = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/decline/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Confirmation rejected!", "success");
      } else {
        console.error(
          "Error rejecting confirmation:",
          response.status,
          response.data
        );

        Swal.fire("Error", "Failed to reject confirmation", "error");
      }
    } catch (error) {
      console.error("reject confirmation error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleCancelContract = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-request/contract/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("contract cancellation request sent!", "success");
      } else {
        console.error(
          "Error cancelling the contract:",
          response.status,
          response.data
        );

        Swal.fire("Error", "Failed to cancel contract", "error");
      }
    } catch (error) {
      console.error("cancel contract error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleTitleClick = (opportunityId) => {
    navigate(`/performer/opportunity/${opportunityId}`);
  };

  return (
    <div className="dashboardcontainer my-4">
      <h2 className="text-center mb-4">Performer Dashboard</h2>
      <div className="row">
        {bookings.map((booking, index) => (
          <div className="col-12 mb-2" key={index}>
            <div
              className={`event-card shadow-lg d-flex justify-content-between align-items-center p-3 ${
                booking.opportunity_id === opportunityId ? "highlighted" : ""
              }`}
            >
              <div>
                <h5
                  className="mb-1 clickable-title"
                  onClick={() => handleTitleClick(booking.opportunity_id)}
                >
                  {booking.title}
                </h5>
                <p className="mb-1 text-muted">{booking.from_date_time_utc}</p>
                <p className="mb-0">
                  <strong>Venue:</strong> {booking.venue}
                </p>
              </div>
              <div>
                {booking.status === "VENUE_CONFIRMED" ? (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleDoubleConfirm(booking._id)}
                    >
                      Double Confirm
                    </Button>
                    <span
                      className={`badge ${getStatusBadgeVariant(
                        booking.status
                      )}`}
                    >
                      {booking.status.replace("_", " ")}
                    </span>
                    <Button
                      variant="danger"
                      className="ms-2"
                      onClick={() => handleReject(booking._id)}
                    >
                      Reject
                    </Button>
                  </>
                ) : booking.status === "PERFORMER_DOUBLED_CONFIRMED" ? (
                  <>
                    <span
                      className={`badge ${getStatusBadgeVariant(
                        booking.status
                      )}`}
                    >
                      {booking.status.replace("_", " ")}
                    </span>
                    <Button
                      variant="warning"
                      className="ms-2"
                      onClick={() => handleCancelContract(booking._id)}
                    >
                      Cancel Contract
                    </Button>
                  </>
                ) : (
                  <span
                    className={`badge ${getStatusBadgeVariant(booking.status)}`}
                  >
                    {booking.status.replace("_", " ")}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerformerDashboard;
