import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SkeletonElement = styled.div`
  background: #ddd;
  border-radius: 4px;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "16px"};
`;

const LoadingSkeleton = () => {
  return (
    <div className="profile-container" style={{ marginTop: "2%" }}>
      <div className="profile-header" style={{ display: "flex" }}>
        <div style={{ cursor: "pointer", zIndex: 1 }}>
          <Skeleton circle={true} height={100} width={100} />
        </div>
        <div className="profile-details" style={{ marginLeft: "20px" }}>
          <div className="profile-info">
            <h1 className="performerName">
              <Skeleton width={200} />
            </h1>
            <Skeleton width={150} height={20} style={{ marginTop: "10px" }} />
          </div>
          <div style={{ marginLeft: "22px", marginTop: "20px" }}>
            <Skeleton width={250} height={20} />
            <Skeleton width={150} height={20} style={{ marginTop: "10px" }} />
            <Skeleton width={200} height={20} style={{ marginTop: "10px" }} />
          </div>
          <div style={{ marginLeft: "22px", marginTop: "20px" }}>
            <Skeleton width={300} height={20} />
            <Skeleton width={150} height={20} style={{ marginTop: "10px" }} />
            <Skeleton width={200} height={20} style={{ marginTop: "10px" }} />
          </div>
        </div>
      </div>
      <div className="profile-card teacher__item2 transition-3 mb-30">
        <Skeleton height={300} />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
