import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Modal, Row } from "react-bootstrap";
import { FaTrash, FaUpload } from "react-icons/fa";
import axiosInstance from "../auth/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import NewClassifiedVenueView from "./NewClassifiedVenueView";
import NewClassifiedPerformerView from "./NewClassifiedPerformerView";
import Swal from "sweetalert2";

function ViewPerformerClassifieds() {
  const profileId = Cookies.get("profileOwnerId");
  const [classifieds, setClassifieds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [error, setError] = useState(null);
  const loggedInUserId = Cookies.get("userId");
  const [venueData, setVenueData] = useState({});
  const { classifiedId } = useParams();
  const [classified, setClassified] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedclassifiedId, setSelectedclassifiedId] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [deleteloading, setDeleteloading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileIdFromCookies = Cookies.get("profileId");

      if (!profileId) {
        setError("venue ID not found in URL parameters.");
        setLoading(false);
        return;
      }

      // Cookies.set("profileId", profileId);

      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          setVenueData(data);
          setError(null);
        } else {
          setError(response.data.error || "Error fetching venue profile");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profileId]);

  useEffect(() => {
    const fetchClassifieds = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/classified/published/by/profile-owner/${profileId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response.status === 200) {
          const data = response.data;
          setClassifieds(
            data.classifieds_published.filter(
              (classified) => classified.author_profile_id === profileId
            )
          );
        } else {
          console.error("Error fetching classifieds:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching classifieds:", error);
      } finally {
        setLoading(false);
      }
    };

    if (profileId) {
      fetchClassifieds();
    }
  }, [profileId]);

  const handleClassifiedClick = (classifiedId) => {
    // Cookies.set("classifiedId", classifiedId);
    console.log("Clicked classifiedId:", classifiedId);
    navigate(`/classified/${classifiedId}`);
  };

  // const handleClassifiedClick = (classifiedId) => {
  //   if (classified) {
  //     Cookies.set("classifiedId", classifiedId);
  //     navigate(`/classified/${classifiedId}`);
  //   }
  // };

  // Check if logged-in user matches profile owner

  const handleViewMore = () => {
    setViewableCount(classifieds.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  const navigateclassified = () => {
    navigate("/create-new-classified");
  };

  const handleDeleteClick = (classifiedId) => {
    setDeleteTarget(classifiedId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteloading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/classified/delete-classified/${deleteTarget}`
      );
      if (response.status === 200) {
        setClassifieds((prevClassifieds) =>
          prevClassifieds.filter(
            (classified) => classified._id !== deleteTarget
          )
        );
      } else {
        console.error("Error deleting classified:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting classified:", error);
      let errorMessage = "There was an error deleting classified.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setDeleteloading(false);
      setShowDeleteModal(false);
    }
  };

  // useEffect(() => {
  //   const fetchStatus = async () => {
  //     try {
  //       const response = await axiosInstance.get("/status-endpoint", {
  //         headers: {
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       });
  //       setStatus(response.data.status);
  //     } catch (error) {
  //       console.error("Error fetching status:", error);
  //     }
  //   };

  //   fetchStatus();
  // }, []);

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected classified id", id);
    setSelectedclassifiedId(id);
    // Cookies.set("classifiedId", id);
    // console.log("classifiedId cookie value:", Cookies.get("classifiedId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/classified/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const classified = response.data;
      console.log("classified response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        classified.classified.author_profile_id
      );
      console.log("classified id:", id);

      const profileType = getProfileType(Cookies.get("profileOwnerId"));
      if (profileType === "performer") {
        setModalContent(
          <NewClassifiedPerformerView
            classifiedId={id}
            profileId={authorProfileId}
            onDeleteSuccess={() => {
              setShowModal(false);
              window.location.reload();
            }}
          />
        );
      } else if (profileType === "venue") {
        setModalContent(
          <NewClassifiedVenueView
            classifiedId={id}
            profileId={authorProfileId}
            onDeleteSuccess={() => {
              setShowModal(false);
              window.location.reload();
            }}
          />
        );
      }
    } catch (error) {
      console.error("Error fetching classified details:", error);
      let errorMessage = "There was an error fetching classified details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedclassifiedId(null);
    setShowModal(false);
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>
          <h4>Classifieds</h4>
        </strong>

        {/* {venueData.venue_info &&
          venueData.venue_info.user_id === loggedInUserId && ( */}
        <Button
          variant="outline-primary"
          onClick={navigateclassified}
          style={{
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            fontSize: "15px",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
            backgroundColor: "#418abb",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff66c4")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#418abb")}
        // disabled={status === "cancelled"}
        >
          <FaUpload style={{ marginRight: "5px" }} />
          Add New Classified
        </Button>
      </center>
      <br></br>

      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading Classifieds...</span>
        </div>
      ) : (
        <Row>
          {classifieds.length > 0 ? (
            <>
              {classifieds.slice(0, viewableCount).map((classified) => (
                <div
                  key={classified._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  // onClick={() => handleClassifiedClick(classified._id)}
                  onClick={() =>
                    openModal(classified._id, classified.author_profile_id)
                  }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{classified.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{classified.short_description}</p>
                        </div>
                        <div className="events__details-inline">
                          <strong>Location:</strong>{" "}
                          {classified.location.suburb}
                        </div>
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(classified.from_date)}
                          {/* {"   "}
                          {formatTime(classified.from_time)} */}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(classified.to_date)}
                          {/* {"   "}
                          {formatTime(classified.to_time)} */}
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Date:</strong>
                          {typeof classified.from_date === "object"
                            ? new Date(
                                classified.from_date.year,
                                classified.from_date.month - 1,
                                classified.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : classified.from_date}
                        </div>
                        <div className="events__details-inline">
                          <strong>Time:</strong>
                          {typeof classified.from_time === "object"
                            ? `${classified.from_time.hour}:${classified.from_time.minute} ${classified.from_time.am_pm}`
                            : classified.from_time}
                        </div> */}

                        {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <Button
                              style={{
                                backgroundColor: "#151a33",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(classified._id);
                              }}
                            >
                              <BsTrash />
                            </Button>
                          )}
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          onClick={() =>
                            openModal(
                              classified._id,
                              classified.author_profile_id
                            )
                          }
                          // onClick={() => handleClassifiedClick(classified._id)}
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {viewableCount < classifieds.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({classifieds.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === classifieds.length &&
                classifieds.length > 2 && (
                  <center>
                    <Button
                      variant="link"
                      onClick={handleViewLess}
                      style={{ color: "#418abb" }}
                    >
                      View Less
                    </Button>
                  </center>
                )}
            </>
          ) : (
            <p className="text-center">No classifieds found.</p>
          )}
        </Row>
      )}
      {/* Delete confirmation modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this classified?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteConfirm}
            style={{
              minWidth: "100px",
              cursor: deleteloading
                ? "not-allowed"
                : "pointer",
              opacity: deleteloading ? 0.6 : 1,
              transition: "opacity 0.3s ease",
            }}
            disabled={deleteloading}
          >
            {deleteloading ? (
              <Spinner
                animation="border"
                role="status"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Delete"
            )}
          </Button>          
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ alignSelf: "center" }}
      // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewPerformerClassifieds;
