// src/Coupons.js
import React, { useState, useEffect } from "react";
import "./Coupons.css"; // Make sure to create and import the CSS file
import Cookies from "js-cookie";
import Footer from "../../components/footer";
import bg3 from "../../assect/images/bg3.jpg";
// import Navbar from "../../components/navbarSelf";
import NavigationBar from "../../components/navbar/newNavigationBarProfiles";
import Swal from "sweetalert2";
import axiosInstance from "../auth/axiosInstance";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingredeem, setLoadingredeem] = useState(false);
  const [error, setError] = useState(null);
  const [redeemCoupon, setRedeemCoupon] = useState({});
  const profileId = Cookies.get("profileOwnerId");
  const navigate = useNavigate();



  useEffect(() => {
    const fetchCoupons = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const url = `/v1/coupon/assigned/to/profile/${profileId}`;
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          setCoupons(response.data.coupons);
        } else {
          setError(`API Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        setError(`Request Error: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  const handleRedeem = async (coupon_id, profileId) => {
    setLoadingredeem((prev) => ({ ...prev, [coupon_id]: true })); // Set loading only for the specific coupon    
    try {
      const response = await axiosInstance.post(
        `/v1/coupon/redeem-coupon/${coupon_id}/${profileId}`,


        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setRedeemCoupon((prevCoupons) => {
          const updatedCoupons = prevCoupons.map((coupon) =>
            coupon._id === coupon_id ? { ...coupon, status: "REDEEMED" } : coupon
          );
          return updatedCoupons;
        });
        // setRedeemCoupon((prev) => ({ ...prev, [coupon_id]: true }));
        Swal.fire(
          "Success",
          "The coupon has been successfully redeemed.",
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          `Error: ${response.status} - ${response.statusText}`,
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", `Request Error: ${error}`, "error");
    }
    setLoadingredeem((prev) => ({ ...prev, [coupon_id]: false }));   
  };

  const handleDeleteClick = (couponId, coupon) => {
    setLoadingredeem(true);
    const durationText =
      coupon && coupon.coupon_duration
        ? getCouponDuration(coupon.coupon_duration)
        : "Unknown Duration";
    Swal.fire({
      title: "Redeeming a Coupon",
      text: `Are you sure you want to redeem this coupon for ${durationText}?`,

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, redeem it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRedeem(couponId, profileId);
      }
    });
    setLoadingredeem(false);
  };

  function getCouponDuration(duration) {
    switch (duration) {
      case "DAYS_30":
        return "30 Days";
      case "DAYS_60":
        return "60 Days";
      case "DAYS_90":
        return "90 Days";
      default:
        return "";
    }
  }

  const handleBackClick = () => {


    const profileType = profileId.split("-")[1];
    if (profileType === "PERFORMER") {
      navigate(`/performer/${profileId}`);
    } else if (profileType === "VENUE") {
      navigate(`/venue/${profileId}`);
    }
  };

  return (
    <>
      <NavigationBar />


      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >


        <div className="bg-overlay bg-gradient-overlay-2"></div>

        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div
              className="back-arrow"
              onClick={handleBackClick} // Call the handleBackClick function                              
              style={{
                border: "none",
                outline: "none",

                fontSize: '25px',
                marginTop: '-10px',
                color: 'white',
                background: 'transparent',
                cursor: 'pointer',
              }}
            >
              ←
            </div>
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Coupons
                </h5>
              </div>
            </div>
          </div>
        </div>

      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>

      <section className="section pt-5">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-12 mt-sm-0 pt-sm-0">
              {isLoading ? (
                <div className="text-center">Loading...</div>
              ) : error ? (
                <div className="text-center text-danger">Error: {error}</div>
              ) : (
                <div className="features-absolute">
                  <div className="row g-4 justify-content-center">
                    {Array.isArray(coupons) && coupons.length > 0 ? (
                      <div className="col-12">
                        <div
                          className="card rounded-3"
                          style={{
                            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
                          }}
                        >
                          <div className="p-4 border-bottom text-center">
                            {/* Back Arrow */}

                            <h5 className="text-primary">Available Coupons</h5>
                          </div>
                          <div className="p-4">
                            <table className="coupons-table">
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Type</th>
                                  <th>Duration</th>
                                  <th>Status</th>
                                  <th>Assigned By</th>
                                  <th>Created On</th>
                                  <th>Expiring On</th>
                                  <th>Redeemed On</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {coupons.map((coupon) => (
                                  <tr key={coupon._id}>
                                    <td>{coupon.code}</td>
                                    <td>{coupon.coupon_type}</td>
                                    <td>
                                      {getCouponDuration(
                                        coupon.coupon_duration
                                      )}
                                    </td>
                                    <td>{coupon.status}</td>
                                    <td>{coupon.assigned_by}</td>
                                    <td>{coupon.created_on}</td>
                                    <td>{coupon.expiring_on || "N/A"}</td>
                                    <td>{coupon.redeemed_on || "N/A"}</td>
                                    <td>
                                      {coupon.status === "ASSIGNED" ? (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(
                                              coupon._id,
                                              coupon
                                            );
                                          }}
                                          disabled={loadingredeem}
                                          style={{
                                            opacity: loadingredeem[coupon._id] ? 0.6 : 1,
                                            cursor: loadingredeem[coupon._id] ? "not-allowed" : "pointer",
                                            transition: "opacity 0.3s ease",
                                            minWidth: "100px",
                                          }}
                                        >
                                          {loadingredeem[coupon._id] ? (
                                            <Spinner
                                              animation="border"
                                              role="status"
                                              size="sm"
                                              style={{
                                                width: "1.2rem",
                                                height: "1.2rem",
                                                borderWidth: "0.1rem",
                                              }}
                                            />
                                          ) : (
                                            "Redeem Now"
                                          )}

                                        </button>
                                      ) : (
                                        <span className="badge bg-secondary">
                                          {coupon.status}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center text-white">No coupons available.</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default Coupons;
