import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner, Row, Col, Card } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import CustomTimePicker from "../../components/CustomTimePicker";
import AddNewOpportunity from "../opportunities/addNewOppurtunity";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import PropTypes from "prop-types";
import "../opportunities/OpportunityDateTime.css";

import moment from "moment";
import BookingForm from "./directBookingApplication";

const DirectBookingPerformerDateTime = ({
  profileId,
  initialData,
  closeFirstModal,
  closeSecondModal,
}) => {
  const fromDate = initialData.fromDate;
  const toDate = initialData.toDate;
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  // const [fromTime, setFromTime] = useState("");
  // const [toTime, setToTime] = useState("");

  // const fromTime = initialData.fromTime;
  // const toTime = initialData.toTime;
  // const fromTimeDisplay = `${fromTime.hour} : ${
  //   fromTime.minute == 0 ? "00" : fromTime.minute
  // } : ${fromTime.am_pm}`;
  // const toTimeDisplay = `${toTime.hour} : ${
  //   toTime.minute == 0 ? "00" : toTime.minute
  // } : ${toTime.am_pm}`;

  const fromTime = initialData.fromTime2 || "";
  const toTime = initialData.toTime2 || "";
  const fromTimeDisplay = initialData.fromDisplayTime2 || "";
  const toTimeDisplay = initialData.toDisplayTime2 || "";

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);

  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");

  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };

  // const closeModal = () => {
  //   setShowNextModal(false);
  //   onClose();
  // };

  useEffect(() => {
    if (fromDate) {
      handleFetchEvents(fromDate);
    }
  }, [fromDate]);

  useEffect(() => {
    console.log(initialData.fromDate);
    // setFormData({ ...formData, fromDate: initialData.initialData.fromDate });
    // setFormData({ ...formData, toDate: initialData.initialData.toDate });
    // setFormData({ ...formData, fromTime: initialData.initialData.fromTime });
    // setFormData({ ...formData, toTime: initialData.initialData.toTime });
  }, [initialData]);

  const closeModal = () => {
    setShowNextModal(false);
    if (typeof onClose === "function") {
      // Call the onClose function if it is defined and a function
    } else {
      console.warn("onClose is not a function");
    }
  };

  const handleFetchEvents = async (date) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/calendar/get-events/by-day/visited",
        {
          profile_id: profileId,
          year: new Date(date).getFullYear(),
          month: new Date(date).getMonth() + 1,
          day: new Date(date).getDate(),
        }
      );
      console.log();
      setEvents(response.data.calendar_events || []);
      setShowEventsModal(true);
    } catch (error) {
      console.error("Error fetching events:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch events",
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleChangeFromTime = (e) => {
  //   const { name, value } = e.target;
  //   // setFromTime(value);
  //   setFromTime(moment(value));
  // };

  // const handleChangeToTime = (e) => {
  //   const { name, value } = e.target;
  //   // setToTime(value);
  //   setToTime(moment(value));
  // };

  //   const handleNext = () => {
  //     setDateTime({ fromDate, toDate, fromTime, toTime });
  //     onNext();
  //   };

  const convertTo12HourFormat = (hr, min) => {
    const isPM = hr >= 12;
    const hour = hr % 12 || 12;
    const minute = min % 30 === 0 ? min : 0;
    const am_pm = isPM ? "PM" : "AM";
    return { hour, minute, am_pm };
  };

  // const handleFromDateChange = (e) => {
  //   const date = e.target.value;
  //   // const fromDateTime = new Date(`${date}`);
  //   // const fromTime = convertTo12HourFormat(
  //   //   fromDateTime.getHours(),
  //   //   fromDateTime.getMinutes()
  //   // );
  //   setFromDate(new Date(date));
  //   handleFetchEvents(date);
  // };

  // const handleToDateChange = (e) => {
  //   const date = e.target.value;
  //   // const toDateTime = new Date(`${date}`);
  //   setToDate(new Date(date));
  //   handleFetchEvents(date);
  // };

  // const handleFromTimeChange = (e) => {
  //   const date = e.target.value;
  //   console.log(date.hour());
  //   const fromTime = convertTo12HourFormat(date.hour(), date.minute());
  //   setFromTime(fromTime);
  // };

  // const handleToTimeChange = (e) => {
  //   const date = e.target.value;
  //   // const fromDateTime = new Date(`${date}`);
  //   const fromTime = convertTo12HourFormat(date.getHours(), date.getMinutes());
  //   setToTime(fromTime);
  // };

  const [dateTime, setDateTime] = useState({
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fromDate || !fromTime || !toDate || !toTime) {
      alert("Please fill in all fields before proceeding.");
      return;
    }

    setLoading(true);

    const convertDateToObject = (dateString) => {
      const year = dateString.getFullYear();
      const month = dateString.getMonth() + 1;
      const day = dateString.getDate();
      return { year, month, day };
    };

    // Function to convert time string to object
    const convertTimeToObject = (timeString) => {
      if (!timeString) return null;
      // Regular expression to match the time components
      const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)/i;

      // Execute the regular expression on the time string
      const matches = timeString.match(timeRegex);

      if (matches) {
        const hour = parseInt(matches[1], 10);
        const minute = parseInt(matches[2], 10);
        const am_pm = matches[3];

        console.log(`Hour: ${hour}`);
        console.log(`Minute: ${minute}`);
        console.log(`AM/PM: ${am_pm}`);
        // const am_pm = hour >= 12 ? "PM" : "AM";
        // hour = hour % 12 || 12;
        return { hour, minute, am_pm };
      } else {
        return null;
      }
    };

    const fromDateObject = convertDateToObject(fromDate);
    const toDateObject = convertDateToObject(toDate);

    // Convert times
    const fromTimeObject = convertTimeToObject(dateTime.fromTime);
    const toTimeObject = convertTimeToObject(dateTime.toTime);

    // const frTime = convertTo12HourFormat(fromTime.hour(), fromTime.minute());
    // const tTime = convertTo12HourFormat(toTime.hour(), toTime.minute());

    // setFromTime(frTime);
    // setToTime(tTime);

    const payload = {
      profile_type: "PERFORMER",
      profile_id: profileId,
      from_date: fromDateObject,
      to_date: toDateObject,
      from_time: fromTime,
      to_time: toTime,
      // from_date: {
      //   year: new Date(fromDate).getFullYear(),
      //   month: new Date(fromDate).getMonth() + 1,
      //   day: new Date(fromDate).getDate(),
      // },
      // to_date: {
      //   year: new Date(toDate).getFullYear(),
      //   month: new Date(toDate).getMonth() + 1,
      //   day: new Date(toDate).getDate(),
      // },
      // from_time: {
      //   hour: frTime.hour,
      //   minute: frTime.minute,
      //   am_pm: frTime.am_pm,
      // },
      // to_time: {
      //   hour: tTime.hour,
      //   minute: tTime.minute,
      //   am_pm: tTime.am_pm,
      // },
    };

    console.log(payload);

    try {
      const response = await axiosInstance.post(
        "/v1/calendar/check-conflict",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      // if (response.data.conflict) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Conflict",
      //     text: "There is a conflict with the selected dates and times.",
      //   });
      // } else {
      //   setShowNextModal(true);

      console.log(response.data);
      if (response.data.slot_availability === false) {
        Swal.fire({
          icon: "error",
          title: "Conflict",
          text:
            // response.data.collision_message ||
            "Performer is not free in that time slot.",
        });
      } else {
        setShowNextModal(true);
        // onClose();
        // setDateTime({ fromDate, fromTime, toDate, toTime });
        // onClose();
        // setDateTime({ fromDate, fromTime, toDate, toTime });
        // onSuccess();
      }
    } catch (error) {
      console.error("Error checking conflicts:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to check for conflicts.",
      });
    } finally {
      setLoading(false);
    }
  };

  // const onSuccess = () => {
  //   setShowNextModal(true);
  //   onClose();
  // };

  // const handleSubmit = async () => {
  //   if (!fromDate || !fromTime || !toDate || !toTime) {
  //     alert("Please fill in all fields before proceeding.");
  //     return;
  //   }

  //   setDateTime({ fromDate, fromTime, toDate, toTime });
  // };

  // const handleNext = () => {
  //   if (!fromDate || !fromTime || !toDate || !toTime) {
  //     alert("Please fill in all fields before proceeding.");
  //     return;
  //   }

  //   setDateTime({ fromDate, fromTime, toDate, toTime });
  //   onNext();
  // };

  // const onSuccess = () => {
  //   // Handle successful submission, such as saving the date and time
  //   // Then open the next modal
  //   setShowNextModal(true);
  //   onClose(); // Close the current modal
  // };

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const formatDateTime = (date) => {
    const localDate = convertUTCToLocal(new Date(date));
    // return localDate.toLocaleString("en-GB");
    const formattedDate = localDate.toLocaleDateString("en-GB"); // dd/mm/yyyy
    const formattedTime = localDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB");
  };

  return (
    <>
      <Card
        style={{
          borderRadius: "5px",
          backgroundColor: "#f0f2f5",
          display: "flex",
          justifyContent: "center",
          // marginLeft: "5%",
          // marginRight: "5%",
          // marginTop: "3%",
          paddingLeft: "2%",
          paddingRight: "2%",
          paddingTop: "2%",
          paddingBottom: "1%",
          // backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Row>
          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-box input-style2"
                  id="from_date"
                  name="from_date"
                  // value={fromDate}
                  value={formatDate(fromDate)}
                  readOnly
                />
                {/* <Datetime
                  className="form-control shadow-box input-style2"
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(date) =>
                    handleFromDateChange({
                      target: { value: date, name: "fromDate" },
                    })
                  }
                  value={fromDate}
                  isValidDate={valid}
                /> */}
              </Form.Group>
              <Form.Group controlId="fromTime">
                <Form.Label>From Time</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-box input-style2"
                  id="from_time"
                  name="from_time"
                  value={fromTimeDisplay}
                  readOnly
                />
                {/* <Datetime
                  className="form-control shadow-box input-style2"
                  dateFormat={false}
                  onChange={(date) =>
                    handleChangeFromTime({
                      target: { value: date, name: "fromTime" },
                    })
                  }
                  value={fromTime}
                  timeConstraints={{
                    minutes: {
                      step: 30,
                    },
                  }}
                  isValidDate={valid}
                /> */}
              </Form.Group>

              <br></br>
              <br></br>
              <Form.Group controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-box input-style2"
                  id="to_date"
                  name="to_date"
                  // value={toDate}
                  value={formatDate(toDate)}
                  readOnly
                />
                {/* <Datetime
                  className="form-control shadow-box input-style2"
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(date) =>
                    handleToDateChange({
                      target: { value: date, name: "toDate" },
                    })
                  }
                  value={toDate}
                  isValidDate={valid}
                /> */}
              </Form.Group>
              <Form.Group controlId="toTime">
                <Form.Label>To Time</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-box input-style2"
                  id="to_time"
                  name="to_time"
                  value={toTimeDisplay}
                  readOnly
                />
                {/* <Datetime
                  className="form-control shadow-box input-style2"
                  dateFormat={false}
                  onChange={(date) =>
                    handleChangeToTime({
                      target: { value: date, name: "toTime" },
                    })
                  }
                  value={toTime}
                  timeConstraints={{
                    minutes: {
                      step: 30,
                    },
                  }}
                  isValidDate={valid}
                /> */}
              </Form.Group>
              <div className="submit-button-container">
                <Button
                  style={{
                    borderRadius: "5px",
                    padding: "0.75rem 1.5rem",
                  }}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
            <br></br>
          </Col>
          <Col md={6}>
            <h3>Events</h3>
            {events.length > 0 ? (
              <ul>
                {events.map((event) => (
                  <>
                    <li
                      key={event._id}
                      style={{
                        color:
                          event.name === "[AVAILABLE]" ? "green" : "inherit",
                      }}
                    >
                      {event.name} - {formatDateTime(event.start_date)} to{" "}
                      {formatDateTime(event.end_date)}
                    </li>{" "}
                    <br></br>
                  </>
                  // <li key={event._id}>
                  //   {event.name} on {event.end_date}
                  // </li>
                ))}
              </ul>
            ) : (
              <p>No events found for the selected date.</p>
            )}
          </Col>
          {/* <div className="submit-button-container"> */}

          {/* </div> */}
        </Row>

        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
        {/* <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Next"}
        </Button> */}
      </Card>

      {/* <Modal show={showEventsModal} onHide={() => setShowEventsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {events && events.length ? ( // Check if events is defined and has length
            <ul>
              {events.map((event) => (
                <li key={event._id}>
                  {event.name} on {event.end_date}
                </li>
              ))}
            </ul>
          ) : (
            <p>No events found for the selected date.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={showNextModal}
        // onHide={() => setShowNextModal(false)}
        // onHide={() => {
        //   setShowNextModal(false);
        //   onClose(); // Ensure to close the main modal when closing this modal
        // }}
        onHide={closeModal}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Booking Form</Modal.Title>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <BookingForm
            initialData={{ fromDate, fromTime, toDate, toTime }}
            profileId={profileId}
            closeFirstModal={closeFirstModal}
            closeSecondModal={closeSecondModal}
            closeThirdModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

DirectBookingPerformerDateTime.propTypes = {
  onClose: PropTypes.func, // Validate that onClose should be a function
};

export default DirectBookingPerformerDateTime;
