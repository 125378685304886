import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import bg3 from "../../assect/images/bg/03.jpg";
// import logo from "../../assect/images/logo-icon-80.png";
import Cookies from "js-cookie";
import axiosInstance from "../auth/axiosInstance";
import "./account.css";
import styled from "styled-components";
import logo from "../../assect/images/logo-icon.png";

export default function AccountApprovalNotice() {
  const [accountStatus, setAccountStatus] = useState("APPROVAL_PENDING");
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const checkAccountStatus = async () => {
      try {
        const response = await axiosInstance.get("/v1/auth/account/status", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const status = response.data.data.account_status;
        setAccountStatus(status);
        if (status === "ACTIVE") {
          navigate("/profiles");
          // navigate("/account-approval-notice");
        } else if (status === "REJECTED") {
          navigate("/account-rejected-page");
        }
      } catch (error) {
        console.error("Error checking account status", error);
      }
    };

    const interval = setInterval(checkAccountStatus, 5000);

    return () => clearInterval(interval);
  }, [navigate, accessToken]);

  return (
    // <section className="bg-home zoom-image d-flex align-items-center">
    //   {/* <div
    //     className="bg-overlay image-wrap"
    //     style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
    //   ></div>
    //   <div className="bg-overlay bg-gradient-overlay"></div> */}
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-12">
    //         <div
    //           className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
    //           style={{ maxWidth: "400px" }}
    //         >
    //           {/* <Link to="/">
    //             <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" />
    //           </Link> */}
    //           <h5 className="mb-3 text-center">Account Created Successfully</h5>
    //           <p className="text-center">
    //             Your account has been created and is currently under review. Our
    //             team will contact you via a phone call.
    //           </p>
    //           {/* <div className="text-center mt-4">
    //             <Link to="/" className="btn btn-primary">
    //               Contact Admin
    //             </Link>
    //           </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <PageWrapper>
      <Card>
        <Logo src={logo} alt="Company Logo" />
        <Title>Account Created Successfully</Title>
        <Message>
          Your account has been created and is currently under review. Our team
          will contact you via a phone call.
        </Message>
        <Link
          to="https://ozstream.au/contact-us/"
          className="text-foot"
          style={{
            color: "#8fbae7",
            // fontFamily: "Poppins, sans-serif",
            // fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Contact Support
        </Link>
        {/* <ContactButton>Contact Support</ContactButton> */}
      </Card>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #edf2f7;
  padding: 20px;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
`;

const Logo = styled.img`
  width: 120px;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 25px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #596275;
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ContactButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;
