import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import OTPInput from "react-otp-input";
import { FaTimes } from "react-icons/fa";
import axiosInstance from "./axiosInstance";

export default function VerifyPhoneNumberModal({ show, handleClose }) {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && !event.target.closest(".modal-dialog")) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, handleClose]);

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("/v1/auth/verify-phone", {
        phone,
        otp,
      });
      console.log("API Response:", response?.data.data);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your phone number has been verified",
      }).then(() => {
        handleClose();
      });
    } catch (error) {
      console.error("API Error:", error.response?.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid verification code",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setResending(true);
    try {
      await axiosInstance.post("/v1/auth/resend-otp", { phone });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "A new OTP has been sent to your phone number",
      });
    } catch (error) {
      console.error("API Error:", error.response?.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to resend OTP",
      });
    } finally {
      setResending(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Verify Your Phone Number</Modal.Title>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          style={{ zIndex: 2 }}
        >
          <FaTimes />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your phone number"
              value={phone}
              onChange={handlePhoneChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formOtp">
            <Form.Label>Verification Code</Form.Label>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={loading}>
            Verify {loading && <Spinner animation="border" size="sm" />}
          </Button>
        </Form>
        <div className="mt-3 text-center">
          <Button
            variant="link"
            onClick={handleResendOtp}
            disabled={resending || !phone}
          >
            Resend Verification Code{" "}
            {resending && <Spinner animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
