import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Collapse,
  Card,
  Badge,
  Spinner,
} from "react-bootstrap";
import {
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaSearch,
  FaStar,
} from "react-icons/fa";
import "./newSearch.css";
import { Button, Nav, Tab, Modal } from "react-bootstrap";
import MapComponent from "../../components/map/opportunityMap";
import "../venue/venue.css";
// import dummyProfilePicture from "../../assect/images/venue/02.jpg";
import { RiMessage3Fill } from "react-icons/ri";
import Navbar from "../../components/navbarSelf";
import SidebarWithBurgerMenu from "../../components/searchNavbar";
import ParentComponent from "../../components/parentComponent";
import Slider from "rc-slider";
import "react-range-slider-input/dist/style.css";
import "rc-slider/assets/index.css";
import axiosInstance from "../../pages/auth/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import ViewVenueProfile from "../../pages/venue/viewVenueProfile";
import AutoComplete from "../../components/autoCompleteNew";
// import ViewopportunityProfile from "../../pages/opportunity/viewopportunityProfile";
// import NewopportunityProfile from "../OtherPages/newopportunityProfile";
// import NewPerformerProfileTest from "../OtherPages/newPerformerProfile";
import { FaFlag, FaBookmark, FaRegBookmark } from "react-icons/fa";
import "../OtherPages/ProfileComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBookmark, faSave } from "@fortawesome/free-regular-svg-icons";
import { FiFlag } from "react-icons/fi";
import NavigationBar from "../../components/navbar/newNavigationBar";
import Swal from "sweetalert2";
import OpportunityPerformerView from "../opportunities/opportunityPerformerView";
import OpportunityAuthorVenueView from "../opportunities/opportunityAuthorVenueView";
import OpportunityVenueView from "../opportunities/opportunityVenueView";
import NewOpportunityAuthorVenueView from "../newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../newView/NewOpportunityPerformerView";
import NewOpportunityVenueView from "../newView/NewOpportunityVenueView";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import moment from "moment";
import Footer from "../../components/footer";

const isMobile = window.innerWidth <= 767;

const CustomToggleButton = ({ open, onClick }) => (
  <center>
    <div
      onClick={onClick}
      className={`filter-toggle-button ${open ? "open" : ""}`}
      style={{ marginTop: isMobile ? "20%" : "5%" }}
    >
      <span className="button-text">
        Opportunities Search Filters
        {open ? (
          <FaChevronUp className="ml-2" />
        ) : (
          <FaChevronDown className="ml-2" />
        )}
      </span>
    </div>
  </center>
);

const NewOpportunitySearch = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const setPlaceholders = () => {
      const fromDateInput = document.getElementById("formFromDate");
      const toDateInput = document.getElementById("formToDate");

      if (fromDateInput) {
        fromDateInput.addEventListener("focus", () => {
          if (fromDateInput.value === "") {
            fromDateInput.type = "date";
          }
        });
        fromDateInput.addEventListener("blur", () => {
          if (fromDateInput.value === "") {
            fromDateInput.type = "text";
            fromDateInput.placeholder = "From Date";
          }
        });
        if (fromDateInput.value === "") {
          fromDateInput.type = "text";
          fromDateInput.placeholder = "From Date";
        }
      }

      if (toDateInput) {
        toDateInput.addEventListener("focus", () => {
          if (toDateInput.value === "") {
            toDateInput.type = "date";
          }
        });
        toDateInput.addEventListener("blur", () => {
          if (toDateInput.value === "") {
            toDateInput.type = "text";
            toDateInput.placeholder = "To Date";
          }
        });
        if (toDateInput.value === "") {
          toDateInput.type = "text";
          toDateInput.placeholder = "To Date";
        }
      }
    };

    setPlaceholders();
  }, []);

  const [formData, setFormData] = useState({
    types: [],
    genres: [],
    keywords: [],
    search_by_location: "NO",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    search_radius: 150,
    from_price_point: 0,
    to_price_point: 0,
    from_date: "",
    to_date: "",
    display_order: "MOST_RECENT",
  });

  const [opportunities, setopportunities] = useState([]);
  const [searchRadius, setSearchRadius] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [nextloading, setNextLoading] = useState(false);
  const [saveloading, setSaveloading] = useState(false);
  const [selectedopportunityId, setSelectedopportunityId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [flaggedopportunities, setFlaggedopportunities] = useState({});
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagComment, setFlagComment] = useState("");
  const [selectedFlaggedopportunity, setSelectedFlaggedopportunity] =
    useState(null);
  const [sectionType, setSectionType] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const mapRef = useRef(null);

  const [savedSearches, setSavedSearches] = useState([]);

  const [saveSearch, setSaveSearch] = useState([]);
  const [activeView, setActiveView] = useState("explore");
  const location = useLocation();
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [centerLocation, setCenterLocation] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");
  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };

  const handleFlagClick = (opportunityId) => {
    setSelectedFlaggedopportunity(opportunityId);
    setShowFlagModal(true);
  };

  const types = [
    "Bar",
    "Beer Garden",
    "Cafe",
    "Chill",
    "Club",
    "Community Event",
    "Contemporary",
    "Festival",
    "Funky",
    "Hotel",
    "Live Performance",
    "Lounge",
    "Modern",
    "Nightclub",
    "Pub",
    "Restaurant",
    "Winery",
    "Wine Bar",
    "Sports Club",
  ];

  const genres = [
    "Alternative",
    "Blues",
    "Chill",
    "Classics",
    "Country",
    "Disco",
    "DJ",
    "Drum and Bass",
    "Dub",
    "Easy Listening",
    "Electroacoustic",
    "Electronic Dance Music",
    "Electronic",
    "Electronica",
    "Ethnic",
    "Experimental",
    "First Nations",
    "Folk",
    "Funk",
    "Hardcore",
    "Hardstyle",
    "Heavy Metal",
    "Hip Hop",
    "House Music",
    "Indie Rock",
    "Indigenous",
    "Industrial",
    "Intelligent Dance Music",
    "Jazz",
    "Jungle",
    "K-pop",
    "Latin",
    "New-age",
    "Plunderphonics",
    "Pop",
    "Popular Music",
    "Punk Rock",
    "Reggae",
    "Rhythm and Blues",
    "Rock",
    "Singing",
    "Ska",
    "Soul",
    "Synth-pop",
    "Techno",
    "World Music",
  ];

  useEffect(() => {
    // Initialize section types array and sort it alphabetically
    const sections = [
      "Title",
      "Venue Name",
      "Short Description",
      "Descriptions",
    ];
    const sortedSections = sections.sort((a, b) => a.localeCompare(b));
    setSectionType(sortedSections);
  }, []);

  const handleFlag = async (
    sectionType,
    flagComment,
    reportinProfileID,
    opportunityProfileID
  ) => {
    console.log("sectionType:", sectionType);
    console.log("flagComment:", flagComment);

    console.log("reportinProfileID:", reportinProfileID);
    console.log("opportunityProfileID:", opportunityProfileID);
    try {
      const response = await axiosInstance.post(
        "/v1/opportunity/flag-opportunity",
        {
          reporting_profile_id: reportinProfileID,
          opportunity_id: opportunityProfileID,
          section: sectionType,
          comment: flagComment,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error searching opportunities:", error);
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = moment().format("YYYY-MM-DD");

  const handleFlagSave = () => {
    setLoadingSaveComment(true);
    setFlaggedopportunities((prev) => ({
      ...prev,
      [selectedFlaggedopportunity]: {
        sectionType: selectedSection,
        flagComment,
      },
    }));
    handleFlag(
      selectedSection,
      flagComment,
      `${Cookies.get("profileOwnerId")}`,
      selectedFlaggedopportunity
    );
    setShowFlagModal(false);
    setSelectedSection("");
    setFlagComment("");
    setLoadingSaveComment(false);
  };

  const handleFlagCommentChange = (e) => {
    setFlagComment(e.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchDropdownOptions = async () => {
  //     try {
  //       const typesResponse = await axiosInstance.get(
  //         "/v1/search/filter/opportunity-types",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       setTypes(typesResponse.data.opportunity_types);
  //       // if (typesResponse.data.opportunity_types.length > 0) {
  //       //   setSelectedTypes([
  //       //     {
  //       //       label: typesResponse.data.opportunity_types[0],
  //       //       value: typesResponse.data.opportunity_types[0],
  //       //     },
  //       //   ]);
  //       //   setFormData((prevData) => ({
  //       //     ...prevData,
  //       //     types: [typesResponse.data.opportunity_types[0]],
  //       //   }));
  //       // }

  //       const genresResponse = await axiosInstance.get(
  //         "/v1/search/filter/opportunity-genres",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       setGenres(genresResponse.data.opportunity_genres);
  //       // if (genresResponse.data.opportunity_genres.length > 0) {
  //       //   setSelectedGenres([
  //       //     {
  //       //       label: genresResponse.data.opportunity_genres[0],
  //       //       value: genresResponse.data.opportunity_genres[0],
  //       //     },
  //       //   ]);
  //       //   setFormData((prevData) => ({
  //       //     ...prevData,
  //       //     genres: [genresResponse.data.opportunity_genres[0]],
  //       //   }));
  //       // }
  //     } catch (error) {
  //       console.error("Error fetching dropdown options:", error);
  //     }
  //   };

  //   fetchDropdownOptions();
  // }, []);

  useEffect(() => {
    if (location.state && location.state.searchConfig) {
      const searchConfig = location.state.searchConfig;
      setFormData({
        types: searchConfig.opportunity_types || [],
        genres: searchConfig.opportunity_genres || [],
        keywords: searchConfig.keywords
          ? searchConfig.keywords.map((kw) => kw.toLowerCase())
          : [],
        search_by_location: searchConfig.search_by_location || "NO",
        location: searchConfig.location || {
          suburb: "",
          zipcode: "",
          latitude: "",
          longitude: "",
          location_name: "",
          address: "",
          google_map_pin: "",
        },
        search_radius: searchConfig.search_radius || 150,
        from_price_point: searchConfig.from_price_point || 0,
        to_price_point: searchConfig.to_price_point || 5000,
        from_date: searchConfig.from_date || "",
        to_date: searchConfig.to_date || "",
        display_order: searchConfig.display_order || "",
      });
      setPricePoint([
        searchConfig.from_price_point || 0,
        searchConfig.to_price_point || 5000,
      ]);
      setOpen(true);
    }
  }, [location.state]);

  // useEffect(() => {
  //   if (formData.types.length > 0 && formData.genres.length > 0) {
  //     handleSubmit();
  //   }
  // }, [formData]);

  //   useEffect(() => {
  //     if (location.state && location.state.searchConfig) {
  //       const searchConfig = location.state.searchConfig;
  //       setFormData({
  //         types: searchConfig.opportunity_types,
  //         genres: searchConfig.opportunity_genres,
  //         keywords: searchConfig.keywords.join(", "),
  //         search_by_location: searchConfig.search_by_location,
  //         location: searchConfig.location,
  //         from_price_point: searchConfig.from_price_point,
  //         to_price_point: searchConfig.to_price_point,
  //         from_date: searchConfig.from_date,
  //         to_date: searchConfig.to_date,
  //         display_order: searchConfig.display_order,
  //       });

  //       //   handleSubmit();
  //       setTimeout(handleSubmit, 0);
  //     }
  //   }, [location.state]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTypeChange = (type) => {
    if (formData.types.includes(type)) {
      setFormData((prevData) => ({
        ...prevData,
        types: prevData.types.filter((t) => t !== type),
      }));
    } else if (formData.types.length < 3) {
      setFormData((prevData) => ({
        ...prevData,
        types: [...prevData.types, type],
      }));
    }
  };

  const handleGenreChange = (genre) => {
    if (formData.genres.includes(genre)) {
      setFormData((prevData) => ({
        ...prevData,
        genres: prevData.genres.filter((g) => g !== genre),
      }));
    } else if (formData.genres.length < 7) {
      setFormData((prevData) => ({
        ...prevData,
        genres: [...prevData.genres, genre],
      }));
    }
  };

  const handleRadiusChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      search_radius: parseInt(e.target.value),
    }));
  };

  //   const handleTypeChange = (type) => {
  //     if (formData.types.includes(type)) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         types: prevData.types.filter((t) => t !== type),
  //       }));
  //       setSelectedTypes(selectedTypes.filter((t) => t.value !== type));
  //     } else if (formData.types.length < 3) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         types: [...prevData.types, type],
  //       }));
  //       setSelectedTypes([...selectedTypes, { label: type, value: type }]);
  //     }
  //   };

  //   const handleGenreChange = (genre) => {
  //     if (formData.genres.includes(genre)) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         genres: prevData.genres.filter((g) => g !== genre),
  //       }));
  //       setSelectedGenres(selectedGenres.filter((g) => g.value !== genre));
  //     } else if (formData.genres.length < 7) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         genres: [...prevData.genres, genre],
  //       }));
  //       setSelectedGenres([...selectedGenres, { label: genre, value: genre }]);
  //     }
  //   };

  const handleSuburbSelect = (suburbData) => {
    setSelectedSuburb(suburbData);
    setFormData((prevData) => ({
      ...prevData,
      search_by_location: suburbData ? "YES" : "NO",
      location: suburbData || {
        suburb: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        location_name: "",
        address: "",
        google_map_pin: "",
      },
    }));
  };

  const handleKeywordChange = (e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      const keyword = e.target.value.trim().toLowerCase();
      if (!formData.keywords.includes(keyword)) {
        setFormData((prevData) => ({
          ...prevData,
          keywords: [...prevData.keywords, keyword],
        }));
      }
      e.target.value = "";
    }
  };

  const removeKeyword = (keyword) => {
    setFormData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((kw) => kw !== keyword),
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (formData.types.length === 0) {
      errors.types =
        "Opportunity type is required. Please select at least one option.";
    }
    if (formData.genres.length === 0) {
      errors.genres =
        "Opportunity genre is required. Please select at least one option.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // console.log("selected types:", selectedTypes[0].value);
    // e.preventDefault();
    // if (e && e.preventDefault) e.preventDefault();

    // if (e) e.preventDefault();
    // const errors = validateForm();
    // if (Object.keys(errors).length > 0) {
    //   setValidationErrors(errors);
    //   return;
    // }
    // setValidationErrors({});

    // const allFieldsFilled =
    //   selectedTypes.length > 0 &&
    //   selectedGenres.length > 0

    // if (!allFieldsFilled) {
    //   alert("Please fill all the required fields.");
    //   return;
    // }

    if (e) e.preventDefault();

    const { latitude, longitude } = formData.location;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);

    if (!isNaN(lat) && !isNaN(lng)) {
      setCenterLocation({ latitude: lat, longitude: lng });
    } else {
      console.error("Invalid location coordinates");
    }

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});

    console.log("Selected types:", formData.types);
    console.log("Selected genres:", formData.genres);
    console.log("Keywords:", formData.keywords);

    // Validate that opportunity_types and opportunity_genres are not empty
    if (formData.types.length === 0 || formData.genres.length === 0) {
      console.error("opportunities types or genres are not selected.");
      return; // Prevent further execution
    }
    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    console.log("from date: ", formData.from_date);
    console.log("to date: ", formData.to_date);
    const formattedDateFrom = moment(formData.from_date).format("YYYY-MM-DD");
    const formattedDateTo = moment(formData.to_date).format("YYYY-MM-DD");
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/v1/search/opportunity-pagination",
        {
          opportunity_types: formData.types,
          opportunity_genres: formData.genres,
          keywords: formData.keywords,
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          from_price_point: parseInt(pricePoint[0]),
          to_price_point: parseInt(pricePoint[1]),
          from_date: formattedDateFrom,
          to_date: formattedDateTo,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setopportunities(response.data.search_results.results);
      console.log("search results: ", response.data.search_results.results);
      // setNextUrl(response.data.search_results.next);
      // setPreviousUrl(response.data.search_results.previous);

      const baseUrl = axiosInstance.defaults.baseURL;

      console.log("base url: ", baseUrl);
      const nextUrl = response.data.search_results.next
        ? `${baseUrl}/v1/search${response.data.search_results.next}`
        : null;
      const prevUrl = response.data.search_results.previous
        ? `${baseUrl}/v1/search${response.data.search_results.previous}`
        : null;

      setNextUrl(nextUrl);
      setPreviousUrl(prevUrl);
      console.log(
        "response of search results: ",
        response.data.search_results.results
      );
    } catch (error) {
      console.error("Error searching opportunities:", error);
      let errorMessage = "Error searching opportunities.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
    setLoading(false);
  };

  const handlePagination = async (url, isNext) => {
    try {
      if (isNext) {
        setLoadingNext(true);
      } else {
        setLoadingPrevious(true);
      }

      const queryString = url.split("?")[1];
      const baseUrl = "/v1/search/opportunity-pagination";

      const response = await axiosInstance.post(
        `${baseUrl}?${queryString}`,
        {
          opportunity_types: formData.types,
          opportunity_genres: formData.genres,
          keywords: formData.keywords,
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          from_price_point: parseInt(pricePoint[0]),
          to_price_point: parseInt(pricePoint[1]),
          from_date: moment(formData.from_date).format("YYYY-MM-DD"),
          to_date: moment(formData.to_date).format("YYYY-MM-DD"),
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setopportunities(response.data.search_results.results);
      setNextUrl(
        response.data.search_results.next
          ? `${axiosInstance.defaults.baseURL}/v1/search${response.data.search_results.next}`
          : null
      );
      setPreviousUrl(
        response.data.search_results.previous
          ? `${axiosInstance.defaults.baseURL}/v1/search${response.data.search_results.previous}`
          : null
      );
    } catch (error) {
      console.error("Error fetching paginated data:", error);
      Swal.fire(
        "Error",
        "An error occurred while fetching the paginated data. Please try again.",
        "error"
      );
    } finally {
      if (isNext) {
        setLoadingNext(false);
      } else {
        setLoadingPrevious(false);
      }
    }
  };

  const handleSaveSerch = async (e) => {
    // console.log("selected types:", selectedTypes[0].value);

    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    const formattedDateFrom = moment(formData.from_date).format("YYYY-MM-DD");
    const formattedDateTo = moment(formData.to_date).format("YYYY-MM-DD");
    try {
      setSaveloading(true);
      const response = await axiosInstance.post(
        "/v1/search/save/search-configuration",
        {
          search_type: "OPPORTUNITY",
          profile_id: `${Cookies.get("profileOwnerId")}`,
          opportunity_types: formData.types,
          opportunity_genres: formData.genres,
          keywords: formData.keywords,
          // keywords: formData.keywords ? [formData.keywords] : [],
          search_by_location: formData.search_by_location,
          location: formData.location,
          search_radius: formData.search_radius,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]),
          to_price_point: parseInt(pricePoint[1]),
          from_date: formattedDateFrom,
          to_date: formattedDateTo,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Search Configuration Saved!",
        text: "Your search configuration has been saved successfully.",
      });
    } catch (error) {
      // console.error("Error searching opportunities:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Failed to Save Search Configuration",
      //   text: "An error occurred while saving your search configuration. Please try again.",
      // });
      let errorMessage = "Failed to Save Search Configuration";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Failed to Save Search Configuration", errorMessage, "error");
    }
    setSaveloading(false);
  };

  // const handleSavedSearchClick = (savedSearch) => {
  //   const {
  //     opportunity_types,
  //     opportunity_genres,
  //     keywords,
  //     search_by_location,
  //     location,
  //     from_price_point,
  //     to_price_point,
  //     from_date,
  //     to_date,
  //     display_order,
  //   } = savedSearch;

  //   setSelectedTypes(
  //     opportunity_types.map((type) => ({ label: type, value: type }))
  //   );
  //   setSelectedGenres(
  //     opportunity_genres.map((genre) => ({ label: genre, value: genre }))
  //   );
  //   setFormData({
  //     types: opportunity_types,
  //     genres: opportunity_genres,
  //     keywords: keywords.join(", "),
  //     search_by_location,
  //     location,
  //     from_price_point,
  //     to_price_point,
  //     from_date,
  //     to_date,
  //     display_order,
  //   });
  //   setPricePoint([from_price_point, to_price_point]);

  //   // Set the triggerSearch flag to true
  //   setTriggerSearch(true);
  // };

  // useEffect(() => {
  //   if (triggerSearch) {
  //     handleSubmit();
  //     setTriggerSearch(false);
  //   }
  // }, [triggerSearch]);

  // const openModal = (id) => {
  //   console.log("selected opportunity id", id);

  //   Cookies.set("profileId", id, { expires: 1, path: "/" });

  //   console.log("profileId cookie value after set:", Cookies.get("profileId"));

  //   setSelectedopportunityId(id);
  //   setShowModal(true);

  //   setTimeout(() => {
  //     console.log(
  //       "profileId cookie value after state update:",
  //       Cookies.get("profileId")
  //     );
  //   }, 500);
  // };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    Cookies.set("opportunityId", id);
    console.log("opportunityId cookie value:", Cookies.get("opportunityId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opportunity details debugging:", opportunity.opportunity);
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(profileId);
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
    }
  };

  const closeModal = () => {
    setSelectedopportunityId(null);
    setShowModal(false);
  };

  const [pricePoint, setPricePoint] = useState([0, 1500]);

  const handlePricePointChange = (value) => {
    setPricePoint(value);
    setFormData((prevData) => ({
      ...prevData,
      from_price_point: value[0],
      to_price_point: value[1],
    }));
  };

  //   const opportunityLocations = opportunities.map((opportunity) => ({
  //     latitude: opportunity.location.latitude,
  //     longitude: opportunity.location.longitude,
  //     name: opportunity. + " " + opportunity.last_name,
  //   }));

  //   const isMobile = window.innerWidth <= 767;

  const halfGenres = Math.ceil(genres.length / 2);
  const firstHalfGenres = genres.slice(0, halfGenres);
  const secondHalfGenres = genres.slice(halfGenres);

  const [opportunityLocations, setopportunityLocations] = useState([]);

  //   useEffect(() => {
  //     // Extract location data from opportunities
  //     const locations = opportunities.map((opportunity) => ({
  //       ...opportunity.location,
  //       : opportunity.,
  //       last_name: opportunity.last_name,
  //       profile_picture_url: opportunity.profile_picture_url,
  //       opportunity_genres: opportunity.opportunity_genres,
  //       opportunity_types: opportunity.opportunity_types,
  //       price_point_currency: opportunity.price_point_currency,
  //       price_point_value: opportunity.price_point_value,
  //     }));
  //     setopportunityLocations(locations);
  //     console.log("locations data..: ", opportunityLocations);
  //   }, [opportunities]);

  useEffect(() => {
    if (!opportunities || opportunities.length === 0) {
      console.warn("opportunities array is empty or undefined");
      return;
    }

    // Extract location data from opportunities and set opportunityLocations state
    const locations = opportunities.map((opportunity) => ({
      ...opportunity.location,
      title: opportunity.title,
      address: opportunity.address,
      profile_picture_url: opportunity.profile_picture_url,
      opportunity_genres: opportunity.opportunity_genres,
      opportunity_types: opportunity.opportunity_types,
      price_point_currency: opportunity.price_point_currency,
      price_point_value: opportunity.price_point_value,
    }));

    setopportunityLocations(locations);
    console.log("Updated opportunityLocations: ", locations);
  }, [opportunities]);

  const handleLocationClick = (openInfoWindow) => {
    mapRef.current = openInfoWindow;
  };

  const handleSearchResultClick = (location) => {
    if (mapRef.current) {
      mapRef.current(location);
    }
  };

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const formatDateTime = (date) => {
    const localDate = convertUTCToLocal(new Date(date));
    // return localDate.toLocaleString("en-GB");
    const formattedDate = localDate.toLocaleDateString("en-GB"); // dd/mm/yyyy
    const formattedTime = localDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  };
  return (
    <>
      <NavigationBar style={{ marginBottom: "22%" }} />
      <Container fluid className="p-2">
        <CustomToggleButton open={open} onClick={() => setOpen(!open)} />
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card
              className="mb-3"
              style={{
                backgroundColor: "#f2f3f7",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "3%",
                paddingBottom: "3%",
              }}
            >
              <Form onSubmit={handleSubmit}>
                <div
                  className="search-container"
                  style={{
                    marginBottom: "3%",
                  }}
                >
                  <div
                    className="d-flex justify-content-end mb-3"
                    style={{
                      justifyContent: "center",
                      marginTop: "-3%",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        gap: "10px",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                        style={{
                          cursor: loading ? "not-allowed" : "pointer", // Disable cursor when loading
                          opacity: loading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Search"
                        )}

                        {/* {loading ? "Loading..." : "Search"} */}
                      </button>
                      <Button
                        className="btn btn-primary"
                        onClick={() => handleSaveSerch()}
                        disabled={saveloading}
                        style={{
                          cursor: saveloading ? "not-allowed" : "pointer", // Disable cursor when loading
                          opacity: saveloading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {saveloading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Save"
                        )}
                        {/* {loading ? "Loading..." : "Save"} */}
                      </Button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Col md={3} style={{ marginRight: "-4%" }}>
                    <Form.Label>Opportunity Types</Form.Label>
                    {isMobile ? (
                      <Form.Group controlId="typeFilter">
                        <MultiSelect
                          options={types.map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          name="opportunity_types"
                          value={selectedTypes}
                          onChange={(selected) => {
                            if (selected.length <= 3) {
                              setSelectedTypes(selected);
                              setFormData((prevData) => ({
                                ...prevData,
                                types: selected.map((s) => s.value),
                              }));
                            }
                          }}
                          labelledBy="Select opportunity Types"
                          hasSelectAll={false}
                        />
                      </Form.Group>
                    ) : (
                      <div className="checkbox-container">
                        {types.map((type) => (
                          <Form.Check
                            key={type}
                            name="opportunity_types"
                            type="checkbox"
                            label={type}
                            checked={formData.types.includes(type)}
                            onChange={() => handleTypeChange(type)}
                            className="checkbox-border"
                          />
                        ))}
                      </div>
                    )}
                  </Col>
                  <Col md={3}>
                    <Form.Label>Opportunity Genres</Form.Label>
                    {isMobile ? (
                      <Form.Group
                        controlId="genreFilter"
                        style={{ border: "none", marginBottom: "2%" }}
                      >
                        <MultiSelect
                          options={genres.map((genre) => ({
                            label: genre,
                            value: genre,
                          }))}
                          name="opportunity_genres"
                          value={selectedGenres}
                          onChange={(selected) => {
                            if (selected.length <= 7) {
                              setSelectedGenres(selected);
                              setFormData((prevData) => ({
                                ...prevData,
                                genres: selected.map((s) => s.value),
                              }));
                            }
                          }}
                          labelledBy="Select opportunity Genres"
                          hasSelectAll={false}
                        />
                        {/* {validationErrors.genres && (
                          <div className="alert alert-danger mt-3" role="alert">
                            {validationErrors.genres}
                          </div>
                        )} */}
                      </Form.Group>
                    ) : (
                      <Row style={{ marginRight: "10%" }}>
                        <Col xs={6}>
                          <div className="checkbox-container">
                            {firstHalfGenres.map((genre) => (
                              <Form.Check
                                key={genre}
                                name="opportunity_genres"
                                type="checkbox"
                                label={genre}
                                checked={formData.genres.includes(genre)}
                                onChange={() => handleGenreChange(genre)}
                                className="checkbox-border"
                              />
                            ))}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="checkbox-container">
                            {secondHalfGenres.map((genre) => (
                              <Form.Check
                                key={genre}
                                name="opportunity_genres"
                                type="checkbox"
                                label={genre}
                                checked={formData.genres.includes(genre)}
                                onChange={() => handleGenreChange(genre)}
                                className="checkbox-border"
                              />
                            ))}
                            {/* {validationErrors.genres && (
                          <div className="text-danger">
                            {validationErrors.genres}
                          </div>
                        )} */}
                          </div>
                        </Col>
                      </Row>
                      // <div className="checkbox-container">
                      //   {genres.map((genre) => (
                      //     <Form.Check
                      //       key={genre}
                      //       name="opportunity_genres"
                      //       type="checkbox"
                      //       label={genre}
                      //       checked={formData.genres.includes(genre)}
                      //       onChange={() => handleGenreChange(genre)}
                      //       className="checkbox-border"
                      //     />
                      //   ))}
                      // </div>
                    )}
                  </Col>
                  <Col md={3}>
                    <Form.Label>Available Date Range</Form.Label>
                    <Form.Group
                      controlId="formFromDate"
                      className="input-group"
                    >
                      <Form.Control
                        type="date"
                        name="from_date"
                        value={formData.from_date}
                        onChange={handleFilterChange}
                        min={today}
                        required
                      />
                      {/* <Datetime
                        // inputProps={{ style: inputStyle }}
                        inputProps={{
                          className: 'form-control input-group shadow-box input-style',
                        }}
                        dateFormat={true}
                        timeFormat={false}
                        onChange={date => handleFilterChange({ target: { value: date, name: 'from_date' } })}
                        value={formData.from_date}
                        required
                        isValidDate={valid}
                      /> */}
                    </Form.Group>
                    <Form.Group controlId="formToDate" className="input-group">
                      <Form.Control
                        type="date"
                        name="to_date"
                        value={formData.to_date}
                        onChange={handleFilterChange}
                        min={formData.from_date || today} // Ensure To Date is on/after From Date
                        required
                      />
                      {/* <Datetime
                        // inputProps={{ style: inputStyle }}
                        inputProps={{
                          className: 'form-control input-group shadow-box input-style',
                        }}
                        dateFormat={true}
                        timeFormat={false}
                        onChange={date => handleFilterChange({ target: { value: date, name: 'to_date' } })}
                        value={formData.to_date}
                        required
                        isValidDate={valid}
                      /> */}
                    </Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Group
                      controlId="formLocation"
                      className="input-group2"
                    >
                      <AutoComplete
                        onSuburbSelect={handleSuburbSelect}
                        location={formData.location}
                      />
                    </Form.Group>

                    <br></br>

                    <Form.Label>Search Radius </Form.Label>

                    <Form.Group controlId="formSearchRadius">
                      <Form.Control
                        as="select"
                        name="search_radius"
                        // value={formData.search_radius}
                        value={300}
                        onChange={handleRadiusChange}
                        className="input-group"
                        disabled
                      >
                        <option value={150}>150 km</option>
                        <option value={300}>300 km</option>
                        <option value={600}>600 km</option>
                        <option value={1200}>1200 km</option>
                        <option value={2400}>2400 km</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="pricePointFilter">
                      <Form.Label>Price Point Range</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          ${pricePoint[0]}
                        </span>
                        <div style={{ flex: 1, margin: "0 10px" }}>
                          <Slider
                            min={0}
                            max={5000}
                            value={pricePoint}
                            onChange={handlePricePointChange}
                            railStyle={{ background: "#d3d3d3", height: "4px" }}
                            trackStyle={[
                              { background: "#007bff", height: "4px" },
                            ]}
                            handleStyle={[
                              {
                                borderColor: "#007bff",
                                height: "14px",
                                width: "14px",
                                marginLeft: "-7px",
                                marginTop: "-5px",
                                backgroundColor: "#007bff",
                              },
                              {
                                borderColor: "#007bff",
                                height: "14px",
                                width: "14px",
                                marginLeft: "-7px",
                                marginTop: "-5px",
                                backgroundColor: "#007bff",
                              },
                            ]}
                            range
                            required
                          />
                        </div>
                        <span style={{ marginLeft: "10px" }}>
                          ${pricePoint[1]}
                        </span>
                      </div>
                    </Form.Group>
                    <input
                      type="hidden"
                      name="from_price_point"
                      // value={pricePoint[0]}
                      value={formData.from_price_point}
                    />
                    <input
                      type="hidden"
                      name="to_price_point"
                      // value={pricePoint[1]}
                      value={formData.to_price_point}
                    />
                    <br />
                    <Form.Group controlId="formKeywords">
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter keywords and press Enter"
                        name="keywords"
                        // onChange={handleFilterChange}
                        // required
                        onKeyDown={handleKeywordChange}
                      />
                      <div className="mt-2">
                        {formData.keywords.map((keyword, index) => (
                          <Badge
                            key={index}
                            pill
                            variant="primary"
                            className="mr-1"
                            onClick={() => removeKeyword(keyword)}
                            style={{ cursor: "pointer" }}
                          >
                            {keyword} <span>&times;</span>
                          </Badge>
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {validationErrors.types && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {validationErrors.types}
                  </div>
                )}

                {validationErrors.genres && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {validationErrors.genres}
                  </div>
                )}
              </Form>
            </Card>
          </div>
        </Collapse>
        <br></br>
        <br></br>
        <Row
          className="mb-3"
          style={{
            justifyContent: "center",
            alignContent: "center",
            //   alignItems: "center",
          }}
        >
          <Col xs={12} md={5}>
            <div style={{ paddingBottom: "10%" }}>
              <Row className="justify-content-center">
                <br />
                <Row>
                  {opportunities && opportunities.length > 0 ? (
                    opportunities.map((opportunity) => (
                      <div
                        key={opportunity._id}
                        className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                        // onClick={() =>
                        //   handleSearchResultClick(opportunity.location)
                        // }
                        onClick={() =>
                          handleSearchResultClick(opportunity.location)
                        }
                        // onClick={() =>
                        //   openModal(
                        //     opportunity._id,
                        //     opportunity.author_profile_id
                        //   )
                        // }
                      >
                        <div className="events__item hover__active">
                          <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                            {/* <div className="events__image">
                            <img
                              src={opportunity.profile_picture_url}
                              alt="opportunity"
                              className="events__img"
                            />
                          </div> */}
                            <div className="events__content">
                              <div
                                className="events__meta"
                                style={{ marginBottom: "-1%" }}
                              >
                                <strong>
                                  <p>{opportunity.title}</p>
                                </strong>
                              </div>

                              <div className="events__details-inline">
                                {formatDateTime(opportunity.from_date_time_utc)}{" "}
                                to{" "}
                                {formatDateTime(opportunity.to_date_time_utc)}
                              </div>

                              <div className="events__details-inline">
                                <strong>Type:</strong>
                                {opportunity.opportunity_types
                                  ? opportunity.opportunity_types.join(", ")
                                  : "No types available"}
                              </div>
                              <div className="events__details-inline">
                                <strong> Genre:</strong>
                                {opportunity.opportunity_genres
                                  ? opportunity.opportunity_genres.join(", ")
                                  : "No genres available"}
                              </div>
                              {/* <div className="events__details-inline">
                              <strong> Available Dates:</strong>
                              {opportunity.available_dates.map((date) => (
                                <span key={date}>{date}</span>
                              ))}
                            </div> */}
                              <div className="events__details-inline">
                                <strong> Price Point:</strong>
                                AUD {opportunity.price_point_value_from}-
                                {opportunity.price_point_value_to}
                              </div>

                              <div className="card-icons">
                                <FiFlag
                                  onClick={() =>
                                    handleFlagClick(opportunity._id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    color: flaggedopportunities[opportunity._id]
                                      ? "red"
                                      : "none",
                                    fill: flaggedopportunities[opportunity._id]
                                      ? "red"
                                      : "none", // Ensure the fill property is also applied
                                  }}
                                />
                              </div>
                            </div>
                            <div className="events__more">
                              <a
                                key={opportunity._id}
                                className="link-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openModal(
                                    opportunity._id,
                                    opportunity.author_profile_id
                                  );
                                }}
                                style={{ cursor: "pointer", zIndex: 1000 }}
                              >
                                View more
                                <i className="fas fa-arrow-right"></i>
                                <i className="fas fa-arrow-right"></i>
                              </a>
                              {/* <a
                              //   href="#"
                              key={opportunity._id}
                              className="link-btn"
                              onClick={() =>
                                handleSearchResultClick(opportunity.location)
                              }
                              // onClick={() => openModal(opportunity._id)}
                              style={{ cursor: "pointer", zIndex: 1000 }}
                            >
                              View Location
                              <i className="fas fa-arrow-right"></i>
                              <i className="fas fa-arrow-right"></i>
                            </a> */}
                            </div>
                            {/* {opportunities.map((opportunity) => (
                            <div className="events__more">
                              <a
                                //   href="#"
                                key={opportunity._id}
                                className="link-btn"
                                onClick={() => openModal(opportunity._id)}
                                style={{ cursor: "pointer" }}
                              >
                                View Post
                                <i className="fas fa-arrow-right"></i>
                                <i className="fas fa-arrow-right"></i>
                              </a>
                            </div>
                          ))} */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No opportunities found</div>
                  )}
                  {opportunities && opportunities.length > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn btn-primary me-2"
                        disabled={!previousUrl || loadingPrevious}
                        onClick={() =>
                          previousUrl && handlePagination(previousUrl, false)
                        }
                        style={{
                          opacity: loadingPrevious || !previousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor:
                            loadingPrevious || !previousUrl
                              ? "not-allowed"
                              : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingPrevious ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Previous"
                        )}
                      </button>

                      <button
                        className="btn btn-primary"
                        disabled={!nextUrl || loadingNext}
                        onClick={() =>
                          nextUrl && handlePagination(nextUrl, true)
                        }
                        style={{
                          opacity: loadingNext || !nextUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                          cursor:
                            loadingNext || !nextUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                          minWidth: "100px",
                        }}
                      >
                        {loadingNext ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Next"
                        )}
                      </button>
                    </div>
                  )}

                  {/* {opportunities.length > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn btn-primary me-2" // Added margin-end utility class
                        disabled={!previousUrl || loadingPrevious}
                        onClick={() =>
                          previousUrl && handlePagination(previousUrl)
                        }
                      >
                        {loadingPrevious ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Previous"
                        )}
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={!nextUrl || loadingNext}
                        onClick={() => nextUrl && handlePagination(nextUrl)}
                      >
                        {loadingNext ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Next"
                        )}
                      </button>
                    </div>
                  )} */}
                </Row>
              </Row>

              <Modal
                show={showFlagModal}
                onHide={() => setShowFlagModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Flag opportunity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="sectionType">
                      <Form.Label>Section</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedSection}
                        onChange={handleSectionChange}
                      >
                        <option value="" disabled>
                          Select a section
                        </option>
                        {sectionType.map((section, index) => (
                          <option
                            key={index}
                            value={section.toUpperCase().replace(" ", "_")}
                          >
                            {section}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="flagComment">
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your comment"
                        value={flagComment}
                        onChange={handleFlagCommentChange}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowFlagModal(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleFlagSave}
                    disabled={loadingSaveComment}
                    style={{
                      opacity: loadingSaveComment ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                      cursor: loadingSaveComment ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                      transition: "opacity 0.3s ease", // Smooth transition for opacity change
                      minWidth: "100px",
                    }}
                  >
                    {loadingSaveComment ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{
                          width: "1.2rem",
                          height: "1.2rem",
                          borderWidth: "0.1rem",
                        }}
                      />
                    ) : (
                      "Save Comment"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showModal}
                onHide={closeModal}
                size="xl"
                // style={{ minWidth: "100% !important" }}
              >
                <Modal.Header>
                  <button
                    style={{
                      position: "absolute",
                      // top: "0px",
                      right: "10px",
                      border: "none",
                      background: "transparent",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {/* Render ViewPerformerProfile component if performer ID is selected */}
                  {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                  <div>{modalContent}</div>
                </Modal.Body>
              </Modal>

              {/* <Modal
                show={showModal}
                onHide={closeModal}
                size="xl"
                style={{ minWidth: "110% !important" }}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  {selectedopportunityId && (
                    <OpportunityPerformerView
                      opportunityId={selectedopportunityId}
                    />
                  )}
                </Modal.Body>
              </Modal> */}
            </div>
          </Col>
          {!isMobile && (
            <Col xs={12} md={6}>
              <div
                style={{
                  position: "sticky",
                  top: "10px",
                  height: "650px",
                }}
              >
                <MapComponent
                  locations={opportunityLocations}
                  onLocationClick={handleLocationClick}
                  centerLocation={centerLocation}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default NewOpportunitySearch;
