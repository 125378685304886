import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { jwtDecode } from 'jwt-decode';
// import dayjs from 'dayjs';
import Cookies from "js-cookie";

const baseBackendURL = process.env.REACT_APP_BASE_URL;

async function refreshToken() {
  try {
    const refreshToken = Cookies.get("refreshToken");

    if (!refreshToken) {
      // Handle missing refresh token
      // navigate('/auth-login');
      throw new Error("Missing refresh token in cookie");
    }

    const response = await fetch(
      `${baseBackendURL}/v1/auth/refresh`,
      // "htt://127.0.0.1:4000/v1/auth/refresh",
      // "htt://127.0.0.1:4000/v1/auth/refresh",
      // "http://127.0.0.1:4000/v1/auth/refresh",
      // "https://www.ramoi.com/v1/auth/refresh",
      // "https://api.ogotrix.au/v1/auth/refresh",
      // "http://gig-bridge-live.eu-north-1.elasticbeanstalk.com/v1/auth/refresh",

      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        // credentials: 'include', // Include cookies for cross-site requests
      }
    );

    if (!response.ok) {
      throw new Error(
        `API error: ${response.status} - ${await response.text()}`
      );
    }

    const data = await response.json();
    const newAccessToken = data.data.access_token;
    Cookies.set("accessToken", newAccessToken, { secure: true });
    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    Cookies.remove("refreshToken");
    Cookies.remove("accessToken");
    window.location.href = "/login";
    throw error;
  }
}

const axiosInstance = axios.create({
  baseURL: baseBackendURL,
  // "htt://127.0.0.1:4000",
  // "http://127.0.0.1:4000",
  // "https://api.ogotrix.au",
  // "https://www.ramoi.com",
  // "https://gig-bridge-live.eu-north-1.elasticbeanstalk.com",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const newToken = await refreshToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

        const originalRequest = error.config;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
