// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
    padding: 50px 20px;
  }
  
  .faq-title {
    margin-bottom: 30px;
    font-weight: bold;
    color: #102733;
  }
  
  .faq-section {
    border: none;
    background-color: #f7f7f7;
  }
  
  .faq-section .card-header {
    background-color: #418abb;
    color: white;
    border-bottom: none;
  }
  
  .faq-section .card-header h3 {
    margin: 0;
  }
  
  .faq-section .accordion-item {
    border: none;
  }
  
  .faq-section .accordion-header {
    background-color: #88a1bb;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .faq-section .accordion-body {
    padding: 20px;
    background-color: white;
    border-top: 1px solid #ddd;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/performer/faq.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,0BAA0B;EAC5B","sourcesContent":[".faq-container {\n    padding: 50px 20px;\n  }\n  \n  .faq-title {\n    margin-bottom: 30px;\n    font-weight: bold;\n    color: #102733;\n  }\n  \n  .faq-section {\n    border: none;\n    background-color: #f7f7f7;\n  }\n  \n  .faq-section .card-header {\n    background-color: #418abb;\n    color: white;\n    border-bottom: none;\n  }\n  \n  .faq-section .card-header h3 {\n    margin: 0;\n  }\n  \n  .faq-section .accordion-item {\n    border: none;\n  }\n  \n  .faq-section .accordion-header {\n    background-color: #88a1bb;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 5px;\n  }\n  \n  .faq-section .accordion-body {\n    padding: 20px;\n    background-color: white;\n    border-top: 1px solid #ddd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
