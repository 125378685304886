import React, { useState } from "react";
import axios from "axios";
import Rating from "@mui/lab/Rating";
import Cookies from "js-cookie";
import styled from "styled-components";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../pages/auth/axiosInstance";

const ReviewContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
`;

const ReviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1a1a1a;
`;

const ReviewTitle = styled.p`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const ReviewTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  resize: vertical;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const SubmitButton = styled.button`
  padding: 10px 40px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #a9a9a9;
    cursor: not-allowed;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: grey;
  font-size: 24px;
`;

const ReviewForm = ({ onClose }) => {
  const [reviewText, setReviewText] = useState("");
  const [stars, setStars] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading indicator

    const payload = {
      author_profile_id: Cookies.get("profileOwnerId"),
      recipient_profile_id: Cookies.get("profileId"),
      review: reviewText,
      stars: stars,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/review/create-review",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setLoading(false); // Stop loading indicator

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Your review was successfully submitted.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          onClose(); // Close the form after successful submission and alert
        });
      } else {
        console.error("Error adding review:", response.statusText);
        Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your review. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      setLoading(false); // Stop loading indicator
      console.error("Error adding review:", error);
      if (error.response && error.response.data) {
        console.error("Error details:", error.response.data);
      }
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleRatingChange = (event, newValue) => {
    setStars(newValue === null ? 0 : newValue); // Ensure stars value is not null
  };

  const handleClose = () => {
    onClose(); // Close the form
  };

  return (
    <ReviewContainer>
      <ReviewHeader>
        <h3>Rate Your Experience</h3>
        <Rating
          name="star-rating"
          value={stars}
          onChange={handleRatingChange}
          style={{ cursor: "pointer", fontSize: 40, color: "#FFD43B" }}
        />
        <br />
      </ReviewHeader>

      <ReviewTitle>
        <h6>Share Your Thoughts!!</h6>
      </ReviewTitle>

      <ReviewTextArea
        className="form-control"
        id="reviewText"
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
      />
      <br />
      <br />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <SubmitButton onClick={handleSubmit} type="submit">
          Submit Review{" "}
          {loading && <Spinner animation="border" color="#fff" size={20} />}{" "}
        </SubmitButton>
      </div>

      <CloseButton
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        &#x2715; {/* Close icon (X) */}
      </CloseButton>
    </ReviewContainer>
  );
};

export default ReviewForm;
