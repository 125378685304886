import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import axios from "axios";
import PasswordChecklist from "react-password-checklist";
import Spinner from "react-bootstrap/Spinner";

const SignupSchema = yup.object().shape({
  email: yup.string().required("Email is required").email(),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password doesn't match the criteria"
    ),
  password_again: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone_number: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^((\+61[-.\s]?4)|04)?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
      "Phone number is not valid"
    ),
  business_name: yup.string().notRequired(),
  abn: yup.string().notRequired(),
});

export default function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [referralProfileId, setReferralProfileId] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get("ref");
    if (referralId) {
      setReferralProfileId(referralId);
      formik.setFieldValue("referral_profile_id", referralId);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_again: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      privacy: false,
      referral_profile_id: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(
          "http://gigbridge1.eu-north-1.elasticbeanstalk.com/v1/auth/sign-up",
          values
        );

        console.log("API Response:", response?.data.data);
        Swal.fire({
          title: "Success!",
          text: "Your account has been created. Please sign in.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate("/login");
        });
      } catch (error) {
        console.error("API Error:", error.response?.data);

        const errorMessage =
          error.response?.data.data.error || "An error occurred";

        if (errorMessage === "User already exists") {
          Swal.fire({
            title: "Error!",
            text: "This email address is already registered. Please try a different email.",
            icon: "error",
            className: "swal-modal",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An error occurred during registration. Please try again later.",
            icon: "error",
            className: "swal-modal",
          });
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <section style={{ overflow: "scroll", minHeight: "100vh", padding: "0" }}>
        <section className="bg-home zoom-image d-flex align-items-center">
          <div
            className="bg-overlay image-wrap"
            style={{
              // backgroundImage: `url(${bg3})`,
              backgroundPosition: "center",
            }}
          ></div>
          <div className="bg-overlay bg-gradient-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
                  style={{ maxWidth: "400px" }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <h5 className="mb-3">Register your account</h5>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="firstName">First Name</label>
                      {formik.errors.first_name &&
                        formik.touched.first_name && (
                          <div className="text-danger">
                            {formik.errors.first_name}
                          </div>
                        )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="lastName">Last Name</label>
                      {formik.errors.last_name && formik.touched.last_name && (
                        <div className="text-danger">
                          {formik.errors.last_name}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="name@example.com"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="firstName">Email Address</label>
                      {formik.errors.email && formik.touched.email && (
                        <div className="text-danger">{formik.errors.email}</div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="password"
                        className="form-control position-relative"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="email">Password</label>
                      {formik.errors.password && formik.touched.password && (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="password_again"
                        name="password_again"
                        placeholder="Confirm Password"
                        value={formik.values.password_again}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="password">Password Again</label>
                      {formik.errors.password_again &&
                        formik.touched.password_again && (
                          <div className="text-danger">
                            {formik.errors.password_again}
                          </div>
                        )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control position-relative"
                        id="phone_number"
                        name="phone_number"
                        placeholder="phone Number"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="phonenumber">Phone Number</label>
                      {formik.errors.phone_number &&
                        formik.touched.phone_number && (
                          <div className="text-danger">
                            {formik.errors.phone_number}
                          </div>
                        )}
                    </div>
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "lowercase",
                      ]}
                      minLength={8}
                      value={formik.values.password}
                      valueAgain={formik.values.password_again}
                    />
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "4%", marginBottom: "-4%" }}
                    >
                      <div className="mb-3">
                        <div className="form-check">
                          <span>
                            <Link to="/terms" className="text-muted me-2">
                              Terms & Conditions
                            </Link>
                          </span>
                        </div>
                      </div>
                      <span className="forgot-pass text-muted mb-0">
                        <Link to="/privacy" className="text-muted me-2">
                          privacy policies
                        </Link>
                      </span>
                    </div>
                    <div
                      className="col-12 text-center mt-3"
                      style={{ marginBottom: "4%" }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label text-muted"
                        htmlFor="flexCheckDefault"
                      >
                        I have read all the terms & conditions and agree
                      </label>
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={!formik.isValid || loading}
                    >
                      Register
                      {loading && <Spinner animation="border" role="status" />}
                    </button>
                    <div className="col-12 text-center mt-3">
                      <span>
                        <span className="text-muted me-2">
                          Already have an account?
                        </span>
                        <Link to="/login" className="text-dark fw-medium">
                          Sign in
                        </Link>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
