import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import PasswordChecklist from "react-password-checklist";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "./axiosInstance";
import NavigationBar from "../../components/navbar/loggedUserNavbarAccount";
import Footer from "../../components/footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  padding-top: 120px;

  @media (max-width: 768px) {
    padding-top: 100px; // Adjust margin as needed for spacing
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 30px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 5px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  color: #666;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6; 
    cursor: not-allowed;
  }
`;

const SignInLink = styled.p`
  margin-top: 15px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ChangePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  new_password: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password doesn't match the criteria"
    ),
  new_password_again: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [referralProfileId, setReferralProfileId] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      new_password_again: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/v1/auth/change-password", {
          current_password: values.current_password,
          new_password: values.new_password,
          new_password_again: values.new_password_again,
        });

        if (response.status === 201) {
          setSuccess(response.data.data.message);
          navigate("/"); // Navigate to homepage on success
          console.log("Password changed successfully");
          Swal.fire({
            title: "Success!",
            text: "Your password has been changed successfully.",
            icon: "success",
          });
        } else {
          setError("An error occurred while updating the password. Please try again.");
          Swal.fire("Error", "An error occurred while updating the password. Please try again.", "error");
        }
      } catch (error) {
        // console.error("Error updating password:", error);
        const errorMsg =
          error.response?.data?.message ||
          "An unexpected error occurred. Please try again later.";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMsg,
        });
        setLoading(false);
        setError(errorMsg);
        // setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Container>
        <NavigationBar />
        <Title>Change your password</Title>

        <FormWrapper onSubmit={formik.handleSubmit}>
          <InputGroup>
            <Label htmlFor="current_password">Current Password</Label>
            <InputWrapper>
              <Icon icon={faLock} />
              <Input
                type="password"
                className="form-control"
                id="current_password"
                name="current_password"
                placeholder="Current Password"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </InputWrapper>

            {formik.errors.current_password &&
              formik.touched.current_password && (
                <div className="text-danger">
                  {formik.errors.current_password}
                </div>
              )}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="new_password">New Password</Label>
            <InputWrapper>
              <Icon icon={faLock} />
              <Input
                type="password"
                className="form-control"
                id="new_password"
                name="new_password"
                placeholder="New Password"
                value={formik.values.new_password}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (!isPasswordFocused) setIsPasswordFocused(true);
                  // setIsPasswordFocused(true);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  setIsPasswordFocused(false);
                }}
              />
            </InputWrapper>

            {formik.errors.new_password && formik.touched.new_password && (
              <div className="text-danger">{formik.errors.new_password}</div>
            )}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="new_password_again">Confirm Password</Label>
            <InputWrapper>
              <Icon icon={faLock} />
              <Input
                type="password"
                className="form-control"
                id="new_password_again"
                name="new_password_again"
                placeholder="Confirm New Password"
                value={formik.values.new_password_again}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </InputWrapper>
            {formik.errors.new_password_again &&
              formik.touched.new_password_again && (
                <div className="text-danger">
                  {formik.errors.new_password_again}
                </div>
              )}
          </InputGroup>
          {isPasswordFocused && (
            <PasswordChecklist
              style={{ textAlign: "left" }}
              rules={[
                "minLength",
                "specialChar",
                "number",
                "capital",
                "lowercase",
              ]}
              minLength={8}
              value={formik.values.new_password}
              valueAgain={formik.values.new_password_again}
            />
          )}

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
          {error && <div className="text-danger mt-2">{error}</div>}
          {success && <div className="text-success mt-2">{success}</div>}
        </FormWrapper>
      </Container>
      <Footer />
    </>
  );
}
