import React, { useState, useEffect } from "react";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import defaultProfilePicture from "../../assect/images/client/02.jpg";

function AllProfiles() {
  const [performerProfiles, setPerformerProfiles] = useState([]);
  const [venueProfiles, setVenueProfiles] = useState([]);

  const savePublicProfileId = (profileId) => {
    Cookies.set("publicProfileId", profileId);
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const performerResponse = await axiosInstance.get(
          "/v1/profile/view/performer-profiles",
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );
        const venueResponse = await axiosInstance.get(
          "/v1/profile/view/venue-profiles",
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        if (performerResponse.status === 200) {
          setPerformerProfiles(performerResponse.data.performer_profiles);
        }
        if (venueResponse.status === 200) {
          setVenueProfiles(venueResponse.data.venue_profiles);
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <center>
            <h2>Performer Profiles</h2>
          </center>
          <div className="row">
            {performerProfiles.map((profile) => (
              <div key={profile._id} className="col-md-6 mb-3">
                <Link
                  to={`/performer/${profile._id}`}
                  onClick={() => savePublicProfileId(profile._id)}
                >
                  <div className="card border-0 shadow">
                    <div className="card-body p-0">
                      <img
                        src={
                          profile.profile_picture_url || defaultProfilePicture
                        }
                        alt="Profile"
                        className="img-fluid rounded-circle"
                        style={{ width: "50%", height: "auto" }}
                      />
                      <h5 className="mt-2">{profile.profile_name}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <center>
            <h2>Venue Profiles</h2>
          </center>
          <div className="row">
            {venueProfiles.map((profile) => (
              <div key={profile._id} className="col-md-6 mb-3">
                <Link
                  to={`/venue/${profile._id}`}
                  onClick={() => savePublicProfileId(profile._id)}
                >
                  <div className="card border-0 shadow">
                    <div className="card-body p-0">
                      <img
                        src={
                          profile.profile_picture_url || defaultProfilePicture
                        }
                        alt="Profile"
                        className="img-fluid rounded-circle"
                        style={{ width: "50%", height: "auto" }}
                      />
                      <h5 className="mt-2">{profile.profile_name}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllProfiles;
