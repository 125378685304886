import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./setupform";
import { useLocation } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  // "pk_test_51PNSh0EqZ7vIVMEPj1ritnYJIlHOUdTd27yPn52BqmDVLigsX4wi9pPJ6jUpkHPMNRjpceeEU2ANsj0FimK08HYy001sL7obHK"
  // "pk_test_51OzAwp02PQwweE4T49EwUAFjrt3VCtuPfJLR37r2MirljkFBn3t6v9ZcXJnM9IpTf3PgVzVgzWbQ3mLqlD1f9JFJ00LD8SfN8n"
  "pk_live_51POb6dBRzVyIM0jjn4vSgKwsiIc2eMIBa8vwI1uPmzk7MH8TIMlPOjPFVEoHiEG5Pu9MGvFGoh35IOJ2vZ9FXSet00dInWJJmf"
);

export default function Pay() {
  const location = useLocation();
  const { clientSecret } = location.state || {};
  console.log(clientSecret);
  const options = {
    // passing the SetupIntent's client secret
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
  );
}
