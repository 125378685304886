// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swal2-popup {
    align-items: center;
    justify-content: center;
  }

.default-unavailable {
  background-color: lightgrey !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/calendar/Calendar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;EACzB;;AAEF;EACE,sCAAsC;AACxC","sourcesContent":[".swal2-popup {\n    align-items: center;\n    justify-content: center;\n  }\n\n.default-unavailable {\n  background-color: lightgrey !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
