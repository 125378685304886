import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import NavigationBar from "../../components/navbar/loggedOutUserNavbar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 100px;
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;
`;

const Message = styled.p`
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 35px;
`;

export default function TemporaryPasswordSent() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <Container>
      {/* <NavBar isMobile={isMobile}>
        <Logo src={logo} alt="Logo" />
        <div>
          <ButtonB onClick={handleLogin}>Login</ButtonB>
          <ButtonB onClick={handleSignup}>Sign up</ButtonB>
        </div>
      </NavBar> */}
      <NavigationBar />

      <Title>Password Reset Request Sent</Title>
      <Message>
        A temporary password has been sent to your email address. Please check
        your inbox and follow the instructions to reset your password.
      </Message>
    </Container>
  );
}
