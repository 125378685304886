import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import ViewPerformerProfile from "../pages/performer/viewPerformerProfile";
import ViewVenueProfile from "../pages/venue/viewVenueProfile";

function ProfileViewer() {
  const { profileId } = useParams();
  const [profileType, setProfileType] = useState(null);

  useEffect(() => {
    const type = profileId.includes("PROFILE-PERFORMER")
      ? "performer"
      : profileId.includes("PROFILE-VENUE")
      ? "venue"
      : null;
    setProfileType(type);
  }, [profileId]);

  return profileType === "performer" ? (
    <ViewPerformerProfile />
  ) : profileType === "venue" ? (
    <ViewVenueProfile />
  ) : (
    <div>Loading...</div>
  );
}

export default ProfileViewer;
