import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import "./faq.css";

const faqData = [
  {
    sectionName: "Billing FAQ",
    questions: [
      {
        question: "How do I update my billing information?",
        answer:
          "To update your billing information, go to your account settings and click on 'Billing Information'. From there, you can update your payment method and billing address.",
      },
      {
        question: "What payment methods are accepted?",
        answer:
          "We accept all major credit cards including Visa, MasterCard, American Express, and Discover. We also accept payments via PayPal.",
      },
    ],
  },
  {
    sectionName: "Features FAQ",
    questions: [
      {
        question: "What features are available for singers?",
        answer:
          "Singers have access to a profile page, performance analytics, and the ability to book gigs through our platform. Additional features include promotional tools and a network of venues.",
      },
      {
        question: "What features are available for venues?",
        answer:
          "Venues can list available dates, book performers, and manage events through our platform. They also have access to promotional tools and analytics to track event performance.",
      },
    ],
  },
];

const FAQPage = () => {
  return (
    <Container className="faq-container">
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 className="faq-title text-center">Frequently Asked Questions</h1>
          {faqData.map((section, index) => (
            <Card className="faq-section mb-4" key={index}>
              <Card.Header>
                <h3>{section.sectionName}</h3>
              </Card.Header>
              <Card.Body>
                <Accordion>
                  {section.questions.map((faq, idx) => (
                    <Accordion.Item eventKey={idx.toString()} key={idx}>
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body>{faq.answer}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default FAQPage;
