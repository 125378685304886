import React, { useState, useEffect } from "react";
import "../venue/opportunity.css";
// import descriptionImage from "../../assect/images/BackgroundImage.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Classified = () => {
  const [classified, setclassified] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const { classifiedId } = useParams();
  const profileId = Cookies.get("profileOwnerId");
  const [profileOwnerUserId, setProfileOwnerUserId] = useState(null);
  // const profileOwnerId = Cookies.get("profileOwnerId");
  const profileOwnerId = Cookies.get("profileId");
  const profileOwnerId2 = Cookies.get("profileOwnerId");
  const loggedInUserId = Cookies.get("userId");
  // const profileOwnerId = Cookies.get("profileOwnerId");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchclassified = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/classified/view/${classifiedId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setclassified(response.data.classified);
        console.log(
          "classifieds fetching response: ",
          response.data.classified
        );
      } catch (error) {
        console.error("Error fetching classified:", error);
      }
    };

    fetchclassified();
  }, [classifiedId, accessToken]);

  useEffect(() => {
    const fetchProfileOwnerUserId = async () => {
      try {
        let response;
        if (profileOwnerId.startsWith("PROFILE-PERFORMER")) {
          response = await axiosInstance.get(
            `/v1/performer/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.performer_info.user_id);
          }
        } else if (profileOwnerId.startsWith("PROFILE-VENUE")) {
          response = await axiosInstance.get(
            `/v1/venue/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.venue_info.user_id);
          }
        }
      } catch (error) {
        console.error("Error fetching profile owner user ID:", error);
      }
    };

    fetchProfileOwnerUserId();
  }, [profileOwnerId, accessToken]);

  //   const handleApplyButtonClick = () => {
  //     // Assuming you have access to the classified object
  //     const toProfileIdFirst = classified.author_profile_id;
  //     console.log("classified auther profile id: " + toProfileIdFirst);
  //     Cookies.set("toProfileIdFirst", toProfileIdFirst);
  //     navigate("/messaging"); // Redirect to the messaging screen
  //   };

  const handleSendMessage = async (toProfileId) => {
    try {
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_classified/${profileOwnerId2}/${toProfileId}/${classifiedId}`,
        { title: "clasifid" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log(response);

        // Extract clasifid_id from the response
        const { clasifid_id } = response.data;

        // Fetch the title associated with the clasifid_id
        const titleResponse = await axiosInstance.get(
          `/v1/classified/view/${clasifid_id}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (titleResponse.status === 200) {
          const title = titleResponse.data.classified.title;
          navigate(`/messaging/${title}`);

          console.log("Title:", title);
        } else {
          console.error(
            "Error fetching classified title:",
            titleResponse.statusText
          );
        }
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  // const handleSendMessage = async (toProfileId) => {
  //   try {
  //     const response = await axiosInstance.post(
  //       `/v1/messaging/create_conversation_for_classified/${profileOwnerId2}/${toProfileId}/${classifiedId}`,
  //       { title: classified.title },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       console.log(response);

  //       // Extract clasifid_id from the response
  //       const { clasifid_id } = response.data;

  //       // Fetch the title associated with the clasifid_id
  //       const titleResponse = await axiosInstance.get(
  //         `/v1/classified/view/${clasifid_id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );

  //       if (titleResponse.status === 200) {
  //         const title = titleResponse.data.classified.title;
  //         navigate(`/messaging/${title}`);

  //         console.log("Title:", title);
  //       } else {
  //         console.error(
  //           "Error fetching classified title:",
  //           titleResponse.statusText
  //         );
  //       }
  //     } else {
  //       console.error("Error creating conversation:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error creating conversation:", error);
  //   }
  // };

  //   const handleSendMessage = async (toProfileId) => {
  //     try {
  //       // Make the API call to create conversation for classified

  //       const response = await axiosInstance.post(
  //         `/v1/messaging/create_conversation_for_classified/${profileOwnerId}/${toProfileId}/${classifiedId}`,
  //         { title: "clasifid" },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         console.log(response);
  //         // Navigate to messaging page after successful API call
  //         navigate("/messaging");
  //       } else {
  //         console.error("Error creating conversation:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error creating conversation:", error);
  //     }
  //   };

  const handleDeleteClick = (classifiedId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this classified?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClassified(classifiedId);
      }
    });
  };

  const handleDeleteClassified = async (classifiedId) => {
    const url = `/v1/classified/delete-classified/${classifiedId}`;

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire("Deleted!", "Your classified has been deleted.", "success");

        if (profileOwnerId2.startsWith("PROFILE-PERFORMER")) {
          navigate(`/performer/${profileOwnerId2}`);
        } else {
          navigate(`/venue/${profileOwnerId2}`);
        }
      } else {
        Swal.fire(
          "Error!",
          `Error deleting classified: ${response.status}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the classified",
        "error"
      );
    }
  };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  return (
    <div
      className="container-fluid"
      style={{
        // backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="row">
        <div className="oppcontainer">
          <div className="oppbox">
            {classified && (
              <>
                {/* <div className="oppdescription">
                  {classified.short_description}
                </div> */}
                <div className="oppdetails">
                  <div className="oppdetailRow">
                    <strong>{classified.title}</strong>
                  </div>
                  {/* <div className="oppdetailRow">
                    <strong>Venue:</strong> <span>{classified.venue}</span>
                  </div> */}
                  <div className="oppdetailRow">
                    <span>{classified.short_description}</span>
                  </div>
                  <div className="oppdetailRow">
                    <strong>Type:</strong>{" "}
                    <span>{classified.classified_type}</span>
                  </div>
                  {/* <div className="oppdetailRow">
                    <strong>Performer:</strong>{" "}
                    <span>{classified.performers.performer_name}</span>
                  </div> */}
                  <div className="oppdetailRow">
                    <strong>Category:</strong>{" "}
                    <span>{classified.classified_category}</span>
                  </div>
                  <div className="oppdetailRow">
                    <strong>Sub Category:</strong>{" "}
                    <span>{classified.classified_sub_category}</span>
                  </div>
                  {/* <div className="oppdetailRow">
                    <strong>Genre:</strong> <span>{classified.genre}</span>
                  </div> */}
                  <div className="oppdetailRow">
                    <strong>Date:</strong>{" "}
                    <span>
                      {formatDate(classified.from_date)} to{" "}
                      {formatDate(classified.to_date)}
                    </span>
                    {/* <span>
                      {classified.from_date} to {classified.to_date}
                    </span> */}
                  </div>
                  <div className="oppdetailRow">
                    <strong>Duration:</strong>{" "}
                    <span>
                      {formatTime(classified.from_time)} to{" "}
                      {formatTime(classified.to_time)}
                    </span>
                    {/* <span>
                      {classified.from_time} to {classified.to_time}
                    </span> */}
                  </div>
                  <hr className="hrStyle" />
                  <div>
                    <strong>Description:</strong> {classified.descriptions}
                  </div>
                </div>
              </>
            )}
          </div>
          <center>
            <div className="buttonsContainer">
              {profileOwnerUserId !== loggedInUserId && (
                <button
                  className="button"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHover}
                  style={{ padding: "10px 60px" }}
                  onClick={() =>
                    handleSendMessage(Cookies.get("toProfileIdFirst"))
                  }
                  //   onClick={handleApplyButtonClick}
                >
                  Message
                </button>
              )}
              {profileOwnerUserId === loggedInUserId && (
                <button
                  className="button button-danger"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHover}
                  style={{
                    padding: "10px 60px",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                  onClick={() => handleDeleteClick(classified._id)}
                >
                  Delete
                </button>
              )}
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default Classified;
