import styled from "styled-components";
// import OpportunityImage from "../assect/images/OpportunityImage2.jpg";
// import payment1 from "../assect/images/payment/visa.png";
// import payment2 from "../assect/images/payment/card.png";
// import payment3 from "../assect/images/payment/discover.png";
// import payment4 from "../assect/images/payment/4.svg";
// import profileImage from "../../assect/images/client/02.jpg";
import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import App from "../messaging/messaging";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaImages, FaUpload } from "react-icons/fa";
import Masonry from "react-masonry-css";
import { FaDeleteLeft } from "react-icons/fa6";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 10px;
  margin-right: 70px;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  margin-left: 70px;
  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const HoverImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;setIsPublished
  cursor: pointer;
  transition: transform 0.2s; /* Add a smooth transition */

  &:hover {
    transform: scale(1.05); /* Scale up when hovering */
  }
`;

const NewClassifiedPerformerView = ({
  classifiedId,
  profileId,
  onDeleteSuccess,
}) => {
  const [classified, setclassified] = useState(null);
  const accessToken = Cookies.get("accessToken");
  // const { profileId } = useParams();
  console.log(profileId);
  // const { classifiedId } = useParams();
  const selectedclassifiedId = classifiedId; //|| Cookies.get("classifiedId");
  // const profileId = Cookies.get("profileOwnerId");
  const [profileOwnerUserId, setProfileOwnerUserId] = useState(null);
  // const profileOwnerId = Cookies.get("profileOwnerId");
  const profileOwnerId = profileId; //Cookies.get("profileId");
  const profileOwnerId2 = Cookies.get("profileOwnerId");
  const loggedInUserId = Cookies.get("userId");
  const [loading, setLoading] = useState(false);
  // const profileOwnerId = Cookies.get("profileOwnerId");
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [galleryPictures, setGalleryPictures] = useState([]);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [zoomedImageUrl, setZoomedImageUrl] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [viewImagesLoading, setViewImagesLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Fetching classified with ID:", selectedclassifiedId);
    const fetchclassified = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/classified/view/${selectedclassifiedId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setclassified(response.data.classified);
      } catch (error) {
        console.error("Error fetching classified:", error);
      }
    };

    fetchclassified();
  }, [selectedclassifiedId, accessToken]);

  const fetchGalleryPictures = async () => {
    setGalleryLoading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/classified/get-classified-image/by/${classifiedId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      console.log("Gallery pictures response:", response.data);

      if (
        response.status === 200 &&
        response.data.image &&
        response.data.image.links
      ) {
        setGalleryPictures(response.data.image.links);
        setNoImages(response.data.image.links.length === 0);
      } else {
        console.error(
          "Error fetching gallery pictures: Invalid response format",
          response
        );
        setNoImages(true);
      }
    } catch (error) {
      console.error("Error fetching gallery pictures:", error);
      setNoImages(true);
    } finally {
      setGalleryLoading(false);
    }
  };

  const handleViewImagesClick = async () => {
    setViewImagesLoading(true);
    await fetchGalleryPictures();
    setShowGalleryModal(true);
    setViewImagesLoading(false);
    // setNoImages(galleryPictures.length === 0);
  };

  const showPreviousArrow = currentImageIndex !== null && currentImageIndex > 0;
  const showNextArrow =
    currentImageIndex !== null &&
    currentImageIndex < galleryPictures.length - 1;

  const handlePreviousClick = () => {
    if (showPreviousArrow) {
      setCurrentImageIndex(currentImageIndex - 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex - 1].image_url);
    }
  };

  const handleNextClick = () => {
    if (showNextArrow) {
      setCurrentImageIndex(currentImageIndex + 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex + 1].image_url);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  useEffect(() => {
    const fetchProfileOwnerUserId = async () => {
      try {
        let response;
        if (profileOwnerId.startsWith("PROFILE-PERFORMER")) {
          response = await axiosInstance.get(
            `/v1/performer/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.performer_info.user_id);
          }
        } else if (profileOwnerId.startsWith("PROFILE-VENUE")) {
          response = await axiosInstance.get(
            `/v1/venue/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.venue_info.user_id);
          }
        }
      } catch (error) {
        console.error("Error fetching profile owner user ID:", error);
      }
    };

    fetchProfileOwnerUserId();
  }, [profileOwnerId, accessToken]);

  //   const handleApplyButtonClick = () => {
  //     // Assuming you have access to the classified object
  //     const toProfileIdFirst = classified.author_profile_id;
  //     console.log("classified auther profile id: " + toProfileIdFirst);
  //     Cookies.set("toProfileIdFirst", toProfileIdFirst);
  //     navigate("/messaging"); // Redirect to the messaging screen
  //   };

  const [showModal3, setShowModal3] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const handleClose3 = () => setShowModal3(false);
  const handleShow = () => setShowModal3(true);

  useEffect(() => {
    if (conversationId) {
      handleShow();
    }
  }, [conversationId]);

  const handleSendMessage = async (toProfileId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_classified/${profileOwnerId2}/${toProfileId}/${selectedclassifiedId}`,
        { title: classified.title },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log(response);

        // Extract clasifid_id from the response
        const { clasifid_id } = response.data;
        const conversation_id =
          response.data.exists_conversation_id ||
          response.data.conversation_id ||
          response.data.exists_econversation_id ||
          response.data.new_conversation_id;
        console.log("Conversation ID:", response);
        setConversationId(conversation_id);
        handleShow();
        setLoading(false);

        // // Fetch the title associated with the clasifid_id
        // const titleResponse = await axiosInstance.get(
        //   `/v1/classified/view/${clasifid_id}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${Cookies.get("accessToken")}`,
        //     },
        //   }
        // );

        // if (titleResponse.status === 200) {
        //   const title = titleResponse.data.classified.title;
        //   navigate(`/messaging/${title}`);

        //   console.log("Title:", title);
        // } else {
        //   console.error(
        //     "Error fetching classified title:",
        //     titleResponse.statusText
        //   );
        // }
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
      setLoading(false);
    }
  };

  //   const handleSendMessage = async (toProfileId) => {
  //     try {
  //       // Make the API call to create conversation for classified

  //       const response = await axiosInstance.post(
  //         `/v1/messaging/create_conversation_for_classified/${profileOwnerId}/${toProfileId}/${selectedclassifiedId}`,
  //         { title: "clasifid" },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         console.log(response);
  //         // Navigate to messaging page after successful API call
  //         navigate("/messaging");
  //       } else {
  //         console.error("Error creating conversation:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error creating conversation:", error);
  //     }
  //   };

  const handleDeleteClick = (selectedclassifiedId) => {
    setIsLoadingDelete(true);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this classified?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClassified(selectedclassifiedId);
      }
    });
    setIsLoadingDelete(false);
  };

  const handleDeleteClassified = async (selectedclassifiedId) => {
    setIsLoadingDelete(true);
    const url = `/v1/classified/delete-classified/${selectedclassifiedId}`;

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire(
          "Deleted!",
          "Your classified has been successfully deleted.",
          "success"
        );
        // navigate(`/performer/${profileOwnerId2}`);
        onDeleteSuccess();
        // if (profileOwnerId2.startsWith("PROFILE-PERFORMER")) {
        //   navigate(`/performer/${profileOwnerId2}`);
        // } else {
        //   navigate(`/venue/${profileOwnerId2}`);
        // }
      } else {
        Swal.fire(
          "Error!",
          `Error deleting classified: ${response.status}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the classified.",
        "error"
      );
    } finally {
      setIsLoadingDelete(false);
    }
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  const [images, setImages] = useState([]);
  const [imgloading, setImgLoading] = useState(false);
  const [noImages, setNoImages] = useState(false);

  const openFileInput = () => {
    const fileInput = document.getElementById("classifiedImages");
    fileInput.click();
  };

  const handleUploadClassifiedImages = async (event) => {
    const files = event.target.files;
    if (images.length >= 5) {
      Swal.fire({
        title: "Error!",
        text: "You can only upload a maximum of 5 images in your post.",
        icon: "error",
        className: "swal-modal",
      });
      event.target.value = null;
      return;
    }
    if (files.length > 5) {
      Swal.fire({
        title: "Error!",
        text: "You can only upload a maximum of 5 images in a single upload.",
        icon: "error",
        className: "swal-modal",
      });
      event.target.value = null;
      return;
    }
    if (images.length + files.length > 5) {
      Swal.fire({
        title: "Error!",
        text: `You can only add ${5 - images.length} more images to the post.`,
        icon: "error",
        className: "swal-modal",
      });
      event.target.value = null;
      return;
    }

    setImgLoading(true);

    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("classified_id", classifiedId);
    console.log("classified id when uploading images: ", classifiedId);

    try {
      const response = await axiosInstance.post(
        "/v1/classified/upload-image/classified",
        formData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        const uploadedImages = response.data.upload_results.filter(
          (result) => result.status === "SUCCESS"
        );
        setImages((prevImages) => [...prevImages, ...uploadedImages]);

        Swal.fire({
          title: "Success!",
          text: "Upload completed successfully.",
          icon: "success",
          className: "swal-modal",
        });
      } else {
        console.error(
          "Error uploading classified images:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error uploading classified images:", error);
    } finally {
      setImgLoading(false);
      event.target.value = null;
    }
  };

  const handleDeleteImage = async (imageId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      className: "swal-modal",
    });

    if (result.isConfirmed) {
      const payload = {
        classified_id: classifiedId,
        image_ids: [imageId],
      };

      try {
        const response = await axiosInstance.post(
          "/v1/classified/delete-classified-image",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          // Remove the deleted image from the state
          setGalleryPictures((prevPictures) =>
            prevPictures.filter((picture) => picture.image_id !== imageId)
          );
          Swal.fire({
            title: "Deleted!",
            text: "Your image has been successfully deleted.",
            icon: "success",
            className: "swal-modal",
          });
        } else {
          console.error("Error deleting image:", response.data.error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the image.",
            icon: "error",
            className: "swal-modal",
          });
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        Swal.fire({
          title: "Error!",
          text: "An error occurred while deleting the image.",
          icon: "error",
          className: "swal-modal",
        });
      }
    }
  };

  // const handleDeleteImage = async (imageId) => {
  //   const payload = {
  //     classified_id: classifiedId,
  //     image_ids: [imageId],
  //   };

  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/classified/delete-classified-image",
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       // Remove the deleted image from the state
  //       setGalleryPictures((prevPictures) =>
  //         prevPictures.filter((picture) => picture.image_id !== imageId)
  //       );
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Image deleted successfully.",
  //         icon: "success",
  //         className: "swal-modal",
  //       });
  //     } else {
  //       console.error("Error deleting image:", response.data.error);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting image:", error);
  //   }
  // };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      {classified && (
        <>
          <LeftColumn>
            {/* <CategoryTag>Open</CategoryTag> */}
            <Title>{classified.title}</Title>
            {/* <InfoRow>
          <Info>
            <ProfileImage src={profileImage} alt="Profile" />
          </Info>
          <Info>
            Venue:
            <br />
            <strong> Harmony Hall</strong>
          </Info>
          <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info>
        </InfoRow> */}

            {/* <Tabs>
          <Tab
            active={activeTab === "opportunityInfo"}
            onClick={() => setActiveTab("opportunityInfo")}
          >
            Opportunity Info
          </Tab>
          <Tab
            active={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Reviews
          </Tab>
        </Tabs> */}
            <Content>
              <About>
                <h3>Description</h3>
                <p>{classified.descriptions}</p>
                {/* <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox> */}
              </About>
              {/* <button onClick={openFileInput}>Upload Images</button> */}
              {profileOwnerUserId === loggedInUserId && (
                <Button
                  variant="outline-primary"
                  onClick={openFileInput}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    fontSize: "15px",
                    border: "none",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    color: "white",
                    backgroundColor: "#418abb",
                    transition: "background-color 0.3s",
                    cursor: imgloading
                      ? "not-allowed"
                      : "pointer",
                    opacity: imgloading ? 0.6 : 1,
                    transition: "opacity 0.3s ease"
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#ff66c4";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#418abb";
                  }}
                >
                  {imgloading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                    />
                  ) : (
                    <FaUpload style={{ marginRight: "5px" }} />
                  )}
                  {imgloading ? "Uploading..." : "Upload Images"}
                </Button>
              )}
              <Button
                variant="outline-primary"
                className="me-2 mb-2 custom-btn"
                style={{ 
                  marginTop: "10px", 
                  borderRadius: "3px",
                  cursor: viewImagesLoading
                  ? "not-allowed"
                  : "pointer",
                  opacity: viewImagesLoading ? 0.6 : 1,
                  transition: "opacity 0.3s ease"}}
                onClick={handleViewImagesClick}
                // style={{
                //   display: "flex",
                //   alignSelf: "center",
                //   alignItems: "center",
                //   justifyContent: "center",
                //   borderRadius: "3px",
                //   fontSize: "15px",
                //   border: "none",
                //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                //   color: "white",
                //   backgroundColor: "#418abb",
                //   transition: "background-color 0.3s",
                //   marginTop: "10px",
                // }}
                // onMouseEnter={(e) => {
                //   e.target.style.backgroundColor = "#ff66c4";
                // }}
                // onMouseLeave={(e) => {
                //   e.target.style.backgroundColor = "#418abb";
                // }}
              >
                {viewImagesLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <FaImages style={{ marginRight: "5px" }} />
                    View Images
                  </>
                )}
              </Button>

              <input
                type="file"
                id="classifiedImages"
                style={{ display: "none" }}
                multiple
                onChange={handleUploadClassifiedImages}
              />
              {/* {activeTab === "opportunityInfo" && (
            <>
              <About>
                <h2>Description</h2>
                <p>
                  An intimate evening of acoustic performances by
                  singer-songwriters and musicians, held in a cozy coffeehouse
                  or café. The stripped-down arrangements and heartfelt lyrics
                  create a warm and inviting atmosphere, perfect for enjoying a
                  cup of coffee and discovering new talent. An intimate evening
                  of acoustic performances by singer-songwriters and musicians,
                  held in a cozy coffeehouse or café. The stripped-down
                  arrangements and heartfelt lyrics create a warm and inviting
                  atmosphere, perfect for enjoying a cup of coffee and
                  discovering new talent.
                </p>
                <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox>
              </About>
            </>
          )}
          {activeTab === "media" && <div>Reviews content goes here...</div>} */}
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {/* <EnrollButton>I am interested</EnrollButton> */}
              <center>
                {profileOwnerUserId !== loggedInUserId && (
                  <button
                    className="oppbutton2"
                    style={{
                      cursor: loading ? "not-allowed" : "pointer",
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",
                      opacity: loading ? 0.6 : 1,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={() =>
                      handleSendMessage(Cookies.get("toProfileIdFirst"))
                    }
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only"> Loading...</span>
                      </>
                    ) : (
                      "MESSAGE"
                    )}
                  </button>
                )}
                <Modal show={showModal3} onHide={handleClose3} size="xl">
                  <Modal.Header>
                    <Modal.Title>Messaging</Modal.Title>
                    <button
                      style={{
                        position: "absolute",
                        // top: "10px",
                        right: "10px",
                        border: "none",
                        background: "transparent",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      onClick={handleClose3}
                    >
                      &times;
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    {/* {conversationId && <App conversation_id={conversationId} />} */}
                    {conversationId ? (
                      <App conversation_id={conversationId} />
                    ) : (
                      "Loading..."
                    )}
                  </Modal.Body>
                </Modal>
                {profileOwnerUserId === loggedInUserId && (
                  <button
                    className="oppbutton2"
                    style={{
                      cursor: isLoadingDelete ? "not-allowed" : "pointer",
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",
                      opacity: isLoadingDelete ? 0.6 : 1,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={() => handleDeleteClick(classified._id)}
                    disabled={isLoadingDelete || isDeleted}
                  >
                    {isLoadingDelete ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "DELETE"
                    )}
                  </button>
                )}
              </center>

              <br></br>
              <Stats>
                <DateContainer>
                  {/* <span>
                    From: {"  "}
                    <DateText>
                      {formatDate(classified.from_date)}
                      {"   "}
                      {formatTime(classified.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(classified.to_date)}
                      {"   "}
                      {formatTime(classified.to_time)}
                    </DateText>
                  </span> */}
                  <span style={{ marginBottom: "18px" }}>
                    Price:{" "}
                    <DateText>
                      AUD {classified.price_point_value_from}-
                      {classified.price_point_value_to}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    Location:{" "}
                    <DateText>
                      {classified.location.suburb},{" "}
                      {classified.location.zipcode}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(classified.from_date)}
                      {/* {"   "}
                      {formatTime(classified.from_time)} */}
                    </DateText>
                  </span>
                  <span style={{ marginBottom: "18px" }}>
                    To: {"  "}
                    <DateText>
                      {formatDate(classified.to_date)}
                      {/* {"   "}
                      {formatTime(classified.to_time)} */}
                    </DateText>
                  </span>
                  <span>
                    Status: <DateText>{classified.status}</DateText>
                  </span>
                </DateContainer>
              </Stats>

              <TagContainer>
                <h6>Type:</h6>
                <TagBox>{classified.classified_type}</TagBox>
              </TagContainer>

              <TagContainer>
                <h6>Category:</h6>
                <TagBox>{classified.classified_category}</TagBox>
              </TagContainer>

              <TagContainer>
                <h6>Sub Category:</h6>
                <TagBox>{classified.classified_sub_category}</TagBox>
              </TagContainer>
            </SideCard>
          </RightColumn>
          <Modal
            show={showGalleryModal}
            onHide={() => setShowGalleryModal(false)}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Uploaded Images</Modal.Title>
              <button
                style={{
                  position: "absolute",
                  // top: "10px",
                  right: "10px",
                  border: "none",
                  background: "transparent",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={() => setShowGalleryModal(false)}
              >
                &times;
              </button>
            </Modal.Header>
            {/* <Modal.Body>
              {galleryLoading ? (
                <div>Loading...</div>
              ) : noImages ? (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No images found
                </p>
              ) : (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "3%",
                  }}
                >
                  {galleryPictures.map((picture, index) => (
                    <div
                      key={picture.image_id}
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <HoverImage
                          src={picture.image_url}
                          alt={picture.image}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            display: "block",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            setCurrentImageIndex(index);
                            setZoomedImageUrl(picture.image_url);
                          }}
                        />
                        <Button
                          variant="danger"
                          style={{
                            position: "absolute",
                            borderRadius: "3px",
                            top: "5px",
                            left: "5px",
                            padding: "3px 8px",
                          }}
                          onClick={() => handleDeleteImage(picture.image_id)}
                        >
                          <FaDeleteLeft />
                        </Button>
                      </div>
                    </div>
                  ))}
                </Masonry>
              )}
            </Modal.Body> */}
            <Modal.Body>
              {galleryLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : noImages ? (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No images found
                </p>
              ) : (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "3%",
                  }}
                >
                  {galleryPictures.map((picture, index) => (
                    <div
                      key={picture.image_id}
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <HoverImage
                          src={picture.image_url}
                          alt={picture.image}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            display: "block",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            setCurrentImageIndex(index);
                            setZoomedImageUrl(picture.image_url);
                          }}
                        />
                        {profileOwnerUserId === loggedInUserId && (
                          <Button
                            variant="danger"
                            style={{
                              position: "absolute",
                              borderRadius: "3px",
                              top: "5px",
                              left: "5px",
                              padding: "3px 8px",
                            }}
                            onClick={() => handleDeleteImage(picture.image_id)}
                          >
                            <FaDeleteLeft />
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </Masonry>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default NewClassifiedPerformerView;
