import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import axiosInstance from "./axiosInstance";

export default function VerifyEmailModal({ show, handleClose }) {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("/v1/auth/verify-email", {
        code,
      });
      console.log("API Response:", response?.data.data);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your email has been verified",
      }).then(() => {
        handleClose();
      });
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid verification code",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResending(true);
    try {
      await axios.post("/v1/auth/resend-verification-code");
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "A new verification code has been sent to your email",
      });
    } catch (error) {
      console.error("API Error:", error.response?.data.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to resend verification code",
      });
    } finally {
      setResending(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Verify Your Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formCode">
            <Form.Label>
              Please enter the email verification code sent to your email.
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter verification code"
              value={code}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={loading}>
            Verify {loading && <Spinner animation="border" size="sm" />}
          </Button>
        </Form>
        <div className="mt-3 text-center">
          <Button
            variant="link"
            onClick={handleResendCode}
            disabled={resending}
          >
            Resend Verification Code{" "}
            {resending && <Spinner animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
