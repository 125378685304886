import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import logo from "../../assect/images/landing/logo.jpg";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 120px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 100px;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

// const Input = styled.textarea`
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   height: auto; /* Allows the textarea to expand vertically */
//   min-height: 100px; /* Set a minimum height to prevent it from collapsing */
// `;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px; /* Set a minimum height to prevent it from collapsing */
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  resize: none; /* Disable manual resizing */

  overflow-y: hidden; /* Hide vertical scrollbar */

  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-left: 60px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  max-width: 200px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

const ThumbnailHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const convertToEmbedUrl = (url) => {
  const youtubeMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)([^&?\n]+)/i
  );
  if (youtubeMatch) {
    const videoId = youtubeMatch[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }
  console.warn("Unsupported video link format:", url);
  return "";
};

const getThumbnailUrl = (url) => {
  const youtubeMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)([^&?\n]+)/i
  );
  if (youtubeMatch) {
    const videoId = youtubeMatch[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }
  return "";
};

function UpdatePerformerVideoGallery() {
  const profileId = Cookies.get("profileOwnerId");
  const [links, setLinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const embedLinks = links
      .split("\n")
      .filter((link) => link.trim() !== "")
      .map((link) => convertToEmbedUrl(link.trim()));

    console.log("Embed Links:", embedLinks); // Ensure this matches expected format

    if (embedLinks.length === 0) {
      setLoading(false);
      setErrorMessage("Please provide at least one video link.");
      Swal.fire({
        title: "Error!",
        text: "Please provide at least one video link.",
        icon: "error",
        className: "swal-modal",
      });
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/video-gallery",
        {
          performer_id: Cookies.get("profileOwnerId"),
          links: embedLinks,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage(
          "The performer’s video gallery has been updated successfully."
        );
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "The performer’s video gallery has been updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileOwnerId");
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage(
          "An error occurred while updating the performer’s video gallery. Please try again."
        );
        console.error("Error updating performer video gallery:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer video gallery:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (event, setStateFunction) => {
  //     const { value, scrollHeight } = event.target;
  //     setStateFunction(value);
  //     event.target.style.height = "auto"; // Reset the height
  //     event.target.style.height = scrollHeight + "px"; // Set the dynamic height
  // };

  const handleChange = (event) => {
    const { value, scrollHeight } = event.target;
    setLinks(value);
    event.target.style.height = "auto";
    event.target.style.height = `${scrollHeight}px`;

    // Update thumbnails
    const urls = value
      .split("\n")
      .filter((link) => link.trim() !== "")
      .map((link) => getThumbnailUrl(link.trim()));

    console.log("Generated Thumbnails:", urls); // Debugging line
    setThumbnails(urls);
  };

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Video Gallery</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="links">Video Links</Label>
          <InputWrapper>
            <Textarea
              id="links"
              className="form-control"
              value={links}
              onChange={handleChange}
              placeholder="Update video gallery.."
              required
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            required
                        /> */}
          </InputWrapper>
        </InputGroup>

        <ThumbnailWrapper>
          {thumbnails.map((thumbnail, index) => (
            <Thumbnail key={index}>
              <ThumbnailHover>
                <iframe
                  width="200"
                  height="113"
                  src={convertToEmbedUrl(links.split("\n")[index])}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </ThumbnailHover>
              <ThumbnailImage src={thumbnail} alt="Video thumbnail" />
              <ThumbnailOverlay>
                <span>Watch</span>
              </ThumbnailOverlay>
            </Thumbnail>
          ))}
        </ThumbnailWrapper>
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerVideoGallery;
