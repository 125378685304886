import React from "react";

const CustomTimePicker = ({ value, onChange, name }) => {
  const generateTimeOptions = () => {
    const options = [];
    const periods = ["AM", "PM"];

    for (let hour = 0; hour <= 11; hour++) {
      for (let minute of ["00", "30"]) {
        options.push(`${hour === 0 ? 12 : hour}:${minute} AM`);
      }
    }

    for (let hour = 12; hour <= 23; hour++) {
      const displayHour = hour > 12 ? hour - 12 : hour;
      for (let minute of ["00", "30"]) {
        options.push(`${displayHour}:${minute} PM`);
      }
    }

    return options;
  };

  const handleChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  return (
    <select
      className="form-control custom-time-picker"
      value={value}
      onChange={handleChange}
    >
      {generateTimeOptions().map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ))}
    </select>
  );
};

export default CustomTimePicker;
