import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import defaultProfilePicture from "../../assect/images/client/02.jpg";
import axiosInstance from "../auth/axiosInstance";
import "../performer/FollowedProfiles.css";

const VenueFollowedProfilesPage = () => {
  const [followedProfiles, setFollowedProfiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFollowedProfiles = async () => {
      const profileId = Cookies.get("profileOwnerId");

      try {
        const response = await axiosInstance.post(
          "/v1/profile/followed-profiles/by-self",
          {
            profile_id: profileId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const followedProfilesData = response.data.profiles_followed_by_self;
          setFollowedProfiles(followedProfilesData);
        } else {
          console.error("API Error:", response.status, response.data);
        }
      } catch (error) {
        console.error("Request Error:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchFollowedProfiles();
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      const profilesData = await Promise.all(
        followedProfiles.map(async (profile) => {
          const response = await axiosInstance.get(
            `/v1/venue/view-profile/${profile.followed_profile_id}/section/complete`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );
          return response.data.venue_info;
        })
      );
      setProfiles(profilesData);
    };

    if (followedProfiles.length > 0) {
      fetchProfiles();
    }
  }, [followedProfiles]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="followed-profiles-page-custom">
      <h2>Followed Profiles</h2>
      <div className="followed-profiles-list-custom">
        {profiles.map((profile) => (
          <div key={profile.user_id} className="profile-card-custom">
            {/* <img
              src={profile.profile_picture_url || defaultProfilePicture}
              alt={`${profile.venue_name} `}
              className="profile-picture-custom"
            /> */}
            <div className="profile-info-custom">
              <h4>{profile.venue_name}</h4>
            </div>
            <div className="follow-button-custom">
              <button className="followed-button-custom">Followed</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VenueFollowedProfilesPage;
