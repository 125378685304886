import styled from "styled-components";
// import OpportunityImage from "../assect/images/OpportunityImage2.jpg";
// import payment1 from "../assect/images/payment/visa.png";
// import payment2 from "../assect/images/payment/card.png";
// import payment3 from "../assect/images/payment/discover.png";
// import payment4 from "../assect/images/payment/4.svg";
// import profileImage from "../../assect/images/client/02.jpg";
import React, { useState, useEffect } from "react";
// import descriptionImage from "../../assect/images/bgimg.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Modal, Spinner } from "react-bootstrap";
import NewPerformerProfileTest from "../OtherPages/newPerformerProfile";
import NewVenueProfile from "../OtherPages/newVenueProfile";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2%px;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: 10px;
  margin-right: 70px;
  min-width: 300px;
  max-width: 600px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  margin-left: 70px;
  min-width: 300px;
  max-width: 400px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const CategoryTag = styled.div`
  background-color: #28a745;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-top: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Info = styled.div`
  margin-bottom: 20px;
  color: #555;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? "2px solid #007bff" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#007bff" : "black")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`;

const SideCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const EnrollButton = styled.button`
  background-color: #0b57d0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  transition: background-color 0.3s;

  &:hover {
    background-color: #2468d5;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin-top: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 0;
  font-weight: bold;

  & > span {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.span`
  font-weight: normal;
`;

const Stats = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  text-align: left;
`;

const PaymentTags = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const TagContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 35px;
    height: auto;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
    }
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const TagBox = styled.div`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ButtonTagBox = styled(TagBox)`
  background-color: #0b57d0;
  color: white;

  &:hover {
    background-color: #2468d5;
  }

  margin-left: 0;
`;

const About = styled.div`
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    text-align: justify;
    line-height: 1.6;
    color: #444;
  }

  button {
    margin-top: 10px;
  }
`;

const Learn = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #555;
`;

const NewOpportunityPerformerView = ({ opportunityId, profileId }) => {
  const [opportunity, setOpportunity] = useState(null);
  const accessToken = Cookies.get("accessToken");
  // const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId; //|| Cookies.get("opportunityId");
  const [hasApplied, setHasApplied] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(null);
  const loggedInProfileId = Cookies.get("profileOwnerId");
  const [bookings, setBookings] = useState([]);
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const [isLoadingWithdraw, setIsLoadingWithdraw] = useState(false);
  const [isLoadingDoubleConfirm, setIsLoadingDoubleConfirm] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [venue, setVenue] = useState(null);
  const [authorId, setauthorId] = useState(null);
  const [isLoadingOpportunity, setIsLoadingOpportunity] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        setHasApplied(false);
        // setBookings([]);
        setBookingStatus(null);
        setIsLoadingOpportunity(true);
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        const fetchedOpportunity = response.data.opportunity;
        fetchVenueDetails(fetchedOpportunity.author_profile_id);
        setauthorId(fetchedOpportunity.author_profile_id);
        console.log("opportunity details: ", response.data.opportunity);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      } finally {
        setIsLoadingOpportunity(false);
      }
    };

    const fetchVenueDetails = async (venueId) => {
      try {
        const venueSection = "info";
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${venueId}/section/${venueSection}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("venue info:", response.data.venue_info.profile_name);
          setVenue(response.data.venue_info);
        } else {
          console.error("Error fetching venue details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching venue details:", error);
      }
    };

    // const checkIfApplied = async () => {

    //   const payload = {
    //     opportunity_id: selectedOpportunityId,
    //     logged_in_profile_id: loggedInProfileId,
    //   };

    //   try {
    //     const response = await axiosInstance.post(
    //       "/v1/booking/has-applied",
    //       payload,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${accessToken}`,
    //         },
    //       }
    //     );

    //     if (response.status === 200) {
    //       setHasApplied(response.data.has_applied);
    //     } else {
    //       console.error(
    //         "Error checking application status:",
    //         response.status,
    //         response.data
    //       );
    //     }
    //   } catch (error) {
    //     console.error("Request error:", error);
    //   }
    // };

    const fetchBookingStatus = async () => {
      try {
        const response = await axiosInstance.post(
          "/v1/booking/view-all/by/performer",
          { logged_in_profile_id: loggedInProfileId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const bookings = response.data.bookings;
          const booking = bookings.find(
            (b) => b.opportunity_id === opportunityId
          );

          if (booking) {
            setHasApplied(true);
            setBookings([booking]);
            setBookingStatus(booking.status);
            console.log(booking.status);
          } else {
            setHasApplied(false);
            setBookings([]);
            setBookingStatus(null);
          }
        } else {
          console.error(
            "Error fetching bookings:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error while fetching bookings:", error);
      }
    };

    fetchOpportunity();
    // checkIfApplied();
    fetchBookingStatus();
  }, [selectedOpportunityId, accessToken]);

  const getVenueProfileImage = () => {
    return venue?.thumbnail_profile_pic_url || venue?.profile_picture_url;
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  const handleApplyButtonClick = async () => {
    setIsLoadingApply(true);
    if (opportunity.status !== "OPEN") {
      setTooltipVisible(true);
      return;
    }
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const opportunityId = selectedOpportunityId;

    const payload = {
      opportunity_id: opportunityId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post("/v1/booking/apply", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your application has been successful!",
        });
        setHasApplied(true);
        setOpportunity((prevOpportunity) => ({
          ...prevOpportunity,
          status: "APPLIED",
        }));
        console.log("Application successful:", response.data);
        // navigate(`/performer/opportunity/${opportunityId}`);
      } else {
        console.error("Application error:", response.status, response.data);
      }
    } catch (error) {
      console.error("Request error:", error);
      let errorMessage = "An error occurred while submitting your application.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setIsLoadingApply(false);
    }
  };

  const handleWithdrawButtonClick = async () => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      opportunity_id: selectedOpportunityId,
      logged_in_profile_id: loggedInProfileId,
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to withdraw your application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, withdraw",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      setIsLoadingWithdraw(true);
      try {
        const response = await axiosInstance.post(
          "/v1/booking/withdraw/application/by-performer",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("Withdrawal successful:", response.data);
          setHasApplied(false);
          setWithdrawn(true);
          setOpportunity((prevOpportunity) => ({
            ...prevOpportunity,
            status: "OPEN",
          }));
          // navigate(`/performer/opportunity/${selectedOpportunityId}`);
        } else {
          console.error("Withdrawal error:", response.status, response.data);
        }
      } catch (error) {
        console.error("Request error:", error);
      } finally {
        setIsLoadingWithdraw(false);
      }
    } else {
      setIsLoadingWithdraw(false);
    }
  };

  const bookingStatusText = {
    VENUE_REJECTED: "Venue has rejected your application",
    PERFORMER_APPLIED: "You have applied for this opportunity",
    PERFORMER_WITHDRAWN: "You have withdrawn your application",
    VENUE_CONFIRMED: "Venue Confirmed your request",
    VENUE_TERMINATED_CONTRACT: "Venue terminated the contract.",
    PERFORMER_DOUBLED_CONFIRMED: "Performer double confirmed",
    PERFORMER_CANCELLED_CONTRACT: "Contract has been cancelled",
    PERFORMER_DECLINED: "Confirmation request has been declined",
  };

  const handleDoubleConfirm = async (bookingId) => {
    setIsLoadingDoubleConfirm(true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/double-confirm/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "Double confirmed!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_DOUBLED_CONFIRMED" }
              : booking
          )
        );
      } else {
        console.error(
          "Error confirming request:",
          response.status,
          response.data
        );
        Swal.fire(
          "Error",
          "An error occurred while canceling the contract.",
          "error"
        );
      }
    } catch (error) {
      console.error("confirm Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    } finally {
      setIsLoadingDoubleConfirm(false);
    }
  };

  const handleReject = async (bookingId) => {
    setIsLoadingReject(true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/decline/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "Confirmation rejected!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_DECLINED" }
              : booking
          )
        );
      } else {
        console.error(
          "Error rejecting confirmation:",
          response.status,
          response.data
        );

        Swal.fire(
          "Error",
          "An error occurred while confirming the request.",
          "error"
        );
      }
    } catch (error) {
      console.error("reject confirmation error:", error);
      Swal.fire("Error", "An error occurred", "error");
    } finally {
      setIsLoadingReject(false);
    }
  };

  const handleCancelContract = async (bookingId) => {
    setIsLoadingCancel(true);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-request/contract/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "contract cancellation request sent!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_CANCELLED_CONTRACT" }
              : booking
          )
        );
      } else {
        console.error(
          "Error cancelling the contract:",
          response.status,
          response.data
        );

        Swal.fire("Error", "Failed to cancel contract", "error");
      }
    } catch (error) {
      console.error("cancel contract error:", error);
      Swal.fire("Error", "An error occurred", "error");
    } finally {
      setIsLoadingCancel(false);
    }
  };

  const [selectedperformerId, setSelectedperformerId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    console.log("author id", authorId);

    setSelectedperformerId(authorId);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedperformerId(null);
    setShowModal(false);
  };

  return (
    <Container style={{ backgroundColor: "#f0f2f5" }}>
      {opportunity && (
        <>
          <LeftColumn>
            <CategoryTag>{opportunity?.status}</CategoryTag>
            <Title>{opportunity?.title}</Title>
            <InfoRow>
              <Info>
                <ProfileImage
                  src={getVenueProfileImage()}
                  alt="Venue Profile"
                />
              </Info>

              <Info>
                Venue:{"  "}
                <a
                  className="link-btn"
                  onClick={() => openModal()}
                  style={{ cursor: "pointer" }}
                >
                  <strong>{venue ? venue.profile_name : "Loading..."}</strong>
                </a>
              </Info>
              {/* <Info>
            Last Update:
            <br />
            <strong> June 7, 2024</strong>
          </Info> */}
            </InfoRow>

            {/* <Tabs>
          <Tab
            active={activeTab === "opportunityInfo"}
            onClick={() => setActiveTab("opportunityInfo")}
          >
            Opportunity Info
          </Tab>
          <Tab
            active={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Reviews
          </Tab>
        </Tabs> */}
            <Content>
              <About>
                <h3>Description</h3>
                <p>{opportunity?.descriptions}</p>
                {/* <ButtonTagBox>Show more &gt;&gt;</ButtonTagBox> */}
              </About>
              {/* {activeTab === "opportunityInfo" && (
            <>
              
            </>
          )} */}
              {/* {activeTab === "media" && <div>Reviews content goes here...</div>} */}
            </Content>
          </LeftColumn>
          <RightColumn>
            <SideCard>
              {isLoadingOpportunity ? (
                <center>
                  <Spinner animation="border" size="lg" />
                </center>
              ) : (
                <>
                  {hasApplied && bookingStatus && (
                    <center>
                      <div
                        style={{
                          marginBottom: "10px",
                          padding: "10px",
                          backgroundColor: "#e9ecef",
                          color: "#495057",
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {bookingStatusText[bookingStatus] || `${bookingStatus}`}
                      </div>
                    </center>
                  )}
                  {/* <EnrollButton></EnrollButton> */}
                  {/* <div className="buttonsContainer"> */}
                  {opportunity.status !== "CONFIRMED" &&
                    bookingStatus !== "VENUE_REJECTED" &&
                    bookingStatus !== "VENUE_CONFIRMED" &&
                    bookingStatus !== "VENUE_TERMINATED_CONTRACT" &&
                    bookingStatus !== "PERFORMER_DECLINED" && (
                      <center>
                        {hasApplied ? (
                          <button
                            className="oppbutton2"
                            style={{
                              cursor: isLoadingWithdraw
                                ? "not-allowed"
                                : "pointer",
                              opacity: isLoadingWithdraw ? 0.6 : 1,
                              transition: "opacity 0.3s ease",
                              padding: "15px 50px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                            onClick={handleWithdrawButtonClick}
                            disabled={isLoadingWithdraw}
                          >
                            {isLoadingWithdraw ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Withdraw"
                            )}
                          </button>
                        ) : (
                          <>
                            <button
                              className="oppbutton2"
                              style={{
                                cursor: isLoadingApply
                                  ? "not-allowed"
                                  : "pointer",
                                padding: "15px 50px",
                                width: "100%",
                                fontSize: "16px",
                                opacity: isLoadingApply ? 0.6 : 1,
                                transition: "opacity 0.3s ease",
                              }}
                              onClick={handleApplyButtonClick}
                              disabled={isLoadingApply || isApplied}
                              // disabled={isApplied}
                            >
                              {/* {isApplied ? "APPLIED" : "APPLY"}{" "} */}
                              {isLoadingApply ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Apply"
                              )}
                              {/* Conditionally render the label */}
                            </button>
                            {tooltipVisible && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  padding: "10px",
                                  backgroundColor: "#f8d7da",
                                  color: "black",
                                  border: "1px solid #f5c6cb",
                                  borderRadius: "4px",
                                }}
                              >
                                This opportunity post is in {opportunity.status}{" "}
                                status, you can't apply to this.
                              </div>
                            )}
                          </>
                        )}
                      </center>
                    )}
                  {/* <div>
                {bookings.map((booking, index) => (
  <div key={index}>
    {booking.status === "VENUE_CONFIRMED" ? (
      <>
                
                  <button
                    variant="secondary"
                    onClick={() => handleDoubleConfirm(booking._id)}
                    style={{
                      padding: "15px 50px",
                      width: "100%",
                      fontSize: "16px",
                      backgroundColor: "#29d5a2",
                      color: "#721c24",
                    }}
                  >
                    Double Confirm
                  </button>
                  
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleReject(booking._id)}
                    style={{ marginTop: "10px", marginLeft: "8px" }}
                  >
                    Reject
                  </Button>
                </>
              ) : bookingStatus === "PERFORMER_DOUBLED_CONFIRMED" ? (
                <>
                 
                  
                  <br></br>
                  <Button
                    variant="warning"
                    className="ms-2"
                    onClick={() => handleCancelContract(booking._id)}
                    style={{ marginTop: "10px", marginLeft: "8px" }}
                  >
                    Cancel Contract
                  </Button>
                </>
              ) : null}
              </div>
               ) )} */}

                  {bookings.map((booking, index) => (
                    <div key={index}>
                      {booking.status === "VENUE_CONFIRMED" ? (
                        <>
                          <Button
                            // variant="secondary"
                            onClick={() => handleDoubleConfirm(booking._id)}
                            disabled={isLoadingDoubleConfirm}
                            style={{
                              cursor: isLoadingDoubleConfirm
                                ? "not-allowed"
                                : "pointer",
                              opacity: isLoadingDoubleConfirm ? 0.6 : 1,
                              transition: "opacity 0.3s ease",
                              padding: "10px 45px",
                              borderRadius: "3px",
                              width: "100%",
                              fontSize: "16px",
                              marginBottom: "3%",
                              backgroundColor: "#29d5a2",
                              // color: "#721c24",
                            }}
                          >
                            {isLoadingDoubleConfirm ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Double Confirm"
                            )}
                          </Button>
                          <Button
                            variant="danger"
                            // className="ms-2"
                            onClick={() => handleReject(booking._id)}
                            disabled={isLoadingReject}
                            style={{
                              cursor: isLoadingReject
                                ? "not-allowed"
                                : "pointer",
                              opacity: isLoadingReject ? 0.6 : 1,
                              transition: "opacity 0.3s ease",
                              padding: "10px 45px",
                              backgroundColor: "#e8855f",
                              borderRadius: "3px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          >
                            {isLoadingReject ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Reject"
                            )}
                          </Button>
                        </>
                      ) : booking.status === "PERFORMER_DOUBLED_CONFIRMED" ? (
                        <>
                          <Button
                            variant="warning"
                            // className="ms-2"
                            onClick={() => handleCancelContract(booking._id)}
                            disabled={isLoadingCancel}
                            style={{
                              cursor: isLoadingCancel
                                ? "not-allowed"
                                : "pointer",
                              opacity: isLoadingCancel ? 0.6 : 1,
                              transition: "opacity 0.3s ease",
                              padding: "10px 45px",
                              backgroundColor: "#e8855f",
                              borderRadius: "3px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          >
                            {isLoadingCancel ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Cancel Contract"
                            )}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  ))}

                  <br></br>
                  <Stats>
                    <DateContainer>
                      <span style={{ marginBottom: "18px" }}>
                        Price:{" "}
                        <DateText>
                          AUD {opportunity.price_point_value_from}-
                          {opportunity.price_point_value_to}
                        </DateText>
                      </span>
                      <span style={{ marginBottom: "18px" }}>
                        Location:{" "}
                        <DateText>{opportunity.location.address}</DateText>
                      </span>
                      <span style={{ marginBottom: "18px" }}>
                        From: {"  "}
                        <DateText>
                          {formatDate(opportunity.from_date)}
                          {"   "}
                          {formatTime(opportunity.from_time)}
                        </DateText>
                      </span>
                      <span style={{ marginBottom: "18px" }}>
                        To: {"  "}
                        <DateText>
                          {formatDate(opportunity.to_date)}
                          {"   "}
                          {formatTime(opportunity.to_time)}
                        </DateText>
                      </span>
                      {/* <span style={{ marginBottom: "18px" }}>
                    From: {"  "}
                    <DateText>
                      {formatDate(opportunity.from_date)}
                      {"   "}
                      {formatTime(opportunity.from_time)}
                      <strong> To: {"  "} </strong>
                      {formatDate(opportunity.to_date)}
                      {"   "}
                      {formatTime(opportunity.to_time)}
                    </DateText>
                  </span> */}
                      <span>
                        Status: <DateText>{opportunity?.status}</DateText>
                      </span>
                    </DateContainer>
                  </Stats>

                  <TagContainer>
                    <h6>Opportunity Types:</h6>
                    {opportunity?.opportunity_types.map((genre) => (
                      <TagBox key={genre}>{genre}</TagBox>
                    ))}
                  </TagContainer>

                  <TagContainer>
                    <h6>Opportunity genres:</h6>
                    {opportunity?.opportunity_genres.map((type) => (
                      <TagBox key={type}>{type}</TagBox>
                    ))}
                  </TagContainer>
                </>
              )}
            </SideCard>
          </RightColumn>
        </>
      )}

      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ minWidth: "110% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "0px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {selectedperformerId && (
            <NewVenueProfile profileId={selectedperformerId} />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NewOpportunityPerformerView;
