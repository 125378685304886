import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Modal, Row } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import OpportunityPerformerView from "./opportunityPerformerView";
import OpportunityVenueView from "./opportunityVenueView";
import OpportunityAuthorVenueView from "./opportunityAuthorVenueView";
import { RiFlag2Fill } from "react-icons/ri";
import NewOpportunityAuthorVenueView from "../newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../newView/NewOpportunityPerformerView";
import NewOpportunityVenueView from "../newView/NewOpportunityVenueView";
import Swal from "sweetalert2";

function ViewOpportunitiesbyOthers(props) {
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  // const { profileId } = useParams();
  const params = useParams();
  const profileId = props.profileId || params.profileId;
  const profileIdn = profileId; //Cookies.get("profileId");
  const [opportunities, setopportunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);
  const { opportunityId } = useParams();
  const [opportunity, setopportunity] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [flaggedOpportunity, setFlaggedOpportunity] = useState({});

  useEffect(() => {
    // Cookies.set("publicProfileId", profileId);
    const fetchopportunities = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/published/by/profile/${profileIdn}`
        );
        if (response.status === 200) {
          const data = response.data;
          setopportunities(data.opportunities_published);
        } else {
          console.error("Error fetching opportunities:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchopportunities();
  }, []);

  //   const handleopportunityClick = () => {
  //     if (opportunity) {
  //       Cookies.set("opportunityId", opportunityId); // Save opportunityId in cookies
  //       navigate(`/opportunity/${opportunityId}`); // Redirect to opportunity venue view page
  //     }
  //   };

  const handleopportunityClick = (opportunityId, authorProfileId) => {
    Cookies.set("toProfileIdFirst", authorProfileId);
    Cookies.set("opportunityId", opportunityId);
    console.log("Clicked opportunityId:", opportunityId);
    navigate(`/opportunity/${opportunityId}`);
  };
  //   const handleopportunityClick = (opportunityId) => {
  //     Cookies.set("opportunityId", opportunityId);
  //     console.log("Clicked opportunityId:", opportunityId);
  //     navigate(`/opportunity/${opportunityId}`);
  //   };

  const handleViewMore = () => {
    setViewableCount(opportunities.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  //   const handleSendMessage = (authorId) => {
  //     // Store author's ID in cookies
  //     Cookies.set("toProfileId", authorId);
  //     // Navigate to messaging page
  //     navigate("/messaging");
  //   };

  const profileOwnerId = Cookies.get("profileOwnerId");

  const handleSendMessage = async (toProfileId) => {
    try {
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_classified/${profileOwnerId}/${toProfileId}`,
        { title: "clasifid" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        navigate("/messaging");
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  //   const handleOpportunityClick = (opportunityId) => {
  //     Cookies.set("opportunityId", opportunityId);
  //     console.log("Clicked opportunityId:", opportunityId);
  //     navigate(`/venue/opportunity/author/${opportunityId}`);
  //   };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    // Cookies.set("opportunityId", id);
    // console.log("opportunityId cookie value:", Cookies.get("opportunityId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(Cookies.get("profileOwnerId"));
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
      let errorMessage = "There was an error fetching opportunity details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedOpportunityId(null);
    setShowModal(false);
  };

  const handleFlagClick = async (opportunity_id) => {
    try {
      const response = await axiosInstance.post(
        "/v1/opportunity/flag-opportunity",
        {
          reporting_profile_id: Cookies.get("profileOwnerId"),
          opportunity_id: opportunity_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setFlaggedOpportunity((prev) => ({ ...prev, [opportunity_id]: true }));
      } else {
        console.error("Error flagging opportunity:", response.statusText);
      }
    } catch (error) {
      console.error("Error flagging opportunity:", error);
    }
  };

  // const formatDate = (dateObject) => {
  //   if (!dateObject) return "";
  //   return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  // };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.day}-${dateObject.month}-${dateObject.year}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>
          <h4>Opportunities</h4>
        </strong>
      </center>
      <br />
      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading opportunities...</span>
        </div>
      ) : (
        <Row>
          {opportunities.length > 0 ? (
            <>
              {opportunities.slice(0, viewableCount).map((opportunity) => (
                <div
                  key={opportunity._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  // onClick={() => handleopportunityClick(opportunity._id)}
                  onClick={() =>
                    openModal(opportunity._id, opportunity.author_profile_id)
                  }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{opportunity.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{opportunity.short_description}</p>
                        </div>
                        <div className="events__details-inline">
                          <strong>Location:</strong>{" "}
                          {opportunity.location.suburb}
                        </div>
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(opportunity.from_date)}
                          {"   "}
                          {formatTime(opportunity.from_time)}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(opportunity.to_date)}
                          {"   "}
                          {formatTime(opportunity.to_time)}
                        </div>

                        {/* <strong>Date:</strong>
                          {typeof opportunity.from_date === "object" &&
                          opportunity.from_date &&
                          typeof opportunity.from_date.year === "number" &&
                          typeof opportunity.from_date.month === "number" &&
                          typeof opportunity.from_date.day === "number" &&
                          opportunity.from_date.year > 0 &&
                          opportunity.from_date.month >= 1 &&
                          opportunity.from_date.month <= 12 &&
                          opportunity.from_date.day >= 1 &&
                          opportunity.from_date.day <= 31
                            ? new Date(
                                opportunity.from_date.year,
                                opportunity.from_date.month - 1,
                                opportunity.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : typeof opportunity.from_date === "string"
                            ? opportunity.from_date
                            : ""} */}

                        {/* {typeof opportunity.from_date === "object"
                            ? new Date(
                                opportunity.from_date.year,
                                opportunity.from_date.month - 1,
                                opportunity.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : opportunity.from_date} */}

                        {/* <div className="events__details-inline">
                          <strong>Time:</strong>
                          {typeof opportunity.from_time === "object"
                            ? `${opportunity.from_time.hour}:${opportunity.from_time.minute} ${opportunity.from_time.am_pm}`
                            : opportunity.from_time}
                        </div> */}
                        <Button
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFlagClick(opportunity._id);
                          }}
                          style={{
                            color: flaggedOpportunity[opportunity._id]
                              ? "red"
                              : "black",
                            zIndex: 1,
                          }}
                        >
                          <RiFlag2Fill />
                        </Button>
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          // onClick={() =>
                          //   handleopportunityClick(opportunity._id)
                          // }
                          onClick={() =>
                            openModal(
                              opportunity._id,
                              opportunity.author_profile_id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {viewableCount < opportunities.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({opportunities.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === opportunities.length &&
                opportunities.length > 2 && (
                  <center>
                    <Button
                      variant="link"
                      onClick={handleViewLess}
                      style={{ color: "#418abb" }}
                    >
                      View Less
                    </Button>
                  </center>
                )}
            </>
          ) : (
            <p className="text-center">No opportunities found.</p>
          )}
        </Row>
        // <div>
        //   {opportunities.length > 0 ? (
        //     <>
        //       {opportunities.slice(0, viewableCount).map((opportunity) => (
        //         <Card
        //           key={opportunity._id}
        //           className="mb-3"
        //           onClick={() => openModal(opportunity._id)}
        //           style={{
        //             backgroundColor: "#e8e8e8",
        //             borderRadius: "15px",
        //             cursor: "pointer",
        //           }}
        //         >
        //           <Card.Body>
        //             <h4>
        //               <strong style={{ fontWeight: "bold" }}>
        //                 {opportunity.title}
        //               </strong>
        //             </h4>
        //             <p>{opportunity.short_description}</p>
        //             <p>
        //               <strong>Location:</strong> {opportunity.location.suburb}
        //             </p>
        //             {/* <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof opportunity.from_date === "object"
        //                 ? new Date(
        //                     opportunity.from_date.year,
        //                     opportunity.from_date.month - 1,
        //                     opportunity.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : opportunity.from_date}
        //             </p> */}
        //             <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof opportunity.from_date === "object" &&
        //               opportunity.from_date &&
        //               typeof opportunity.from_date.year === "number" &&
        //               typeof opportunity.from_date.month === "number" &&
        //               typeof opportunity.from_date.day === "number" &&
        //               opportunity.from_date.year > 0 &&
        //               opportunity.from_date.month >= 1 &&
        //               opportunity.from_date.month <= 12 &&
        //               opportunity.from_date.day >= 1 &&
        //               opportunity.from_date.day <= 31
        //                 ? new Date(
        //                     opportunity.from_date.year,
        //                     opportunity.from_date.month - 1,
        //                     opportunity.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : typeof opportunity.from_date === "string"
        //                 ? opportunity.from_date
        //                 : ""}
        //             </p>
        //             <p>
        //               <strong>Time:</strong>{" "}
        //               {typeof opportunity.from_time === "object"
        //                 ? `${opportunity.from_time.hour}:${opportunity.from_time.minute} ${opportunity.from_time.am_pm}`
        //                 : opportunity.from_time}
        //             </p>

        //             {/* <Button
        //               variant="link"
        //               onClick={() =>
        //                 handleSendMessage(opportunity.author_profile_id)
        //               }
        //             >
        //               <FaEnvelope />
        //             </Button> */}

        //             <Button
        //               variant="link"
        //               onClick={(e) => {
        //                 e.stopPropagation();
        //                 handleFlagClick(opportunity._id);
        //               }}
        //               style={{
        //                 color: flaggedOpportunity[opportunity._id]
        //                   ? "red"
        //                   : "black",
        //                 zIndex: 1,
        //               }}
        //             >
        //               <RiFlag2Fill />
        //             </Button>
        //           </Card.Body>
        //         </Card>
        //       ))}
        //       {viewableCount < opportunities.length && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewMore}
        //             style={{ color: "#418abb" }}
        //           >
        //             View More ({opportunities.length - viewableCount} more)
        //           </Button>
        //         </center>
        //       )}
        //       {viewableCount === opportunities.length &&
        //         opportunities.length > 2 && (
        //           <center>
        //             <Button
        //               variant="link"
        //               onClick={handleViewLess}
        //               style={{ color: "#418abb" }}
        //             >
        //               View Less
        //             </Button>
        //           </center>
        //         )}
        //     </>
        //   ) : (
        //     <p className="text-center">No opportunities found.</p>
        //   )}
        // </div>
      )}

      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ alignSelf: "center" }}
        // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewOpportunitiesbyOthers;
