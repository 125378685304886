import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import axiosInstance from "../pages/auth/axiosInstance";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";


const CreateSupportTicket = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [ticketTitle, setTicketTitle] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);

      try {
        const response = await axiosInstance.get("/v1/profile/by/user-id", {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        if (response.status === 200) {
          const { performer_profiles, venue_profiles } = response.data;
          const allProfiles = [...performer_profiles, ...venue_profiles];
          setProfiles(allProfiles);

          console.log("Profiles fetched:", allProfiles); // Debugging log
        } else {
          setError("Error fetching profiles");
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  // Reset error when a valid profile is selected
  useEffect(() => {
    if (selectedProfile) {
      setError("");  // Clear the error when profile is selected
    }
  }, [selectedProfile]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    if (!selectedProfile) {
      setError("Please select a profile.");
      setLoading(false);
      return;
    }

    const profileId = selectedProfile._id;

    if (!profileId) {
      setError("Selected profile does not have a valid ID.");
      setLoading(false);
      return;
    }

    // Debugging logs
    console.log("Selected Profile:", selectedProfile);
    console.log("Profile ID:", profileId);
    console.log("Cookie (profileId):", Cookies.get("profile_id"));

    const payload = {
      author_profile_id: profileId,
      title: ticketTitle,
      message: [message],
    };

    // Debugging logs
    console.log("Payload:", payload);

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/v1/support/create-ticket",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );



      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your support ticket was successfully submitted.",
        });


        console.log("Ticket added:", response.data);
        // setSuccessMessage("Your support ticket successfully submitted");
        setSelectedProfile(null);
        setTicketTitle("");
        setMessage("");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        // setError("Error submitting ticket: " + response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error submitting ticket: " + response.statusText,
        });
      }
    } catch (error) {
      console.error("Error adding ticket:", error);
      // setError("An unexpected error occurred. Please try again.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
    }
    // setLoading(false);
  };

  const handleProfileChange = (e) => {
    const profile = JSON.parse(e.target.value);
    console.log("Selected Profile on Change:", profile); // Debugging log
    setSelectedProfile(profile);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "40px",
        borderRadius: "10px",
        boxShadow: "0 2px 9px rgba(0,0,0,0.8)",
        backgroundColor: "#fff",
        zIndex: "1000",
        width: "400px",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Create Support Ticket
        </h2>

        <label
          htmlFor="ticketType"
          style={{ marginBottom: "8px", textAlign: "left" }}
        >
          Profile:
        </label>
        <select
          id="ticketType"
          value={selectedProfile ? JSON.stringify(selectedProfile) : ""}
          onChange={handleProfileChange}
          style={{
            padding: "10px",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          <option value="">Select a profile</option>
          {profiles.map((profile, index) => (
            <option key={index} value={JSON.stringify(profile)}>
              {profile.profile_name}
            </option>
          ))}
        </select>

        <label
          htmlFor="ticketTitle"
          style={{ marginBottom: "8px", textAlign: "left" }}
        >
          Title:
        </label>
        <input
          type="text"
          id="ticketTitle"
          value={ticketTitle}
          onChange={(e) => setTicketTitle(e.target.value)}
          style={{
            padding: "10px",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "16px",
            textAlign: "left",
          }}
        />

        <label
          htmlFor="message"
          style={{ marginBottom: "8px", textAlign: "left" }}
        >
          Message:
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{
            padding: "10px",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "16px",
            resize: "vertical",
            height: "100px",
            textAlign: "left",
          }}
        />

        {/* {successMessage && (
          <p
            style={{
              color: "green",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {successMessage}
          </p>
        )}

        {error && (
          <p
            style={{ color: "red", textAlign: "center", marginBottom: "20px" }}
          >
            {error}
          </p>
        )} */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="button"
            type="submit"
            disabled={loading}
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "16px",
            }}


          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit T"
            )}

          </button>
        </div>

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "grey",
            fontSize: "24px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          &#x2715; {/* Close icon (X) */}
        </div>
      </form>
    </div>
  );
};

export default CreateSupportTicket;