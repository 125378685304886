import { Route, Routes, useLocation, Link } from "react-router-dom";
// import Index from "./pages/index";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assect/scss/style.scss";
import "./assect/css/materialdesignicons.min.css";
import Footer from "./components/footer";
import ScrollTop from "./components/scrollTop";
// import IndexTwo from "./pages/index/index-two";
// import IndexThree from "./pages/index/index-three";
// import IndexFour from "./pages/index/index-four";
// import IndexFive from "./pages/index/index-five";
// import IndexSix from "./pages/index/index-six";
// import IndexSeven from "./pages/index/index-seven";
// import Buy from "./pages/buy";
// import Sell from "./pages/sell";
// import Grid from "./pages/listing/grid";
// import GridSidebar from "./pages/listing/grid-sidebar";
// import List from "./pages/listing/list";
// import ListSidebar from "./pages/listing/list-sidebar";
// import PropertyDetails from "./pages/listing/property-detail";
// import PropertyDetailsTwo from "./pages/listing/property-detail-two";
// import AboutUs from "./pages/aboutus";
// import Features from "./pages/features";
// import Pricing from "./pages/pricing";
import Faqs from "./pages/OtherPages/faqs";
// import Terms from "./pages/terms";
// import Privacy from "./pages/privacy";
// import Blogs from "./pages/blogs";
// import BlogSidebar from "./pages/blog-sidebar";
// import BlogDetail from "./pages/blog-detail";
// import ContactUs from "./pages/contactus";
import AuthLogin from "./pages/auth/auth-login";
import AuthLoginT from "./pages/auth/auth-loginT";
import ResetPassword from "./pages/auth/auth-re-password";
import ResetPasswordT from "./pages/auth/auth-re-passwordT";
import Signup from "./pages/auth/auth-signup";
import SignupT from "./pages/auth/auth-signupT";
import Comingsoon from "./pages/Special/comingsoon";
import Maintenance from "./pages/Special/maintenance";
import Error from "./pages/Special/error";
import Error2 from "./pages/Special/error2";
import AuthProvider from "./pages/auth/AuthProvider";
import ProtectedRoute from "./pages/auth/protectedRoute";
import ChangePassword from "./pages/auth/auth-change-password";
import ChangePasswordT from "./pages/auth/auth-change-passwordT";

import CreatePerformerProfile from "./pages/performer/createPerformerProfile";
import CreatePerformerProfileT from "./pages/performer/createPerformerProfileT";
import CreatePerformerProfileN from "./pages/performer/createPerformerProfileN";
import ViewPerformerProfile from "./pages/performer/viewPerformerProfile";
import UpdateProfile from "./pages/performer/updatePerformerInfo";
import UpdatePerformerContact from "./pages/performer/updatePerformerContact";
import UpdatePerformerContactT from "./pages/performer/updatePerformerContactT";
import UpdatePerformerSocialMedia from "./pages/performer/updatePerformerSocialMedia";
import UpdatePerformerSocialMediaT from "./pages/performer/updatePerformerSocialMediaT";
import UpdatePerformerExperience from "./pages/performer/updatePerformerExperience";
import UpdatePerformerExperienceT from "./pages/performer/updatePerformerExperienceT";
import UpdatePerformerPerformanceHistory from "./pages/performer/updatePerformerHistory";
import UpdatePerformerPerformanceHistoryT from "./pages/performer/updatePerformerHistoryT";
import UpdatePerformerEquipReq from "./pages/performer/updatePerformerEquipReq";
import UpdatePerformerEquipReqT from "./pages/performer/updatePerformerEquipReqT";
import UpdatePerformerVideoGallery from "./pages/performer/updateVideoGallery";
import UpdatePerformerVideoGalleryT from "./pages/performer/updateVideoGalleryT";
import Profile from "./pages/OtherPages/profiles";
// import ProfileSelectionPage from "./pages/selectProfiles";
import ViewVenueProfile from "./pages/venue/viewVenueProfile";
import VenueOppurtunityApplicants from "./pages/venue/venueOppurtunityApplicants";
import AddNewOppurtunity from "./pages/opportunities/addNewOppurtunity";
import VenueSearch from "./pages/search/venueSearch";
import PerformerSearch from "./pages/search/performerSearch";
import OppurtunitySearch from "./pages/search/oppurtunitySearch";
import CalloutSearch from "./pages/search/calloutSearch";
import ClassifiedSearch from "./pages/search/classifiedSearch";
import CreateVenueProfile from "./pages/venue/createVenue";
import CreateVenueProfileT from "./pages/venue/createVenueT";
import ProfileViewer from "./components/profileViewer";
import UpdateVenueProfile from "./pages/venue/updateVenueInfo";
import UpdateVenueContact from "./pages/venue/updateVenueContact";
import UpdateVenueContactT from "./pages/venue/updateVenueContactT";
import UpdateVenueSocialMedia from "./pages/venue/updateVenueSocialMedia";
import UpdateVenueSocialMediaT from "./pages/venue/updateVenueSocialMediaT";
import UpdateVenueVideoGallery from "./pages/venue/updateVenueVideogallery";
import UpdateVenueVideoGalleryT from "./pages/venue/updateVenueVideogalleryT";
import UpdateVenuePerformanceHistory from "./pages/venue/updateVenueHistory";
import UpdateVenuePerformanceHistoryT from "./pages/venue/updateVenueHistoryT";
import AllProfiles from "./pages/OtherPages/allProfiles";
import OpportunityCard from "./pages/calendarPage";
import LandingPage from "./pages/OtherPages/landing";
import ScheduleEventScreen from "./pages/OtherPages/bookRequest";
import OpportunityPerformerView from "./pages/opportunities/opportunityPerformerView";
import AddNewCallout from "./pages/callouts/createCalloutbyVenue";
import CreateClassified from "./pages/classifieds/createClassifieds";
import Messaging from "./pages/messaging/messaging";
import ClassifiedVenue from "./pages/classifieds/ClassifiedVenueView";
import UpdatePerformerGenre from "./pages/performer/updatePerformerGenre";
import UpdatePerformerGenreT from "./pages/performer/updatePerformerGenreT";
import UpdatePerformerType from "./pages/performer/updatePerformerType";
import UpdatePerformerTypeT from "./pages/performer/updatePerformerTypeT";
import UpdatePerformerFirstName from "./pages/performer/updatePerformerFirstName";
import UpdatePerformerFirstNameT from "./pages/performer/updatePerformerFirstNameT";
import UpdatePerformerPricePoint from "./pages/performer/updatePerformerPricePoint";
import UpdatePerformerPricePointT from "./pages/performer/updatePerformerPricePointT";
import UpdatePerformerLocation from "./pages/performer/updatePerformerLocation";
import UpdatePerformerLocationT from "./pages/performer/updatePerformerLocationT";
import UpdateVenueType from "./pages/venue/updateVenueType";
import UpdateVenueTypeT from "./pages/venue/updateVenueTypesT";
import UpdateVenueGenre from "./pages/venue/updateVenueGenre";
import UpdateVenueGenreT from "./pages/venue/updateVenueGenreT";
import UpdateVenuePricePoint from "./pages/venue/updateVenuePricePoint";
import UpdateVenuePricePointT from "./pages/venue/updateVenuePricePointT";
import UpdateVenueName from "./pages/venue/updateVenueName";
import UpdateVenueNameT from "./pages/venue/updateVenueNameT";
import NewPerformerProfile from "./pages/OtherPages/newPerformerProfile";
import UpdateVenueAbn from "./pages/venue/updateVenueAbn";
import UpdateVenueAbnT from "./pages/venue/updateVenueAbnT";
import UpdateVenueBusiness from "./pages/venue/updateVenueBusiness";
import UpdateVenueBusinessT from "./pages/venue/updateVenueBusinessT";
import OpportunityAuthorVenueView from "./pages/opportunities/opportunityAuthorVenueView";
import OpportunityVenueView from "./pages/opportunities/opportunityVenueView";
import PerformerDashboard from "./pages/performer/performerDashboard";
import FAQPage from "./pages/performer/faq";
import CalendarPublicView from "./pages/calendar/calendarPublicView";
import CalendarSelfView from "./pages/calendar/calendarSelfView";
// import NewPerformerProfileTest from "./pages/OtherPages/newProfilePageTesting";
import NewUserLandingPage from "./pages/OtherPages/newUserlanding";
import VerifyEmailPage from "./pages/auth/emailVerification";
import AccountPage from "./pages/account/userAccount";
import AccountRejectedPage from "./pages/account/accountRejected";
// import VerifyEmail from "./pages/auth/emailVerification";
import CreateClassifiedbyPerformer from "./pages/classifieds/createClassifiedbyPerformer";
// import CreateCalloutbyPerformer from "./pages/callouts/createCalloutbyPerformer";
import CreateCalloutbyVenue from "./pages/callouts/createCalloutbyVenue";
import PaymentPackages from "./pages/OtherPages/paymentPackages";
import PerformerPricing from "./pages/pricing/performerPricing";
import VenuePricing from "./pages/pricing/venuePricing";
import CalloutView from "./pages/callouts/calloutPerformerView";
import RetryPayment from "./pages/pricing/retryPayment";
import Pay from "./pages/pricing/paymentpage";
import BillingAndPayment from "./pages/pricing/billingHistory";
import UpdatePerformerFullName from "./pages/performer/updatePerformerFullName";
import UpdatePerformerFullNameT from "./pages/performer/updatePerformerFullNameT";
import FollowedProfilesPage from "./pages/performer/followedProfiles";
import NewVenueProfile from "./pages/OtherPages/newVenueProfile";
import VenueFollowedProfilesPage from "./pages/venue/venueFollowedProfiles";
import UpdatePerformerPhoneNumber from "./pages/performer/updatePerformerPhoneNumber";
import UpdatePerformerPhoneNumberT from "./pages/performer/updatePerformerPhoneNumberT";
import UpdatePerformerEmail from "./pages/performer/updatePerformerEmail";
import UpdatePerformerEmailT from "./pages/performer/updatePerformerEmailT";
import UpdateVenuePhoneNumber from "./pages/venue/updateVenuePhoneNumber";
import UpdateVenuePhoneNumberT from "./pages/venue/updateVenuePhoneNumberT";
import UpdateVenueEmail from "./pages/venue/updateVenueEmail";
import UpdateVenueEmailT from "./pages/venue/updateVenueEmailT";
import Coupons from "./pages/OtherPages/coupons";
import SupportTicketForm from "./components/supportTicketForm";
import SupportTicketFormP from "./components/supportTicketFormT";
import SupportTicketPage from "./components/supportTicketPage";
import VerifyEmail from "./pages/auth/verifyEmailPage";
import VerifyEmailT from "./pages/auth/verifyEmailPageT";
import PhoneEntry from "./pages/auth/phoneEntry";
import PhoneEntryT from "./pages/auth/phoneEntryT";
import VerifyPhone from "./pages/auth/verifyPhone";
import VerifyPhoneT from "./pages/auth/verifyPhoneT";
import NotificationsPage from "./pages/OtherPages/notifications";
import AccountApprovalNotice from "./pages/account/accountApprovalNotice";
import LandingTesting from "./pages/landingT/landingTesting";
import NewPerformerSearch from "./pages/search/newPerformerSearch";
import NewVenueSearch from "./pages/search/newVenueSearch";
import SavedSearches from "./pages/search/savedSearches";
import NewCalloutSearch from "./pages/search/newCalloutSearch";
import NewClassifiedSearch from "./pages/search/newClassifiedSearch";
import NewOpportunitySearch from "./pages/search/newOpportunitySearch";
import DashboardPerformer from "./pages/dashboard/performerDashboard";
import DashboardVenue from "./pages/dashboard/venueDashboard";
import OpportunityAuthorVenueView2 from "./pages/newView/opportunityAuthorVenueView2";
import NewOpportunityAuthorVenueView from "./pages/newView/NewOpportunityAuthorVenueView";
import NewOpportunityVenueView from "./pages/newView/NewOpportunityVenueView";
import NewOpportunityPerformerView from "./pages/newView/NewOpportunityPerformerView";
import NewClassifiedVenueView from "./pages/classifieds/NewClassifiedVenueView";
import PerformerDashboard2 from "./pages/performer/performerDashboard";
import UpdatePerformerEmailVerify from "./pages/performer/updatePerformerEmailVerify";
import UpdatePerformerPhoneVerify from "./pages/performer/updatePerformerPhoneVerify";
import UpdateVenueEmailVerify from "./pages/venue/updateVenueEmailVerify";
import UpdateVenuePhoneVerify from "./pages/venue/updateVenuePhoneVerify";
import UpdateVenueLocation from "./pages/venue/updateVenueLocationT";
import UpdatePerformerTagline from "./pages/performer/updatePerformerTagline";
import UpdateVenueTagline from "./pages/venue/updateVenueTagline";
import UpdatePerformerShortBio from "./pages/performer/updatePerformerShortBio";
import UpdateVenueShortBio from "./pages/venue/updateVenueShortBio";
import TemporaryPasswordSent from "./pages/auth/temporaryPasswordSent";
import RestrictedAccessPage from "./pages/auth/restrictedAccessPage";
import ErrorBoundary from "./components/ErrorBoundary";
import AddPerformerPastVenue from "./pages/performer/addPerformerPastVenues";
import AddEventsAndFestivals from "./pages/performer/addEventsAndFestivals";
import AddCollaborations from "./pages/performer/addCollaborations";
import AddResidencies from "./pages/performer/addResidencies";

// import newFAQ from "./pages/faq/faqs";

function App() {
  const location = useLocation();

  if (typeof window !== "undefined") {
    const observerErrHandler = () => {
      window.addEventListener("error", (event) => {
        if (
          event.message ===
          "ResizeObserver loop completed with undelivered notifications."
        ) {
          event.stopImmediatePropagation();
        }
      });
    };
    observerErrHandler();
  }
  return (
    <>
      <AuthProvider>
        <ErrorBoundary>
          <Routes>
            {/* <Route
            path="/new-view-opportunity"
            element={<NewOpportunityAuthorVenueView />}
          /> */}
            <Route
              path="/temporaryPasswordSent"
              element={<TemporaryPasswordSent />}
            />
            <Route
              path="/performer-dashboard-previous"
              element={<PerformerDashboard2 />}
            />

            <Route
              path="/new-view-opportunity-venue"
              element={<NewOpportunityVenueView />}
            />
            <Route
              path="/new-view-opportunity-performer"
              element={<NewOpportunityPerformerView />}
            />
            {/* <Route path="/:profileId" element={<ProfileViewer />} /> */}
            {/* <Route path="/" element={<ProtectedRoute><Index /></ProtectedRoute>} /> */}
            {/* <Route path="/:performerId" element={<ViewPerformerProfile />} />
          <Route path="/:venueId" element={<ViewVenueProfile />} /> */}

            {/* <Route path="/verify-email-previous" element={<VerifyEmail />} /> */}
            <Route path="/verify-email" element={<VerifyEmailT />} />

            <Route path="/phone-entry-previous" element={<PhoneEntry />} />
            <Route path="/phone-entry" element={<PhoneEntryT />} />

            {/* <Route path="/verify-phone-previous" element={<VerifyPhone />} /> */}
            <Route path="/verify-phone" element={<VerifyPhoneT />} />

            <Route path="/notifications" element={<NotificationsPage />} />

            <Route
              path="/search/performer"
              element={
                <ProtectedRoute>
                  <NewPerformerSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/venue"
              element={
                <ProtectedRoute>
                  <NewVenueSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/call_out"
              element={
                <ProtectedRoute>
                  <NewCalloutSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/classified"
              element={
                <ProtectedRoute>
                  <NewClassifiedSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/opportunity"
              element={
                <ProtectedRoute>
                  <NewOpportunitySearch />
                </ProtectedRoute>
              }
            />

            <Route
              path="/saved-searches"
              element={
                <ProtectedRoute>
                  <SavedSearches />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account-approval-notice"
              element={<AccountApprovalNotice />}
            />

            <Route path="/all-profiles" element={<AllProfiles />} />

            <Route path="/account-page" element={<AccountPage />} />
            <Route
              path="/account-rejected-page"
              element={<AccountRejectedPage />}
            />
            <Route path="/payment-packages" element={<PaymentPackages />} />
            {/* <Route
              path="/new-profile-page"
              element={<NewPerformerProfileTest />}
            /> */}

            <Route path="/performer-pricing" element={<PerformerPricing />} />
            <Route path="/billing-history" element={<BillingAndPayment />} />

            <Route path="/venue-pricing" element={<VenuePricing />} />
            <Route
              path="/create-new-classified"
              element={<CreateClassifiedbyPerformer />}
            />

            <Route path="/retry-payment" element={<RetryPayment />} />

            <Route path="/pay" element={<Pay />} />

            {/* <Route
            path="/performer/:profileId"
            element={<ViewPerformerProfile />}
          /> */}
            <Route
              path="/performer/:profileId"
              element={<NewPerformerProfile />}
            />

            {/* <Route
            path="/classified/:classifiedId"
            element={<ClassifiedVenue />}
          /> */}
            <Route
              path="/classified/:classifiedId"
              element={<NewClassifiedVenueView />}
            />

            <Route path="/callout/:calloutId" element={<CalloutView />} />
            <Route path="/verify-email-new" element={<VerifyEmailPage />} />

            <Route path="/calendar-public" element={<CalendarPublicView />} />
            <Route path="/calendar-self" element={<CalendarSelfView />} />
            <Route path="/messaging/:title" element={<Messaging />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/messaging" element={<Messaging />} />
            <Route path="/add-new-classified" element={<CreateClassified />} />
            <Route path="/add-new-callout" element={<CreateCalloutbyVenue />} />
            {/* <Route
            path="/create-new-callout"
            element={<CreateCalloutbyPerformer />}
          /> */}
            <Route path="/calendar-page" element={<OpportunityCard />} />
            {/* <Route path="/venue/:profileId" element={<ViewVenueProfile />} /> */}
            <Route path="/venue/:profileId" element={<NewVenueProfile />} />
            <Route
              path="/performer/opportunity/:opportunityId"
              element={<OpportunityPerformerView />}
            />
            <Route
              path="/venue/opportunity/author/:opportunityId"
              element={<NewOpportunityAuthorVenueView />}
            />
            <Route
              path="/venue/opportunity/:opportunityId"
              element={<OpportunityVenueView />}
            />

            {/* <Route path="/auth-login" element={<AuthLogin />} /> */}
            <Route path="/schedule-event" element={<ScheduleEventScreen />} />
            {/* <Route path="/home" element={<Index />} /> */}
            {/* <Route path="/login" element={<AuthLogin />} /> */}
            <Route path="/login" element={<AuthLoginT />} />
            <Route path="/landing-previous" element={<LandingPage />} />

            <Route
              path="/new-user-landing-page"
              element={<NewUserLandingPage />}
            />
            <Route path="/" element={<LandingTesting />} />
            {/* <Route path="/landing-testing-page" element={<LandingTesting />} /> */}
            {/* <Route path="/" element={<NewUserLandingPage />} /> */}
            <Route path="/profiles" element={<Profile />} />
            {/* <Route path="/view-venue-profile" element={<ViewVenueProfile />} /> */}
            <Route
              path="/update-performer-genre-previous"
              element={<UpdatePerformerGenre />}
            />

            <Route
              path="/update-performer-genre"
              element={<UpdatePerformerGenreT />}
            />
            <Route
              path="/update-venue-business-previous"
              element={<UpdateVenueBusiness />}
            />
            <Route
              path="/update-venue-business"
              element={<UpdateVenueBusinessT />}
            />
            <Route
              path="/update-venue-abn-previous"
              element={<UpdateVenueAbn />}
            />
            <Route path="/update-venue-abn" element={<UpdateVenueAbnT />} />
            <Route
              path="/update-venue-name-previous"
              element={<UpdateVenueName />}
            />
            <Route path="/update-venue-name" element={<UpdateVenueNameT />} />
            <Route
              path="/update-venue-type-previous"
              element={<UpdateVenueType />}
            />
            <Route path="/update-venue-type" element={<UpdateVenueTypeT />} />
            <Route
              path="/update-venue-genre-previous"
              element={<UpdateVenueGenre />}
            />
            <Route path="/update-venue-genre" element={<UpdateVenueGenreT />} />
            <Route
              path="/update-venue-phoneNumber-previous"
              element={<UpdateVenuePhoneNumber />}
            />
            <Route
              path="/update-venue-phoneNumber"
              element={<UpdateVenuePhoneNumberT />}
            />
            <Route
              path="/update-venue-email-previous"
              element={<UpdateVenueEmail />}
            />
            <Route path="/update-venue-email" element={<UpdateVenueEmailT />} />
            <Route
              path="/update-venue-pricepoint-previous"
              element={<UpdateVenuePricePoint />}
            />
            <Route
              path="/update-venue-pricepoint"
              element={<UpdateVenuePricePointT />}
            />

            <Route
              path="/update-venue-location"
              element={<UpdateVenueLocation />}
            />

            <Route
              path="/verify-performer-email"
              element={<UpdatePerformerEmailVerify />}
            />
            <Route
              path="/verify-performer-phone"
              element={<UpdatePerformerPhoneVerify />}
            />

            <Route
              path="/verify-venue-email"
              element={<UpdateVenueEmailVerify />}
            />
            <Route
              path="/verify-venue-phone"
              element={<UpdateVenuePhoneVerify />}
            />

            <Route
              path="/update-performer-location-previous"
              element={<UpdatePerformerLocation />}
            />

            <Route
              path="/update-performer-location"
              element={<UpdatePerformerLocationT />}
            />
            <Route
              path="/update-performer-pricepoint-previous"
              element={<UpdatePerformerPricePoint />}
            />
            <Route
              path="/update-performer-pricepoint"
              element={<UpdatePerformerPricePointT />}
            />
            <Route
              path="/update-performer-type-previous"
              element={<UpdatePerformerType />}
            />
            <Route
              path="/update-performer-type"
              element={<UpdatePerformerTypeT />}
            />
            <Route
              path="/update-performer-firstName-previous"
              element={<UpdatePerformerFirstName />}
            />

            <Route
              path="/update-performer-firstName"
              element={<UpdatePerformerFirstNameT />}
            />

            <Route
              path="/update-performer-tagline"
              element={<UpdatePerformerTagline />}
            />

            <Route
              path="/update-venue-tagline"
              element={<UpdateVenueTagline />}
            />

            <Route
              path="/update-performer-shortBio"
              element={<UpdatePerformerShortBio />}
            />

            <Route
              path="/update-venue-shortBio"
              element={<UpdateVenueShortBio />}
            />

            <Route
              path="/update-performer-fullName-previous"
              element={<UpdatePerformerFullName />}
            />

            <Route
              path="/update-performer-fullName"
              element={<UpdatePerformerFullNameT />}
            />

            <Route
              path="/update-performer-phoneNumber-previous"
              element={<UpdatePerformerPhoneNumber />}
            />

            <Route
              path="/update-performer-phoneNumber"
              element={<UpdatePerformerPhoneNumberT />}
            />

            <Route path="/coupons" element={<Coupons />} />

            <Route
              path="/update-performer-email-previous"
              element={<UpdatePerformerEmail />}
            />

            <Route
              path="/update-performer-email"
              element={<UpdatePerformerEmailT />}
            />

            <Route
              path="/followed-profiles"
              element={<FollowedProfilesPage />}
            />

            <Route
              path="/venue-followed-profiles"
              element={<VenueFollowedProfilesPage />}
            />
            {/* <Route
            path="/venue-oppurtunity-applicants"
            element={<VenueOppurtunityApplicants />}
          /> */}
            {/* <Route
            path="/oppurtunity-performer-view"
            element={<Opportunity2 />}
          /> */}
            {/* <Route
            path="/venue-oppurtunity-applicants"
            element={<VenueOppurtunityApplicants />}
          /> */}
            <Route
              path="/update-venue-history-previous"
              element={<UpdateVenuePerformanceHistory />}
            />
            <Route
              path="/update-venue-history"
              element={<UpdateVenuePerformanceHistoryT />}
            />
            <Route
              path="/update-venue-videogallery-previous"
              element={<UpdateVenueVideoGallery />}
            />
            <Route
              path="/update-venue-videogallery"
              element={<UpdateVenueVideoGalleryT />}
            />
            <Route
              path="/update-venue-socialmedia-previous"
              element={<UpdateVenueSocialMedia />}
            />
            <Route
              path="/update-venue-socialmedia"
              element={<UpdateVenueSocialMediaT />}
            />
            <Route
              path="/update-venue-contact-previous"
              element={<UpdateVenueContact />}
            />
            <Route
              path="/update-venue-contact"
              element={<UpdateVenueContactT />}
            />
            <Route
              path="/create-venue-profile-previous"
              element={<CreateVenueProfile />}
            />
            <Route
              path="/create-venue-profile"
              element={<CreateVenueProfileT />}
            />
            <Route path="/update-venue-info" element={<UpdateVenueProfile />} />
            <Route
              path="/classified-search"
              element={
                <ProtectedRoute>
                  <ClassifiedSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/callout-search"
              element={
                <ProtectedRoute>
                  <CalloutSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/opportunity-search"
              element={
                <ProtectedRoute>
                  <OppurtunitySearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-new-oppurtunity"
              element={<AddNewOppurtunity />}
            />
            <Route
              path="/performer-search"
              element={
                <ProtectedRoute>
                  <PerformerSearch />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path="/profile-type-select"
            element={<ProfileSelectionPage />}
          /> */}
            <Route
              path="/update-performer-videoGallery-previous"
              element={<UpdatePerformerVideoGallery />}
            />
            <Route
              path="/update-performer-videoGallery"
              element={<UpdatePerformerVideoGalleryT />}
            />
            <Route
              path="/venue-search"
              element={
                <ProtectedRoute>
                  <VenueSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/update-performer-equipReq-previous"
              element={<UpdatePerformerEquipReq />}
            />
            <Route
              path="/update-performer-equipReq"
              element={<UpdatePerformerEquipReqT />}
            />
            <Route
              path="/update-performer-history-previous"
              element={<UpdatePerformerPerformanceHistory />}
            />
            <Route
              path="/update-performer-history"
              element={<UpdatePerformerPerformanceHistoryT />}
            />
            <Route
              path="/update-performer-experience-previous"
              element={<UpdatePerformerExperience />}
            />
            <Route
              path="/update-performer-experience"
              element={<UpdatePerformerExperienceT />}
            />
            <Route
              path="/update-performer-socialmedia-previous"
              element={<UpdatePerformerSocialMedia />}
            />
            <Route
              path="/update-performer-socialmedia"
              element={<UpdatePerformerSocialMediaT />}
            />
            <Route
              path="/update-performer-contact-previous"
              element={<UpdatePerformerContact />}
            />
            <Route
              path="/update-performer-contact"
              element={<UpdatePerformerContactT />}
            />
            <Route path="/update-performer-info" element={<UpdateProfile />} />
            <Route
              path="/view-performer-profile"
              element={<ViewPerformerProfile />}
            />
            {/* <Route path="/auth-change-password-previous" element={<ChangePassword />} /> */}
            <Route path="/auth-change-password" element={<ChangePasswordT />} />
            <Route
              path="/create-performer-profile-previous"
              element={<CreatePerformerProfile />}
            />
            <Route
              path="/create-performer-profile"
              element={<CreatePerformerProfileT />}
            />
            <Route
              path="/performer-profileN"
              element={<CreatePerformerProfileN />}
            />
            {/* <Route path="/index-two" element={<IndexTwo />} />
            <Route path="/index-three" element={<IndexThree />} />
            <Route path="/index-four" element={<IndexFour />} />
            <Route path="/index-five" element={<IndexFive />} />
            <Route path="/index-six" element={<IndexSix />} />
            <Route path="/index-seven" element={<IndexSeven />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/sell" element={<Sell />} />
            <Route path="/grid" element={<Grid />} />
            <Route path="/grid-sidebar" element={<GridSidebar />} />
            <Route path="/list" element={<List />} />
            <Route path="/list-sidebar" element={<ListSidebar />} />
            <Route path="/property-detail" element={<PropertyDetails />} />
            <Route path="/property-detail/:id" element={<PropertyDetails />} />
            <Route
              path="/property-detail-two"
              element={<PropertyDetailsTwo />}
            /> */}
            {/* <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/faqs" element={<Faqs />} />
            {/* <Route path="/new-faq" element={<newFAQ />} /> */}

            {/* <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog-sidebar" element={<BlogSidebar />} />
            <Route path="/blog-detail" element={<BlogDetail />} />
            <Route path="/blog-detail/:id" element={<BlogDetail />} />
            <Route path="/contactus" element={<ContactUs />} /> */}
            <Route
              path="/support-ticket-form"
              element={<SupportTicketForm />}
            />
            <Route
              path="/support-ticket-form-previous"
              element={<SupportTicketFormP />}
            />
            <Route
              path="/support-ticket-page"
              element={<SupportTicketPage />}
            />
            {/* <Route
          path="/performer-dashboard-page"
          element={<DashboardPerformer />}
        /> */}
            <Route
              path="/performer-dashboard-page"
              element={
                <ProtectedRoute>
                  <DashboardPerformer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/performer-dashboard-page-previous"
              element={<PerformerDashboard />}
            />
            <Route
              path="/venue-dashboard-page"
              element={
                <ProtectedRoute>
                  <DashboardVenue />
                </ProtectedRoute>
              }
            />

            <Route
              path="/add-performer-past-venues"
              element={<AddPerformerPastVenue />}
            />
            <Route
              path="/add-events-and-festivals"
              element={<AddEventsAndFestivals />}
            />
            <Route path="/add-residencies" element={<AddResidencies />} />
            <Route path="/add-collaboration" element={<AddCollaborations />} />

            {/* <Route path="/auth-signup" element={<Signup />} /> */}
            <Route path="/signup" element={<SignupT />} />
            <Route path="/auth-reset-password" element={<ResetPassword />} />
            <Route path="/fogot-password" element={<ResetPasswordT />} />
            <Route path="/comingsoon" element={<Comingsoon />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/error" element={<Error2 />} />
            <Route path="*" element={<Error />} />
            <Route path="/restricted" element={<RestrictedAccessPage />} />
          </Routes>
        </ErrorBoundary>
      </AuthProvider>
      <ScrollTop />
    </>
  );
}

export default App;
