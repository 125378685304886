import React, { useState, useEffect } from "react";
import "./opportunity.css";
import "./opportunityView.css";

import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
// import dummyProfilePicture from "../assect/images/client/jezzypic.jpg";
// import dummyProfilePicture2 from "../assect/images/client/kayapic.jpg";
// import dummyProfilePicture3 from "../assect/images/client/01.jpg";
import "../venue/venue.css";
import Swal from "sweetalert2";
import styled from "styled-components";

const OpportunityAuthorVenueView2 = () => {
  const [opportunity, setOpportunity] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [performerDetails, setPerformerDetails] = useState({});
  const accessToken = Cookies.get("accessToken");
  const { opportunityId } = useParams();
  const selectedOpportunityId = opportunityId || Cookies.get("opportunityId");
  const performerId = Cookies.get("profileOwnerId");
  const [activeTab, setActiveTab] = React.useState("opportunityInfo");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/opportunity/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setOpportunity(response.data.opportunity);
        console.log("opportunity details:", response);
      } catch (error) {
        console.error("Error fetching opportunity:", error);
      }
    };

    fetchOpportunity();
  }, [selectedOpportunityId, accessToken]);

  useEffect(() => {
    const fetchBookings = async () => {
      const loggedInProfileId = Cookies.get("profileOwnerId");

      const payload = {
        opportunity_id: selectedOpportunityId,
        logged_in_profile_id: loggedInProfileId,
      };

      try {
        const response = await axiosInstance.post(
          "/v1/booking/view-all-active/by/opportunity",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setBookings(response.data.bookings);
          response.data.bookings.forEach((booking) => {
            fetchPerformerDetails(booking.applicant_profile_id);
          });
        } else {
          console.error(
            "Error fetching bookings:",
            response.status,
            response.data
          );
        }
      } catch (error) {
        console.error("Request error:", error);
      }
    };

    fetchBookings();
  }, [selectedOpportunityId, accessToken]);

  const fetchPerformerDetails = async (performerId) => {
    try {
      const response = await axiosInstance.get(
        `/v1/performer/view-profile/${performerId}/section/complete`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setPerformerDetails((prevDetails) => ({
        ...prevDetails,
        [performerId]: response.data.performer_info,
      }));
    } catch (error) {
      console.error("Error fetching performer details:", error);
    }
  };

  const handleSendConfirmation = async (bookingId, performerId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");
    const payload = {
      booking_id: bookingId,
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/booking/confirm/by-venue",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Confirmation Sent!", `Booking ID: ${bookingId}`, "success");

        const confirmedBooking = {
          ...bookings.find((b) => b._id === bookingId),
        };
        confirmedBooking.performer = performerDetails[performerId];
        confirmedBooking.confirmed = true;

        setBookings((prevBookings) => {
          const confirmedBookings = prevBookings.filter((b) => b.confirmed);
          const pendingBookings = prevBookings.filter((b) => !b.confirmed);
          return [
            ...confirmedBookings.concat(confirmedBooking),
            ...pendingBookings,
          ];
        });
      } else {
        Swal.fire(
          "Error",
          `Failed to send confirmation: ${response.data.message}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "Failed to send confirmation", "error");
    }
  };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  const handleWithdrawRequest = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/withdraw/confirmation/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_APPLIED" }
              : booking
          )
        );
        Swal.fire("Request Withdrawn!", `Booking ID: ${bookingId}`, "success");
      } else {
        console.error(
          "Error withdrawing booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to withdraw booking", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleReject = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/reject/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Request rejected!", "success");
      } else {
        console.error(
          "Error rejecting booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to reject booking", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const handleAcceptCancellation = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-accept/contract/by-venue",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Cancellation Accepted!", "success");
      } else {
        console.error(
          "Error accepting cancellation:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Failed to accept cancellation", "error");
      }
    } catch (error) {
      console.error("Request error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
  `;

  const Tab = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: ${(props) =>
      props.active ? "2px solid #007bff" : "2px solid transparent"};
    color: ${(props) => (props.active ? "#007bff" : "black")};
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  `;

  return (
    <div className="row">
      <div className="col-md-2" style={{ marginTop: "2%", marginRight: "5%" }}>
        <h1 className="applicants text-left" style={{ color: "white" }}>
          Applicants
        </h1>
        <div className="table-container">
          <table className="table table-striped table-hover">
            <tbody>
              {bookings.map((booking) => {
                const performer =
                  performerDetails[booking.applicant_profile_id];
                const isConfirmed = booking.status === "VENUE_CONFIRMED";

                return (
                  <tr key={booking._id} className={isConfirmed ? "" : "frozen"}>
                    <td>
                      {performer ? (
                        <div className="d-flex align-items-center">
                          <img
                            src={performer.profile_picture_url}
                            alt={`${performer.profile_name}`}
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "10px",
                            }}
                          />
                          <span>{`${performer.profile_name}`}</span>
                        </div>
                      ) : (
                        "Loading..."
                      )}
                    </td>
                    <td>
                      {isConfirmed ? (
                        <button
                          className="oppbtn oppbtn-warning"
                          onClick={() => handleWithdrawRequest(booking._id)}
                        >
                          Withdraw Request
                        </button>
                      ) : booking.status === "PERFORMER_CANCELLED_CONTRACT" ? (
                        <button
                          className="oppbtn oppbtn-success"
                          onClick={() => handleAcceptCancellation(booking._id)}
                        >
                          Accept Contract Cancellation
                        </button>
                      ) : (
                        <button
                          className="oppbtn oppbtn-success"
                          onClick={() => handleSendConfirmation(booking._id)}
                        >
                          Send Confirmation
                        </button>
                      )}
                    </td>
                    <td>
                      <span className="booking-status">{booking.status}</span>
                      {booking.status !== "PERFORMER_CANCELLED_CONTRACT" && (
                        <button
                          className="oppbtn oppbtn-danger"
                          onClick={() => handleReject(booking._id)}
                        >
                          Reject
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-10">
        <div className="container_Op">
          {opportunity && (
            <>
              <div className="leftColumn_Op">
                <div className="categoryTag_Op">{opportunity.status}</div>
                <h1 className="title_Op">{opportunity.title}</h1>

                <p style={{ color: "#444" }}>{opportunity.short_description}</p>

                <Tabs>
                  <Tab
                    active={activeTab === "opportunityInfo"}
                    onClick={() => setActiveTab("opportunityInfo")}
                  >
                    Opportunity Info
                  </Tab>
                  <Tab
                    active={activeTab === "media"}
                    onClick={() => setActiveTab("media")}
                  >
                    Media
                  </Tab>
                </Tabs>

                {/* <div className="tabs">
                <div className={`tab ${activeTab === 'opportunityInfo' ? 'active' : ''}`} onClick={() => setActiveTab('opportunityInfo')}>
                  Opportunity Info
                </div>
                <div className={`tab ${activeTab === 'media' ? 'active' : ''}`} onClick={() => setActiveTab('media')}>
                  Media
                </div>
              </div> */}

                <div className="content_Op">
                  {activeTab === "opportunityInfo" && (
                    <div className="about_Op">
                      <h2>Description</h2>
                      <p>{opportunity.descriptions}</p>
                      <div className="buttonTagBox_Op">Show more &gt;&gt;</div>
                    </div>
                  )}
                  {activeTab === "media" && (
                    <div>Media content goes here...</div>
                  )}
                </div>
              </div>
              <div className="rightColumn_Op">
                <div className="sideCard_Op">
                  <div className="enrollButton_Op">I am Interested</div>
                  <div className="stats_Op">
                    <div className="dateContainer_Op">
                      <span>
                        Venue:{" "}
                        <span className="dateText_Op">{opportunity.venue}</span>
                      </span>
                      <span>
                        Status:{" "}
                        <span className="dateText_Op">
                          {opportunity.status}
                        </span>
                      </span>
                      <span>
                        From Date:{" "}
                        <span className="dateText_Op">
                          {formatDate(opportunity.from_date)}
                          <span className="space">
                            {" "}
                            {formatTime(opportunity.from_time)}
                          </span>
                        </span>
                      </span>
                      <span>
                        To Date:{" "}
                        <span className="dateText_Op">
                          {formatDate(opportunity.to_date)}
                          <span className="space">
                            {" "}
                            {formatTime(opportunity.to_time)}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="tagContainer_Op">
                    <h6>Opportunity Types:</h6>
                    {opportunity.opportunity_types.map((type, index) => (
                      <div key={index} className="tagBox_Op">
                        {type}
                      </div>
                    ))}
                  </div>
                  <div className="tagContainer_Op">
                    <h6>Genres:</h6>
                    {opportunity.opportunity_genres.map((genre, index) => (
                      <div key={index} className="tagBox_Op">
                        {genre}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default OpportunityAuthorVenueView2;
