import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import NavigationBar from "../../components/navbar/newNavigationBar";
import Navbar from "../../components/navbarSelf";
import Swal from "sweetalert2";

const SavedSearches = () => {
  const [savedSearches, setSavedSearches] = useState([]);
  const navigate = useNavigate();
  const profileId = Cookies.get("profileOwnerId");
  const ACCESS_TOKEN = Cookies.get("accessToken");

  useEffect(() => {
    const fetchSavedSearches = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/search/load-search-configuration/${profileId}`,
          {
            headers: {
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
          }
        );
        setSavedSearches(response.data.search_configurations);
      } catch (error) {
        console.error("Error fetching saved searches:", error);
      }
    };

    fetchSavedSearches();
  }, []);

  const handleSearchClick = (search) => {
    navigate(`/search/${search.search_type.toLowerCase()}`, {
      state: { searchConfig: search },
    });
  };

  const handleDeleteSearch = async (searchId) => {
    try {
      const url = `/v1/search/delete-search-configuration/${searchId}`;
      await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      setSavedSearches(
        savedSearches.filter((search) => search._id !== searchId)
      );
      Swal.fire("Deleted!", "Your saved search has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting saved search:", error);
      Swal.fire("Error!", "Failed to delete the saved search.", "error");
    }
  };

  const confirmDeleteSearch = (searchId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteSearch(searchId);
      }
    });
  };

  const getCardColor = (search_type) => {
    switch (search_type) {
      case "PERFORMER":
        return "lightblue";
      case "VENUE":
        return "lightgreen";
      case "CALL_OUT":
        return "white";
      case "OPPORTUNITY":
        return "lightgoldenrodyellow";
      case "CLASSIFIED":
        return "lightgrey";
      default:
        return "white";
    }
  };

  const renderSearchDetails = (search) => {
    switch (search.search_type) {
      case "PERFORMER":
        return (
          <>
            <strong>Types:</strong> {search.performer_types?.join(", ")} <br />
            <strong>Genres:</strong> {search.performer_genres?.join(", ")}{" "}
            <br />
          </>
        );
      case "VENUE":
        return (
          <>
            <strong>Types:</strong> {search.venue_types?.join(", ")} <br />
            <strong>Genres:</strong> {search.venue_genres?.join(", ")} <br />
          </>
        );
      case "CALL_OUT":
        return (
          <>
            <strong>Type:</strong> {search.call_out_type} <br />
            <strong>Category:</strong> {search.call_out_category} <br />
            <strong>Sub-category:</strong> {search.call_out_sub_category} <br />
            <strong>From Date:</strong> {search.from_date}{" "}
            <strong>To Date:</strong> {search.to_date}
            <br />
          </>
        );
      case "CLASSIFIED":
        return (
          <>
            <strong>Type:</strong> {search.classified_type} <br />
            <strong>Category:</strong> {search.classified_category} <br />
            <strong>Sub-category:</strong> {search.classified_sub_category}{" "}
            <br />
            <strong>From Date:</strong> {search.from_date}{" "}
            <strong>To Date:</strong> {search.to_date}
            <br />
          </>
        );
      case "OPPORTUNITY":
        return (
          <>
            <strong>Types:</strong> {search.opportunity_types?.join(", ")}{" "}
            <br />
            <strong>Genres:</strong> {search.opportunity_genres?.join(", ")}{" "}
            <br />
            <strong>From Date:</strong> {search.from_date}{" "}
            <strong>To Date:</strong> {search.to_date}
            <br />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <NavigationBar />
      <h3
        className="heading fw-bold title-dark mb-3 text-center"
        style={{ marginTop: "6%" }}
      >
        Saved Searches
      </h3>

      <div className="row">
        {savedSearches.map((search, index) => (
          <div
            key={search._id}
            className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
          >
            <div className="events__item hover__active">
              <div
                className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg"
                style={{ backgroundColor: getCardColor(search.search_type) }}
              >
                <div className="events__content">
                  <div className="events__meta">
                    <span>Saved Search {index + 1}</span>
                  </div>
                  <h5 className="events__title">
                    <a
                      onClick={() => handleSearchClick(search)}
                      style={{ cursor: "pointer" }}
                    >
                      View Details
                    </a>
                  </h5>
                  <div className="events__details-inline">
                    {renderSearchDetails(search)}
                    <p>
                      <strong>Keywords:</strong> {search.keywords?.join(", ")}
                    </p>
                    <p>
                      <strong>From Price Point:</strong>{" "}
                      {search.from_price_point} <strong>To Price Point:</strong>{" "}
                      {search.to_price_point}
                    </p>
                    {/* <p>
                      <strong>From Date:</strong> {search.from_date}{" "}
                      <strong>To Date:</strong> {search.to_date}
                    </p> */}
                    <p>
                      <strong>Search by Location:</strong>{" "}
                      {search.search_by_location}{" "}
                      <strong>Location Name:</strong>{" "}
                      {search.location.location_name} <strong>Suburb:</strong>{" "}
                      {search.location.suburb} <strong>Zipcode:</strong>{" "}
                      {search.location.zipcode}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => confirmDeleteSearch(search._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SavedSearches;
