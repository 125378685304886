import React, { useEffect, useState } from "react";
import "./ProfileComponent.css";
import "../OtherPages/LandingProfiles.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPlus,
  FaSpotify,
  FaTiktok,
  FaYoutube,
  FaKey,
  FaTrashAlt,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSpotify,
//   faSquareFacebook,
//   faSquareInstagram,
//   faSquareTwitter,
//   faSquareYoutube,
//   faTiktok,
// } from "@fortawesome/free-brands-svg-icons";
import {
  faLinkedinIn,
  faMastodon,
  faMixcloud,
  faSoundcloud,
  faSpotify,
  faSquareFacebook,
  faSquareInstagram,
  faSquareTwitter,
  faSquareYoutube,
  faTiktok,
  faVimeo,
} from "@fortawesome/free-brands-svg-icons";
import {
  faImage,
  faNewspaper,
  faStar,
  faEdit,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";

import {
  faBriefcase,
  faEnvelope,
  faPhone,
  faDollar,
  faComment,
  faLocation,
  faPlus,
  faTimes,
  faEllipsis,
  faTrash,
  faCheckCircle,
  faCircleXmark,
  faX,
} from "@fortawesome/free-solid-svg-icons";
// import dp from "../../assect/images/client/kayapic.jpg";
// import jezzypic from "../../assect/images/client/jezzypic.jpg";
// import kayapic from "../../assect/images/client/kayapic.jpg";
import Swal from "sweetalert2";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { BsTrash } from "react-icons/bs";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import "../../components/ratings.css";
import CalendarSelfView from "../../pages/calendar/venueCalendarSelfView";
import CalendarPublicView from "../../pages/calendar/venueCalendarPublicView";
import ViewVenueClassifieds from "../classifieds/viewVenueClassifieds";
import ViewVenueClassifiedsbyOthers from "../classifieds/viewVenueClassifiedsbyOthers";
import ViewOpportunities from "../../pages/opportunities/viewOpportunities";
import ViewOpportunitiesbyOthers from "../../pages/opportunities/viewOpportunitiesbyOthers";

import ViewCalloutsbyOthers from "../callouts/viewCalloutsbyOthers";
import ViewCalloutsbySelf from "../callouts/viewCalloutsbySelf";
// import MyCalendar from "../performer/calendar";
// import VerifyEmailModal from "../auth/emailVerification";
import VerifyEmailModal from "../account/VerificationModal";
import VerifyPhoneNumberModal from "../auth/phoneVerification";
import Spinner from "react-bootstrap/Spinner";
import { Modal, Button, Nav, Tab, Row, Col, Card, Form } from "react-bootstrap";
// import bg3 from "../../assect/images/bgnew2.jpg";
import Navbar from "../../components/navbarSelf";
import {
  FaPhone,
  FaEnvelope,
  FaEdit,
  FaTrash,
  FaEllipsisV,
  FaUpload,
  FaCog,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

import youtubeThumbnail from "youtube-thumbnail";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import defaultProfilePicture from "../../assect/images/defaultPlace.jpg";

import { FaUserGroup } from "react-icons/fa6";
import { button } from "@material-tailwind/react";
import { FiFlag } from "react-icons/fi";
import { RiFlagFill } from "react-icons/ri";
import InviteFriendButton from "../../components/inviteFriend";
import NavigationBar from "../../components/navbar/newNavigationBarProfiles";
import StarRatings from "react-star-ratings";
import App from "../messaging/messaging";
import LoadingSkeleton from "./LoadingSkeleton";
import Skeleton from "react-loading-skeleton";

export function getVideoId(url) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;
  while ((match = regex.exec(url))) {
    if (match[1] === "v") {
      return match[2];
    }
  }
  return null;
}

export function getVideoThumbnailUrl(videoId) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}

const allowedPerformerSections = [
  "complete",
  "info",
  "contact",
  "social_media",
  "image_gallery",
  "video_gallery",
  "experience",
  "performance_history",
  "equipment_requirements",
  "reviews_by_self",
  "reviews_by_others",
];

const HoverImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s; /* Add a smooth transition */

  &:hover {
    transform: scale(1.05); /* Scale up when hovering */
  }
`;

const NewVenueProfile = (props) => {
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishButtonloading, setPublishButtonloading] = useState(false);
  const [error, setError] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [galleryPictures, setGalleryPictures] = useState([]);
  const [deleteloading, setDeleteloading] = useState(false);
  const [deletevideoloading, setDeletevideoloading] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState(null);
  const [videoGalleryThumbnails, setVideoGalleryThumbnails] = useState([]);
  const { performerId } = useParams();
  const [showImageOptionsModal, setShowImageOptionsModal] = useState(false);
  const [showImageConfirmationModal, setShowImageConfirmationModal] =
    useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showImageGalleryOptionsModal, setShowImageGalleryOptionsModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGalleryOption, setSelectedGalleryOption] = useState(null);
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const [zoomedImageUrl, setZoomedImageUrl] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [hoveredFollow, setHoveredFollow] = useState(false);
  const [clickedFollow, setClickedFollow] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState(false);
  const [clickedMessage, setClickedMessage] = useState(false);
  const [hoveredBook, setHoveredBook] = useState(false);
  const [clickedBook, setClickedBook] = useState(false);
  const [venueData, setVenueData] = useState({});
  // const { profileId } = useParams();
  const params = useParams();
  const profileId = props.profileId || params.profileId;
  const selectedPerformerId = profileId; //profileId || Cookies.get("profileId");
  const loggedInUserId = Cookies.get("userId");

  const [opportunities, setOpportunities] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [viewableCount, setViewableCount] = useState(2);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOpportunityId, setDeleteOpportunityId] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState(null);
  const [reviewsBySelf, setReviewsBySelf] = useState([]);
  const [authorInfo, setAuthorInfo] = useState({});
  const [selectedCalloutId, setSelectedCalloutId] = useState(null);
  const [callouts, setCallouts] = useState([]);
  const [classifieds, setClassifieds] = useState([]);
  const loggedInProfileId = Cookies.get("profileId");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [showModal2, setShowModal2] = useState(false);
  const handleShowModal2 = () => setShowModal2(true);
  const [loadingOthers, setLoadingOthers] = useState(false);
  const [loadingSelf, setLoadingSelf] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [flaggedReviews, setFlaggedReviews] = useState({});
  const [showFlagModal, setShowFlagModal] = useState(false);

  const [selectedFlaggedReviews, setSelectedFlaggedReviews] = useState(null);
  const [flagComment, setFlagComment] = useState("");
  const [sectionType, setSectionType] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const [activeTab, setActiveTab] = useState("posts");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [pricePoint, setPricePoint] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [followButtonloading, setFollowButtonloading] = useState(false);

  const [videoGallery, setVideoGallery] = useState([]);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const TagContainer = styled.div`
    text-align: left;
    display: flex;
    width: 330px;
    flex-wrap: wrap;
  `;

  const TagBox = styled.div`
    display: inline-block;
    padding: 8px 16px;
    margin: 5px;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  `;

  useEffect(() => {
    const fetchClassifieds = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `/v1/classified/published/by/profile-owner/${loggedInProfileId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          // Filter classifieds based on author_profile_id
          const filteredClassifieds = data.classifieds_published.filter(
            (classified) => classified.author_profile_id === loggedInProfileId
          );
          setClassifieds(filteredClassifieds);
        } else {
          console.error(`Failed to fetch classifieds: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching classifieds:", error);
      }
      setLoading(false);
    };

    fetchClassifieds();
  }, [loggedInUserId]);

  const handleDeleteClickClassified = (id) => {
    console.log(`Delete classified with ID: ${id}`);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmClassified = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    fetchCallouts();
  }, []);

  const fetchCallouts = async () => {
    setLoading(true);
    const profileId = selectedPerformerId;
    try {
      const response = await axiosInstance.get(
        `/v1/call_out/published/by/profile-owner/${selectedPerformerId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setCallouts(response.data.call_outs_published);
    } catch (error) {
      console.error("Error fetching callouts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClickCallout = (calloutId) => {
    setSelectedCalloutId(calloutId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmCallout = async () => {
    setShowDeleteModal(false);
    setLoading(true);

    try {
      const response = await axiosInstance.delete(
        `/v1/call_out/delete-call-out/${selectedCalloutId}`
      );

      if (response.status === 200) {
        setCallouts((prevCallouts) =>
          prevCallouts.filter((callout) => callout._id !== selectedCalloutId)
        );
      } else {
        console.error("Error deleting callout:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting callout:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/review/received/by/profile/${selectedPerformerId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setReviews(response.data.reviews_received);
        setRatings(response.data.reviews_summary);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const deleteReview = async (reviewId) => {
    setLoadingReview(true);
    try {
      const response = await axiosInstance.get(
        `/v1/review/delete-review/${reviewId}`
      );
      if (response.data) {
        setLoadingReview(false);
      }
    } catch (error) {
      console.error("Error fetching author info:", error);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/review/written/by/profile/${selectedPerformerId}`
        );

        setReviewsBySelf(response.data.reviews_written);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [loadingReview]);

  const handleFlagClick = (review_id) => {
    setSelectedFlaggedReviews(review_id);
    setShowFlagModal(true);
  };

  useEffect(() => {
    // Initialize section types array and sort it alphabetically
    const sections = ["Title", "Review"];
    const sortedSections = sections.sort((a, b) => a.localeCompare(b));
    setSectionType(sortedSections);
  }, []);

  const handleFlag = async (
    sectionType,
    flagComment,
    reportinProfileID,
    review_id
  ) => {
    console.log("sectionType:", sectionType);
    console.log("flagComment:", flagComment);

    console.log("reportinProfileID:", reportinProfileID);
    console.log("review_id:", review_id);
    try {
      const response = await axiosInstance.post(
        "/v1/review/flag-review",
        {
          reporting_profile_id: reportinProfileID,
          review_id: review_id,
          section: sectionType,
          comment: flagComment,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error flagging reviews:", error);
    }
  };

  const handleFlagSave = () => {
    setFlaggedReviews((prev) => ({
      ...prev,
      [selectedFlaggedReviews]: { sectionType: selectedSection, flagComment },
    }));
    handleFlag(
      selectedSection,
      flagComment,
      `${Cookies.get("profileOwnerId")}`,
      selectedFlaggedReviews
    );
    setShowFlagModal(false);
    setSelectedSection("");
    setFlagComment("");
  };

  const handleFlagCommentChange = (e) => {
    setFlagComment(e.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  // const handleFlagClick = async (review_id) => {
  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/review/flag-review",
  //       {
  //         reporting_profile_id: Cookies.get("profileOwnerId"),
  //         review_id: review_id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       setFlaggedReviews((prev) => ({ ...prev, [review_id]: true }));
  //     } else {
  //       console.error("Error flagging review:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error flagging review:", error);
  //   }
  // };

  const fetchAuthorInfo = async (authorProfileId) => {
    try {
      const profileType = authorProfileId.startsWith("PROFILE-PERFORMER")
        ? "performer"
        : "venue";
      const response = await axiosInstance.get(
        `/v1/${profileType}/view-profile/${authorProfileId}/section/complete`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching author info:", error);
      return null;
    }
  };

  useEffect(() => {
    reviews.forEach((review) => {
      fetchAuthorInfo(review.author_profile_id).then((authorInfo) => {
        setAuthorInfo((prevAuthorInfo) => ({
          ...prevAuthorInfo,
          [review.author_profile_id]: authorInfo,
        }));
      });
    });
  }, [reviews]);

  const handleDeleteClick = (id) => {
    setDeleteOpportunityId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/opportunity/delete-opportunity/${deleteOpportunityId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("Opportunity deleted:", response.data);

      fetchOpportunities();
    } catch (error) {
      console.error("Error deleting opportunity:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const fetchOpportunities = async () => {
    setLoading(true);
    const profileId = selectedPerformerId;
    try {
      const response = await axiosInstance.get(
        `/v1/opportunity/published/by/profile-owner/${selectedPerformerId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setOpportunities(response.data.opportunities_published);
    } catch (error) {
      console.error("Error fetching opportunities:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEllipsisClick = (imageId) => {
    setSelectedImageId(imageId);
    setShowImageConfirmationModal(true);
  };

  // const handleDeleteOption = () => {
  //   setShowImageOptionsModal(false);
  //   setShowImageConfirmationModal(true);
  // };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "deletePicture") {
      setShowConfirmationModal(true);
      setShowOptionsModal(false);
    } else {
      setShowOptionsModal(false);
      if (option === "changePassword") {
        navigate("/auth-change-password");
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal2(false);
    setShowOptionsModal(false);
    setSelectedOption(null);
  };

  const handleVerify = (verificationCode) => {
    // Add your verify logic here
    console.log("Verification code:", verificationCode);
    setShowModal(false); // Close the modal after verification
  };

  const handleResend = () => {
    // Add your resend logic here
    console.log("Resend verification code");
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);

  //     const profileIdFromCookies = selectedPerformerId;

  //     // if (!profileId) {
  //     //   setError("venue ID not found in URL parameters.");
  //     //   setLoading(false);
  //     //   return;
  //     // }

  //     // Cookies.set("profileId", profileId);

  //     try {
  //       const response = await axiosInstance.get(
  //         `/v1/venue/view-profile/${selectedPerformerId}/section/complete`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         const venueInfo = response.data.venue_info;
  //         const data = response.data;
  //         setVenueData(data);
  //         setError(null);
  //         const profilePictureUrlFromResponse =
  //           response.data.venue_info?.profile_picture_url;
  //         if (response.data.venue_video_gallery) {
  //           console.log(response.data.venue_video_gallery);
  //           setVideoGallery(response.data.venue_video_gallery);
  //         }
  //         if (profilePictureUrlFromResponse) {
  //           setProfilePictureUrl(profilePictureUrlFromResponse);
  //           localStorage.setItem(
  //             "profilePictureUrl",
  //             profilePictureUrlFromResponse
  //           );
  //         } else {
  //           setProfilePictureUrl(defaultProfilePicture);
  //         }

  //         if (venueInfo.status === "ACTIVE") {
  //           setIsPublished(true);
  //         }

  //         if (venueInfo.status === "SUSPENDED") {
  //           setIsPublishButtonEnabled(false);
  //         } else if (
  //           venueInfo.status === "FRESH" &&
  //           profilePictureUrlFromResponse &&
  //           venueInfo.price_point_value_to
  //         ) {
  //           setIsPublishButtonEnabled(true);
  //         } else {
  //           setIsPublishButtonEnabled(false);
  //         }

  //         if (venueInfo.price_point_value_to) {
  //           setPricePoint(venueInfo.price_point_value_to);
  //         }

  //         // if (
  //         //   venueInfo.status === "FRESH" &&
  //         //   profilePictureUrlFromResponse &&
  //         //   venueInfo.price_point_value_to
  //         // ) {
  //         //   setIsPublishButtonEnabled(true);
  //         // } else {
  //         //   setIsPublishButtonEnabled(false);
  //         // }

  //         // if (venueInfo.status === "FRESH" && profilePictureUrlFromResponse) {
  //         //   setIsPublishButtonEnabled(true);
  //         // }

  //         const profileOwnerId = venueInfo.user_id;

  //         const loggedInUserId = Cookies.get("userId");

  //         if (profileOwnerId === loggedInUserId) {
  //           const isSecure = window.location.protocol === "https:";
  //           const cookieOptions = {
  //             secure: isSecure,
  //             sameSite: "Lax",
  //             // httpOnly: true,
  //           };
  //           // Cookies.set("profileOwnerId", selectedPerformerId, cookieOptions);
  //           // Cookies.set("profileOwnerId", selectedPerformerId);
  //         }

  //         const checkFollowStatus = await axiosInstance.post(
  //           "/v1/profile/is-following-profile",
  //           {
  //             followed_profile_id: selectedPerformerId,
  //             follower_profile_id: Cookies.get("profileOwnerId"),
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //             },
  //           }
  //         );

  //         if (
  //           checkFollowStatus.status === 200 &&
  //           checkFollowStatus.data.is_following
  //         ) {
  //           console.log(
  //             "this is a followed/unfollowed profile",
  //             checkFollowStatus.status
  //           );
  //           setIsFollowed(true);
  //         } else {
  //           // console.log(
  //           //   "error in loading followed/unfollowed status",
  //           //   checkFollowStatus.status
  //           // );
  //         }
  //       } else {
  //         setError(response.data.error || "Error fetching venue profile data.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching venue profile:", error);
  //       setError("An unexpected error occurred. Please try again later.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [selectedPerformerId]);

  const userId = Cookies.get("userId");
  const profileOwnerId = Cookies.get("profileOwnerId");
  const [profileStatus, setProfileStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Retrieve cached data
      const cachedData = localStorage.getItem("venueData");
      const cachedProfileOwnerId = localStorage.getItem("cachedProfileOwnerId");
      const cachedUserId = localStorage.getItem("cachedUserId");
      const cachedVenueId = localStorage.getItem("cachedVenueId");
      const cachedStatus = localStorage.getItem("cachedStatus");

      // Clear cache if selectedPerformerId or profileOwnerId or userId has changed
      if (
        cachedVenueId !== selectedPerformerId ||
        cachedProfileOwnerId !== profileOwnerId ||
        cachedUserId !== userId ||
        cachedStatus !== profileStatus
      ) {
        localStorage.removeItem("venueData");
        localStorage.removeItem("cachedProfileOwnerId");
        localStorage.removeItem("cachedUserId");
        localStorage.removeItem("cachedVenueId");
        localStorage.removeItem("cachedStatus");
      }

      // Use cached data if available and IDs match
      if (
        cachedData &&
        cachedProfileOwnerId === profileOwnerId &&
        cachedUserId === userId &&
        cachedVenueId === selectedPerformerId
      ) {
        const parsedData = JSON.parse(cachedData);
        const venueInfo = parsedData.venue_info;

        // Set state from cached data
        setVenueData(parsedData);
        setProfilePictureUrl(
          venueInfo?.profile_picture_url || defaultProfilePicture
        );
        setVideoGallery(parsedData.venue_video_gallery || []);
        setIsPublished(venueInfo.status === "ACTIVE");
        setIsPublishButtonEnabled(
          venueInfo.status !== "SUSPENDED" &&
            venueInfo.status === "FRESH" &&
            venueInfo.profile_picture_url &&
            venueInfo.price_point_value_to
        );
        setPricePoint(venueInfo.price_point_value_to);

        setProfileStatus(venueInfo.status);

        setLoading(false);
        return;
      }

      // If no cached data, fetch from backend
      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${selectedPerformerId}/section/complete`,
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        if (response.status === 200) {
          const venueInfo = response.data.venue_info;
          setVenueData(response.data);

          // Cache the fetched data along with profileOwnerId, userId, and selectedPerformerId
          localStorage.setItem("venueData", JSON.stringify(response.data));
          localStorage.setItem("cachedProfileOwnerId", profileOwnerId);
          localStorage.setItem("cachedUserId", userId);
          localStorage.setItem("cachedVenueId", selectedPerformerId);
          localStorage.setItem("cachedStatus", venueInfo.status);

          // Set state from fetched data
          const profilePictureUrlFromResponse = venueInfo?.profile_picture_url;
          setProfilePictureUrl(
            profilePictureUrlFromResponse || defaultProfilePicture
          );
          setVideoGallery(response.data.venue_video_gallery || []);
          setIsPublished(venueInfo.status === "ACTIVE");
          setIsPublishButtonEnabled(
            venueInfo.status !== "SUSPENDED" &&
              venueInfo.status === "FRESH" &&
              profilePictureUrlFromResponse &&
              venueInfo.price_point_value_to
          );
          setPricePoint(venueInfo.price_point_value_to);

          setProfileStatus(venueInfo.status);

          const loggedInUserId = Cookies.get("userId");

          if (profileOwnerId === loggedInUserId) {
            const isSecure = window.location.protocol === "https:";
            const cookieOptions = {
              secure: isSecure,
              sameSite: "Lax",
            };
            // Cookies.set("profileOwnerId", selectedPerformerId, cookieOptions);
          }

          // Check follow status
          const checkFollowStatus = await axiosInstance.post(
            "/v1/profile/is-following-profile",
            {
              followed_profile_id: selectedPerformerId,
              follower_profile_id: Cookies.get("profileOwnerId"),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );

          if (
            checkFollowStatus.status === 200 &&
            checkFollowStatus.data.is_following
          ) {
            setIsFollowed(true);
          }
        } else {
          setError(response.data.error || "Error fetching venue profile data.");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setError("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPerformerId, profileOwnerId, userId, profileStatus]);

  useEffect(() => {
    if (profilePictureUrl && pricePoint) {
      setIsPublishButtonEnabled(true);
    } else {
      setIsPublishButtonEnabled(false);
    }
  }, [profilePictureUrl, pricePoint]);

  const isProfileSuspended = venueData?.venue_info?.status === "SUSPENDED";

  const handlePublishProfile = async (e) => {
    setPublishButtonloading(true);
    if (e) e.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/v1/venue/publish-profile`,
        {
          venue_id: selectedPerformerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your profile has been published successfully!",
        });
        setIsPublished(true);
      } else {
        Swal.fire({
          icon: "error",
          title: `API Error: ${response.status}`,
          text: `${response.data}`,
        });
      }
    } catch (error) {
      console.error("Error publishing profile:", error);
      Swal.fire({
        icon: "error",
        title:
          "Adding a profile picture and a price point is mandatory to publish the profile",
        // title: "Error publishing profile",
        text: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setPublishButtonloading(false); // Stop showing the spinner
    }
  };

  const confirmUnpublish = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to unpublish the profile?",
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: "#3085d6",
      // cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unpublish it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnpublishProfile();
      }
    });
  };

  const handleUnpublishProfile = async (e) => {
    setPublishButtonloading(true);
    if (e) e.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/v1/venue/un-publish-profile`,
        {
          venue_id: selectedPerformerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your profile has been unpublished successfully!",
        });
        setIsPublished(false);
      } else {
        Swal.fire({
          icon: "error",
          title: `API Error: ${response.status}`,
          text: `${response.data}`,
        });
      }
    } catch (error) {
      console.error("Error unpublishing profile:", error);
      Swal.fire({
        icon: "error",
        title: "Error unpublishing profile",
        text: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setPublishButtonloading(false); // Stop showing the spinner
    }
  };

  // const handlePublishProfile = async () => {
  //   try {
  //     const response = await axiosInstance.post(
  //       `/v1/venue/publish-profile`,
  //       {
  //         venue_id: selectedPerformerId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       alert("Profile published successfully!");
  //     } else {
  //       alert(`API Error: ${response.status} - ${response.data}`);
  //     }
  //   } catch (error) {
  //     console.error("Error publishing profile:", error);
  //     alert("An unexpected error occurred. Please try again.");
  //   }
  // };

  useEffect(() => {
    const fetchGalleryPictures = async () => {
      setGalleryLoading(true);

      const profileIdFromCookies = selectedPerformerId;
      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${selectedPerformerId}/section/image_gallery`,
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        console.log("Gallery pictures response:", response.data);

        if (response.status === 200 && response.data.venue_image_gallery) {
          setGalleryPictures(response.data.venue_image_gallery.links);
        } else {
          console.error(
            "Error fetching gallery pictures: Invalid response format",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching gallery pictures:", error);
      } finally {
        setGalleryLoading(false);
      }
    };

    fetchGalleryPictures();
  }, [selectedPerformerId]);

  const deleteVideo = async (id) => {
    setDeletevideoloading((prevState) => ({ ...prevState, [id]: true }));
    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/video-gallery/delete",
        {
          venue_id: Cookies.get("profileOwnerId"),
          link_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        setVideoGallery(videoGallery.filter((video) => video._id !== id));
        Swal.fire({
          title: "Success!",
          text: "The video has been deleted successfully.",
          icon: "success",
          className: "swal-modal",
        });
      } else {
        console.error("Error deleting picture:", response.data.error);
        Swal.fire({
          title: "Error!",
          text: response.data.error,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      console.error("Error deleting video:", error);
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setDeletevideoloading((prevState) => ({ ...prevState, [id]: false }));
      setShowImageConfirmationModal(false);
      setSelectedImageId(null);
      // setDeletingImageId(null);
    }
  };

  const handleUploadProfilePicture = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("venue_id", selectedPerformerId);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/upload-picture/profile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        localStorage.removeItem("cachedProfileOwnerId");
        localStorage.removeItem("cachedUserId");
        localStorage.removeItem("cachedVenueId");
        localStorage.removeItem("profilesCache");
        const imageUrl = response.data.image_url;
        setProfilePictureUrl(imageUrl);
        setShowOptionsModal(false); // Close the modal after successful upload
      } else {
        console.error("Error uploading profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProfilePicture = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/venue/delete-picture/profile",
        { venue_id: selectedPerformerId },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        localStorage.removeItem("cachedProfileOwnerId");
        localStorage.removeItem("cachedUserId");
        localStorage.removeItem("cachedVenueId");
        localStorage.removeItem("profilesCache");
        setProfilePictureUrl(defaultProfilePicture);
        setShowConfirmationModal(false);
      } else {
        console.error("Error deleting profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting profile picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const openFileInput = () => {
    const fileInput = document.getElementById("galleryPictures");
    fileInput.click();
  };

  const handleUploadGalleryPictures = async (event) => {
    const files = event.target.files;

    // Check if there are already 10 images in the gallery
    if (galleryPictures.length >= 10) {
      Swal.fire({
        title: "Error!",
        text: "You already have the maximum of 10 images in your gallery.",
        icon: "error",
        className: "swal-modal",
      });

      // Reset the file input to allow the same file to be uploaded again
      event.target.value = null;
      return;
    }

    // Check if the user is trying to upload more than 10 images at once
    if (files.length > 10) {
      Swal.fire({
        title: "Error!",
        text: "You can upload a maximum of 10 images at a time.",
        icon: "error",
        className: "swal-modal",
      });

      // Reset the file input to allow the same file to be uploaded again
      event.target.value = null;
      return;
    }

    // Check if the total images (existing + new) would exceed 10
    if (galleryPictures.length + files.length > 10) {
      Swal.fire({
        title: "Error!",
        text: `You can add up to ${
          10 - galleryPictures.length
        } more images to the gallery.`,
        icon: "error",
        className: "swal-modal",
      });

      // Reset the file input to allow the same file to be uploaded again
      event.target.value = null;
      return;
    }

    setGalleryLoading(true);

    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("venue_id", selectedPerformerId);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/upload-picture/gallery",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        const uploadedImages = response.data.upload_results.filter(
          (result) => result.status === "SUCCESS"
        );
        setGalleryPictures((prevPictures) => [
          ...prevPictures,
          ...uploadedImages,
        ]);

        Swal.fire({
          title: "Success!",
          text: "Upload completed successfully.",
          icon: "success",
          className: "swal-modal",
        });
      } else {
        console.error("Error uploading gallery pictures:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading gallery pictures:", error);
    } finally {
      setGalleryLoading(false);

      // Reset the file input after the upload is handled
      event.target.value = null;
    }
  };

  // const handleUploadGalleryPictures = async (event) => {
  //   const files = event.target.files;

  //   // Check if the user is trying to upload more than 10 images at once
  //   if (files.length > 10) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: "You can only upload a maximum of 10 images at a time.",
  //       icon: "error",
  //       className: "swal-modal",
  //     });
  //     return;
  //   }

  //   // Check if there are already 10 images in the gallery
  //   if (galleryPictures.length >= 10) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: "You already have 10 images in your gallery.",
  //       icon: "error",
  //       className: "swal-modal",
  //     });
  //     return;
  //   }

  //   // Check if the total images (existing + new) would exceed 10
  //   if (galleryPictures.length + files.length > 10) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: `You can only add ${10 - galleryPictures.length} more images to the gallery.`,
  //       icon: "error",
  //       className: "swal-modal",
  //     });
  //     return;
  //   }

  //   setGalleryLoading(true);
  //   const formData = new FormData();
  //   Array.from(files).forEach((file) => {
  //     formData.append("images[]", file);
  //   });
  //   formData.append("venue_id", selectedPerformerId);

  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/venue/upload-picture/gallery",
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const uploadedImages = response.data.upload_results.filter(
  //         (result) => result.status === "SUCCESS"
  //       );
  //       setGalleryPictures([...galleryPictures, ...uploadedImages]);
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Successfully uploaded",
  //         icon: "success",
  //         className: "swal-modal",
  //       });
  //     } else {
  //       console.error("Error uploading gallery pictures:", response.data.error);
  //     }
  //   } catch (error) {
  //     console.error("Error uploading gallery pictures:", error);
  //   } finally {
  //     setGalleryLoading(false);
  //   }
  // };

  const handleDeletePicture = async (imageId) => {
    try {
      setDeleteloading(true);
      const response = await axiosInstance.post(
        "/v1/venue/delete-picture/gallery",
        {
          venue_id: selectedPerformerId,
          image_ids: [imageId],
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        setGalleryPictures(
          galleryPictures.filter((picture) => picture.image_id !== imageId)
        );
        setSuccessMessage("price point updated successfully.");
        Swal.fire({
          title: "Success!",
          text: "The image has been deleted successfully.",
          icon: "success",
          className: "swal-modal",
        });
      } else {
        console.error("Error deleting picture:", response.data.error);
        setErrorMessage(
          "An error occurred while deleting the picture. Please try again."
        );
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      console.error("Error deleting picture:", error);
      setErrorMessage(
        "An error occurred while deleting the picture. Please try again."
      );
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setDeleteloading(false);
      setShowImageConfirmationModal(false);
      setSelectedImageId(null);
      // setDeletingImageId(null);
    }
  };

  const showPreviousArrow = currentImageIndex !== null && currentImageIndex > 0;
  const showNextArrow =
    currentImageIndex !== null &&
    currentImageIndex < galleryPictures.length - 1;

  const handlePreviousClick = () => {
    if (showPreviousArrow) {
      setCurrentImageIndex(currentImageIndex - 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex - 1].image_url);
    }
  };

  const handleNextClick = () => {
    if (showNextArrow) {
      setCurrentImageIndex(currentImageIndex + 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex + 1].image_url);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const navigateToUpdateInfo = () => {
    navigate("/update-venue-info");
  };

  const addopportunity = () => {
    navigate("/add-new-oppurtunity");
  };

  const addcallout = () => {
    navigate("/add-new-callout");
  };

  const extractVideoIdFromLink = (link) => {
    const youtubeMatch = link.match(
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    if (youtubeMatch) {
      return youtubeMatch[1];
    }
    console.warn("Unsupported video link format:", link);
    return "";
  };

  const handleUploadProfilePictureClick = () => {
    const fileInput = document.getElementById("profilePicture");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleViewMore = () => {
    setViewableCount(opportunities.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  const handleViewMorecallout = () => {
    setViewableCount(callouts.length);
  };

  const handleViewLesscallout = () => {
    setViewableCount(2);
  };

  const navigatetoallprofiles = () => {
    navigate("/all-profiles");
  };

  const handleOpportunityClick = (opportunityId) => {
    // Cookies.set("opportunityId", opportunityId);
    console.log("Clicked opportunityId:", opportunityId);
    navigate(`/venue/opportunity/author/${opportunityId}`);
  };

  const handleMessageClick = () => {
    const profileOwnerId = Cookies.get("profileOwnerId");
    navigate(`/messaging/${profileOwnerId}`);
  };

  // const profileOwnerId = Cookies.get("profileOwnerId");

  const [showModal3, setShowModal3] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [msgloading, setmsgLoading] = useState(false);

  const handleClose3 = () => setShowModal3(false);
  const handleShow = () => setShowModal3(true);

  const navigatetomessaging = () => {
    setmsgLoading(true);
    const profile_id = profileOwnerId;
    const toProfileId = selectedPerformerId;

    axiosInstance
      .post(
        `/v1/messaging/create_conversation/${profile_id}/${toProfileId}`,
        { title: "" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const conversation_id =
          response.data.exists_conversation_id ||
          response.data.conversation_id ||
          response.data.new_conversation_id;
        console.log("Conversation ID:", conversation_id);
        setConversationId(conversation_id);
        setmsgLoading(false);
        handleShow();
        // const conversation_id = response.data.conversation_id;
        // navigate(`/messaging/${conversation_id}`);
      })
      .catch((error) => {
        console.error("Error creating conversation:", error);
        setmsgLoading(false);
      });
  };

  useEffect(() => {
    if (conversationId) {
      handleShow();
    }
  }, [conversationId]);

  const [isFollowed, setIsFollowed] = useState(false);

  const handleFollowClick = () => {
    setIsFollowed(!isFollowed);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isPopupVisible && !event.target.closest(".popup-content")) {
        togglePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible]);

  const handleCloseModal2 = () => {
    setShowModal(false);
    setShowModal2(false);
    setSelectedOption(null);
  };

  const handleFollowUnfollow = async (e) => {
    setFollowButtonloading(true);
    if (e) e.preventDefault();
    const url = isFollowed
      ? "/v1/profile/unfollow-profile"
      : "/v1/profile/follow-profile";
    const payload = {
      followed_profile_id: selectedPerformerId,
      follower_profile_id: Cookies.get("profileOwnerId"),
    };

    try {
      const response = await axiosInstance.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        setIsFollowed(!isFollowed);
        console.log("you have successfully followed the profile:", response);
      } else {
        console.error("API Error:", response.status, response.data);
      }
    } catch (error) {
      console.error("Request Error:", error);
    } finally {
      // End loading state when the request is finished
      setFollowButtonloading(false);
    }
  };

  const isMobile = window.innerWidth <= 767;

  const socialMediaLinks = Object.entries(venueData.venue_social_media || {})
    .filter(([key, url]) => url) // Remove empty links
    .map(([key, url]) => {
      const icons = {
        FACEBOOK: faSquareFacebook,
        INSTAGRAM: faSquareInstagram,
        TIKTOK: faTiktok,
        YOUTUBE: faSquareYoutube,
        SPOTIFY: faSpotify,
        LINKEDIN: faLinkedinIn,
        X: faX,
        MASTODON: faMastodon,
        MIX_CLOUD: faMixcloud,
        SOUND_CLOUD: faSoundcloud,
        VIMEO: faVimeo,
      };

      return { platform: key, url, icon: icons[key] };
    });

  return (
    <>
      {loading ? (
        // <Skeleton height={50} width={500} count={5} />
        <LoadingSkeleton />
      ) : (
        <>
          {venueData.venue_info &&
            venueData.venue_info.user_id === loggedInUserId && (
              <NavigationBar isPublished={isPublished} />
            )}
          {/* <Navbar
        navClass="defaultscroll sticky"
        menuClass="navigation-menu nav-left"
      /> */}
          <div
            className="profile-container"
            style={{ marginTop: isMobile ? "20%" : "2%" }}
          >
            <div className="profile-header" style={{ display: "flex" }}>
              <div
                style={{ cursor: "pointer", zIndex: 1 }}
                onClick={
                  venueData.venue_info &&
                  venueData.venue_info.user_id === loggedInUserId
                    ? () => setShowOptionsModal(true)
                    : null
                }
              >
                {/* {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : profilePictureUrl ? (
                  <img
                    src={profilePictureUrl}
                    alt="Profile Picture"
                    className="profile-image"
                  />
                ) : (
                  <img
                    src={defaultProfilePicture}
                    alt="default profile Picture"
                  />
                )} */}
                {profilePictureUrl ? (
                  <div className="perprofile-image-container">
                    <img
                      src={profilePictureUrl}
                      alt="Profile Picture"
                      className="perprofile-image"
                    />
                  </div>
                ) : null}
                {/* {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : profilePictureUrl ? (
                  <img
                    src={profilePictureUrl}
                    alt="Profile Picture"
                    className="profile-image"
                  />
                ) : null} */}
              </div>

              <div className="profile-details">
                <div className="profile-info">
                  <h1 className="performerName">
                    {venueData.venue_info?.profile_name ||
                      (venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId
                        ? "Enter Venue name"
                        : "")}{" "}
                    {/* {venueData.venue_info?.profile_name ||
                      (venueData.venue_info.user_id === loggedInUserId
                        ? "Enter Venue name"
                        : "")}{" "} */}
                    <a href={"/update-venue-name"}>
                      {venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              color: "#949A9C ",
                              marginRight: "5px",
                              fontSize: "20px",
                            }}
                          />
                        )}
                    </a>
                    {"  "}
                    {venueData?.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <button
                          type="submit"
                          style={{
                            backgroundColor:
                              isPublishButtonEnabled && !isProfileSuspended
                                ? "#28a745"
                                : "#28a745",
                            opacity: publishButtonloading
                              ? "0.6"
                              : isPublished
                              ? "1"
                              : isPublishButtonEnabled && !isProfileSuspended
                              ? "1"
                              : "0.6",
                            cursor:
                              publishButtonloading && !isProfileSuspended
                                ? "not-allowed"
                                : "pointer",
                            width: "120px",
                          }}
                          disabled={
                            publishButtonloading ||
                            !isPublishButtonEnabled ||
                            isProfileSuspended
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            if (isPublished) {
                              confirmUnpublish(e);
                            } else {
                              handlePublishProfile(e);
                            }
                          }}
                          className="publish-btn"
                          title={
                            isProfileSuspended
                              ? "Your profile is suspended"
                              : !isPublishButtonEnabled && !isPublished
                              ? "Add a price point value and profile picture to publish the profile"
                              : ""
                          }
                        >
                          {publishButtonloading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                borderWidth: "0.1rem",
                              }}
                            />
                          ) : isPublished ? (
                            "Unpublish"
                          ) : (
                            "Publish"
                          )}
                          {/* {isPublished ? "Unpublish" : "Publish"} */}
                        </button>
                      )}
                    {/* <button
                  className="publish-btn"
                  style={{
                    backgroundColor: isPublishButtonEnabled
                      ? "#418abb"
                      : "#88a1bb",
                    cursor: isPublishButtonEnabled ? "pointer" : "not-allowed",
                  }}
                  disabled={!isPublishButtonEnabled}
                  onClick={handlePublishProfile}
                >
                  Publish
                </button> */}
                  </h1>
                  <div
                    className="stars"
                    style={{ marginBottom: "4%", marginTop: "-4%" }}
                  >
                    <StarRatings
                      rating={ratings?.starts_average}
                      starRatedColor="#FCCC4D"
                      numberOfStars={5}
                      name="rating"
                      starDimension="16px"
                      starSpacing="3px"
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "22px" }}>
                  <p className="venueTagline" style={{ marginTop: "2px" }}>
                    {venueData.venue_info?.tag_line ||
                      (venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId
                        ? "Add your tagline"
                        : "")}{" "}
                    <a href={"/update-venue-tagline"}>
                      {venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              color: "#949A9C ",
                              marginRight: "5px",
                              fontSize: "15px",
                            }}
                          />
                        )}
                    </a>
                    {"  "}
                  </p>
                  {/* <p className="venueTagline" style={{ marginTop: "2px" }}>
                    {venueData.venue_info?.tag_line ||
                      (venueData.venue_info.user_id === loggedInUserId
                        ? "Add your tagline"
                        : "")}{" "}
                    <a href={"/update-venue-tagline"}>
                      {venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{
                              color: "#949A9C ",
                              marginRight: "5px",
                              fontSize: "15px",
                            }}
                          />
                        )}
                    </a>
                    {"  "}
                  </p> */}
                </div>
                <br></br>
                {/* <div className="social"> */}
                <div style={{ marginLeft: "22px", marginTop: "-3%" }}>
                  <span className="social-icons">
                    {/* {socialMediaLinks.slice(0, 5).map((link, index) => (
                      <a key={index} href={link.url}>
                        <FontAwesomeIcon
                          icon={link.icon}
                          style={{ color: "#FFD43B", margin: "5px" }}
                        />
                      </a>
                    ))}
                    {socialMediaLinks.length > 5 && (
                      <FontAwesomeIcon
                        icon={faEllipsis}
                        style={{
                          color: "#949A9C",
                          marginLeft: "5px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={togglePopup}
                      />
                    )} */}
                    {socialMediaLinks ? (
                      <>
                        <span>
                          {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId
                            ? "Social Media"
                            : ""}
                        </span>
                        {/* <span>Social Media</span> */}
                        {socialMediaLinks.slice(0, 5).map((link, index) => (
                          <a key={index} href={link.url}>
                            <FontAwesomeIcon
                              icon={link.icon}
                              style={{ color: "#FFD43B", margin: "5px" }}
                            />
                          </a>
                        ))}
                        {socialMediaLinks.length > 5 && (
                          <FontAwesomeIcon
                            icon={faEllipsis}
                            style={{
                              color: "#949A9C",
                              marginLeft: "5px",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={togglePopup}
                          />
                        )}
                      </>
                    ) : (
                      <span style={{ color: "#949A9C" }}>
                        Add your social media
                      </span>
                    )}
                  </span>
                  {venueData.venue_info &&
                    venueData.venue_info.user_id === loggedInUserId && (
                      <a href="/update-venue-socialmedia">
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{
                            color: "#949A9C",
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                        />
                      </a>
                    )}
                  {isPopupVisible && (
                    <div className="popup-box">
                      <div className="popup-content">
                        <button className="close-icon" onClick={togglePopup}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <h6>All Social Media Accounts</h6>
                        <div className="social-icons-popup">
                          {socialMediaLinks.map((link, index) => (
                            <a key={index} href={link.url}>
                              <FontAwesomeIcon
                                icon={link.icon}
                                style={{ color: "#FFD43B", margin: "5px" }}
                              />
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Modal
                  size="sm"
                  centered
                  show={showOptionsModal}
                  onHide={handleCloseModal}
                >
                  {/* <Modal.Header className="text-center" closeButton>
                    <Modal.Title className="text-center">Options</Modal.Title>
                  </Modal.Header> */}
                  <Modal.Body className="text-center">
                    {loading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <>
                        <p
                          onClick={() => handleUploadProfilePictureClick()} // Call function to trigger file input click event
                          className="text-dark fw-medium"
                          style={{ cursor: "pointer" }}
                        >
                          Upload Profile Picture
                        </p>
                        <input
                          type="file"
                          id="profilePicture"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleUploadProfilePicture}
                        />
                        <p
                          onClick={() => handleOptionClick("deletePicture")}
                          className="text-dark fw-medium"
                          style={{ cursor: "pointer" }}
                        >
                          Delete Profile Picture
                        </p>
                        {/* <p
                      onClick={() => handleOptionClick("changePassword")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Change Password
                    </p> */}
                      </>
                    )}
                  </Modal.Body>
                  {/* <Modal.Body className="text-center">
                    <p
                      onClick={() => handleOptionClick("deletePicture")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Delete Profile Picture
                    </p>
                    <p
                      onClick={() => handleOptionClick("changePassword")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Change Password
                    </p>
                  </Modal.Body> */}
                  <Modal.Footer className="d-flex justify-content-center align-items-center">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showConfirmationModal}
                  onHide={() => setShowConfirmationModal(false)}
                  size="sm"
                  centered
                >
                  <Modal.Header className="text-center" closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-center">
                    Are you sure you want to delete your profile picture?
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={() => setShowConfirmationModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleDeleteProfilePicture}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* <div className="tagline">
              Lead vocalist long text as tagline{" "}
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  color: "#949A9C ",
                  marginLeft: "5px",
                  fontSize: "15px",
                }}
              />
            </div> */}

                {/* <div className="review" style={{ display: 'flex', alignItems: 'center' }}>
                
                <div className="stars">
                  <StarRatings
                  rating={ratings?.starts_average}
                  starRatedColor="#FCCC4D"
                  numberOfStars={5}
                  name="rating"
                  starDimension="16px"
                  starSpacing="3px"
                />
                
                </div>
                <div className="c-rating-text" style={{color:'#f5a623',marginLeft: '10px', fontSize:'17px', marginTop: '5px'}}>
                {" "}{ratings && ratings?.starts_average ? ratings?.starts_average : "0"}
                </div>
                
                
              </div> */}
                {/* <div className="review-text">Review:</div> */}
                {/* <div className="stars">★★★★☆ 4.5</div> */}

                <div style={{ display: "flex", gap: "2%", marginLeft: "3%" }}>
                  {venueData.venue_info &&
                    venueData.venue_info.user_id !== loggedInUserId && (
                      <button
                        className="follow-btn"
                        onClick={handleFollowUnfollow}
                        style={{
                          cursor: followButtonloading
                            ? "not-allowed"
                            : "pointer",
                          opacity: followButtonloading ? 0.6 : 1,
                          transition: "opacity 0.3s ease",
                          minWidth: "100px",
                        }}
                        disabled={followButtonloading}
                      >
                        {followButtonloading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : isFollowed ? (
                          "Unfollow"
                        ) : (
                          "Follow"
                        )}
                      </button>
                    )}
                  {/* {venueData.venue_info &&
                venueData.venue_info.user_id != loggedInUserId && (
                  <button className="follow-btn">
                    {isFollowed ? "Unfollow -" : "Follow +"}
                  </button>
                )} */}
                  {venueData.venue_info &&
                    venueData.venue_info.user_id != loggedInUserId && (
                      <button
                        className="message-btn"
                        onClick={navigatetomessaging}
                      >
                        <FontAwesomeIcon
                          icon={faComment}
                          style={{ marginRight: "5px", fontSize: "15px" }}
                        />
                        {msgloading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only"> Loading...</span>
                          </>
                        ) : (
                          "Message"
                        )}
                      </button>
                    )}
                  <Modal show={showModal3} onHide={handleClose3} size="xl">
                    <Modal.Header>
                      <Modal.Title>Messaging</Modal.Title>
                      <button
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          border: "none",
                          background: "transparent",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                        onClick={handleClose3}
                      >
                        &times;
                      </button>
                    </Modal.Header>
                    <Modal.Body>
                      {/* {conversationId && <App conversation_id={conversationId} />} */}
                      {conversationId ? (
                        <App conversation_id={conversationId} />
                      ) : (
                        "Loading..."
                      )}
                    </Modal.Body>
                  </Modal>

                  {/* {venueData.venue_info &&
                    venueData.venue_info.user_id != loggedInUserId && (
                      <button className="book-btn">
                        Book
                      </button>
                    )} */}
                </div>
                {loading ? (
                  <>
                    <Skeleton height={30} width={200} />
                    <Skeleton height={20} width={100} />
                  </>
                ) : (
                  <div
                    className="profile-card teacher__item2 transition-3 mb-30 bio-section"
                    style={{ marginLeft: "-1px" }}
                  >
                    <Card
                      className="mb-3 profile-card-inner"
                      style={{ backgroundColor: "#f8f9fa" }}
                    >
                      {/* {venueData.venue_info?.thumbnail_bio ? ( */}
                      {Array.isArray(venueData.venue_info?.thumbnail_bio) &&
                      venueData.venue_info.thumbnail_bio.length > 0 ? (
                        <>
                          <h3>Bio</h3>
                          <p>
                            {/* {venueData.venue_info.thumbnail_bio}{" "} */}
                            {venueData.venue_info.thumbnail_bio.map(
                              (line, index) => (
                                <p key={index}>{line ? line : <br />}</p>
                              )
                            )}{" "}
                            <a href="/update-venue-shortBio">
                              {venueData.venue_info &&
                                venueData.venue_info.user_id ===
                                  loggedInUserId && (
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    style={{
                                      color: "#949A9C ",
                                      marginLeft: "5px",
                                      fontSize: "15px",
                                    }}
                                  />
                                )}
                            </a>
                          </p>
                        </>
                      ) : (
                        <p>
                          {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId
                            ? "Add venue bio"
                            : ""}{" "}
                          <a href="/update-venue-shortBio">
                            {venueData.venue_info &&
                              venueData.venue_info.user_id ===
                                loggedInUserId && (
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                    color: "#949A9C ",
                                    marginLeft: "5px",
                                    fontSize: "15px",
                                  }}
                                />
                              )}
                          </a>
                        </p>
                      )}
                      {/* <h3>Bio</h3>
                    <p>
                      {venueData.venue_info?.thumbnail_bio}{" "}
                      <a href="/update-venue-shortBio">
                        {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{
                                color: "#949A9C ",
                                marginLeft: "5px",
                                fontSize: "15px",
                              }}
                            />
                          )}
                      </a>
                    </p> */}
                    </Card>
                  </div>
                )}

                {/* {venueData.venue_info &&
              venueData.venue_info.user_id != loggedInUserId && (
                <button className="follow-btn" onClick={handleFollowUnfollow}>
                  {isFollowed ? "Unfollow -" : "Follow +"}
                </button>
              )}
            {venueData.venue_info &&
              venueData.venue_info.user_id != loggedInUserId && (
                <button className="message-btn">
                  <FontAwesomeIcon
                    icon={faComment}
                    style={{ marginRight: "5px", fontSize: "15px" }}
                  />
                  Message
                </button>
              )} */}
              </div>
              {/* Add new card column here */}
              <div className="profile-card teacher__item2 transition-3 mb-30">
                <Card
                  className="mb-3 profile-card-inner"
                  style={{ backgroundColor: "#f8f9fa" }}
                >
                  <Card.Body className="badgestyle">
                    {/* <p style={{ marginTop: "-2%" }}>Types:</p>
                <div className="badge-container">
                  {venueData.venue_info?.venue_types ? (
                    venueData.venue_info?.venue_types.map((type, index) => (
                      <div key={index} className="badge">
                        {type}
                      </div>
                    ))
                  ) : (
                    <div className="badge">Not specified</div>
                  )}

                  <div className="edit-icon">
                    <a href="/update-venue-type">
                      {venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: "#949A9C", marginRight: "5px" }}
                          />
                        )}
                    </a>
                  </div>
                </div> */}
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "2%" }}>Types: </p>
                      {venueData.venue_info?.user_id === loggedInUserId && (
                        <div className="edit-icon">
                          <a href="/update-venue-type">
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: "#949A9C ", marginTop: "1.5%" }}
                            />
                          </a>
                        </div>
                      )}
                    </div>

                    {venueData.venue_info?.venue_types ? (
                      <>
                        <TagContainer>
                          {venueData.venue_info.venue_types.map((type) => (
                            <TagBox key={type}>{type}</TagBox>
                          ))}
                        </TagContainer>
                      </>
                    ) : (
                      <span>venue types</span>
                      // <div className="badge">Not specified</div>
                    )}

                    {/* <p style={{ marginTop: "1%" }}>Genres:</p>
                <div className="badge-container">
                  {venueData.venue_info?.venue_genres ? (
                    venueData.venue_info?.venue_genres.map((type, index) => (
                      <div key={index} className="badge">
                        {type}
                      </div>
                    ))
                  ) : (
                    <div className="badge">Not specified</div>
                  )}

                  <div className="edit-icon">
                    <a href="/update-venue-genre">
                      {venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: "#949A9C", marginRight: "5px" }}
                          />
                        )}
                    </a>
                  </div>
                </div> */}

                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "2%" }}>Genres: </p>
                      {venueData.venue_info?.user_id === loggedInUserId && (
                        <div className="edit-icon">
                          <a href="/update-venue-genre">
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: "#949A9C ", marginTop: "1.5%" }}
                            />
                          </a>
                        </div>
                      )}
                    </div>

                    {venueData.venue_info?.venue_genres ? (
                      <>
                        <TagContainer>
                          {venueData.venue_info.venue_genres.map((type) => (
                            <TagBox key={type}>{type}</TagBox>
                          ))}
                        </TagContainer>
                      </>
                    ) : (
                      <span>venue genres</span>
                      // <div className="badge">Not specified</div>
                    )}

                    <p className="mt-3">
                      {" "}
                      <FontAwesomeIcon
                        icon={faDollar}
                        style={{ color: "#FFD43B", marginRight: "5px" }}
                      />{" "}
                      {venueData.venue_info?.price_point_value_from === -1 ? (
                        "Add your price point value"
                      ) : (
                        <>
                          AUD {venueData.venue_info?.price_point_value_from}-
                          {venueData.venue_info?.price_point_value_to}
                        </>
                      )}{" "}
                      {/* AUD {venueData.venue_info?.price_point_value}{" "} */}
                      <a href={"/update-venue-pricepoint"}>
                        {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: "#949A9C ", marginRight: "5px" }}
                            />
                          )}
                      </a>
                    </p>

                    {/* <p className="mt-3">
                  {" "}
                  <strong>Contact Details:</strong>{" "}
                  <a href={"/update-venue-contact"}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId && (
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#949A9C ", marginRight: "5px" }}
                        />
                      )}
                  </a>
                </p> */}
                    <p className="mt-3">
                      {venueData && venueData.venue_contact ? (
                        <>
                          <FontAwesomeIcon
                            icon={faPhone}
                            style={{ color: "#FFD43B", marginRight: "5px" }}
                          />
                          {venueData.venue_contact.display_phone.phone_number ||
                            (venueData.venue_info &&
                            venueData.venue_info.user_id === loggedInUserId
                              ? "Add your mobile number"
                              : "")}{" "}
                          <a href={"/update-venue-phoneNumber"}>
                            {venueData.venue_info &&
                              venueData.venue_info.user_id ===
                                loggedInUserId && (
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                    color: "#949A9C ",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                          </a>
                          {venueData.venue_info &&
                            venueData.venue_info.user_id === loggedInUserId && (
                              <FontAwesomeIcon
                                icon={
                                  venueData.venue_contact.display_phone
                                    .is_verified
                                    ? faCheckCircle
                                    : faCircleXmark
                                }
                                style={{
                                  color: venueData.venue_contact.display_phone
                                    .is_verified
                                    ? "green"
                                    : "red",
                                  cursor: "pointer",
                                }}
                                // onClick={() =>
                                //   !venueData.venue_contact.display_phone
                                //     .is_verified && setShowModal(true)
                                // }
                              />

                              // <button
                              //   onClick={handleShowModal2}
                              //   className="verify-btns"
                              // >
                              //   Verify
                              // </button>
                            )}
                        </>
                      ) : null}{" "}
                    </p>
                    <VerifyPhoneNumberModal
                      show={showModal2}
                      handleClose={handleCloseModal2}
                    />

                    <p className="mt-3">
                      {venueData && venueData.venue_contact ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{ color: "#FFD43B", marginRight: "5px" }}
                          />
                          {venueData.venue_contact.display_email.email ||
                            (venueData.venue_info &&
                            venueData.venue_info.user_id === loggedInUserId
                              ? "Add your Email address"
                              : "")}{" "}
                          <a href={"/update-venue-email"}>
                            {venueData.venue_info &&
                              venueData.venue_info.user_id ===
                                loggedInUserId && (
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                    color: "#949A9C ",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                          </a>
                          {venueData.venue_info &&
                            venueData.venue_info.user_id === loggedInUserId && (
                              <FontAwesomeIcon
                                icon={
                                  venueData.venue_contact.display_email
                                    .is_verified
                                    ? faCheckCircle
                                    : faCircleXmark
                                }
                                style={{
                                  color: venueData.venue_contact.display_email
                                    .is_verified
                                    ? "green"
                                    : "red",
                                  cursor: "pointer",
                                }}
                                // onClick={() =>
                                //   !venueData.venue_contact.display_email
                                //     .is_verified && setShowModal(true)
                                // }
                              />
                              // <button
                              //   onClick={handleShowModal}
                              //   className="verify-btns"
                              // >
                              //   Verify
                              // </button>
                            )}
                        </>
                      ) : null}
                    </p>
                    <VerifyEmailModal
                      show={showModal}
                      handleClose={handleCloseModal2}
                    />

                    <p className="mt-3">
                      {" "}
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#FFD43B", marginRight: "5px" }}
                      />{" "}
                      {venueData.venue_contact?.location.address ||
                        (venueData.venue_info &&
                        venueData.venue_info.user_id === loggedInUserId
                          ? "Add your address"
                          : "")}{" "}
                      <a href={"/update-venue-location"}>
                        {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: "#949A9C ", marginRight: "5px" }}
                            />
                          )}
                      </a>
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>

            {isPublished && (
              <div className="tabs">
                <button
                  className={`tab ${activeTab === "posts" ? "active" : ""}`}
                  onClick={() => setActiveTab("posts")}
                >
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    style={{ marginRight: "5px" }}
                  />
                  Posts
                </button>
                <button
                  className={`tab ${activeTab === "calendar" ? "active" : ""}`}
                  onClick={() => setActiveTab("calendar")}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ marginRight: "5px" }}
                  />
                  Calendar
                </button>

                <button
                  className={`tab ${activeTab === "reviews" ? "active" : ""}`}
                  onClick={() => setActiveTab("reviews")}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{ marginRight: "5px" }}
                  />
                  Reviews
                </button>
                <button
                  className={`tab ${activeTab === "gallery" ? "active" : ""}`}
                  onClick={() => setActiveTab("gallery")}
                >
                  <FontAwesomeIcon
                    icon={faImage}
                    style={{ marginRight: "5px" }}
                  />
                  Gallery
                </button>
              </div>
            )}

            {isPublished && (
              <div className="tab-content">
                {activeTab === "posts" && (
                  <div>
                    <div>
                      {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId ? (
                        <ViewOpportunities profileId={selectedPerformerId} />
                      ) : (
                        <ViewOpportunitiesbyOthers
                          profileId={selectedPerformerId}
                        />
                      )}
                    </div>
                    <div>
                      {/* <ViewCalloutsbySelf /> */}
                      {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId ? (
                        <ViewCalloutsbySelf profileId={selectedPerformerId} />
                      ) : (
                        <ViewCalloutsbyOthers profileId={selectedPerformerId} />
                      )}
                    </div>
                    <div>
                      {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId ? (
                        <ViewVenueClassifieds profileId={selectedPerformerId} />
                      ) : (
                        <ViewVenueClassifiedsbyOthers
                          profileId={selectedPerformerId}
                        />
                      )}
                    </div>
                  </div>
                )}

                {activeTab === "reviews" ? (
                  venueData.venue_info &&
                  venueData.venue_info.user_id === loggedInUserId ? (
                    <Tab.Container defaultActiveKey="reviews_by_self">
                      <Row className="justify-content-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="reviews_by_self"
                              style={{ color: "#418abb" }}
                              onSelect={() => setActiveTab("reviews_by_self")}
                            >
                              Reviews Written
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="reviews_by_others"
                              style={{ color: "#418abb" }}
                              onSelect={() => setActiveTab("reviews_by_others")}
                            >
                              Reviews Received
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Row>
                      <Row className="justify-content-center">
                        <Tab.Content>
                          <Tab.Pane eventKey="reviews_by_self">
                            <br />
                            <div style={{ padding: "5%" }}>
                              <div className="row">
                                {loadingSelf ? (
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                  />
                                ) : (
                                  <div className="row">
                                    {reviewsBySelf.map((review, index) => (
                                      <div
                                        key={index}
                                        className="col-md-12 mb-3"
                                      >
                                        <Card
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "15px",
                                            position: "relative",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          <Card.Body className="d-flex align-items-center">
                                            <div className="col-md-2">
                                              <img
                                                src={
                                                  venueData.venue_info
                                                    ?.profile_picture_url ??
                                                  "https://via.placeholder.com/150"
                                                }
                                                alt="Author"
                                                className="rounded-circle mr-3"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  marginTop: "-5%",
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-10">
                                              <h6>
                                                {venueData.venue_info
                                                  ?.profile_name
                                                  ? `${venueData.venue_info?.profile_name} `
                                                  : "Anonymous Reviewer"}
                                              </h6>
                                              <div className="d-flex align-items-center">
                                                {Array.from({
                                                  length: review.stars,
                                                }).map((_, index) => (
                                                  <span
                                                    key={index}
                                                    className="text-warning"
                                                  >
                                                    &#9733;
                                                  </span>
                                                ))}
                                              </div>
                                              <p>{review.review}</p>
                                            </div>
                                            <button
                                              onClick={() =>
                                                deleteReview(review._id)
                                              }
                                              disabled={loadingReview}
                                              style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                                backgroundColor: loadingReview
                                                  ? "#ccc"
                                                  : "#dc3545",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                padding: "5px 10px",
                                                cursor: loadingReview
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrash} />{" "}
                                              Delete
                                            </button>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="reviews_by_others">
                            <br />
                            <div style={{ padding: "5%" }}>
                              <div className="row">
                                {loadingOthers ? (
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                  />
                                ) : (
                                  <div className="row">
                                    {reviews.map((review, index) => (
                                      <div
                                        key={index}
                                        className="col-md-12 mb-3"
                                      >
                                        <Card
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "15px",
                                            position: "relative",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          <Card.Body className="d-flex align-items-center">
                                            <div className="col-md-2">
                                              <img
                                                src={
                                                  review.author_profile_picture_url ||
                                                  "https://via.placeholder.com/150"
                                                }
                                                alt="Author"
                                                className="rounded-circle mr-3"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  marginTop: "-5%",
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-10">
                                              <h6>
                                                {review.author_display_name ||
                                                  "Anonymous Reviewer"}
                                              </h6>
                                              <div className="d-flex align-items-center">
                                                {Array.from({
                                                  length: review.stars,
                                                }).map((_, index) => (
                                                  <span
                                                    key={index}
                                                    className="text-warning"
                                                  >
                                                    &#9733;
                                                  </span>
                                                ))}
                                              </div>
                                              <p>{review.review}</p>
                                              <FiFlag
                                                variant="link"
                                                onClick={() =>
                                                  handleFlagClick(review._id)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "10px",
                                                  color: flaggedReviews[
                                                    review._id
                                                  ]
                                                    ? "red"
                                                    : "none",
                                                  fill: flaggedReviews[
                                                    review._id
                                                  ]
                                                    ? "red"
                                                    : "none", // Ensure the fill property is also applied
                                                }}
                                              />

                                              <Modal
                                                show={showFlagModal}
                                                onHide={() =>
                                                  setShowFlagModal(false)
                                                }
                                              >
                                                <Modal.Header>
                                                  <Modal.Title>
                                                    Flag Review
                                                  </Modal.Title>
                                                  <button
                                                    style={{
                                                      position: "absolute",
                                                      // top: "10px",
                                                      right: "10px",
                                                      border: "none",
                                                      background: "transparent",
                                                      fontSize: "1.5rem",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      setShowFlagModal(false)
                                                    }
                                                  >
                                                    &times;
                                                  </button>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <Form>
                                                    <Form.Group controlId="sectionType">
                                                      <Form.Label>
                                                        Section
                                                      </Form.Label>
                                                      <Form.Control
                                                        as="select"
                                                        value={selectedSection}
                                                        onChange={
                                                          handleSectionChange
                                                        }
                                                      >
                                                        <option
                                                          value=""
                                                          disabled
                                                        >
                                                          Select a section
                                                        </option>
                                                        {sectionType.map(
                                                          (section, index) => (
                                                            <option
                                                              key={index}
                                                              value={section
                                                                .toUpperCase()
                                                                .replace(
                                                                  " ",
                                                                  "_"
                                                                )}
                                                            >
                                                              {section}
                                                            </option>
                                                          )
                                                        )}
                                                      </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId="flagComment">
                                                      <Form.Label>
                                                        Comment
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        placeholder="Enter your comment"
                                                        value={flagComment}
                                                        onChange={
                                                          handleFlagCommentChange
                                                        }
                                                      />
                                                    </Form.Group>
                                                  </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                      setShowFlagModal(false)
                                                    }
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={handleFlagSave}
                                                  >
                                                    Save Comment
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Row>
                    </Tab.Container>
                  ) : (
                    <div style={{ padding: "5%" }}>
                      <div className="row">
                        {loadingOthers ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <div className="row">
                            {reviews.map((review, index) => (
                              <div key={index} className="col-md-12 mb-3">
                                <Card
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "15px",
                                    position: "relative",
                                    border: "1px solid #ddd",
                                  }}
                                >
                                  <Card.Body className="d-flex align-items-center">
                                    <div className="col-md-2">
                                      <img
                                        src={
                                          review.author_profile_picture_url ||
                                          "https://via.placeholder.com/150"
                                        }
                                        alt="Author"
                                        className="rounded-circle mr-3"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          marginTop: "-5%",
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-10">
                                      <h6>
                                        {review.author_display_name ||
                                          "Anonymous Reviewer"}
                                      </h6>
                                      <div className="d-flex align-items-center">
                                        {Array.from({
                                          length: review.stars,
                                        }).map((_, index) => (
                                          <span
                                            key={index}
                                            className="text-warning"
                                          >
                                            &#9733;
                                          </span>
                                        ))}
                                      </div>
                                      <p>{review.review}</p>
                                      <FiFlag
                                        variant="link"
                                        onClick={() =>
                                          handleFlagClick(review._id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                          color: flaggedReviews[review._id]
                                            ? "red"
                                            : "none",
                                          fill: flaggedReviews[review._id]
                                            ? "red"
                                            : "none", // Ensure the fill property is also applied
                                        }}
                                      />

                                      <Modal
                                        show={showFlagModal}
                                        onHide={() => setShowFlagModal(false)}
                                      >
                                        <Modal.Header>
                                          <Modal.Title>Flag Review</Modal.Title>
                                          <button
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "10px",
                                              border: "none",
                                              background: "transparent",
                                              fontSize: "1.5rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setShowFlagModal(false)
                                            }
                                          >
                                            &times;
                                          </button>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <Form>
                                            <Form.Group controlId="sectionType">
                                              <Form.Label>Section</Form.Label>
                                              <Form.Control
                                                as="select"
                                                value={selectedSection}
                                                onChange={handleSectionChange}
                                              >
                                                <option value="" disabled>
                                                  Select a section
                                                </option>
                                                {sectionType.map(
                                                  (section, index) => (
                                                    <option
                                                      key={index}
                                                      value={section
                                                        .toUpperCase()
                                                        .replace(" ", "_")}
                                                    >
                                                      {section}
                                                    </option>
                                                  )
                                                )}
                                              </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="flagComment">
                                              <Form.Label>Comment</Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Enter your comment"
                                                value={flagComment}
                                                onChange={
                                                  handleFlagCommentChange
                                                }
                                              />
                                            </Form.Group>
                                          </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="secondary"
                                            onClick={() =>
                                              setShowFlagModal(false)
                                            }
                                          >
                                            Close
                                          </Button>
                                          <Button
                                            variant="primary"
                                            onClick={handleFlagSave}
                                          >
                                            Save Comment
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                ) : null}

                {activeTab === "gallery" && (
                  <Tab.Container defaultActiveKey="video_gallery">
                    <Row className="justify-content-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="video_gallery"
                            style={{ color: "#418abb" }}
                          >
                            Video Gallery
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="image_gallery"
                            style={{ color: "#418abb" }}
                          >
                            Image Gallery
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Row>
                    <Row className="justify-content-center">
                      <Tab.Content>
                        <Tab.Pane eventKey="video_gallery">
                          <br />
                          <div
                            style={{
                              paddingLeft: "4%",
                              paddingRight: "4%",
                              paddingBottom: "10%",
                            }}
                          >
                            <h4>
                              {venueData.venue_info &&
                                venueData.venue_info.user_id ===
                                  loggedInUserId && (
                                  <Button
                                    variant="outline-secondary"
                                    style={{
                                      backgroundColor: "white",
                                      border: "none",
                                      color: "#418abb",
                                      borderRadius: "15px",
                                    }}
                                    as={Link}
                                    to="/update-venue-videoGallery"
                                  >
                                    <FaEdit size={20} />
                                  </Button>
                                )}
                              Video Gallery
                            </h4>
                            {videoGallery ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns:
                                    "repeat(auto-fit, minmax(200px, 1fr))",
                                  gap: "10px",
                                }}
                              >
                                {Array.isArray(videoGallery)
                                  ? videoGallery.map((videoLink, index) => {
                                      const videoId = extractVideoIdFromLink(
                                        videoLink.link
                                      );
                                      const embedUrl = `https://www.youtube.com/embed/${videoId}`;

                                      return (
                                        <div key={index}>
                                          <iframe
                                            src={embedUrl}
                                            width="100%"
                                            height="250"
                                            frameBorder="0"
                                            allowFullScreen
                                          ></iframe>

                                          <Button
                                            variant="outline-danger"
                                            onClick={() =>
                                              deleteVideo(videoLink._id)
                                            }
                                            style={{
                                              minWidth: "100px",
                                              cursor: deletevideoloading[
                                                videoLink._id
                                              ]
                                                ? "not-allowed"
                                                : "pointer",
                                              backgroundColor: "transparent",
                                              border: "1px solid red",
                                              color: "red",
                                            }}
                                            disabled={
                                              deletevideoloading[videoLink._id]
                                            }
                                            className="dlt-button"
                                          >
                                            {deletevideoloading[
                                              videoLink._id
                                            ] ? (
                                              <Spinner
                                                animation="border"
                                                role="status"
                                                style={{
                                                  width: "1.5rem",
                                                  height: "1.5rem",
                                                  borderWidth: "0.1rem",
                                                }}
                                              />
                                            ) : (
                                              "Delete"
                                            )}
                                          </Button>
                                          <br />
                                        </div>
                                      );
                                    })
                                  : videoGallery
                                      .split(",")
                                      .map((videoLink, index) => {
                                        const videoId = extractVideoIdFromLink(
                                          videoLink.link
                                        );
                                        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

                                        return (
                                          <div key={index}>
                                            <iframe
                                              src={embedUrl}
                                              width="100%"
                                              height="250"
                                              frameBorder="0"
                                              allowFullScreen
                                            ></iframe>
                                            <br />
                                          </div>
                                        );
                                      })}
                              </div>
                            ) : (
                              <p>No videos found.</p>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="image_gallery">
                          <br />
                          <div
                            style={{
                              textAlign: "center",
                              paddingBottom: "10%",
                            }}
                          >
                            <h4>Image Gallery</h4>
                            <br></br>
                            <div className="text-center">
                              <center>
                                {venueData.venue_info &&
                                  venueData.venue_info.user_id ===
                                    loggedInUserId && (
                                    <Button
                                      variant="outline-primary"
                                      onClick={openFileInput}
                                      disabled={galleryLoading}
                                      style={{
                                        display: "flex",
                                        alignSelf: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "12px",
                                        fontSize: "15px",
                                        border: "none",
                                        boxShadow:
                                          "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        color: "white",
                                        backgroundColor: "#418abb",
                                        transition: "background-color 0.3s",
                                        cursor: galleryLoading
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      onMouseEnter={(e) => {
                                        e.target.style.backgroundColor =
                                          "#ff66c4";
                                      }}
                                      onMouseLeave={(e) => {
                                        e.target.style.backgroundColor =
                                          "#418abb";
                                      }}
                                    >
                                      <FaUpload
                                        style={{ marginRight: "5px" }}
                                      />
                                      Upload Gallery Picture
                                    </Button>
                                  )}
                              </center>
                              {/* <button
                                          onClick={openFileInput}
                                          style={{
                                            width: "30%",
                                            aspectRatio: 5.5,
                                            backgroundColor: "#418abb",
                                            border: "none",
                                            color: "white",
                                            borderRadius: "15px",
                                          }}
                                          onMouseOver={(e) => {
                                            e.target.style.backgroundColor =
                                              "#ff66c4";
                                          }}
                                          onMouseOut={(e) => {
                                            e.target.style.backgroundColor =
                                              "#418abb";
                                          }}
                                          onMouseDown={(e) => {
                                            e.target.style.backgroundColor =
                                              "#ff66c4";
                                          }}
                                          onMouseUp={(e) => {
                                            e.target.style.backgroundColor =
                                              "#418abb";
                                          }}
                                        >
                                          <FaUpload size={20} /> Upload Gallery
                                          Picture
                                        </button> */}
                              {galleryLoading && (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    borderWidth: "0.1rem",
                                  }}
                                />
                              )}

                              <input
                                type="file"
                                id="galleryPictures"
                                accept="image/*"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleUploadGalleryPictures}
                              />
                            </div>
                            <div>
                              <br></br>
                              <br></br>
                              <div
                                style={{
                                  paddingLeft: "2%",
                                  paddingRight: "2%",
                                }}
                              >
                                <Masonry
                                  breakpointCols={breakpointColumnsObj}
                                  className="my-masonry-grid"
                                  columnClassName="my-masonry-grid_column"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "3%",
                                  }} // Optional: Adjust spacing
                                >
                                  {galleryPictures.map((picture, index) => (
                                    <div
                                      key={picture.image_id}
                                      style={{
                                        position: "relative",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <HoverImage
                                          src={picture.image_url}
                                          alt={picture.image}
                                          style={{
                                            maxWidth: "100%",
                                            height: "auto",
                                            display: "block",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setCurrentImageIndex(index);
                                            setZoomedImageUrl(
                                              picture.image_url
                                            );
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            cursor: "pointer",
                                            background:
                                              "rgba(255, 255, 255, 0.5)",
                                            borderRadius: "20%",
                                          }}
                                          onClick={() =>
                                            handleEllipsisClick(
                                              picture.image_id
                                            )
                                          }
                                          // onClick={() =>
                                          //   setDeletingImageId(
                                          //     picture.image_id
                                          //   )
                                          // }
                                        >
                                          {venueData.venue_info &&
                                            venueData.venue_info.user_id ===
                                              loggedInUserId && (
                                              <FaEllipsisV size={20} />
                                            )}
                                        </div>
                                      </div>
                                      {/* Options Modal */}
                                      {/* <Modal
                                      show={showImageOptionsModal}
                                      onHide={() =>
                                        setShowImageOptionsModal(false)
                                      }
                                      centered
                                      size="sm"
                                      backdropStyle={{
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      }}
                                    > */}
                                      {/* <Modal.Header closeButton>
                                                      <Modal.Title>
                                                        Options
                                                      </Modal.Title>
                                                    </Modal.Header> */}
                                      {/* <Modal.Body className="text-center">
                                        <p
                                          onClick={handleDeleteOption}
                                          className="text-dark fw-medium"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Delete Image
                                        </p>
                                        <p>Hide Image</p>
                                      </Modal.Body>
                                      <Modal.Footer className="d-flex justify-content-center align-items-center">
                                        <Button
                                          className="text-center"
                                          variant="primary"
                                          onClick={() =>
                                            setShowImageOptionsModal(false)
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </Modal.Footer>
                                    </Modal> */}

                                      <Modal
                                        show={showImageConfirmationModal}
                                        onHide={() =>
                                          setShowImageConfirmationModal(false)
                                        }
                                        // backdropStyle={{
                                        //   opacity: 0.05,
                                        // }}
                                        backdropClassName="profile-custom-modal-backdrop"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Confirm Deletion
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          Are you sure you want to delete this
                                          image?
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              setShowImageConfirmationModal(
                                                false
                                              )
                                            }
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              handleDeletePicture(
                                                selectedImageId
                                              )
                                            }
                                            style={{
                                              minWidth: "100px",
                                              cursor: deleteloading
                                                ? "not-allowed"
                                                : "pointer",
                                              opacity: deleteloading ? 0.6 : 1,
                                              transition: "opacity 0.3s ease",
                                            }}
                                            disabled={deleteloading}
                                          >
                                            {deleteloading ? (
                                              <Spinner
                                                animation="border"
                                                role="status"
                                                style={{
                                                  width: "1.5rem",
                                                  height: "1.5rem",
                                                  borderWidth: "0.1rem",
                                                }}
                                              />
                                            ) : (
                                              "Delete"
                                            )}
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>

                                      <Modal
                                        show={currentImageIndex === index}
                                        onHide={() =>
                                          setCurrentImageIndex(null)
                                        }
                                        size="xl"
                                      >
                                        <Modal.Body>
                                          <div
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <img
                                              src={zoomedImageUrl}
                                              alt="Zoomed"
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                                borderRadius: "8px",
                                                objectFit: "contain",
                                                maxHeight: "80vh",
                                                maxWidth: "80vw",
                                              }}
                                            />
                                            <div
                                              className="close-icon"
                                              onClick={() =>
                                                setCurrentImageIndex(null)
                                              }
                                              style={{
                                                position: "absolute",
                                                top: "10px",
                                                left: "10px",
                                                cursor: "pointer",
                                                color: "Grey",
                                                fontSize: "24px",
                                              }}
                                            >
                                              &#x2715; {/* Close icon (X) */}
                                            </div>
                                          </div>
                                          {/* Previous arrow */}
                                          {showPreviousArrow && (
                                            <div
                                              className="arrow"
                                              onClick={handlePreviousClick}
                                              style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "10px",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                color: "Grey",
                                                fontSize: "36px", // Larger font size for the arrow
                                              }}
                                            >
                                              <FaAngleLeft />
                                            </div>
                                          )}

                                          {/* Next arrow */}
                                          {showNextArrow && (
                                            <div
                                              className="arrow"
                                              onClick={handleNextClick}
                                              style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "10px",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                color: "Grey",
                                                fontSize: "36px",
                                              }}
                                            >
                                              <FaAngleRight />
                                            </div>
                                          )}
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                  ))}
                                </Masonry>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                )}
                {activeTab === "calendar" && (
                  <div style={{ padding: "2%", marginTop: "3%" }}>
                    {venueData.venue_info &&
                      venueData.venue_info.user_id === loggedInUserId &&
                      profileOwnerId.startsWith("PROFILE-PERFORMER") && (
                        <Button
                          style={{
                            borderRadius: "12px",
                            fontSize: "15px",
                            backgroundColor: clickedBook
                              ? "#418abb"
                              : "#88a1bb",
                            border: "none",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            color: "white",
                          }}
                          variant={
                            hoveredBook || clickedBook ? "secondary" : "primary"
                          }
                          onMouseEnter={() => setHoveredBook(true)}
                          onMouseLeave={() => setHoveredBook(false)}
                          onMouseDown={() => setClickedBook(true)}
                          onMouseUp={() => setClickedBook(false)}
                        >
                          Book
                        </Button>
                      )}

                    {venueData.venue_info &&
                    venueData.venue_info.user_id === loggedInUserId ? (
                      <CalendarSelfView />
                    ) : (
                      <CalendarPublicView profileId={profileId} />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NewVenueProfile;
