import React from "react";
import Cookies from "js-cookie";
import config from "../config/config";
import "../pages/OtherPages/ProfileComponent.css";

const InviteFriendButton = () => {
  const generateInviteUrl = () => {
    const profileOwnerId = Cookies.get("profileOwnerId");
    const inviteUrl = `${config.baseUrl}?ref=${profileOwnerId}`;
    return inviteUrl;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Invite URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy the URL: ", err);
      });
  };

  const handleInviteClick = () => {
    const inviteUrl = generateInviteUrl();
    copyToClipboard(inviteUrl);
  };

  return (
    <div>
      <button
        className="verify-btn"
        onClick={handleInviteClick}
        style={{ marginLeft: "7%" }}
      >
        Invite a Friend
      </button>
    </div>
  );
};

export default InviteFriendButton;
