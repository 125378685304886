import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import bg3 from "../../assect/images/bg/03.jpg";
// import logo from "../../assect/images/logo-icon-white.png";
import { Button } from "react-bootstrap";

export default function RetryPayment() {
  return (
    <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        // style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <div className="d-flex flex-column min-vh-100 justify-content-center px-md-5 py-5 px-4">
              <div className="text-center">
                <Link to="/">{/* <img src={logo} alt="" /> */}</Link>
              </div>
              <div className="title-heading text-center my-auto">
                <h1 className="title-dark text-white mt-2 mb-4 coming-soon">
                  Payment Failed!
                </h1>
                <p className="text-white-50 para-desc para-dark mx-auto">
                  Please make the payment to proceed.
                </p>
                <br></br>
                <Button>Retry Payment</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
