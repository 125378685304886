import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import axiosInstance from "../pages/auth/axiosInstance";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";

const FormWrapper = styled.form`  
  display: "flex";
  flexDirection: "column"; 
  
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;
  text-align: center;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 20px;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    cursor: not-allowed;
  }
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 8px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: black;
`;
// style={{ marginBottom: "8px", textAlign: "left" }}  

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  textAlign: "left",
  
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 150px; /* Set a minimum height to prevent it from collapsing */
  
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;  
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide vertical scrollbar */
  
  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CreateSupportTicket = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [addsupportloading, setAddSupportLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [ticketTitle, setTicketTitle] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);

      try {
        const response = await axiosInstance.get("/v1/profile/by/user-id", {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        if (response.status === 200) {
          const { performer_profiles, venue_profiles } = response.data;
          const allProfiles = [...performer_profiles, ...venue_profiles];
          setProfiles(allProfiles);

          console.log("Profiles fetched:", allProfiles); // Debugging log
        } else {
          setError("An error occurred while retrieving profiles. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
        setError("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  // Reset error when a valid profile is selected
  useEffect(() => {
    if (selectedProfile) {
      setError("");  // Clear the error when profile is selected
    }
  }, [selectedProfile]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddSupportLoading(true);

    if (!selectedProfile) {
      setError("Please select a profile.");
      setAddSupportLoading(false);
      return;
    }

    const profileId = selectedProfile._id;

    if (!profileId) {
      setError("Selected profile does not have a valid ID.");
      setAddSupportLoading(false); // Ensure loading stops if there's an error
      return;
    }

    // Debugging logs
    console.log("Selected Profile:", selectedProfile);
    console.log("Profile ID:", profileId);
    console.log("Cookie (profileId):", Cookies.get("profile_id"));

    const payload = {
      author_profile_id: profileId,
      title: ticketTitle,
      message: [message],
    };

    // Debugging logs
    console.log("Payload:", payload);

    try {
      
      const response = await axiosInstance.post(
        "/v1/support/create-ticket",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      setAddSupportLoading(false);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your support ticket has been submitted successfully.",
        });
        console.log("Ticket added:", response.data);
        // setSuccessMessage("Your support ticket successfully submitted");
        setSelectedProfile(null);
        setTicketTitle("");
        setMessage("");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error submitting ticket: " + response.statusText,
        });
        // setError("Error submitting ticket: " + response.statusText);
      }
    } catch (error) {
      console.error("Error adding ticket:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
      // setError("An unexpected error occurred. Please try again.");
    }
    setAddSupportLoading(false);
  };

  const handleProfileChange = (e) => {
    const profile = JSON.parse(e.target.value);
    console.log("Selected Profile on Change:", profile); // Debugging log
    setSelectedProfile(profile);
  };

  const handleChange = (event, setStateFunction) => {
    const { value, scrollHeight } = event.target;
    setStateFunction(value);
    event.target.style.height = "auto"; // Reset the height
    event.target.style.height = scrollHeight + "px"; // Set the dynamic height
  };


  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "40px",
        boxShadow: "0 2px 9px rgba(0,0,0,0.8)",
        backgroundColor: "#fff",
        zIndex: "1000",
        width: "400px",
      }}
    >
      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
      
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Create Support Ticket
        </h2>

        <InputGroup>
          <Label htmlFor="ticketType">
            Profile:
          </Label>
          <InputWrapper>
            <select
              id="ticketType"
              value={selectedProfile ? JSON.stringify(selectedProfile) : ""}
              onChange={handleProfileChange}
              className="form-control"
              style={{
                padding: "10px 10px 10px 10px",
                marginBottom: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                fontSize: "16px",
                textAlign: "left",
                width: "100%",
              }}

            >

              <option value="" >Select profile</option>
              {profiles.map((profile, index) => (
                <option key={index} value={JSON.stringify(profile)}>
                  {profile.profile_name}
                </option>
              ))}

            </select>

          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="ticketTitle">
            Title:
          </Label>
          <InputWrapper>
            <Input
              type="text"
              id="ticketTitle"
              className="form-control"
              placeholder="Ticket Title"
              value={ticketTitle}
              onChange={(e) => setTicketTitle(e.target.value)}
            />
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="message">
            Message:
          </Label>
          <InputWrapper>
            <Textarea
              id="message"
              value={message}
              onChange={(e) => handleChange(e, setMessage)}
              // onChange={(e) => setMessage(e.target.value)}
              className="form-control"
              placeholder="Add your message..."
            />
          </InputWrapper>
        </InputGroup>

        {successMessage && (
          <div className="alert alert-success mt-3" role="alert">       
            {successMessage}
          </div>
        )}

        {error && (
          <div className="alert alert-danger mt-3" role="alert">      
            {error}
          </div>
        )}
        
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            disabled={addsupportloading}            
            style={{
              opacity: addsupportloading ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
              cursor: addsupportloading ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
              transition: "opacity 0.3s ease", // Smooth transition for opacity change
              minWidth: "100px",
            }}
            
          >
            {addsupportloading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit Ticket"
            )}
            
          </Button>
        </div>

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            cursor: "pointer",
            color: "grey",
            fontSize: "24px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          &#x2715; {/* Close icon (X) */}
        </div>

      </FormWrapper>           
      
    </div>
  );
};

export default CreateSupportTicket;
