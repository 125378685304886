// VerificationModal.jsx
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {  
  Spinner,
} from "react-bootstrap";

function VerificationModal({
  show,
  onClose,
  onVerify,
  onResend,
  verificationType,
}) {
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyloading, setVerifyloading] = useState(false);

  const handleSubmit = () => {
    setVerifyloading(true);
    onVerify(verificationCode);
    setVerifyloading(false);

  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {verificationType === "email"
            ? "Verify Email"
            : "Verify Phone Number"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Enter Verification Code:</label>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="form-control"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}
        disabled={verifyloading}
        style={{
          cursor: verifyloading ? "not-allowed" : "pointer", // Disable cursor when loading
          opacity: verifyloading ? 0.6 : 1, // Reduce opacity when loading
          transition: "opacity 0.3s ease", // Smooth transition for opacity change
          minWidth: "100px",
        }}>
          {verifyloading ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              style={{
                width: "1.2rem",
                height: "1.2rem",
                borderWidth: "0.1rem",
              }}
            />
          ) : (
            "Verify"
          )}
          
        </Button>
        <Button variant="link" onClick={onResend}>
          Resend Code
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerificationModal;
