import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import logo from "../../assect/images/landing/logo.jpg";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 150px;
  padding: 20px;
  margin-top: 50px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: 60px;
  }
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-direction: row;

  svg {
    width: 30px; /* Adjust the icon size */
    height: auto;
    margin-bottom: 18px;
    color: #1a1a1a;
    margin-right: 10px; /* Space between icon and title */
  }
`;

const Title = styled.h2`
  font-size: 24px; /* Adjust title font size */
  color: #1a1a1a;
  //   margin-left: 10px;
  //   margin-top: '5px';
  margin-bottom: 10px;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const InputWrapper = styled.div`
  position: relative;
`;

// const Input = styled.textarea`
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   height: auto; /* Allows the textarea to expand vertically */
//   min-height: 100px; /* Set a minimum height to prevent it from collapsing */
// `;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px; /* Set a minimum height to prevent it from collapsing */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  resize: none; /* Disable manual resizing */

  overflow-y: hidden; /* Hide vertical scrollbar */

  /* Firefox specific */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

function UpdatePerformerPerformanceHistory() {
  const profileId = Cookies.get("profileOwnerId");
  const [pastVenues, setPastVenues] = useState("");
  const [eventsAndFestivals, setEventsAndFestivals] = useState("");
  const [collaborations, setCollaborations] = useState("");
  const [residencies, setResidencies] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formattedPastVenues = safeSplit(pastVenues);
      const formattedEventsAndFestivals = safeSplit(eventsAndFestivals);
      const formattedCollaborations = safeSplit(collaborations);
      const formattedResidencies = safeSplit(residencies);
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/performance-history",
        {
          performer_id: profileId,
          past_venues: formattedPastVenues,
          events_and_festivals: formattedEventsAndFestivals,
          collaborations: formattedCollaborations,
          residencies: formattedResidencies,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);
      console.log(
        "payload: ",
        profileId,
        " ",
        pastVenues,
        " ",
        eventsAndFestivals,
        " ",
        collaborations,
        " ",
        residencies
      );

      if (response.status === 200) {
        localStorage.removeItem("performerData");
        setSuccessMessage(
          "Performer performance history updated successfully."
        );
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Performer performance history updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating performer performance history.");
        console.error(
          "Error updating performer performance history:",
          response.data
        );
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer performance history:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (event, setStateFunction) => {
  //   const { value, scrollHeight } = event.target;
  //   setStateFunction(value);
  //   event.target.style.height = "auto";
  //   event.target.style.height = scrollHeight + "px";
  // };

  // const handleChange = (event, setStateFunction) => {
  //   const { value, scrollHeight } = event.target;
  //   const arrayValue = value.split(",").map((item) => item.trim());
  //   setStateFunction(arrayValue);
  //   event.target.style.height = "auto";
  //   event.target.style.height = scrollHeight + "px";
  // };

  // const handleChange = (event, setStateFunction) => {
  //   const { value, scrollHeight } = event.target;
  //   setStateFunction(value); // No splitting or trimming, allowing spaces.
  //   event.target.style.height = "auto";
  //   event.target.style.height = scrollHeight + "px";
  // };

  // const handleChange = (event, setStateFunction) => {
  //   const { value, scrollHeight } = event.target;
  //   setStateFunction(value); // Store the input as a string, including spaces and commas
  //   event.target.style.height = "auto";
  //   event.target.style.height = scrollHeight + "px";
  // };

  const handleChange = (event, setStateFunction) => {
    const { value, scrollHeight } = event.target;
    setStateFunction(value); // Store input as a string with spaces allowed
    event.target.style.height = "auto";
    event.target.style.height = scrollHeight + "px";
  };

  // Helper function to safely split input strings
  const safeSplit = (input) => {
    return typeof input === "string" && input.length > 0
      ? input.split(",").map((item) => item.trim())
      : []; // Return an empty array if the input is not a valid string or is empty
  };

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/performer/${profileId}`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        {/* <FontAwesomeIcon icon={faPhone} style={{ marginTop: '5px' }} /> */}
        <Title>Update Performer Performance History</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={(e) => handleSubmit(e)}>
        <InputGroup>
          <Label htmlFor="pastVenues">Past Venues</Label>
          <InputWrapper>
            <Textarea
              id="pastVenues"
              className="form-control"
              name="past_venues"
              value={pastVenues}
              onChange={(e) => handleChange(e, setPastVenues)}
              placeholder="Update past venues..."
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            
                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="eventsAndFestivals">Events and Festivals</Label>
          <InputWrapper>
            <Textarea
              id="eventsAndFestivals"
              name="events_and_festivals"
              className="form-control"
              value={eventsAndFestivals}
              onChange={(e) => handleChange(e, setEventsAndFestivals)}
              placeholder="Update events and festivals..."
            />

            {/* <Input
                            type="text"
                            className="form-control"
                            id="technicalRequirements"

                            value={technicalRequirements}
                            onChange={(e) => setTechnicalRequirements(e.target.value)}

                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="collaborations">Collaborations</Label>
          <InputWrapper>
            <Textarea
              id="collaborations"
              name="collaborations"
              className="form-control"
              value={collaborations}
              onChange={(e) => handleChange(e, setCollaborations)}
              placeholder="Update collaborations..."
            />
            {/* <Input
                            type="text"
                            className="form-control"
                            id="instruments"

                            value={instruments}
                            onChange={(e) => setInstruments(e.target.value)}
                            
                        /> */}
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="residencies">Residencies</Label>
          <InputWrapper>
            <Textarea
              type="text"
              name="residencies"
              className="form-control"
              id="residencies"
              value={residencies}
              onChange={(e) => handleChange(e, setResidencies)}
              placeholder="Update residencies..."
            />

            {/* <Input
                            type="text"
                            className="form-control"
                            id="technicalRequirements"

                            value={technicalRequirements}
                            onChange={(e) => setTechnicalRequirements(e.target.value)}

                        /> */}
          </InputWrapper>
        </InputGroup>
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}
export default UpdatePerformerPerformanceHistory;
