import React, { useEffect, useRef, useState } from "react";
import "./msg.scss";
import axiosInstance from "../auth/axiosInstance";
import "./messaging.css";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { FaEllipsisV, FaPaperPlane, FaTrash } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { io } from "socket.io-client";
import Tooltip from "./Tooltip";
import { v4 as uuidv4 } from "uuid";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const baseBackendURL = process.env.REACT_APP_BASE_URL;

const MySwal = withReactContent(Swal);

function App(props) {
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [recipientNames, setRecipientNames] = useState({});
  // const { conversation_id } = useParams();
  const params = useParams();
  const conversation_id = props.conversation_id || params.conversation_id;

  const profile_id = Cookies.get("profileOwnerId");
  const [to_profile_id, setToProfileId] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    message_id: null,
  });

  const [socket, setSocket] = useState(null);
  const [selectedConversationTitle, setSelectedConversationTitle] = useState();
  const [selectedConversationImage, setSelectedConversationImage] = useState();
  const [selfImage, setSelfImage] = useState();
  const [highlightedConversation, setHighlightedConversation] = useState([]);

  const { title } = useParams();

  const messagesEndRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [loadingNextUrl, setLoadingNextUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [convloading, setconvLoading] = useState(false);
  const [mgloading, setmgLoading] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState(null);

  const Button = styled.button`
    width: 30%;
    padding: 8px;
    background-color: #2b4eff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    margin: 10px auto;
    margin-left: 140px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `;

  useEffect(() => {
    const fetchData = async () => {
      const profileType = profile_id.split("-")[1];
      let apiUrl = "";
      if (profileType === "PERFORMER") {
        apiUrl = `/v1/performer/view-profile/${profile_id}/section/complete`;
      } else if (profileType === "VENUE") {
        apiUrl = `/v1/venue/view-profile/${profile_id}/section/complete`;
      }

      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        });

        let profileImage = "";
        if (profileType === "PERFORMER") {
          profileImage =
            response.data.performer_info.thumbnail_profile_pic_url ||
            response.data.performer_info.profile_picture_url;
        } else if (profileType === "VENUE") {
          profileImage =
            response.data.venue_info.thumbnail_profile_pic_url ||
            response.data.venue_info.profile_picture_url;
        }

        if (profileType === "PERFORMER") {
          setSelfImage(profileImage);
          // navigate(`/performer-pricing`);
        } else if (profileType === "VENUE") {
          setSelfImage(profileImage);
          // navigate(`/venue-pricing`);
        }
      } catch (error) {
        console.error("Error fetching author info:", error);
        return null;
      }
    };
    fetchData();
  }, [selfImage]);

  const handleTooltipToggle = (e) => {
    e.stopPropagation();
    setTooltipVisible(!tooltipVisible);
  };

  // const socket = io("http://gig-bridge-v1.eu-north-1.elasticbeanstalk.com", {
  //     auth: { token: "123" },
  //   });

  // useEffect(() => {
  //   if (!conversation_id) return;

  //   const fetchConversationDetails = async () => {
  //     try {
  //       // Fetch conversation details
  //       const response = await axiosInstance.get(
  //         `/v1/messaging/get_conversation_by_id/${profile_id}/${conversation_id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       const conversation = response.data.message;

  //       setToProfileId(
  //         profile_id === conversation.to ? conversation.from_ : conversation.to
  //       );
  //       setSelectedConversation(conversation_id);
  //       setSelectedConversationTitle(conversation.title);
  //       setSelectedConversationImage(
  //         profile_id === conversation.to
  //           ? conversation.from_profile_pic_url
  //           : conversation.to_profile_pic_url
  //       );

  //       // Join the socket room
  //       if (socket) {
  //         socket.emit("join", {
  //           username: profile_id,
  //           room: conversation_id,
  //         });
  //       }

  //       // Fetch all messages for the conversation
  //       const messagesResponse = await axiosInstance.get(
  //         `/v1/messaging/get_all_messages/${profile_id}/${conversation_id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );

  //       const messages = messagesResponse.data.message;
  //       if (messages.length > 0) {
  //         const lastMessageAuthor = messages[messages.length - 1].author;
  //         if (lastMessageAuthor !== profile_id) {
  //           setToProfileId(lastMessageAuthor);
  //         }
  //       }
  //       setMessages(messages);
  //     } catch (error) {
  //       console.error("Error getting conversation or messages:", error);
  //       setMessages([]); // Clear messages on error
  //     }
  //   };

  //   fetchConversationDetails();
  // }, [conversation_id, profile_id, socket]);

  useEffect(() => {
    if (!conversation_id) return;

    // Update active conversation ID when a conversation is clicked
    setmgLoading(true);
    setActiveConversationId(conversation_id);

    const fetchConversationDetails = async () => {
      try {
        // Fetch conversation details
        const response = await axiosInstance.get(
          `/v1/messaging/get_conversation_by_id/${profile_id}/${conversation_id}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        const conversation = response.data.message;

        // Update states if the conversation is still active
        if (conversation_id === activeConversationId) {
          setToProfileId(
            profile_id === conversation.to
              ? conversation.from_
              : conversation.to
          );
          setSelectedConversation(conversation_id);
          setSelectedConversationTitle(conversation.title);
          setSelectedConversationImage(
            profile_id === conversation.to
              ? conversation.from_profile_pic_url
              : conversation.to_profile_pic_url
          );

          // Join the socket room
          if (socket) {
            socket.emit("join", {
              username: profile_id,
              room: conversation_id,
            });
          }

          // Fetch all messages for the conversation
          const messagesResponse = await axiosInstance.get(
            `/v1/messaging/get_all_messages/${profile_id}/${conversation_id}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );

          const messages = messagesResponse.data.message;
          if (conversation_id === activeConversationId) {
            setMessages(messages);

            if (messages.length > 0) {
              const lastMessageAuthor = messages[messages.length - 1].author;
              if (lastMessageAuthor !== profile_id) {
                setToProfileId(lastMessageAuthor);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error getting conversation or messages:", error);
        if (conversation_id === activeConversationId) {
          setMessages([]); // Clear messages on error
        }
      } finally {
        if (conversation_id === activeConversationId) {
          setmgLoading(false);
        }
      }
    };

    fetchConversationDetails();
  }, [conversation_id, profile_id, socket, activeConversationId]);

  const fetchConversations = (url) => {
    setconvLoading(true);
    axiosInstance
      .get(url)
      .then((response) => {
        // setContacts((prevContacts) => [
        //   ...prevContacts,
        //   ...response.data.message.results,
        // ]);

        // const results = response.data?.message?.results;
        // if (Array.isArray(results)) {
        //   setContacts((prevContacts) => [...prevContacts, ...results]);
        // }
        // setLoadingNextUrl(response.data.message.next);
        // fetchRecipientNames(response.data.message.results);

        const messageData = response.data?.message;
        console.log("message data: ", messageData);
        if (messageData && Array.isArray(messageData.results)) {
          setContacts((prevContacts) => [
            ...prevContacts,
            ...messageData.results,
          ]);
          setLoadingNextUrl(messageData.next);
          fetchRecipientNames(messageData.results);
        } else {
          console.error("Invalid message data:", messageData);
        }

        setconvLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching conversations:", error);
        setconvLoading(false);
      })
      .finally(() => {
        setconvLoading(false);
        // setLoading(false);
      });
  };

  useEffect(() => {
    const url = `/v1/messaging/get_pagination_all_conversation_for_profile/${profile_id}`;
    fetchConversations(url);
  }, [profile_id]);

  // useEffect((url = `/v1/messaging/get_pagination_all_conversation_for_profile/${profile_id}`) => {
  //   axiosInstance
  //     .get(url)
  //     .then((response) => {
  //       setContacts(response.data.message.results);
  //       setLoadingNextUrl(response.data.message.next);
  //       fetchRecipientNames(response.data.message.results);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching conversations:", error);
  //     });
  // }, [profile_id]);

  const handleLoadingNext = (e) => {
    e.preventDefault();

    if (!loadingNextUrl) {
      console.log("No more messages to load.");
      return; // Early return if no more URL
    }

    setLoading(true); // Set loading to true

    const nextUrlWithBasePath = loadingNextUrl.startsWith("/v1/messaging")
      ? loadingNextUrl
      : `/v1/messaging${loadingNextUrl}`; // Ensure URL starts correctly

    console.log("Fetching next URL for messaging: ", nextUrlWithBasePath);

    fetchConversations(nextUrlWithBasePath);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (Array.isArray(contacts)) {
      contacts.forEach((conversation) => {
        if (
          conversation.to === profile_id &&
          conversation.has_opened_by_to === false
        ) {
          console.log(conversation.to);
          console.log(profile_id);
          const conversationElement = document.getElementById(conversation._id);

          if (conversationElement) {
            conversationElement.classList.add("msgcolor2");
          }
        }
        if (
          conversation.from_ === profile_id &&
          conversation.has_opened_by_from === false
        ) {
          console.log(conversation.from_);
          console.log(profile_id);
          const conversationElement = document.getElementById(conversation._id);

          if (conversationElement) {
            conversationElement.classList.add("msgcolor2");
          }
        }
      });
    }
  }, [contacts]);

  useEffect(() => {
    const newSocket = io(
      baseBackendURL,
      // "https://www.ramoi.com",
      // "http://127.0.0.1:4000",
      // "https://api.ogotrix.au",
      {
        auth: { token: "123" },
      }
    ); // Replace with your server address
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to socket server");
    });

    // socket.on("join", (data) => {
    //   console.log("Joined room:", data);
    // });

    return () => newSocket.disconnect(); // Disconnect on component unmount
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("join", (data) => {
        console.log("Joined room:", data);
      });

      socket.on("message", (data) => {
        if (data.username !== profile_id) {
          console.log("data:", data);
          const newMessage = {
            author: data.username,
            conversation_id: data.room,
            created_on: null,
            deleted_by: "",
            media: [""],
            message: [
              {
                content: data.message,
                type: "text",
              },
            ],
            message_id: data.messageId,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setHighlightedConversation((prevData) => {
            // Ensure prevData is an array before spreading or manipulating
            if (Array.isArray(prevData)) {
              return [...prevData, data];
            } else {
              console.error("Previous data is not an array:", prevData);
              // return prevData;
              return [data];
            }
          });
          console.log("Message received:", data);
        }
      });

      socket.on("updateconversationcolor", (data) => {
        setHighlightedConversation((prevData) => {
          // Ensure prevData is an array before spreading or manipulating
          if (Array.isArray(prevData)) {
            return [...prevData, data];
          } else {
            console.error("Previous data is not an array:", prevData);
            return prevData; // or handle the error state as appropriate
          }
        });
        console.log("Update color:", data);
      });
    }
  }, [socket]);

  // useEffect(() => {
  //   if (conversation_id) {
  //     axiosInstance
  //       .get(
  //         `/v1/messaging/get_conversation_by_id/${profile_id}/${conversation_id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const conversation = response.data.message;
  //         setToProfileId(
  //           profile_id === conversation.to
  //             ? conversation.from_
  //             : conversation.to
  //         );
  //         setSelectedConversation(conversation_id);
  //         setSelectedConversationTitle(conversation.title);
  //         setSelectedConversationImage(
  //           profile_id === conversation.to
  //             ? conversation.from_profile_pic_url
  //             : conversation.to_profile_pic_url
  //         );

  //         socket.emit("join", {
  //           username: profile_id,
  //           room: conversation_id,
  //         });

  //         axiosInstance
  //           .get(
  //             `/v1/messaging/get_all_messages/${profile_id}/${conversation_id}`,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //               },
  //             }
  //           )
  //           .then((response) => {
  //             const lastMessageAuthor =
  //               response.data.message[response.data.message.length - 1].author;
  //             if (lastMessageAuthor !== profile_id) {
  //               setToProfileId(lastMessageAuthor);
  //             }

  //             setMessages(response.data.message);
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching messages:", error);
  //             setMessages([]);
  //           });
  //       })
  //       .catch((error) => {
  //         console.error("Error getting conversation:", error);
  //       });
  //   }
  // }, [conversation_id, profile_id, socket]);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("Connected to Socket.IO server");
  //   });

  //   // socket.emit("join", {
  //   //   username: "PROFILE-PERFORMER-3cd3c143-bc32-404b-94bf-17954faa1204",
  //   //   room: "CONVERSATION-e2977136-2049-40ca-80dc-c351bf46090a",
  //   // });

  //   // socket.emit("message", {
  //   //   username: "PROFILE-PERFORMER-3cd3c143-bc32-404b-94bf-17954faa1204",
  //   //   message: "Hard coded message.",
  //   //   room: "CONVERSATION-e2977136-2049-40ca-80dc-c351bf46090a",
  //   // });

  //   socket.on("message", (data) => {
  //     setMessages((prevMessages) => [...prevMessages, data]);
  //     console.log("Message received:", data);
  //   });

  // socket.on("join", (data) => {
  //   console.log("Joined room:", data);
  // });

  //   socket.on("leave", (data) => {
  //     console.log("Left room:", data);
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from Socket.IO server");
  //   });

  //   socket.on('connect_error', (error) => {
  //     console.log('connection error', error);
  //   });

  //   socket.on('error', (error) => {
  //     console.log('socket error', error);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const fetchRecipientNames = async (contacts) => {
    const names = {};
    for (const contact of contacts) {
      const name = await fetchProfileName(contact.to);
      names[contact._id] = name;
    }
    setRecipientNames(names);
  };

  const fetchProfileName = async (to_profile_id) => {
    const accessToken = Cookies.get("accessToken");
    const prefix = to_profile_id.startsWith("PROFILE-PERFORMER")
      ? "performer"
      : "venue";
    const section = "complete";
    const url = `/v1/${prefix}/view-profile/${to_profile_id}/section/${section}`;

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        return (
          response.data.performer_info?.profile_name ||
          response.data.venue_info?.profile_name ||
          "Unknown"
        );
      } else {
        console.error(`Failed to fetch ${prefix} profile`);
        return "Unknown";
      }
    } catch (error) {
      console.error(`Error fetching ${prefix} profile:`, error);
      return "Unknown";
    }
  };

  const handleConversationClick = (conversation_id) => {
    setmgLoading(true);
    setActiveConversationId(conversation_id);
    setHighlightedConversation((prev) =>
      prev.filter((item) => item.conversation !== conversation_id)
    );
    const conversationElement = document.getElementById(conversation_id); // Adjust this based on your HTML structure

    // Apply the 'unread-message' class to highlight
    conversationElement.classList.remove("msgfriend-drawer--unread");

    axiosInstance
      .get(
        `/v1/messaging/get_conversation_by_id/${profile_id}/${conversation_id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      )
      .then((response) => {
        const conversation = response.data.message;
        setToProfileId(
          profile_id === conversation.to ? conversation.from_ : conversation.to
        );
        setSelectedConversation(conversation_id);
        setSelectedConversationTitle(conversation.title);
        console.log(conversation);

        console.log(profile_id);

        if (profile_id === conversation.to) {
          console.log(true);
          setSelectedConversationImage(conversation.from_profile_pic_url);
        } else {
          setSelectedConversationImage(conversation.to_profile_pic_url);
        }

        // Join the room for the selected conversation
        console.log("Conversatoin: ", conversation);
        // const socket = io("http://gigbridge1.eu-north-1.elasticbeanstalk.com");
        socket.emit("join", {
          username: profile_id,
          room: conversation_id,
        });
        console.log("Joined", conversation_id);

        axiosInstance
          .get(
            `/v1/messaging/get_all_messages/${profile_id}/${conversation_id}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          )
          .then((response) => {
            const lastMessageAuthor =
              response.data.message[response.data.message.length - 1].author;
            if (lastMessageAuthor !== profile_id) {
              setToProfileId(lastMessageAuthor);
            }

            setMessages(response.data.message);
            setmgLoading(false);
            console.log(response.data.message);
          })
          .catch((error) => {
            console.error("Error fetching messages:", error);
            setMessages([]);
            setmgLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error getting conversation:", error);
        setmgLoading(false);
      });
    setConversationsVisible(false);
    setActiveConversation(conversation_id);
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message.length || !selectedConversation || !to_profile_id) {
      return;
    }

    const messageId = uuidv4();

    console.log("Generated message ID:", messageId);

    const newMessage = {
      author: profile_id,
      conversation_id: conversation_id,
      created_on: new Date(), // Ensure the created date/time is set
      deleted_by: "",
      media: [""],
      message: [
        {
          content: message,
          type: "text",
        },
      ],
      _id: messageId,
    };

    console.log("Message payload:", newMessage);

    // Add the new message to the conversation's message list
    setMessages([...messages, newMessage]);

    const printing = messages;
    console.log("printing messages, ", printing);

    // Move the conversation with the latest message to the top
    setContacts((prevContacts) => {
      // Find the index of the current conversation
      const updatedContacts = [...prevContacts];
      const index = updatedContacts.findIndex(
        (contact) => contact._id === selectedConversation
      );

      if (index > -1) {
        // Update the last message and move the conversation to the top
        const updatedContact = {
          ...updatedContacts[index],
          last_message: message,
          last_message_time: new Date(), // Update the time as well
        };
        updatedContacts.splice(index, 1); // Remove the old entry
        updatedContacts.unshift(updatedContact); // Add it to the top
      }

      return updatedContacts;
    });

    // Clear the input field
    setMessage("");

    console.log("Sending message to API:", {
      media: "",
      message: message,
      type: "text",
      to_profile_id: to_profile_id,
      message_id: messageId,
    });

    axiosInstance
      .post(
        `v1/messaging/send_message/${profile_id}/${to_profile_id}/${selectedConversation}`,
        {
          media: "",
          message: message,
          type: "text",
          to_profile_id: to_profile_id,
          message_id: messageId,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      )
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });

    // Emit the message to the room
    if (socket) {
      socket.emit("message", {
        username: profile_id,
        message: message,
        room: selectedConversation,
        message_id: messageId,
      });

      socket.emit("updateUnreadCount", {
        profileId: to_profile_id,
      });

      socket.emit("updateconversationcolor", {
        username: profile_id,
        message: message,
        conversation: selectedConversation,
      });
    }
  };

  const handleDeleteConversation = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "All your messages in this conversation will be deleted. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(selectedConversation);
        console.log(profile_id);
        axiosInstance
          .get(
            `/v1/messaging/delete_conversation/${profile_id}/${selectedConversation}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          )
          .then((response) => {
            setMessages([]);
            MySwal.fire(
              "Deleted!",
              "Your messages have been deleted.",
              "success"
            );
          })
          .catch((error) => {
            console.log(error);
            console.error("Error deleting conversation:", error);
            console.error(
              "Error details:",
              error.response ? error.response.data : error.message
            );
            MySwal.fire(
              "Error!",
              "There was an error deleting the messages.",
              "error"
            );
          });
      }
    });
  };

  const handleRightClick = (e, message_id) => {
    e.preventDefault();
    console.log("message id: ", message_id);
    const rect = e.target.getBoundingClientRect();
    const chatPanelRect = document
      .querySelector(".chat-panel")
      .getBoundingClientRect();

    setContextMenu({
      visible: true,
      x: rect.left - chatPanelRect.left + rect.width / 2,
      y: rect.top - chatPanelRect.top + rect.height / 2,
      message_id: message_id,
    });
  };

  // const handleRightClick = (e, message_id) => {
  //   e.preventDefault();
  //   const rect = e.target.getBoundingClientRect();
  //   const chatPanelRect = document
  //     .querySelector(".chat-panel")
  //     .getBoundingClientRect();

  //   const x = rect.left + window.scrollX;
  //   const y = rect.top + window.scrollY;

  //   setContextMenu({
  //     visible: true,
  //     x: x,
  //     y: y,
  //     message_id: message_id,
  //   });
  // };

  // const handleRightClick = (e, message_id) => {
  //   e.preventDefault();
  //   setContextMenu({
  //     visible: true,
  //     x: e.clientX,
  //     y: e.clientY,
  //     message_id: message_id,
  //   });
  // };

  const handleDeleteMessage = (message_id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "This message will be deleted. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .get(
            `/v1/messaging/delete_message/${profile_id}/${selectedConversation}/${message_id}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          )
          .then((response) => {
            setMessages(messages.filter((msg) => msg._id !== message_id));
            MySwal.fire("Deleted!", "The message has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting message:", error);
            console.log("message id: ", message_id);
            console.error(
              "Error details:",
              error.response ? error.response.data : error.message
            );
            MySwal.fire(
              "Error!",
              "There was an error deleting the message.",
              "error"
            );
          });
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.visible) {
        setContextMenu({
          ...contextMenu,
          visible: false,
        });
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  // const handleClickOutside = () => {
  //   setContextMenu({ visible: false, x: 0, y: 0, message_id: null });
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const friends = [
  //   { name: "Robo Cop", message: "Hey, you're arrested!", time: "13:21", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/robocop.jpg" },
  //   { name: "Optimus", message: "Wanna grab a beer?", time: "00:32", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/optimus-prime.jpeg" },
  //   { name: "Skynet", message: "Seen that canned piece of s?", time: "13:21", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/real-terminator.png" },
  //   { name: "Termy", message: "Im studying spanish...", time: "13:21", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/termy.jpg" },
  //   { name: "Richard", message: "I'm not sure...", time: "13:21", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/rick.jpg" },
  //   { name: "XXXXX", message: "Hi, wanna see something?", time: "13:21", img: "https://www.clarity-enhanced.net/wp-content/uploads/2020/06/rachel.jpeg" }
  // ];

  // const handleClick = () => {
  //   const bubbles = document.querySelectorAll('.msgchat-bubble');
  //   bubbles.forEach(bubble => {
  //     bubble.style.display = 'none';
  //     setTimeout(() => {
  //       bubble.style.display = 'block';
  //     }, 500);
  //   });
  // };

  const [isConversationsVisible, setConversationsVisible] = useState(false);

  const toggleConversations = () => {
    setConversationsVisible(!isConversationsVisible);
  };

  const [activeConversation, setActiveConversation] = useState(null);

  return (
    <div className="msgcontainer">
      {/* <div className="left-column"> */}
      <div className={`left-column ${isConversationsVisible ? "visible" : ""}`}>
        <div className="msgsettings-tray">
          <img
            className="msgprofile-image"
            src={selfImage}
            alt="Profile image"
          />
        </div>
        <div className="conversations">
          {contacts.map((contact, index) => (
            <div key={index}>
              {(contact.to_last_message || contact.from_last_message) && (
                <div
                  id={contact._id}
                  className={`msgfriend-drawer msgfriend-drawer--onhover ${
                    highlightedConversation &&
                    highlightedConversation.some(
                      (conv) =>
                        conv.conversation === contact._id &&
                        profile_id !== conv.username
                    )
                      ? "msgcolor"
                      : ""
                  } ${activeConversation === contact._id ? "active" : ""}`}
                  onClick={() => handleConversationClick(contact._id)}
                >
                  <img
                    className="msgprofile-image"
                    alt=""
                    src={
                      profile_id === contact.to
                        ? contact.from_profile_pic_url
                        : contact.to_profile_pic_url
                    }
                  />
                  <div className="text">
                    <h6>
                      {profile_id === contact.to
                        ? contact.from_display_name
                        : contact.to_display_name}
                    </h6>
                    <p className="text-muted">
                      {(highlightedConversation[
                        highlightedConversation.findIndex(
                          (conv) => conv.conversation === contact._id
                        )
                      ] &&
                        highlightedConversation[
                          highlightedConversation.findIndex(
                            (conv) => conv.conversation === contact._id
                          )
                        ].message) ||
                        // (contact.last_message.length > 30
                        //   ? `${contact.last_message.slice(0, 30)}....`
                        //   : contact.last_message) ||
                        // "Loading..."}
                        (profile_id === contact.to
                          ? contact.to_last_message &&
                            contact.to_last_message.length > 30
                            ? `${contact.to_last_message.slice(0, 30)}....`
                            : contact.to_last_message || "No message"
                          : contact.from_last_message &&
                            contact.from_last_message.length > 30
                          ? `${contact.from_last_message.slice(0, 30)}....`
                          : contact.from_last_message || "No message") ||
                        "Loading..."}
                    </p>
                  </div>

                  <button
                    className="hover-button"
                    onClick={handleDeleteConversation}
                    style={{ zIndex: 1 }}
                  >
                    <FaTrash />
                  </button>
                  <span className="time text-muted small"></span>
                </div>
              )}
            </div>
          ))}
          {/* {contacts.length > 0 && (
            <div className="load-more" disabled={!loadingNextUrl} onClick={handleLoadingNext}>
              Load More
            </div>
          )} */}

          {contacts.length >= 8 && loadingNextUrl && (
            <Button
              onClick={handleLoadingNext}
              disabled={loading || !loadingNextUrl} // Disable button if loading or no next URL
            >
              {loading ? (
                <Spinner
                  animation="border"
                  role="status"
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    borderWidth: "0.1rem",
                  }}
                />
              ) : (
                "Load more"
              )}
            </Button>
          )}
        </div>
      </div>

      <div className="right-column">
        <div className="msgsettings-tray">
          <div className="msgfriend-drawer no-gutters msgfriend-drawer--grey">
            <button className="hamburger-menu" onClick={toggleConversations}>
              ☰
            </button>
            {mgloading ? (
              <div>Loading...</div>
            ) : (
              <>
                <img
                  className="msgprofile-image"
                  src={selectedConversationImage}
                  alt=""
                />
                <div className="text">
                  <h6>{selectedConversationTitle}</h6>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="scrollable-content chat-panel">
          {mgloading ? (
            <div>Loading...</div>
          ) : (
            messages.slice().map((item) => (
              <div className="row no-gutters" key={item._id}>
                {/* <div
                className={`col-md-7 ${
                  item.author === profile_id ? "offset-md-4" : ""
                }`}
              > */}
                <div
                  className={`message-wrapper ${
                    item.author === profile_id ? "align-right" : "align-left"
                  }`}
                >
                  <div
                    className={`msgchat-bubble ${
                      item.author === profile_id
                        ? "msgchat-bubble--right"
                        : "msgchat-bubble--left"
                    }`}
                    onContextMenu={(e) => handleRightClick(e, item._id)}
                  >
                    <div className="message-body">
                      {item.message[0].content}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}

          <div ref={messagesEndRef} />

          {contextMenu.visible && (
            <div
              className="context-menu"
              style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
            >
              <ul>
                <li onClick={() => handleDeleteMessage(contextMenu.message_id)}>
                  Delete for me
                </li>
              </ul>
            </div>
          )}
          {/* {contextMenu.visible && (
            <div
              className="context-menu"
              style={{ top: contextMenu.y, left: contextMenu.x }}
            >
              <ul>
                <li onClick={() => handleDeleteMessage(contextMenu.message_id)}>
                  Delete for me
                </li>
              </ul>
            </div>
          )} */}
        </div>
        <form
          className="messages-inputs"
          onSubmit={handleSubmit}
          style={{ paddingBottom: "2%" }}
        >
          <div className="fixed-footer col-10">
            {/* <div className="msgchat-box-tray"> */}
            <input
              type="text"
              placeholder="Send a message"
              onChange={handleChange}
              className="form-control"
              value={message}
            />
            <button type="submit" className="send-button">
              <FaPaperPlane />
            </button>
            {/* <i className="material-icons">send</i> */}
            {/* </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
