import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Modal, Badge, Row } from "react-bootstrap"; // Assuming Bootstrap integration
import { FaTrash, FaUpload } from "react-icons/fa";
import axiosInstance from "../auth/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import NewCalloutPerformerView from "../callouts/NewCalloutPerformerView";
import Swal from "sweetalert2";

function ViewCalloutsbySelfforBilling({ updateCalloutCount }) {
  const [deletecalloutId, setDeletecalloutId] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const profileId = Cookies.get("profileOwnerId");
  const [callouts, setcallouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewableCount, setViewableCount] = useState(2); // Initial number of callouts to display

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [error, setError] = useState(null);
  const loggedInUserId = Cookies.get("userId");
  const [venueData, setVenueData] = useState({});
  const { opportunityId } = useParams();
  const [callout, setcallout] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedcalloutId, setSelectedcalloutId] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileIdFromCookies = Cookies.get("profileId");

      if (!profileId) {
        setError("venue ID not found in URL parameters.");
        setLoading(false);
        return;
      }

      // Cookies.set("profileId", profileId);

      try {
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          setVenueData(data);
          setError(null);
        } else {
          setError(response.data.error || "Error fetching venue profile");
        }
      } catch (error) {
        console.error("Error fetching venue profile:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profileId]);

  // Fetch callouts based on profile ID on component mount
  useEffect(() => {
    const fetchcallouts = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/call_out/published/by/profile-owner/${profileId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        const data = response.data;
        // if (data.call_outs_published) {
        //   setcallouts(
        //     data.call_outs_published.filter(
        //       (callout) => callout.author_profile_id === profileId
        //     )
        //   );
        if (data.call_outs_published) {
          const filteredCallouts = data.call_outs_published.filter(
            (callout) => callout.author_profile_id === profileId
          );
          setcallouts(filteredCallouts);
          updateCalloutCount(filteredCallouts.length);
        } else {
          console.error("Error: callouts_published is undefined");
        }
      } catch (error) {
        console.error("Error fetching callouts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (profileId) {
      fetchcallouts();
    }
  }, [profileId, updateCalloutCount]);

  // useEffect(() => {
  //   const fetchcallouts = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axiosInstance.get(
  //         `http://127.0.0.1:4000/v1/call_out/published/by/profile-owner/${profileId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       if (response.ok) {
  //         const data = await response.json();
  //         setcallouts(
  //           data.callouts_published.filter(
  //             (callout) => callout.author_profile_id === profileId
  //           )
  //         );
  //       } else {
  //         console.error("Error fetching callouts:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching callouts:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (profileId) {
  //     fetchcallouts();
  //   }

  // }, [profileId]);

  const handlecalloutClick = (calloutId) => {
    Cookies.set("calloutId", calloutId);
    console.log("Clicked calloutId:", calloutId);
    navigate(`/callout/${calloutId}`);
  };

  // const handlecalloutClick = (calloutId) => {
  //   if (callout) {
  //     Cookies.set("calloutId", calloutId);
  //     navigate(`/callout/${calloutId}`);
  //   }
  // };

  // Check if logged-in user matches profile owner

  const handleViewMore = () => {
    setViewableCount(callouts.length);
  };

  const handleViewLess = () => {
    setViewableCount(2);
  };

  const navigatecallout = () => {
    navigate("/add-new-callout");
  };

  const handleDeleteClick = (calloutId) => {
    setDeleteTarget(calloutId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/call_out/delete-call-out/${deleteTarget}`
      );
      if (response.status === 200) {
        // Remove the deleted callout from the state
        setcallouts((prevcallouts) =>
          prevcallouts.filter((callout) => callout._id !== deleteTarget)
        );
      } else {
        console.error("Error deleting callout:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting callout:", error);
      let errorMessage = "There was an error deleting callout.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setShowDeleteModal(false);
    }
  };

  //   const handlecalloutClick = (calloutId) => {
  //     Cookies.set("calloutId", calloutId);
  //     console.log("Clicked calloutId:", calloutId);
  //     navigate(`/venue/callout/author/${calloutId}`);
  //   };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected callout id", id);
    setSelectedcalloutId(id);
    // Cookies.set("calloutId", id);
    // console.log("calloutId cookie value:", Cookies.get("calloutId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/call_out/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const callout = response.data;
      console.log("callout response", response.data);
      // const profileId = Cookies.get("profileOwnerId");
      // console.log("author profile id:", callout.callout.author_profile_id);
      console.log("callout id:", id);

      const profileType = getProfileType(Cookies.get("profileOwnerId"));
      if (profileType === "performer") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      } else if (profileType === "venue") {
        setModalContent(
          <NewCalloutPerformerView calloutId={id} profileId={authorProfileId} />
        );
      }
    } catch (error) {
      console.error("Error fetching callout details:", error);
      let errorMessage = "There was an error fetching callout details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  const closeModal = () => {
    setSelectedcalloutId(null);
    setShowModal(false);
  };

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    return `${hour}:${minute} ${am_pm}`;
  };

  return (
    <div style={{ padding: "4%" }}>
      <center>
        <strong>{/* <h4>Call Outs</h4> */}</strong>

        {/* {venueData.venue_info &&
          venueData.venue_info.user_id === loggedInUserId && ( */}
      </center>

      {loading ? (
        <div className="text-center mt-5">
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2">Loading callouts...</span>
        </div>
      ) : (
        <Row>
          {callouts.length > 0 ? (
            <>
              {callouts.slice(0, viewableCount).map((callout) => (
                <div
                  key={callout._id}
                  className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 mb-3"
                  // onClick={() => handlecalloutClick(callout._id)}
                  onClick={() =>
                    openModal(callout._id, callout.author_profile_id)
                  }
                >
                  <div className="events__item hover__active">
                    <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                      <div className="events__content">
                        <div className="events__meta">
                          <h5>
                            <strong>{callout.title}</strong>
                          </h5>
                        </div>

                        <div className="events__details-inline">
                          <p>{callout.short_description}</p>
                        </div>
                        <div className="events__details-inline">
                          <strong>Location:</strong> {callout.location.suburb}
                        </div>
                        <div className="events__details-inline">
                          <strong>From:</strong> {"  "}
                          {formatDate(callout.from_date)}
                          {"   "}
                          {formatTime(callout.from_time)}
                        </div>
                        <div className="events__details-inline">
                          <strong>To:</strong> {"  "}
                          {formatDate(callout.to_date)}
                          {"   "}
                          {formatTime(callout.to_time)}
                        </div>
                        {/* <div className="events__details-inline">
                          <strong>Date:</strong>
                          {typeof callout.from_date === "object"
                            ? new Date(
                                callout.from_date.year,
                                callout.from_date.month - 1,
                                callout.from_date.day
                              )
                                .toISOString()
                                .split("T")[0]
                            : callout.from_date}
                        </div>
                        <div className="events__details-inline">
                          <strong>Time:</strong>
                          {typeof callout.from_time === "object"
                            ? `${callout.from_time.hour}:${callout.from_time.minute} ${callout.from_time.am_pm}`
                            : callout.from_time}
                        </div> */}

                        {venueData.venue_info &&
                          venueData.venue_info.user_id === loggedInUserId && (
                            <Button
                              style={{
                                backgroundColor: "#151a33",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(callout._id);
                              }}
                            >
                              <BsTrash />
                            </Button>
                          )}
                      </div>
                      <div className="events__more">
                        <a
                          className="link-btn"
                          // onClick={() => handlecalloutClick(callout._id)}
                          onClick={() =>
                            openModal(callout._id, callout.author_profile_id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                          <i className="fas fa-arrow-right"></i>
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this callout?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={handleDeleteConfirm}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
              {viewableCount < callouts.length && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewMore}
                    style={{ color: "#418abb" }}
                  >
                    View More ({callouts.length - viewableCount} more)
                  </Button>
                </center>
              )}
              {viewableCount === callouts.length && callouts.length > 2 && (
                <center>
                  <Button
                    variant="link"
                    onClick={handleViewLess}
                    style={{ color: "#418abb" }}
                  >
                    View Less
                  </Button>
                </center>
              )}
            </>
          ) : (
            <p className="text-center">No callouts found.</p>
          )}
        </Row>
        // <div>
        //   {callouts.length > 0 ? (
        //     <>
        //       {callouts.slice(0, viewableCount).map((callout) => (
        //         <Card
        //           key={callout._id}
        //           className="mb-3"
        //           onClick={() => handlecalloutClick(callout._id)}
        //           style={{
        //             backgroundColor: "#e8e8e8",
        //             borderRadius: "15px",
        //             cursor: "pointer",
        //           }}
        //         >
        //           <span className="badge bg-danger rounded-pill position-absolute top-0 end-0 m-2">
        //             Urgent
        //           </span>
        //           <Card.Body>
        //             <h4>
        //               <strong style={{ fontWeight: "bold" }}>
        //                 {callout.title}
        //               </strong>
        //             </h4>
        //             <p>{callout.short_description}</p>
        //             <p>
        //               <strong>Location:</strong> {callout.location.suburb}
        //             </p>
        //             {/* <p>
        //               <strong>Date:</strong>
        //               {typeof callout.from_date === "object"
        //                 ? new Date(
        //                     callout.from_date.year,
        //                     callout.from_date.month - 1,
        //                     callout.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : callout.from_date}
        //             </p> */}
        //             <p>
        //               <strong>Date:</strong>{" "}
        //               {typeof callout.from_date === "object" &&
        //               callout.from_date &&
        //               typeof callout.from_date.year === "number" &&
        //               typeof callout.from_date.month === "number" &&
        //               typeof callout.from_date.day === "number" &&
        //               callout.from_date.year > 0 &&
        //               callout.from_date.month >= 1 &&
        //               callout.from_date.month <= 12 &&
        //               callout.from_date.day >= 1 &&
        //               callout.from_date.day <= 31
        //                 ? new Date(
        //                     callout.from_date.year,
        //                     callout.from_date.month - 1,
        //                     callout.from_date.day
        //                   )
        //                     .toISOString()
        //                     .split("T")[0]
        //                 : typeof callout.from_date === "string"
        //                 ? callout.from_date
        //                 : ""}
        //             </p>

        //             <p>
        //               <strong>Time:</strong>{" "}
        //               {typeof callout.from_time === "object"
        //                 ? `${callout.from_time.hour}:${callout.from_time.minute} ${callout.from_time.am_pm}`
        //                 : callout.from_time}
        //             </p>
        //             {/* Trash icon for deleting */}
        //             {venueData.venue_info &&
        //               venueData.venue_info.user_id === loggedInUserId && (
        //                 <Button
        //                   style={{
        //                     backgroundColor: "#151a33",
        //                     zIndex: 1,
        //                   }}
        //                   onClick={() => handleDeleteClick(callout._id)}
        //                 >
        //                   <BsTrash />
        //                 </Button>
        //               )}
        //           </Card.Body>

        //           <Modal
        //             show={showDeleteModal}
        //             onHide={() => setShowDeleteModal(false)}
        //           >
        //             <Modal.Header closeButton>
        //               <Modal.Title>Confirm Deletion</Modal.Title>
        //             </Modal.Header>
        //             <Modal.Body>
        //               Are you sure you want to delete this opportunity?
        //             </Modal.Body>
        //             <Modal.Footer>
        //               <Button
        //                 variant="primary"
        //                 onClick={() => setShowDeleteModal(false)}
        //               >
        //                 Cancel
        //               </Button>
        //               <Button variant="danger" onClick={handleDeleteConfirm}>
        //                 Delete
        //               </Button>
        //             </Modal.Footer>
        //           </Modal>
        //         </Card>
        //       ))}
        //       {viewableCount < callouts.length && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewMore}
        //             style={{ color: "#418abb" }}
        //           >
        //             View More ({callouts.length - viewableCount} more)
        //           </Button>
        //         </center>
        //       )}
        //       {viewableCount === callouts.length && callouts.length > 2 && (
        //         <center>
        //           <Button
        //             variant="link"
        //             onClick={handleViewLess}
        //             style={{ color: "#418abb" }}
        //           >
        //             View Less
        //           </Button>
        //         </center>
        //       )}
        //     </>
        //   ) : (
        //     <p className="text-center">No callouts found.</p>
        //   )}
        // </div>
      )}
      {/* Delete confirmation modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this opportunity?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        style={{ alignSelf: "center" }}
        // style={{ minWidth: "100% !important" }}
      >
        <Modal.Header>
          <button
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* Render ViewPerformerProfile component if performer ID is selected */}
          {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
          <div>{modalContent}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewCalloutsbySelfforBilling;
