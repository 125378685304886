import React from "react";
import { FaTrash } from "react-icons/fa";

const Tooltip = ({ onDelete, visible }) => {
  return (
    <div className={`tooltip ${visible ? "visible" : ""}`}>
      <button onClick={onDelete}>
        <FaTrash /> Delete Conversation
      </button>
    </div>
  );
};

export default Tooltip;
