import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../../components/autoCompleteForms";
import "../opportunities/addNewOpportunity.css";
import CustomTimePicker from "../../components/CustomTimePicker";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import moment from "moment";
import { BorderColor } from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

const CreateClassifiedbyPerformer = () => {
  const [formData, setFormData] = useState({
    title: "",
    classified_category: "",
    classified_sub_category: "",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    classified_type: "",
    price_point: { currency: "AUD", value_from: 0, value_to: 1500 },
    price_point: {
      currency: "AUD",
      value_from: 0,
      value_to: 1500,
    },
    short_description: "",
    descriptions: [""],
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    author_profile_id: "",
  });

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suburb, setSuburb] = useState();
  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");
  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };

  const classifiedTypes = [
    "Looking to Buy",
    "Selling"
  ];

  // Categories and subcategories
  const categories = [
    "Instruments Brass / Wind",    
    "Instruments Electronic",    
    "Instruments Keyboard",    
    "Instruments Percussion",    
    "Instruments String",    
    "Memorabillia + Collectibles",    
    "Sound + Stage Equipment"
  ];

  const subCategories = {
    "Instruments Brass / Wind": [
      "Baritone Horn",
      "Bassoon",
      "Clarinet",
      "Cornet",
      "Euphonium",
      "Flugelhorn",
      "Flute",
      "French Horn",
      "Harmonica",
      "Oboe",
      "Piccolo",
      "Recorder",
      "Saxophone",
      "Trombone",
      "Trumpet",
      "Tuba"
    ],
    "Instruments Electronic": [
      "Audio Interfaces",
      "Drum Machine",
      "Electronic Keyboard",
      "Headphones",
      "MIDI Controller",
      "MIDI Controllers / Sequencers",
      "Music Production Hardware",
      "Music Production Software",
      "Sampler",
      "Sequencer",
      "Studio Equipment",
      "Synthesiser"
    ],
    "Instruments Keyboard": [
      "Accordion",
      "Digital Piano",
      "Electronic Keyboard",
      "Harpsichord",
      "Melodica",
      "Organ",
      "Piano",
      "Synthesiser"
    ],
    "Instruments Percussion": [
      "Bass Drum",
      "Bongos",
      "Cajón",
      "Congas",
      "Djembe",
      "Drum Kit",
      "Electronic Drums",
      "Marimba",
      "Snare Drum",
      "Tambourine",
      "Timpani",
      "Vibraphone",
      "Xylophone"
    ],
    "Instruments String": [
      "Acoustic Guitar",
      "Banjo",
      "Bass Guitar",
      "Cello",
      "Double Bass",
      "Electric Guitar",
      "Harp",
      "Lute",
      "Mandolin",
      "Ukulele",
      "Viola",
      "Violin"
    ],
    "Memorabillia + Collectibles": [
      "Collectible Records",
      "Limited Edition Releases",
      "Memorabilia (autographs, Posters, Rare Items)",
      "Vintage Gear",
      "Vintage Instruments"
    ],
    "Sound + Stage Equipment": [
      "Accessories",
      "Amplifiers",
      "Audio Interfaces",
      "Cables",
      "DJ Equipment",
      "Effects Pedals",
      "Loudspeakers",
      "Microphones",
      "Mixing Desks",
      "Monitors",
      "PA Systems",
      "Stage Lighting"
    ]
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const performer_id = Cookies.get("profileOwnerId");
        console.log(performer_id);
        const profileType = performer_id.split("-");
        let url;
        if (profileType === "VENUE") {
          url = `/v1/venue/view-profile/${performer_id}/section/contact`;
        } else {
          url = `/v1/performer/view-profile/${performer_id}/section/contact`;
        }
        const response = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });
        console.log(response.data.performer_contact.location);
        setSuburb(response.data.performer_contact.location);
        handleSuburbSelect(response.data.performer_contact.location);
        // console.log(response.data.performer_contact.location);
        if (profileType === "VENUE") {
          console.log(response.data);
          // handleSuburbSelect(response.data.venue_contact.location);
        } else {
          console.log(response.data);
          // handleSuburbSelect(response.data.performer_contact.location);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  // useEffect(() => {
  //   const profileId = Cookies.get("profileOwnerId");
  //   if (profileId) {
  //     setFormData({ ...formData, author_profile_id: profileId });
  //   }
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const categoriesResponse = await axiosInstance.get(
  //       "/v1/classified/categories-sub-categories"
  //     );
  //     if (
  //       categoriesResponse.data &&
  //       categoriesResponse.data.classified_categories_sub_categories
  //     ) {
  //       setCategories(
  //         categoriesResponse.data.classified_categories_sub_categories.map(
  //           (category) => category.category
  //         )
  //       );
  //       setSubCategories(
  //         categoriesResponse.data.classified_categories_sub_categories
  //       );
  //       console.log(
  //         "categories",
  //         categoriesResponse.data.classified_categories_sub_categories
  //       );
  //     }

  //     const typesResponse = await axiosInstance.get("/v1/classified/types");
  //     if (typesResponse.data && typesResponse.data.classified_types) {
  //       setClassifiedTypes(typesResponse.data.classified_types);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    const profileId = Cookies.get("profileOwnerId");
    if (profileId) {
      setFormData({ ...formData, author_profile_id: profileId });
    }

    //     const fetchData = async () => {
    //       try {
    //         const categoriesResponse = await axiosInstance.get(
    //           "/v1/classified/categories-sub-categories"
    //         );
    //         if (
    //           categoriesResponse.data &&
    //           categoriesResponse.data.classified_categories_sub_categories
    //         ) {
    //           setCategories(
    //             categoriesResponse.data.classified_categories_sub_categories.map(
    //               (category) => category.genre
    //             )
    //           );
    //           setSubCategories(
    //             categoriesResponse.data.classified_categories_sub_categories
    //           );
    //           console.log(
    //             "categories" +
    //               categoriesResponse.data.classified_categories_sub_categories
    //           );
    //         }

    //         const typesResponse = await axiosInstance.get("/v1/classified/types");
    //         if (typesResponse.data && typesResponse.data.classified_types) {
    //           setClassifiedTypes(typesResponse.data.classified_types);
    //         }
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       }
    //     };

    //     fetchData();
  }, []);

  //   useEffect(() => {
  //     const profileId = Cookies.get("profileId");
  //     if (profileId) {
  //       setFormData({ ...formData, author_profile_id: profileId });
  //     }
  //   }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "price_point_value") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       price_point: { ...prevData.price_point, value: parseFloat(value) },
  //     }));
  //   } else if (name === "price_point_currency") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       price_point: { ...prevData.price_point, currency: value },
  //     }));
  //   } else {
  //     setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title" && value.length > 100) {
      Swal.fire("Error", "The title cannot exceed 100 characters.", "error");
      return;
    }

    let formattedValue = value;

    // Format the date if the field is a date input
    if (e.target.type === "date") {
      formattedValue = moment(value).format("DD/MM/YYYY");
    }

    if (name === "price_point_value") {
      const priceValue = value;
      if (priceValue[0] < 0 || priceValue[1] > 5000) {
        setErrorMessage("The price point must be between $0 and $5000.");
      } else {
        setErrorMessage(""); // Clear error message if valid
        setFormData((prevData) => ({
          ...prevData,
          price_point: {
            ...prevData.price_point,
            value_from: priceValue[0],
            value_to: priceValue[1],
          },
        }));
      }
    } else if (name === "price_point_currency") {
      setFormData((prevData) => ({
        ...prevData,
        price_point: { ...prevData.price_point, currency: value },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // const handleDescriptionChange = (index, field, value) => {
  //   const newDescriptions = [...formData.descriptions];
  //   if (field === "body") {
  //     newDescriptions[index][field] = [value];
  //   } else {
  //     newDescriptions[index][field] = value;
  //   }
  //   setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
  // };

  // const handleDescriptionChange = (index, value) => {
  //   const newDescriptions = [...formData.descriptions];
  //   newDescriptions[index] = value;  // Update the specific description at the index
  //   setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
  // };

  const handleDescriptionChange = (index, value) => {
    // Check if the description has more than 2000 characters
    if (value.length > 2000) {
      Swal.fire({
        title: 'Too Many Characters!',
        text: 'The description should not exceed 2000 characters.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    } else {
      const newDescriptions = [...formData.descriptions];
      newDescriptions[index] = value; // Update the specific description at index
      setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Function to convert date string to object
    const convertDateToObject = (dateString) => {
      const year = dateString.getFullYear();
      const month = dateString.getMonth() + 1;
      const day = dateString.getDate();

      return { year, month, day };
    };

    const convertTo12HourFormat = (hr, min) => {
      const isPM = hr >= 12;
      const hour = hr % 12 || 12;
      const minute = min % 30 === 0 ? min : 0;
      const am_pm = isPM ? "PM" : "AM";
      return { hour, minute, am_pm };
    };

    const fromDateTime = new Date(`${formData.from}`);
    const toDateTime = new Date(`${formData.to}`);

    console.log(fromDateTime);

    const fromTimeObject = convertTo12HourFormat(
      fromDateTime.getHours(),
      fromDateTime.getMinutes()
    );

    const toTimeObject = convertTo12HourFormat(
      toDateTime.getHours(),
      toDateTime.getMinutes()
    );

    // Convert dates
    const fromDateObject = convertDateToObject(fromDateTime);
    const toDateObject = convertDateToObject(toDateTime);
    const hourDifference = (toDateTime - fromDateTime) / (1000 * 60 * 60);

    // Check if "From Date/Time" is before "To Date/Time"
    if (fromDateTime >= toDateTime) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "The 'From Date/Time' must be before the 'To Date/Time'.",
      });
      setLoading(false);
      return;
    }

    // Check if the difference exceeds 10 hours
    // if (hourDifference > 10) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Validation Error",
    //     text: "To Date/Time must be within 10 hours of From Date/Time",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // Create new form data with converted dates and times
    const updatedFormData = {
      ...formData,
      from_date: fromDateObject,
      to_date: toDateObject,
      from_time: fromTimeObject,
      to_time: toTimeObject,
    };

    console.log("Updated Form Data:", updatedFormData);

    try {
      const response = await axiosInstance.post(
        "/v1/classified/create-classified",
        updatedFormData
      );
      console.log("Classified created:", response.data);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "The classified has been created successfully!",
      }).then(() => {
        navigate(`/performer/${Cookies.get("profileOwnerId")}`);
      });
    } catch (error) {
      console.error("Error creating classified:", error);
      let errorMessage = "An error occurred while creating the classified. Please try again.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigateBack();
  };

  const navigateBack = () => {
    const profileId = Cookies.get("profileOwnerId");
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      navigate(`/performer/${profileId}`);
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      navigate(`/venue/${profileId}`);
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  // const inputStyle = {
  //   width: '100%',
  //   padding: '0.75rem',
  //   border: '1px solid #dcdcdc',
  //   borderRadius: '5px',
  //   marginBottom: '1rem',
  //   transition: 'BorderColor 0.3s',
  // };

  return (
    <>
      <style>
        {`
      body {
        background-color: #f0f2f5;
      }
    `}
      </style>
      <div>
        {/* <Navbar
          navClass="defaultscroll sticky"
          menuClass="navigation-menu nav-left"
        /> */}
        <br></br>

        <Card
          style={{
            borderRadius: "5px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            marginLeft: "13%",
            marginRight: "13%",
            marginTop: "3%",
            paddingLeft: "6%",
            paddingRight: "6%",
            paddingTop: "2%",
            paddingBottom: "1%",
            // backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <div>
            <center>
              <h3>Create new Classified</h3>
            </center>
          </div>
          <br></br>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-2">
              <Row style={{ marginBottom: "-2.5%" }}>
                <div className="inputwith-title">
                  <p className="para-style">Title</p>
                  <input
                    type="text"
                    className="form-control mb-3 shadow-box input-style"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Row>

              <Row>
                <Col md={6}>

                  <div className="d-flex flex-column">
                    {/* Left column (half width) */}
                    <div className="inputwith-title">
                      <p className="para-style">Classified Type</p>
                      <select
                        className="form-select shadow-box input-style"
                        name="classified_type"
                        value={formData.classified_type}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Classified Type</option>
                        {classifiedTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="inputwith-title">
                      <p className="para-style">Category</p>
                      <select
                        className="form-select shadow-box input-style"
                        name="classified_category"
                        value={formData.classified_category}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Category</option>
                        {categories.map((category, index) => (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="inputwith-title">
                      <p className="para-style">Sub Category</p>
                      <select
                        className="form-select shadow-box input-style"
                        name="classified_sub_category"
                        value={formData.classified_sub_category || ""}
                        onChange={handleChange}
                        disabled={!formData.classified_category || !subCategories[formData.classified_category]}
                        required
                      >
                        <option value="">Select Sub Category</option>
                        {formData.classified_category && subCategories[formData.classified_category] &&
                          subCategories[formData.classified_category].map((subCategory) => (
                            <option key={subCategory} value={subCategory}>
                              {subCategory}
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="inputwith-title">
                      <p className="para-style">Location</p>
                      {/* <AutoComplete onSuburbSelect={handleSuburbSelect} /> */}
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="location"
                        name="location"
                        value={formData.location.address}
                        onChange={handleChange}
                        readOnly={true}
                      />
                    </div>

                    {/* <div className="inputwith-title">
                      <p className="para-style">Price Point</p>
                      <input
                        type="number"
                        className="form-control shadow-box input-style"
                        id="floatingInput"
                        name="price_point"
                        placeholder="Price point"
                        value={formData.price_point}
                        onChange={handleChange}
                      />
                    </div> */}

                    <Row>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">Currency</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="currency"
                            name="price_point_currency"
                            value={formData.price_point.currency}
                            onChange={handleChange}
                            disabled
                          // required
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style mb-4">Price Point</p>
                          {/* <input
                            type="number"
                            className="form-control shadow-box input-style"
                            id="price_point"
                            name="price_point_value"
                            placeholder="Price point"
                            value={formData.price_point.value}
                            onChange={handleChange}
                          /> */}
                          <div style={{ position: "relative", width: "100%" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                left: "0",
                              }}
                            >
                              ${formData.price_point.value_from}
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                right: "0",
                              }}
                            >
                              ${formData.price_point.value_to}
                            </span>
                            <Slider
                              value={[
                                formData.price_point.value_from,
                                formData.price_point.value_to,
                              ]}
                              onChange={(event, newValue) =>
                                handleChange({
                                  target: {
                                    name: "price_point_value",
                                    value: newValue,
                                  },
                                })
                              }
                              valueLabelDisplay="auto"
                              style={{ top: "2px" }}
                              min={0}
                              max={5000}
                              step={10}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div className="inputwith-title">
                      <p className="para-style">Short Description</p>
                      <textarea
                        className="form-control mb-3 shadow-box textarea-style"
                        placeholder="Short Description"
                        name="short_description"
                        value={formData.short_description}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    {/* <div className="inputwith-title">
                      <p className="para-style">Venue</p>
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="venue"
                        name="venue"
                        placeholder="venue"
                        value={formData.venue}
                        onChange={handleChange}
                      />
                    </div> */}

                    <Row>
                      <p>Date</p>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">From</p>
                          <Datetime
                            // inputProps={{ style: inputStyle }}
                            inputProps={{
                              className: "form-control shadow-box input-style",
                              readOnly: true,
                              placeholder: "Select Date",
                            }}
                            // dateFormat={true}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={(date) =>
                              handleChange({
                                target: { value: date, name: "from" },
                              })
                            }
                            value={formData.from}
                            timeConstraints={{
                              minutes: { step: 30 },
                            }}
                            isValidDate={valid}
                          />
                        </div>
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">To</p>
                          <Datetime
                            // inputProps={{ style: inputStyle }}
                            inputProps={{
                              className: "form-control shadow-box input-style",
                              readOnly: true,
                              placeholder: "Select Date",
                            }}
                            // dateFormat={true}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={(date) =>
                              handleChange({
                                target: { value: date, name: "to" },
                              })
                            }
                            value={formData.to}
                            timeConstraints={{
                              minutes: { step: 30 },
                            }}
                            isValidDate={valid}
                          />
                        </div>
                      </div>
                    </Row>
                    {/* <div className="inputwith-title">
                      <p className="para-style">Category</p>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                      />
                    </div> */}
                    {/* <div className="inputwith-title">
                      <p className="para-style">Amount</p>
                      <input
                        type="number"
                        className="form-control shadow-box input-style"
                        id="floatingInput"
                        name="amount"
                        placeholder="amount"
                        value={formData.amount}
                        onChange={handleChange}
                      />
                    </div> */}
                  </div>
                </Col>
                <Col md={6}>
                  {/* Right column (half width on large screens) */}
                  {/* <p className="para-style">Description</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="floatingInput"
                    placeholder="Enter a detailed description here"
                    name="descriptions"
                    value={formData.descriptions}
                    onChange={handleChange}
                  ></textarea> */}
                  {/* <p className="para-style" style={{ marginTop: "2%" }}>
                    Description Heading
                  </p>
                  <input
                    type="text"
                    className="form-control shadow-box input-style"
                    id="description_heading"
                    name="description_heading"
                    value={formData.descriptions[0].heading}
                    onChange={(e) =>
                      handleDescriptionChange(0, "heading", e.target.value)
                    }
                    required
                  />

                  <p className="para-style">Description Body</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="description_body"
                    name="description_body"
                    value={formData.descriptions[0].body[0]}
                    onChange={(e) =>
                      handleDescriptionChange(0, "body", e.target.value)
                    }
                    required
                  ></textarea> */}
                  {formData.descriptions.map((description, index) => (
                    <div key={index}>
                      <p className="para-style">Description</p>
                      <textarea
                        className="form-control shadow-box textarea-style"
                        id="floatingInput"
                        placeholder="Enter a detailed description here"
                        value={description}
                        onChange={(e) => handleDescriptionChange(index, e.target.value)}
                        required
                        style={{ minHeight: "800px", resize: "vertical" }} // Adjust minHeight as needed
                      ></textarea>
                      <br />
                    </div>
                  ))}
                  {/* <div className="inputwith-title">
                    <p className="para-style">Description Heading</p>
                    <input
                      type="text"
                      className="form-control shadow-box input-style"
                      id="description_heading"
                      name="description_heading"
                      value={formData.descriptions[0].heading}
                      onChange={(e) =>
                        handleDescriptionChange(0, "heading", e.target.value)
                      }
                    />
                  </div>
                  <div className="inputwith-title">
                    <p className="para-style">Description Body</p>
                    <textarea
                      className="form-control shadow-box textarea-style"
                      id="description_body"
                      name="description_body"
                      value={formData.descriptions[0].body[0]}
                      onChange={(e) =>
                        handleDescriptionChange(0, "body", e.target.value)
                      }
                    ></textarea>
                  </div> */}
                  <br></br>
                  <div
                    className="button-container"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="text-center">
                      <button type="button"
                        onClick={handleCancel}
                        style={{
                          marginRight: "5%",
                          borderRadius: "5px",
                          padding: "0.75rem 1.5rem",
                        }}
                        className="btn btn-outline-primary">
                        Cancel
                      </button>

                      <button
                        style={{
                          borderRadius: "5px",
                          padding: "0.75rem 1.5rem",
                          minWidth: "110px",
                          cursor: loading ? "not-allowed" : "pointer",
                          opacity: loading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                        }}
                        className="btn btn-primary"
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {/* {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )} */}
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateClassifiedbyPerformer;
