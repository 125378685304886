import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "./axiosInstance";
import NavigationBar from "../../components/navbar/loggedOutUserNavbar";

const baseBackendURL = process.env.REACT_APP_BASE_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  min-height:: 100vh;
  padding-top: 150px;
  padding: 20px;  
  margin-top:120px;  

  @media (max-width: 768px) {
    padding: 8px; // Adjust margin as needed for spacing
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  color: #666;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SignUpLink = styled.div`
  margin-top: 15px;
  color: #666;

  a {
    color: #007bff;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function AuthLogin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error message for the corresponding field
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#f0f2f5";

    // Cleanup function to reset the background color
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []); // <== Added useEffect hook

  const validate = () => {
    const errors = {};
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        // "http://gigbridgeapiqa-env.eba-tiav7hzt.eu-north-1.elasticbeanstalk.com/v1/auth/sign-in",
        // "https://api.ogotrix.au/v1/auth/sign-in",
        // "http://127.0.0.1:4000/v1/auth/sign-in",
        // "https://www.ramoi.com/v1/auth/sign-in",
        `${baseBackendURL}/v1/auth/sign-in`,
        formData
      );
      console.log("API Response:", response?.data.data);

      const { access, refresh } = response.data.data.tokens;
      // console.log("access refresh tokens: ", response.data.data.tokens);
      handleLoginSuccess(access, refresh); // Pass tokens to handleLoginSuccess function
    } catch (error) {
      console.error("API Error:", error.response?.data.data);

      let errorMessage = "An error occurred during login. Please try again.";

      if (error.response && error.response.data && error.response.data.data) {
        errorMessage = error.response.data.message;
        console.log(error.response.data);
        // errorMessage = "Invalid username or password";
      }

      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
      // Reset loading state to allow retry
      setLoading(false);
    }
  };

  const handleLoginSuccess = async (accessToken, refreshToken) => {
    const isSecure = window.location.protocol === "https:";
    const cookieOptions = {
      secure: isSecure,
      sameSite: "Lax",
      // httpOnly: true,
    };
    Cookies.set("accessToken", accessToken, cookieOptions);
    Cookies.set("refreshToken", refreshToken, cookieOptions);
    localStorage.setItem("isAuthenticated", true);

    try {
      // Fetch account status directly
      const accountStatusResponse = await axiosInstance.get(
        "/v1/auth/account/status",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const accountStatus = accountStatusResponse.data.data.account_status;
      console.log(
        "account status response:",
        accountStatusResponse.data.data.account_status
      );

      if (accountStatus === "ACTIVE") {
        navigate("/profiles");
      } else if (accountStatus === "EMAIL_UNVERIFIED") {
        navigate("/verify-email");
      } else if (accountStatus === "FRESH") {
        navigate("/profiles");
      } else if (accountStatus === "PHONE_NUMBER_PENDING") {
        navigate("/phone-entry");
        // navigate("/profiles");
      } else if (accountStatus === "PHONE_NUMBER_UNVERIFIED") {
        navigate("/phone-entry");
        // navigate("/profiles");
      } else if (accountStatus === "APPROVAL_PENDING") {
        navigate("/account-approval-notice");
      } else if (accountStatus === "REJECTED") {
        navigate("/account-rejected-page");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unexpected account status detected.",
        });
      }
    } catch (error) {
      console.error("API Error:", error.response?.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to retrieve account status. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleHome = () => {
    navigate("/");
  };

  return (
    <>
      <Container>
        {/* <NavBar isMobile={isMobile}>
          <Logo src={logo} alt="Logo" />
          <div>
            <ButtonB onClick={handleHome}>Home</ButtonB>
            <ButtonB onClick={handleSignup}>Sign up</ButtonB>
          </div>
        </NavBar> */}

        <NavigationBar />
        <Title>Sign in to GigBridge</Title>

        <FormWrapper onSubmit={handleSubmit}>
          <InputGroup>
            <Label htmlFor="email">Email</Label>
            <InputWrapper>
              <Icon icon={faEnvelope} />
              <Input
                type="email"
                id="email"
                className="form-control"
                name="email"
                placeholder="E-mail address"
                value={formData.email}
                onChange={handleChange}
              />
            </InputWrapper>
            {/* {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>} */}
            {/* {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>} */}
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password">Password</Label>
            <InputWrapper>
              <Icon icon={faLock} />
              <Input
                type="password"
                id="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </InputWrapper>
            {errors.password && (
              <div className="text-danger">{errors.password}</div>
            )}
          </InputGroup>

          <CheckboxWrapper>
            {/* <CheckboxLabel>
              <Checkbox type="checkbox" value="" id="keepSignedIn" />
              <span>Keep me signed in</span>
            </CheckboxLabel> */}
            <a href="/fogot-password">Forgot your password?</a>
          </CheckboxWrapper>

          {/* <Button type="submit">
            Sign in {loading && <Spinner animation="border" role="status" style={{ width: '2rem', height: '2rem', borderWidth: '0.1rem' }}/>}{" "}
          </Button> */}

          <Button type="submit" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Sign in"
            )}
          </Button>

          <SignUpLink>
            New to GigBridge? <a href="/signup">Sign Up</a>
          </SignUpLink>
        </FormWrapper>
      </Container>
    </>
  );
}
