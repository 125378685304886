import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import "../OtherPages/LandingProfiles.css";

function UpdatePerformerSocialMedia() {
  const [socialMediaTypes, setSocialMediaTypes] = useState([]);
  const [socialMediaValues, setSocialMediaValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSocialMediaTypes() {
      try {
        const response = await axiosInstance.get(
          "/v1/profile/social-media-types",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          setSocialMediaTypes(response.data.social_media_types);
          // Initialize social media values state with empty strings for each type
          const initialValues = response.data.social_media_types.reduce(
            (acc, type) => ({ ...acc, [type]: "" }),
            {}
          );
          setSocialMediaValues(initialValues);
        } else {
          console.error("Error fetching social media types:", response.data);
          setErrorMessage("Error fetching social media types.");
        }
      } catch (error) {
        console.error("Error fetching social media types:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchSocialMediaTypes();
  }, []);

  const handleChange = (type, value) => {
    setSocialMediaValues((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/performer/update-profile/social-media",
        {
          performer_id: Cookies.get("profileId"),
          ...socialMediaValues,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("Performer social media updated successfully.");
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Performer social media updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileId");
          navigate(`/performer/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating performer social media.");
        console.error("Error updating performer social media:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating performer social media:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update Performer Social Media</h5>

                {socialMediaTypes.map((type) => (
                  <div className="form-floating mb-2" key={type}>
                    <input
                      type="text"
                      className="form-control"
                      id={type.toLowerCase()}
                      placeholder={type}
                      value={socialMediaValues[type]}
                      onChange={(e) => handleChange(type, e.target.value)}
                    />
                    <label htmlFor={type.toLowerCase()}>{type}</label>
                  </div>
                ))}

                <button className="btn btn-primary w-100" type="submit">
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePerformerSocialMedia;
