import React, { useEffect } from "react";

const ZohoForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://zcsub-cmpzourl.maillist-manage.com/js/optin.min.js";
    script.onload = () => {
      window.setupSF(
        "sf3zd15418c3f8ff7da7ea36ecc341096d53770274e285b96b8153f451b9302d3f17",
        "ZCFORMVIEW",
        false,
        "light",
        false,
        "0"
      );
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div
      id="sf3zd15418c3f8ff7da7ea36ecc341096d53770274e285b96b8153f451b9302d3f17"
      data-type="signupform"
    >
      <div id="customForm">
        <div
          name="SIGNUP_BODY"
          changeitem="BG_IMAGE"
          style={{
            textAlign: "center",
            width: "250px",
            height: "340px",
            position: "relative",
            zIndex: 2,
            backgroundColor: "rgb(255, 255, 255)",
            margin: "auto",
            overflow: "hidden",
          }}
        >
          <div
            changeitem="ELEGANTFORM_IMAGE"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              bottom: 0,
            }}
          >
            <img
              src="https://campaign-image.com/zohocampaigns/1301d85c_sign_form_bg_16.png"
              style={{ width: "100%", height: "100%", position: "relative" }}
            />
          </div>
          <div
            style={{
              width: "250px",
              height: "340px",
              position: "relative",
              fontFamily: "Arial",
              margin: "auto",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(255, 235, 232)",
                padding: "10px",
                color: "rgb(210, 0, 0)",
                fontSize: "11px",
                margin: "20px 10px 0px",
                border: "1px solid rgb(255, 217, 211)",
                opacity: 1,
                position: "absolute",
                boxShadow: "rgb(27, 27, 27) 0px 5px 12px 0px",
                display: "none",
              }}
              id="errorMsgDiv"
            >
              Please correct the marked field(s) below.
            </div>
            <form
              method="POST"
              id="zcampaignOptinForm"
              style={{ margin: 0, width: "100%", color: "rgb(255, 255, 255)" }}
              action="https://zcsub-cmpzourl.maillist-manage.com/weboptin.zc"
              target="_zcSignup"
            >
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  float: "left",
                  position: "absolute",
                  zIndex: 2,
                  bottom: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontFamily: "Arial",
                    fontWeight: "normal",
                    color: "rgb(0, 0, 0)",
                    lineHeight: 1.556,
                    textAlign: "center",
                    margin: "0px 0px 10px",
                    width: "100%",
                    float: "left",
                  }}
                >
                  Sign up to be in the know.
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "190px",
                    height: "28px",
                    marginBottom: "20px",
                    display: "inline-block",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      borderWidth: "0px 0px 1px",
                      borderColor: "rgb(99, 99, 99)",
                      borderStyle: "solid",
                      width: "100%",
                      height: "100%",
                      zIndex: 4,
                      outline: "none",
                      padding: "5px",
                      boxSizing: "border-box",
                      color: "rgb(51, 51, 51)",
                      fontFamily: "Arial",
                      backgroundColor: "transparent",
                    }}
                    placeholder="First Name"
                    name="FIRSTNAME"
                    id="FIRSTNAME"
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "190px",
                    height: "28px",
                    margin: "auto",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    placeholder="Email Address"
                    name="CONTACT_EMAIL"
                    id="EMBED_FORM_EMAIL_LABEL"
                    type="text"
                    style={{
                      borderWidth: "0px 0px 1px",
                      borderColor: "rgb(99, 99, 99)",
                      borderStyle: "solid",
                      width: "100%",
                      height: "100%",
                      zIndex: 4,
                      outline: "none",
                      padding: "5px",
                      boxSizing: "border-box",
                      color: "rgb(136, 136, 136)",
                      fontFamily: "Arial",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "113px",
                    height: "30px",
                    display: "inline-block",
                  }}
                >
                  <input
                    type="button"
                    style={{
                      textAlign: "center",
                      borderRadius: "6px",
                      backgroundColor: "rgb(209, 107, 166)",
                      width: "100%",
                      height: "100%",
                      zIndex: 5,
                      border: 0,
                      color: "rgb(255, 255, 255)",
                      cursor: "pointer",
                      outline: "none",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                    name="SIGNUP_SUBMIT_BUTTON"
                    id="zcWebOptin"
                    value="Sign Up"
                  />
                </div>
              </div>
              <input type="hidden" id="fieldBorder" value="" />
              <input
                type="hidden"
                id="submitType"
                name="submitType"
                value="optinCustomView"
              />
              <input
                type="hidden"
                id="emailReportId"
                name="emailReportId"
                value=""
              />
              <input
                type="hidden"
                id="formType"
                name="formType"
                value="QuickForm"
              />
              <input type="hidden" name="zx" id="cmpZuid" value="13303ce3f" />
              <input type="hidden" name="zcvers" value="3.0" />
              <input
                type="hidden"
                name="oldListIds"
                id="allCheckedListIds"
                value=""
              />
              <input
                type="hidden"
                id="mode"
                name="mode"
                value="OptinCreateView"
              />
              <input type="hidden" id="zcld" name="zcld" value="" />
              <input
                type="hidden"
                id="zctd"
                name="zctd"
                value="111dc86f9937577a9"
              />
              <input type="hidden" id="document_domain" value="" />
              <input
                type="hidden"
                id="zc_Url"
                value="zcsub-cmpzourl.maillist-manage.com"
              />
              <input type="hidden" id="new_optin_response_in" value="0" />
              <input type="hidden" id="duplicate_optin_response_in" value="0" />
              <input
                type="hidden"
                name="zc_trackCode"
                id="zc_trackCode"
                value="ZCFORMVIEW"
              />
              <input
                type="hidden"
                id="zc_formIx"
                name="zc_formIx"
                value="3zd15418c3f8ff7da7ea36ecc341096d53770274e285b96b8153f451b9302d3f17"
              />
              <input type="hidden" id="viewFrom" value="URL_ACTION" />
              <span style={{ display: "none" }} id="dt_CONTACT_EMAIL">
                1,true,6,Contact Email,2
              </span>
              <span style={{ display: "none" }} id="dt_FIRSTNAME">
                1,false,1,First Name,2
              </span>
              <span style={{ display: "none" }} id="dt_LASTNAME">
                1,false,1,Last Name,2
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZohoForm;
