import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import NavigationBar from "../../components/navbar/newNavigationBar";
import pic from "../../assect/images/dashboard/venue-1.png";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axiosInstance from "../../pages/auth/axiosInstance";
import NewOpportunityAuthorVenueView from "../../pages/newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../../pages/newView/NewOpportunityPerformerView";
import NewOpportunityVenueView from "../../pages/newView/NewOpportunityVenueView";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import DirectBookingPerformerView from "../directBookings/directBookingPerformerView";
import Footer from "../../components/footer";
import Spinner from "react-bootstrap/Spinner";

const getStatusBadgeVariant = (status) => {
  switch (status) {
    case "PERFORMER_APPLIED":
      return "badge-primary";
    case "PERFORMER_DECLINED":
      return "badge-danger";
    case "VENUE_REJECTED":
      return "badge-danger";
    case "CONFIRMATION_REQUESTED":
      return "badge-warning";
    case "VENUE_WITHDRAWN":
      return "badge-warning";
    case "PERFORMER_DOUBLED_CONFIRMED":
      return "badge-success";
    default:
      return "badge-secondary";
  }
};

const Tabs = () => {
  const [bookings, setBookings] = useState([]);
  const [directBookings, setDirectBookings] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const loggedInProfileId = Cookies.get("profileOwnerId");
  const location = useLocation();
  const { opportunityId } = location.state || {};
  const [performerData, setPerformerData] = useState({});
  const [activeTab, setActiveTab] = useState("Applied Posts");
  const [subTab, setSubTab] = useState("Opportunity"); // Initialize subTab state
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedbookingId, setSelectedbookingId] = useState(null);
  const [modalContent2, setModalContent2] = useState(null);
  // const [nextUrl, setNextUrl] = useState(null);
  // const [previousUrl, setPreviousUrl] = useState(null);
  // State variables for bookings pagination
  const [bookingsNextUrl, setBookingsNextUrl] = useState(null);
  const [bookingsPreviousUrl, setBookingsPreviousUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [cancelRequestLoading, setCancelRequestLoading] = useState(false);
  const [doubleConfirmLoading, setDoubleConfirmLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [cancelContractLoading, setCancelContractLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [opploadingNext, setOppLoadingNext] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [opploadingPrevious, setOppLoadingPrevious] = useState(false);

  // State variables for opportunities pagination
  const [opportunitiesNextUrl, setOpportunitiesNextUrl] = useState(null);
  const [opportunitiesPreviousUrl, setOpportunitiesPreviousUrl] =
    useState(null);

  // const getStatusLabel = (status) => {
  //   switch (status) {
  //     case "PERFORMER_APPLIED":
  //       return "Applied"&"badge-primary";
  //     case "PERFORMER_DECLINED":
  //       return "Declined"&"badge-danger";
  //     case "VENUE_REJECTED":
  //       return "Rejected"&"badge-danger";
  //     case "CONFIRMATION_REQUESTED":
  //       return "Confirmation Requested"&"badge-warning";
  //     case "VENUE_WITHDRAWN":
  //       return "Withdrawn"&"badge-warning";
  //     case "PERFORMER_DOUBLED_CONFIRMED":
  //       return "Success"&"badge-success";
  //     default:
  //       return status&"badge-secondary"; // Default to showing the raw status if no match is found
  //   }
  // };

  const getStatusLabel = (status) => {
    switch (status) {
      case "PERFORMER_APPLIED":
        return { label: "Applied", badgeClass: "badge-primary" };
      case "PERFORMER_DECLINED":
        return { label: "Declined", badgeClass: "badge-danger" };
      case "VENUE_REJECTED":
        return { label: "Rejected", badgeClass: "badge-danger" };
      case "CONFIRMATION_REQUESTED":
        return { label: "Confirmation Requested", badgeClass: "badge-warning" };
      case "VENUE_WITHDRAWN":
        return { label: "Withdrawn", badgeClass: "badge-warning" };
      case "PERFORMER_DOUBLED_CONFIRMED":
        return {
          label: "Performer Double Confirmed",
          badgeClass: "badge-success",
        };
      case "VENUE_REQUESTED":
        return { label: "VENUE REQUESTED", badgeClass: "badge-success" };
      case "CONFIRMED":
        return { label: "BOOKING CONFIRMED", badgeClass: "badge-success" };
      default:
        return { label: status, badgeClass: "badge-secondary" };
    }
  };

  // const sortBookingsByDate = (bookings) => {
  //   return bookings.sort((booking1, booking2) => {
  //     const date1 = new Date(booking1.created_on_timestamp_utc);
  //     const date2 = new Date(booking2.created_on_timestamp_utc);
  //     return date2.getTime() - date1.getTime();
  //   });
  // };

  const sortBookingsByDate = (bookings) => {
    return bookings.sort((booking1, booking2) => {
      const date1 = new Date(booking1.from_date_time_utc);
      const date2 = new Date(booking2.from_date_time_utc);
      return date1.getTime() - date2.getTime();
    });
  };

  const formatDateRange = (fromDate, fromTime, toDate, toTime) => {
    const formatTime = (time) => {
      return `${time.hour}:${time.minute.toString().padStart(2, "0")} ${
        time.am_pm
      }`;
    };

    const formatDate = (date) => {
      return `${date.day}/${date.month}/${date.year}`;
    };

    const formattedFrom = `${formatDate(fromDate)} ${formatTime(fromTime)}`;
    const formattedTo = `${formatDate(toDate)} ${formatTime(toTime)}`;

    return `${formattedFrom} - ${formattedTo}`;
  };

  // Function to fetch bookings
  const fetchBookings = async (
    url = "/v1/booking/view-all-pagination/by/performer"
  ) => {
    const payload = {
      logged_in_profile_id: loggedInProfileId,
    };

    try {
      const response = await axiosInstance.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log("opp booking response: ", response);
        const bookings = response.data.bookings.results;

        const opportunityPromises = bookings.map(async (booking) => {
          try {
            const opportunityResponse = await axiosInstance.get(
              `/v1/opportunity/view/${booking.opportunity_id}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            return {
              ...booking,
              opportunityStatus: opportunityResponse.data.opportunity.status,
            };
          } catch (opportunityError) {
            console.error(
              `Error fetching opportunity details for booking ${booking.opportunity_id}:`,
              opportunityError
            );
            return { ...booking, opportunityStatus: null };
          }
        });

        const bookingsWithOpportunityDetails = await Promise.all(
          opportunityPromises
        );
        const sortedBookings = sortBookingsByDate(
          bookingsWithOpportunityDetails
        );
        setBookings(sortedBookings);
        setOpportunitiesNextUrl(response.data.bookings.next);
        setOpportunitiesPreviousUrl(response.data.bookings.previous);
      } else {
        console.error(
          "Error fetching bookings:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Request error:", error);
    }
  };

  // Function to fetch direct bookings
  const fetchDirectBookings = async (
    url = "/v1/booking-direct/view-all-pagination/by/performer"
  ) => {
    const payload = {
      performer_profile_id: loggedInProfileId,
    };

    try {
      const bookingresponse = await axiosInstance.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (bookingresponse.status === 200) {
        console.log("direct booking response: ", bookingresponse);
        const sortedDirectBookings = sortBookingsByDate(
          bookingresponse.data.bookings_direct.results
        );
        setDirectBookings(sortedDirectBookings);
        setBookingsNextUrl(bookingresponse.data.bookings_direct.next);
        setBookingsPreviousUrl(bookingresponse.data.bookings_direct.previous);
      } else {
        console.error(
          "Error fetching bookings:",
          bookingresponse.status,
          bookingresponse.data
        );
      }
    } catch (error) {
      console.error("Request error:", error);
    }
  };

  // useEffect to initially fetch bookings
  useEffect(() => {
    fetchBookings();
    fetchDirectBookings();
  }, [accessToken, loggedInProfileId]);

  // Handle next for bookings
const handleBookingsNext = async (e) => {
  e.preventDefault();
  setLoadingNext(true); // Start loading
  try {
    if (bookingsNextUrl) {
      const nextUrlWithBasePath = bookingsNextUrl.startsWith(
        "/v1/booking-direct"
      )
        ? bookingsNextUrl
        : `/v1/booking-direct${bookingsNextUrl}`;

      console.log("Fetching next URL for bookings: ", nextUrlWithBasePath);

      // Wait for the booking fetch to complete
      await fetchDirectBookings(nextUrlWithBasePath);
    }
  } catch (error) {
    console.error("Error fetching next bookings:", error);
  } finally {
    setLoadingNext(false); // Always stop loading, success or error
  }
};

// Handle previous for bookings
const handleBookingsPrevious = async (e) => {
  e.preventDefault();
  setLoadingPrevious(true); // Start loading
  try {
    if (bookingsPreviousUrl) {
      const previousUrlWithBasePath = bookingsPreviousUrl.startsWith(
        "/v1/booking-direct"
      )
        ? bookingsPreviousUrl
        : `/v1/booking-direct${bookingsPreviousUrl}`;

      console.log("Fetching previous URL for bookings: ", previousUrlWithBasePath);

      // Wait for the booking fetch to complete
      await fetchDirectBookings(previousUrlWithBasePath);
    }
  } catch (error) {
    console.error("Error fetching previous bookings:", error);
  } finally {
    setLoadingPrevious(false); // Always stop loading, success or error
  }
};


  // Handle next and previous for opportunities (assuming you have a fetchOpportunities function)
  const handleOpportunitiesNext = async (e) => {
    e.preventDefault();
    setOppLoadingNext(true); // Start loading
    try {
      if (opportunitiesNextUrl) {
        const nextUrlWithBasePath = opportunitiesNextUrl.startsWith("/v1/booking")
          ? opportunitiesNextUrl
          : `/v1/booking${opportunitiesNextUrl}`;

        console.log("Fetching next URL for opportunities: ", nextUrlWithBasePath);

        // Ensure fetchBookings is an async function that returns a promise
        await fetchBookings(nextUrlWithBasePath); // Wait for fetch to complete
      }
    } catch (error) {
      console.error("Error fetching next opportunities:", error);
    } finally {
      setOppLoadingNext(false); // Stop loading in both success and failure
    }
  };

  const handleOpportunitiesPrevious = async (e) => {
    e.preventDefault();
    setOppLoadingPrevious(true); 
    try {
      if (opportunitiesPreviousUrl) {
        const previousUrlWithBasePath = opportunitiesPreviousUrl.startsWith("/v1/booking")
          ? opportunitiesPreviousUrl
          : `/v1/booking${opportunitiesPreviousUrl}`;

        console.log("Fetching previous URL for opportunities: ", previousUrlWithBasePath);

        // Ensure fetchBookings is an async function that returns a promise
        await fetchBookings(previousUrlWithBasePath); // Wait for fetch to complete
      }
    } catch (error) {
      console.error("Error fetching previous opportunities:", error);
    } finally {
      setOppLoadingPrevious(false); // Stop loading in both success and failure
    }
  };

  // useEffect(() => {
  //     console.log("Fetching direct bookings...");

  //     const fetchDirectBookings = async () => {
  //         const payload = {
  //             logged_in_profile_id: loggedInProfileId,
  //         };

  //         try {
  //             const response = await axiosInstance.post(
  //                 "/v1/booking_direct/view-all/by/performer",
  //                 payload,
  //                 {
  //                     headers: {
  //                         "Content-Type": "application/json",
  //                         Authorization: `Bearer ${accessToken}`,
  //                     },
  //                 }
  //             );

  //             if (response.status === 200) {
  //                 console.log("Direct Bookings API Response Status:", response.status);
  //                 console.log("Direct Bookings API Response Data:", response.data);
  //                 const sortedBookings = sortBookingsByDate(response.data.bookings_direct);
  //                 setDirectBookings(sortedBookings);
  //             } else {
  //                 console.error(
  //                     "Error fetching direct bookings:",
  //                     response.status,
  //                     response.data
  //                 );
  //             }
  //         } catch (error) {
  //             console.error("Request error:", error);
  //         }
  //     };

  //     fetchDirectBookings();
  // }, [accessToken, loggedInProfileId]);

  const handleDoubleConfirm = async (bookingId) => {
    setDoubleConfirmLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/double-confirm/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "Double confirmed!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_DOUBLED_CONFIRMED" }
              : booking
          )
        );
      } else {
        console.error(
          "Error confirming request:",
          response.status,
          response.data
        );
        Swal.fire("Error", "An error occurred while confirming the request.", "error");
      }
    } catch (error) {
      console.error("confirm Request error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    } finally {
      setDoubleConfirmLoading(null);
    }
  };

  const handleReject = async (bookingId) => {
    setRejectLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/decline/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "Confirmation rejected!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_DECLINED" }
              : booking
          )
        );
      } else {
        console.error(
          "Error rejecting confirmation:",
          response.status,
          response.data
        );

        Swal.fire("Error", "An error occurred while rejecting the confirmation.", "error");
      }
    } catch (error) {
      console.error("reject confirmation error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    } finally {
      setRejectLoading(null);
    }
  };

  const handleCancelContract = async (bookingId) => {
    setCancelContractLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-request/contract/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "contract cancellation request sent!",
          title: "Success!",
          text: "successful!",
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: "PERFORMER_CANCELLED_CONTRACT" }
              : booking
          )
        );
      } else {
        console.error(
          "Error cancelling the contract:",
          response.status,
          response.data
        );

        Swal.fire("Error", "An error occurred while canceling the contract.", "error");
      }
    } catch (error) {
      console.error("cancel contract error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    } finally {
      setCancelContractLoading(null);
    }
  };

  // const handleTitleClick = (opportunityId) => {
  //     navigate(`/performer/opportunity/${opportunityId}`);
  // };

  const handleBookingTitleClick = (bookingId) => {
    openModal2(bookingId);
  };

  const openModal2 = async (id) => {
    console.log("selected booking id", id);
    setSelectedbookingId(id);
    setShowModal2(true);

    setModalContent2(<DirectBookingPerformerView bookingId={id} />);
  };

  const closeModal2 = () => {
    setSelectedbookingId(null);
    setShowModal2(false);
  };

  const handleTitleClick = (opportunityId, authorProfileId) => {
    openModal(opportunityId, authorProfileId);
  };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    Cookies.set("opportunityId", id);
    console.log("opportunityId cookie value:", Cookies.get("opportunityId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opportunity details debugging:", opportunity.opportunity);
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(profileId);
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
    }
  };

  const closeModal = () => {
    setSelectedOpportunityId(null);
    setShowModal(false);
  };

  const StyledHeading = styled.h2`
    color: #1a1a1a;
    font-size: 2.5em;
    margin-bottom: 0;
    margin-right: 20px;
  `;

  const handleConfirm = async (bookingId) => {
    setConfirmLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/confirm/by/performer",
        {
          direct_booking_id: bookingId,
          performer_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        // Swal.fire("Booking confirmed!", "success");
        Swal.fire({
          title: "Success!",
          text: "Your booking has been confirmed!",
          icon: "success",
          className: "swal-modal",
        });
        setDirectBookings((prevBookings) =>
          prevBookings.map((bookings_direct) =>
            bookings_direct._id === bookingId
              ? { ...bookings_direct, status: "CONFIRMED" }
              : bookings_direct
          )
        );
      } else {
        console.error(
          "Error confirming request:",
          response.status,
          response.data
        );
        Swal.fire({
          title: "Error",
          text: "An error occurred while confirming your request.",
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    } catch (error) {
      console.error("Confirm Request error:", error);
      let errorMessage = "An error occurred while confirming your request.";

      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // No response received
        errorMessage = "No response from server.";
      } else {
        // Other errors
        errorMessage = `Request error: ${error.message}`;
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setConfirmLoading(null);
    }
  };

  const handleDecline = async (bookingId) => {
    setDeclineLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/decline/by/performer",
        {
          direct_booking_id: bookingId,
          performer_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Your booking has been declined.",
          icon: "success",
          className: "swal-modal",
        });
        setDirectBookings((prevBookings) =>
          prevBookings.map((bookings_direct) =>
            bookings_direct._id === bookingId
              ? { ...bookings_direct, status: "PERFORMER_DECLINED" }
              : bookings_direct
          )
        );
        // .then(() => {
        //   navigate(`/performer/${profileId}`);
        // });
      } else {
        console.error(
          "Error declining request:",
          response.status,
          response.data
        );
        Swal.fire({
          title: "Error",
          text: "An error occurred while declining the request.",
          icon: "error",
          declineButtonText: "Retry",
        });
      }
    } catch (error) {
      console.error("decline Request error:", error);
      let errorMessage = "An error occurred while declining your request.";

      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // No response received
        errorMessage = "No response from server. ";
      } else {
        // Other errors
        errorMessage = `Request error: ${error.message}`;
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        declineButtonText: "OK",
      });
    } finally {
      setDeclineLoading(null);
    }
  };

  const handleRequestCancellation = async (bookingId) => {
    setCancelRequestLoading(bookingId);
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/cancellation/by/performer",
        {
          direct_booking_id: bookingId,
          performer_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("The cancellation request has been sent.", "success");
        Swal.fire({
          title: "Success!",
          text: "The cancellation request has been sent.",
          icon: "success",
          className: "swal-modal",
        });
        setDirectBookings((prevBookings) =>
          prevBookings.map((bookings_direct) =>
            bookings_direct._id === bookingId
              ? {
                  ...bookings_direct,
                  status: "CANCELLATION_REQUEST_BY_PERFORMER",
                }
              : bookings_direct
          )
        );
      } else {
        console.error("Error sending request:", response.status, response.data);
        Swal.fire({
          title: "Error",
          text: "An error occurred while sending the request.",
          icon: "error",
          sendButtonText: "Retry",
        });
      }
    } catch (error) {
      console.error("send Request error:", error);
      let errorMessage = "An error occurred while declining your request.";

      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // No response received
        errorMessage = "No response from server. ";
      } else {
        // Other errors
        errorMessage = `Request error: ${error.message}`;
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        declineButtonText: "OK",
      });
    } finally {
      setCancelRequestLoading(null);
    }
  };

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  // const formatDateTime = (date) => {
  //   const localDate = convertUTCToLocal(new Date(date));
  //   return localDate.toLocaleString();
  // };

  const formatDateTime = (date) => {
    const localDate = convertUTCToLocal(new Date(date));
    const day = String(localDate.getDate()).padStart(2, "0");
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const year = localDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <NavigationBar />
      <div className="dash-container">
        <div className="dash-image-container">
          <img src={pic} alt="Venue" className="dash-venue-image" />
        </div>
        <h2>Applied Posts</h2>

        <>
          <div className="dash-card-container">
            {bookings.map((booking, index) => (
              <div className="dash-card" key={index}>
                {/* <div>{formatDateTime(booking.from_date_time_utc)}</div> */}
                <div>
                  {formatDateRange(
                    booking.from_date,
                    booking.from_time,
                    booking.to_date,
                    booking.to_time
                  )}
                </div>
                <h6
                  className="dash-post-title"
                  // onClick={() => handleTitleClick(booking.opportunity_id)}
                  onClick={() =>
                    handleTitleClick(
                      booking.opportunity_id,
                      booking.author_profile_id
                    )
                  }
                >
                  {booking.title}
                </h6>

                {/* <span className="dash-applied-category-label">
                  {getStatusLabel(booking.status)}
                </span> */}
                {/* <span className="dash-applied-category-label">
                  {getStatusLabel(booking.status.replace(/_/g, " "))}
                  
                </span> */}
                <span
                  // className={`badge ${
                  //   getStatusLabel(booking.status).badgeClass
                  // }`}
                  className={`booking-status ${booking.status.toLowerCase()}`}
                >
                  <strong>Opportunity Post Status:</strong>{" "}
                  {booking.status === "PERFORMER_APPLIED" &&
                  booking.opportunityStatus === "CONFIRMED"
                    ? "OPPORTUNITY CONFIRMED WITH ANOTHER PERFORMER"
                    : getStatusLabel(booking.status.replace(/_/g, " ")).label}
                </span>
                {/* <span
                  className={`badge ${
                    getStatusLabel(booking.status).badgeClass
                  }`}
                >
                  {booking.status === "PERFORMER_APPLIED" &&
                  booking.opportunityStatus === "CONFIRMED"
                    ? "OPPORTUNITY CONFIRMED WITH ANOTHER PERFORMER"
                    : getStatusLabel(booking.status.replace(/_/g, " ")).label}
                </span> */}
                {/* <span
                  className={`badge ${
                    getStatusLabel(booking.status).badgeClass
                  }`}
                >
                  {getStatusLabel(booking.status.replace(/_/g, " ")).label}
                </span> */}
                <br></br>
                <div>
                  {booking.status === "VENUE_CONFIRMED" ? (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => handleDoubleConfirm(booking._id)}
                        style={{ cursor: doubleConfirmLoading === booking._id ? "not-allowed" : "pointer", marginTop: "10px", minWidth: "180px", minHeight: "10px", }}
                        disabled={doubleConfirmLoading === booking._id}
                      >
                        {doubleConfirmLoading === booking._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Double Confirm"
                        )}
                      </Button>
                      {/* <span
                        className={getStatusLabel(
                          booking.status.replace("_", " ")
                        )}
                      >                        
                      </span> */}
                      <Button
                        variant="danger"
                        className="ms-2"
                        onClick={() => handleReject(booking._id)}
                        style={{ cursor: rejectLoading === booking._id ? "not-allowed" : "pointer", marginTop: "10px", marginLeft: "8px", minWidth: "100px", minHeight: "20px", }}
                        disabled={rejectLoading === booking._id}
                      >
                        {rejectLoading === booking._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Reject"
                        )}
                      </Button>
                    </>
                  ) : booking.status === "PERFORMER_DOUBLED_CONFIRMED" ? (
                    <>
                      {/* <span
                        className={getStatusLabel(
                          booking.status.replace(/_/g, " ")
                        )}
                      >
                        
                      </span> */}

                      {/* <span
                        className={`badge ${
                          getStatusLabel(booking.status).badgeClass
                        }`}
                      >
                        {
                          getStatusLabel(booking.status.replace(/_/g, " "))
                            .label
                        }
                      </span> */}
                      <br></br>
                      <Button
                        variant="warning"
                        className="ms-2"
                        onClick={() => handleCancelContract(booking._id)}
                        style={{ cursor: cancelContractLoading === booking._id ? "not-allowed" : "pointer", marginTop: "10px", marginLeft: "8px", minWidth: "190px", minHeight: "-10px", }}
                        disabled={cancelContractLoading === booking._id}
                      >
                        {cancelContractLoading === booking._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Cancel Contract"
                        )}
                      </Button>
                    </>
                  ) : null}
                  {/* ) : (
                    <span
                      className={`badge ${getStatusBadgeVariant(
                        booking.status
                      )}`}
                    >
                      {booking.status.replace("_", " ")}
                    </span>
                  )} */}
                </div>

                <a
                  href="#"
                  className="dash-view-more"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTitleClick(booking.opportunity_id);
                  }}
                >
                  View More →
                </a>
              </div>
            ))}
            {bookings.length > 0 && (
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-primary me-3"
                  onClick={handleOpportunitiesPrevious}
                  disabled={!opportunitiesPreviousUrl || opploadingPrevious}
                  style={{
                    opacity: opploadingPrevious || !opportunitiesPreviousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                    cursor: opploadingPrevious || !opportunitiesPreviousUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                    transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    minWidth: "100px",
                  }}
                >
                  {opploadingPrevious ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Previous"
                  )}

                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleOpportunitiesNext}
                  disabled={!opportunitiesNextUrl || opploadingNext}
                  style={{
                    opacity: opploadingNext || !opportunitiesNextUrl ? 0.6 : 1,
                    cursor: opploadingNext || !opportunitiesNextUrl ? "not-allowed" : "pointer",
                    transition: "opacity 0.3s ease",
                    minWidth: "100px",
                    marginRight: "180px",
                  }}
                >
                  {opploadingNext ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Next"
                  )}

                </button>
              </div>
            )}

            {directBookings.map((bookings_direct, index) => (
              <div
                style={{ backgroundColor: "#E8E8E8" }}
                className="dash-card"
                key={index}
              >
                {/* <div>{formatDateTime(bookings_direct.from_date_time_utc)}</div> */}
                <div>
                  {formatDateRange(
                    bookings_direct.from_date,
                    bookings_direct.from_time,
                    bookings_direct.to_date,
                    bookings_direct.to_time
                  )}
                </div>
                <h6
                  className="dash-post-title"
                  // onClick={() => handleTitleClick(bookings_direct.opportunity_id)}

                  // onClick={() => handleBookingTitleClick(bookings_direct._id)}
                  onClick={() => handleBookingTitleClick(bookings_direct._id)}
                >
                  {bookings_direct.title}
                </h6>

                {/* <span className="dash-applied-category-label">
                  {getStatusLabel(bookings_direct.status.replace(/_/g, " "))}
                </span> */}

                {/* <span
                  className={`badge ${
                    getStatusLabel(bookings_direct.status).badgeClass
                  }`}
                >
                  {
                    getStatusLabel(bookings_direct.status.replace(/_/g, " "))
                      .label
                  }
                </span>
                <br></br> */}
                <div
                  className={`booking-status ${bookings_direct.status.toLowerCase()}`}
                >
                  <strong>Booking Status:</strong>{" "}
                  {bookings_direct.status.replace(/_/g, " ")}
                </div>
                <div>
                  {bookings_direct.status === "VENUE_REQUESTED" ? (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => handleConfirm(bookings_direct._id)}
                        style={{ cursor: confirmLoading === bookings_direct._id ? "not-allowed" : "pointer", marginTop: "10px", borderRadius: "5px", minWidth: "100px", minHeight: "20px", }}
                        disabled={confirmLoading === bookings_direct._id}
                      >
                        {confirmLoading === bookings_direct._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => handleDecline(bookings_direct._id)}
                        style={{
                          cursor: declineLoading === bookings_direct._id ? "not-allowed" : "pointer",
                          marginTop: "10px",
                          marginLeft: "8px",
                          borderRadius: "5px",
                          minWidth: "100px",
                          minHeight: "20px",
                        }}
                        disabled={declineLoading === bookings_direct._id}
                      >
                        {declineLoading === bookings_direct._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Decline"
                        )}


                      </Button>
                    </>
                  ) : bookings_direct.status === "CONFIRMED" ? (
                    <>
                      {/* <span
                        className={getStatusLabel(
                          bookings_direct.status.replace(/_/g, " ")
                        )}
                      >                        
                      </span> */}

                      {/* <span className={`badge ${getStatusLabel(bookings_direct.status).badgeClass}`}>
                        {getStatusLabel(bookings_direct.status.replace(/_/g, " ")).label}
                      </span> */}

                      <Button
                        variant="warning"
                        // className="ms-2"
                        onClick={() =>
                          handleRequestCancellation(bookings_direct._id)
                        }
                        style={{
                          cursor: cancelRequestLoading === bookings_direct._id ? "not-allowed" : "pointer",
                          marginTop: "10px",
                          // marginLeft: "8px",
                          borderRadius: "5px",
                          minWidth: "300px",
                          minHeight: "20px",
                        }}
                        disabled={cancelRequestLoading === bookings_direct._id}
                      >
                        {cancelRequestLoading === bookings_direct._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Request for cancellation"
                        )}
                      </Button>
                    </>
                  ) : null}
                  {/* ) : (
                    <span
                    //   className={`badge ${getStatusBadgeVariant(
                    //     bookings_direct.status
                    //   )}`}
                    >
                      {bookings_direct.status.replace("_", " ")}
                    </span>
                  )} */}
                </div>

                <a
                  href="#"
                  className="dash-view-more"
                  //   onClick={(e) => {
                  //     e.preventDefault();
                  //     handleTitleClick(bookings_direct.opportunity_id);
                  //   }}
                  // onClick={() => handleBookingTitleClick(bookings_direct._id)}
                  onClick={(e) => {
                    e.preventDefault();
                    handleBookingTitleClick(bookings_direct._id);
                  }}
                >
                  View More →
                </a>
              </div>
            ))}
            {directBookings.length > 0 && (
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-primary me-3"
                  onClick={handleBookingsPrevious}
                  disabled={!bookingsPreviousUrl || loadingPrevious}
                  style={{
                    opacity: loadingPrevious || !bookingsPreviousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                    cursor: loadingPrevious || !bookingsPreviousUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                    transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    minWidth: "100px",
                  }}
                >
                  {loadingPrevious ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Previous"
                  )}

                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleBookingsNext}
                  disabled={!bookingsNextUrl || loadingNext}
                  style={{
                    opacity: loadingNext || !bookingsNextUrl ? 0.6 : 1,
                    cursor: loadingNext || !bookingsNextUrl ? "not-allowed" : "pointer",
                    transition: "opacity 0.3s ease",
                    minWidth: "100px",
                    marginRight: "180px",
                  }}
                >
                  {loadingNext ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Next"
                  )}

                </button>
              </div>
            )}

            {/* <Modal
              show={showModal}
              onHide={closeModal}
              size="xl"
              // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div>{modalContent}</div>
              </Modal.Body>
            </Modal> */}
            {/* <Modal
              show={showModal2}
              onHide={closeModal2}
              size="xl"
              // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div>{modalContent2}</div>
              </Modal.Body>
            </Modal> */}
            <Modal
              show={showModal2}
              onHide={closeModal2}
              size="xl"
              // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header>
                <button
                  style={{
                    position: "absolute",
                    // top: "0px",
                    right: "10px",
                    border: "none",
                    background: "transparent",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={closeModal2}
                >
                  &times;
                </button>
              </Modal.Header>
              <Modal.Body>
                {/* Render ViewPerformerProfile component if performer ID is selected */}
                {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                <div>{modalContent2}</div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showModal}
              onHide={closeModal}
              size="xl"
              // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header>
                <button
                  style={{
                    position: "absolute",
                    // top: "0px",
                    right: "10px",
                    border: "none",
                    background: "transparent",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  &times;
                </button>
              </Modal.Header>
              <Modal.Body>
                {/* Render ViewPerformerProfile component if performer ID is selected */}
                {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                <div>{modalContent}</div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      </div>
      <Footer />
    </>
  );
};

export default Tabs;
