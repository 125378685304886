import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import errorImg from "../assect/images/error.png";

// Functional wrapper to inject `navigate` into the class component
function ErrorBoundaryWrapper(props) {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    const accessToken = Cookies.get("accessToken");
    const url = "/v1/auth/create-error-recode";
    const payload = {
      error_message: error.toString(),
      //   error_info: errorInfo.componentStack,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axiosInstance.post(url, payload, { headers });

      console.log("Error reported successfully:", response.data);
      this.props.navigate("/error");
    } catch (err) {
      console.error("Error reporting failed:", err);
    }

    this.props.navigate("/error");
    if (process.env.NODE_ENV === "development") {
      console.error = () => {};
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <section className="position-relative bg-soft-primary">
          <div className="container">
            <div className="row">
              <div className="col-12 p-0">
                <div className="d-flex flex-column min-vh-100 justify-content-center px-md-5 py-5 px-4">
                  <div className="title-heading text-center my-auto">
                    <img src={errorImg} className="img-fluid" alt="" />
                    <h1 className="mb-3 mt-5 text-dark">
                      Oops! Something Went Wrong.
                    </h1>

                    <div className="mt-4">
                      <Link
                        onClick={() => window.location.reload()}
                        className="btn btn-primary"
                      >
                        Reload
                      </Link>
                    </div>
                  </div>
                  <div className="text-center">
                    {/* Optional copyright message can go here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryWrapper;
