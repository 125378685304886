import React, { useState, useEffect } from "react";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import ReactCrop from "react-easy-crop";
// import FullCalendarApp from "./fullCalendar";
import CalendarPublicView from "../../pages/calendar/calendarPublicView";
import CalendarSelfView from "../../pages/calendar/calendarSelfView";
// import MyCalendar from "./calendar";
// import { Calendar } from "primereact/calendar";
import StarRatings from "react-star-ratings";
import { ProgressBar } from "react-bootstrap";
import "../../components/ratings.css";

import {
  Spinner,
  Modal,
  Button,
  Nav,
  Tab,
  Row,
  Col,
  Card,
} from "react-bootstrap";
// import bg3 from "../../assect/images/bgnew2.jpg";
import Navbar from "../../components/navbarSelf";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPlus,
  FaSpotify,
  FaTiktok,
  FaYoutube,
  FaKey,
} from "react-icons/fa";
import {
  FaPhone,
  FaEnvelope,
  FaEdit,
  FaTrash,
  FaEllipsisV,
  FaUpload,
  FaCog,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import youtubeThumbnail from "youtube-thumbnail";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import defaultProfilePicture from "../../assect/images/client/02.jpg";
import { FaUserGroup } from "react-icons/fa6";
import Ratings from "../../components/ratings";
// import Tab from "@mui/material";

export function getVideoId(url) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;
  while ((match = regex.exec(url))) {
    if (match[1] === "v") {
      return match[2];
    }
  }
  return null;
}

export function getVideoThumbnailUrl(videoId) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}
const allowedPerformerSections = [
  "complete",
  "info",
  "contact",
  "social_media",
  "image_gallery",
  "video_gallery",
  "experience",
  "performance_history",
  "equipment_requirements",
];

const HoverImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s; /* Add a smooth transition */

  &:hover {
    transform: scale(1.05); /* Scale up when hovering */
  }
`;

function ViewPerformerProfile() {
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(false);
  const [performerData, setPerformerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [galleryLoading, setGalleryLoading] = useState(false);
  const [galleryPictures, setGalleryPictures] = useState([]);
  const [deletingImageId, setDeletingImageId] = useState(null);
  const [videoGalleryThumbnails, setVideoGalleryThumbnails] = useState([]);
  const { profileId } = useParams();
  const selectedPerformerId = profileId || Cookies.get("profileId");
  const [showImageOptionsModal, setShowImageOptionsModal] = useState(false);
  const [showImageConfirmationModal, setShowImageConfirmationModal] =
    useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showImageGalleryOptionsModal, setShowImageGalleryOptionsModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGalleryOption, setSelectedGalleryOption] = useState(null);
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const [zoomedImageUrl, setZoomedImageUrl] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [hoveredFollow, setHoveredFollow] = useState(false);
  const [clickedFollow, setClickedFollow] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState(false);
  const [clickedMessage, setClickedMessage] = useState(false);
  const [hoveredBook, setHoveredBook] = useState(false);
  const [clickedBook, setClickedBook] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState(null);

  const loggedInUserId = Cookies.get("userId");
  const [authorInfo, setAuthorInfo] = useState({});
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/review/received/by/profile/${selectedPerformerId}`
        );

        setReviews(response.data.reviews_received);
        setRatings(response.data.reviews_summary);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const fetchAuthorInfo = async (authorProfileId) => {
    try {
      const profileType = authorProfileId.startsWith("PROFILE-PERFORMER")
        ? "performer"
        : "venue";
      const response = await axiosInstance.get(
        `/v1/${profileType}/view-profile/${authorProfileId}/section/complete`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching author info:", error);
      return null;
    }
  };

  useEffect(() => {
    reviews.forEach((review) => {
      fetchAuthorInfo(review.author_profile_id).then((authorInfo) => {
        setAuthorInfo((prevAuthorInfo) => ({
          ...prevAuthorInfo,
          [review.author_profile_id]: authorInfo,
        }));
      });
    });
  }, [reviews]);

  const handleEllipsisClick = (imageId) => {
    setSelectedImageId(imageId);
    setShowImageOptionsModal(true);
  };

  const handleDeleteOption = () => {
    setShowImageOptionsModal(false);
    setShowImageConfirmationModal(true);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "deletePicture") {
      setShowConfirmationModal(true);
      setShowOptionsModal(false);
    } else {
      setShowOptionsModal(false);
      if (option === "changePassword") {
        navigate("/auth-change-password");
      }
    }
  };

  const handleCloseModal = () => {
    setShowOptionsModal(false);
    setSelectedOption(null);
  };

  const [date, setDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // const profileIdFromCookies = Cookies.get("profileId");
      const hardcodedPerformerSection = "complete";

      // Validate profileId
      // if (!selectedPerformerId) {
      //   setError("Performer ID not found in URL parameters.");
      //   setLoading(false);
      //   return;
      // }

      // Set profileId obtained from URL parameters in cookies
      // Cookies.set("profileId", profileId);

      try {
        const response = await axiosInstance.get(
          `/v1/performer/view-profile/${selectedPerformerId}/section/${hardcodedPerformerSection}`,
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        if (response.status === 200) {
          const performerInfo = response.data.performer_info;
          setPerformerData(response.data);
          setError(null);
          const profilePictureUrlFromResponse =
            performerInfo?.profile_picture_url;
          if (profilePictureUrlFromResponse) {
            setProfilePictureUrl(profilePictureUrlFromResponse);
            localStorage.setItem(
              "profilePictureUrl",
              profilePictureUrlFromResponse
            );
          } else {
            setProfilePictureUrl(defaultProfilePicture);
          }

          if (
            performerInfo.status === "FRESH" &&
            profilePictureUrlFromResponse
          ) {
            setIsPublishButtonEnabled(true);
          }

          const profileOwnerId = performerInfo.user_id;

          const loggedInUserId = Cookies.get("userId");

          if (profileOwnerId === loggedInUserId) {
            Cookies.set("profileOwnerId", selectedPerformerId);
          }
        } else {
          setError(response.data.error || "Error fetching performer data");
        }
      } catch (error) {
        console.error("Error fetching performer profile:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPerformerId]);

  const handlePublishProfile = async () => {
    try {
      const response = await axiosInstance.post(
        `/v1/performer/publish-profile`,
        {
          performer_id: selectedPerformerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Profile published successfully!");
      } else {
        alert(`API Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      console.error("Error publishing profile:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchGalleryPictures = async () => {
      setGalleryLoading(true);
      try {
        const response = await axiosInstance.get(
          `/v1/performer/view-profile/${selectedPerformerId}/section/image_gallery`,
          {
            headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
          }
        );

        console.log("Gallery pictures response:", response.data);

        if (response.status === 200 && response.data.performer_image_gallery) {
          setGalleryPictures(response.data.performer_image_gallery.links);
        } else {
          console.error(
            "Error fetching gallery pictures: Invalid response format",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching gallery pictures:", error);
      } finally {
        setGalleryLoading(false);
      }
    };

    fetchGalleryPictures();
  }, [selectedPerformerId]);

  const handleUploadProfilePicture = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("performer_id", Cookies.get("profileOwnerId"));

    try {
      const response = await axiosInstance.post(
        "/v1/performer/upload-picture/profile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const imageUrl = response.data.image_url;
        setProfilePictureUrl(imageUrl);
      } else {
        console.error("Error uploading profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      const response = await axiosInstance.post(
        "/v1/performer/delete-picture/profile",
        { performer_id: Cookies.get("profileOwnerId") },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setProfilePictureUrl(defaultProfilePicture);
      } else {
        console.error("Error deleting profile picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting profile picture:", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const openFileInput = () => {
    const fileInput = document.getElementById("galleryPictures");
    fileInput.click();
  };

  const handleUploadGalleryPictures = async (event) => {
    setGalleryLoading(true);
    const files = event.target.files;
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("performer_id", Cookies.get("profileId"));

    try {
      const response = await axiosInstance.post(
        "/v1/performer/upload-picture/gallery",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const uploadedImages = response.data.upload_results.filter(
          (result) => result.status === "SUCCESS"
        );
        setGalleryPictures([...galleryPictures, ...uploadedImages]);
      } else {
        console.error("Error uploading gallery pictures:", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading gallery pictures:", error);
    } finally {
      setGalleryLoading(false);
    }
  };

  const handleDeletePicture = async (imageId) => {
    try {
      setGalleryLoading(true);
      const response = await axiosInstance.post(
        "/v1/performer/delete-picture/gallery",
        {
          performer_id: Cookies.get("profileOwnerId"),
          image_ids: [imageId],
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setGalleryPictures(
          galleryPictures.filter((picture) => picture.image_id !== imageId)
        );
      } else {
        console.error("Error deleting picture:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting picture:", error);
    } finally {
      setGalleryLoading(false);
      setShowImageConfirmationModal(false);
      setSelectedImageId(null);
      // setDeletingImageId(null);
    }
  };

  const showPreviousArrow = currentImageIndex !== null && currentImageIndex > 0;
  const showNextArrow =
    currentImageIndex !== null &&
    currentImageIndex < galleryPictures.length - 1;

  const handlePreviousClick = () => {
    if (showPreviousArrow) {
      setCurrentImageIndex(currentImageIndex - 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex - 1].image_url);
    }
  };

  const handleNextClick = () => {
    if (showNextArrow) {
      setCurrentImageIndex(currentImageIndex + 1);
      setZoomedImageUrl(galleryPictures[currentImageIndex + 1].image_url);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const handleUploadProfilePictureClick = () => {
    const fileInput = document.getElementById("profilePicture");
    if (fileInput) {
      fileInput.click();
    }
  };

  const navigateToChangePassword = () => {
    navigate("/auth-change-password");
  };

  // useEffect(() => {
  //   const processVideoLinks = async () => {
  //     if (!performerData?.performer_video_gallery?.links) return;

  //     const videoLinks = performerData.performer_video_gallery.links.split(",");

  //     const videoElements = videoLinks.map((videoLink) => {
  //       // Extract video ID from the link (assuming specific format)
  //       const videoId = extractVideoIdFromLink(videoLink);

  //       // Assuming YouTube videos, construct the thumbnail URL
  //       const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  //       // Create the iframe element with the video URL
  //       const iframe = document.createElement("iframe");
  //       iframe.src = videoLink;
  //       iframe.width = "200";
  //       iframe.height = "150";
  //       iframe.frameBorder = "0";
  //       iframe.allowFullScreen = true;

  //       // Create a container element to hold the thumbnail and iframe
  //       const container = document.createElement("div");
  //       container.style.display = "inline-block";
  //       container.style.marginRight = "10px";

  //       // Create an image element to display the thumbnail
  //       const thumbnailImage = document.createElement("img");
  //       thumbnailImage.src = thumbnailUrl;
  //       thumbnailImage.alt = "Video Thumbnail";
  //       thumbnailImage.style.width = "100%";
  //       thumbnailImage.style.cursor = "pointer";

  //       thumbnailImage.addEventListener("click", () => {
  //         iframe.style.display = "block";
  //       });

  //       container.appendChild(thumbnailImage);
  //       container.appendChild(iframe);
  //       iframe.style.display = "none"; // Hide iframe initially

  //       return container;
  //     });
  //   };

  //   processVideoLinks();
  // }, [performerData]);

  const extractVideoIdFromLink = (link) => {
    const youtubeMatch = link.match(
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    if (youtubeMatch) {
      return youtubeMatch[1];
    }

    console.warn("Unsupported video link format:", link);
    return "";
  };

  const navigatetoallprofiles = () => {
    navigate("/all-profiles");
  };
  const performer = performerData.performer_info;

  // const isVenue = (profileOwnerId) => {
  //   // Assuming profile IDs have a specific format or a method to check the type
  //   return profileOwnerId.startsWith("PROFILE-VENUE");
  // };

  // const shouldShowButton = profileOwnerId !== performer.user_id && isVenue(profileOwnerId);

  const profileOwnerId = Cookies.get("profileOwnerId");

  const navigatetomessaging = () => {
    const profile_id = profileOwnerId;
    const toProfileId = Cookies.get("profileId");

    axiosInstance
      .post(
        `/v1/messaging/create_conversation/${profile_id}/${toProfileId}`,
        { title: "" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const conversation_id = response.data.conversation_id;
        navigate(`/messaging/${conversation_id}`);
      })
      .catch((error) => {
        console.error("Error creating conversation:", error);
      });
  };
  // const navigatetomessaging = () => {
  //   navigate("/messaging", {
  //     state: {
  //       profileId: Cookies.get("profileOwnerId"),
  //       toProfileId: Cookies.get("profileId"),
  //     },
  //   });
  // };

  const handleFollowClick = () => {
    setIsFollowed(!isFollowed);
  };

  return (
    <>
      <style>
        {`
      body {
        background-color: #102733;
      }
    `}
      </style>
      {/* <Navbar
        navClass="defaultscroll sticky"
        menuClass="navigation-menu nav-left"
      /> */}
      <div
        style={{
          position: "absolute",
          right: "15%",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "row",
          gap: "10%",
          zIndex: "1",
        }}
      >
        {performerData.performer_info &&
          performerData.performer_info.user_id === loggedInUserId && (
            <Button
              style={{
                borderRadius: "12px",
                fontSize: "15px",
                backgroundColor: clickedFollow ? "#418abb" : "#88a1bb",
                border: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "white",
              }}
              variant={hoveredFollow || clickedFollow ? "secondary" : "primary"}
              onMouseEnter={() => setHoveredFollow(true)}
              onMouseLeave={() => setHoveredFollow(false)}
              onMouseDown={() => setHoveredFollow(true)}
              onMouseUp={handleFollowClick}
            >
              {isFollowed ? "Unfollow" : "Follow"}
            </Button>
          )}
        {performerData.performer_info &&
          performerData.performer_info.user_id === loggedInUserId && (
            <Button
              style={{
                borderRadius: "12px",
                fontSize: "15px",
                backgroundColor: clickedMessage ? "#418abb" : "#88a1bb",
                border: "none",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "white",
              }}
              variant={
                hoveredMessage || clickedMessage ? "secondary" : "primary"
              }
              onMouseEnter={() => setHoveredMessage(true)}
              onMouseLeave={() => setHoveredMessage(false)}
              onMouseDown={() => setClickedMessage(true)}
              onMouseUp={() => setClickedMessage(false)}
              onClick={navigatetomessaging}
            >
              Message
            </Button>
          )}

        <Button
          style={{
            borderRadius: "100px",
            backgroundColor: "#16293a",
            border: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          onClick={navigatetoallprofiles}
        >
          <FaUserGroup />
        </Button>
      </div>
      <Card
        style={{
          position: "relative",
          backgroundColor: "#88a1bb",
          padding: "3%",
          borderRadius: "15px",
          overflow: "hidden",
          marginRight: "4%",
          marginLeft: "4%",
          marginTop: "5%",
          marginBottom: "1%",
        }}
      >
        <Row className="justify-content-between">
          <Col
            xs={12}
            md={{ span: 3, order: 1, offset: 0 }}
            // className="custom-left-position"
            className="custom-left-position text-center text-md-left"
          >
            <div
              onClick={
                performerData.performer_info &&
                performerData.performer_info.user_id === loggedInUserId
                  ? () => setShowOptionsModal(true)
                  : null
              }
              // onClick={() => setShowOptionsModal(true)}
              style={{
                padding: "10px",
                borderRadius: "50%",
                border: "5px solid white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : profilePictureUrl ? (
                <img
                  src={profilePictureUrl}
                  alt="Profile Picture"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={defaultProfilePicture}
                  alt="default profile Picture"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>

            <br></br>
            <h1 className="my-custom-title" style={{ textAlign: "center" }}>
              {performerData.performer_info?.first_name}{" "}
              <a href={"/update-performer-firstName"}>
                {performerData.performer_info &&
                  performerData.performer_info.user_id === loggedInUserId && (
                    <FaEdit
                      size={20}
                      style={{ marginRight: "10px", color: "#418abb" }}
                    />
                  )}
              </a>
              {performerData.performer_info?.last_name}{" "}
              {/* <a href={"#"} onClick={() => setShowOptionsModal(true)}>
                    <FaCog
                      size={20}
                      style={{ marginRight: "10px", color: "black" }}
                    />
                  </a> */}
            </h1>

            <Modal
              size="sm"
              centered
              show={showOptionsModal}
              onHide={handleCloseModal}
            >
              {/* <Modal.Header className="text-center" closeButton>
                    <Modal.Title className="text-center">Options</Modal.Title>
                  </Modal.Header> */}
              <Modal.Body className="text-center">
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>
                    <p
                      onClick={() => handleUploadProfilePictureClick()} // Call function to trigger file input click event
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Upload Profile Picture
                    </p>
                    <input
                      type="file"
                      id="profilePicture"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleUploadProfilePicture}
                    />
                    <p
                      onClick={() => handleOptionClick("deletePicture")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Delete Profile Picture
                    </p>
                    {/* <p
                      onClick={() => handleOptionClick("changePassword")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Change Password
                    </p> */}
                  </>
                )}
              </Modal.Body>
              {/* <Modal.Body className="text-center">
                    <p
                      onClick={() => handleOptionClick("deletePicture")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Delete Profile Picture
                    </p>
                    <p
                      onClick={() => handleOptionClick("changePassword")}
                      className="text-dark fw-medium"
                      style={{ cursor: "pointer" }}
                    >
                      Change Password
                    </p>
                  </Modal.Body> */}
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <Button variant="primary" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showConfirmationModal}
              onHide={() => setShowConfirmationModal(false)}
              size="sm"
              centered
            >
              <Modal.Header className="text-center" closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                Are you sure you want to delete your profile picture?
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  onClick={() => setShowConfirmationModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteProfilePicture}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col
            xs={12}
            md={{ span: 4, order: 2, offset: 1 }}
            style={{ textAlign: "center" }}
          >
            <div style={{ marginTop: "7%", textAlign: "left" }}>
              <br></br>

              {/* <p>
                <FaPhone size={20} style={{ marginRight: "10px" }} />
                {
                  performerData.performer_contact?.display_phone.phone_number
                }{" "}
                <br />
                <br></br>
                <FaEnvelope size={20} style={{ marginRight: "10px" }} />
                {performerData.performer_contact?.display_email.email}
                <span style={{ marginRight: "10px" }}></span> */}

              <Button
                style={{
                  borderRadius: "12px",
                  fontSize: "15px",
                  backgroundColor: isPublishButtonEnabled
                    ? "#418abb"
                    : "#88a1bb",
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "white",
                  cursor: isPublishButtonEnabled ? "pointer" : "not-allowed",
                }}
                disabled={!isPublishButtonEnabled}
                onClick={handlePublishProfile}
              >
                Publish
              </Button>
              <br></br>
              <br></br>
              <p>
                {performerData && performerData.performer_contact ? (
                  <>
                    <FaPhone size={20} style={{ marginRight: "10px" }} />
                    {performerData.performer_contact.display_phone.phone_number}
                  </>
                ) : null}{" "}
                <br />
                <br></br>
                {performerData && performerData.performer_contact ? (
                  <>
                    <FaEnvelope size={20} style={{ marginRight: "10px" }} />
                    {performerData.performer_contact.display_email.email}
                    <span style={{ marginRight: "10px" }}></span>
                  </>
                ) : null}
                {/* <a href={"/update-performer-contact"}>
                  <FaEdit
                    size={20}
                    style={{ marginRight: "10px", color: "black" }}
                  />
                </a> */}
                <a href={"/update-performer-contact"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "black" }}
                      />
                    )}
                </a>
              </p>
              <p>
                {performerData.performer_social_media?.FACEBOOK && (
                  <a href={performerData.performer_social_media.FACEBOOK}>
                    <FaFacebook size={28} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {/* {performerData.performer_social_media?.FACEBOOK && (
                      <a href={performerData.performer_social_media.FACEBOOK}>
                        <FaInstagram
                          size={28}
                          style={{ marginRight: "10px", color: "#e44b61" }}
                        />
                      </a>
                    )}
                    {performerData.performer_social_media?.FACEBOOK && (
                      <a href={performerData.performer_social_media.FACEBOOK}>
                        <FaLinkedin size={28} style={{ marginRight: "10px" }} />
                      </a>
                    )} */}
                {performerData.performer_social_media?.INSTAGRAM && (
                  <a href={performerData.performer_social_media.INSTAGRAM}>
                    <FaInstagram
                      size={20}
                      style={{ marginRight: "10px", color: "#e44b61" }}
                    />
                  </a>
                )}
                {performerData.performer_social_media?.LINKEDIN && (
                  <a href={performerData.performer_social_media.LINKEDIN}>
                    <FaLinkedin size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {performerData.performer_social_media?.SPOTIFY && (
                  <a href={performerData.performer_social_media.SPOTIFY}>
                    <FaSpotify size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {performerData.performer_social_media?.TIKTOK && (
                  <a href={performerData.performer_social_media.TIKTOK}>
                    <FaTiktok size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                {performerData.performer_social_media?.YOUTUBE && (
                  <a href={performerData.performer_social_media.YOUTUBE}>
                    <FaYoutube size={20} style={{ marginRight: "10px" }} />
                  </a>
                )}
                <span style={{ marginRight: "10px" }}></span>
                <a href={"/update-performer-socialmedia"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "black" }}
                      />
                    )}
                </a>
              </p>
              {/* <Button
                variant="outline-primary"
                onClick={navigateToChangePassword}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  fontSize: "15px",
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "white",
                  backgroundColor: "#418abb",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#ff66c4";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#418abb";
                }}
              >
                <FaKey style={{ marginRight: "5px" }} />
                Change Password
              </Button> */}
            </div>
          </Col>
          <Col
            xs={12}
            md={{ span: 4, order: 3, offset: 0 }}
            className="text-center text-md-right"
          >
            <div
              style={{ textAlign: "left", marginTop: "3%", marginRight: "10%" }}
            >
              <h4 style={{ color: "black" }}>Public Info</h4>
              {/* <div
                      style={{
                        paddingLeft: "3%",
                        paddingTop: "2%",
                        paddingBottom: "0%",
                      }}
                    ></div> */}
              {/* <Card
                  className="shadow-lg"
                  style={{ borderRadius: "15px", marginBottom: "-2%" }}
                > */}

              <p>
                Performer type:{" "}
                {performerData.performer_info?.performer_types
                  ? performerData.performer_info?.performer_types.join(", ")
                  : "Not specified"}
                <span style={{ marginRight: "10px" }}></span>
                <a href={"/update-performer-type"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "#418abb" }}
                      />
                    )}
                </a>
              </p>

              {/* <Card
                  className="shadow-lg"
                  style={{ borderRadius: "15px", marginBottom: "-2%" }}
                > */}

              <p>
                Genres:{" "}
                {performerData.performer_info?.performer_genres
                  ? performerData.performer_info?.performer_genres.join(", ")
                  : "Not specified"}
                <span style={{ marginRight: "10px" }}></span>
                <a href={"/update-performer-genre"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "#418abb" }}
                      />
                    )}
                </a>
              </p>

              {/* <Card
                  className="shadow-lg"
                  style={{ borderRadius: "15px", marginBottom: "-2%" }}
                > */}

              <p>
                Price point range: AUD{" "}
                {performerData.performer_info?.price_point_value}
                <a href={"/update-performer-pricepoint"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "#418abb" }}
                      />
                    )}
                </a>
              </p>

              <p>
                location: {performerData.performer_contact?.location?.address}
                <a href={"/update-performer-location"}>
                  {performerData.performer_info &&
                    performerData.performer_info.user_id === loggedInUserId && (
                      <FaEdit
                        size={20}
                        style={{ marginRight: "10px", color: "#418abb" }}
                      />
                    )}
                </a>
              </p>

              {/* <MyCalendar /> */}
              {/* <Calendar
                localizer={localizer}
                events={[]}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: "260px",
                  width: "100%",
                  border: "1px solid #000",
                  borderRadius: "10px",
                  backgroundColor: "#000",
                  color: "#fff",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  fontSize: "0.9vw",
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: "#418abb",
                    color: "white",
                    borderRadius: "0px",
                    border: "none",
                  };

                  return {
                    style: newStyle,
                  };
                }}
                dayPropGetter={() => ({
                  style: {
                    backgroundColor: "transparent",
                  },
                })}
              /> */}
            </div>
          </Col>
        </Row>
      </Card>

      <Row>
        <div style={{ paddingLeft: "4.8%", paddingRight: "4.8%" }}>
          <Card
            style={{
              borderRadius: "15px",
              backgroundColor: "#e2e2e2",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card.Body>
              <div>
                {/* <h5>Bio</h5> */}
                <p>
                  <strong>Bio: </strong> {""}
                  {performerData.performer_experience?.brief_bio}
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>

      <div style={{ paddingLeft: "3.6%", paddingRight: "3.6%" }}>
        <div className="container-fluid">
          <Row>
            <Col xs={12} md={5} className="order-md-1">
              <Card
                className="shadow-lg"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#e2e2e2",
                  marginTop: "3%",
                  paddingTop: "3%",
                  paddingLeft: "4",
                  paddingRight: "4",
                }}
              >
                <div
                  className="c"
                  style={{
                    paddingLeft: "5%",
                    paddingRight: "5%",
                  }}
                >
                  <h4 style={{ textAlign: "left", color: "black" }}>Ratings</h4>
                  <div className="c-rating">
                    <div className="ratings">
                      <StarRatings
                        rating={ratings?.starts_average}
                        starRatedColor="#FCCC4D"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                        starSpacing="10px"
                      />
                    </div>
                    <div className="c-rating-text">
                      {ratings && ratings?.starts_average
                        ? ratings?.starts_average
                        : "0"}{" "}
                      out of 5
                    </div>
                  </div>
                  <p className="rating-count">
                    {ratings && ratings?.stars_total
                      ? ratings?.stars_total
                      : "0"}{" "}
                    customer ratings
                  </p>
                  <div className="rating-percents">
                    <div className="rating-percent">
                      <span className="rating-no">5 star</span>
                      <ProgressBar
                        now={
                          (Number(ratings?.stars_5) /
                            (Number(ratings?.stars_total) || 1)) *
                          100
                        }
                        className="rating-progress"
                      />
                      <span className="rating-percent-no">
                        {ratings && ratings?.stars_total
                          ? Math.round(
                              (Number(ratings?.stars_5) /
                                Number(ratings?.stars_total)) *
                                100
                            )
                          : "0"}
                        %
                      </span>
                    </div>
                    <div className="rating-percent">
                      <span className="rating-no">4 star</span>
                      <ProgressBar
                        now={
                          (Number(ratings?.stars_4) /
                            (Number(ratings?.stars_total) || 1)) *
                          100
                        }
                        className="rating-progress"
                      />
                      <span className="rating-percent-no">
                        {ratings && ratings?.stars_total
                          ? Math.round(
                              (Number(ratings?.stars_4) /
                                Number(ratings?.stars_total)) *
                                100
                            )
                          : "0"}
                        %
                      </span>
                    </div>
                    <div className="rating-percent">
                      <span className="rating-no">3 star</span>
                      <ProgressBar
                        now={
                          (Number(ratings?.stars_3) /
                            (Number(ratings?.stars_total) || 1)) *
                          100
                        }
                        className="rating-progress"
                      />
                      <span className="rating-percent-no">
                        {ratings && ratings?.stars_total
                          ? Math.round(
                              (Number(ratings?.stars_3) /
                                Number(ratings?.stars_total)) *
                                100
                            )
                          : "0"}
                        %
                      </span>
                    </div>
                    <div className="rating-percent">
                      <span className="rating-no">2 star</span>
                      <ProgressBar
                        now={
                          (Number(ratings?.stars_2) /
                            (Number(ratings?.stars_total) || 1)) *
                          100
                        }
                        className="rating-progress"
                      />
                      <span className="rating-percent-no">
                        {ratings && ratings?.stars_total
                          ? Math.round(
                              (Number(ratings?.stars_2) /
                                Number(ratings?.stars_total)) *
                                100
                            )
                          : "0"}
                        %
                      </span>
                    </div>
                    <div className="rating-percent">
                      <span className="rating-no">1 star</span>
                      <ProgressBar
                        now={
                          (Number(ratings?.stars_1) /
                            (Number(ratings?.stars_total) || 1)) *
                          100
                        }
                        className="rating-progress"
                      />
                      <span className="rating-percent-no">
                        {ratings && ratings?.stars_total
                          ? Math.round(
                              (Number(ratings?.stars_1) /
                                Number(ratings?.stars_total)) *
                                100
                            )
                          : "0"}
                        %
                      </span>
                    </div>
                  </div>
                  <p className="c-footer-text">How do we calculate ratings?</p>
                </div>
                {/* <Ratings /> */}
              </Card>
              {/* <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", backgroundColor: "#e2e2e2" }}
              >
                <div style={{ padding: "5%" }}>
                  <div
                    style={{
                      paddingLeft: "3%",
                      paddingRight: "3%",
                    }}
                  >
                    <h4 style={{ textAlign: "left", color: "black" }}>
                      Public Info
                    </h4>
                    <p>Performer type: </p>


                    <p>
                      Genres:{" "}
                      {performerData.performer_info?.genres
                        ? performerData.performer_info?.genres.join(", ")
                        : "Not specified"}
                      <span style={{ marginRight: "10px" }}></span>
                      <a href={"/update-performer-info"}>
                        {performerData.performer_info &&
                          performerData.performer_info.user_id ===
                            loggedInUserId && (
                            <FaEdit
                              size={20}
                              style={{ marginRight: "10px", color: "#418abb" }}
                            />
                          )}
                      </a>
                    </p>


                    <p>Price point range: </p>
                  </div>
                </div>
              </Card> */}
              <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", marginTop: "3%" }}
              >
                <div style={{ padding: "5%" }}>
                  <h4 style={{ textAlign: "left", color: "black" }}>Reviews</h4>
                  {loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="row">
                      {reviews.map((review, index) => (
                        <div key={index} className="col-md-12 mb-3">
                          <Card
                            style={{
                              backgroundColor: "#e8e8e8",
                              borderRadius: "15px",
                            }}
                          >
                            <Card.Body className="d-flex align-items-center">
                              <div className="col-md-2">
                                <img
                                  src={
                                    review.author_profile_id.startsWith(
                                      "PROFILE-PERFORMER"
                                    )
                                      ? authorInfo[review.author_profile_id]
                                          ?.performer_info.profile_picture_url
                                      : authorInfo[review.author_profile_id]
                                          ?.venue_info.profile_picture_url ||
                                        "https://via.placeholder.com/150"
                                  }
                                  alt="Author"
                                  className="rounded-circle mr-3"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginTop: "-5%",
                                  }}
                                />
                              </div>
                              <div className="col-md-10">
                                <h6>
                                  {review.author_profile_id.startsWith(
                                    "PROFILE-PERFORMER"
                                  )
                                    ? `${
                                        authorInfo[review.author_profile_id]
                                          ?.performer_info.profile_name
                                      }`
                                    : authorInfo[review.author_profile_id]
                                        ?.venue_info.profile_name ||
                                      "Anonymous Reviewer"}
                                </h6>
                                <div className="d-flex align-items-center">
                                  {Array.from({ length: review.stars }).map(
                                    (_, index) => (
                                      <span
                                        key={index}
                                        className="text-warning"
                                      >
                                        &#9733;
                                      </span>
                                    )
                                  )}
                                </div>
                                <p>{review.review}</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
              {/* <Card
                className="shadow-lg"
                style={{ borderRadius: "15px", marginTop: "3%" }}
              >
                <div style={{ padding: "5%" }}>
                  <h4 style={{ textAlign: "left", color: "black" }}>Reviews</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="mb-3"
                        style={{
                          backgroundColor: "#e8e8e8",
                          borderRadius: "15px",
                        }}
                      >
                        <Card.Body className="d-flex align-items-center">
                          <img
                            src="https://via.placeholder.com/150"
                            alt="Reviewer"
                            className="rounded-circle mr-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <div>
                            <h6>John Doe</h6>
                            <div className="d-flex align-items-center">
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9734;</span>
                            </div>
                            <p>
                              "Great performer! Really enjoyed the experience.
                              Would definitely recommend."
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="mb-3"
                        style={{
                          backgroundColor: "#e8e8e8",
                          borderRadius: "15px",
                        }}
                      >
                        <Card.Body className="d-flex align-items-center">
                          <img
                            src="https://via.placeholder.com/150"
                            alt="Reviewer"
                            className="rounded-circle mr-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <div>
                            <h6>Jane Smith</h6>
                            <div className="d-flex align-items-center">
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9733;</span>
                              <span className="text-warning">&#9734;</span>
                              <span className="text-warning">&#9734;</span>
                            </div>
                            <p>
                              "Had a wonderful time! Amazing performance and
                              very talented artist."
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </Card> */}
            </Col>

            <Col
              xs={12}
              md={7}
              className="order-md-2"
              style={{
                marginTop: "1.2%",
              }}
            >
              <Card className="shadow-lg" style={{ borderRadius: "15px" }}>
                <div
                  style={{
                    paddingLeft: "5%",
                    paddingRight: "5%",
                  }}
                >
                  <Tab.Container defaultActiveKey="calendar">
                    <Row className="justify-content-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="video_gallery"
                            style={{ color: "#418abb" }}
                          >
                            Posts
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="calendar"
                            style={{ color: "#418abb" }}
                          >
                            Calendar
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="experience"
                            style={{ color: "#418abb" }}
                          >
                            Experience
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                      <Nav.Link
                        eventKey="performance_history"
                        style={{ color: "#418abb" }}
                      >
                        Performance History
                      </Nav.Link>
                    </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="media"
                            style={{ color: "#418abb" }}
                          >
                            Media
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                      <Nav.Link
                        eventKey="equipment_requirements"
                        style={{ color: "#418abb" }}
                      >
                        Equipment Requirements
                      </Nav.Link>
                    </Nav.Item> */}
                      </Nav>
                    </Row>
                    <Row className="justify-content-center">
                      <Tab.Content>
                        {/* <Tab.Pane eventKey="video_gallery">
                      <br />
                      <div style={{ paddingLeft: "10%" }}>
                        <h4>Video Gallery</h4>
                        <p>
                          Links: {performerData.performer_video_gallery?.links}
                        </p>
                        <div>
                          {performerData.performer_video_gallery &&
                          performerData.performer_video_gallery.links &&
                          Array.isArray(
                            performerData.performer_video_gallery.links
                          ) ? (
                            performerData.performer_video_gallery.links.map(
                              (videoUrl, index) => {
                                // Extract video ID from URL
                                const videoId = getVideoId(videoUrl);
                                // Generate thumbnail URL
                                const thumbnailUrl = videoId
                                  ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
                                  : null;

                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-12"
                                  >
                                    <div className="d-block mb-4 h-100">
                                      {thumbnailUrl && (
                                        <img
                                          className="img-fluid img-thumbnail"
                                          src={thumbnailUrl}
                                          alt=""
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p> </p>
                          )}
                          <Button
                            variant="outline-secondary"
                            style={{
                              backgroundColor: "#418abb",
                              borderColor: "#418abb",
                              color: "white",
                              borderRadius: "15px",
                            }}
                            as={Link}
                            to="/update-performer-videoGallery"
                          >
                            <FaEdit size={20} /> Update Video Gallery
                          </Button>

                        </div>
                      </div>
                    </Tab.Pane> */}

                        {/* <Tab.Pane eventKey="video_gallery">
                <br></br>
                <h4>Video Gallery: </h4>
                <p>Links: {performerData.performer_video_gallery?.links}</p>
                <Link to="/update-performer-videoGallery" className="text-dark fw-medium">
                    Update Video Gallery
                </Link>
            </Tab.Pane> */}
                        <Tab.Pane eventKey="calendar">
                          <div style={{ padding: "2%", marginTop: "3%" }}>
                            {performerData.performer_info &&
                              performerData.performer_info.user_id ===
                                loggedInUserId &&
                              profileOwnerId.startsWith("PROFILE-VENUE") && (
                                <Button
                                  style={{
                                    borderRadius: "12px",
                                    fontSize: "15px",
                                    backgroundColor: clickedBook
                                      ? "#418abb"
                                      : "#88a1bb",
                                    border: "none",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    color: "white",
                                  }}
                                  variant={
                                    hoveredBook || clickedBook
                                      ? "secondary"
                                      : "primary"
                                  }
                                  onMouseEnter={() => setHoveredBook(true)}
                                  onMouseLeave={() => setHoveredBook(false)}
                                  onMouseDown={() => setClickedBook(true)}
                                  onMouseUp={() => setClickedBook(false)}
                                >
                                  Book
                                </Button>
                              )}

                            {performerData.performer_info &&
                            performerData.performer_info.user_id ===
                              loggedInUserId ? (
                              <CalendarSelfView />
                            ) : (
                              <CalendarPublicView />
                            )}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="experience">
                          <br></br>
                          <div
                            style={{
                              paddingLeft: "2%",
                              paddingRight: "2%",
                              paddingBottom: "10%",
                            }}
                          >
                            <Card
                              className="mb-3"
                              style={{
                                backgroundColor: "#e8e8e8",
                                borderRadius: "15px",
                              }}
                            >
                              <Card.Body>
                                <h4>
                                  {performerData.performer_info &&
                                    performerData.performer_info.user_id ===
                                      loggedInUserId && (
                                      <Button
                                        variant="outline-secondary"
                                        style={{
                                          backgroundColor: "white",
                                          border: "none",
                                          color: "#418abb",
                                          borderRadius: "15px",
                                        }}
                                        as={Link}
                                        to="/update-performer-experience"
                                      >
                                        <FaEdit size={20} />
                                      </Button>
                                    )}
                                  Experience
                                </h4>
                                <p>
                                  {/* Bio:{" "}
                              {performerData.performer_experience?.brief_bio}{" "}
                              <br></br> */}
                                  Education:{" "}
                                  {
                                    performerData.performer_experience
                                      ?.education
                                  }
                                  <br></br>
                                  Notable Achievements:{" "}
                                  {
                                    performerData.performer_experience
                                      ?.notable_achievements
                                  }
                                  <br></br>
                                  Skills:{" "}
                                  {
                                    performerData.performer_experience?.skills
                                  }{" "}
                                </p>
                              </Card.Body>
                            </Card>

                            <br></br>

                            <Card
                              className="mb-3"
                              style={{
                                backgroundColor: "#e8e8e8",
                                borderRadius: "15px",
                              }}
                            >
                              <Card.Body>
                                <div>
                                  <h4>
                                    {performerData.performer_info &&
                                      performerData.performer_info.user_id ===
                                        loggedInUserId && (
                                        <Button
                                          variant="outline-secondary"
                                          style={{
                                            backgroundColor: "white",
                                            border: "none",
                                            color: "#418abb",
                                            borderRadius: "15px",
                                          }}
                                          as={Link}
                                          to="/update-performer-history"
                                        >
                                          <FaEdit size={20} />
                                        </Button>
                                      )}
                                    Performance History
                                  </h4>
                                  <p>
                                    collaborations:{" "}
                                    {
                                      performerData
                                        .performer_performance_history
                                        ?.collaborations
                                    }{" "}
                                    <br></br>
                                    Events and festivals:{" "}
                                    {
                                      performerData
                                        .performer_performance_history
                                        ?.events_and_festivals
                                    }{" "}
                                    <br></br>
                                    Past venues:{" "}
                                    {
                                      performerData
                                        .performer_performance_history
                                        ?.past_venues
                                    }{" "}
                                    <br></br>
                                    Recidencies:{" "}
                                    {
                                      performerData
                                        .performer_performance_history
                                        ?.residencies
                                    }
                                  </p>
                                </div>
                              </Card.Body>
                            </Card>
                            {/* <Link
                    to="/update-performer-experience"
                    className="text-dark fw-medium"
                  >
                    Update Experience
                  </Link> */}
                          </div>
                        </Tab.Pane>

                        {/* <Tab.Pane eventKey="performance_history">
                      <br></br>
                      <div style={{ paddingLeft: "10%" }}>
                        <h4>Performance History</h4>
                        <p>
                          collaborations:{" "}
                          {
                            performerData.performer_performance_history
                              ?.collaborations
                          }{" "}
                          <br></br>
                          Events and festivals:{" "}
                          {
                            performerData.performer_performance_history
                              ?.events_and_festivals
                          }{" "}
                          <br></br>
                          Past venues:{" "}
                          {
                            performerData.performer_performance_history
                              ?.past_venues
                          }{" "}
                          <br></br>
                          Recidencies:{" "}
                          {
                            performerData.performer_performance_history
                              ?.residencies
                          }
                        </p>
                        <Button
                          variant="outline-secondary"
                          style={{
                            backgroundColor: "#418abb",
                            borderColor: "#418abb",
                            color: "white",
                            borderRadius: "15px",
                          }}
                          as={Link}
                          to="/update-performer-history"
                        >
                          <FaEdit size={20} /> Update Performance History
                        </Button>
                      </div>
                    </Tab.Pane> */}

                        <Tab.Pane eventKey="media">
                          <br />
                          <Tab.Container defaultActiveKey="video_gallery">
                            <Row className="justify-content-center">
                              <Nav variant="tabs">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="video_gallery"
                                    style={{ color: "#418abb" }}
                                  >
                                    Video Gallery
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="image_gallery"
                                    style={{ color: "#418abb" }}
                                  >
                                    Image Gallery
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Row>
                            <Row className="justify-content-center">
                              <Tab.Content>
                                <Tab.Pane eventKey="video_gallery">
                                  <br />
                                  <div
                                    style={{
                                      paddingLeft: "4%",
                                      paddingRight: "4%",
                                      paddingBottom: "10%",
                                    }}
                                  >
                                    <h4>
                                      {performerData.performer_info &&
                                        performerData.performer_info.user_id ===
                                          loggedInUserId && (
                                          <Button
                                            variant="outline-secondary"
                                            style={{
                                              backgroundColor: "white",
                                              border: "none",
                                              color: "#418abb",
                                              borderRadius: "15px",
                                            }}
                                            as={Link}
                                            to="/update-performer-videoGallery"
                                          >
                                            <FaEdit size={20} />
                                          </Button>
                                        )}
                                      Video Gallery
                                    </h4>
                                    {performerData?.performer_video_gallery
                                      ?.links && (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "repeat(auto-fit, minmax(200px, 1fr))",
                                          gap: "10px",
                                        }}
                                      >
                                        {Array.isArray(
                                          performerData.performer_video_gallery
                                            .links
                                        )
                                          ? performerData.performer_video_gallery.links.map(
                                              (videoLink, index) => {
                                                const videoId =
                                                  extractVideoIdFromLink(
                                                    videoLink
                                                  );
                                                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                                                return (
                                                  <div key={videoLink}>
                                                    <iframe
                                                      src={videoLink}
                                                      width="100%"
                                                      height="150"
                                                      frameBorder="0"
                                                      allowFullScreen
                                                    ></iframe>
                                                    <br />
                                                  </div>
                                                );
                                              }
                                            )
                                          : performerData.performer_video_gallery.links
                                              .split(",")
                                              .map((videoLink, index) => {
                                                const videoId =
                                                  extractVideoIdFromLink(
                                                    videoLink
                                                  );
                                                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                                                return (
                                                  <div key={videoLink}>
                                                    <iframe
                                                      src={videoLink}
                                                      width="100%"
                                                      height="150"
                                                      frameBorder="0"
                                                      allowFullScreen
                                                    ></iframe>
                                                    <br />
                                                  </div>
                                                );
                                              })}
                                      </div>
                                    )}
                                    {!performerData?.performer_video_gallery
                                      ?.links && <p>No videos found.</p>}
                                  </div>
                                  {/* {performerData?.performer_video_gallery
                                      ?.links && (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "repeat(auto-fit, minmax(200px, 1fr))",
                                          gap: "10px",
                                        }}
                                      >
                                        {performerData.performer_video_gallery.links
                                          .split(",")
                                          .map((videoLink, index) => {
                                            const videoId =
                                              extractVideoIdFromLink(videoLink);
                                            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

                                            return (
                                              <div key={videoLink}>
                                                <iframe
                                                  src={videoLink}
                                                  width="100%" 
                                                  height="150"
                                                  frameBorder="0"
                                                  allowFullScreen
                                                ></iframe>
                                                <br />
                                              </div>
                                            );
                                          })}
                                      </div>
                                    )}
                                    {!performerData?.performer_video_gallery
                                      ?.links && <p>No videos found.</p>}
                                  </div> */}
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="video_gallery">
                <br></br>
                <h4>Video Gallery: </h4>
                <p>Links: {performerData.performer_video_gallery?.links}</p>
                <Link to="/update-performer-videoGallery" className="text-dark fw-medium">
                    Update Video Gallery
                </Link>
            </Tab.Pane> */}
                                <Tab.Pane eventKey="image_gallery">
                                  <br />
                                  <div
                                    style={{
                                      textAlign: "center",
                                      paddingBottom: "10%",
                                    }}
                                  >
                                    <h4>Image Gallery</h4>
                                    <br></br>
                                    <div className="text-center">
                                      <center>
                                        {performerData.performer_info &&
                                          performerData.performer_info
                                            .user_id === loggedInUserId && (
                                            <Button
                                              variant="outline-primary"
                                              onClick={openFileInput}
                                              style={{
                                                display: "flex",
                                                alignSelf: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "12px",
                                                fontSize: "15px",
                                                border: "none",
                                                boxShadow:
                                                  "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                color: "white",
                                                backgroundColor: "#418abb",
                                                transition:
                                                  "background-color 0.3s",
                                              }}
                                              onMouseEnter={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#ff66c4";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.target.style.backgroundColor =
                                                  "#418abb";
                                              }}
                                            >
                                              <FaUpload
                                                style={{ marginRight: "5px" }}
                                              />
                                              Upload Gallery Picture
                                            </Button>
                                          )}
                                      </center>
                                      {/* <button
                                        onClick={openFileInput}
                                        style={{
                                          width: "30%",
                                          aspectRatio: 5.5,
                                          backgroundColor: "#418abb",
                                          border: "none",
                                          color: "white",
                                          borderRadius: "15px",
                                        }}
                                        onMouseOver={(e) => {
                                          e.target.style.backgroundColor =
                                            "#ff66c4";
                                        }}
                                        onMouseOut={(e) => {
                                          e.target.style.backgroundColor =
                                            "#418abb";
                                        }}
                                        onMouseDown={(e) => {
                                          e.target.style.backgroundColor =
                                            "#ff66c4";
                                        }}
                                        onMouseUp={(e) => {
                                          e.target.style.backgroundColor =
                                            "#418abb";
                                        }}
                                      >
                                        <FaUpload size={20} /> Upload Gallery
                                        Picture
                                      </button> */}
                                      {galleryLoading && (
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}

                                      <input
                                        type="file"
                                        id="galleryPictures"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        multiple
                                        onChange={handleUploadGalleryPictures}
                                      />
                                    </div>
                                    <div>
                                      <br></br>
                                      <br></br>
                                      <div
                                        style={{
                                          paddingLeft: "2%",
                                          paddingRight: "2%",
                                        }}
                                      >
                                        <Masonry
                                          breakpointCols={breakpointColumnsObj}
                                          className="my-masonry-grid"
                                          columnClassName="my-masonry-grid_column"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: "3%",
                                          }}
                                        >
                                          {galleryPictures.map(
                                            (picture, index) => (
                                              <div
                                                key={picture.image_id}
                                                style={{
                                                  position: "relative",
                                                  marginBottom: "20px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <HoverImage
                                                    src={picture.image_url}
                                                    alt={picture.image}
                                                    style={{
                                                      maxWidth: "100%",
                                                      height: "auto",
                                                      display: "block",
                                                      borderRadius: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setCurrentImageIndex(
                                                        index
                                                      );
                                                      setZoomedImageUrl(
                                                        picture.image_url
                                                      );
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      top: "5px",
                                                      right: "5px",
                                                      cursor: "pointer",
                                                      background:
                                                        "rgba(255, 255, 255, 0.5)",
                                                      borderRadius: "20%",
                                                    }}
                                                    onClick={() =>
                                                      handleEllipsisClick(
                                                        picture.image_id
                                                      )
                                                    }
                                                    // onClick={() =>
                                                    //   setDeletingImageId(
                                                    //     picture.image_id
                                                    //   )
                                                    // }
                                                  >
                                                    {performerData.performer_info &&
                                                      performerData
                                                        .performer_info
                                                        .user_id ===
                                                        loggedInUserId && (
                                                        <FaEllipsisV
                                                          size={20}
                                                        />
                                                      )}
                                                  </div>
                                                </div>
                                                <Modal
                                                  show={showImageOptionsModal}
                                                  onHide={() =>
                                                    setShowImageOptionsModal(
                                                      false
                                                    )
                                                  }
                                                  centered
                                                  size="sm"
                                                  backdropStyle={{
                                                    backgroundColor:
                                                      "rgba(0, 0, 0, 0.5)",
                                                  }}
                                                >
                                                  {/* <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      Options
                                                    </Modal.Title>
                                                  </Modal.Header> */}
                                                  <Modal.Body className="text-center">
                                                    <p
                                                      onClick={
                                                        handleDeleteOption
                                                      }
                                                      className="text-dark fw-medium"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      Delete Image
                                                    </p>
                                                    <p>Hide Image</p>
                                                  </Modal.Body>
                                                  <Modal.Footer className="d-flex justify-content-center align-items-center">
                                                    <Button
                                                      className="text-center"
                                                      variant="primary"
                                                      onClick={() =>
                                                        setShowImageOptionsModal(
                                                          false
                                                        )
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>

                                                {/* Delete Confirmation Modal */}
                                                <Modal
                                                  show={
                                                    showImageConfirmationModal
                                                  }
                                                  onHide={() =>
                                                    setShowImageConfirmationModal(
                                                      false
                                                    )
                                                  }
                                                  backdropStyle={{
                                                    opacity: 0.05,
                                                  }}
                                                  centered
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      Confirm Deletion
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Are you sure you want to
                                                    delete this image?
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="primary"
                                                      onClick={() =>
                                                        setShowImageConfirmationModal(
                                                          false
                                                        )
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      variant="danger"
                                                      onClick={() =>
                                                        handleDeletePicture(
                                                          selectedImageId
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>

                                                {/*Modal for zoomed image */}
                                                <Modal
                                                  show={
                                                    currentImageIndex === index
                                                  } // Show the modal only for the current image
                                                  onHide={() =>
                                                    setCurrentImageIndex(null)
                                                  }
                                                  size="xl" // Set the size of the modal to extra large
                                                >
                                                  <Modal.Body>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <img
                                                        src={zoomedImageUrl}
                                                        alt="Zoomed"
                                                        style={{
                                                          width: "100%",
                                                          height: "auto",
                                                          borderRadius: "8px",
                                                          objectFit: "contain",
                                                          maxHeight: "80vh",
                                                          maxWidth: "80vw",
                                                        }}
                                                      />
                                                      <div
                                                        className="close-icon"
                                                        onClick={() =>
                                                          setCurrentImageIndex(
                                                            null
                                                          )
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "10px",
                                                          left: "10px",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "24px",
                                                        }}
                                                      >
                                                        &#x2715;{" "}
                                                        {/* Close icon (X) */}
                                                      </div>
                                                    </div>
                                                    {/* Previous arrow */}
                                                    {showPreviousArrow && (
                                                      <div
                                                        className="arrow"
                                                        onClick={
                                                          handlePreviousClick
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "50%",
                                                          left: "10px",
                                                          transform:
                                                            "translateY(-50%)",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "36px",
                                                        }}
                                                      >
                                                        <FaAngleLeft />
                                                      </div>
                                                    )}

                                                    {/* Next arrow */}
                                                    {showNextArrow && (
                                                      <div
                                                        className="arrow"
                                                        onClick={
                                                          handleNextClick
                                                        }
                                                        style={{
                                                          position: "absolute",
                                                          top: "50%",
                                                          right: "10px",
                                                          transform:
                                                            "translateY(-50%)",
                                                          cursor: "pointer",
                                                          color: "Grey",
                                                          fontSize: "36px",
                                                        }}
                                                      >
                                                        <FaAngleRight />
                                                      </div>
                                                    )}
                                                  </Modal.Body>
                                                </Modal>
                                              </div>
                                            )
                                          )}
                                        </Masonry>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Row>
                          </Tab.Container>
                        </Tab.Pane>

                        {/* <Tab.Pane eventKey="equipment_requirements">
                      <br></br>
                      <div style={{ paddingLeft: "10%" }}>
                        <h4>Equipment Requirements</h4>
                        <p>
                          Instruments:{" "}
                          {
                            performerData.performer_equipment_requirement
                              ?.instruments
                          }{" "}
                          <br></br>
                          Stage setup:{" "}
                          {
                            performerData.performer_equipment_requirement
                              ?.stage_setup
                          }{" "}
                          <br></br>
                          Technical Requirements:{" "}
                          {
                            performerData.performer_equipment_requirement
                              ?.technical_requirements
                          }
                        </p>
                        <Button
                          variant="outline-secondary"
                          style={{
                            backgroundColor: "#418abb",
                            borderColor: "#418abb",
                            color: "white",
                            borderRadius: "15px",
                          }}
                          as={Link}
                          to="/update-performer-equipreq"
                        >
                          <FaEdit size={20} /> Equipment Requirements
                        </Button>
                      </div>
                    </Tab.Pane> */}
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ViewPerformerProfile;

{
  /**<div className="row">
                                <div className="col">
                                  <div className="d-flex align-items-left">
                                   
                                    <strong>
                                      <h4>Callouts</h4>
                                    </strong>
                                  </div>
                                  <br></br>
                                  <center>
                                    {venueData.venue_info &&
                                      venueData.venue_info.user_id ===
                                        loggedInUserId && (
                                        <Button
                                          variant="outline-primary"
                                          onClick={addcallout}
                                          style={{
                                            display: "flex",
                                            alignSelf: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "12px",
                                            fontSize: "15px",
                                            border: "none",
                                            boxShadow:
                                              "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            color: "white",
                                            backgroundColor: "#418abb",
                                            transition: "background-color 0.3s",
                                          }}
                                          onMouseEnter={(e) => {
                                            e.target.style.backgroundColor =
                                              "#ff66c4";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.target.style.backgroundColor =
                                              "#418abb";
                                          }}
                                        >
                                          <FaUpload
                                            style={{ marginRight: "5px" }}
                                          />
                                          Add new Callout
                                        </Button>
                                      )}
                                  </center>
                                  <br></br>
                                  {loading ? (
                                    <div className="text-center mt-5">
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <span className="ms-2">
                                        Loading Callouts...
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      {callouts
                                        .slice(
                                          0,
                                          showAll
                                            ? callouts.length
                                            : viewableCount
                                        )
                                        .map((callout) => (
                                          <Card
                                            key={callout._id}
                                            className="mb-3"
                                            style={{
                                              backgroundColor: "#e8e8e8",
                                              borderRadius: "15px",
                                            }}
                                          >
                                            <Card.Body>
                                              <h4
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {callout.title}
                                              </h4>
                                              <p>{callout.short_description}</p>
                                              <p>
                                                <strong>Location:</strong>{" "}
                                                {callout.location}
                                              </p>
                                              <p>
                                                <strong>Date:</strong>{" "}
                                                {typeof callout.from_date ===
                                                "object"
                                                  ? new Date(
                                                      callout.from_date.year,
                                                      callout.from_date.month -
                                                        1,
                                                      callout.from_date.date
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                  : callout.from_date}
                                              </p>
                                              <p>
                                                <strong>Time:</strong>{" "}
                                                {typeof callout.from_time ===
                                                "object"
                                                  ? `${callout.from_time.hour}:${callout.from_time.minute} ${callout.from_time.am_pm}`
                                                  : callout.from_time}
                                              </p>

                                              {venueData.venue_info &&
                                                venueData.venue_info.user_id ===
                                                  loggedInUserId && (
                                                  <Button
                                                    style={{
                                                      backgroundColor:
                                                        "#151a33",
                                                    }}
                                                    onClick={() =>
                                                      handleDeleteClickCallout(
                                                        callout._id
                                                      )
                                                    }
                                                  >
                                                    <BsTrash />
                                                  </Button>
                                                )}
                                            </Card.Body>
                                            <Modal
                                              show={showDeleteModal}
                                              onHide={() =>
                                                setShowDeleteModal(false)
                                              }
                                            >
                                              <Modal.Header closeButton>
                                                <Modal.Title>
                                                  Confirm Deletion
                                                </Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to delete
                                                this callout?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button
                                                  variant="primary"
                                                  onClick={() =>
                                                    setShowDeleteModal(false)
                                                  }
                                                >
                                                  Cancel
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  onClick={
                                                    handleDeleteConfirmCallout
                                                  }
                                                >
                                                  Delete
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </Card>
                                        ))}
                                    </div>
                                  )}
                                  {callouts.length > viewableCount && (
                                    <div className="text-center">
                                      <Button
                                        onClick={() => setShowAll(!showAll)}
                                      >
                                        {showAll ? "Show less" : "Load more..."}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */
}
