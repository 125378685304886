import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ListGroup, Modal, Table } from "react-bootstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import axiosInstance from "../auth/axiosInstance";
import Navbar from "../../components/navbarSelf";
import Footer from "../../components/footer";
// import bg3 from "../../assect/images/bg/03.jpg";
import "./BillingAndPayment.css";
import { FaPlusCircle, FaTimes } from "../../assect/icons/vander";
import { FaCreditCard } from "react-icons/fa6";
import ViewPerformerClassifieds from "../classifieds/viewPerformerClassifieds";
import ViewVenueClassifieds from "../classifieds/viewVenueClassifieds";
import ViewPerformerClassifiedsforBilling from "./viewPerformerClassifiedsforBilling";
import ViewVenueClassifiedsforBilling from "./viewVenueClassifiedsforBilling";
import NavigationBar from "../../components/navbar/loggedUserNavbar";
import ViewCalloutsbySelfforBilling from "./viewCalloutsbySelfforBilling";
import ViewOpportunitiesforBilling from "./viewOpportunitiesforBilling";
import { SettingsVoiceOutlined } from "@mui/icons-material";
import Spinner from "react-bootstrap/Spinner";

export default function BillingAndPayment() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClassifiedsModalOpen, setIsClassifiedsModalOpen] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelsubloading, setCancelSubLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const navigate = useNavigate();
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [calloutCount, setCalloutCount] = useState(0);
  const [classifiedsCount, setClassifiedsCount] = useState(0);
  const [opportunitiesCount, setOpportunitiesCount] = useState(0);
  const [profiles, setProfiles] = useState({});
  const [pricingData, setPricingData] = useState([]);

  const handleCalloutCount = (count) => setCalloutCount(count);
  const handleClassifiedsCount = (count) => setClassifiedsCount(count);
  const handleOpportunitiesCount = (count) => setOpportunitiesCount(count);

  const [invoiceId, setInvoiceId] = useState();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingPayment2, setIsLoadingPayment2] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  // const profileId = Cookies.get("profileOwnerId");

  // const isButtonDisabled = calloutCount > 0 && classifiedsCount > 0;
  const isButtonDisabled = !(
    calloutCount === 0 &&
    classifiedsCount === 0 &&
    opportunitiesCount === 0
  );

  const areBothEmpty =
    calloutCount === 0 && classifiedsCount === 0 && opportunitiesCount === 0;

  const modalTitle = areBothEmpty
    ? "You have cleared your posts. You can cancel the subscription."
    : "Please withdraw all the posts you have posted before cancelling the subscription";

  const fetchBillingHistory = async () => {
    try {
      const response = await axiosInstance.get("/v1/billing/list-invoices", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      setBillingHistory(response.data.invoices);
      console.log("billing info: ", response.data);
      fetchProfileData(response.data.invoices);
    } catch (error) {
      console.error("Error fetching billing history:", error);
      setError(error);
    }
  };

  const fetchProfileData = async (invoices) => {
    const fetchedProfiles = {};

    for (const invoice of invoices) {
      const profileId = invoice.metadata?.profile_id;
      if (profileId) {
        try {
          let profileResponse;
          if (profileId.startsWith("PROFILE-PERFORMER")) {
            profileResponse = await axiosInstance.get(
              `/v1/performer/view-profile/${profileId}/section/info`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }
            );

            fetchedProfiles[profileId] = {
              name: profileResponse.data.performer_info.profile_name,
              image: profileResponse.data.performer_info.profile_picture_url,
            };

            const billingInfoResponse = await axiosInstance.get(
              `/v1/performer/view-profile/${profileId}/section/complete`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }
            );
            fetchedProfiles[profileId].billingStatus =
              billingInfoResponse.data.profile_billing_info.subscription_status;
            console.log(
              "performer billing info: ",
              billingInfoResponse.data.profile_billing_info
            );
          } else if (profileId.startsWith("PROFILE-VENUE")) {
            profileResponse = await axiosInstance.get(
              `/v1/venue/view-profile/${profileId}/section/info`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }
            );

            fetchedProfiles[profileId] = {
              name: profileResponse.data.venue_info.profile_name,
              image: profileResponse.data.venue_info.profile_picture_url,
            };
            const billingInfoResponse = await axiosInstance.get(
              `/v1/venue/view-profile/${profileId}/section/complete`,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("accessToken")}`,
                },
              }
            );
            fetchedProfiles[profileId].billingStatus =
              billingInfoResponse.data.profile_billing_info.subscription_status;
            console.log(
              "performer billing info: ",
              billingInfoResponse.data.profile_billing_info
            );
          }
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      }
    }
    setProfiles(fetchedProfiles);
  };

  useEffect(() => {
    fetchBillingHistory();
  }, []);

  const fetchSubscriptionId = async () => {
    const selectedPerformerId = Cookies.get("profileOwnerId");
    const hardcodedPerformerSection = "complete";

    try {
      const response = await axiosInstance.get(
        `/v1/performer/view-profile/${selectedPerformerId}/section/${hardcodedPerformerSection}`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      if (response.status === 200) {
        const performerbillingInfo = response.data.profile_billing_info;
        const subscriptionId = performerbillingInfo?.subscription_id;
        console.log("sub id:", subscriptionId);

        if (subscriptionId) {
          setSubscriptionId(subscriptionId);
        } else {
          console.error("Subscription ID not found");
          setError(new Error("Subscription ID not found"));
        }
      }
    } catch (error) {
      console.error("Error fetching subscription ID:", error);
      setError(error);
    } finally {
      setIsLoadingCancel(false);
    }
  };

  const handleCancelSubscription = () => {
    setIsLoadingCancel(true);
    const profileId = Cookies.get("profileOwnerId");
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      setProfileType("performer");
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      setProfileType("venue");
    }
    setIsClassifiedsModalOpen(true);
    fetchSubscriptionId();
  };

  useEffect(() => {
    console.log(invoiceId);
    if (selectedPaymentMethod && invoiceId) {
      const doPay = async () => {
        try {
          const response = await axiosInstance.post(
            "/v1/billing/pay-invoice",
            {
              invoiceId: invoiceId,
              paymentMethod: selectedPaymentMethod,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("accessToken")}`,
              },
            }
          );
          // setPaymentMethods(response.data.payment_methods);s
        } catch (error) {
          console.error("Error retry payment.", error);
          setError(error);
        }
      };

      doPay();
    }
  }, [invoiceId, selectedPaymentMethod]);

  const handleRetryPayment = (invoice_id) => {
    setIsModalOpen(true);
    console.log(invoice_id);
    setInvoiceId(invoice_id);
  };

  const handlePaymentMethodSelection = (id) => {
    setIsModalOpen(false);
    setSelectedPaymentMethod(id);
    createSubscription(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.post(
          `/v1/billing/get-subscription-package`,
          { name: "Performer Profile" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("pricing data", response.data);
        setPricingData(response.data);
        if (response.data.length > 0) {
          setPriceId(response.data[0].default_price);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const [priceId, setPriceId] = useState();

  const createSubscription = async (paymentMethodId) => {
    console.log(priceId);
    console.log(Cookies.get("accessToken"));
    console.log(Cookies.get("profileOwnerId"));
    try {
      const response = await axiosInstance.post(
        "/v1/billing/create-subscription",
        {
          priceId: priceId,
          paymentId: paymentMethodId,
          profileId: Cookies.get("profileOwnerId"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      const data = response.data;
      console.log(data.clientSecret);

      if (data.clientSecret) {
        navigate("/pay", { state: { clientSecret: data.clientSecret } });
      } else {
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "The profile is now active again.",
        }).then(() => {
          const profileId = Cookies.get("profileOwnerId");
          if (profileId.startsWith("PROFILE-PERFORMER")) {
            navigate(`/performer/${profileId}`);
          } else if (profileId.startsWith("PROFILE-VENUE")) {
            navigate(`/venue/${profileId}`);
          } else {
            console.error("Unknown profile type");
          }
        });
      }
    } catch (error) {
      console.error("Error creating subscription:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "An issue occurred while processing your payment. Please try again.",
        showCancelButton: true,
        confirmButtonText: "Change Payment Method",
      }).then((result) => {
        if (result.isConfirmed) {
          handleOpenModal();
        }
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchBillingHistory();
  }, [isModalOpen]);

  useEffect(() => {
    console.log(isModalOpen);
    const fetchPaymentMethods = async () => {
      try {
        const response = await axiosInstance.post(
          "/v1/billing/payment-methods",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setPaymentMethods(response.data.payment_methods);
        console.log(response.data.payment_methods);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
        setError(error);
      }
    };

    fetchPaymentMethods();
  }, [isModalOpen]);

  const cancelSubscription = async () => {
    setCancelSubLoading(true);
    try {
      const response = await axiosInstance.post(
        "/v1/billing/cancel-subscription-period-end",
        { subscriptionId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      Swal.fire({
        title: "Subscription cancelled successfully",
        text: "You will retain access to the portal until the current subscription period concludes.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        setIsClassifiedsModalOpen(false);
        fetchBillingHistory();
      });
      // setIsClassifiedsModalOpen(false);
      // fetchBillingHistory();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      Swal.fire({
        title: "There was an error cancelling your subscription.",
        text: "An error occurred while canceling your subscription. Please contact the administrator for assistance.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    setCancelSubLoading(false);
  };

  const setupPaymentMethod = async () => {
    setIsLoadingPayment(true);
    const profileId = Cookies.get("profileOwnerId");

    try {
      const isPerformer = profileId.startsWith("PROFILE-PERFORMER");
      const apiUrl = isPerformer
        ? `/v1/performer/view-profile/${profileId}/section/complete`
        : `/v1/venue/view-profile/${profileId}/section/complete`;

      const profileResponse = await axiosInstance.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });

      const subscriptionId =
        profileResponse.data.profile_billing_info.subscription_id;

      const paymentResponse = await axiosInstance.post(
        "/v1/billing/setup-payment-method",
        {
          subscription_id: subscriptionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      const data = paymentResponse.data;
      console.log(data.clientSecret);

      navigate("/pay", { state: { clientSecret: data.clientSecret } });
      setIsLoadingPayment(false);
    } catch (error) {
      console.error("Error setting up payment method:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "An issue occurred while processing your payment. Please try again.",
        showCancelButton: true,
        confirmButtonText: "Change Payment Method",
      });
      setIsLoadingPayment(false);
    }
  };

  const setupPaymentMethod2 = async () => {
    setIsLoadingPayment2(true);
    const profileId = Cookies.get("profileOwnerId");

    try {
      const isPerformer = profileId.startsWith("PROFILE-PERFORMER");
      const apiUrl = isPerformer
        ? `/v1/performer/view-profile/${profileId}/section/complete`
        : `/v1/venue/view-profile/${profileId}/section/complete`;

      const profileResponse = await axiosInstance.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });

      const subscriptionId =
        profileResponse.data.profile_billing_info.subscription_id;

      const paymentResponse = await axiosInstance.post(
        "/v1/billing/setup-payment-method",
        {
          subscription_id: subscriptionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      const data = paymentResponse.data;
      console.log(data.clientSecret);

      navigate("/pay", { state: { clientSecret: data.clientSecret } });
      setIsLoadingPayment2(false);
    } catch (error) {
      console.error("Error setting up payment method:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "An issue occurred while processing your payment. Please try again.",
        showCancelButton: true,
        confirmButtonText: "Change Payment Method",
      });
      setIsLoadingPayment2(false);
    }
  };

  // const setupPaymentMethod = async () => {
  //   setIsLoadingPayment(true);
  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/billing/setup-payment-method",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     const data = response.data;
  //     console.log(data.clientSecret);

  //     navigate("/pay", { state: { clientSecret: data.clientSecret } });
  //   } catch (error) {
  //     console.error("Error creating subscription:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Payment Failed",
  //       text: "An issue occurred while processing your payment. Please try again.",
  //       showCancelButton: true,
  //       confirmButtonText: "Change Payment Method",
  //     });
  //     // }).then((result) => {
  //     //   if (result.isConfirmed) {
  //     //     handleOpenModal();
  //     //   }
  //     // });
  //   } finally {
  //     setIsLoadingPayment(false);
  //   }
  // };

  // const setupPaymentMethod2 = async () => {
  //   setIsLoadingPayment2(true);
  //   try {
  //     const response = await axiosInstance.post(
  //       "/v1/billing/setup-payment-method",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     const data = response.data;
  //     console.log(data.clientSecret);

  //     navigate("/pay", { state: { clientSecret: data.clientSecret } });
  //   } catch (error) {
  //     console.error("Error creating subscription:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Payment Failed",
  //       text: "An issue occurred while processing your payment. Please try again.",
  //       showCancelButton: true,
  //       confirmButtonText: "Change Payment Method",
  //     });
  //     // }).then((result) => {
  //     //   if (result.isConfirmed) {
  //     //     handleOpenModal();
  //     //   }
  //     // });
  //   } finally {
  //     setIsLoadingPayment2(false);
  //   }
  // };

  const handleBackClick = () => {
    navigate(`/account-page`);
  };

  const statusMapping = {
    PENDING_SUBSCRIPTION_CANCELLATION: "Pending Subscription Cancellation",
    // REQEST_TO_CANCELLATION: "Requested for Subscription Cancellation",
    REQEST_TO_CANCELLATION: "Pending Subscription Cancellation.",
    CANCELLED: "Subscription Cancelled",
  };

  return (
    <>
      <NavigationBar />
      {/* Back Arrow */}

      {/* <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      /> */}
      {/* <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Billing & Payment
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="title-heading text-center">
        <h5>Billing & Payment</h5>
      </div>
      {/* <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div> */}
      <section className="section pt-5">
        <button
          className="back-arrow"
          onClick={handleBackClick}
          style={{
            border: "none",
            outline: "none",
            marginLeft: "13%",
            fontSize: "25px",
            marginBottom: "10px",
            color: "black",
            background: "transparent",
            cursor: "pointer",
          }}
        >
          ←
        </button>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-sm-0 pt-sm-0">
              <Button
                onClick={() => setupPaymentMethod()}
                className="btn btn-primary"
                style={{
                  cursor: isLoadingPayment ? "not-allowed" : "pointer",
                  borderRadius: "5px",
                  marginRight: "2%",
                  padding: "0.75rem 1.5rem",
                  minWidth: "250px",
                  minHeight: "20px",
                  opacity: isLoadingPayment ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                  transition: "opacity 0.3s ease", // Smooth transition for opacity change
                }}
                disabled={isLoadingPayment}
              >
                {isLoadingPayment ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{
                      width: "1.2rem",
                      height: "1.2rem",
                      borderWidth: "0.1rem",
                    }}
                  />
                ) : (
                  "Change Payment Method"
                )}
              </Button>
              <Button
                onClick={handleCancelSubscription}
                className="btn btn-danger"
                style={{
                  cursor: isLoadingCancel ? "not-allowed" : "pointer",
                  borderRadius: "5px",
                  padding: "0.75rem 1.5rem",
                  minWidth: "250px",
                  minHeight: "20px",
                  opacity: isLoadingCancel ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                  transition: "opacity 0.3s ease", // Smooth transition for opacity change
                }}
                disabled={isLoadingCancel}
              >
                {isLoadingCancel ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{
                      width: "1.2rem",
                      height: "1.2rem",
                      borderWidth: "0.1rem",
                    }}
                  />
                ) : (
                  "Cancel Subscription"
                )}
              </Button>
              <br></br>
              <br></br>
              <h5>Billing History</h5>
              {isLoading ? (
                <div className="text-center">Loading...</div>
              ) : error ? (
                <div className="text-center text-danger">
                  Error: {error.message}
                </div>
              ) : (
                <div className="table-responsive">
                  <Table striped bordered hover className="billing-table">
                    <thead>
                      <tr>
                        {/* <th>Subscription</th> */}
                        <th>Profile</th>
                        <th>Profile Name</th>
                        <th>Amount Due</th>
                        <th>Amount Paid</th>
                        <th>Amount Remaining</th>
                        <th>Billing Reason</th>
                        <th>Currency</th>
                        <th>Invoice Link</th>
                        <th>Next Payment Attempt</th>
                        <th>Paid</th>
                        <th>Status</th>
                        <th>Effective At</th>
                        <th>Retry</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingHistory.map((invoice, index) => {
                        const profileId = invoice.metadata?.profile_id;
                        const profile = profiles[profileId];

                        return (
                          <tr key={index}>
                            {/* <td>{invoice.subscription}</td> */}
                            {/* <td>
                            {invoice.subscription_details.metadata.profile_id}
                          </td> */}
                            <td>
                              {profile ? (
                                <div>
                                  <img
                                    src={profile.image}
                                    alt={profile.name}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                              ) : (
                                "Loading..."
                              )}
                            </td>
                            <td>
                              {profile ? (
                                <div>
                                  <span>{profile.name}</span>
                                </div>
                              ) : (
                                "Loading..."
                              )}
                            </td>
                            <td>${invoice.amount_due / 100}</td>
                            <td>${invoice.amount_paid / 100}</td>
                            <td>${invoice.amount_remaining / 100}</td>
                            <td>{invoice.billing_reason}</td>
                            <td>{invoice.currency}</td>
                            <td>
                              <a
                                href={invoice.invoice_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Invoice
                              </a>
                            </td>
                            <td>
                              {new Date(
                                invoice.period_end * 1000
                              ).toLocaleDateString()}
                            </td>
                            <td>{invoice.paid ? "Yes" : "No"}</td>
                            <td>{invoice.status}</td>
                            <td>
                              {new Date(
                                invoice.effective_at * 1000
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              {profile ? (
                                profile.billingStatus ===
                                  "PENDING_SUBSCRIPTION_CANCELLATION" ||
                                profile.billingStatus ===
                                  "REQEST_TO_CANCELLATION" ||
                                profile.billingStatus === "CANCELLED" ? (
                                  <span>
                                    {statusMapping[profile.billingStatus]}
                                  </span>
                                ) : !invoice.paid ? (
                                  <Button
                                    onClick={() =>
                                      handleRetryPayment(invoice.id)
                                    }
                                    className="btn btn-danger"
                                    style={{
                                      borderRadius: "5px",
                                      padding: "0.75rem 1.5rem",
                                    }}
                                  >
                                    Retry
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn btn-danger"
                                    style={{
                                      borderRadius: "5px",
                                      padding: "0.75rem 1.5rem",
                                    }}
                                    disabled={true}
                                  >
                                    Retry
                                  </Button>
                                )
                              ) : (
                                "Loading..."
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                centered
              >
                <Modal.Header>
                  <Modal.Title>Select a Payment Method</Modal.Title>
                  <FaTimes
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "red",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  />
                </Modal.Header>
                <Modal.Body>
                  <ListGroup>
                    {paymentMethods.length > 0 ? (
                      paymentMethods.map((value) => (
                        <ListGroup.Item
                          key={value.id}
                          action
                          onClick={() => handlePaymentMethodSelection(value.id)}
                          className="d-flex align-items-center"
                        >
                          <FaCreditCard className="me-3" />
                          {value.id}
                        </ListGroup.Item>
                      ))
                    ) : (
                      <ListGroup.Item>
                        No existing payment methods.
                      </ListGroup.Item>
                    )}
                    <ListGroup.Item
                      action
                      // onClick={() => handlePaymentMethodSelection(false)}
                      onClick={() => setupPaymentMethod2()}
                      className="d-flex align-items-center"
                      disabled={isLoadingPayment2}
                    >
                      {isLoadingPayment2 ? (
                        <Spinner
                          animation="border"
                          role="status"
                          style={{
                            width: "1.2rem",
                            height: "1.2rem",
                            borderWidth: "0.1rem",
                          }}
                        />
                      ) : (
                        <>
                          <FaPlusCircle className="me-3" />
                          Attach new method.
                        </>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Modal.Body>
              </Modal>
              <Modal
                show={isClassifiedsModalOpen}
                onHide={() => setIsClassifiedsModalOpen(false)}
                size="xl"
                centered
              >
                <Modal.Header>
                  <Modal.Title>{modalTitle}</Modal.Title>
                  {/* <Modal.Title>
                    Please withdraw all the posts you have posted before
                    cancelling the subscription
                  </Modal.Title> */}
                  <FaTimes
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "red",
                    }}
                    onClick={() => setIsClassifiedsModalOpen(false)}
                  />
                </Modal.Header>
                <Modal.Body>
                  {profileType === "performer" ? (
                    <ViewPerformerClassifiedsforBilling
                      updateClassifiedsCount={handleClassifiedsCount}
                    />
                  ) : (
                    <>
                      <ViewVenueClassifiedsforBilling
                        updateClassifiedsCount={handleClassifiedsCount}
                      />
                      <ViewOpportunitiesforBilling
                        updateOpportunitiesCount={handleOpportunitiesCount}
                      />
                    </>
                  )}

                  <ViewCalloutsbySelfforBilling
                    updateCalloutCount={handleCalloutCount}
                  />
                </Modal.Body>
                <center>
                  <Button
                    className="btn btn-danger"
                    style={{
                      cursor: cancelsubloading ? "not-allowed" : "pointer",
                      borderRadius: "5px",
                      padding: "0.75rem 1.5rem",
                      minWidth: "250px",
                      minHeight: "20px",
                      opacity: cancelsubloading ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                      cursor: cancelsubloading ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                      transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    }}
                    onClick={cancelSubscription}
                    disabled={cancelsubloading || isButtonDisabled}
                  >
                    {cancelsubloading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderWidth: "0.1rem",
                        }}
                      />
                    ) : (
                      "Cancel Subscription"
                    )}
                  </Button>
                </center>
                <br></br>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
