import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import axiosInstance from "../auth/axiosInstance";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Form, useNavigate } from "react-router-dom";
import AutoComplete from "../../components/autoCompleteForms";
import "../opportunities/addNewOpportunity.css";
import CustomTimePicker from "../../components/CustomTimePicker";
import { isInteger } from "formik";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import moment from "moment";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

const CreateCalloutbyVenue = () => {
  const [formData, setFormData] = useState({
    title: "",
    venue: "",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    call_out_category: "",
    call_out_type: "",
    short_description: "",
    descriptions: [""],
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    author_profile_id: "",
    call_out_sub_category: "",
    // price_point: { currency: "AUD", value: 0 },
    price_point: {
      currency: "AUD",
      value_from: 0,
      value_to: 1500,
    },
  });

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const [loading, setLoading] = useState(false);

  const [priceRange, setPriceRange] = useState([0, 1000]); // Initialize range

  const [suburb, setSuburb] = useState();

  const yesterday = moment().subtract(1, "day");
  const lastday = moment().add(12, "month");
  const valid = function (current) {
    return current.isAfter(yesterday) && !current.isAfter(lastday);
  };

  const calloutTypes = [
    "Performer to Performer",
    "Performer to Venue",
    "Performer to Performer and Venue",
    "Venue to Performer",
    "Venue to Venue",
    "Venue to Performer and Venue",
  ];

  // Categories List
  const categories = [
    "Auditions",
    "Collaboration",    
    "Educational Resources",    
    "Industry Insights",    
    "Personal Announcement",    
    "Services Needed",    
    "Services Offered",    
    "Technical Support + Advice",    
    "Venue Specific",
    "Work Opportunities",
  ];

  // Subcategories for each category
  const subCategories = {
    Auditions: [
      "Auditions",
      "Backup Singers",
      "Band Members",
      "Performance Opportunities",
      "Session Musicians",
      "Stage / Sound Production",
    ],
    Collaboration: [
      "Collaboration",
      "Composing",
      "Content",
      "Creating",
      "Creation",
      "Digital",
      "Education",
      "Forming New Band",
      "Lyrics",
      "Music",
      "Musical Arrangements",
      "Networking",
      "Other Performers",
      "Project",
      "Songwriting",
      "Workshop",
    ],
    "Educational Resources": [
      "Books",
      "Courses",
      "Education",
      "General Information",
      "Instructional Video",
      "In-House",
      "In-Person",
      "Masterclass",
      "Music Books",
      "Music Lessons",
      "Online Course",
      "Resources",
      "Seminar",
      "Sheet Music",
      "Tuition",
      "Webinar",
      "Website",
      "Workshop",
    ],
    "Industry Insights": [
      "Compensation",
      "Competitions",
      "Copyright",
      "Grants",
      "Income and Payments",
      "Industry",
      "Laws",
      "Licensing",
      "Music",
      "Performance",
      "Scholarships",
      "Statistics",
      "Streaming",
      "Trends",
    ],
    "Personal Announcement": [
      "Album Launch",
      "Award Nominations",
      "Awards",
      "Call Outs",
      "Crowdfunding Campaigns",
      "Feeding It Forwards",
      "Kudos",
      "Projects",
      "Significant Performance",
      "Special Mentions",
    ],
    "Services Needed": [
      "Album Artwork Design",
      "Bump In / Bump Out",
      "Education",
      "Engineering",
      "Industry Experience",
      "Marketing",
      "Mastering",
      "Mixing",
      "Music",
      "Production",
      "Recording",
      "Sound",
      "Stage Design",
      "Stage Support",
      "Tuition",
      "Video",
    ],
    "Services Offered": [
      "Album Artwork Design",
      "Bump In / Bump Out",
      "Education",
      "Engineering",
      "Industry Experience",
      "Marketing",
      "Mastering",
      "Mixing",
      "Music",
      "Production",
      "Recording",
      "Sound",
      "Stage Design",
      "Stage Support",
      "Tuition",
      "Video",
    ],
    "Technical Support + Advice": [
      "Equipment",
      "How To",
      "Instrument Care",
      "Instrument Customisation",
      "Instrument Maintenance",
      "Music Technology",
      "Performance Techniques",
      "Repair",
      "Seeking Advice",
      "Software",
      "Sound Setup",
      "Tips and Tricks",
      "Troubleshooting",
      "Tutorials",
    ],
    "Venue Specific": [
      "Employing",
      "Hiring",
      "New Venue Openings",
      "Regular Performers",
      "Resident Musicians",
      "Seeking",
      "Special Event Bookings",
      "Themed Music Night",
    ],
    "Work Opportunities": [
      "Gigs",
      "Joint Performances",
      "Live Performance",
      "Offering",
      "Passing Through",
      "Seeking",
      "Touring",
      "Traveling",
      "Venues",
    ],
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //     sub_category: "", // Reset sub_category when category changes
  //   });
  // };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const performer_id = Cookies.get("profileOwnerId");
        console.log(performer_id);
        // const profileType = performer_id.split("-");
        const profileType = performer_id.startsWith("PROFILE-PERFORMER")
          ? "performer"
          : "venue";
        let url;
        if (profileType === "venue") {
          url = `/v1/venue/view-profile/${performer_id}/section/contact`;
        } else {
          url = `/v1/performer/view-profile/${performer_id}/section/complete`;
        }
        const response = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });
        console.log(response);
        // setSuburb(response.data.performer_contact.location);
        // handleSuburbSelect(response.data.performer_contact.location);
        // console.log(response.data.performer_contact.location);
        if (profileType === "venue") {
          console.log(response.data);
          // handleSuburbSelect(response.data.venue_contact.location);
          setSuburb(response.data.venue_contact.location);
          handleSuburbSelect(response.data.venue_contact.location);
        } else {
          console.log(response.data);
          setSuburb(response.data.performer_contact.location);
          handleSuburbSelect(response.data.performer_contact.location);
          // handleSuburbSelect(response.data.performer_contact.location);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       const venue_id = Cookies.get("profileOwnerId");
  //       console.log(venue_id);
  //       const response = await axiosInstance.get(
  //         `/v1/venue/view-profile/${venue_id}/section/contact`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }
  //       );
  //       console.log("Location: ", response.data.venue_contact.location);
  //       handleSuburbSelect(response.data.venue_contact.location);
  //     } catch (error) {
  //       console.error("Error fetching profile:", error);
  //     }
  //   };

  //   fetchProfile();
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title" && value.length > 100) {
      Swal.fire("Error", "The title cannot exceed 100 characters.", "error");
      return;
    }

    let formattedValue = value;

    // Format the date if the field is a date input
    if (e.target.type === "date") {
      formattedValue = moment(value).format("DD/MM/YYYY");
    }

    if (name === "price_point_value") {
      // Handle range slider change
      if (value[0] < 0 || value[1] > 5000) {
        setErrorMessage("The price point must be between $0 and $5000.");
      } else {
        setErrorMessage(""); // Clear error message if valid
        setFormData((prevData) => ({
          ...prevData,
          price_point: {
            ...prevData.price_point,
            value_from: value[0],
            value_to: value[1],
          },
        }));
      }
    } else if (name === "price_point_currency") {
      setFormData((prevData) => ({
        ...prevData,
        price_point: { ...prevData.price_point, currency: value },
      }));
    } else if (name === "location") {
      // Only update the suburb field of the location object
      setFormData({
        ...formData,
        location: {
          suburb: value,
        },
      });
    } else {
      // For other fields, update normally, using formattedValue for date fields
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "price_point_value") {
  //     // Handle range slider change
  //     if (value[0] < 0 || value[1] > 10000) {
  //       setErrorMessage("Price Point should be between $0 and $10000");
  //     } else {
  //       setErrorMessage(""); // Clear error message if valid
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         price_point: {
  //           ...prevData.price_point,
  //           value_from: value[0],
  //           value_to: value[1],
  //         },
  //       }));
  //     }
  //   } else if (name === "price_point_currency") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       price_point: { ...prevData.price_point, currency: value },
  //     }));
  //   } else if (name === "location") {
  //     // Only update the suburb field of the location object
  //     setFormData({
  //       ...formData,
  //       location: {
  //         suburb: value,
  //       },
  //     });
  //   } else {
  //     // For other fields, update normally
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };

  // const handleDescriptionChange = (index, value) => {
  //   const newDescriptions = [...formData.descriptions];
  //   newDescriptions[index] = value;
  //   setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
  // };

  const handleDescriptionChange = (index, value) => {
    // Check if the description has more than 2000 characters
    if (value.length > 2000) {
      Swal.fire({
        title: 'Too Many Characters!',
        text: 'The description should not exceed 2000 characters.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    } else {
      const newDescriptions = [...formData.descriptions];
      newDescriptions[index] = value; // Update the specific description at index
      setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
    }
  };


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    const profileId = Cookies.get("profileOwnerId");
    if (profileId) {
      setFormData({ ...formData, author_profile_id: profileId });
    }

    // Fetch Callout Types (Assuming it's retrieved from a separate API endpoint)
    // const fetchCalloutTypes = async () => {
    //   try {
    //     const response = await axiosInstance.get("/v1/call_out/types");
    //     const data = response.data;
    //     console.log("Callout Types:", data);
    //     // Update formData with fetched callout types (if applicable)
    //     // setFormData({ ...formData, call_out_type: data[0] }); // Assuming you want to pre-populate the first callout type
    //   } catch (error) {
    //     console.error("Error fetching callout types:", error);
    //   }
    // };

    // const fetchCalloutTypes = async () => {
    //   try {
    //     const response = await axiosInstance.get("/v1/call_out/types");
    //     const data = response.data.call_out_types.map((type, index) => ({
    //       id: index, // Assign a unique ID to each type
    //       name: type,
    //     }));
    //     console.log("Callout Types:", data);
    //     setCalloutTypes(data); // Set the transformed data in the state
    //   } catch (error) {
    //     console.error("Error fetching callout types:", error);
    //   }
    // };

    // const fetchCalloutTypes = async () => {
    //   try {
    //     const response = await axiosInstance.get("/v1/call_out/types");
    //     const data = response.data;
    //     console.log("Callout Types:", data);
    //     setCalloutTypes(data); // Update state with fetched callout types
    //   } catch (error) {
    //     console.error("Error fetching callout types:", error);
    //   }
    // };

    // Fetch Categories
    //   const fetchCategories = async () => {
    //     try {
    //       const response = await axiosInstance.get(
    //         "/v1/call_out/categories-sub-categories"
    //       );
    //       // const data = response.data.call_out_categories_sub_categories;
    //       // setCategories(data.map((category) => category.genre));
    //       // setSubCategories(data);
    //       setCategories(
    //         response.data.call_out_categories_sub_categories.map(
    //           (category) => category.category
    //         )
    //       );
    //       setSubCategories(response.data.call_out_categories_sub_categories);
    //       console.log(
    //         "Categories:",
    //         response.data.call_out_categories_sub_categories
    //       );
    //       // console.log("Subcategories:", data);
    //     } catch (error) {
    //       console.error("Error fetching categories:", error);
    //     }
    //   };

    //   fetchCalloutTypes();
    //   fetchCategories();
  }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Update formData with the selected category
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //     // Reset sub-category when category changes
  //     sub_category: "",
  //   });

  //   // Filter sub-categories based on the selected category
  //   const filteredSubCategories = subCategories.find(
  //     (category) => category.genre === value
  //   );
  //   setSubCategories(
  //     filteredSubCategories ? filteredSubCategories.sub_genres : []
  //   );
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleDescriptionChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedDescriptions = [...formData.descriptions];
  //   updatedDescriptions[index][name] = value;
  //   setFormData({
  //     ...formData,
  //     descriptions: updatedDescriptions,
  //   });
  // };

  const addDescriptionField = () => {
    setFormData({
      ...formData,
      descriptions: [...formData.descriptions, ""],
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Function to convert date string to object
    const convertDateToObject = (dateString) => {
      const year = dateString.getFullYear();
      const month = dateString.getMonth() + 1;
      const day = dateString.getDate();

      return { year, month, day };
    };

    const convertTo12HourFormat = (hr, min) => {
      const isPM = hr >= 12;
      const hour = hr % 12 || 12;
      const minute = min % 30 === 0 ? min : 0;
      const am_pm = isPM ? "PM" : "AM";
      return { hour, minute, am_pm };
    };

    const fromDateTime = new Date(`${formData.from}`);
    const toDateTime = new Date(`${formData.to}`);

    const hourDifference = (toDateTime - fromDateTime) / (1000 * 60 * 60);

    // Convert dates
    const fromDateObject = convertDateToObject(fromDateTime);
    const toDateObject = convertDateToObject(toDateTime);

    // Convert times
    const fromTimeObject = convertTo12HourFormat(
      fromDateTime.getHours(),
      fromDateTime.getMinutes()
    );

    const toTimeObject = convertTo12HourFormat(
      toDateTime.getHours(),
      toDateTime.getMinutes()
    );

    console.log(fromDateTime);

    // Check if "From Date/Time" is before "To Date/Time"
    if (fromDateTime >= toDateTime) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "The 'From Date/Time' must be before the 'To Date/Time'.",
      });
      setLoading(false);
      return;
    }

    // Check if the difference exceeds 10 hours
    // if (hourDifference > 10) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Validation Error",
    //     text: "To Date/Time must be within 10 hours of From Date/Time",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // Create new form data with converted dates and times
    const updatedFormData = {
      ...formData,
      from_date: fromDateObject,
      to_date: toDateObject,
      from_time: fromTimeObject,
      to_time: toTimeObject,
    };

    console.log("Updated Form Data:", updatedFormData);

    try {
      const response = await axiosInstance.post(
        "/v1/call_out/create-callout",
        updatedFormData
      );
      console.log("Callout created:", response.data);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "The callout has been created successfully!",
      }).then(() => {
        const profileId = Cookies.get("profileOwnerId");
        if (profileId.startsWith("PROFILE-PERFORMER")) {
          navigate(`/performer/${profileId}`);
        } else if (profileId.startsWith("PROFILE-VENUE")) {
          navigate(`/venue/${profileId}`);
        }
      });
    } catch (error) {
      console.error("Error creating CallOut:", error);
      let errorMessage = "An error occurred while creating the callout. Please try again.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  const handleCancel = () => {
    navigateBack();
  };

  const navigateBack = () => {
    const profileId = Cookies.get("profileOwnerId");
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      navigate(`/performer/${profileId}`);
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      navigate(`/venue/${profileId}`);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <>
      <style>
        {`
    body {
      background-color: #f0f2f5;
    }
  `}
      </style>
      <div>
        <br></br>

        <Card
          style={{
            borderRadius: "5px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            marginLeft: "13%",
            marginRight: "13%",
            marginTop: "3%",
            paddingLeft: "6%",
            paddingRight: "6%",
            paddingTop: "2%",
            paddingBottom: "1%",
            // backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <div>
            <center>
              <h2>Create new CallOut</h2>
            </center>
          </div>
          <br></br>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-2">
              <Row style={{ marginBottom: "-2.5%" }}>
                <div className="inputwith-title">
                  <p className="para-style">Title</p>
                  <input
                    type="text"
                    className="form-control mb-3 shadow-box input-style"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="d-flex flex-column">
                    {/* <div className="inputwith-title">
                      <p className="para-style">Venue</p>
                      <input
                        type="text"
                        className="form-control mb-3 shadow-box input-style"
                        placeholder="Venue"
                        name="venue"
                        value={formData.venue}
                        onChange={handleChange}
                        required
                      />
                    </div> */}

                    <div className="inputwith-title">
                      <p className="para-style">Location</p>
                      {/* <AutoComplete onSuburbSelect={handleSuburbSelect} /> */}
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="location"
                        name="location"
                        value={formData.location.address}
                        onChange={handleChange}
                        readOnly={true}
                      />
                    </div>

                    <div className="inputwith-title">
                      <div className="mb-3">
                        <label htmlFor="call_out_type" className="para-style">
                          CallOut Type
                        </label>
                        <select
                          id="call_out_type"
                          name="call_out_type"
                          className="form-select shadow-box input-style"
                          value={formData.call_out_type}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select CallOut Type</option>
                          {Array.isArray(calloutTypes) &&
                            calloutTypes.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="inputwith-title">
                      <div className="mb-3">
                        <label htmlFor="category" className="para-style">
                          Category
                        </label>
                        <select
                          id="category"
                          name="call_out_category"
                          className="form-select shadow-box input-style"
                          value={formData.call_out_category}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Category</option>
                          {categories.map((category, index) => (
                            <option key={index} value={category}>
                              {category}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="inputwith-title">
                      <div className="mb-3">
                        <label htmlFor="sub_category" className="para-style">
                          Sub Category
                        </label>
                        <select
                          id="sub_category"
                          name="call_out_sub_category"
                          className="form-select shadow-box input-style"
                          value={formData.call_out_sub_category}
                          onChange={handleChange}
                          disabled={
                            !formData.call_out_category ||
                            subCategories.length === 0
                          } // Disable if no category selected or sub-categories not loaded
                          required
                        >
                          <option value="">Select Subcategory</option>
                          {/* {subCategories
                            .filter(
                              (category) =>
                                category.category === formData.call_out_category
                            )
                            .flatMap((category) =>
                              category.sub_categories.map((subCategory) => (
                                <option key={subCategory} value={subCategory}>
                                  {subCategory}
                                </option>
                              ))
                            )} */}

                          {formData.call_out_category &&
                            subCategories[formData.call_out_category]?.map(
                              (subCategory, index) => (
                                <option key={index} value={subCategory}>
                                  {subCategory}
                                </option>
                              )
                            )}
                        </select>
                        {/* <select
                        id="sub_category"
                        name="sub_category"
                        className="form-select"
                        value={formData.sub_category}
                        onChange={handleChange}
                        disabled={
                          !formData.category || subCategories.length === 0
                        } // Disable if no category selected or sub-categories not loaded
                        required
                      >
                        <option value="">Select Subcategory</option>
                        {subCategories
                          .filter(
                            (category) => category.genre === formData.category
                          )
                          .flatMap((category) =>
                            category.sub_genres.map((subCategory, index) => (
                              <option key={index} value={subCategory}>
                                {subCategory}
                              </option>
                            ))
                          )}
                      </select> */}
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="sub_category" className="form-label">
                        Subcategory
                      </label>
                      <select
                        id="sub_category"
                        name="sub_category"
                        className="form-select"
                        value={formData.sub_category}
                        onChange={handleChange}
                        disabled={
                          !formData.category || subCategories.length === 0
                        } // Disable if no category selected or sub-categories not loaded
                        required
                      >
                        <option value="">Select Subcategory</option>
                        {subCategories
                          .filter(
                            (category) => category.genre === formData.category
                          )
                          .map((category) =>
                            category.sub_genres.map((subCategory, index) => (
                              <option key={index} value={subCategory}>
                                {subCategory}
                              </option>
                            ))
                          )}
                      </select>
                    </div> */}

                    {/* <Form.Group controlId="subCategory">
                      <Form.Label>Subcategory</Form.Label>
                      <Form.Control
                        as="select"
                        name="subCategory"
                        value={formData.subCategory}
                        onChange={handleChange}
                        disabled={!formData.category}
                      >
                        <option value="">Select subcategory...</option>
                        {formData.subCategories
                          .filter(
                            (subCat) => subCat.category === formData.category
                          )
                          .map((subCat) => (
                            <option
                              key={subCat.sub_category}
                              value={subCat.sub_category}
                            >
                              {subCat.sub_category}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group> */}
                    
                    {/* <div className="mb-3">
                      <label htmlFor="call_out_type" className="form-label">
                        Callout Type
                      </label>
                      <select
                        id="call_out_type"
                        name="call_out_type"
                        className="form-select"
                        value={formData.call_out_type}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Callout Type</option>
                      </select>
                    </div> */}

                    <div className="inputwith-title">
                      <p className="para-style">Short Description</p>
                      <textarea
                        className="form-control mb-3 shadow-box textarea-style"
                        placeholder="Short Description"
                        name="short_description"
                        value={formData.short_description}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>

                    <Row style={{ marginTop: "-5%" }}>
                      <p>Date and Time</p>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">From</p>
                          <Datetime
                            inputProps={{
                              className: "form-control shadow-box input-style",
                              readOnly: true,
                              placeholder: "Select Date and Time",
                            }}
                            // dateFormat={true}
                            dateFormat="DD/MM/YYYY"
                            onChange={(date) =>
                              handleChange({
                                target: { value: date, name: "from" },
                              })
                            }
                            value={formData.from}
                            timeConstraints={{
                              minutes: {
                                step: 30,
                              },
                            }}
                            isValidDate={valid}
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style">To</p>
                          <Datetime
                            inputProps={{
                              className: "form-control shadow-box input-style",
                              readOnly: true,
                              placeholder: "Select Date and Time",
                            }}
                            // dateFormat={true}
                            dateFormat="DD/MM/YYYY"
                            onChange={(date) =>
                              handleChange({
                                target: { value: date, name: "to" },
                              })
                            }
                            value={formData.to}
                            timeConstraints={{
                              minutes: {
                                step: 30,
                              },
                            }}
                            isValidDate={valid}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">Currency</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="currency"
                            name="price_point_currency"
                            value={formData.price_point.currency}
                            onChange={handleChange}
                            disabled
                          // required
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style mb-4">Price Point</p>
                          {/* <input
                            type="number"
                            className="form-control shadow-box input-style"
                            id="price_point"
                            name="price_point_value"
                            placeholder="Price point"
                            value={formData.price_point.value}
                            onChange={handleChange}
                          /> */}
                          {/* <Slider
                            min={0}
                            max={10000}
                            value={formData.price_point.value}
                            onChange={(value) => handleChange({ target: null }, value)}
                            railStyle={{ background: "#d3d3d3", height: "4px" }}
                            trackStyle={{ background: "#007bff", height: "4px" }}
                            handleStyle={{
                              borderColor: "#007bff",
                              height: "14px",
                              width: "14px",
                              marginLeft: "-7px",
                              marginTop: "-5px",
                              backgroundColor: "#007bff",
                            }}
                            range
                            required
                          /> */}
                          {/* <Slider
                            value={priceRange}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={10000}
                            step={10}

                          /> */}

                          <div style={{ position: "relative", width: "100%" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                left: "0",
                              }}
                            >
                              ${formData.price_point.value_from}
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                right: "0",
                              }}
                            >
                              ${formData.price_point.value_to}
                            </span>

                            <Slider
                              value={[
                                formData.price_point.value_from,
                                formData.price_point.value_to,
                              ]}
                              onChange={(event, newValue) =>
                                handleChange({
                                  target: {
                                    name: "price_point_value",
                                    value: newValue,
                                  },
                                })
                              }
                              valueLabelDisplay="auto"
                              style={{ top: "2px" }}
                              min={0}
                              max={5000}
                              step={10}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  {/* <p className="para-style">Description</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="floatingInput"
                    placeholder="Enter a detailed description here"
                    name="descriptions"
                    value={formData.descriptions}
                    onChange={handleChange}
                  ></textarea> */}
                  {/* <p className="para-style" style={{ marginTop: "2%" }}>
                    Description Heading
                  </p>
                  <input
                    type="text"
                    className="form-control shadow-box input-style"
                    id="description_heading"
                    name="description_heading"
                    value={formData.descriptions[0].heading}
                    onChange={(e) =>
                      handleDescriptionChange(0, "heading", e.target.value)
                    }
                    required
                  />

                  <p className="para-style">Description Body</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="description_body"
                    name="description_body"
                    value={formData.descriptions[0].body[0]}
                    onChange={(e) =>
                      handleDescriptionChange(0, "body", e.target.value)
                    }
                    required
                  ></textarea> */}
                  {formData.descriptions.map((description, index) => (
                    <div key={index}>
                      <p className="para-style">Description</p>
                      <textarea
                        className="form-control shadow-box textarea-style"
                        id="floatingInput"
                        placeholder="Enter a detailed description here"
                        value={description}
                        onChange={(e) => handleDescriptionChange(index, e.target.value)}
                        required
                        style={{ minHeight: "800px", resize: "vertical" }} // Adjust minHeight as needed
                      ></textarea>
                      <br />
                    </div>
                  ))}

                  <br></br>
                  <div
                    className="button-container"
                    style={{ marginTop: "18px" }}
                  >
                    <div className="text-center">
                      {/* {loading ? (
                        <Button variant="primary" disabled>
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        </Button>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary"
                            style={{
                              marginRight: "5%",
                              borderRadius: "5px",
                              padding: "0.75rem 1.5rem",
                            }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              padding: "0.75rem 1.5rem",
                            }}
                            className="btn btn-outline-primary"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </>
                      )} */}
                      <button type="button"
                        onClick={handleCancel}
                        style={{
                          marginRight: "5%",
                          borderRadius: "5px",
                          padding: "0.75rem 1.5rem",
                        }}
                        className="btn btn-outline-primary">
                        Cancel
                      </button>

                      <button
                        style={{
                          borderRadius: "5px",
                          padding: "0.75rem 1.5rem",
                          minWidth: "110px",
                          cursor: loading ? "not-allowed" : "pointer",                          
                          opacity: loading ? 0.6 : 1, // Reduce opacity when loading
                          transition: "opacity 0.3s ease", // Smooth transition for opacity change
                        }}
                        className="btn btn-primary"
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit}
                        
                      >
                        {/* {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )} */}
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCalloutbyVenue;
