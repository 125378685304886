import React, { useState, useEffect } from "react";
import "../venue/opportunity.css";
// import descriptionImage from "../../assect/images/BackgroundImage.jpg";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CalloutView = () => {
  const [callout, setCallout] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const { calloutId } = useParams();
  const selectedOpportunityId = calloutId || Cookies.get("calloutId");
  const [profileOwnerUserId, setProfileOwnerUserId] = useState(null);
  // const profileOwnerId = Cookies.get("profileOwnerId");
  const profileOwnerId = Cookies.get("profileId");
  const profileOwnerId2 = Cookies.get("profileOwnerId");
  const loggedInUserId = Cookies.get("userId");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCallout = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/call_out/view/${selectedOpportunityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCallout(response.data.call_out);
      } catch (error) {
        console.error("Error fetching callout:", error);
      }
    };

    fetchCallout();
  }, [selectedOpportunityId, accessToken]);

  useEffect(() => {
    const fetchProfileOwnerUserId = async () => {
      try {
        let response;
        if (profileOwnerId.startsWith("PROFILE-PERFORMER")) {
          response = await axiosInstance.get(
            `/v1/performer/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.performer_info.user_id);
          }
        } else if (profileOwnerId.startsWith("PROFILE-VENUE")) {
          response = await axiosInstance.get(
            `/v1/venue/view-profile/${profileOwnerId}/section/complete`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (response.status === 200) {
            setProfileOwnerUserId(response.data.venue_info.user_id);
          }
        }
      } catch (error) {
        console.error("Error fetching profile owner user ID:", error);
      }
    };

    fetchProfileOwnerUserId();
  }, [profileOwnerId, accessToken]);

  const formatDate = (dateObject) => {
    if (!dateObject) return "";
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  };

  // const formatTime = (timeObject) => {
  //   if (!timeObject) return "";
  //   const { hour, minute, am_pm } = timeObject;
  //   return `${hour}:${minute} ${am_pm}`;
  // };

  const formatTime = (timeObject) => {
    if (!timeObject) return "";
    const { hour, minute, am_pm } = timeObject;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${hour}:${formattedMinute} ${am_pm}`;
  };

  const handleApplyButtonClick = () => {
    const toProfileId = callout.author_profile_id;
    console.log("opp auther profile id: " + toProfileId);
    Cookies.set("toProfileId", toProfileId);
    // Cookies.set("calloutId", calloutId);
    navigate("/messaging");
  };

  const handleSendMessage = async (toProfileId) => {
    try {
      console.log(callout.title);
      const response = await axiosInstance.post(
        `/v1/messaging/create_conversation_for_call_out/${profileOwnerId2}/${toProfileId}/${Cookies.get(
          "call_outId"
        )}`,
        { title: callout.title },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log(response);

        const { call_out_id } = response.data;

        const titleResponse = await axiosInstance.get(
          `/v1/call_out/view/${call_out_id}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (titleResponse.status === 200) {
          const title = titleResponse.data.call_out.title;
          navigate(`/messaging/${title}`);

          console.log("Title:", title);
        } else {
          console.error(
            "Error fetching classified title:",
            titleResponse.statusText
          );
        }
      } else {
        console.error("Error creating conversation:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
      let errorMessage = "There was an error creating conversation.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  return (
    <div
      className="container-fluid"
      style={{
        // backgroundImage: `url(${descriptionImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="row">
        <div className="oppcontainer">
          <div className="oppbox">
            {callout && (
              <>
                <div className="oppdescription">
                  {callout.short_description}
                </div>
                <div className="oppdetails">
                  <div className="oppdetailRow">
                    <strong>Venue:</strong> <span>{callout.venue}</span>
                  </div>
                  <div className="oppdetailRow">
                    <strong>Type:</strong> <span>{callout.call_out_type}</span>
                  </div>
                  {/* <div className="oppdetailRow">
                    <strong>Performer:</strong>{" "}
                    <span>{callout.performers.performer_name}</span>
                  </div> */}
                  <div className="oppdetailRow">
                    <strong>Category:</strong>{" "}
                    <span>{callout.call_out_category}</span>
                  </div>
                  <div className="oppdetailRow">
                    <strong>Sub Category:</strong>{" "}
                    <span>{callout.call_out_sub_category}</span>
                  </div>
                  {/* <div className="oppdetailRow">
                    <strong>Genre:</strong> <span>{callout.genre}</span>
                  </div> */}
                  <div className="oppdetailRow">
                    <strong>Date:</strong>{" "}
                    <span>
                      {formatDate(callout.from_date)} to{" "}
                      {formatDate(callout.to_date)}
                    </span>
                  </div>
                  <div className="oppdetailRow">
                    <strong>Duration:</strong>{" "}
                    <span>
                      {formatTime(callout.from_time)} to{" "}
                      {formatTime(callout.to_time)}
                    </span>
                  </div>
                  <hr className="hrStyle" />
                  <div>
                    <strong>Description:</strong> {callout.descriptions}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="buttonsContainer">
            {profileOwnerUserId !== loggedInUserId && (
              <button
                className="button"
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{ padding: "10px 60px" }}
                onClick={() =>
                  handleSendMessage(Cookies.get("toProfileIdFirst"))
                }
                // onClick={handleApplyButtonClick}
              >
                Message
              </button>
            )}
            {/* <button
              className="button"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              style={{ padding: "10px 60px" }}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const handleHover = (e) => {
  e.target.classList.toggle("hovered");
};

export default CalloutView;
