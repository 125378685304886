import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axiosInstance from "../auth/axiosInstance";
import pic from "../../assect/images/landing/Ozstream_Monomark.png";
import logo from "../../assect/images/landing/OgotrixLogo.png";
import performer from "../../assect/images/landing/Performer.jpg";
import venue from "../../assect/images/landing/Venue.jpg";
import opportunity from "../../assect/images/landing/Opportunity.jpg";
import callout from "../../assect/images/landing/CallOut.jpg";
import classified from "../../assect/images/landing/Classifieds.jpg";
import booking from "../../assect/images/landing/Connect.jpg";
import PricingPage from "../../components/pricing";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar/loggedOutUserNavbar";
import Spinner from "react-bootstrap/Spinner";
import AOS from "aos";
import "aos/dist/aos.css";

const PageWrapper = styled.div`
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: center;  
  justify-content: space-between;
  padding: ${(props) => (props.isMobile ? "80px 20px" : "80px")};
  width: 100%;  
  box-sizing: border-box;
  position: relative; /* Ensure the parent is relative if using absolute positioning */
  right: 0; /* Aligns it to the right edge of the parent */  
  justify-content: flex-end; /* Aligns child elements to the right */
`;

const StyledHeading = styled.h1`
  color: #1a1a1a;
  font-size: 2.5em;
  margin-bottom: 0;

  @media (max-width: 768px) {
    margin-left: 5%; 
  }
`;

const StyledParagraph = styled.p`
  font-size: 1em;
  color: #1a1a1a;

  @media (max-width: 768px) {
    margin-left: 5%; 
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.isMobile ? "center" : "flex-start")};
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
  margin-top: ${(props) => (props.isMobile ? "20px" : "80px")};
  margin-left: 5%;
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: right;  
  margin-right: -6%;
  margin-top: ${(props) => (props.isMobile ? "20px" : "80px")};
`;

const Header = styled.div`
  font-size: 3.5em;
  color: #1a1a1a;
  line-height: 1.2;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};

  @media (max-width: 768px) {
    font-size: 3em; 
  }
`;

const SubHeader = styled.p`
  font-size: 1.2em;
  color: #1a1a1a;
  margin: 1px 0;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};

  @media (max-width: 768px) {
    font-size: 1em; 
  }
`;

const SubscribeWrapper = styled.div`
  display: flex;  
  align-items: center;
  flex-direction: row;
  width: 70%; 
  justify-content: flex-end; /* Aligns items to the right */
  position: absolute; 
  margin: 20px; /* Optional: Add some margin if needed */

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 10%;
  }
   
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 25px;
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Input = styled.input`
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
  padding: 10px 30px;
  font-size: 1em;
  flex: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  // margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const ButtonN = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6; 
    cursor: not-allowed;
  }

  @media (max-width: 768px) {    
    width: 100%;
    margin-Top: 5px;
    margin-right: 10px;
  }
`;

const Button = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
  
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "20px" : "40px")};
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const Image = styled.img`
  width: 55%;
  height: auto;
  max-width: 55%;
  object-fit: cover;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const CourseCard = styled.div`
  
  @media (max-width: 768px) {
      margin-left: 8%; 
    }
  
`;


const CourseCardContainer = styled.div`
  flex: 1 1 300px;
  margin: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: left;
  position: relative;
  
`;

const CourseCardImage = styled.img`  
  width: 100%;
  height: 200px;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  transition: transform 0.3s ease-in-out; // Add this line

  &:hover {
    transform: scale(1.1);
  
`;

const CourseCardDetails = styled.div`
  padding: 20px;
  position: relative;
`;

const CourseCardTitle = styled.h3`
  margin: 0;
  font-size: 1.2em;
  color: #333;
  text-align: left;
`;

const CourseCardCategory = styled.h4`
  margin: 10px 0;
  color: #007bff;
`;

const CourseCardDescription = styled.p`
  text-align: justify;
`;

// const PricingContainer = styled.div`
//   background-color: #ffffff;
//   padding: 50px;
//   text-align: center;
// `;

// Keyframes for fade-in-up animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components for the section and text
const SectionB = styled.section`  
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #102733;  // This will be the background behind the curve  
  text-align: center;
  position: relative;
  z-index: -15;
  
`;

const Title = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color:white;
  opacity: ${(props) => (props.inView ? 1 : 0)};
  transform: ${(props) =>
    props.inView
      ? 'translateY(0) scale(1)'
      : 'translateY(20px) scale(0.8)'};
  transition: opacity 3s ease-out, transform 3s ease-out;
`;

const Text = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin: 10px 0;
  color:white;
  text-align: center;
  opacity: ${(props) => (props.inView ? 1 : 0)};
  transform: ${(props) =>
    props.inView
      ? 'translateY(0) scale(1)'
      : 'translateY(20px) scale(0.8)'};
  transition: opacity 3s ease-out, transform 3s ease-out;
`;

// Create a styled component for the SVG curve
const Curve = styled.svg`
  position: relative;
  display: block;
  width: 100%;
  height: auto;  
  margin-top: -5px;  // To overlap the content slightly and create a seamless effect
`;

// Styled components for the section and text
const SectionC = styled.section`  
  max-width: 100%;
  margin: 0 auto;
  padding: 80px 200px;
  background-color: white;  // This will be the background behind the curve  
  text-align: center;
  position: relative;   
  z-index: -15;
  
  @media (max-width: 768px) {
    padding: 60px 20px; // Reduce padding on mobile devices
  }
`;

const Title2 = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color:black;  
`;

const Link = styled.a`
  color: #2b4eff;
  text-decoration: underline !important; // Ensure underline is applied  
`;


const LandingPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [bannerData, setBannerData] = useState([]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");
  const MySwal = withReactContent(Swal);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribeloading, setSubscribeloading] = useState(false);

  const handleLogin = () => {
    navigate("/signup");
  };

  useEffect(() => {
    AOS.init({
      duration: 2500, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
  }, []);

  const CourseCard = ({ category, title, description, image }) => {
    return (
      <CourseCardContainer>
        <CourseCardImage src={image} alt={title} />
        <CourseCardDetails>
          <CourseCardCategory>{category}</CourseCardCategory>
          <CourseCardTitle>{title}</CourseCardTitle>
          <CourseCardDescription>{description}</CourseCardDescription>
          <Button onClick={handleLogin}>Know Details</Button>
        </CourseCardDetails>
      </CourseCardContainer>
    );
  };



  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    setIsVisible(true); // Trigger the animation on load
  }, []);

  // useInView hook to track element visibility
  const { ref: sectionRef, inView } = useInView({
    triggerOnce: true,  // Animates only once
    threshold: 0.5,     // Element is considered in view when 20% of it is visible
  });

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axiosInstance.get("/v1/banner/top", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setBannerData(response.data.data.banner);
        console.log("banner data: ", response.data.data.banner);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);

  const handleSubscribe = async () => {
    setSubscribeloading(true);
    const payload = { email: email };

    try {
      const response = await axiosInstance.post(
        "/v1/newsletter/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        MySwal.fire({
          title: "Success!",
          text: "Thank you for your subscription!",
          icon: "success",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      } else {
        MySwal.fire({
          title: "Error",
          text: "Subscription failed. Please try again.",
          icon: "error",
          confirmButtonText: '<i class="fa fa-times"></i> Retry',
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      }
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "An error has occurred.Please try again.",
        icon: "error",
        confirmButtonText: '<i class="fa fa-times"></i> Retry',
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
    } finally {
      setSubscribeloading(false);
    }
  };

  return (
    <>
      <PageWrapper>
        {/* <div>
      {bannerData ? (
        <div>
          <h1>{bannerData.title}</h1>
          <img src={bannerData.banner_url} alt={bannerData.description} />
          <p>{bannerData.description}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div> */}
        {/* <NavBar isMobile={isMobile}>
          <Logo src={logo} alt="Logo" />
          <div>
            <Button onClick={handleHome}>Sign In</Button>
            <Button onClick={handleSignup}>Sign up</Button>
          </div>
        </NavBar> */}
        <NavigationBar />

        <Container isMobile={isMobile}>
          <LeftColumn isMobile={isMobile}>
            <Header isMobile={isMobile} data-aos="zoom-in">
              <strong>Bridging the gap between Talent and Opportunity</strong>
            </Header>
            <SubHeader isMobile={isMobile} data-aos="zoom-in">
              <strong>Unlimited access to all opportunities.</strong>
              <br />
              {/* <p style={{ fontSize: "1em", color: "#666" }}>Only for $9.99 per month</p> */}
            </SubHeader>

            {/* <p style={{ fontSize: "1em", color: "#666", textAlign: isMobile ? 'center' : 'left' }}>
              You're guaranteed to find something that's right for you
            </p> */}
          </LeftColumn>
          <RightColumn isMobile={isMobile} data-aos="zoom-in">
            <Image src={pic} alt="Talent" />
          </RightColumn>
        </Container>
      </PageWrapper>
      {/* First Curve (Upper part white, lower part dark) */}


      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 115">
        <rect fill="#f0f0f0" width="1440" height="115" />
        <path fill="#102733" fillOpacity="1" d="M0,100L60,84C120,68,240,36,360,36C480,36,600,68,720,89.3C840,110,960,120,1080,104.3C1200,87,1320,45,1380,23.3L1440,4L1440,120L1380,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"></path>
      </svg>
      <SectionB ref={sectionRef}>
        <Title inView={inView} >Bridging the gap between Talent and Opportunity!</Title>
        <Text inView={inView} >
          Built for you, from the heart, by the minds at Ozstream, GigBridge was created as a way of
          facilitating and cultivating direct relationships between performers and venues.
        </Text>
        <Text inView={inView} >
          Soft-launching from October 2024, GigBridge will initially be available by special invitation
          for 100 musicians and 25 live music venues.
        </Text>
        <Text inView={inView} >
          GigBridge will then move into its pilot launch phase in early 2025 for musicians and venues located in the ACT and S/E NSW.
        </Text>
        <Text inView={inView} >
          As we navigate our soft-launch and pilot phases, we’ll be asking for lots of feedback from our users
          to understand how they’re using GigBridge and to see where opportunities exist to introduce new features
          and functionality.
        </Text>
        <Text inView={inView} >
          If our planned timelines hold true, our aim is to see GigBridge made available for all types of performing
          artists, businesses and opportunity creators in September of 2025.
        </Text>
        <Text inView={inView} >
          No matter whether you’re a performer or venue, GigBridge fosters connection, communication, and collaboration,
          helping venues discover performers, and for performers to discover opportunities for work.
        </Text>
        <Text inView={inView} >
          Join the GigBridge revolution and empower yourself to go and do something new – let the spotlight find you and
          sign up today!
        </Text>
      </SectionB>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
        <rect width="1440" height="120" fill="white" />
        <path fill="#102733" fillOpacity="1" d="M0,100L60,84C120,68,240,36,360,36C480,36,600,68,720,89.3C840,110,960,120,1080,104.3C1200,87,1320,45,1380,23.3L1440,4L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
      </svg>




      <PricingPage />

      <section className="courses-section" style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }} >
        <div className="container" >
          <StyledHeading>
            <strong data-aos="zoom-in">Elevate Your Musical Journey</strong>
          </StyledHeading>
          <StyledParagraph data-aos="zoom-in">
            Explore and Enhance Your Skills in Every Note...
          </StyledParagraph>
          <div className="row" data-aos="zoom-in">
            <CourseCard
              category="Performers"
              // title="Master the Stage: Performer Training"
              description="Showcase your talent and help the spotlight find you, GigBridge is your digital stage for self-promotion."
              image={performer}
            />
            <CourseCard
              category="Venues"
              // title="Venue Vibes: Hosting Music Magic"
              description="Now you’re in control, GigBridge helps you choose the entertainment line-up that suits the needs of your venue!
"
              image={venue}
            />
            <CourseCard

              category="Opportunities"
              // title="Golden Opportunities: Your Next Gig Awaits"              
              description={
                <>
                  The missing link! Created by venues! Discoverable by performers!
                  <br />
                  <br />
                </>
              }

              image={opportunity}
            />
          </div>
          <div className="row" data-aos="zoom-in">
            <CourseCard
              category="Callouts"
              // title="Callout Connections: Join the Next Big Event"
              description=" Get your message out there and let others know what you’re up to and what you’d like to do next!"
              image={callout}
            />
            <CourseCard
              category="Classifieds"
              // title="Market Beats: Music Gear Classifieds"
              description="Looking to buy or sell music equipment, there’s no better place to let others know!"
              image={classified}
            />
            <CourseCard
              category="Connect"
              // title="Soundcheck: Event Booking Essentials"
              description=" Foster new connections, find like-minded people, build valuable industry relationships!"
              image={booking}
            />
          </div>
        </div>
      </section>

      <SectionC>
        <h1 style={{ color: '#1a1a1a', marginBottom: '30px', fontWeight: "bold" }} data-aos="zoom-in">
          Subscribe or connect with us to get important information and updates!

        </h1>
        <br></br>
        <br></br>
        <br></br>
        <SubscribeWrapper data-aos="zoom-in">
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
          />
          <ButtonN onClick={handleSubscribe} disabled={subscribeloading}
            style={{
              opacity: subscribeloading ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
              cursor: subscribeloading ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
              transition: "opacity 0.3s ease", // Smooth transition for opacity change
              minWidth: "210px",
            }}>
            {subscribeloading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "SUBSCRIBE FOR NEWSLETTER"
            )}

          </ButtonN>
        </SubscribeWrapper>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br />
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Link href="https://linktr.ee/ozstream" target="_blank" rel="noopener noreferrer">
            Ogotrix Link Tree
          </Link>
        </div>

      </SectionC >


      <Footer />
    </>
  );
};

export default LandingPage;
