import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import * as yup from "yup";
import AutoComplete from "../../components/autoComplete";
import { MultiSelect } from "react-multi-select-component";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMusic,
  faGuitar,
  faStar,
  faFilm,
  faMusicNote,
  faMicrophone,
  faHatCowboy,
  faHeadphones,
  faMapMarkerAlt,
  faChevronDown,
  faWarehouse,
  faBriefcase,
  faBuilding,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import axiosInstance from "../auth/axiosInstance";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  padding-top: 100px;

  @media (max-width: 768px) {
    padding-top: 100px; // Adjust margin as needed for spacing
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const ButtonB = styled.button`
  background-color: #2b4eff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile
      ? "20px"
      : "40px"}; /* Adjusted padding for better alignment */
  position: fixed; /* Fixed position to keep navbar at the top */
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000; /* Ensure navbar is above other elements */

  /* Media query for mobile view */
  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile view */
    margin-top: -110px;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-top: 5px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
  color: #1a1a1a;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  color: #666;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;

  &.rotated {
  transform: translateY(-50%) rotate(180deg);
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SignInLink = styled.p`
  margin-top: 15px;
  color: #666;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function CreateVenueProfile() {
  const [formData, setFormData] = useState({
    profile_name: "",
    business_name: "",
    venue_genres: [],
    venue_types: [],
    location: {
      suburb: "",
      zipcode: "",
      latitude: 0,
      longitude: 0,
      location_name: "",
      address: "",
      google_map_pin: "",
    },
  });

  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTypesDropdown, setShowTypesDropdown] = useState(false);
  const [showGenresDropdown, setShowGenresDropdown] = useState(false);
  const typesInputRef = useRef(null);
  const genresInputRef = useRef(null);
  const navigate = useNavigate();

  // Hardcoded venue types
  const types = [
    "Bar",
    "Beer Garden",
    "Cafe",
    "Chill",
    "Club",
    "Community Event",
    "Contemporary",
    "Festival",
    "Funky",
    "Hotel",
    "Live Performance",
    "Lounge",
    "Modern",
    "Nightclub",
    "Pub",
    "Restaurant",
    "Winery",
    "Wine Bar",
    "Sports Club",
  ];

  const genres = [
    "Alternative",
    "Blues",
    "Chill",
    "Classics",
    "Country",
    "Disco",
    "DJ",
    "Drum and Bass",
    "Dub",
    "Easy Listening",
    "Electroacoustic",
    "Electronic Dance Music",
    "Electronic",
    "Electronica",
    "Ethnic",
    "Experimental",
    "First Nations",
    "Folk",
    "Funk",
    "Hardcore",
    "Hardstyle",
    "Heavy Metal",
    "Hip Hop",
    "House Music",
    "Indie Rock",
    "Indigenous",
    "Industrial",
    "Intelligent Dance Music",
    "Jazz",
    "Jungle",
    "K-Pop",
    "Latin",
    "New-Age",
    "Plunderphonics",
    "Pop",
    "Popular Music",
    "Punk Rock",
    "Reggae",
    "Rhythm and Blues",
    "Rock",
    "Singing",
    "Ska",
    "Soul",
    "Synth-Pop",
    "Techno",
    "World Music",
  ];

  // Example function that sets the error message
  const showError = (message) => {
    setErrorMessage(message);
  };

  // Clear the error message after 3 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      // Cleanup the timer if the component unmounts or errorMessage changes
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       console.log("Fetching data...");
  //       const [genresResponse, typesResponse] = await Promise.all([
  //         axiosInstance.get("/v1/venue/venue-genres", {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }),
  //         axiosInstance.get("/v1/venue/venue-types", {
  //           headers: {
  //             Authorization: `Bearer ${Cookies.get("accessToken")}`,
  //           },
  //         }),
  //       ]);

  //       console.log("Data received:", typesResponse, genresResponse);

  //       if (genresResponse.status === 200 && typesResponse.status === 200) {
  //         console.log("Performer types response data:", typesResponse.data);
  //         setGenres(genresResponse.data.venue_genres);
  //         setTypes(typesResponse.data.venue_types);
  //       } else {
  //         setErrorMessage("Error fetching data.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setErrorMessage("An unexpected error occurred. Please try again.");
  //     }
  //   }

  //   fetchData();
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "profile_name" && value.length > 100) {
      Swal.fire({
        title: "Error",
        text: "Profile name cannot exceed 100 characters.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      location: { ...prevData.location, [name]: value },
    }));
  };

  const handleTypeChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedTypes.length < 3) {
        setSelectedTypes([...selectedTypes, value]);
      } else {
        setErrorMessage("You can only select a maximum of 3 performer types.");
      }
    } else {
      setSelectedTypes(selectedTypes.filter((type) => type !== value));
    }
  };

  const handleGenreChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      if (selectedGenres.length < 7) {
        setSelectedGenres([...selectedGenres, value]);
      } else {
        setErrorMessage("You can only select a maximum of 7 genres.");
      }
    } else {
      setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      type: "VENUE",
      ...formData,
      venue_genres: selectedGenres,
      venue_types: selectedTypes,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/venue/create-profile",
        payload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const profileId = response.data.profile_id;
        // Cookies.set("profileId", profileId, { secure: true });
        Cookies.set("profileOwnerId", profileId);

        const profileResponse = await axiosInstance.get(
          `/v1/venue/view-profile/${profileId}/section/complete`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (profileResponse.status === 200) {
          const profileStatus = profileResponse.data.venue_info.status;

          if (
            profileStatus === "PAYMENT_DETAILS_PENDING" &&
            profileId.startsWith("PROFILE-PERFORMER")
          ) {
            navigate("/performer-pricing");
            // navigate(`/performer/${profileId}`);
          } else if (
            profileStatus === "PAYMENT_DETAILS_PENDING" &&
            profileId.startsWith("PROFILE-VENUE")
          ) {
            navigate("/venue-pricing");
            // navigate(`/venue/${profileId}`);
          } else if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-PERFORMER")
          ) {
            navigate(`/performer/${profileId}`);
          } else if (
            profileStatus === "FRESH" &&
            profileId.startsWith("PROFILE-VENUE")
          ) {
            navigate(`/venue/${profileId}`);
          } else {
            Swal.fire({
              title: "Success!",
              text: "Your profile has been created successfully!",
              icon: "success",
              className: "swal-modal",
            }).then(() => {
              // navigate(`/venue/${profileId}`);
              navigate(`/venue-pricing`);
            });
          }
        } else {
          setErrorMessage("Error fetching profile status.");
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            className: "swal-modal",
          });
        }
      } else {
        setErrorMessage(response.data.error);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTypesInputClick = () => {
    setShowTypesDropdown(!showTypesDropdown);
  };

  const handleGenresInputClick = () => {
    setShowGenresDropdown(!showGenresDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      typesInputRef.current &&
      !typesInputRef.current.contains(event.target)
    ) {
      setShowTypesDropdown(false);
    }
    if (
      genresInputRef.current &&
      !genresInputRef.current.contains(event.target)
    ) {
      setShowGenresDropdown(false);
    }
  };

  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/profiles`);
    } catch (error) {
      console.error("Error navigating to performer profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <Title>Create Venue Profile</Title>

      <FormWrapper onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="venueName">Venue Name</Label>
          <InputWrapper>
            <Icon icon={faWarehouse} />
            <Input
              type="text"
              className="form-control"
              id="venueName"
              placeholder="Venue Name"
              name="profile_name"
              value={formData.profile_name}
              onChange={handleChange}
              required
            />
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="businessName">Business Name</Label>
          <InputWrapper>
            <Icon icon={faBriefcase} />
            <Input
              type="text"
              className="form-control"
              id="businessName"
              placeholder="business Name"
              name="business_name"
              value={formData.business_name}
              onChange={handleChange}
              required
            />
          </InputWrapper>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="venue_types">Venue Types </Label>
          <InputWrapper>
            <Icon icon={faBuilding} />
            <Input
              type="text"
              className="form-control"
              id="venue_types"
              name="venue_types"
              placeholder="Select up to 3 Types"
              value={selectedTypes.join(", ")}
              onFocus={handleTypesInputClick}
              readOnly
            />
            <DropdownIcon
              icon={faChevronDown}
              className={showTypesDropdown ? "rotated" : ""}
              onClick={() => setShowTypesDropdown(!showTypesDropdown)}
            />
          </InputWrapper>
          {showTypesDropdown && (
            <div className="dropdown-content" ref={typesInputRef}>
              {types.length > 0 ? (
                types.map((type) => (
                  <div className="form-check" key={type}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`type-${type}`}
                      name="venue_types"
                      value={type}
                      checked={selectedTypes.includes(type)}
                      onChange={handleTypeChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`type-${type}`}
                    >
                      {type}
                    </label>
                  </div>
                ))
              ) : (
                <p>Loading types...</p>
              )}
            </div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="venue_genres">Venue Genres</Label>
          <InputWrapper>
            <Icon icon={faGuitar} />
            <Input
              type="text"
              className="form-control"
              id="venue_genres"
              name="venue_genres"
              placeholder="Select up to 7 Genres"
              value={selectedGenres.join(", ")}
              onFocus={handleGenresInputClick}
              readOnly
            />
            <DropdownIcon
              icon={faChevronDown}
              className={showGenresDropdown ? "rotated" : ""}
              onClick={() => setShowGenresDropdown(!showGenresDropdown)}
            />
          </InputWrapper>
          {showGenresDropdown && (
            <div className="dropdown-content" ref={genresInputRef}>
              {genres.length > 0 ? (
                genres.map((genre) => (
                  <div className="form-check" key={genre}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`genre-${genre}`}
                      name="venue_genres"
                      value={genre}
                      checked={selectedGenres.includes(genre)}
                      onChange={handleGenreChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`genre-${genre}`}
                    >
                      {genre}
                    </label>
                  </div>
                ))
              ) : (
                <p>Loading genres...</p>
              )}
            </div>
          )}
        </InputGroup>

        <InputGroup>
          <Label htmlFor="location_suburb">Location</Label>
          <InputWrapper>
            <Icon icon={faMapMarkerAlt} />
            <AutoComplete onSuburbSelect={handleSuburbSelect} />
          </InputWrapper>
        </InputGroup>
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>

        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
          </div>
        )}
      </FormWrapper>
    </Container>
  );
}
export default CreateVenueProfile;
