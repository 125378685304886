import React, { useState, useEffect, useRef } from "react";
import "./NavigationBar.css";
import gigbridgelogo from "../../assect/images/landing/OgotrixLogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaSearch,
  FaChartBar,
  FaUserCog,
  FaUser,
  FaBell,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import Cookies from "js-cookie";

import { FiLogOut } from "react-icons/fi";
import axiosInstance from "../../pages/auth/axiosInstance";

const NavigationBar = () => {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadNotCount, setUnreadNotCount] = useState(0);
  const [messageThreads, setMessageThreads] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const navigate = useNavigate();
  const notificationModalRef = useRef(null);
  const messageModalRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    const fetchUnreadCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          "/v1/notification/get_count_of_unopened_notification",
          {
            params: { target_id: profileId },
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log("Full API Response:", response);

        if (response.status === 200) {
          if (response.data) {
            console.log("API Response Data:", response.data);

            const countMatch = response.data.match(/count\s*:\s*(\d+)/);
            if (countMatch && countMatch[1]) {
              const count = parseInt(countMatch[1], 10);
              setUnreadNotCount(count);
              console.log("Unread Notifications Count:", count);
            } else {
              console.error("Count not found in response data.");
              setUnreadNotCount(0);
            }
          } else {
            console.error("Response data is undefined.");
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    fetchUnreadCount();
  }, []);

  const fetchNotifications = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        "/v1/notification/get_last_7_notification",
        {
          params: { target_id: profileId },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        setNotifications(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const handleNotificationClick = (e) => {
    e.preventDefault();
    setNotificationModal(!notificationModal);
    if (!notificationModal) fetchNotifications();
  };

  const handleClickOutside = (event) => {
    if (
      notificationModalRef.current &&
      !notificationModalRef.current.contains(event.target)
    ) {
      setNotificationModal(false);
    }

    if (
      messageModalRef.current &&
      !messageModalRef.current.contains(event.target)
    ) {
      setModal(false);
    }
  };

  useEffect(() => {
    if (notificationModal || modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationModal, modal]);

  useEffect(() => {
    const fetchUnreadMessagesCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          `/v1/messaging/get_all_unread_messge_count_by_profile/${profileId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          setUnreadCount(response.data.count || 0);
          console.log("unread msg count:", response.data);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    fetchUnreadMessagesCount();
  }, []);

  const fetchLastSevenMessages = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        `/v1/messaging/get_last_7_messge_by_profile/${profileId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        setMessageThreads(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? "active-link" : "";
  };

  return (
    <header id="topnav" className={isSticky ? "nav-sticky" : ""}>
      <section className="navigation">
        <div className="nav-container">
          <div className="brand">
            {/* <a href="#!">Gig Bridge LOGO</a> */}
            <img
              src={gigbridgelogo}
              alt="Gigbridge Logo"
              style={{ height: "60px" }}
            />
          </div>
          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!" onClick={toggleMobileMenu}>
                <span className={isMobile ? "active" : ""}></span>
              </a>
            </div>
            <ul className={`nav-list ${isMobile ? "active" : ""}`}>
              {/* <li>
                <a href="/profiles">Dashboard</a>
              </li> */}
              <li>
                {/* <a href="/profiles">Profile Hub</a> */}
                <Link to="/profiles" className={getActiveClass("/profiles")}>
                  Profile Hub
                </Link>
              </li>
              <li>
                {/* <a href="/account-page">Account</a> */}
                <Link
                  to="/account-page"
                  className={getActiveClass("/account-page")}
                >
                  Account
                </Link>
              </li>
              {/* <li>
                <a href="#!" onClick={() => handleDropdownClick(0)}>
                  Search
                </a>
                <ul
                  className={`nav-dropdown ${
                    activeDropdown === 0 ? "active" : ""
                  }`}
                >
                  <li>
                    <a href="/search/performer">Performer Search</a>
                  </li>
                  <li>
                    <a href="/search/venue">Venue Search</a>
                  </li>
                  <li>
                    <a href="/search/opportunity">Opportunity Search</a>
                  </li>
                  <li>
                    <a href="/search/call_out">CallOut Search</a>
                  </li>
                  <li>
                    <a href="/search/classified">Classifieds Search</a>
                  </li>
                  <li>
                    <a href="/saved-searches">Saved Searches</a>
                  </li>
                </ul>
              </li> */}

              {/* <li>
                <a href="#!">About Us</a>
              </li> */}
            </ul>
          </nav>
        </div>
      </section>
    </header>
  );
};

export default NavigationBar;
