import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../pages/auth/axiosInstance";

import {
  FaSearch,
  FaChartBar,
  FaUserCog,
  FaUser,
  FaBell,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";

export default function Navbar({ navClass }) {
  const [scroll, setScroll] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadNotCount, setUnreadNotCount] = useState(0);
  const [messageThreads, setMessageThreads] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const navigate = useNavigate();
  const notificationModalRef = useRef(null);
  const messageModalRef = useRef(null);

  const handleLogout = async () => {
    try {
      const accessToken = Cookies.get("accessToken");
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await axiosInstance.get("/v1/auth/sign-out", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("performerId");

      navigate("/login");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to log out. Please try again.",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    const fetchUnreadCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          "/v1/notification/get_count_of_unopened_notification",
          {
            params: { target_id: profileId },
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log("Full API Response:", response);

        if (response.status === 200) {
          if (response.data) {
            console.log("API Response Data:", response.data);

            const countMatch = response.data.match(/count\s*:\s*(\d+)/);
            if (countMatch && countMatch[1]) {
              const count = parseInt(countMatch[1], 10);
              setUnreadNotCount(count);
              console.log("Unread Notifications Count:", count);
            } else {
              console.error("Count not found in response data.");
              setUnreadNotCount(0);
            }
          } else {
            console.error("Response data is undefined.");
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    fetchUnreadCount();
  }, []);

  const fetchNotifications = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        "/v1/notification/get_last_7_notification",
        {
          params: { target_id: profileId },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        setNotifications(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const handleNotificationClick = (e) => {
    e.preventDefault();
    setNotificationModal(!notificationModal);
    if (!notificationModal) fetchNotifications();
  };

  const handleClickOutside = (event) => {
    if (
      notificationModalRef.current &&
      !notificationModalRef.current.contains(event.target)
    ) {
      setNotificationModal(false);
    }

    if (
      messageModalRef.current &&
      !messageModalRef.current.contains(event.target)
    ) {
      setModal(false);
    }
  };

  useEffect(() => {
    if (notificationModal || modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationModal, modal]);

  useEffect(() => {
    const fetchUnreadMessagesCount = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          `/v1/messaging/get_all_unread_messge_count_by_profile/${profileId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          setUnreadCount(response.data.count || 0);
          console.log("unread msg count:", response.data);
        }
      } catch (error) {
        console.error("Request Error:", error);
      }
    };

    fetchUnreadMessagesCount();
  }, []);

  const fetchLastSevenMessages = async () => {
    const profileId = Cookies.get("profileOwnerId");
    const accessToken = Cookies.get("accessToken");

    try {
      const response = await axiosInstance.get(
        `/v1/messaging/get_last_7_messge_by_profile/${profileId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.status === 200) {
        setMessageThreads(response.data.message || []);
      }
    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <header id="topnav" className={`${scroll ? "nav-sticky" : ""} ${navClass}`}>
      <div className="container" style={{ padding: "2%" }}>
        <div className="menu-extras">
          <div className="menu-item">
            <Link
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={toggleMenu}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <ul
          style={{
            textAlign: "right",
            marginRight: "-15%",
            marginTop: "-3%",
          }}
          className="buy-button list-inline mb-0"
        >
          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="#"
              className="btn btn-sm btn-icon btn-pills btn-primary position-relative"
              onClick={handleNotificationClick}
            >
              <FaBell className="icons" />
              {unreadNotCount > 0 && (
                <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                  {unreadNotCount}
                </span>
              )}
            </Link>

            {notificationModal && (
              <div
                className="dropdown-menu show p-3"
                style={{
                  position: "absolute",
                  top: "50px",
                  right: "0",
                  width: "300px",
                }}
                ref={notificationModalRef}
              >
                <ul className="list-unstyled">
                  {notifications.map((notification) => (
                    <li
                      key={notification.notification_id}
                      className="mb-2"
                      style={{
                        backgroundColor: notification.has_opened
                          ? "#f8f9fa"
                          : "#e9ecef",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <strong
                            style={{
                              color: notification.has_opened
                                ? "#6c757d"
                                : "#000",
                            }}
                          >
                            {notification.title}
                          </strong>
                          <p
                            style={{
                              color: notification.has_opened
                                ? "#6c757d"
                                : "#000",
                            }}
                            className="mb-0"
                          >
                            {notification.body}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Link to="/notifications" className="btn btn-primary btn-block">
                  Show More
                </Link>
              </div>
            )}
          </li>

          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="#"
              className="btn btn-sm btn-icon btn-pills btn-primary position-relative"
              onClick={(e) => {
                e.preventDefault();
                setModal(!modal);
                if (!modal) fetchLastSevenMessages();
              }}
            >
              <FaMessage className="icons" />
              {unreadCount > 0 && (
                <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                  {unreadCount}
                </span>
              )}
            </Link>

            {modal && (
              <div
                className="dropdown-menu show p-3"
                style={{
                  position: "absolute",
                  top: "50px",
                  right: "0",
                  width: "300px",
                }}
                ref={messageModalRef}
              >
                <ul className="list-unstyled">
                  {messageThreads.map((thread) => (
                    <li
                      key={thread.conversation_id}
                      className="mb-2"
                      style={{
                        backgroundColor: thread.has_opened_by_to
                          ? "#f8f9fa"
                          : "#e9ecef",
                      }}
                    >
                      <Link
                        to={`/messaging/${thread.conversation_id}`}
                        className="d-flex align-items-center"
                        onClick={() => setModal(false)}
                      >
                        <img
                          src={thread.from_profile_pic_url}
                          alt={thread.from_display_name}
                          className="rounded-circle me-2"
                          width="40"
                          height="40"
                        />
                        <div>
                          <strong
                            style={{
                              color: thread.has_opened_by_to
                                ? "#6c757d"
                                : "#000",
                            }}
                          >
                            {thread.from_display_name}
                          </strong>
                          <p
                            style={{
                              color: thread.has_opened_by_to
                                ? "#6c757d"
                                : "#000",
                            }}
                            className="mb-0"
                          >
                            {thread.last_message}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                <Link to="/messaging" className="btn btn-primary btn-block">
                  Show More
                </Link>
              </div>
            )}
          </li>

          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="/performer-search"
              className="btn btn-sm btn-icon btn-pills btn-primary"
            >
              <FaSearch className="icons" />
            </Link>
          </li>
          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="/dashboard-page"
              className="btn btn-sm btn-icon btn-pills btn-primary"
            >
              <FaChartBar className="icons" />
            </Link>
          </li>
          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="/profiles"
              className="btn btn-sm btn-icon btn-pills btn-primary"
            >
              <FaUserCog className="icons" />
            </Link>
          </li>
          <li className="list-inline-item ps-1 mb-0">
            <Link
              to="/account-page"
              className="btn btn-sm btn-icon btn-pills btn-primary"
            >
              <FaUser className="icons" />
            </Link>
          </li>
          <li className="list-inline-item ps-1 mb-0">
            <button
              onClick={handleLogout}
              type="button"
              className="btn btn-sm btn-icon btn-pills btn-primary"
            >
              <FiLogOut className="icons" />
            </button>
          </li>
        </ul>
      </div>
    </header>
  );
}
