import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import "./BookingForm.css";
import { Button, Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
const BookingForm = ({
  initialData,
  profileId,
  closeFirstModal,
  closeSecondModal,
  closeThirdModal,
}) => {
  const [loading, setLoading] = useState(false);
  const fromDate = initialData.fromDate;
  const toDate = initialData.toDate;
  const fromTime = initialData.fromTime;
  const toTime = initialData.toTime;
  const fromTimeDisplay = `${fromTime.hour} : ${
    fromTime.minute == 0 ? "00" : fromTime.minute
  } : ${fromTime.am_pm}`;
  const toTimeDisplay = `${toTime.hour} : ${
    toTime.minute == 0 ? "00" : toTime.minute
  } : ${toTime.am_pm}`;
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    from_date: { year: 0, month: 0, date: 0 },
    to_date: { year: 0, month: 0, date: 0 },
    from_time: { hour: 0, minute: 0, am_pm: "" },
    to_time: { hour: 0, minute: 0, am_pm: "" },
    // from_date: initialData.fromDate,
    // to_date: initialData.toDate,
    // from_time: initialData.fromTime,
    // to_time: initialData.toTime,
  });

  const [dateTime, setDateTime] = useState({
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    console.log(initialData.fromDate);
    // setFormData({ ...formData, fromDate: initialData.fromDate });
    // setFormData({ ...formData, toDate: initialData.toDate });
    // setFormData({ ...formData, fromTime: initialData.fromTime });
    // setFormData({ ...formData, toTime: initialData.toTime });
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    const convertDateToObject = (dateString) => {
      const year = dateString.getFullYear();
      const month = dateString.getMonth() + 1;
      const day = dateString.getDate();
      return { year, month, day };
    };

    // Function to convert time string to object
    const convertTimeToObject = (timeString) => {
      if (!timeString) return null;
      // Regular expression to match the time components
      const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)/i;

      // Execute the regular expression on the time string
      const matches = timeString.match(timeRegex);

      if (matches) {
        const hour = parseInt(matches[1], 10);
        const minute = parseInt(matches[2], 10);
        const am_pm = matches[3];

        console.log(`Hour: ${hour}`);
        console.log(`Minute: ${minute}`);
        console.log(`AM/PM: ${am_pm}`);
        // const am_pm = hour >= 12 ? "PM" : "AM";
        // hour = hour % 12 || 12;
        return { hour, minute, am_pm };
      } else {
        return null;
      }
    };

    const fromDateObject = convertDateToObject(fromDate);
    const toDateObject = convertDateToObject(toDate);

    // Convert times
    const fromTimeObject = convertTimeToObject(dateTime.fromTime);
    const toTimeObject = convertTimeToObject(dateTime.toTime);

    const payload = {
      logged_in_venue_profile_id: Cookies.get("profileOwnerId"),
      target_performer_profile_id: profileId,
      title: formData.title,
      message: formData.message.split("\n"),
      from_date: fromDateObject,
      to_date: toDateObject,
      from_time: fromTime,
      to_time: toTime,
    };

    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/request",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      console.log("Response:", response.data);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Direct booking sent successfully!",
      }).then(() => {
        closeFirstModal();
        closeSecondModal();
        closeThirdModal();
      });
    } catch (error) {
      console.error("Error:", error);
      let errorMessage = "There was an error sending direct booking.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      Swal.fire("Error", errorMessage, "error");
    }
    setLoading(false);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB");
  };

  return (
    <Card
      style={{
        borderRadius: "5px",
        backgroundColor: "#f0f2f5",
        display: "flex",
        justifyContent: "center",
        // marginLeft: "5%",
        // marginRight: "5%",
        // marginTop: "3%",
        paddingLeft: "2%",
        paddingRight: "2%",
        paddingTop: "2%",
        paddingBottom: "1%",
        // backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="title">Title</label>
          <input
            className="form-control shadow-box input-style"
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <div className="form-column">
            <label htmlFor="from_date">From Date</label>
            {/* <input
          type="date"
          id="from_date"
          name="from_date"
          value={formData.from_date}
          onChange={handleChange}
          required
        /> */}
            <input
              type="text"
              className="form-control shadow-box input-style"
              id="from_date"
              name="from_date"
              // value={fromDate}
              value={formatDate(fromDate)}
              readOnly
            />

            <label htmlFor="from_time">From Time</label>
            {/* <input
          type="time"
          id="from_time"
          name="from_time"
          value={formData.from_time}
          onChange={handleChange}
          required
        /> */}
            <input
              type="text"
              className="form-control shadow-box input-style"
              id="from_time"
              name="from_time"
              value={fromTimeDisplay}
              readOnly
            />
          </div>

          <div className="form-column">
            <label htmlFor="to_date">To Date</label>
            {/* <input
          type="date"
          id="to_date"
          name="to_date"
          value={formData.to_date}
          onChange={handleChange}
          required
        /> */}
            <input
              type="text"
              className="form-control shadow-box input-style"
              id="to_date"
              name="to_date"
              // value={toDate}
              value={formatDate(toDate)}
              readOnly
            />

            <label htmlFor="to_time">To Time</label>
            {/* <input
          type="time"
          id="to_time"
          name="to_time"
          value={formData.to_time}
          onChange={handleChange}
          required
        /> */}
            <input
              type="text"
              className="form-control shadow-box input-style"
              id="to_time"
              name="to_time"
              value={toTimeDisplay}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control shadow-box textarea-style"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <div className="submit-button-container">
          <Button
            style={{
              borderRadius: "5px",
              padding: "0.75rem 1.5rem",
              minWidth: "110px",
            }}
            variant="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
        {/* <div className="form-row">
          <button type="submit">Submit</button>
        </div> */}
      </form>
    </Card>
  );
};

BookingForm.propTypes = {
  initialData: PropTypes.shape({
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    fromTime: PropTypes.string.isRequired,
    toTime: PropTypes.string.isRequired,
  }).isRequired,
};

export default BookingForm;
