import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import "../../pages/venue/venue.css";
import MapComponent from "../../components/map/map";
// import dummyProfilePicture from "../../assect/images/venue/02.jpg";
import { RiMessage3Fill } from "react-icons/ri";
import Navbar from "../../components/navbarSelf";
import SidebarWithBurgerMenu from "../../components/searchNavbar";
import ParentComponent from "../../components/parentComponent";
import Slider from "rc-slider";
import "react-range-slider-input/dist/style.css";
import "rc-slider/assets/index.css";
import axiosInstance from "../../pages/auth/axiosInstance";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
// import ViewVenueProfile from "../../pages/venue/viewVenueProfile";
import { FaFlag, FaBookmark, FaRegBookmark } from "react-icons/fa";

import AutoComplete from "../../components/autoComplete";
import NewVenueProfile from "../OtherPages/newVenueProfile";
const VenueSearch = () => {
  const [formData, setFormData] = useState({
    types: [],
    genres: [],
    keywords: "",
    search_by_location: "NO",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    // search_radius: 0,
    from_price_point: 0,
    to_price_point: 0,
    from_date: "",
    to_date: "",
    display_order: "",
  });

  const [venues, setVenues] = useState([]);
  const [searchRadius, setSearchRadius] = useState([]);
  const [types, setTypes] = useState([]);
  const [genres, setGenres] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVenueId, setSelectedVenueId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSuburb, setSelectedSuburb] = useState(null);

  const navigate = useNavigate();
  const [saveSearch, setSaveSearch] = useState([]);

  const [flaggedVenues, setFlaggedVenues] = useState({});
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagComment, setFlagComment] = useState("");
  const [selectedFlaggedVenue, setSelectedFlaggedVenue] = useState(null);

  const handleFlagClick = (VenueId) => {
    setSelectedFlaggedVenue(VenueId);
    setShowFlagModal(true);
  };
  const handleFlag = async (flagComment, reportinProfileID, VenueProfileID) => {
    console.log("flagComment:", flagComment);

    console.log("reportinProfileID:", reportinProfileID);
    console.log("VenueProfileID:", VenueProfileID);
    try {
      const response = await axiosInstance.post(
        "/v1/venue/flag-venue-profile",
        {
          reporting_profile_id: reportinProfileID,
          venue_profile_id: VenueProfileID,
          comment: flagComment,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error searching Venues:", error);
    }
  };

  const handleFlagSave = () => {
    setFlaggedVenues((prev) => ({
      ...prev,
      [selectedFlaggedVenue]: flagComment,
    }));
    handleFlag(
      flagComment,
      `${Cookies.get("profileOwnerId")}`,
      selectedFlaggedVenue
    );
    setShowFlagModal(false);
    setFlagComment("");
  };

  const handleFlagCommentChange = (e) => {
    setFlagComment(e.target.value);
  };

  useEffect(() => {
    const fetchSaveSearch = async () => {
      try {
        const PROFILE_ID = `${Cookies.get("profileOwnerId")}`;
        const response = await axiosInstance.get(
          `/v1/search/load-search-configuration/${PROFILE_ID}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setSaveSearch(response.data.search_configurations);
        console.log(response.data.search_configurations);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchSaveSearch();
  }, []);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const typesResponse = await axiosInstance.get(
          "/v1/search/filter/venue-types",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setTypes(typesResponse.data.venue_types);

        const genresResponse = await axiosInstance.get(
          "/v1/search/filter/venue-genres",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        setGenres(genresResponse.data.venue_genres);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTypeChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setSelectedTypes(selectedOptions);
    }
  };

  const handleGenreChange = (selectedOptions) => {
    if (selectedOptions.length <= 7) {
      setSelectedGenres(selectedOptions);
    }
  };

  const handleSuburbSelect = (suburbData) => {
    setSelectedSuburb(suburbData);
    setFormData((prevData) => ({
      ...prevData,
      search_by_location: suburbData ? "YES" : "NO",
      location: suburbData || {
        suburb: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        location_name: "",
        address: "",
        google_map_pin: "",
      },
    }));
  };

  const handleSubmit = async (e) => {
    console.log("selected types:", selectedTypes[0].value);
    e.preventDefault();
    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/v1/search/venue",
        {
          venue_types: selectedTypes.map((type) => type.value),
          venue_genres: selectedGenres.map((genre) => genre.value),
          keywords: formData.keywords ? [formData.keywords] : [],
          search_by_location: formData.search_by_location,
          location: formData.location,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]),
          to_price_point: parseInt(pricePoint[1]),
          from_date: formData.from_date,
          to_date: formData.to_date,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      setVenues(response.data.search_results);
    } catch (error) {
      console.error("Error searching venues:", error);
    }
    setLoading(false);
  };

  const handleSaveSerch = async (e) => {
    console.log("selected types:", selectedTypes[0].value);

    const [minPrice, maxPrice] = pricePoint;
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    try {
      const response = await axiosInstance.post(
        "/v1/search/save/search-configuration",
        {
          search_type: "VENUE",
          profile_id: `${Cookies.get("profileOwnerId")}`,
          venue_types: selectedTypes.map((type) => type.value),
          venue_genres: selectedGenres.map((genre) => genre.value),
          keywords: formData.keywords ? [formData.keywords] : [],
          search_by_location: formData.search_by_location,
          location: formData.location,
          // search_radius: parseInt(formData.search_radius),
          from_price_point: parseInt(pricePoint[0]),
          to_price_point: parseInt(pricePoint[1]),
          from_date: formData.from_date,
          to_date: formData.to_date,
          display_order: formData.display_order,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error searching Venues:", error);
    }
  };

  const openModal = (id) => {
    console.log("selected venue id", id);
    setSelectedVenueId(id);
    Cookies.set("profileId", id);
    console.log("profileId cookie value:", Cookies.get("profileId"));
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedVenueId(null);
    setShowModal(false);
  };

  const [pricePoint, setPricePoint] = useState([20, 8000]);

  const handlePricePointChange = (value) => {
    setPricePoint(value);
  };

  const venueLocations = venues.map((venue) => ({
    latitude: venue.location.latitude,
    longitude: venue.location.longitude,
    name: venue.first_name + " " + venue.last_name,
  }));

  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <ParentComponent />
        </Col>

        <Col xs={12} md={9} style={{ marginLeft: "-12%" }}>
          <Container className="searchcontainer">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3" style={{ width: "120%" }}>
                <Col xs={12} md={2}>
                  <Form.Group controlId="typeFilter">
                    <Form.Label>Type</Form.Label>
                    <MultiSelect
                      options={types.map((type) => ({
                        label: type,
                        value: type,
                      }))}
                      name="venue_types"
                      value={selectedTypes}
                      onChange={handleTypeChange}
                      labelledBy="Select Type.."
                      hasSelectAll={false}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group controlId="genreFilter">
                    <Form.Label>Genre</Form.Label>
                    <MultiSelect
                      options={genres.map((genre) => ({
                        label: genre,
                        value: genre,
                      }))}
                      name="venue_genres"
                      value={selectedGenres}
                      onChange={handleGenreChange}
                      labelledBy="Select Genre.."
                      hasSelectAll={false}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group controlId="locationFilter">
                    <Form.Label>Location</Form.Label>
                    {/* <Form.Control
                      name="location"
                      type="text"
                      placeholder="Enter Location"
                      onChange={handleFilterChange}
                      className="filterstyles"
                    /> */}
                    <AutoComplete onSuburbSelect={handleSuburbSelect} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Form.Group controlId="radiusFilter">
                    <Form.Label>Radius</Form.Label>
                    <Form.Control
                      name="search_radius"
                      as="select"
                      onChange={handleFilterChange}
                      className="filterstyles"
                    >
                      <option value="">Select Radius</option>
                      <option value="radius1">Low</option>
                      <option value="radius2">High</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Form.Group controlId="pricePointFilter">
                    <Form.Label>Price Point range</Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        ${pricePoint[0]}
                      </span>
                      <div style={{ flex: 1, margin: "0 10px" }}>
                        <Slider
                          min={0}
                          max={10000}
                          value={pricePoint}
                          onChange={handlePricePointChange}
                          railStyle={{ background: "#d3d3d3", height: "4px" }}
                          trackStyle={[
                            { background: "#007bff", height: "4px" },
                          ]}
                          handleStyle={[
                            {
                              borderColor: "#007bff",
                              height: "14px",
                              width: "14px",
                              marginLeft: "-7px",
                              marginTop: "-5px",
                              backgroundColor: "#007bff",
                            },
                            {
                              borderColor: "#007bff",
                              height: "14px",
                              width: "14px",
                              marginLeft: "-7px",
                              marginTop: "-5px",
                              backgroundColor: "#007bff",
                            },
                          ]}
                          range
                        />
                      </div>
                      <span style={{ marginLeft: "10px" }}>
                        ${pricePoint[1]}
                      </span>
                    </div>
                  </Form.Group>
                  <input
                    type="hidden"
                    name="from_price_point"
                    value={pricePoint[0]}
                  />
                  <input
                    type="hidden"
                    name="to_price_point"
                    value={pricePoint[1]}
                  />
                </Col>
              </Row>

              {/* Search and filter section - Second Row */}
              <Row className="mb-3" style={{ width: "120%" }}>
                <Col xs={6} md={2}>
                  <Form.Group controlId="dateFilter">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      name="from_date"
                      type="date"
                      onChange={handleFilterChange}
                      className="filterstyles"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId="dateFilter">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      name="to_date"
                      type="date"
                      onChange={handleFilterChange}
                      className="filterstyles"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group controlId="searchBox">
                    <Form.Label>Keyword Search</Form.Label>
                    <Form.Control
                      name="keywords"
                      type="text"
                      placeholder="Enter Keyword"
                      onChange={handleFilterChange}
                      className="filterstyles"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId="locationFilter">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="category"
                      // onChange={handleFilterChange}
                      className="filterstyles"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId="locationFilter">
                    <Form.Label>Sub-category</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="sub-category"
                      // onChange={handleFilterChange}
                      className="filterstyles"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Button
                    variant="outline-primary"
                    className="me-2"
                    style={{
                      borderRadius: "25px",
                      marginTop: "22%",
                      fontSize: "15px",
                      border: "none",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      color: "white",
                      backgroundColor: "#418abb",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ff66c4";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#418abb";
                    }}
                    onClick={() => handleSaveSerch()}
                  >
                    Save
                  </Button>
                </Col>
                <Col xs={12} md={2}>
                  <Button type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>

          <Row className="mb-3" style={{ marginTop: "-2%" }}>
            <Col xs={12} md={6}>
              <div style={{ paddingLeft: "5%", paddingBottom: "10%" }}>
                <Tab.Container defaultActiveKey="explore">
                  <Row className="justify-content-center">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="explore"
                          style={{ color: "#418abb" }}
                        >
                          Explore
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="saved" style={{ color: "#418abb" }}>
                          Saved
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Row>
                  <Row className="justify-content-center">
                    <Tab.Content>
                      <Tab.Pane eventKey="explore">
                        <br></br>
                        {venues.map((venue) => (
                          <Card
                            key={venue._id}
                            onClick={() => openModal(venue._id)}
                          >
                            <div className="card-container">
                              <div className="card-left">
                                <Row className="align-items-center">
                                  <Col xs={4} md={4}>
                                    <div className="card-image">
                                      <img
                                        src={venue.profile_picture_url}
                                        alt="venue"
                                        style={{
                                          width: 70,
                                          height: 70,
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={8} md={8}>
                                    <div className="card-details">
                                      <div className="card-title">
                                        {venue.first_name} {venue.last_name}
                                      </div>
                                      {/* <div className="card-location">
                                          Location:{" "}
                                          {venue.location.location_name}
                                        </div> */}
                                      <div className="card-info d-flex justify-content-between">
                                        <span>
                                          Type -{" "}
                                          {venue.venue_types
                                            ? venue.venue_types.join(", ")
                                            : "No types available"}
                                        </span>
                                        <span>
                                          Genre -{" "}
                                          {venue.venue_genres
                                            ? venue.venue_genres.join(", ")
                                            : "No genres available"}
                                        </span>
                                      </div>
                                      {/* <div className="card-info">
                                                Keywords -{" "}
                                                {venue.keywords.join(", ")}
                                              </div> */}
                                      <div className="card-info">
                                        Available Dates -{" "}
                                        {venue.available_dates.map((date) => (
                                          <span key={date}>{date}</span>
                                        ))}
                                      </div>
                                      <div className="card-info">
                                        Price Point - {venue.price_point}
                                      </div>
                                      <div className="card-icons">
                                        <FaFlag
                                          onClick={() =>
                                            handleFlagClick(venue._id)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "10px",
                                          }}
                                        />
                                        {flaggedVenues[venue._id] && (
                                          <FaBookmark
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </Tab.Pane>
                      <Modal
                        show={showFlagModal}
                        onHide={() => setShowFlagModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Flag Venue</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="flagComment">
                              <Form.Label>Comment</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your comment"
                                value={flagComment}
                                onChange={handleFlagCommentChange}
                              />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setShowFlagModal(false)}
                          >
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleFlagSave}>
                            Save Comment
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        show={showModal}
                        onHide={closeModal}
                        size="xl"
                        style={{ minWidth: "110% !important" }}
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          {selectedVenueId && (
                            <NewVenueProfile venueId={selectedVenueId} />
                          )}
                        </Modal.Body>
                      </Modal>

                      <br></br>
                      <Tab.Pane eventKey="saved">
                        {saveSearch
                          .map((search) => {
                            if (search.search_type === "VENUE") {
                              return (
                                <Card
                                  className="box"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    marginBottom: "3%",
                                  }}
                                >
                                  <div
                                    className="card-title"
                                    style={{ marginBottom: "2%" }}
                                  ></div>
                                  <Card>
                                    <div className="card-container">
                                      <div className="card-left">
                                        <Row className="align-items-center">
                                          <Col xs={4} md={4}>
                                            <div className="card-image">
                                              <img
                                                // src={dummyProfilePicture}
                                                alt="Venue"
                                                style={{
                                                  width: 70,
                                                  height: 70,
                                                  borderRadius: "15px",
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col xs={8} md={8}>
                                            <div className="card-details">
                                              <div className="card-title">
                                                Save Search
                                              </div>
                                              <div className="card-location">
                                                Venue Type -
                                                {search.venue_types.map(
                                                  (date) => (
                                                    <span key={date}>
                                                      {" "}
                                                      {date},{" "}
                                                    </span>
                                                  )
                                                )}
                                              </div>
                                              <div className="card-location">
                                                Venue Genre -
                                                {search.venue_genres.map(
                                                  (date) => (
                                                    <span key={date}>
                                                      {" "}
                                                      {date},{" "}
                                                    </span>
                                                  )
                                                )}
                                              </div>
                                              <div className="card-info d-flex justify-content-between">
                                                <span>
                                                  Location -{" "}
                                                  {search.location.suburb}{" "}
                                                </span>
                                              </div>
                                              <div className="card-info d-flex justify-content-between">
                                                <span>
                                                  Date - from {search.from_date}{" "}
                                                  to {search.to_date}
                                                </span>
                                              </div>
                                              <div className="card-info d-flex justify-content-between">
                                                <span>
                                                  Price - from $
                                                  {search.from_price_point} to $
                                                  {search.to_price_point}
                                                </span>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Card>
                                </Card>
                              );
                            }
                            return null;
                          })
                          .filter(Boolean)}
                        {/* <Card
                          className="box"
                          style={{
                            width: "100%",
                            height: "auto",
                            marginBottom: "3%",
                          }}
                        >
                          <div
                            className="card-title"
                            style={{ marginBottom: "2%" }}
                          >
                            Type, Genre, Location, availability, price point
                            range
                          </div>

                          <Card>
                            <div className="card-container">
                              <div className="card-left">
                                <Row className="align-items-center">
                                  <Col xs={4} md={4}>
                                    <div className="card-image">
                                      <img
                                        src={dummyProfilePicture}
                                        alt="Venue"
                                        style={{
                                          width: 70,
                                          height: 70,
                                          borderRadius: "15px",
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={8} md={8}>
                                    <div className="card-details">
                                      <div className="card-title">
                                        Crown Canberra
                                      </div>
                                      <div className="card-location">
                                        Location: New York, USA
                                      </div>
                                      <div className="card-info d-flex justify-content-between">
                                        <span>Date May - 4</span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Card>
                        </Card> */}
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
            <Col xs={12} md={5}>
              <div className="map-container" style={{ marginTop: "5%" }}>
                <MapComponent locations={venueLocations} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VenueSearch;
