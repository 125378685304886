import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../../pages/auth/axiosInstance";
import Cookies from "js-cookie";
import "../OtherPages/LandingProfiles.css";

function UpdateVenueProfile() {
  const [venueName, setVenueName] = useState("");
  const [genres, setGenres] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const MAX_GENRES = 5;

  const handleGenreChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;

    if (checked) {
      // Add genre if not already selected and within the limit
      if (genres.length < MAX_GENRES) {
        setGenres([...genres, value]);
      } else {
        setErrorMessage("You can only select a maximum of 5 genres.");
      }
    } else {
      setGenres(genres.filter((genre) => genre !== value));
    }
  };

  useEffect(() => {
    async function fetchGenreTypes() {
      try {
        const response = await axiosInstance.get("/v1/profile/genre-types", {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });

        if (response.status === 200) {
          const { music_genres, other_genres } = response.data;
          const allGenres = [...music_genres, ...other_genres];
          setAvailableGenres(allGenres);
        } else {
          setErrorMessage("Error fetching genre types.");
        }
      } catch (error) {
        console.error("Error fetching genre types:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchGenreTypes();
  }, []);

  useEffect(() => {
    async function fetchVenueProfile() {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/v1/venue/profile", {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        });

        setLoading(false);

        if (response.status === 200) {
          const { venue_name, genres } = response.data;
          setVenueName(venue_name);
          setGenres(genres);
        } else {
          setErrorMessage("Error fetching venue profile data.");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching venue profile:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }

    fetchVenueProfile();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/info",
        {
          venue_id: Cookies.get("profileId"),
          venue_name: venueName.trim(),
          genres,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setSuccessMessage("Venue profile updated successfully.");
        console.log("API Response:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Your profile updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileId");
          navigate(`/venue/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating venue profile.");
        console.error("Error updating venue profile:", response.data);
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating venue profile:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <section className="landing-profiles-container">
      {/* <section className="bg-home zoom-image d-flex align-items-center">
      <div
        className="bg-overlay image-wrap"
        style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "center" }}
      ></div>
      <div className="bg-overlay bg-gradient-overlay"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Update Venue Profile</h5>

                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="venueName"
                    placeholder="Venue Name"
                    name="venueName"
                    value={venueName}
                    onChange={(e) => setVenueName(e.target.value)}
                    required
                  />
                  <label htmlFor="venueName">Venue Name</label>
                </div>

                <div className="mb-3">
                  <label htmlFor="genres" className="form-label">
                    Genres
                  </label>
                  <div>
                    {availableGenres.map((genre) => (
                      <div key={genre}>
                        <input
                          type="checkbox"
                          id={`genre-${genre}`}
                          name="genres"
                          value={genre}
                          checked={genres.includes(genre)}
                          onChange={(event) => handleGenreChange(event)}
                        />
                        <label htmlFor={`genre-${genre}`}>{genre}</label>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Submit{" "}
                  {loading && <Spinner animation="border" role="status" />}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdateVenueProfile;
