import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import NavigationBar from "../../components/navbar/newNavigationBar";
import pic from "../../assect/images/dashboard/venue-1.png";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axiosInstance from "../../pages/auth/axiosInstance";
import ViewOpportunities from "../../pages/opportunities/viewOpportunities";
import NewOpportunityVenueView from "../../pages/newView/NewOpportunityVenueView";
import NewOpportunityAuthorVenueView from "../../pages/newView/NewOpportunityAuthorVenueView";
import NewOpportunityPerformerView from "../../pages/newView/NewOpportunityPerformerView";
import { Button, Modal } from "react-bootstrap";
import DirectBookingVenueView from "../directBookings/directBookingVenueView";
import Footer from "../../components/footer";
import Spinner from "react-bootstrap/Spinner";

const Tabs = () => {
  const [opportunity, setOpportunity] = useState([]);
  const [bookings, setbookings] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const loggedInProfileId = Cookies.get("profileOwnerId");
  const profileId = Cookies.get("profileOwnerId");
  const [opportunities, setopportunities] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { opportunityId } = location.state || {};
  const [performerData, setPerformerData] = useState({});
  const [activeTab, setActiveTab] = useState("My Posts");
  const [subTab, setSubTab] = useState("Opportunity"); // Initialize subTab state
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedbookingId, setSelectedbookingId] = useState(null);
  const [modalContent2, setModalContent2] = useState(null);
  // State variables for bookings pagination
  const [bookingsNextUrl, setBookingsNextUrl] = useState(null);
  const [bookingsPreviousUrl, setBookingsPreviousUrl] = useState(null);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [opploadingNext, setOppLoadingNext] = useState(false);
  const [opploadingPrevious, setOppLoadingPrevious] = useState(false);

  // State variables for opportunities pagination
  const [opportunitiesNextUrl, setOpportunitiesNextUrl] = useState(null);
  const [opportunitiesPreviousUrl, setOpportunitiesPreviousUrl] =
    useState(null);

  // const getStatusLabel = (status) => {
  //   switch (status) {
  //     case "PERFORMER_APPLIED":
  //       return "Performer Applied";
  //     case "PERFORMER_DECLINED":
  //       return "Performer Declined";
  //     case "VENUE_REJECTED":
  //       return "Venue Rejected";
  //     case "CONFIRMATION_REQUESTED":
  //       return "Confirmation Requested";
  //     case "VENUE_WITHDRAWN":
  //       return "Venue Withdrawn";
  //     case "PERFORMER_DOUBLED_CONFIRMED":
  //       return "Performer Double confirmed";
  //     default:
  //       return status; // Default to showing the raw status if no match is found
  //   }
  // };

  const getStatusLabel = (status) => {
    switch (status) {
      case "PERFORMER_APPLIED":
        return { label: "Applied", badgeClass: "badge-primary" };
      case "PERFORMER_DECLINED":
        return { label: "Declined", badgeClass: "badge-danger" };
      case "VENUE_REJECTED":
        return { label: "Rejected", badgeClass: "badge-danger" };
      case "CONFIRMATION_REQUESTED":
        return { label: "Confirmation Requested", badgeClass: "badge-warning" };
      case "VENUE_WITHDRAWN":
        return { label: "Withdrawn", badgeClass: "badge-warning" };
      case "PERFORMER_DOUBLED_CONFIRMED":
        return { label: "Success", badgeClass: "badge-success" };
      case "VENUE_REQUESTED":
        return { label: "VENUE REQUESTED", badgeClass: "badge-success" };
      case "CONFIRMED":
        return { label: "BOOKING CONFIRMED", badgeClass: "badge-success" };
      default:
        return { label: status, badgeClass: "badge-secondary" };
    }
  };

  // const sortOpportunityByDate = (opportunities) => {
  //   return opportunities.sort((opportunity1, opportunity2) => {
  //     const date1 = new Date(opportunity1.created_on_timestamp_utc);
  //     const date2 = new Date(opportunity2.created_on_timestamp_utc);
  //     return date2.getTime() - date1.getTime();
  //   });
  // };

  const sortOpportunityByDate = (opportunities) => {
    return opportunities.sort((opportunity1, opportunity2) => {
      const date1 = new Date(opportunity1.from_date_time_utc);
      const date2 = new Date(opportunity2.from_date_time_utc);
      return date1.getTime() - date2.getTime();
    });
  };

  const formatDateRange = (fromDate, fromTime, toDate, toTime) => {
    const formatTime = (time) => {
      return `${time.hour}:${time.minute.toString().padStart(2, "0")} ${time.am_pm
        }`;
    };

    const formatDate = (date) => {
      return `${date.day}/${date.month}/${date.year}`;
    };

    const formattedFrom = `${formatDate(fromDate)} ${formatTime(fromTime)}`;
    const formattedTo = `${formatDate(toDate)} ${formatTime(toTime)}`;

    return `${formattedFrom} - ${formattedTo}`;
  };

  const fetchBookings = async (
    url = `/v1/booking-direct/view-all-pagination/by/venue`
  ) => {
    setLoading(true);
    try {
      const bookingresponse = await axiosInstance.post(
        url,
        {
          venue_profile_id: profileId,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );
      if (bookingresponse.status === 200) {
        const data = bookingresponse.data;
        console.log("booking data: ", data);

        const filteredBookings = data.bookings_direct.results.filter(
          (booking) => booking.venue_profile_id === profileId
        );

        const sortedBookings = sortOpportunityByDate(filteredBookings);

        setbookings(sortedBookings);
        setBookingsNextUrl(bookingresponse.data.bookings_direct.next);
        setBookingsPreviousUrl(bookingresponse.data.bookings_direct.previous);
      } else {
        console.error("Error fetching bookings:", bookingresponse.statusText);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOpportunities = async (
    url = `/v1/opportunity/pagination-published/by/profile-owner/${profileId}`
  ) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      if (response.status === 200) {
        const data = response.data;

        const filteredOpportunities =
          data.opportunities_published.results.filter(
            (opportunity) => opportunity.author_profile_id === profileId
          );

        const sortedOpportunities = sortOpportunityByDate(
          filteredOpportunities
        );
        console.log("Opportunity data: ", data);

        // Fetch bookings for each opportunity
        const opportunitiesWithBookingStatus = await Promise.all(
          sortedOpportunities.map(async (opportunity) => {
            const bookingStatus = await fetchBookingStatus(opportunity._id);
            return { ...opportunity, bookingStatus };
          })
        );

        setopportunities(opportunitiesWithBookingStatus);

        // setopportunities(sortedOpportunities);
        setOpportunitiesNextUrl(response.data.opportunities_published.next);
        setOpportunitiesPreviousUrl(
          response.data.opportunities_published.previous
        );
      } else {
        console.error("Error fetching opportunities:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching opportunities:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBookingStatus = async (opportunityId) => {
    try {
      const loggedInProfileId = Cookies.get("profileOwnerId");
      const payload = {
        opportunity_id: opportunityId,
        logged_in_profile_id: loggedInProfileId,
      };

      const response = await axiosInstance.post(
        "/v1/booking/view-all-active/by/opportunity",
        payload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const bookings = response.data.bookings.filter(
          (booking) => booking !== null
        );

        // Find the booking with status "VENUE_CONFIRMED"
        const venueConfirmedBooking = bookings.find(
          (booking) =>
            booking.status === "VENUE_CONFIRMED" ||
            booking.status === "PERFORMER_DOUBLED_CONFIRMED" ||
            booking.status === "PERFORMER_CANCELLED_CONTRACT"
        );

        // If found, return the latest status for that booking
        return venueConfirmedBooking ? venueConfirmedBooking.status : null;
      }
    } catch (error) {
      console.error("Error fetching booking status:", error);
    }
    return null; // Return null if no booking found
  };

  useEffect(() => {
    if (profileId) {
      fetchBookings();
      fetchOpportunities();
    }
  }, [profileId]);

  const handleBookingsNext = async (e) => {
    e.preventDefault();
    setLoadingNext(true);
    try {
      if (bookingsNextUrl) {
        const nextUrlWithBasePath = bookingsNextUrl.startsWith(
          "/v1/booking-direct"
        )
          ? bookingsNextUrl
          : `/v1/booking-direct${bookingsNextUrl}`;
        console.log("Fetching next URL for bookings: ", nextUrlWithBasePath);
        await fetchBookings(nextUrlWithBasePath);
      } 
    } catch (error) {
      console.error("Error fetching next bookings:", error);
    } finally {
      setLoadingNext(false);
    }
  };

  const handleBookingsPrevious = async (e) => {
    e.preventDefault();
    setLoadingPrevious(true);
    try {
      if (bookingsPreviousUrl) {
        const previousUrlWithBasePath = bookingsPreviousUrl.startsWith(
          "/v1/booking-direct"
        )
          ? bookingsPreviousUrl
          : `/v1/booking-direct${bookingsPreviousUrl}`;
        console.log(
          "Fetching previous URL for bookings: ",
          previousUrlWithBasePath
        );
        await fetchBookings(previousUrlWithBasePath);
      }
    } catch (error) {
      console.error("Error fetching next opportunities:", error);
    } finally {
      setLoadingPrevious(false);
    }
  };

  const handleOpportunitiesNext = async (e) => {
    e.preventDefault();
    setOppLoadingNext(true);
    try {
      if (opportunitiesNextUrl) {
        const nextUrlWithBasePath = opportunitiesNextUrl.startsWith(
          "/v1/opportunity"
        )
          ? opportunitiesNextUrl
          : `/v1/opportunity${opportunitiesNextUrl}`;
        console.log("Fetching next URL for opportunities: ", nextUrlWithBasePath);
        await fetchOpportunities(nextUrlWithBasePath);
      }
    } catch (error) {
      console.error("Error fetching next opportunities:", error);
    } finally {
      setOppLoadingNext(false);
    }
  };

  const handleOpportunitiesPrevious = async (e) => {
    e.preventDefault();
    setOppLoadingPrevious(true);
    try {
      if (opportunitiesPreviousUrl) {
        const previousUrlWithBasePath = opportunitiesPreviousUrl.startsWith(
          "/v1/opportunity"
        )
          ? opportunitiesPreviousUrl
          : `/v1/opportunity${opportunitiesPreviousUrl}`;
        console.log(
          "Fetching previous URL for opportunities: ",
          previousUrlWithBasePath
        );
        await fetchOpportunities(previousUrlWithBasePath);
      }
    } catch (error) {
      console.error("Error fetching previous opportunities:", error);
    } finally {
      setOppLoadingPrevious(false);
    }
  };

  const handleDoubleConfirm = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/double-confirm/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Double confirmed!", "success");
      } else {
        console.error(
          "Error confirming request:",
          response.status,
          response.data
        );
        Swal.fire("Error", "An error occurred while confirming the request.", "error");
      }
    } catch (error) {
      console.error("confirm Request error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    }
  };

  const handleReject = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/decline/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Confirmation has been rejected.", "success");
      } else {
        console.error(
          "Error rejecting confirmation:",
          response.status,
          response.data
        );

        Swal.fire("Error", "An error occurred during the reject confirmation.", "error");
      }
    } catch (error) {
      console.error("reject confirmation error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    }
  };

  const handleCancelContract = async (bookingId) => {
    const loggedInProfileId = Cookies.get("profileOwnerId");

    try {
      const response = await axiosInstance.post(
        "/v1/booking/cancellation-request/contract/by-performer",
        {
          booking_id: bookingId,
          logged_in_profile_id: loggedInProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Contract cancellation request has been sent!", "success");
      } else {
        console.error(
          "Error cancelling the contract:",
          response.status,
          response.data
        );

        Swal.fire("Error", "An error occurred while canceling the contract.", "error");
      }
    } catch (error) {
      console.error("cancel contract error:", error);
      Swal.fire("Error", "An error has occurred.", "error");
    }
  };

  const handleWithdraw = async (bookingId) => {
    setWithdrawLoading(bookingId);
    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/withdraw/by/venue",
        {
          venue_profile_id: loggedInProfileId,
          direct_booking_id: bookingId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Withdrawn!",
          "The booking has been successfully withdrawn.",
          "success"
        );
        setbookings((prevBookings) =>
          prevBookings.filter(
            (bookings_direct) => bookings_direct._id !== bookingId
          )
        );
      } else {
        console.error(
          "Error withdrawing booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Unable to withdraw the booking.", "error");
      }
    } catch (error) {
      console.error("Withdraw request error:", error);
      let errorMessage = "An error occurred while withdrawing the booking.";

      if (error.response) {
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response from server.";
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setWithdrawLoading(null);
    }
  };

  const handleDelete = async (bookingId) => {
    setDeleteLoading(bookingId);
    try {
      const response = await axiosInstance.post(
        "/v1/booking-direct/delete/by/venue",
        {
          venue_profile_id: loggedInProfileId,
          direct_booking_id: bookingId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire(
          "Deleted!",
          "The booking has been successfully deleted.",
          "success"
        );
        setbookings((prevBookings) =>
          prevBookings.filter(
            (bookings_direct) => bookings_direct._id !== bookingId
          )
        );
      } else {
        console.error(
          "Error deleting booking:",
          response.status,
          response.data
        );
        Swal.fire("Error", "Unable to delete the booking.", "error");
      }
    } catch (error) {
      console.error("delete error:", error);
      let errorMessage = "An error occurred while deleting the booking.";

      if (error.response) {
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response from server.";
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setDeleteLoading(null);
    }
  };

  const handleTitleClick = (opportunityId) => {
    navigate(`/venue/opportunity/author/${opportunityId}`);
  };

  // const handleTitleClick = (opportunityId) => {
  //     setSelectedPerformerId(opportunityId);
  // };

  // const handleOpportunityClick = (opportunityId) => {
  //     Cookies.set("opportunityId", opportunityId);
  //     console.log("Clicked opportunityId:", opportunityId);
  //     navigate(`/venue/opportunity/author/${opportunityId}`);
  //   };

  const getProfileType = (profileId) => {
    if (profileId.startsWith("PROFILE-PERFORMER")) {
      return "performer";
    } else if (profileId.startsWith("PROFILE-VENUE")) {
      return "venue";
    }
    return null;
  };

  const openModal = async (id, authorProfileId) => {
    console.log("selected opportunity id", id);
    setSelectedOpportunityId(id);
    // Cookies.set("opportunityId", id);
    // console.log("opportunityId cookie value:", Cookies.get("opportunityId"));
    setShowModal(true);

    try {
      const response = await axiosInstance.get(`/v1/opportunity/view/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      });
      const opportunity = response.data;
      console.log("opportunity response", response.data);
      const profileId = Cookies.get("profileOwnerId");
      console.log(
        "author profile id:",
        opportunity.opportunity.author_profile_id
      );
      console.log("opp id:", id);

      if (profileId === opportunity.opportunity.author_profile_id) {
        setModalContent(
          <NewOpportunityAuthorVenueView
            opportunityId={id}
            profileId={authorProfileId}
          />
        );
        console.log("author profile id:", opportunity.author_profile_id);
      } else {
        const profileType = getProfileType(profileId);
        if (profileType === "performer") {
          setModalContent(
            <NewOpportunityPerformerView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        } else if (profileType === "venue") {
          setModalContent(
            <NewOpportunityVenueView
              opportunityId={id}
              profileId={authorProfileId}
            />
          );
        }
      }
    } catch (error) {
      console.error("Error fetching opportunity details:", error);
      let errorMessage = "An error occurred while fetching the opportunity details.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    }
  };

  // const closeModal = () => {
  //   setSelectedOpportunityId(null);
  //   setShowModal(false);
  // };

  const closeModal = () => {
    setSelectedOpportunityId(null);
    setShowModal(false);
  };

  const handleBookingTitleClick = (bookingId) => {
    openModal2(bookingId);
  };

  const openModal2 = async (id) => {
    console.log("selected booking id", id);
    setSelectedbookingId(id);
    setShowModal2(true);

    setModalContent2(
      <DirectBookingVenueView
        bookingId={id}
        onDeleteSuccess={() => {
          setShowModal2(false);
          window.location.reload();
        }}
      />
    );
  };

  const closeModal2 = () => {
    setSelectedbookingId(null);
    setShowModal2(false);
  };

  const convertUTCToLocal = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  const formatDateTime = (date) => {
    const localDate = convertUTCToLocal(new Date(date));
    return localDate.toLocaleString();
  };

  return (
    <>
      <NavigationBar />
      <div className="dash-container">
        <div className="dash-image-container">
          <img src={pic} alt="Venue" className="dash-venue-image" />
        </div>
        <h2>My Posts</h2>
        <>
          <div className="dash-card-container">
            {opportunities.map((opportunity, index) => (
              <div className="dash-card" key={index}>
                <div>
                  {formatDateRange(
                    opportunity.from_date,
                    opportunity.from_time,
                    opportunity.to_date,
                    opportunity.to_time
                  )}
                </div>
                {/* <div>{formatDateTime(opportunity.from_date_time_utc)}</div> */}
                {/* <div>{new Date(opportunity.from_date_time_utc).toLocaleString()}</div> */}
                <h6
                  className="dash-post-title"
                  // onClick={() => handleTitleClick(opportunity._id)}
                  onClick={() =>
                    openModal(opportunity._id, opportunity.author_profile_id)
                  }
                >
                  {opportunity.title}
                </h6>

                <div
                  className={`booking-status ${opportunity.status.toLowerCase()}`}
                >
                  <strong>Opportunity Post Status:</strong>{" "}
                  {opportunity.status.replace(/_/g, " ")}
                </div>

                {/* <span
                  className={`badge ${
                    getStatusLabel(opportunity.status).badgeClass
                  }`}
                >
                  {getStatusLabel(opportunity.status.replace(/_/g, " ")).label}
                </span> */}

                {/* <span className={`badge ${getStatusLabel'}>
                  {getStatusLabel(opportunity.status)}
                </span> */}

                {opportunity.bookingStatus && (
                  <div
                    className={`booking-status ${opportunity.bookingStatus.toLowerCase()}`}
                  >
                    <strong>Booking Status:</strong>{" "}
                    {opportunity.bookingStatus.replace(/_/g, " ")}
                  </div>
                )}

                <a
                  href="#"
                  className="dash-view-more"
                  onClick={() =>
                    openModal(opportunity._id, opportunity.author_profile_id)
                  }
                  style={{ cursor: "pointer" }}
                >
                  View More →
                </a>
              </div>
            ))}
            {opportunities.length > 0 && (
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-primary me-3"
                  onClick={handleOpportunitiesPrevious}
                  disabled={!opportunitiesPreviousUrl || opploadingPrevious}
                  style={{
                    opacity: opploadingPrevious || !opportunitiesPreviousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                    cursor: opploadingPrevious || !opportunitiesPreviousUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                    transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    minWidth: "100px",
                  }}
                >
                  {opploadingPrevious ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Previous"
                  )}

                </button>
                <button
                  className="btn btn-primary"
                  disabled={!opportunitiesNextUrl || opploadingNext}
                  onClick={handleOpportunitiesNext}
                  style={{
                    opacity: opploadingNext || !opportunitiesNextUrl ? 0.6 : 1,
                    cursor: opploadingNext || !opportunitiesNextUrl ? "not-allowed" : "pointer",
                    transition: "opacity 0.3s ease",
                    minWidth: "100px",
                    marginRight: "180px",
                  }}
                >
                  {opploadingNext ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Next"
                  )}

                </button>
              </div>
            )}

            {bookings.map((booking, index) => (
              <div
                style={{ backgroundColor: "#E8E8E8" }}
                className="dash-card"
                key={index}
              >
                {/* <div>{bookings_direct.from_date_time_utc}</div> */}
                <div>
                  {formatDateRange(
                    booking.from_date,
                    booking.from_time,
                    booking.to_date,
                    booking.to_time
                  )}
                </div>
                <h6
                  className="dash-post-title"
                  onClick={() => handleBookingTitleClick(booking._id)}
                // onClick={() =>
                //   openModal(
                //     bookings_direct._id,
                //     bookings_direct.author_profile_id
                //   )
                // }
                >
                  {booking.title}
                </h6>
                {/* <span>
                  Requested on: {formatDateTime(booking.requested_on)}
                </span> */}

                {/* <span className="dash-applied-category-label">
                  {getStatusLabel(bookings_direct.status)}
                </span> */}
                {/* <span
                  className={`badge ${
                    getStatusLabel(booking.status).badgeClass
                  }`}
                >
                  {getStatusLabel(booking.status.replace(/_/g, " ")).label}
                </span> */}
                <div
                  className={`booking-status ${booking.status.toLowerCase()}`}
                >
                  <strong>Booking Status:</strong>{" "}
                  {booking.status.replace(/_/g, " ")}
                </div>

                <div>
                  {booking.status === "VENUE_REQUESTED" ? (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => handleWithdraw(booking._id)}
                        // tyle={{ marginTop: "10px", marginLeft: "8px" }}
                        style={{
                          cursor:
                            withdrawLoading === booking._id
                              ? "not-allowed"
                              : "pointer",
                          marginTop: "10px",
                          borderRadius: "3px",
                          minWidth: "100px",
                          minHeight: "20px",
                        }}
                        disabled={withdrawLoading === booking._id}
                      >
                        {withdrawLoading === booking._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Withdraw"
                        )}
                      </Button>
                    </>
                  ) : booking.status === "CANCELLATION_REQUEST_BY_PERFORMER" ||
                    booking.status === "PERFORMER_DECLINED" ? (
                    <>
                      <span
                        className={`badge ${getStatusLabel(booking.status).badgeClass
                          }`}
                      >
                        {/* {
                          getStatusLabel(booking.status.replace(/_/g, " "))
                            .label
                        } */}
                      </span>
                      {/* <span
                        className={`badge ${getStatusBadgeVariant(
                          bookings_direct.status
                        )}`}
                      >
                        {bookings_direct.status.replace("_", " ")}
                      </span> */}
                      <Button
                        variant="danger"
                        className="ms-2"
                        onClick={() => handleDelete(booking._id)}
                        style={{
                          cursor:
                            deleteLoading === booking._id
                              ? "not-allowed"
                              : "pointer",
                          marginTop: "10px",
                          marginLeft: "8px",
                          borderRadius: "3px",
                          minWidth: "100px",
                          minHeight: "20px",
                        }}
                        disabled={deleteLoading === booking._id}
                      >
                        {deleteLoading === booking._id ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    </>
                  ) : null}
                  {/* ) : (
                    <span
                    //   className={`badge ${getStatusBadgeVariant(
                    //     bookings_direct.status
                    //   )}`}
                    >
                      {bookings_direct.status.replace("_", " ")}
                    </span>
                  )} */}
                </div>

                {/* <div>{bookings_direct.requested_on}</div> */}
                <a
                  href="#"
                  className="dash-view-more"
                  //   onClick={() =>
                  //     openModal(
                  //       bookings_direct._id,
                  //       bookings_direct.author_profile_id
                  //     )
                  //   }
                  onClick={() => handleBookingTitleClick(booking._id)}
                  style={{ cursor: "pointer" }}
                >
                  View More →
                </a>
              </div>
            ))}
            {bookings.length > 0 && (
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-primary me-3"
                  disabled={!bookingsPreviousUrl || loadingPrevious}
                  onClick={handleBookingsPrevious}
                  style={{
                    opacity: loadingPrevious || !bookingsPreviousUrl ? 0.6 : 1, // Reduce opacity when loadingPrevious is true
                    cursor: loadingPrevious || !bookingsPreviousUrl ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
                    transition: "opacity 0.3s ease", // Smooth transition for opacity change
                    minWidth: "100px",
                  }}
                >
                  {loadingPrevious ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Previous"
                  )}

                </button>
                <button
                  className="btn btn-primary"
                  disabled={!bookingsNextUrl || loadingNext}
                  onClick={handleBookingsNext}
                  style={{
                    opacity: loadingNext || !bookingsNextUrl ? 0.6 : 1,
                    cursor: loadingNext || !bookingsNextUrl ? "not-allowed" : "pointer",
                    transition: "opacity 0.3s ease",
                    minWidth: "100px",
                    marginRight: "180px",
                  }}
                >
                  {loadingNext ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        borderWidth: "0.1rem",
                      }}
                    />
                  ) : (
                    "Next"
                  )}

                </button>
              </div>
            )}

            <Modal
              show={showModal2}
              onHide={closeModal2}
              size="xl"
            // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header>
                <button
                  style={{
                    position: "absolute",
                    // top: "0px",
                    right: "10px",
                    border: "none",
                    background: "transparent",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={closeModal2}
                >
                  &times;
                </button>
              </Modal.Header>
              <Modal.Body>
                {/* Render ViewPerformerProfile component if performer ID is selected */}
                {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                <div>{modalContent2}</div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showModal}
              onHide={closeModal}
              size="xl"
            // style={{ minWidth: "100% !important" }}
            >
              <Modal.Header>
                <button
                  style={{
                    position: "absolute",
                    // top: "0px",
                    right: "10px",
                    border: "none",
                    background: "transparent",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  &times;
                </button>
              </Modal.Header>
              <Modal.Body>
                {/* Render ViewPerformerProfile component if performer ID is selected */}
                {/* {selectedOpportunityId && (
                            <OpportunityPerformerView
                              opportunityId={selectedOpportunityId}
                            />
                          )} */}
                <div>{modalContent}</div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      </div>
      <Footer />
    </>
  );
};

export default Tabs;
