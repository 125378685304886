import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import LeftNavbar from "./searchNavbar";

const ParentComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Hamburger icon */}
      <div className="d-md-none text-end">
        <Button variant="link" style={{ zIndex: 1 }}>
          <FaBars onClick={toggleNavbar} />
        </Button>
      </div>
      {/* Left navbar */}
      <LeftNavbar isOpen={isOpen} toggleNavbar={toggleNavbar} />
    </>
  );
};

export default ParentComponent;
