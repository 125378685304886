import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  margin-top: 80px;
`;

const FormWrapper = styled.form`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 10px;
  text-align: center;
`;

const InputGroup = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
`;
const Button = styled.button`
  justify-content: space-between;
  margin-right: 2px;
  margin-left: 2px;
  width: 100%;
  padding: 10px;
  background-color: #2b4eff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #0056b3;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-left: 60px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  max-width: 200px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

const ThumbnailHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const convertToEmbedUrl = (url) => {
  const youtubeMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)([^&?\n]+)/i
  );
  if (youtubeMatch) {
    const videoId = youtubeMatch[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }
  console.warn("Unsupported video link format:", url);
  return "";
};

const getThumbnailUrl = (url) => {
  const youtubeMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)([^&?\n]+)/i
  );
  if (youtubeMatch) {
    const videoId = youtubeMatch[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }
  return "";
};

function UpdateVenueVideoGallery() {
  const profileId = Cookies.get("profileOwnerId");
  const [links, setLinks] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const embedLinks = links
      .split("\n")
      .filter((link) => link.trim() !== "")
      .map((link) => convertToEmbedUrl(link.trim()));

    console.log("Embed Links:", embedLinks); // Ensure this matches expected format

    if (embedLinks.length === 0) {
      setLoading(false);
      setErrorMessage("Please provide at least one video link.");
      Swal.fire({
        title: "Error!",
        text: "Please provide at least one video link.",
        icon: "error",
        className: "swal-modal",
      });
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/v1/venue/update-profile/video-gallery",
        {
          venue_id: Cookies.get("profileOwnerId"),
          links: embedLinks,
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        localStorage.removeItem("venueData");
        setSuccessMessage(
          "The venue video gallery has been updated successfully."
        );
        Swal.fire({
          title: "Success!",
          text: "The venue video gallery has been updated successfully.",
          icon: "success",
          className: "swal-modal",
        }).then(() => {
          const profileId = Cookies.get("profileOwnerId");
          navigate(`/venue/${profileId}`);
        });
      } else {
        setErrorMessage("Error updating venue video gallery.");
        Swal.fire({
          title: "Error!",
          text: "Failed to update the venue video gallery. Please try again.",
          icon: "error",
          className: "swal-modal",
        });
      }
    } catch (error) {
      setLoading(false);
      const status = error.response?.status || "unknown";
      const errorMsg =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again later.";
      console.error("Error details:", error.response?.data); // Log the backend response
      setErrorMessage(`Error ${status}: ${errorMsg}`);
      Swal.fire({
        title: "Error!",
        text: `Error ${status}: ${errorMsg}`,
        icon: "error",
        className: "swal-modal",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { value, scrollHeight } = event.target;
    setLinks(value);
    event.target.style.height = "auto";
    event.target.style.height = `${scrollHeight}px`;

    // Update thumbnails
    const urls = value
      .split("\n")
      .filter((link) => link.trim() !== "")
      .map((link) => getThumbnailUrl(link.trim()));

    console.log("Generated Thumbnails:", urls); // Debugging line
    setThumbnails(urls);
  };
  const handleCancel = async () => {
    setErrorMessage(""); // Clear previous error message

    try {
      // Assuming navigate is an asynchronous function, you might need to await it
      await navigate(`/venue/${profileId}`);
    } catch (error) {
      console.error("Error navigating to venue profile:", error);
      setLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again.", // Directly use the error message here
        icon: "error",
        className: "swal-modal",
      });
    }
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>Update Venue Video Gallery</Title>
      </TitleWrapper>

      <FormWrapper onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="links">Video Links (One link per line)</Label>
          <Textarea
            id="links"
            value={links}
            onChange={handleChange}
            className="form-control"
            placeholder="Update video gallery..."
            required
          />
        </InputGroup>

        <ThumbnailWrapper>
          {thumbnails.map((thumbnail, index) => (
            <Thumbnail key={index}>
              <ThumbnailHover>
                <iframe
                  width="200"
                  height="113"
                  src={convertToEmbedUrl(links.split("\n")[index])}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </ThumbnailHover>
              <ThumbnailImage src={thumbnail} alt="Video thumbnail" />
              <ThumbnailOverlay>
                <span>Watch</span>
              </ThumbnailOverlay>
            </Thumbnail>
          ))}
        </ThumbnailWrapper>
        <ButtonWrapper>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.1rem",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
}

export default UpdateVenueVideoGallery;
