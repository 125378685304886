import React, { useState, useEffect } from "react";
import "./faqs.css";
import { useNavigate } from "react-router-dom";
import { Link as Link2 } from "react-scroll";
import Navbar from "../../components/navbarSelf";
// import bg3 from "../../assect/images/bg/03.jpg";
import Footer from "../../components/footer";
import axios from "axios";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import NavigationBar from "../../components/navbar/newNavigationBarProfiles";
import NavigationBar2 from "../../components/navbar/loggedOutUserNavbar";
const profileId = Cookies.get("profileOwnerId");

export default function Faqs() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    const isAuthenticatedFromStorage = localStorage.getItem("isAuthenticated");

    if (accessToken) {
      setUser({ isAuthenticated: true });
    } else {
      setUser({ isAuthenticated: false });
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchFaqs = async () => {
      const url = `/v1/faqs`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      try {
        const response = await axiosInstance.get(url, { headers });
        setFaqs(response.data.data.faqs);
        setLoading(false);
      } catch (error) {
        console.error("API Error:", error);
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleBackClick = () => {
    if (!user?.isAuthenticated) {
      navigate(`/`); // Navigate to the home page if the user is not authenticated
    } else {
      const profileType = profileId.split("-")[1];
      if (profileType === "PERFORMER") {
        navigate(`/performer/${profileId}`);
      } else if (profileType === "VENUE") {
        navigate(`/venue/${profileId}`);
      }
    }
  };

  return (
    <>
      {!user?.isAuthenticated ? <NavigationBar2 /> : <NavigationBar />}

      {/*         
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-black-50 para-desc mx-auto mb-0">
                  Questions & Answers
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-black title-dark">
                  Frequently Asked Questions
                </h5>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div className="position-relative">
        
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div> */}

      <section className="section">
        <div
          className="faqs-back-arrow-custom"
          onClick={handleBackClick}
          style={{ cursor: "pointer" }}
        >
          ←
        </div>
        <div className="container">
          <div
            className="row mt-5 justify-content-center"
            style={{ marginBottom: "50px" }}
          >
            <div className="col-12">
              <div className="title-heading text-center">
                <p
                  className="text-muted-50 para-desc mx-auto mb-0"
                  style={{ color: "#418ABB" }}
                >
                  Questions & Answers
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-black title-dark">
                  Frequently Asked Questions
                </h5>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-5 col-12 d-none d-md-block">
              <div className="rounded-3 shadow p-4 sticky-bar">
                <ul
                  className="list-unstyled sidebar-nav mb-0 py-0"
                  id="navmenu-nav"
                >
                  <li className="navbar-item p-0">
                    <Link2
                      to="tech"
                      spy={true}
                      activeclassname="active"
                      smooth={true}
                      duration={500}
                      className="h6 text-dark navbar-link"
                    >
                      Buying Questions
                    </Link2>
                  </li>
                  <li className="navbar-item mt-3 p-0">
                    <Link2
                      to="general"
                      spy={true}
                      activeclassname="active"
                      smooth={true}
                      duration={500}
                      className="h6 text-dark navbar-link"
                    >
                      General Questions
                    </Link2>
                  </li>
                  <li className="navbar-item mt-3 p-0">
                    <Link2
                      to="payment"
                      spy={true}
                      activeclassname="active"
                      smooth={true}
                      duration={500}
                      className="h6 text-dark navbar-link"
                    >
                      Payments Questions
                    </Link2>
                  </li>
                  <li className="navbar-item mt-3 p-0">
                    <Link2
                      to="support"
                      spy={true}
                      activeclassname="active"
                      smooth={true}
                      duration={500}
                      className="h6 text-dark navbar-link"
                    >
                      Support Questions
                    </Link2>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-8 col-md-7 col-12">
              <div id="tech">
                <div className="section-title">
                  <h4>Buying Product</h4>
                </div>

                <div className="accordion mt-4 pt-2" id="buyingquestion">
                  {faqs.map((faq, index) => (
                    <div className="accordion-item rounded-3 mb-3" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button border-0 bg-light ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => setActiveIndex(index)}
                        >
                          {faq.questions}
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse border-0"
                        >
                          <div className="accordion-body text-muted">
                            {faq.answer}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div id="general">
                <div className="section-title mt-5">
                  <h4>General Questions</h4>
                </div>

                <div className="accordion mt-4 pt-2" id="generalquestion">
                  {faqs.map((faq, index) => (
                    <div className="accordion-item rounded-3 mb-3" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button border-0 bg-light ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => setActiveIndex(index)}
                        >
                          {faq.questions}
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse border-0"
                        >
                          <div className="accordion-body text-muted">
                            {faq.answer}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div id="payment">
                <div className="section-title mt-5">
                  <h4>Payments Questions</h4>
                </div>

                <div className="accordion mt-4 pt-2" id="paymentquestion">
                  {faqs.map((faq, index) => (
                    <div className="accordion-item rounded-3 mb-3" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button border-0 bg-light ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => setActiveIndex(index)}
                        >
                          {faq.questions}
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse border-0"
                        >
                          <div className="accordion-body text-muted">
                            {faq.answer}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div id="support">
                <div className="section-title mt-5">
                  <h4>Support Questions</h4>
                </div>

                <div className="accordion mt-4 pt-2" id="supportquestion">
                  {faqs.map((faq, index) => (
                    <div className="accordion-item rounded-3 mb-3" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button border-0 bg-light ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => setActiveIndex(index)}
                        >
                          {faq.questions}
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse border-0"
                        >
                          <div className="accordion-body text-muted">
                            {faq.answer}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
