import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbarSelf";
import axiosInstance from "../auth/axiosInstance";
// import bg3 from "../../assect/images/bg3.jpg";
import Cookies from "js-cookie";
import Footer from "../../components/footer";

export default function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      const profileId = Cookies.get("profileOwnerId");
      const accessToken = Cookies.get("accessToken");

      try {
        const response = await axiosInstance.get(
          "/v1/notification/get_notification",
          {
            params: { target_id: profileId },
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          setNotifications(response.data.results || []);
        } else {
          setError("Failed to load notifications");
        }
      } catch (error) {
        setError("An error occurred while fetching notifications");
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <>
      {/* <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      /> */}
      <section
        className="bg-half-170 d-table w-100"
        // style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Notifications
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-sm-0 pt-sm-0">
              {isLoading ? (
                <div className="text-center">Loading...</div>
              ) : error ? (
                <div className="text-center text-danger">Error: {error}</div>
              ) : (
                <div className="features-absolute">
                  <div className="row g-4 justify-content-center">
                    {Array.isArray(notifications) &&
                    notifications.length > 0 ? (
                      <div className="col-12">
                        <div
                          className="card rounded-3"
                          style={{
                            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
                          }}
                        >
                          <div className="p-4 border-bottom text-center">
                            <h5 className="text-primary">Notifications</h5>
                          </div>
                          <div className="p-4">
                            {notifications.map((notification) => (
                              <div
                                key={notification.notification_id}
                                className={`alert ${
                                  notification.has_opened
                                    ? "alert-secondary"
                                    : "alert-primary"
                                }`}
                                role="alert"
                              >
                                <h6 className="alert-heading">
                                  {notification.title}
                                </h6>
                                <p className="mb-1">{notification.body}</p>
                                <hr />
                                <p className="mb-0">
                                  {new Date(
                                    notification.creat_date
                                  ).toLocaleString()}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        No notifications available.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
