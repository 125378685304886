import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Form,
  Modal,
} from "react-bootstrap";
import Navbar from "../../components/navbarSelf";
import axiosInstance from "../auth/axiosInstance";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AutoComplete from "../../components/autoCompleteForms";
import { MultiSelect } from "react-multi-select-component";
import OpportunityDateTime from "./OpportunityDateTime";
import "./addNewOpportunity.css";
import CustomTimePicker from "../../components/CustomTimePicker";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

const AddNewOpportunity = ({
  initialData,
  closeNextModal,
  closeCurrentModal,
  closeFirstModal,
  setNewOpportunityCreated,
}) => {
  const closeModal = closeNextModal;
  const fromDate = initialData.fromDate || "";
  const toDate = initialData.toDate || "";
  const fromTime = initialData.fromTime2 || "";
  const toTime = initialData.toTime2 || "";
  const fromTimeDisplay = initialData.fromDisplayTime2 || "";
  const toTimeDisplay = initialData.toDisplayTime2 || "";
  // const fromTimeDisplay = `${fromTime.hour} : ${
  //   fromTime.minute == 0 ? "00" : fromTime.minute
  // } : ${fromTime.am_pm}`;
  // const toTimeDisplay = `${toTime.hour} : ${
  //   toTime.minute == 0 ? "00" : toTime.minute
  // } : ${toTime.am_pm}`;
  const [formData, setFormData] = useState({
    title: "",
    venue: "",
    location: {
      suburb: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      location_name: "",
      address: "",
      google_map_pin: "",
    },
    price_point: { currency: "AUD", value_from: 0, value_to: 1500 },
    performers: [{ performer_name: "", performer_profile_id: "" }],
    opportunity_types: [],
    genres: [],
    // from_date: "",
    // to_date: "",
    // from_time: "",
    // to_time: "",
    from_date: { year: 0, month: 0, date: 0 },
    to_date: { year: 0, month: 0, date: 0 },
    from_time: { hour: 0, minute: 0, am_pm: "" },
    to_time: { hour: 0, minute: 0, am_pm: "" },
    author_profile_id: "",
    short_description: "",
    descriptions: [""],
  });

  // const [locationData, setLocationData] = useState({
  //   suburb: "",
  //   zipcode: "",
  //   latitude: "",
  //   longitude: "",
  //   location_name: "",
  //   address: "",
  //   google_map_pin: "",
  // });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [showDateTimeModal, setShowDateTimeModal] = useState(true);
  const [dateTime, setDateTime] = useState({
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  });

  const types = [
    "Bar",
    "Beer Garden",
    "Cafe",
    "Chill",
    "Club",
    "Community Event",
    "Contemporary",
    "Festival",
    "Funky",
    "Hotel",
    "Live Performance",
    "Lounge",
    "Modern",
    "Nightclub",
    "Pub",
    "Restaurant",
    "Winery",
    "Wine Bar",
    "Sports Club",
  ];

  const genres = [
    "Alternative",
    "Blues",
    "Chill",
    "Classics",
    "Country",
    "Disco",
    "DJ",
    "Drum and Bass",
    "Dub",
    "Easy Listening",
    "Electroacoustic",
    "Electronic Dance Music",
    "Electronic",
    "Electronica",
    "Ethnic",
    "Experimental",
    "First Nations",
    "Folk",
    "Funk",
    "Hardcore",
    "Hardstyle",
    "Heavy Metal",
    "Hip Hop",
    "House Music",
    "Indie Rock",
    "Indigenous",
    "Industrial",
    "Intelligent Dance Music",
    "Jazz",
    "Jungle",
    "K-pop",
    "Latin",
    "New-age",
    "Plunderphonics",
    "Pop",
    "Popular Music",
    "Punk Rock",
    "Reggae",
    "Rhythm and Blues",
    "Rock",
    "Singing",
    "Ska",
    "Soul",
    "Synth-pop",
    "Techno",
    "World Music",
  ];

  // Example function that sets the error message
  const showError = (message) => {
    setErrorMessage(message);
  };

  // Clear the error message after 3 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      // Cleanup the timer if the component unmounts or errorMessage changes
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const venue_id = Cookies.get("profileOwnerId");
        console.log(venue_id);
        const response = await axiosInstance.get(
          `/v1/venue/view-profile/${venue_id}/section/contact`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        console.log("Location: ", response.data.venue_contact.location);
        handleSuburbSelect(response.data.venue_contact.location);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    //   const fetchGenres = async () => {
    //     try {
    //       const response = await axiosInstance.get("/v1/opportunity/genres");
    //       setGenres(response.data.opportunity_genres);
    //     } catch (error) {
    //       console.error("Error fetching genres:", error);
    //     }
    //   };

    //   const fetchTypes = async () => {
    //     try {
    //       const response = await axiosInstance.get("/v1/opportunity/types");
    //       setTypes(response.data.opportunity_types);
    //     } catch (error) {
    //       console.error("Error fetching types:", error);
    //     }
    //   };

    //   fetchGenres();
    //   fetchTypes();

    const profileId = Cookies.get("profileOwnerId");
    console.log("profileId:", profileId);

    if (profileId) {
      setFormData((prevData) => ({
        ...prevData,
        author_profile_id: profileId,
      }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Function to convert date string to object
    const convertDateToObject = (dateString) => {
      const year = dateString.getFullYear();
      const month = dateString.getMonth() + 1;
      const day = dateString.getDate();
      return { year, month, day };
    };

    // Function to convert time string to object
    const convertTimeToObject = (timeString) => {
      if (!timeString) return null;
      // Regular expression to match the time components
      const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)/i;

      // Execute the regular expression on the time string
      const matches = timeString.match(timeRegex);

      if (matches) {
        const hour = parseInt(matches[1], 10);
        const minute = parseInt(matches[2], 10);
        const am_pm = matches[3];

        console.log(`Hour: ${hour}`);
        console.log(`Minute: ${minute}`);
        console.log(`AM/PM: ${am_pm}`);
        // const am_pm = hour >= 12 ? "PM" : "AM";
        // hour = hour % 12 || 12;
        return { hour, minute, am_pm };
      } else {
        return null;
      }
      // let [hour, minute] = timeString.split(":").map(Number);
      // console.log(timeString);

      // Ensure minute is either 0 or 30
      // if (minute !== 0 && minute !== 30) {
      //   minute = minute < 15 ? 0 : 30;
      // }

      // const am_pm = hour >= 12 ? "PM" : "AM";
      // hour = hour % 12 || 12;
      // return { hour, minute, am_pm };
      // closeModal();
    };

    // Convert dates
    const fromDateObject = convertDateToObject(fromDate);
    const toDateObject = convertDateToObject(toDate);

    // Convert times
    const fromTimeObject = convertTimeToObject(dateTime.fromTime);
    const toTimeObject = convertTimeToObject(dateTime.toTime);

    // Calculate the difference in hours between From and To dates
    const fromDateTime = new Date(`${formData.fromDate}T${formData.fromTime}`);
    const toDateTime = new Date(`${formData.toDate}T${formData.toTime}`);
    const hourDifference = (toDateTime - fromDateTime) / (1000 * 60 * 60);

    // Check if "From Date/Time" is before "To Date/Time"
    if (fromDateTime >= toDateTime) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "From Date/Time must be before To Date/Time",
      });
      setLoading(false);
      return;
    }

    // Check if the difference exceeds 10 hours
    if (hourDifference > 10) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "To Date/Time must be within 10 hours of From Date/Time",
      });
      setLoading(false);
      return;
    }

    const updatedFormData = {
      ...formData,

      from_date: fromDateObject,
      to_date: toDateObject,
      from_time: fromTime,
      to_time: toTime,
      opportunity_types: selectedTypes.map((type) => type.value),
      opportunity_genres: selectedGenres.map((genre) => genre.value),
    };

    console.log("Updated Form Data:", updatedFormData);

    try {
      const response = await axiosInstance.post(
        "/v1/opportunity/create-opportunity",
        updatedFormData
      );
      console.log("Opportunity created:", response.data);

      closeCurrentModal();
      closeFirstModal();
      setNewOpportunityCreated(true);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "The opportunity has been created successfully!",
      }).then(() => {
        const profileId = Cookies.get("profileOwnerId");
        // closeModal();
        // window.location.reload();
        // navigate(`/venue/${profileId}`);
        // if (typeof closeModal === "function") {
        //   closeModal(); // Call the closeModal function if it is defined and a function
        // }
      });
    } catch (error) {
      console.error("Error creating opportunity:", error);
      let errorMessage = "An error occurred while creating the opportunity.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire("Error", errorMessage, "error");
    } finally {
      setLoading(false);
      // closeModal(
      //   initialData.fromDate,
      //   initialData.toDate,
      //   initialData.fromTime,
      //   initialData.toTime
      // );
    }
  };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "price_point") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       price_point: { ...prevData.price_point, value: parseFloat(value) },
  //     }));
  //   } else {
  //     setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title" && value.length > 100) {
      Swal.fire("Error", "The title cannot exceed 100 characters.", "error");
      return;
    }

    if (name === "price_point_value") {
      const priceValue = value;
      if (priceValue[0] < 0 || priceValue[1] > 5000) {
        setErrorMessage("Price Point should be between $0 and $5000");
      } else {
        setErrorMessage(""); // Clear error message if valid
        setFormData((prevData) => ({
          ...prevData,
          price_point: {
            ...prevData.price_point,
            value_from: priceValue[0],
            value_to: priceValue[1],
          },
        }));
      }
    } else if (name === "price_point_currency") {
      setFormData((prevData) => ({
        ...prevData,
        price_point: { ...prevData.price_point, currency: value },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // const handleDescriptionChange = (index, value) => {
  //   const newDescriptions = [...formData.descriptions];
  //   newDescriptions[index] = value; // Update the specific description at index
  //   setFormData((prevData) => ({ ...prevData, descriptions: newDescriptions }));
  // };

  const handleDescriptionChange = (index, value) => {
    // Check if the description has more than 2000 characters
    if (value.length > 2000) {
      Swal.fire({
        title: "Too Many Characters!",
        text: "The description should not exceed 2000 characters.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      const newDescriptions = [...formData.descriptions];
      newDescriptions[index] = value; // Update the specific description at index
      setFormData((prevData) => ({
        ...prevData,
        descriptions: newDescriptions,
      }));
    }
  };


  const handleCancel = () => {
    // navigateBack();
    // if (typeof onClose === "function") {
    //   onClose(); // Call the onClose function if it is defined and a function
    // }
  };

  const navigateBack = () => {
    const profileId = Cookies.get("profileOwnerId");
    navigate(`/venue/${profileId}`);
  };

  const handleSuburbSelect = (suburbData) => {
    setFormData((prevData) => ({
      ...prevData,
      location: {
        suburb: suburbData.suburb,
        zipcode: suburbData.zipcode,
        latitude: suburbData.latitude,
        longitude: suburbData.longitude,
        location_name: suburbData.location_name,
        address: suburbData.address,
        google_map_pin: suburbData.google_map_pin,
      },
    }));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB");
  };

  return (
    <>
      <style>
        {`
        body {
          background-color: #f0f2f5;
        }
      `}
      </style>
      <div>
        {/* <Navbar
          navClass="defaultscroll sticky"
          menuClass="navigation-menu nav-left"
        /> */}
        <br></br>

        <Card
          style={{
            borderRadius: "5px",
            backgroundColor: "#f0f2f5",
            display: "flex",
            justifyContent: "center",
            // marginLeft: "5%",
            // marginRight: "5%",
            // marginTop: "3%",
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingTop: "2%",
            paddingBottom: "1%",
            // backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          {/* <div className="text-center">
            <h2>Create new Opportunity</h2>
          </div>
          <br></br> */}
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-2">
              <Row style={{ marginBottom: "-1.5%" }}>
                <div className="inputwith-title">
                  <p className="para-style">Title</p>
                  <input
                    type="text"
                    className="form-control shadow-box input-style"
                    id="title"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* <label htmlFor="floatingInput">First Name</label> */}
              </Row>

              <Row>
                <Col md={6}>
                  <div className="d-flex flex-column">
                    {/* Left column (half width) */}
                    <div className="inputwith-title">
                      <Form.Group>
                        <Form.Label className="para-style">
                          Opportunity Type
                        </Form.Label>
                        <MultiSelect
                          style={{ padding: "0.75rem" }}
                          options={types.map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          name="opportunity_types"
                          value={selectedTypes}
                          onChange={(selected) => {
                            if (selected.length <= 3) {
                              setSelectedTypes(selected);
                              setFormData((prevData) => ({
                                ...prevData,
                                opportunity_types: selected.map((s) => s.value),
                              }));
                            } else {
                              setErrorMessage(
                                "You can only select a maximum of 3 types"
                              );
                            }
                          }}
                          labelledBy="Select opportunity types"
                          hasSelectAll={false}
                        />
                      </Form.Group>
                    </div>
                    <div className="inputwith-title">
                      <Form.Group>
                        <Form.Label className="para-style">Genre</Form.Label>
                        <MultiSelect
                          options={genres.map((genre) => ({
                            label: genre,
                            value: genre,
                          }))}
                          name="genres"
                          value={selectedGenres}
                          onChange={(selected) => {
                            if (selected.length <= 7) {
                              setSelectedGenres(selected);
                              setFormData((prevData) => ({
                                ...prevData,
                                genres: selected.map((s) => s.value),
                              }));
                            } else {
                              setErrorMessage(
                                "You can only select a maximum of 7 genres"
                              );
                            }
                          }}
                          labelledBy="Select genres"
                          hasSelectAll={false}
                        />
                      </Form.Group>
                    </div>
                    <div className="inputwith-title">
                      <p className="para-style">Location</p>
                      {/* <AutoComplete onSuburbSelect={handleSuburbSelect} /> */}
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="location"
                        name="location"
                        value={formData.location.address}
                        onChange={handleChange}
                        readOnly={true}
                      />
                    </div>
                    {/* <div className="inputwith-title">
                      <p className="para-style">Venue</p>
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="venue"
                        name="venue"
                        placeholder="venue"
                        value={formData.venue}
                        onChange={handleChange}
                        required
                      />
                    </div> */}
                    <Row>
                      <p>Date</p>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">From</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="from_date"
                            name="from_date"
                            // value={fromDate}
                            value={formatDate(fromDate)}
                            readOnly
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style">To</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="to_date"
                            name="to_date"
                            // value={toDate}
                            value={formatDate(toDate)}
                            readOnly
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <p>Time</p>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">From</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="from_time"
                            name="from_time"
                            value={fromTimeDisplay}
                            readOnly
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style">To</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="to_time"
                            name="to_time"
                            value={toTimeDisplay}
                            readOnly
                          />
                        </div>
                      </div>
                    </Row>
                    <div className="inputwith-title">
                      <p className="para-style">Short Description</p>
                      <input
                        type="text"
                        className="form-control shadow-box input-style"
                        id="short_description"
                        name="short_description"
                        value={formData.short_description}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <Row>
                      <div className="d-flex justify-content-between align-items-center twocol-row">
                        <div className="inputwith-title col-md-6 mr-3 p-2">
                          <p className="para-style">Currency</p>
                          <input
                            type="text"
                            className="form-control shadow-box input-style"
                            id="currency"
                            name="price_point_currency"
                            value={formData.price_point.currency}
                            onChange={handleChange}
                            disabled
                          // required
                          />
                        </div>
                        <div className="inputwith-title col-md-6 p-2">
                          <p className="para-style mb-4">Price Point</p>
                          {/* <input
                            type="number"
                            className="form-control shadow-box input-style"
                            id="price_point"
                            name="price_point_value"
                            placeholder="Price point"
                            value={formData.price_point.value}
                            onChange={handleChange}
                          /> */}
                          <div style={{ position: "relative", width: "100%" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                left: "0",
                              }}
                            >
                              ${formData.price_point.value_from}
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "-23px",
                                right: "0",
                              }}
                            >
                              ${formData.price_point.value_to}
                            </span>
                            <Slider
                              value={[
                                formData.price_point.value_from,
                                formData.price_point.value_to,
                              ]}
                              onChange={(event, newValue) =>
                                handleChange({
                                  target: {
                                    name: "price_point_value",
                                    value: newValue,
                                  },
                                })
                              }
                              valueLabelDisplay="auto"
                              style={{ top: "2px" }}
                              min={0}
                              max={5000}
                              step={10}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                    {/* <div className="inputwith-title">
                      <p className="para-style">Amount</p>
                      <input
                        type="number"
                        className="form-control shadow-box input-style"
                        id="price_point"
                        name="price_point"
                        placeholder="amount"
                        value={formData.price_point.value}
                        onChange={handleChange}
                      />
                    </div> */}
                  </div>
                </Col>
                <Col md={6}>
                  {/* Right column (half width on large screens) */}
                  {/* <p className="para-style">Description</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="floatingInput"
                    placeholder="Enter a detailed description here"
                    name="descriptions"
                    value={formData.descriptions}
                    onChange={handleChange}
                  ></textarea> */}

                  {/* <p className="para-style" style={{ marginTop: "2%" }}>
                    Description Heading
                  </p>
                  <input
                    type="text"
                    className="form-control shadow-box input-style"
                    id="description_heading"
                    name="description_heading"
                    value={formData.descriptions[0].heading}
                    onChange={(e) =>
                      handleDescriptionChange(0, "heading", e.target.value)
                    }
                    required
                  />

                  <p className="para-style">Description Body</p>
                  <textarea
                    className="form-control shadow-box textarea-style"
                    id="description_body"
                    name="description_body"
                    value={formData.descriptions[0].body[0]}
                    onChange={(e) =>
                      handleDescriptionChange(0, "body", e.target.value)
                    }
                    required
                  ></textarea> */}

                  {formData.descriptions.map((description, index) => (
                    <div key={index}>
                      <p className="para-style">Description</p>
                      <textarea
                        className="form-control shadow-box textarea-style"
                        id="floatingInput"
                        placeholder="Enter a detailed description here"
                        value={description}
                        onChange={(e) =>
                          handleDescriptionChange(index, e.target.value)
                        }
                        required
                        style={{ minHeight: "800px", resize: "vertical" }} // Adjust minHeight as needed
                      ></textarea>
                      <br />
                    </div>
                  ))}

                  <br></br>
                  <div
                    className="button-container"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="text-center">
                      <Button
                        style={{
                          borderRadius: "5px",
                          padding: "0.75rem 1.5rem",
                          minWidth: "110px",
                          cursor: loading ? "not-allowed" : "pointer",
                          opacity: loading ? 0.6 : 1,                            
                          transition: "opacity 0.3s ease" 
                        }}
                        variant="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {/* {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )} */}
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              borderWidth: "0.1rem",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </form>
        </Card>
      </div>
      {/* <Modal
        show={showDateTimeModal}
        onHide={() => setShowDateTimeModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <center>Enter Date and Time</center>
          </Modal.Title>
        </Modal.Header>
        <p style={{ alignSelf: "left", marginTop: "10px", marginLeft: "4%" }}>
          Your To Date/Time must be within 10 hours of From Date/Time
        </p>
        <Modal.Body>
          <OpportunityDateTime
            setDateTime={setDateTime}
            onNext={() => setShowDateTimeModal(false)}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

AddNewOpportunity.propTypes = {
  initialData: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default AddNewOpportunity;
