import React from "react";
import { Card, Button } from "react-bootstrap";
import CalendarSelfView from "./calendar/calendarSelfView";

function OpportunityCard() {
  return (
    <div
      className="opportunity-card"
      style={{ position: "absolute", right: "-35px", marginTop: "120px" }}
    >
      <CalendarSelfView />
      <Card
        style={{
          border: "1px solid #ced4da",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          width: "70%",
          height: "80vh",
          overflowY: "hidden",
        }}
      >
        <Card.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button variant="primary" className="mb-3">
              Post Opportunity
            </Button>
            <br />
            <br />
            <br />
            <h5>Opportunity Details</h5>
            <br />
            <p style={{ marginBottom: "1em" }}>
              <strong>Date:</strong> 03/07/2024
              <br />
              <br />
              <strong>Performer:</strong> Brad Franzen
              <br />
              <br />
              <strong>Status:</strong> Confirmed
              <br />
              <br />
              <strong>Location:</strong> New York City, USA
              <br />
              <br />
              <strong>Time:</strong> 7.00 PM
              <br />
              <br />
              <strong>Duration:</strong> 3 hours
              <br />
              <br />
              <strong>Genre:</strong> Jazz
            </p>
          </div>
          <div>
            <Button variant="primary" className="mb-2">
              See full post in profile
            </Button>
            <Button variant="primary">Messages</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default OpportunityCard;
